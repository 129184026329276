import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { LayoutService } from '../layout.service';

import { UISections } from 'src/models/constants';

@Component({
  selector: 'app-navbar-pagename',
  templateUrl: './navbar-pagename.component.html',
  styleUrls: ['./navbar-pagename.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarPagenameComponent implements OnInit {
  uiSections: typeof UISections = UISections;

  constructor(public layoutService: LayoutService) {}

  ngOnInit() {}

  getSectionTitle(m: string) {
    return this.layoutService.appMenu.filter(r => r.section === m)[0].title;
  }
}
