import {
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails
} from 'src/models/constants';
import { Actions } from './subMenuUI.actions';
import * as actions from './subMenuUI.actions';
import { SubMenuUI } from 'src/models/SubMenu';

const INITIAL_STATE: SubMenuUI = {
  eop: 0,
  sop: null,
  hash: '',
  currentPage: [],
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {}
};

export function subMenuUiReducer(
  state = INITIAL_STATE,
  action: Actions
): SubMenuUI {
  switch (action.type) {
    case actions.SubMenuUIActionTypes.SUB_MENU_STORE_RECEIVED: {
      return {
        ...state,
        currentPage: state.currentPage.map(r => {
          if (r.id === action.subMenuStore.id) {
            return action.subMenuStore;
          }
          return r;
        })
      };
    }

    case actions.SubMenuUIActionTypes.UI_SUB_MENU_GET_NEXT_PAGE: {
      return {
        ...state,
        direction: PageDirection.NEXT,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubMenuUIActionTypes.UI_SUB_MENU_GET_PREV_PAGE: {
      return {
        ...state,
        direction: PageDirection.PREV,
        status: SCREEN_STATUS.REQUESTING
      };
    }
    case actions.SubMenuUIActionTypes.UI_SUB_MENU_SET_IDLE_STATUS: {
      return {
        ...state,
        status: SCREEN_STATUS.IDLE
      };
    }
    case actions.SubMenuUIActionTypes.UI_SUB_MENU_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;

      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        status: SCREEN_STATUS.IDLE,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.SubMenuUIActionTypes.UI_SUB_MENU_RESET_PAGING: {
      return INITIAL_STATE;
    }
    case actions.SubMenuUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
