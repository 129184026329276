/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hover-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./hover-tooltip.component";
var styles_HoverTooltipComponent = [i0.styles];
var RenderType_HoverTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HoverTooltipComponent, data: {} });
export { RenderType_HoverTooltipComponent as RenderType_HoverTooltipComponent };
export function View_HoverTooltipComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "tooltiptext"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.positionStyle; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 3, 0, currVal_1); }); }
export function View_HoverTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip", [], null, null, null, View_HoverTooltipComponent_0, RenderType_HoverTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HoverTooltipComponentNgFactory = i1.ɵccf("app-hover-tooltip", i3.HoverTooltipComponent, View_HoverTooltipComponent_Host_0, { text: "text", top: "top", right: "right", width: "width" }, {}, []);
export { HoverTooltipComponentNgFactory as HoverTooltipComponentNgFactory };
