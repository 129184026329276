<div [class.form-group]="!isInline" [class.form-inline]="isInline">
  <label [for]="fieldId" class="control-label" [class.md]="isInline">
    {{ filedLabel }}
    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    ></app-hover-tooltip-icon>
  </label>
  <div class="form-group lg-input">
    <input
      type="text"
      class="form-control"
      [id]="fieldId"
      [formControl]="locationControl"
      [placeholder]="filedLabel"
      autocomplete="off"
    />
    <div class="dropdown-menu locations" *ngIf="locList?.length > 0">
      <div
        class="menu-item"
        *ngFor="let loc of locList"
        (click)="getPlaceDetails(loc)"
      >
        {{ loc }}
      </div>
    </div>

    <ng-container *ngIf="validationErrors$ | async as errors">
      <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
      </app-validation-errors>
    </ng-container>

    <app-map-cell
      *ngIf="longControl.value && latControl.value"
      [width]="'593'"
      [height]="'150'"
      [latitude]="latControl.value"
      [longitude]="longControl.value"
      style="padding-top: 10px;"
    >
    </app-map-cell>
    <input type="text" [formControl]="latControl" hidden />
    <input type="text" [formControl]="longControl" hidden />
  </div>
</div>
