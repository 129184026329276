import { BehaviorSubject } from 'rxjs';
import { ScreenSizeService } from 'src/app/core/screen-size.service';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { SIDE_MENU } from 'src/models/AppSections';
import { GridStyle } from 'src/models/constants';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../store/root-store.selectors";
import * as i2 from "../../store/messages/message.dispatchers";
import * as i3 from "../../core/screen-size.service";
import * as i4 from "../../store/ui/ui.dispatchers";
export class LayoutService {
    constructor(_selectors, _messageDispatchers, _screenSize, _uiDispatcher) {
        this._selectors = _selectors;
        this._messageDispatchers = _messageDispatchers;
        this._screenSize = _screenSize;
        this._uiDispatcher = _uiDispatcher;
        this.appMenu = SIDE_MENU;
        this._listGridStyle = new BehaviorSubject(GridStyle.card);
        this.listGridStyle$ = this._listGridStyle.asObservable();
    }
    getSideNavStatus() {
        return this._screenSize.mobileQuery;
    }
    setListGridStyle(n) {
        this._listGridStyle.next(n);
    }
    setSideMenuStatus(status) {
        this._uiDispatcher.setSideMenuStatus(status);
    }
    get messagesCollection$() {
        return this._selectors.messagesCollection$;
    }
    get uiCollection$() {
        return this._selectors.uiCollection$;
    }
    get selectedChat$() {
        return this._selectors.getSelectedChat$();
    }
    get allChannels$() {
        return this._selectors.getChatsMenu$();
    }
    getsideMenuStatus$() {
        // this._selectors
        //   .getSideMenuStatus()
        //   .subscribe(res => console.log('status', res));
        return this._selectors.getSideMenuStatus();
    }
    deselectChannel() {
        this._messageDispatchers.deselectChannel();
    }
}
LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.MessageDispatchers), i0.ɵɵinject(i3.ScreenSizeService), i0.ɵɵinject(i4.UIDispatchers)); }, token: LayoutService, providedIn: "root" });
