import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./summary/summary.module').then(m => m.SummaryModule)
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./app-mgmt/app-mgmt.module').then(m => m.AppManagementModule)
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('./messages-main/messages-main.module').then(
            m => m.MessagesMainModule
          )
      },
      {
        path: 'schedules',
        loadChildren: () =>
          import('./schedules/schedules.module').then(m => m.SchedulesModule)
      },
      {
        path: 'billings',
        loadChildren: () =>
          import('./billings/billings.module').then(m => m.BillingsModule)
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import('./campaigns/campaigns.module').then(m => m.CampaignsModule)
      },
      {
        path: 'tags',
        loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule)
      },
      {
        path: 'coupons',
        loadChildren: () =>
          import('./coupons/coupons.module').then(m => m.CouponsModule)
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import('./bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'calendars',
        loadChildren: () =>
          import('./calendars/calendars.module').then(m => m.CalendarModule)
      },
      {
        path: 'bots',
        loadChildren: () => import('./bots/bots.module').then(m => m.BotsModule)
      },
      {
        path: 'customercare',
        loadChildren: () =>
          import('./customer-care/customer-care.module').then(
            m => m.CustomerCareModule
          )
      },
      {
        path: 'polls',
        loadChildren: () =>
          import('./menus/menus.module').then(m => m.MenusModule)
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('./locations/locations.module').then(m => m.LocationsModule)
      },
      {
        path: 'bulkupload',
        loadChildren: () =>
          import('./bulk-upload/bulk-upload.module').then(
            m => m.BulkUploadModule
          )
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./members/members.module').then(m => m.MembersModule)
      },
      {
        path: 'subgroups',
        loadChildren: () =>
          import('./sub-chats/sub-chats.module').then(m => m.SubChatsModule)
      },
      {
        path: 'subchannels',
        loadChildren: () =>
          import('./sub-chats/sub-chats.module').then(m => m.SubChatsModule)
      },
      {
        path: 'vapps',
        loadChildren: () =>
          import('./sub-chats/sub-chats.module').then(m => m.SubChatsModule)
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        path: 'bundles',
        loadChildren: () =>
          import('./bundles/bundles.module').then(m => m.BundlesModule)
      },
      {
        path: 'packages',
        loadChildren: () =>
          import('./packages/packages.module').then(m => m.PackagesModule)
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'mStore',
        loadChildren: () =>
          import('./mobile-store/mobile-store.module').then(
            m => m.MobileStoreModule
          )
      },
      {
        path: 'storeMenu',
        loadChildren: () =>
          import('./store-menu/store-menu.module').then(m => m.StoreMenuModule)
      },
      {
        path: 'tempsupload',
        loadChildren: () =>
          import('./upload-temps/upload-temps.module').then(
            m => m.UploadTempsModule
          )
      },
      {
        path: 'blackList',
        loadChildren: () =>
          import('./blacklist/blackList.module').then(m => m.BlackListsModule)
      },
      {
        path: 'my-channel',
        loadChildren: () =>
          import('./my-channel/mychannel.module').then(m => m.MyChannel)
      },
      {
        path: 'wl',
        loadChildren: () =>
          import('./white-list/white-list.module').then(m => m.WhitelistModule)
      },
      {
        path: 'MS',
        loadChildren: () =>
          import('./map-service/mapService.module').then(
            m => m.MapServiceModule
          )
      },
      {
        path: 'MR',
        loadChildren: () =>
          import('./map-routes/mapRoutes.module').then(m => m.MapRoutesModule)
      },
      {
        path: 'Trips',
        loadChildren: () =>
          import('./trips/trips.module').then(m => m.TripsModule)
      },
      {
        path: 'TH',
        loadChildren: () =>
          import('./trips-history/trips-history.module').then(
            m => m.TripsHistoryModule
          )
      },
      {
        path: 'TN',
        loadChildren: () =>
          import('./trips-notifiers/trips-notifiers.module').then(
            m => m.TripsNotifiersModule
          )
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
