import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IOption } from 'src/models/campaign';

import { AbstractControlsService } from '../abstract-controls.service';

@Component({
  selector: 'app-selector-input-area',
  templateUrl: './selector-input-area.component.html',
  styleUrls: ['./selector-input-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorInputAreaComponent {
  /**
   * 'fieldId' is a unique field identification
   * Example: 'email', 'password'
   *    ---REQUIRED PROPERTY---
   */
  @Input() isInline = false;
  @Input() filedLabel: string;
  /**
   * 'control' is a reactive form validator/value controller
   *    ---REQUIRED PROPERTY---
   */
  @Input() control: AbstractControl;

  keyValueOptions = [];

  constructor(public abstractControls: AbstractControlsService) {
    const option: IOption = {
      key: 'null',
      value: 'None'
    };

    this.keyValueOptions.unshift(option);
    abstractControls.area$.subscribe(res => {
      if (res && res.list && res.list.length > 0) {
        res.list.map(res => {
          const option = {
            key: res,
            value: res
          };
          this.keyValueOptions.push(option);
        });
      }
    });
  }
}
