import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-edit-btn',
  template: `
    <app-circle-btn edit (btnClicked)="btnEvent()">
      <app-edit-icon></app-edit-icon>
    </app-circle-btn>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBtnComponent implements OnInit {
  @Output() btnClicked = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  btnEvent() {
    this.btnClicked.emit(true);
  }
}
