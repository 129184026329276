import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { ChatMiddleware } from './chat.middleware';
import { ProfileMiddleware } from '../profile/profile.middleware';

import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { FileService } from 'src/app/core/file.service';
import { HelperService } from 'src/app/core/helper.service';
import { StorageService } from 'src/app/core/storage.service';

import * as ChatActions from './chat.actions';
import * as MessagesActions from '../messages/message.actions';
import * as AuthActions from './../auth/auth.actions';
import { ChatDispatchers } from './chat.dispatchers';
import { AuthDispatchers } from '../auth/auth.dispatchers';
import { ProfileDispatchers } from '../profile/profile.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';

import * as ChatMethods from 'src/models/IChat';
import * as MainChatMethods from 'src/models/MainChat';
import * as ProfileMethods from 'src/models/IProfile';
import { AppState } from 'src/models/AppState';
import { Photo } from 'src/models/Photo';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IUploadResponse } from 'src/models/IUploadResponse';
import { GetBookingPeriods } from 'src/models/BookingPeriods';
import {
  GROUP,
  CHANNEL,
  NO_FILE_NAME,
  NOT_SUPPORTED_FILE_TYPE,
  MessageTypes,
  FILE_SIZE_EXCEEDED,
  ERROR_NOT_IMAGE,
  GROUP_UPLOAD_TYPE,
  BOOKING_CHANNEL
} from 'src/models/constants';
import {
  createEventMethod,
  SetChat,
  createGroupMethod,
  createChannelMethod,
  EnableServiceMethod,
  createVirtualAppMethod
} from 'src/models/IChat';
import { MessageDispatchers } from '../messages/message.dispatchers';

import * as isEqual from 'lodash.isequal';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { GetChatAdministrators, PayCash } from 'src/models/IChatMember';

@Injectable()
export class ChatEffects {
  @Effect({ dispatch: false })
  authOk = this.actions$.pipe(
    ofType(AuthActions.RECIVE_AUTH_OK),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chats]) => {
      const action = <AuthActions.ReciveAuthOK>val;
      if (action.payload.chatId) {
        if (!chats.find(chat => chat.id === action.payload.chatId)) {
          const selectedChat: ChatMethods.IChat = {};
          selectedChat.id = action.payload.chatId;
          selectedChat.admin = true; // This effect takes place only in merchant web where the logged in is always the admin
          this._chatDispatcher.newChatReceived(selectedChat);
        }
        const newChat: ChatMethods.IChat = {};
        newChat.id = action.payload.chatId;
        this.getChatsDetails([newChat], chats);
      }
    })
  );

  @Effect({ dispatch: false })
  requestChatDetails = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.REQUEST_CHAT_DETAILS),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chats]) => {
      const action = <ChatActions.RequestChatDetails>val;
      const newChat: ChatMethods.IChat = {};
      newChat.id = action.chatID;
      this.getChatsDetails([newChat], chats);
    })
  );

  @Effect({ dispatch: false })
  chatCounters = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.GET_CHAT_COUNTERS),
    map((action: ChatActions.RequestChatCounters) => {
      this.getChatCounters(action.chatId, action.message_ids);
    })
  );

  @Effect({ dispatch: false })
  chatHistory = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.REQUEST_CHAT_HISTORY),
    map((action: ChatActions.RequestChatHistory) => {
      this.getChatHistory(action.payload);
    })
  );

  @Effect({ dispatch: false })
  settingChatToCache = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_DETAILS_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatsState]) => {
      const action = <ChatActions.ChatDetailsReceived>val;

      const rcvdChat = chatsState.find(chat => chat.id === action.payload.id);

      if (rcvdChat && (rcvdChat.type === GROUP || rcvdChat.type === CHANNEL)) {
        const mainChat = ChatMiddleware.getMainChatFromChat(rcvdChat);
        this._mainChatDispatchers.mainChatReceived(mainChat);
      }
      if (rcvdChat && rcvdChat.welcome_message) {
        this._messageDispatchers.initReceivedMessage(rcvdChat.welcome_message);
      }
      this._storageService.setChatToCache(action.payload.id, action.payload);
    })
  );

  @Effect({ dispatch: false })
  memberCountRcvd = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_MEMBER_COUNT_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatsState]) => {
      const action = <ChatActions.ChatMemberCountReceived>val;

      const selectedChat = chatsState.find(
        chat => chat.id === action.payload.id
      );
      if (selectedChat) {
        this._mainChatDispatchers.mainChatMemberCountReceived(
          action.payload.id,
          action.payload.member_count
        );
      }
      if (
        selectedChat &&
        (selectedChat.version &&
          selectedChat.version !== action.payload.version)
      ) {
        this.getChatInfo(action.payload.id);
      }
    })
  );

  @Effect({ dispatch: false })
  loggedInLeftChat = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.LOGGED_IN_LEFT_CHAT),
    map((action: ChatActions.LoggedInLeftChat) => {
      this._mainChatDispatchers.loggedInLeftMainChat(action.payload.chat_id);
      // should dispatch action in mainchat
    })
  );

  @Effect({ dispatch: false })
  detailsRcvdFromCache = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_DETAILS_FROM_CACHE),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatsState]) => {
      const action = <ChatActions.ChatDetailsFromCache>val;

      const rcvdChat = chatsState.find(chat => chat.id === action.payload.id);
      if (rcvdChat && (rcvdChat.type === GROUP || rcvdChat.type === CHANNEL)) {
        this._mainChatDispatchers.mainChatReceived(
          ChatMiddleware.getMainChatFromChat(rcvdChat)
        );
      }
      if (rcvdChat && rcvdChat.welcome_message) {
        this._messageDispatchers.initReceivedMessage(rcvdChat.welcome_message);
      }
    })
  );

  @Effect({ dispatch: false })
  chatDeselected = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.DESELECT_CHAT),
    map((action: ChatActions.DeselectChat) => {
      this._mainChatDispatchers.mainChatDeselected(
        action.id,
        action.lastTypedMessage
      );
    })
  );

  @Effect({ dispatch: false })
  chatSelected = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_SELECTED),
    map((action: ChatActions.ChatSelected) => {
      this.getChatMemberCount(action.payload);
    })
  );

  @Effect({ dispatch: false })
  updateChat = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.UPDATE_CHAT),
    map((action: ChatActions.UpdateChat) => {
      this._socketGateway.sendSocketMessage(
        new SetChat(action.chatDetails, action.reference)
      );
    })
  );

  @Effect({ dispatch: false })
  chatHistoryRequested = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.REQUEST_CHAT_HISTORY),
    map((action: ChatActions.RequestChatHistory) => {
      this._mainChatDispatchers.mainChatHistoryRequested(action.payload);
    })
  );

  @Effect({ dispatch: false })
  chatListRcvd = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_LIST_RECEIVED),
    map((action: ChatActions.ChatListReceived) => action),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.hasNoContacts)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(res => {
      if (res[0].payload) {
        const groupsList = res[0].payload.filter(chat => chat.type === GROUP);
        if (groupsList) {
          this._uiDispatchers.setGroupsCount(groupsList.length);
          this.getChatsDetails(groupsList, res[2]);
        }
        // To fire the details received event in case the user has no contacts and no groups
        if ((!groupsList || groupsList.length === 0) && res[1]) {
          this._uiDispatchers.detailsReceived();
        }
        const channelsList = res[0].payload.filter(
          chat => chat.type === CHANNEL
        );
        if (channelsList) {
          this.getChatsDetails(channelsList, res[2]);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  subChatListRcvd = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.SUB_CHAT_LIST_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatsState]) => {
      const action = <ChatActions.SubChatListReceived>val;

      if (action.payload) {
        this.getChatsDetails(action.payload, chatsState);
        if (action.listType && action.listType === BOOKING_CHANNEL) {
          action.payload.forEach(bookingChannel => {
            this._socketGateway.sendSocketMessage(
              new GetBookingPeriods(bookingChannel.id)
            );
            // 2019-03-18
            const currentDate = new Date();
            const currentDay = currentDate.getDate();
            const currentMonthNum = currentDate.getMonth() + 1;
            const currentMonth: string =
              currentMonthNum < 10
                ? '0' + currentMonthNum
                : '' + currentMonthNum;
            const currentYear = currentDate.getFullYear();
            const startDateString =
              currentYear + '-' + currentMonth + '-' + currentDay;

            this._socketGateway.sendSocketMessage(
              new ChatMethods.GetBookingExceptions(
                bookingChannel.id,
                startDateString
              )
            );
          });
        }
      }
    })
  );

  @Effect({ dispatch: false })
  messageReceived = this.actions$.pipe(
    ofType(MessagesActions.RECEIVE_MESSAGE),
    withLatestFrom(
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatsState, profilesState]) => {
      const action = <MessagesActions.ReceiveMessage>val;

      if (action.payload.group_id) {
        if (
          this._chatDispatcher.isNewChat(action.payload.group_id, chatsState)
        ) {
          const mainChat: MainChatMethods.MainChat = {};
          mainChat.id = action.payload.group_id;
          mainChat.name = action.payload.group_name;
          mainChat.type = action.payload.group_type === 100 ? GROUP : CHANNEL;
          const lastMessage = this._helperService.handleDifferentReplyTypes(
            action.payload
          );
          const newChat: ChatMethods.IChat = {};
          newChat.id = action.payload.group_id;
          this._chatDispatcher.newChatReceived(newChat);

          this._mainChatDispatchers.mainChatReceived(
            ChatMiddleware.initNewMainChat(lastMessage, mainChat)
          );
          this.getChatInfo(action.payload.group_id);
        }

        if (
          !action.payload.isChannelPost &&
          action.payload.sender_id !== this._authDispatcher.getLoginID()
        ) {
          if (
            ProfileMiddleware.isNewProfile(
              action.payload.sender_id,
              profilesState
            )
          ) {
            // That means a member in group and he is not a contact to logged in user
            this._profileDispatcher.unknownProfile(action.payload.sender_id);

            this.getProfileDetails(action.payload.sender_id);
          } else if (!action.payload.endOfPage) {
            const profileWithDifferentVersion = profilesState.find(
              prof =>
                prof.user_id === action.payload.sender_id &&
                prof.version &&
                action.payload.sender_version &&
                action.payload.sender_version.indexOf(prof.version) === -1
            );
            if (profileWithDifferentVersion) {
              this.getProfileDetails(action.payload.sender_id);
            }
          }
        }

        if (action.payload) {
          if (
            ProfileMiddleware.isNewProfile(
              action.payload.receiver_id,
              profilesState
            )
          ) {
            this._profileDispatcher.unknownProfile(action.payload.receiver_id);
            this.getProfileDetails(action.payload.receiver_id);
          }

          if (
            ProfileMiddleware.isNewProfile(
              action.payload.sender_id,
              profilesState
            )
          ) {
            this._profileDispatcher.unknownProfile(action.payload.sender_id);
            this.getProfileDetails(action.payload.sender_id);
          }
        }
      }
    })
  );

  @Effect({ dispatch: false })
  profileInvalidated = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_INVALIDATED),
    map((action: ChatActions.ChatInvalidated) => {
      this.getChatInfo(action.payload);
    })
  );

  @Effect({ dispatch: false })
  createEvent = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CREATE_EVENT),
    map((action: ChatActions.CreateEvent) => {
      this._socketGateway.sendSocketMessage(
        createEventMethod(action.eventDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  getBookingPeriods = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.GET_BOOKING_PERIODS),
    map((action: ChatActions.GetBookingPeriods) => {
      this._socketGateway.sendSocketMessage(
        new GetBookingPeriods(action.chatID)
      );
    })
  );

  @Effect({ dispatch: false })
  createBooking = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CREATE_BOOKING),
    map((action: ChatActions.CreateBooking) => {
      this._socketGateway.sendSocketMessage(
        ChatMethods.createBookingChannelMethod(action.bookingDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  createGroup = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CREATE_GROUP),
    map((action: ChatActions.CreateGroup) => {
      this._socketGateway.sendSocketMessage(
        createGroupMethod(action.groupDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  deleteChat = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.DELETE_CHAT),
    map((action: ChatActions.DeleteChat) => {
      this._socketGateway.sendSocketMessage(
        new ChatMethods.DeleteChat(action.chat_id)
      );
    })
  );

  @Effect({ dispatch: false })
  chatDelete = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_DELETED),
    map((action: ChatActions.ChatDeleted) => {
      console.log(`${action.chat_id} deleted successfully`);
    })
  );

  @Effect({ dispatch: false })
  chatDeleteError = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CHAT_DELETED_ERROR),
    map((action: ChatActions.ChatDeletedError) => {
      this._uiDispatchers.showPopup(SERVER_ERROR_MESSAGES[action.errorNumber]);
    })
  );

  @Effect({ dispatch: false })
  enableService = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.ENABLE_SERVICE),
    map((action: ChatActions.EnableService) => {
      this._socketGateway.sendSocketMessage(
        new EnableServiceMethod(
          action.chat_id,
          action.period,
          action.period_number,
          action.price,
          action.currency
        )
      );
    })
  );

  @Effect({ dispatch: false })
  createChannel = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CREATE_CHANNEL),
    map((action: ChatActions.CreateChannel) => {
      this._socketGateway.sendSocketMessage(
        createChannelMethod(action.channelDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  createVirtualApp = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.CREATE_VIRTUAL_APP),
    map((action: ChatActions.CreateVirtualApp) => {
      this._socketGateway.sendSocketMessage(
        createVirtualAppMethod(action.virtualAppDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  uploadChatImage = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.UPLOAD_CHAT_PHOTO),
    map((action: ChatActions.UploadChatPhoto) => {
      if (this.validateFile(action.selectedFile, 'image')) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.localFile)
          .then(fileAsArrayBuffer => {
            this._uploadGateway
              .uploadWithProgress(
                fileAsArrayBuffer,
                action.selectedFile.localFile.type,
                action.selectedFile.localFile.name,
                false,
                GROUP_UPLOAD_TYPE,
                null,
                action.chatID,
                true
              )
              .subscribe(
                event => {
                  if (event.type === HttpEventType.Response) {
                    const res = <IUploadResponse>event.body;
                    const chat: ChatMethods.IChat = {};
                    const photo: Photo = { id: res.file };
                    chat.id = action.chatID;
                    chat.photo = photo;
                    this._socketGateway.sendSocketMessage(new SetChat(chat));
                  }
                },
                error => {
                  console.log('Failed to upload event image');
                }
              );
          });
      }
    })
  );

  @Effect({ dispatch: false })
  rolesReceived = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.ROLES_DELIVERED),
    withLatestFrom(
      this._store
        .select(s => s.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <ChatActions.RolesDelivered>val;
      const localChat: ChatMethods.IChat = {
        id: selectedChat.id,
        chatRoles: action.payload
      };
      this._chatDispatcher.rolesReceived(localChat);
    })
  );

  @Effect({ dispatch: false })
  buySubscriptionOnBehalfOfUser = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.BUY_SUBSCRIPTION_ON_BEHALF_OF_USER),
    map(val => {
      const action = <ChatActions.BuySubscriptionOnBehalfOfUser>val;
      this._socketGateway.sendSocketMessage(
        new PayCash(
          action.member_id,
          null,
          '1',
          1,
          action.currency,
          'PAY_USER',
          action.chat_id
        )
      );
    })
  );
  @Effect({ dispatch: false })
  getChatAdministrators = this.actions$.pipe(
    ofType(ChatActions.ChatActionTypes.GET_CHAT_ADMINISTRATORS),
    map((action: ChatActions.GetChatAdministrators) => {
      this._socketGateway.sendSocketMessage(
        new GetChatAdministrators(action.chatID)
      );
    })
  );

  private getChatsDetails(
    chats: ChatMethods.IChat[],
    currentChatsState: ChatMethods.IChat[]
  ) {
    chats.forEach(chat => {
      const targetChat = currentChatsState.find(group => group.id === chat.id);
      if (
        !(
          targetChat &&
          targetChat.detailsReceived &&
          ((chat.version && targetChat.version === chat.version) ||
            !chat.version)
        )
      ) {
        this._storageService
          .getChatFromCache(chat.id)
          .then(cachedChat => {
            if (
              cachedChat &&
              ((chat.version && cachedChat.version === chat.version) ||
                !chat.version)
            ) {
              this._chatDispatcher.chatDetailsFromCache(cachedChat);
            } else {
              this.getChatInfo(chat.id);
            }
          })
          .catch(err => {
            this.getChatInfo(chat.id);
          });
      }
    });
  }

  constructor(
    private actions$: Actions,
    private _chatDispatcher: ChatDispatchers,
    private _socketGateway: SocketGateway,
    private _storageService: StorageService,
    private _authDispatcher: AuthDispatchers,
    private _profileDispatcher: ProfileDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _mainChatDispatchers: MainChatDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _helperService: HelperService,
    private _uploadGateway: UploadGateway,
    private _fileService: FileService,
    private _store: Store<AppState>,
    private _uiDispatches: UIDispatchers
  ) {}

  private getChatInfo(chatID: string) {
    this._socketGateway.sendSocketMessage(
      new ChatMethods.GetChatTemplate(chatID)
    );
  }

  private getChatMemberCount(chatID: string) {
    this._socketGateway.sendSocketMessage(
      new MainChatMethods.GetChatMemberCount(chatID)
    );
  }

  private getChatCounters(chatID: string, messageIDs: string[]) {
    this._socketGateway.sendSocketMessage(
      new ChatMethods.GetChatCounters(chatID, messageIDs)
    );
  }

  private getChatHistory(chatID: string) {
    this._socketGateway.sendSocketMessage(
      new ChatMethods.GetChatHistory(chatID)
    );
  }

  private getProfileDetails(userID: string) {
    if (userID) {
      this._socketGateway.sendSocketMessage(new ProfileMethods.GetUser(userID));
    }
  }

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
