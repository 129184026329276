import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { ICoupon } from 'src/models/ICoupon';
import { Sticky } from 'src/models/MessageParts';
import { BotMenu } from 'src/models/IBotMenu';

@Component({
  selector: 'app-compose-attachs-preview',
  templateUrl: './compose-attachs-preview.component.html',
  styleUrls: ['./compose-attachs-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeAttachsPreviewComponent {
  @Input() attachCoupon: ICoupon;
  @Input() attachMenu: BotMenu;
  @Input() attachSticky: Sticky;

  @Output() removeAttachCouponNotfiy = new EventEmitter<boolean>();
  @Output() removeAttachMenuNotfiy = new EventEmitter<boolean>();
  @Output() removeAttachStickyNotfiy = new EventEmitter<boolean>();

  constructor() {}

  removeAttachCoupon() {
    this.removeAttachCouponNotfiy.emit(true);
  }
  removeAttachMenu() {
    this.removeAttachMenuNotfiy.emit(true);
  }
  removeAttachSticky() {
    this.removeAttachStickyNotfiy.emit(true);
  }
}
