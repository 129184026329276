import {
  BanMemberFromChat,
  UnBanMemberFromChat
} from './../../../models/IChatMember';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { MembersUIActionTypes } from './membersUI.actions';
import * as MembersActions from './membersUI.actions';
import { MembersUiDispatchers } from './membersUI.dispatchers';
import { GetMyMembers, RemoveMemberFromChat } from 'src/models/IChatMember';
import { UserStatus, PageDirection } from 'src/models/constants';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class MembersUiEffects {
  @Effect({ dispatch: false })
  getNextMembersPage = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_MEMBERS_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.membersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, membersUiState]) => {
      const action = <MembersActions.GetNextMembersPage>val;
      const prevRequest = membersUiState.previousRequest;
      if (
        !(
          membersUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          membersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMyMembers(
            membersUiState.status,
            null,
            0,
            membersUiState.hash,
            membersUiState.eop,
            membersUiState.name,
            membersUiState.searchBy,
            membersUiState.view,
            membersUiState.accountTypes
          )
        );
      } else {
        this._uiMembersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevMembersPage = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_MEMBERS_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.membersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, membersUiState]) => {
      const action = <MembersActions.GetPrevMembersPage>val;
      const prevRequest = membersUiState.previousRequest;
      if (
        !(
          membersUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          membersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMyMembers(
            membersUiState.status,
            null,
            1,
            membersUiState.hash,
            membersUiState.sop,
            membersUiState.name,
            membersUiState.searchBy,
            membersUiState.view,
            membersUiState.accountTypes
          )
        );
      } else {
        this._uiMembersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getMyMembers = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_MY_MEMBERS),
    withLatestFrom(
      this._store
        .select(state => state.membersUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, membersUiState]) => {
      const action = <MembersActions.GetPrevMembersPage>val;
      const prevRequest = membersUiState.previousRequest;
      if (
        !(
          membersUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          membersUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetMyMembers(
            membersUiState.status,
            null,
            1,
            membersUiState.hash,
            membersUiState.sop,
            membersUiState.name,
            membersUiState.searchBy,
            membersUiState.view,
            membersUiState.accountTypes
          )
        );
      } else {
        this._uiMembersDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterMembersResetPaging = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_MEMBERS_RESET_PAGING),
    map((action: MembersActions.ResetMembersPaging) => {
      this._uiMembersDispatchers.getNextMembersPage();
    })
  );

  @Effect({ dispatch: false })
  deleteMember = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_REMOVE_MEMBERS_FROM_CHAT),
    map((action: MembersActions.DeleteUser) => {
      this._socketGateway.sendSocketMessage(
        new RemoveMemberFromChat(action.chatID, action.userID, action.ref)
      );
    })
  );

  @Effect({ dispatch: false })
  banMember = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_BAN_MEMBER_FROM_CHAT),
    map((action: MembersActions.BanUser) => {
      this._socketGateway.sendSocketMessage(
        new BanMemberFromChat(action.chatID, action.userID)
      );
    })
  );

  @Effect({ dispatch: false })
  unBanMember = this.actions$.pipe(
    ofType(MembersUIActionTypes.UI_UNBAN_MEMBER_FROM_CHAT),
    map((action: MembersActions.UnBanUser) => {
      this._socketGateway.sendSocketMessage(
        new UnBanMemberFromChat(action.chatID, action.userID)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiMembersDispatchers: MembersUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
