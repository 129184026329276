import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ICoupon } from 'src/models/ICoupon';
import { Sticky } from 'src/models/MessageParts';
import { BotMenu } from 'src/models/IBotMenu';
import { PrivilegesName } from 'src/models/privileges';

interface LocalStateType {
  showCouponsPool: boolean;
  attachedCoupon: ICoupon;
  showStickyInput: boolean;
  attachedSticky: Sticky;
  showMenusPool: boolean;
  attachedMenu: BotMenu;
}

@Component({
  selector: 'app-select-message-attachs',
  templateUrl: './select-message-attachs.component.html',
  styleUrls: ['./select-message-attachs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageAttachsComponent {
  localState: LocalStateType = {
    showCouponsPool: false,
    attachedCoupon: null,
    showStickyInput: false,
    attachedSticky: null,
    showMenusPool: false,
    attachedMenu: null
  };
  couponEdit: ICoupon = {};
  privilegesName: typeof PrivilegesName = PrivilegesName;
  hoverAttach: boolean;

  constructor() {}

  // Coupos pool events
  toggleCouponsPool(show: boolean) {
    this.localState = { ...this.localState, showCouponsPool: show };
  }
  setCouponEvent(coupon: ICoupon) {
    this.localState = { ...this.localState, attachedCoupon: coupon };
    this.toggleCouponsPool(false);
  }
  resetCouponEvent() {
    this.localState = { ...this.localState, attachedCoupon: null };
  }

  // Menus pool events
  toggleMenusPool(show: boolean) {
    this.localState = { ...this.localState, showMenusPool: show };
  }
  setMenuEvent(menu: BotMenu) {
    this.localState = { ...this.localState, attachedMenu: menu };
    this.toggleMenusPool(false);
  }
  resetMenuEvent() {
    this.localState = { ...this.localState, attachedMenu: null };
  }

  // sticky note events
  toggleStickyInput(show: boolean) {
    this.localState = { ...this.localState, showStickyInput: show };
  }
  setStickyInput(value: Sticky) {
    this.localState = { ...this.localState, attachedSticky: value };
    this.toggleStickyInput(false);
  }
  resetStickyInput() {
    this.localState = { ...this.localState, attachedSticky: null };
  }

  // get values
  getSelectedCoupon(): ICoupon {
    return this.localState.attachedCoupon;
  }
  getSelectedMenu(): BotMenu {
    return this.localState.attachedMenu;
  }
  getStickyInput() {
    return this.localState.attachedSticky;
  }
  toggleHoverAttach(e) {
    this.hoverAttach = e;
  }
}
