<div
  #ScrollContainer
  (scroll)="onScroll($event)"
  class="copyable-item scrollable-body"
>
  <div class="content-scroll-area">
    <app-post-cell
      *ngIf="message"
      [message]="message"
      [chat]="chat"
      [isSubChat]="isSubChat"
      [fixedShare]="true"
      [isPublicChannel]="isPublicChannel"
    >
    </app-post-cell>
    <div class="replies" #messagesContainer>
      <ng-template [ngIf]="message">
        <ng-container
          *ngFor="
            let profile of (profilesRepliedToChannel$ | async)
              ?.channelMemberList;
            trackBy: TrackByFunction;
            let last = last
          "
        >
          <app-reply-cell
            [member]="profile"
            [chatId]="chat?.id"
            [parentPost]="message"
            [isSubChat]="isSubChat"
          >
          </app-reply-cell>
          {{
            last
              ? onLastElement(
                  (profilesRepliedToChannel$ | async)?.totalNumberOfPages
                )
              : ''
          }}
        </ng-container>
      </ng-template>
      <ng-template [ngIf]="!message">
        <ng-container
          *ngFor="
            let profile of (uiService.profilesRepliedToAdmin$ | async)
              ?.channelMemberList;
            trackBy: TrackByFunction;
            let last = last
          "
        >
          <app-reply-cell [member]="profile" [chatId]="chat?.id">
          </app-reply-cell>
          {{
            last
              ? onLastElement(
                  (uiService.profilesRepliedToAdmin$ | async)
                    ?.totalNumberOfPages
                )
              : ''
          }}
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div class="header-scroll-area"></div>
</div>
