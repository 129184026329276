import * as tslib_1 from "tslib";
import { BanMemberFromChat, UnBanMemberFromChat } from './../../../models/IChatMember';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { MembersUiDispatchers } from './membersUI.dispatchers';
import { GetMyMembers, RemoveMemberFromChat } from 'src/models/IChatMember';
import { PageDirection } from 'src/models/constants';
import * as isEqual from 'lodash.isequal';
export class MembersUiEffects {
    constructor(actions$, _socketGateway, _uiMembersDispatchers, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._uiMembersDispatchers = _uiMembersDispatchers;
        this._store = _store;
        this.getNextMembersPage = this.actions$.pipe(ofType("UI_MEMBERS_GET_NEXT_PAGE" /* UI_MEMBERS_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.membersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, membersUiState]) => {
            const action = val;
            const prevRequest = membersUiState.previousRequest;
            if (!(membersUiState.eop === prevRequest.eop &&
                PageDirection.NEXT === prevRequest.direction &&
                membersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMyMembers(membersUiState.status, null, 0, membersUiState.hash, membersUiState.eop, membersUiState.name, membersUiState.searchBy, membersUiState.view, membersUiState.accountTypes));
            }
            else {
                this._uiMembersDispatchers.setStatusAsIdle();
            }
        }));
        this.getPrevMembersPage = this.actions$.pipe(ofType("UI_MEMBERS_GET_PREV_PAGE" /* UI_MEMBERS_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.membersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, membersUiState]) => {
            const action = val;
            const prevRequest = membersUiState.previousRequest;
            if (!(membersUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                membersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMyMembers(membersUiState.status, null, 1, membersUiState.hash, membersUiState.sop, membersUiState.name, membersUiState.searchBy, membersUiState.view, membersUiState.accountTypes));
            }
            else {
                this._uiMembersDispatchers.setStatusAsIdle();
            }
        }));
        this.getMyMembers = this.actions$.pipe(ofType("UI_MY_MEMBERS" /* UI_MY_MEMBERS */), withLatestFrom(this._store
            .select(state => state.membersUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, membersUiState]) => {
            const action = val;
            const prevRequest = membersUiState.previousRequest;
            if (!(membersUiState.sop === prevRequest.sop &&
                PageDirection.PREV === prevRequest.direction &&
                membersUiState.hash === prevRequest.hash)) {
                this._socketGateway.sendSocketMessage(new GetMyMembers(membersUiState.status, null, 1, membersUiState.hash, membersUiState.sop, membersUiState.name, membersUiState.searchBy, membersUiState.view, membersUiState.accountTypes));
            }
            else {
                this._uiMembersDispatchers.setStatusAsIdle();
            }
        }));
        this.afterMembersResetPaging = this.actions$.pipe(ofType("UI_MEMBERS_RESET_PAGING" /* UI_MEMBERS_RESET_PAGING */), map((action) => {
            this._uiMembersDispatchers.getNextMembersPage();
        }));
        this.deleteMember = this.actions$.pipe(ofType("UI_REMOVE_MEMBERS_FROM_CHAT" /* UI_REMOVE_MEMBERS_FROM_CHAT */), map((action) => {
            this._socketGateway.sendSocketMessage(new RemoveMemberFromChat(action.chatID, action.userID, action.ref));
        }));
        this.banMember = this.actions$.pipe(ofType("UI_BAN_MEMBER_FROM_CHAT" /* UI_BAN_MEMBER_FROM_CHAT */), map((action) => {
            this._socketGateway.sendSocketMessage(new BanMemberFromChat(action.chatID, action.userID));
        }));
        this.unBanMember = this.actions$.pipe(ofType("UI_UNBAN_MEMBER_FROM_CHAT" /* UI_UNBAN_MEMBER_FROM_CHAT */), map((action) => {
            this._socketGateway.sendSocketMessage(new UnBanMemberFromChat(action.chatID, action.userID));
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "getNextMembersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "getPrevMembersPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "getMyMembers", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "afterMembersResetPaging", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "deleteMember", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "banMember", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], MembersUiEffects.prototype, "unBanMember", void 0);
