<!-- <div
  [class.projection]="!stayleOnEdit"
  [class.onEdit]="stayleOnEdit"
  *ngIf="!card"
>
  <div class="projection-label" *ngIf="!stayleOnEdit">
    {{ screenType }} Admins
  </div>
  <div
    class="projection-value"
    *ngFor="let mem of chatAdmins$ | async; let i = index"
  >
    <app-profile-image
      *ngIf="i < 5"
      class="projection-avatar"
      [id]="mem?.user_id"
      [chatType]="'Individual'"
      [size]="24"
    >
    </app-profile-image>

    <span class="btn-container" *ngIf="showButton">
      <app-add-btn (btnClicked)="showUsersModalEvent(true)"></app-add-btn>
    </span>
  </div>
  <div class="count" *ngIf="(chatAdmins$ | async).length > 5">
    + {{ (chatAdmins$ | async).length - 5 }}
  </div>
</div> -->

<div [class.projection]="!stayleOnEdit" [class.onEdit]="stayleOnEdit">
  <div class="projection-label" *ngIf="!stayleOnEdit">
    {{ screenType }} Admins
  </div>
  <div class="projection-value">
    <ng-container *ngFor="let mem of admins?.slice(0, 5)">
      <app-profile-image
        class="projection-avatar"
        [id]="mem"
        [chatType]="'Individual'"
        [size]="24"
      >
      </app-profile-image>
    </ng-container>

    <div class="count" *ngIf="admins?.length > 5">
      + {{ admins?.length - 5 }}
    </div>
    <span class="btn-container" *ngIf="showButton">
      <app-add-btn (btnClicked)="showUsersModalEvent(true)"></app-add-btn>
    </span>
  </div>
</div>

<app-modal-container
  *ngIf="usersModal"
  no-scroll
  (cancelNotfiy)="showUsersModalEvent(false)"
>
  <app-list-selector-pool
    class="customer-modal-form"
    [allowSearchBy]="true"
    [searchOptions]="searchOptions"
    [tableService]="membersService"
    [items]="membersService.list$ | async"
    [tableColumns]="tableColumns"
    [title]="'Add Member'"
    [selectedMembers]="selectedMembers"
    (cancelNotfiy)="showUsersModalEvent(false)"
    (submitItemsFormNotfiy)="setSubChatsEvent()"
  >
  </app-list-selector-pool>
</app-modal-container>
