<ng-container *ngIf="subscriptionService?.paymentStore$ | async as payStore">
  <app-modal-container
    *ngIf="!payStore?.plan && !payStore?.canceled_plan"
    (cancelNotfiy)="closeEvent()"
  >
    <app-subscription-price-table
      (closeNotify)="closeEvent()"
      [plans]="payStore?.pricePlans"
      [payStore]="subscriptionService?.paymentStore$ | async"
    >
    </app-subscription-price-table>
  </app-modal-container>

  <app-modal-container
    *ngIf="payStore?.canceled_plan"
    (cancelNotfiy)="closeEvent()"
  >
    <app-subscription-confirm-cancel
      [plan]="payStore?.canceled_plan"
      [url]="payStore?.canceled_url"
      (closeNotify)="closeEvent()"
      (backNotify)="resetPlan()"
    >
    </app-subscription-confirm-cancel>
  </app-modal-container>

  <app-modal-container *ngIf="payStore?.plan" (cancelNotfiy)="closeEvent()">
    <app-subscription-confirm
      [plan]="payStore?.plan"
      [url]="payStore?.url"
      (closeNotify)="closeEvent()"
      (backNotify)="resetPlan()"
    >
    </app-subscription-confirm>
  </app-modal-container>
</ng-container>
