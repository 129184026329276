import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

export const enum EventUIActionTypes {
  UI_LIST_EVENT = 'UI_LIST_EVENT',
  UI_CREATE_EVENT = 'UI_CREATE_EVENT',
  UI_VIEW_EVENT = 'UI_VIEW_EVENT',
  UI_EDIT_EVENT = 'UI_EDIT_EVENT',
  UPLOAD_EVENT_IMAGE = 'UPLOAD_EVENT_IMAGE',
  UI_EVENT_UPLOAD_PHOTO = 'UI_EVENT_UPLOAD_PHOTO',
  UI_EVENT_CREATION_COMPLETE = 'UI_EVENT_CREATION_COMPLETE',
  UI_CLOSE_VIEW_EVENT = 'UI_CLOSE_VIEW_EVENT',
  UI_CLOSE_EDIT_EVENT = 'UI_CLOSE_EDIT_EVENT',
  UI_EVENT_GET_NEXT_PAGE = 'UI_EVENT_GET_NEXT_PAGE',
  UI_EVENT_GET_PREV_PAGE = 'UI_EVENT_GET_PREV_PAGE',
  UI_EVENT_RECEIVED_HISTORY_CHUNK = 'UI_EVENT_RECEIVED_HISTORY_CHUNK',
  UI_EVENT_RESET_PAGING = 'UI_EVENT_RESET_PAGING',
  RESET = 'RESET'
}

export class ListEventUI implements Action {
  readonly type = EventUIActionTypes.UI_LIST_EVENT;
}
export class CreateEventUI implements Action {
  readonly type = EventUIActionTypes.UI_CREATE_EVENT;
}
export class ViewEventUI implements Action {
  readonly type = EventUIActionTypes.UI_VIEW_EVENT;
  constructor(public selectedEvent: IChat) {}
}
export class EditEventUI implements Action {
  readonly type = EventUIActionTypes.UI_EDIT_EVENT;
  constructor(public selectedEvent: IChat) {}
}

/**
 * Create part events
 */
export class UploadEventImage implements Action {
  readonly type = EventUIActionTypes.UPLOAD_EVENT_IMAGE;
  constructor(public selectedFile) {}
}
export class EventPhotoUploadUI implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_UPLOAD_PHOTO;
  constructor(public createdEvent: IChat) {}
}
export class EventCreationComplete implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_CREATION_COMPLETE;
}
/****/

export class CloseViewEventUI implements Action {
  readonly type = EventUIActionTypes.UI_CLOSE_VIEW_EVENT;
}

export class CloseEventEditUI implements Action {
  readonly type = EventUIActionTypes.UI_CLOSE_EDIT_EVENT;
}
// Paging Actions

export class GetNextEventPage implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_GET_NEXT_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

export class GetPrevEventPage implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_GET_PREV_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

// we can detect that by listening to the return of getsubchatlist of type event
export class ReceivedEventHistoryChunk implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChat[]
  ) {}
}

export class ResetEventPaging implements Action {
  readonly type = EventUIActionTypes.UI_EVENT_RESET_PAGING;
  constructor() {}
}

export class Reset implements Action {
  readonly type = EventUIActionTypes.RESET;
}

export type Actions =
  | ListEventUI
  | CreateEventUI
  | ViewEventUI
  | EditEventUI
  | UploadEventImage
  | EventPhotoUploadUI
  | EventCreationComplete
  | CloseViewEventUI
  | CloseEventEditUI
  | GetNextEventPage
  | GetPrevEventPage
  | ReceivedEventHistoryChunk
  | ResetEventPaging
  | Reset;
