import { SwUpdate } from '@angular/service-worker';
import { merge, of, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractNotifiersService } from './abstract-notifiers/abstract-notifiers.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "./abstract-notifiers/abstract-notifiers.service";
export class AppService {
    constructor(updates, abstractNotifiersService) {
        updates.available.subscribe(event => {
            // if (event.available.appData['force_update']) {
            //   window.location.reload();
            // } else {
            abstractNotifiersService.showSnakbarNotifierMessage(event.available.appData['message'], true);
            // }
        });
        updates.activated.subscribe(event => {
            abstractNotifiersService.showSnakbarNotifierMessage(event.current.appData['new_message'], false);
        });
    }
    get online$() {
        return merge(of(navigator.onLine), fromEvent(window, 'online').pipe(map(() => true)), fromEvent(window, 'offline').pipe(map(() => false)));
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.AbstractNotifiersService)); }, token: AppService, providedIn: "root" });
