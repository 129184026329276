import { NgModule, ErrorHandler, Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { SENTRY_DNS, PROJECT_NAME, ENV_NAME } from 'src/models/constants';

Sentry.init({
  environment: (PROJECT_NAME + '-' + ENV_NAME).toLowerCase(),
  dsn: SENTRY_DNS,
  beforeSend(event, hint) {
    /* tslint:disable:no-string-literal only-arrow-functions */
    const isNonErrorException =
      event.exception.values[0].value.startsWith(
        'Non-Error exception captured'
      ) ||
      hint.originalException['message'].startsWith(
        'Non-Error exception captured'
      );
    /* tslint:enable:no-string-literal only-arrow-functions */

    if (isNonErrorException) {
      // We want to ignore those kind of errors
      return null;
    }
    return event;
  }
});
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    }
  ]
})
export class SentryModule {}
