import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as otherTripsAction from './otherDriverTrips.actions';
import { AppState } from 'src/models/AppState';
import * as driverAssigned from 'src/models/DrverAssigned';

@Injectable()
export class OtherTripsEffects {
  // send update to the server after insert in store
  @Effect({ dispatch: false })
  getOtherTrips = this.actions$.pipe(
    ofType(otherTripsAction.otherDriverTripsActionTypes.GET_OTHER_TRIPS),
    map((action: otherTripsAction.GetOtherTrips) => {
      this._socketGateway.sendSocketMessage(
        new driverAssigned.GetOtherTrips(action.trip_id, action.account_id)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _socketGateway: SocketGateway
  ) {}
}
