import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nickName'
})
export class NickNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length > 0) {
      if (value.substring(0, 1).match(/[\w\s]/g)) {
        return value
          .trim()
          .split(/\s+/)
          .slice(0, 2)
          .map(s => s.charAt(0))
          .join('')
          .toLocaleUpperCase()
          .replace(/[^\w\s]/g, '');
      } else {
        if (this.emojiStringToArray(value)) {
          return this.emojiStringToArray(value);
        } else {
          return value
            .trim()
            .replace(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g, '')
            .split(/\s+/)
            .slice(0, 2)
            .map(s => s.charAt(0))
            .join('')
            .toLocaleUpperCase();
        }
      }
    }
    return '';
  }

  emojiStringToArray(str: string) {
    const split = str.split(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g);
    const arr = [];
    for (let i = 0; i < split.length; i++) {
      const char = split[i];
      if (char !== '') {
        arr.push(char);
      }
    }
    if (arr[0].match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g)) {
      return arr[0];
    }
  }
}
