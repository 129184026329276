import * as tslib_1 from "tslib";
import { AppConfigDispatchers } from './appConfig.dispatchers';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppConfigActionTypes } from './appConfig.actions';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { HttpService } from './../../network/services/http.service';
import * as AuthActions from './../auth/auth.actions';
import * as AuthMethods from 'src/models/IAuth';
import { GetConfigRequestBody, SetConfigRequestBody, PublishAppConfig, SetChatDefaults, AppConfig, GetAppTemplate, SetAppProgress, ImageSet, GenerateApp, GetTagsValues, RevokeApp, SystemConfig, SetChannelConfigRequest, WholeApp, CreateAppTemplate, SetAppChannelDataMethod, ChannelData, TagItem, SetAppUserData, SetAppMode, SetInAppPurchases } from 'src/models/ChannelAppConfig';
import { parseAppConfigData, constructAppConfig, getTabKeys, getTabTags } from './appConfig.middleware';
import { AppClassNames, TabTypes, ComponentTypes, PUBLISH_APP, SAVE, REQUEST_CONFIG, GET_COUNTRY_DATA_API, CHANNEL_API, JSON_CONTENT_TYPE, UISections, NO_FILE_NAME, COLORED_LOGO_UPLOAD_TYPE, LOGO_WIDTH, ERROR_NOT_PNG_IMAGE, ERROR_IMAGE_SHOULD_BE_1024X1024, WHITE_LOGO_UPLOAD_TYPE, APP_CONFIG_COMPLETE, GROUP_UPLOAD_TYPE, FILE_SIZE_EXCEEDED, MessageTypes, FAILED_TO_UPLOAD_IMAGE, MessageMediaStatus, COUNTRY_CODE_KEY, TIMEZONE_KEY, APP_REVOKE_TYPES, PUBLISH_CHANNEL, ERROR_NOT_JPG_IMAGE, MimeTypes, uuidv4, FAILED_TO_CREATE_APP_TEMPLATE, ContainerTypes, LOGIN_TAG_TYPE } from 'src/models/constants';
import { Store } from '@ngrx/store';
import { AuthDispatchers } from '../auth/auth.dispatchers';
import { GetToken } from 'src/models/IAuth';
import { StorageService } from 'src/app/core/storage.service';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { ContainerDispatchers } from '../channelAppContainers/container.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import { SetChat, GetConfigQrCode } from 'src/models/IChat';
import { AppLocalInfoDispatchers } from '../channelAppLocalInfo/appLocalInfo.dispatchers';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { SetMyPageConfigRequest, GetMyPageConfigRequest } from 'src/models/MyPageConfig';
import { ItemDispatchers } from '../channelAppItems/item.dispatchers';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import { removeEmptyPageContainers } from '../channelMyPage/myPage.middleware';
import * as isEqual from 'lodash.isequal';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
export class AppConfigEffects {
    constructor(actions$, _appConfigDispatchers, _containerDispatchers, _authDispatchers, _uiDispatchers, _mainChatDispatchers, _socketGateway, _httpService, _localStorage, _fileService, _uploadGateway, _localAppInfoDispatchers, _itemDispatchers, _myPageDispatchers, appManagementService, 
    // private subscription: Subscription,
    _store) {
        this.actions$ = actions$;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._containerDispatchers = _containerDispatchers;
        this._authDispatchers = _authDispatchers;
        this._uiDispatchers = _uiDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this._socketGateway = _socketGateway;
        this._httpService = _httpService;
        this._localStorage = _localStorage;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this._localAppInfoDispatchers = _localAppInfoDispatchers;
        this._itemDispatchers = _itemDispatchers;
        this._myPageDispatchers = _myPageDispatchers;
        this.appManagementService = appManagementService;
        this._store = _store;
        this.requestAppConfig = this.actions$.pipe(ofType("REQUEST_APP_CONFIG" /* REQUEST_APP_CONFIG */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetConfigRequestBody(REQUEST_CONFIG));
        }));
        this.requestAppTemplate = this.actions$.pipe(ofType("REQUEST_APP_TEMPLATE" /* REQUEST_APP_TEMPLATE */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetAppTemplate(action.templateID));
        }));
        this.setProgressWeb = this.actions$.pipe(ofType(AuthActions.SET_APP_PROGRESS), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppProgress(action.progressWeb, action.mode));
        }));
        this.setWebMode = this.actions$.pipe(ofType(AuthActions.SET_APP_MODE), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppMode(action.webMode));
        }));
        this.requestConfigQrCode = this.actions$.pipe(ofType("REQUEST_CONFIG_QR_CODE" /* REQUEST_CONFIG_QR_CODE */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetConfigQrCode());
        }));
        this.appConfigAck = this.actions$.pipe(ofType("APP_CONFIG_ACK" /* APP_CONFIG_ACK */), map((action) => {
            const emptyApp = {};
            if ((action.onlineAppConfig && action.onlineAppConfig.app) ||
                (action.offlineAppConfig && action.offlineAppConfig.app)) {
                this._appConfigDispatchers.receiveAppConfig(action.onlineAppConfig ? action.onlineAppConfig.app : emptyApp, action.offlineAppConfig ? action.offlineAppConfig.app : emptyApp);
            }
            else {
                // const appConf: App = JSON.parse(DEFAULT_APP_CONFIG);
                // this._appConfigDispatchers.receiveDefaultAppConfig(appConf.app);
            }
        }));
        this.appTemplateAck = this.actions$.pipe(ofType("RECEIVE_APP_TEMPLATE" /* RECEIVE_APP_TEMPLATE */), map((action) => {
            const emptyApp = {};
            if ((action.onlineAppConfig && action.onlineAppConfig.app) ||
                (action.offlineAppConfig && action.offlineAppConfig.app)) {
                this._socketGateway.sendSocketMessage(new SetConfigRequestBody(SAVE, action.offlineAppConfig, null, action.onlineAppConfig, null));
                this._appConfigDispatchers.receiveAppConfig(action.onlineAppConfig ? action.onlineAppConfig.app : emptyApp, action.offlineAppConfig ? action.offlineAppConfig.app : emptyApp);
            }
        }));
        this.appConfigReceived = this.actions$.pipe(ofType("RECEIVE_APP_CONFIG" /* RECEIVE_APP_CONFIG */), withLatestFrom(this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, systemConfig]) => {
            const action = val;
            const onlineParsedAppConfig = parseAppConfigData(action.onlineAppConfig, systemConfig, true);
            const offlineParsedAppConfig = parseAppConfigData(action.offlineAppConfig, systemConfig, false);
            this._appConfigDispatchers.appConfigDataChanged(onlineParsedAppConfig, offlineParsedAppConfig);
            //
            if (onlineParsedAppConfig && onlineParsedAppConfig.containers) {
                const pageTabs = onlineParsedAppConfig.containers.filter(cont => cont.type === TabTypes.PAGE);
                if (pageTabs && pageTabs.length > 0) {
                    pageTabs.forEach(pageTab => {
                        if (pageTab.page_id) {
                            this._socketGateway.sendSocketMessage(new GetMyPageConfigRequest(pageTab.page_id));
                        }
                        else if (pageTab.template_id && !pageTab.page_id) {
                            const pageRef = uuidv4();
                            this._containerDispatchers.updateContainer(pageTab.ref, {
                                page_ref: pageRef,
                                template_id: pageTab.template_id
                            });
                            this._myPageDispatchers.createMyPage(pageRef, null, null, null, null, null, pageTab.template_id);
                        }
                    });
                }
            }
            if (offlineParsedAppConfig && offlineParsedAppConfig.items) {
                const pageItems = offlineParsedAppConfig.items.filter(item => item.link === TabTypes.PAGE);
                if (pageItems && pageItems.length > 0) {
                    pageItems.forEach(pageItem => {
                        if (pageItem.page_id) {
                            this._socketGateway.sendSocketMessage(new GetMyPageConfigRequest(pageItem.page_id));
                        }
                        else if (pageItem.template_id && !pageItem.page_id) {
                            const pageRef = uuidv4();
                            this._itemDispatchers.updateItem(pageItem.ref, {
                                page_ref: pageRef,
                                template_id: pageItem.template_id
                            });
                            this._myPageDispatchers.createMyPage(pageRef, null, null, null, null, null, pageItem.template_id);
                        }
                    });
                }
            }
        }));
        this.appConfigColorsChanged = this.actions$.pipe(ofType("UPDATE_APP_COLORS" /* UPDATE_APP_COLORS */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, appClasses, containers, components, items, subItems, systemConfig]) => {
            const action = val;
            const wholeChannelConf = constructAppConfig(appClasses, containers, components, items, subItems, systemConfig, true);
            const wholeAppConf = constructAppConfig(appClasses, containers, components, items, subItems, systemConfig, false);
            const onlineParsedAppConfig = parseAppConfigData(wholeChannelConf.appConfig.app, systemConfig, true, true);
            const offlineParsedAppConfig = parseAppConfigData(wholeAppConf.appConfig.app, systemConfig, false, true);
            this._appConfigDispatchers.appConfigDataChanged(onlineParsedAppConfig, offlineParsedAppConfig);
            this._containerDispatchers.selectFirstContainer(AppClassNames.TABS);
        }));
        this.appDefaultConfigReceived = this.actions$.pipe(ofType("RECEIVE_DEFAULT_APP_CONFIG" /* RECEIVE_DEFAULT_APP_CONFIG */), withLatestFrom(this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, systemConfig]) => {
            const action = val;
            const offlineConfig = parseAppConfigData(action.offlineAppConfig, systemConfig, false);
            const onlineConfig = parseAppConfigData(action.offlineAppConfig, systemConfig, true);
            this._appConfigDispatchers.appConfigDataChanged(onlineConfig, offlineConfig);
        }));
        this.setAppConfig = this.actions$.pipe(ofType("SET_APP_CONFIG" /* SET_APP_CONFIG */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.channelAppUiReducer.currentScreen)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.progressWeb)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.mode)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myPageReducer.filter(page => page.needUpdate))
            .pipe(distinctUntilChanged(isEqual))), map(([val, appClasses, containers, components, items, subItems, sysConfig, currentScreen, progressWeb, mode, pagesNeedUpdate]) => {
            const action = val;
            const tagItems = getTabTags(containers);
            if (sysConfig.app_info &&
                sysConfig.app_info.login_tags_values &&
                sysConfig.app_info.login_tags_values.length > 0) {
                const tagItem = {};
                tagItem.type = LOGIN_TAG_TYPE;
                tagItem.value = sysConfig.app_info.login_tags_values;
                tagItems.push(tagItem);
            }
            if (tagItems && tagItems.length > 0) {
                this._socketGateway.sendSocketMessage(new GetTagsValues(tagItems));
            }
            else {
                if (containers.length > 0) {
                    this.prepareAndSetConfig(appClasses, containers, components, items, subItems, sysConfig, action.channelConfOnly, pagesNeedUpdate, action.modules);
                    this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege('save'));
                }
            }
            if (!action.channelConfOnly &&
                APP_CONFIG_COMPLETE !== `${progressWeb}`) {
                if (progressWeb !== null &&
                    progressWeb > 0 &&
                    action.selectedPageNumber &&
                    mode == 2 &&
                    action.selectedPageNumber > progressWeb) {
                    this._authDispatchers.setAppProgress(action.selectedPageNumber + '', 2);
                }
                else if (progressWeb !== null &&
                    progressWeb > 0 &&
                    currentScreen &&
                    mode == 2 &&
                    currentScreen > progressWeb) {
                    this._authDispatchers.setAppProgress(currentScreen + '', 2);
                }
            }
        }));
        ///
        this.createAppTemplate = this.actions$.pipe(ofType("CREATE_APP_TEMPLATE" /* CREATE_APP_TEMPLATE */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myPageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, appClasses, containers, components, items, subItems, sysConfig, pages]) => {
            const action = val;
            let canCreateAppTemplate = true;
            if (containers) {
                const pageTabs = containers.filter(cont => cont.appClassName === AppClassNames.TABS &&
                    cont.type === TabTypes.PAGE);
                const pagesUsedInTabs = pages.filter(page => {
                    return pageTabs.find(tab => tab.page_id === page.id);
                });
                pagesUsedInTabs.forEach(p => {
                    if ((!p.template_id || p.template_id === '0') &&
                        (!p.content || !(p.content && p.content.childTemplateId))) {
                        canCreateAppTemplate = false;
                    }
                });
            }
            if (items) {
                const pageNavItems = items.filter(item => item.page_id && item.link === TabTypes.PAGE);
                const pagesUsedInNavMenu = pages.filter(page => {
                    return pageNavItems.find(pageItem => pageItem.page_id === page.id);
                });
                pagesUsedInNavMenu.forEach(p => {
                    if ((!p.template_id || p.template_id === '0') &&
                        (!p.content || (p.content && !p.content.childTemplateId))) {
                        canCreateAppTemplate = false;
                    }
                });
            }
            if (canCreateAppTemplate) {
                const result = this.prepareAppTemplate(appClasses, containers, components, items, subItems, sysConfig, pages);
                if (result.appConfig.appConfig.app.system.app_info.payment_provider) {
                    delete result.appConfig.appConfig.app.system.app_info
                        .payment_provider;
                }
                if (result.appConfig.appConfig.app.system.app_info.ads_provider) {
                    delete result.appConfig.appConfig.app.system.app_info.ads_provider;
                }
                // if (
                //   result.appConfig.
                //    &&
                //   result.appConfig.appConfig.app.system.app_info
                // ) {
                //   // delete result.appConfig.appConfig.app.system.app_info.logo_color;
                //   // delete result.appConfig.appConfig.app.system.app_info
                //   //   .logo_color_ios;
                //   // delete result.appConfig.appConfig.app.system.app_info.logo_white;
                //   delete result.appConfig.appConfig.app.system.app_info.image;
                //   delete result.appConfig.appConfig.app.system.app_info.image_id;
                //   // delete result.appConfig.appConfig.app.system.app_info.ios_store_url;
                //   // delete result.appConfig.appConfig.app.system.app_info
                //   //   .white_logo_url;
                // }
                result.appConfig.appConfig.app.splash.container.forEach((cont, index) => {
                    if (index === 0) {
                        delete cont.link;
                    }
                    else {
                        cont.component.forEach(comp => {
                            delete comp.image_set;
                            delete comp.image_url;
                        });
                    }
                });
                result.channelConfig.appConfig.app.tabs.container.forEach(cont => {
                    if (cont.type === TabTypes.MEMBERSHIP) {
                        cont.component.forEach(comp => {
                            delete comp.image_url;
                        });
                    }
                });
                this._socketGateway.sendSocketMessage(new CreateAppTemplate(result.appConfig.appConfig, result.channelConfig.appConfig, action.androidImageUrl, action.iosImageUrl, action.categories, action.name, action.order, action.id));
            }
            else {
                this._uiDispatchers.showPopup(FAILED_TO_CREATE_APP_TEMPLATE);
            }
        }));
        this.getTagsSuccess = this.actions$.pipe(ofType("RECEIVE_TAG_VALUES" /* RECEIVE_TAG_VALUES */), withLatestFrom(this._store
            .select(state => state.appClassReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.containerReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.itemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.subItemReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appConfigReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.myPageReducer.filter(page => page.needUpdate))
            .pipe(distinctUntilChanged(isEqual))), map(([val, appClasses, containers, components, items, subItems, sysConfig, pagesNeedUpdate]) => {
            const action = val;
            const featuresIds = [];
            containers.map(result => {
                if (featuresIds.indexOf(result.ui_module_id) <= -1 &&
                    result.ui_module_id !== null &&
                    result.ui_module_id) {
                    featuresIds.push(result.ui_module_id);
                }
            });
            items.map(result => {
                if (featuresIds.indexOf(result.ui_module_id) <= -1 &&
                    result.ui_module_id !== null &&
                    result.ui_module_id) {
                    featuresIds.push(result.ui_module_id);
                }
            });
            this.prepareAndSetConfig(appClasses, containers, components, items, subItems, sysConfig, action.isChannelConfigOnly, pagesNeedUpdate, featuresIds);
        }));
        this.publishOnlineConfig = this.actions$.pipe(ofType("PUBLISH_APP_CONFIG" /* PUBLISH_APP_CONFIG */), withLatestFrom(this._store
            .select(state => state.authReducer.progressWeb)
            .pipe(distinctUntilChanged(isEqual))), map(([val, progressWeb]) => {
            const action = val;
            if (action.isChannelOnly) {
                this._socketGateway.sendSocketMessage(new PublishAppConfig(PUBLISH_CHANNEL, action.track, action.versionName, action.versionDesc));
            }
            else {
                this._socketGateway.sendSocketMessage(new PublishAppConfig(PUBLISH_APP, action.track, action.versionName, action.versionDesc));
                if (APP_CONFIG_COMPLETE !== `${progressWeb}`) {
                    this._authDispatchers.setAppProgress(APP_CONFIG_COMPLETE, 2);
                }
            }
        }));
        this.generateApp = this.actions$.pipe(ofType("GENERATE_APP" /* GENERATE_APP */), map((action) => {
            // this._appConfigDispatchers.publishAppConfig(false);
            if (action.os) {
                this._socketGateway.sendSocketMessage(new GenerateApp(action.appId, action.os, action.version_name, action.version_desc));
            }
            else {
                this._socketGateway.sendSocketMessage(new GenerateApp(action.appId, action.os, action.version_name, action.version_desc, action.android_format));
            }
        }));
        this.appConfigPublished = this.actions$.pipe(ofType("APP_CONFIG_PUBLISHED" /* APP_CONFIG_PUBLISHED */), withLatestFrom(this._store
            .select(state => state.containerReducer.filter(cont => cont.appClassName === AppClassNames.TABS))
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.componentReducer.filter(comp => comp.appClassName === AppClassNames.TABS &&
            (comp.component_type === ComponentTypes.WALLET ||
                comp.component_type === ComponentTypes.CARD)))
            .pipe(distinctUntilChanged(isEqual))), map(([val, tabContainers, tabComponents]) => {
            const action = val;
            const tabComponentArray = tabComponents.map(comp => {
                const tabComp = {};
                tabComp.component = comp;
                const selectedTab = tabContainers.find(tab => tab.ref === comp.parentContainerRef);
                tabComp.tabID = selectedTab.id;
                if ((comp.component_type === ComponentTypes.WALLET &&
                    selectedTab.type === TabTypes.WALLET) ||
                    (comp.component_type === ComponentTypes.CARD &&
                        selectedTab.type === TabTypes.MEMBERSHIP)) {
                    return tabComp;
                }
            });
            // the following filter to remove undefined values
            const tabkeys = getTabKeys(tabComponentArray.filter(comp => comp));
            this._socketGateway.sendSocketMessage(new SetChatDefaults(tabkeys));
            if (!action.isChannelOnly) {
                this._socketGateway.sendSocketMessage(new RevokeApp(APP_REVOKE_TYPES.APP));
            }
            this._socketGateway.sendSocketMessage(new RevokeApp(APP_REVOKE_TYPES.CHANNEL));
        }));
        // Authentication
        this.getCountryIso = this.actions$.pipe(ofType(AuthActions.GET_COUNTRY_DATA), map((action) => {
            this._httpService.httpGetRequest(GET_COUNTRY_DATA_API).subscribe(res => {
                this._authDispatchers.setCountryData(res);
                if (res.country_code) {
                    this._localStorage.setRecord(COUNTRY_CODE_KEY, res.country_code);
                }
                if (res.timezone) {
                    this._localStorage.setRecord(TIMEZONE_KEY, res.timezone);
                }
            });
        }));
        this.createAccountSuccess = this.actions$.pipe(ofType(AuthActions.CREATE_ACCOUNT_SUCCESS), withLatestFrom(this._store
            .select(state => state.authReducer.email)
            .pipe(distinctUntilChanged(isEqual))), map(([val, email]) => {
            const action = val;
            this._httpService
                .httpPostRequest(this._localStorage.getApiUrl(CHANNEL_API), JSON_CONTENT_TYPE, new GetToken(email, action.password))
                .subscribe(response => {
                if (response.result === 0) {
                    this._authDispatchers.emailTokenReceived(response.token);
                    this._uiDispatchers.setSection(UISections.APP);
                }
            });
        }));
        //
        this.uploadColoredLogo = this.actions$.pipe(ofType("UPLOAD_APP_COLORED_LOGO" /* UPLOAD_APP_COLORED_LOGO */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.section)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, section]) => {
            // const localAppInfo: LocalAppInfo = {};
            // localAppInfo.coloredLogoUploadStatus = MessageMediaStatus.UPLOADING;
            // this._localAppInfoDispatchers.updateAppLocalInfo(localAppInfo);
            const action = val;
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width < LOGO_WIDTH || props.height < 1023) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                                // const appInfo: LocalAppInfo = {};
                                // appInfo.coloredLogoUploadStatus =
                                //   MessageMediaStatus.UPLOAD_FAILED;
                                // this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, COLORED_LOGO_UPLOAD_TYPE, null, null, true, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const res = event.body;
                                        let imageId = '';
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageId = res.files[4].file;
                                            imageUrl = res.files[4].url;
                                        }
                                        if (section !== UISections.CHANNEL) {
                                            const imageSet = {};
                                            imageSet.hdpi = res.files[0].url;
                                            imageSet.mdpi = res.files[1].url;
                                            imageSet.xhdpi = res.files[2].url;
                                            imageSet.xxhdpi = res.files[3].url;
                                            imageSet.xxxhdpi = res.files[4].url;
                                            imageSet.hdpi_rounded = res.files[5].url;
                                            imageSet.mdpi_rounded = res.files[6].url;
                                            imageSet.xhdpi_rounded = res.files[7].url;
                                            imageSet.xxhdpi_rounded = res.files[8].url;
                                            imageSet.xxxhdpi_rounded = res.files[9].url;
                                            imageSet.ios20_1x = res.files[10].url;
                                            imageSet.ios20_2x = res.files[11].url;
                                            imageSet.ios20_3x = res.files[12].url;
                                            imageSet.ios29_1x = res.files[13].url;
                                            imageSet.ios29_2x = res.files[14].url;
                                            imageSet.ios29_3x = res.files[15].url;
                                            imageSet.ios40_2x = res.files[16].url;
                                            imageSet.ios40_3x = res.files[17].url;
                                            imageSet.ios60_3x = res.files[18].url;
                                            imageSet.ios76_1x = res.files[19].url;
                                            imageSet.ios76_2x = res.files[20].url;
                                            imageSet.ios83_2x = res.files[21].url;
                                            imageSet.ios150_1x = res.files[22].url;
                                            imageSet.ios300_2x = res.files[23].url;
                                            imageSet.ios450_3x = res.files[24].url;
                                            // const appInfo: LocalAppInfo = {};
                                            // appInfo.coloredLogoUploadStatus =
                                            //   MessageMediaStatus.UPLOADED;
                                            // this._localAppInfoDispatchers.updateAppLocalInfo(
                                            //   appInfo
                                            // );
                                            // console.log(
                                            //   'after uploading .....',
                                            //   imageId,
                                            //   selectedChat.id,
                                            //   res.files[1].file,
                                            //   imageUrl
                                            // );
                                            this._appConfigDispatchers.uploadAppColoredLogoSuccess(imageId, imageUrl, imageSet, res.dominant_color);
                                            this._mainChatDispatchers.updateMainChatImages(selectedChat.id, imageUrl);
                                            this._appConfigDispatchers.setAppConfig(false);
                                        }
                                        else {
                                            // const appInfo: LocalAppInfo = {};
                                            // appInfo.coloredLogoUploadStatus =
                                            //   MessageMediaStatus.UPLOADED;
                                            // this._localAppInfoDispatchers.updateAppLocalInfo(
                                            //   appInfo
                                            // );
                                            this._mainChatDispatchers.updateMainChatImages(selectedChat.id, imageUrl);
                                            this._appConfigDispatchers.setAppConfig(true);
                                        }
                                    }
                                    this._appConfigDispatchers.uploadAppChatImage(action.selectedFile);
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                    const appInfo = {};
                                    appInfo.coloredLogoUploadStatus =
                                        MessageMediaStatus.UPLOAD_FAILED;
                                    this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                });
                            }
                        });
                    });
                });
            }
            else {
                const appInfo = {};
                appInfo.coloredLogoUploadStatus = MessageMediaStatus.UPLOAD_FAILED;
                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
            }
        }));
        this.getDomainatColor = this.actions$.pipe(ofType("GET_DOMAINAT_COLOR" /* GET_DOMAINAT_COLOR */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.section)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, section]) => {
            const localAppInfo = {};
            localAppInfo.coloredLogoUploadStatus = MessageMediaStatus.UPLOADING;
            this._localAppInfoDispatchers.updateAppLocalInfo(localAppInfo);
            const action = val;
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width < LOGO_WIDTH || props.height < 1023) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                                const appInfo = {};
                                appInfo.coloredLogoUploadStatus =
                                    MessageMediaStatus.UPLOAD_FAILED;
                                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, COLORED_LOGO_UPLOAD_TYPE, null, null, true, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const res = event.body;
                                        let imageId = '';
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageId = res.files[4].file;
                                            imageUrl = res.files[4].url;
                                        }
                                        const imageSet = {};
                                        imageSet.hdpi = res.files[0].url;
                                        imageSet.mdpi = res.files[1].url;
                                        imageSet.xhdpi = res.files[2].url;
                                        imageSet.xxhdpi = res.files[3].url;
                                        imageSet.xxxhdpi = res.files[4].url;
                                        imageSet.hdpi_rounded = res.files[5].url;
                                        imageSet.mdpi_rounded = res.files[6].url;
                                        imageSet.xhdpi_rounded = res.files[7].url;
                                        imageSet.xxhdpi_rounded = res.files[8].url;
                                        imageSet.xxxhdpi_rounded = res.files[9].url;
                                        imageSet.ios20_1x = res.files[10].url;
                                        imageSet.ios20_2x = res.files[11].url;
                                        imageSet.ios20_3x = res.files[12].url;
                                        imageSet.ios29_1x = res.files[13].url;
                                        imageSet.ios29_2x = res.files[14].url;
                                        imageSet.ios29_3x = res.files[15].url;
                                        imageSet.ios40_2x = res.files[16].url;
                                        imageSet.ios40_3x = res.files[17].url;
                                        imageSet.ios60_3x = res.files[18].url;
                                        imageSet.ios76_1x = res.files[19].url;
                                        imageSet.ios76_2x = res.files[20].url;
                                        imageSet.ios83_2x = res.files[21].url;
                                        imageSet.ios150_1x = res.files[22].url;
                                        imageSet.ios300_2x = res.files[23].url;
                                        imageSet.ios450_3x = res.files[24].url;
                                        // if (section !== UISections.CHANNEL) {
                                        //   this._appConfigDispatchers.UpdateDominatColor(
                                        //     res.dominant_color
                                        //   );
                                        // }
                                        // imageUrl = res.files[4].url;
                                        const appInfo = {};
                                        appInfo.coloredLogoUploadStatus =
                                            MessageMediaStatus.UPLOADED;
                                        this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                        // const newAppInfo: AppInfo = {};
                                        // newAppInfo.image = imageUrl;
                                        // this._appConfigDispatchers.updateAppInfo(
                                        //   newAppInfo
                                        // );
                                        const appIcon = {};
                                        appIcon.image = imageUrl;
                                        appIcon.dominantColor = res.dominant_color;
                                        appIcon.imageId = imageId;
                                        appIcon.imageSet = imageSet;
                                        this.appManagementService._appIcon.next(appIcon);
                                        this._mainChatDispatchers.updateMainChatImages(selectedChat.id, imageUrl);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                    const appInfo = {};
                                    appInfo.coloredLogoUploadStatus =
                                        MessageMediaStatus.UPLOAD_FAILED;
                                    this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                });
                            }
                        });
                    });
                });
            }
            else {
                const appInfo = {};
                appInfo.coloredLogoUploadStatus = MessageMediaStatus.UPLOAD_FAILED;
                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
            }
        }));
        this.uploadAppChatIcon = this.actions$.pipe(ofType("UPLOAD_APP_CHAT_ICON" /* UPLOAD_APP_CHAT_ICON */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.section)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, section]) => {
            const action = val;
            this._fileService
                .readFileAsArrayBuffer(action.selectedFile.localFile)
                .then(fileAsArrayBuffer => {
                this._fileService
                    .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                    .then(blobUrl => {
                    ////////////////// Upload the image and set it to the channel
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, GROUP_UPLOAD_TYPE, null, selectedChat.id, true)
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            const chat = {};
                            const photo = { id: res.file };
                            chat.id = selectedChat.id;
                            chat.photo = photo;
                            this._socketGateway.sendSocketMessage(new SetChat(chat));
                        }
                    }, error => {
                        console.log('Failed to upload chat image');
                    });
                });
            });
        }));
        this.uploadAppIcon = this.actions$.pipe(ofType("UPLOAD_APP_ICON" /* UPLOAD_APP_ICON */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.section)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, section]) => {
            const action = val;
            this._fileService
                .readFileAsArrayBuffer(action.selectedFile.localFile)
                .then(fileAsArrayBuffer => {
                this._fileService
                    .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                    .then(blobUrl => {
                    ////////////////// Upload the image and set it to the channel
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, GROUP_UPLOAD_TYPE, null, selectedChat.id, true)
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            const chat = {};
                            const photo = { id: res.file };
                            chat.id = selectedChat.id;
                            chat.photo = photo;
                            const iconOption = this.appManagementService.appIcon$.pipe();
                            // console.log('here icon option ', iconOption.source.value);
                            let iconData = {};
                            iconData = iconOption.source.value;
                            this._appConfigDispatchers.uploadAppColoredLogoSuccess(iconData.imageId, iconData.image, iconData.imageSet, iconData.dominantColor);
                            this._appConfigDispatchers.setAppConfig(false);
                            this._socketGateway.sendSocketMessage(new SetChat(chat));
                        }
                    }, error => {
                        console.log('Failed to upload chat image');
                    });
                });
            });
        }));
        this.uploadTempImage = this.actions$.pipe(ofType("UPLOAD_TEMP_IMAGE_PROFILE" /* UPLOAD_TEMP_IMAGE_PROFILE */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.appInfoReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat, appInfo]) => {
            const action = val;
            if (action.imageId) {
                const chat = {};
                const photo = { id: action.imageId };
                chat.id = selectedChat.id;
                chat.photo = photo;
                this._socketGateway.sendSocketMessage(new SetChat(chat));
            }
        }));
        this.uploadWhiteLogo = this.actions$.pipe(ofType("UPLOAD_APP_WHITE_LOGO" /* UPLOAD_APP_WHITE_LOGO */), map((action) => {
            const localAppInfo = {};
            localAppInfo.whiteLogoUploadStatus = MessageMediaStatus.UPLOADING;
            this._localAppInfoDispatchers.updateAppLocalInfo(localAppInfo);
            if (this.validateImage(action.selectedFile)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width < LOGO_WIDTH || props.height < 1023) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                                const appInfo = {};
                                appInfo.whiteLogoUploadStatus =
                                    MessageMediaStatus.UPLOAD_FAILED;
                                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, WHITE_LOGO_UPLOAD_TYPE, null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const appInfo = {};
                                        appInfo.whiteLogoUploadStatus =
                                            MessageMediaStatus.UPLOADED;
                                        this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                        const res = event.body;
                                        let imageUrl = '';
                                        if (res.files[4]) {
                                            imageUrl = res.files[4].url;
                                        }
                                        const imageSet = {};
                                        imageSet.hdpi = res.files[0].url;
                                        imageSet.mdpi = res.files[1].url;
                                        imageSet.xhdpi = res.files[2].url;
                                        imageSet.xxhdpi = res.files[3].url;
                                        imageSet.xxxhdpi = res.files[4].url;
                                        imageSet.ios16_1x = res.files[5].url;
                                        imageSet.ios32_2x = res.files[6].url;
                                        imageSet.ios48_3x = res.files[7].url;
                                        this._appConfigDispatchers.uploadAppWhiteLogoSuccess(imageUrl, imageSet);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                    const appInfo = {};
                                    appInfo.whiteLogoUploadStatus =
                                        MessageMediaStatus.UPLOAD_FAILED;
                                    this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                });
                            }
                        });
                    });
                });
            }
            else {
                const appInfo = {};
                appInfo.whiteLogoUploadStatus = MessageMediaStatus.UPLOAD_FAILED;
                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
            }
        }));
        this.setApp = this.actions$.pipe(ofType("SET_APP" /* SET_APP */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetAppUserData(action.userData));
        }));
        this.uploadIosStoreLogo = this.actions$.pipe(ofType("UPLOAD_IOS_STORE_LOGO" /* UPLOAD_IOS_STORE_LOGO */), map((action) => {
            const localAppInfo = {};
            localAppInfo.iosStoreLogoUploadStatus = MessageMediaStatus.UPLOADING;
            this._localAppInfoDispatchers.updateAppLocalInfo(localAppInfo);
            if (this.validateImage(action.selectedFile, MimeTypes.JPG)) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._fileService
                        .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.selectedFile.type)
                        .then(blobUrl => {
                        this._fileService.getImageMetadata(blobUrl).then(props => {
                            if (props.width < LOGO_WIDTH || props.height < 1023) {
                                this._uiDispatchers.showPopup(ERROR_IMAGE_SHOULD_BE_1024X1024);
                                const appInfo = {};
                                appInfo.iosStoreLogoUploadStatus =
                                    MessageMediaStatus.UPLOAD_FAILED;
                                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                            }
                            else {
                                this._uploadGateway
                                    .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, '4', null, null, true)
                                    .subscribe(event => {
                                    if (event.type === HttpEventType.Response) {
                                        const appInfo = {};
                                        appInfo.iosStoreLogoUploadStatus =
                                            MessageMediaStatus.UPLOADED;
                                        this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                        const res = event.body;
                                        const imageUrl = res.url;
                                        this._appConfigDispatchers.uploadIosStoreLogoSuccess(imageUrl);
                                    }
                                }, error => {
                                    this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                                    const appInfo = {};
                                    appInfo.iosStoreLogoUploadStatus =
                                        MessageMediaStatus.UPLOAD_FAILED;
                                    this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
                                });
                            }
                        });
                    });
                });
            }
            else {
                const appInfo = {};
                appInfo.iosStoreLogoUploadStatus = MessageMediaStatus.UPLOAD_FAILED;
                this._localAppInfoDispatchers.updateAppLocalInfo(appInfo);
            }
        }));
        this.setInAppPurchases = this.actions$.pipe(ofType("SET_IN_APP_PURCHASES" /* SET_IN_APP_PURCHASES */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetInAppPurchases(action.json));
        }));
    }
    validateImage(selectedFile, imageType) {
        const imgType = imageType ? imageType : MimeTypes.PNG;
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (selectedFile.localFile.type.indexOf(imgType) === -1) {
            if (imgType === MimeTypes.PNG) {
                this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
            }
            else if (imgType === MimeTypes.JPG) {
                this._uiDispatchers.showPopup(ERROR_NOT_JPG_IMAGE);
            }
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        return true;
    }
    prepareAndSetConfig(appClasses, containers, components, items, subItems, sysConfig, isChannelConfOnly, pagesToBeUpdated, modules) {
        if (pagesToBeUpdated && pagesToBeUpdated.length > 0) {
            pagesToBeUpdated.forEach(page => {
                const updatedPage = removeEmptyPageContainers(page);
                this._socketGateway.sendSocketMessage(new SetMyPageConfigRequest(updatedPage.id, updatedPage.content, updatedPage.name, updatedPage.url, updatedPage.image, updatedPage.desc));
            });
        }
        let contactPermission = 0;
        const contactTabs = containers.filter(cont => cont.appClassName === AppClassNames.TABS &&
            cont.container_type === ContainerTypes.TAB &&
            TabTypes.MYLIST === cont.type &&
            'contact' === cont.sub_type);
        const contactMenus = items.filter(item => item.appClassName === AppClassNames.NAV_MENU &&
            item.link === TabTypes.MYLIST &&
            'contact' === item.sub_type);
        contactPermission =
            (contactTabs && contactTabs.length > 0) ||
                (contactMenus && contactMenus.length > 0)
                ? 1
                : 0;
        const wholeChannelConf = constructAppConfig(appClasses, containers, components, items, subItems, sysConfig, true);
        if (!isChannelConfOnly) {
            const wholeAppConf = constructAppConfig(appClasses, containers, components, items, subItems, sysConfig, false, contactPermission);
            this._socketGateway.sendSocketMessage(new SetConfigRequestBody(SAVE, wholeAppConf.appConfig, wholeChannelConf.channelAppConfig, wholeChannelConf.appConfig, wholeAppConf.onlineAppConfig, modules));
        }
        else {
            this._socketGateway.sendSocketMessage(new SetChannelConfigRequest(wholeChannelConf.appConfig, wholeChannelConf.channelAppConfig, wholeChannelConf.appConfig, modules));
        }
        // set channel data //wholeChannelConf.appConfig
        if (wholeChannelConf.appConfig &&
            wholeChannelConf.appConfig.app &&
            wholeChannelConf.appConfig.app.search_category) {
            const data = [];
            wholeChannelConf.appConfig.app.search_category.container.forEach(cont => {
                const dataRecord = {};
                dataRecord.key = cont.type;
                dataRecord.title = cont.title;
                dataRecord.icon = cont.icon;
                dataRecord.list = cont.list ? cont.list : [];
                data.push(dataRecord);
            });
            this._socketGateway.sendSocketMessage(new SetAppChannelDataMethod(data));
        }
        //
    }
    ///////////////////
    prepareAppTemplate(appClasses, containers, components, items, subItems, sysConfig, pages) {
        if (pages) {
            const pagesToBeUpdated = pages.filter(p => p.needUpdate);
            if (pagesToBeUpdated && pagesToBeUpdated.length > 0) {
                pagesToBeUpdated.forEach(page => this._socketGateway.sendSocketMessage(new SetMyPageConfigRequest(page.id, page.content, page.name, page.url, page.image, page.desc)));
            }
        }
        const updatedContainers = containers.map(cont => {
            if (cont.page_id && cont.type === TabTypes.PAGE) {
                const targetPage = pages.find(p => p.id === cont.page_id);
                const updatedContainer = Object.assign({}, cont);
                if (!updatedContainer.template_id && targetPage) {
                    updatedContainer.template_id =
                        targetPage.template_id && targetPage.template_id !== '0'
                            ? targetPage.template_id
                            : targetPage.content.childTemplateId;
                }
                delete updatedContainer.page_id;
                delete updatedContainer.page_ref;
                delete updatedContainer.url;
                return updatedContainer;
            }
            return cont;
        });
        const updatedItems = items.map(item => {
            if (item.page_id && item.link === TabTypes.PAGE) {
                const targetPage = pages.find(p => p.id === item.page_id);
                const updatedItem = Object.assign({}, item);
                if (!updatedItem.template_id) {
                    updatedItem.template_id =
                        targetPage.template_id && targetPage.template_id !== '0'
                            ? targetPage.template_id
                            : targetPage.content.childTemplateId;
                }
                delete updatedItem.page_id;
                delete updatedItem.page_ref;
                delete updatedItem.url;
                return updatedItem;
            }
            return item;
        });
        const wholeChannelConf = constructAppConfig(appClasses, updatedContainers, components, updatedItems, subItems, sysConfig, true);
        const wholeAppConf = constructAppConfig(appClasses, updatedContainers, components, updatedItems, subItems, sysConfig, false);
        return {
            appConfig: wholeAppConf,
            channelConfig: wholeChannelConf
        };
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "requestAppConfig", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "requestAppTemplate", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "setProgressWeb", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "setWebMode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "requestConfigQrCode", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appConfigAck", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appTemplateAck", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appConfigReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appConfigColorsChanged", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appDefaultConfigReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "setAppConfig", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "createAppTemplate", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "getTagsSuccess", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "publishOnlineConfig", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "generateApp", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "appConfigPublished", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "getCountryIso", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "createAccountSuccess", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadColoredLogo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "getDomainatColor", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadAppChatIcon", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadAppIcon", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadTempImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadWhiteLogo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "setApp", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "uploadIosStoreLogo", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], AppConfigEffects.prototype, "setInAppPurchases", void 0);
