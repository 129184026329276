import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, take } from 'rxjs/operators';

import { PaymentDispatchers } from './payment.dispatchers';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { PlansGateway } from 'src/app/network/gateway/plans.gateway';

import * as PaymentActions from './payment.actions';
import * as PaymentMehods from 'src/models/IPayment';
import * as AuthMethods from 'src/models/IAuth';

@Injectable()
export class PaymentEffects {
  @Effect({ dispatch: false })
  getPlans = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.GET_PRICE_PLANS),
    map(() => {
      this._plansGateway.getAllPlans().subscribe(res => {
        const plans = [];
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            plans.push(res[key]);
          }
        }
        this._paymentDispatchers.setPricePlans(plans);
      });
    })
  );

  @Effect({ dispatch: false })
  rcvdPaymentAuthInfo = this.actions$.pipe(
    ofType(
      PaymentActions.PaymentActionTypes.RCVD_PAYMENT_AUTH_INFO,
      PaymentActions.PaymentActionTypes.SET_PAYMENT_INFO
    ),
    map((action: PaymentActions.SetPaymentInfo) => {
      if (action.payload && action.payload.plan_id) {
        this._plansGateway
          .getPlan(action.payload.plan_id)
          .pipe(take(1))
          .subscribe(res => {
            this._paymentDispatchers.setCurrentPayment(res);
          });
      }
    })
  );

  @Effect({ dispatch: false })
  paymentSet = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.PAYMENT_SET),
    map(() => {
      this._paymentDispatchers.getPaymentInfo();
      this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
    })
  );

  @Effect({ dispatch: false })
  freepaymentSet = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.SET_SELECTED_FREE_PAYMENT),
    map(() => {
      this._socketGateway.sendSocketMessage(new PaymentMehods.SetFreePayment());
    })
  );
  @Effect({ dispatch: false })
  freepaymentCancel = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.SET_CANCELED_FREE_PAYMENT),
    map(() => {
      this._socketGateway.sendSocketMessage(
        new PaymentMehods.CancelFreePayment()
      );
    })
  );

  @Effect({ dispatch: false })
  getPaymentInfo = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.GET_PAYMENT_INFO),
    map(() => {
      this._socketGateway.sendSocketMessage(new PaymentMehods.GetPaymentInfo());
    })
  );

  @Effect({ dispatch: false })
  getPaymentToken = this.actions$.pipe(
    ofType(PaymentActions.PaymentActionTypes.GET_PAYMENT_TOKEN),
    map(() => {
      this._socketGateway.sendSocketMessage(
        new PaymentMehods.GetPaymentToken()
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _plansGateway: PlansGateway,
    private _paymentDispatchers: PaymentDispatchers
  ) {}
}
