<!-- Sidebar -->
<div class="item header clickable-cursor" (click)="toggleMenuEvent()">
  <img
    *ngIf="!isOpen"
    alt="nandbox logo"
    src="./assets/img/logo.svg"
    height="40"
  />
  <img
    *ngIf="isOpen"
    alt="nandbox logo"
    src="./assets/img/logo-w-text.svg"
    height="40"
  />
</div>

<!-- Sections & SubSections -->
<div *ngFor="let section of layoutService?.appMenu">
  <ng-container *appHideIfMainChat="section?.hideOnMain">
    <ng-container *appHideIfSubChat="section?.hideOnSub">
      <ng-container
        *appHideIfSubChat="
          section?.section === uiSections?.CHANNEL &&
          !(authService?.authCollection$ | async)?.is_nandbox
        "
      >
        <ng-container *appShowIfHasPrivilege="section?.privilegeName">
          <ng-container *ngIf="section?.isCategory">
            <div class="sec-category">
              {{ section.title }}
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!section.isCategory">
    <ng-container *appHideIfMainChat="section?.hideOnMain">
      <ng-container *appShowIfHasPrivilege="section?.privilegeName">
        <ng-container *appHideIfSubChat="section?.hideOnSub">
          <ng-container
            *appHideIfSubChat="
              (section?.section === uiSections?.CHANNEL &&
                !(authService?.authCollection$ | async)?.is_nandbox) ||
              (section?.section === uiSections?.CHANNEL &&
                (authService?.authCollection$ | async)?.is_nandbox &&
                (authService?.authCollection$ | async)?.role !== 2)
            "
          >
            <div
              class="item list clickable-cursor"
              [class.cat-item]="section.hasCategory"
              [title]="section.title"
              [class.active]="isActive(section)"
              [ngStyle]="setItemStyle(section)"
            >
              <div
                class="item-inner"
                (click)="sectionEvent(section.section, null)"
              >
                <svg
                  class="svg-icon icon-icon"
                  [style.fill]="isActive(section) ? section.symbolColor : ''"
                >
                  <use
                    attr.xlink:href="./assets/svg/app-sections.svg#{{
                      section.symbolImage
                    }}"
                  ></use>
                </svg>
                <div *ngIf="isOpen" class="core">
                  {{ section.title }}
                </div>

                <div class="full-w"></div>

                <ng-container *ngIf="section?.subMenu">
                  <div
                    *ngIf="section.showSubMenu"
                    class="fas fa-chevron-up clickable-cursor"
                    (click)="section.showSubMenu = !section.showSubMenu"
                  ></div>
                  <div
                    *ngIf="!section.showSubMenu"
                    class="fas fa-chevron-down clickable-cursor"
                    (click)="section.showSubMenu = !section.showSubMenu"
                  ></div>
                </ng-container>
              </div>

              <ul
                *ngIf="isOpen && section?.subMenu && section.showSubMenu"
                class="sub-inner"
              >
                <li
                  *ngFor="let subMenu of section.subMenu"
                  class="clickable-cursor"
                  [class.active]="
                    setSubMenuActive(subMenu.section, subMenu.subMenuScreen)
                  "
                  [ngClass]="{
                    branding: 1 === subMenu.subMenuScreen,
                    splash: 2 === subMenu.subMenuScreen,
                    login: 3 === subMenu.subMenuScreen,
                    profile: 4 === subMenu.subMenuScreen,
                    sidemenu: 5 === subMenu.subMenuScreen,
                    home: 6 === subMenu.subMenuScreen,
                    features: 7 === subMenu.subMenuScreen,
                    releases: 8 === subMenu.subMenuScreen,
                    publish: 9 === subMenu.subMenuScreen,
                    activation: 10 === subMenu.subMenuScreen,
                    indexdata: 11 === subMenu.subMenuScreen
                  }"
                  (click)="sectionEvent(subMenu.section, subMenu.subMenuScreen)"
                >
                  {{ subMenu?.title }}
                </li>
              </ul>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
