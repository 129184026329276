import { Store } from '@ngrx/store';
import * as BookingUIActions from './bookingUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BookingUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listBookingUI() {
        this._store.dispatch(new BookingUIActions.ListBookingUI());
    }
    createBookingUI() {
        this._store.dispatch(new BookingUIActions.CreateBookingUI());
    }
    viewBookingUI(selectedBooking) {
        this._store.dispatch(new BookingUIActions.ViewBookingUI(selectedBooking));
    }
    editBookingUI(selectedBooking) {
        this._store.dispatch(new BookingUIActions.EditBookingUI(selectedBooking));
    }
    uploadBookingImage(booking) {
        this._store.dispatch(new BookingUIActions.UploadBookingImage(booking));
    }
    bookingPhotoUploadUI(createdBooking) {
        this._store.dispatch(new BookingUIActions.BookingPhotoUploadUI(createdBooking));
    }
    bookingCreationComplete() {
        this._store.dispatch(new BookingUIActions.BookingCreationComplete());
    }
    showReservations(selectedBooking) {
        this._store.dispatch(new BookingUIActions.ShowReservationsUI(selectedBooking));
    }
    closeViewBookingUI() {
        this._store.dispatch(new BookingUIActions.CloseViewBookingUI());
    }
    closeEditBookingUI() {
        this._store.dispatch(new BookingUIActions.CloseEditBookingUI());
    }
    // paging dispatchers
    getNextBookingPage() {
        this._store.dispatch(new BookingUIActions.GetNextBookingPage());
    }
    getPrevBookingPage() {
        this._store.dispatch(new BookingUIActions.GetPrevBookingPage());
    }
    receivedBookingHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new BookingUIActions.ReceivedBookingHistoryChunk(eop, sop, currentPage));
    }
    setEnableService(config) {
        this._store.dispatch(new BookingUIActions.SetEnableService(config));
    }
    resetBookingPaging() {
        this._store.dispatch(new BookingUIActions.ResetBookingPaging());
    }
    setChat(chat) {
        this._store.dispatch(new BookingUIActions.SetChat(chat));
    }
}
BookingUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingUiDispatchers_Factory() { return new BookingUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: BookingUiDispatchers, providedIn: "root" });
