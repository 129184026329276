/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lang-selector.component";
import * as i4 from "@ngx-translate/core";
var styles_LagSelectorComponent = [i0.styles];
var RenderType_LagSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LagSelectorComponent, data: {} });
export { RenderType_LagSelectorComponent as RenderType_LagSelectorComponent };
function View_LagSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "lang"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "cat-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "arrow"], ["src", "./assets/img/arrow.png"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.img; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.value; _ck(_v, 5, 0, currVal_1); }); }
function View_LagSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "drp-list"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagSelectorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.key === _co.selectedLang); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LagSelectorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "arrow"], ["src", "./assets/img/selectedSection.png"]], null, null, null, null, null))], null, null); }
function View_LagSelectorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "list-item"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeBrowserLang(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "lang"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "cat-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagSelectorComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_v.context.$implicit.key === _co.selectedLang); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.key === _co.selectedLang); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.img; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.value; _ck(_v, 6, 0, currVal_2); }); }
function View_LagSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagSelectorComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LagSelectorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagSelectorComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LagSelectorComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langs; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showLangMenu; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LagSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lang-selector", [], null, null, null, View_LagSelectorComponent_0, RenderType_LagSelectorComponent)), i1.ɵdid(1, 638976, null, 0, i3.LagSelectorComponent, [i4.TranslateService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LagSelectorComponentNgFactory = i1.ɵccf("app-lang-selector", i3.LagSelectorComponent, View_LagSelectorComponent_Host_0, { selectedLang: "selectedLang" }, {}, []);
export { LagSelectorComponentNgFactory as LagSelectorComponentNgFactory };
