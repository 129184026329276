import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-assign-text-action',
  templateUrl: './assign-text-action.component.html',
  styleUrls: ['./assign-text-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignTextActionComponent {
  @Input() action: any;
  @Input() title: string;
  @Input() hideRemove: boolean = false;
  @Output() remove = new EventEmitter<any>();

  constructor() {}

  delete() {
    this.remove.emit(this.action);
  }
}
