import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { AbstractNotifiersService } from './abstract-notifiers/abstract-notifiers.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    updates: SwUpdate,
    abstractNotifiersService: AbstractNotifiersService
  ) {
    updates.available.subscribe(event => {
      // if (event.available.appData['force_update']) {
      //   window.location.reload();
      // } else {
      abstractNotifiersService.showSnakbarNotifierMessage(
        event.available.appData['message'],
        true
      );
      // }
    });
    updates.activated.subscribe(event => {
      abstractNotifiersService.showSnakbarNotifierMessage(
        event.current.appData['new_message'],
        false
      );
    });
  }

  get online$(): Observable<boolean> {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    );
  }
}
