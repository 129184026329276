import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

import { NgxModule } from 'src/app/ngx.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { NavBarComponent } from './navbar/navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NavbarPagenameComponent } from './navbar-pagename/navbar-pagename.component';
import { LayoutComponent } from './layout.component';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    NgxModule,
    SharedModule,
    AccessorsModule
  ],
  declarations: [
    NavBarComponent,
    SideMenuComponent,
    BreadcrumbsComponent,
    NavbarPagenameComponent,
    LayoutComponent,
    ConfirmInfoComponent
  ],
  exports: [BreadcrumbsComponent, LayoutComponent]
})
export class LayoutModule {}
