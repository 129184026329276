import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-compose-schedule-input',
  templateUrl: './compose-schedule-input.component.html',
  styleUrls: ['./compose-schedule-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeScheduleInputComponent implements OnInit {
  isDatePickerKeyboardOpen = false;
  placement = 'bottom';
  minDate: Date = new Date();
  scheduleDate: Date = new Date();
  schedule_date: number = null;
  datePickerConfig = {
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    minDate: new Date()
  };

  @Output() isScheduleDateValid = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit() {}

  setScheduleTimeMessage() {
    this.schedule_date = this.scheduleDate.getTime();
    this.toggleDatePickerKeyboard(false);
    this.isScheduleDateValid.emit(true);
  }

  toggleDatePickerKeyboard(value: boolean): void {
    this.isDatePickerKeyboardOpen = value;
  }

  resetScheduleDate() {
    this.schedule_date = null;
    this.toggleDatePickerKeyboard(false);
    this.isScheduleDateValid.emit(false);
  }

  getScheduleDate() {
    return this.schedule_date;
  }

  get isScheduleValid() {
    return this.scheduleDate.getTime() > new Date().getTime();
  }
}
