<div
  (click)="mediaClickedEvent()"
  class="media-container"
  [class.full-screen]="isFullScreen"
  [style.width.px]="width"
  [style.height.px]="height"
  [class.caption]="caption"
>
  <!-- loader for downloading/uploading or failed -->
  <div class="loader-container" appCancelEvent>
    <div
      class="loader-t"
      *ngIf="
        mediaStatus === messageMediaStatus.UPLOADING ||
        mediaStatus === messageMediaStatus.DOWNLOADING
      "
    >
      <svg-icon
        class="cancel"
        src="./assets/img/close-white.svg"
        [stretch]="true"
        [svgStyle]="{ 'width.px': 14, fill: '#FFF' }"
        (click)="cancelEvent()"
      >
      </svg-icon>
      <div *ngIf="!percent" class="loader" [ngStyle]="them"></div>
      <circle-progress
        *ngIf="percent"
        [percent]="percent"
        [outerStrokeColor]="themeColor"
      ></circle-progress>
    </div>
    <div
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOAD_FAILED ||
        mediaStatus === messageMediaStatus.UPLOAD_FAILED
      "
      (click)="retryEvent()"
      class="reprocess"
    >
      <i
        class="fas"
        [class.fa-long-arrow-alt-up]="
          mediaStatus === messageMediaStatus.UPLOAD_FAILED
        "
        [class.fa-long-arrow-alt-down]="
          mediaStatus === messageMediaStatus.DOWNLOAD_FAILED
        "
        aria-hidden="true"
      >
      </i>
      {{ fileSize | fileSize }}
    </div>
  </div>
  <!-- End: Loader -->

  <!-- Start: NotFullScreen -->

  <!-- Display thumbnail if exist in case of not fullScreen -->
  <img
    *ngIf="thumbnail && !isFullScreen"
    [src]="thumbnail"
    class="media-center cropped img-blur"
  />

  <!-- Display media if exist in case of not fullScreen -->
  <img
    *ngIf="localMedia && !isFullScreen"
    [src]="media"
    class="media-center fit"
  />
  <!-- End: NotFullScrren -->

  <!-- Start: FullScreen -->

  <!-- Display thumbnail if exist and no local media -->
  <img
    *ngIf="thumbnail && !localMedia && isFullScreen"
    [src]="thumbnail"
    alt=""
    appCancelEvent
    class="media img-blur"
  />

  <!-- Image in FullScreen -->
  <img
    *ngIf="localMedia && isFullScreen"
    [src]="media"
    appCancelEvent
    class="media"
  />
  <!-- End: Image FullScreen -->

  <!-- End: FullScreen -->

  <ng-content></ng-content>
</div>

<app-text-cell
  *ngIf="caption"
  class="caption-box wrap-text copyable-item text-cursor"
  dir="auto"
  [text]="caption"
  [style.width.px]="width"
>
</app-text-cell>
