import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-hover-tooltip',
  templateUrl: './hover-tooltip.component.html',
  styleUrls: ['./hover-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoverTooltipComponent implements OnInit {
  @Input() text: string;
  @Input() top: number;
  @Input() right: number;
  @Input() width;

  constructor() {}

  ngOnInit() {}

  get positionStyle() {
    return {
      position: 'absolute',
      top: `${this.top}%`,
      right: `${this.right}%`,
      width: `${this.width}`
    };
  }
}
