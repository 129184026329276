import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { UrlServiceMeta } from 'src/models/MetaData';
import { JSON_CONTENT_TYPE } from 'src/models/constants';

@Injectable()
export class HttpService {
  constructor(private _http: HttpClient) {}

  downloadWithProgress(url: string) {
    const headers = this.setHeaderOptions(JSON_CONTENT_TYPE);
    return this._http.get(url, {
      headers,
      responseType: 'arraybuffer',
      reportProgress: true,
      observe: 'events'
    });
  }

  uploadWithProgress(
    file: ArrayBuffer,
    url: string,
    contentType: string,
    params?: HttpParams
  ) {
    const headers = this.setHeaderOptions(contentType);
    return this._http.put(url, file, {
      headers,
      params,
      responseType: 'json',
      reportProgress: true,
      observe: 'events'
    });
  }

  getUrl(url: string) {
    return this._http.get<UrlServiceMeta>(url);
  }

  httpGetRequest(url: string) {
    return this._http.get(url);
  }

  httpPostRequest(url: string, contentType: string, body: any) {
    const headers = this.setHeaderOptions(contentType);
    return this._http.post(url, body, { headers });
  }

  private setHeaderOptions(contentType: string) {
    return new HttpHeaders({
      'Content-Type': contentType
    });
  }
}
