import {
  Component,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { CouponsService } from 'src/app/+merchant/+dashboard/coupons/coupons.service';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';

import { AppForm } from 'src/models/AppForm';
import { ICoupon } from 'src/models/ICoupon';
import { STATUS, SHARE } from 'src/models/campaign';
import { groupDescValidator } from 'src/app/validators/groupDescValidator';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';

@Component({
  selector: 'app-coupon-form',
  templateUrl: './coupon-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponFormComponent implements OnChanges, AppForm {
  @Input() couponInput: ICoupon = {};
  @Input() showCancel = false;
  @Input() isCreateFromBool = false;
  @Output() cancelNotify = new EventEmitter<boolean>();

  statusOptions = STATUS.slice(0, -1);
  shareOptions = SHARE;
  form: FormGroup;
  couponEdit: ICoupon = {};

  constructor(
    private _ref: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _validateForm: FormValidatorsService,
    private _couponService: CouponsService
  ) {}

  ngOnChanges() {
    this.couponEdit = Object.assign({}, this.couponInput);
    this.form = this.setFormGroup();
    this._ref.detectChanges();
  }

  /* Setup form Validators */
  setFormGroup(): FormGroup {
    console.table(this.couponEdit);
    return this._fb.group({
      name: [
        this.couponEdit && this.couponEdit.name ? this.couponEdit.name : null,
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(1),
          noWhitespaceValidator
        ]
      ],
      code: [
        this.couponEdit && this.couponEdit.code ? this.couponEdit.code : null,
        [
          Validators.required,
          Validators.maxLength(19),
          Validators.minLength(5),
          noWhitespaceValidator
        ]
      ],
      description: [
        this.couponEdit && this.couponEdit.description
          ? this.couponEdit.description
          : null,
        [groupDescValidator, noWhitespaceValidator]
      ],
      expire: [
        this.couponEdit && this.couponEdit.expire
          ? this.couponEdit.expire
          : null,
        [Validators.required]
      ],
      share: [
        this.couponEdit && this.couponEdit.share
          ? this.couponEdit.share
          : this.shareOptions[1].value,
        [Validators.required]
      ],
      value: [
        this.couponEdit && this.couponEdit.value ? this.couponEdit.value : null,
        [Validators.required]
      ],
      status: [
        this.couponEdit && this.couponEdit.status
          ? this.couponEdit.status
          : this.statusOptions[1].value,
        [Validators.required]
      ],
      couponId: [
        this.couponEdit && this.couponEdit.couponId
          ? this.couponEdit.couponId
          : null
      ]
    });
  }

  /** Get fields */
  get name() {
    return this.form.get('name');
  }
  get code() {
    return this.form.get('code');
  }
  get description() {
    return this.form.get('description');
  }
  get expire() {
    return this.form.get('expire');
  }
  get share() {
    return this.form.get('share');
  }
  get value() {
    return this.form.get('value');
  }
  get status() {
    return this.form.get('status');
  }

  get upcCode() {
    return this.form.controls['code'].value;
  }

  onSubmit() {
    if (this.form.valid) {
      if (!this.isCreateFromBool) {
        const couponToSend = Object.assign({}, this.form.value);
        this._couponService.setCoupon(couponToSend, false);
        this.cancelNotify.emit(true);
      } else {
        this.form.patchValue({
          status: 'A'
        });
        const couponToSend = Object.assign({}, this.form.value);
        this._couponService.setCoupon(couponToSend, true);
        this.cancelNotify.emit(true);
      }
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }
}
