<div [class.form-group]="!isInline" [class.form-inline]="isInline">
  <label
    [for]="fieldId"
    class="control-label"
    [class.md]="isInline"
    style="display: flex; align-items: center;"
  >
    {{ filedLabel }}

    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    ></app-hover-tooltip-icon>
  </label>
  <div class="form-group lg-input">
    <textarea
      class="form-control"
      [id]="fieldId"
      [formControl]="control"
      [placeholder]="placeHolder || filedLabel"
      style="font-family: sans-serif;"
    >
    </textarea>

    <ng-container *ngIf="showError">
      <ng-container *ngIf="validationErrors$ | async as errors">
        <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
        </app-validation-errors>
      </ng-container>
    </ng-container>
  </div>
</div>
