import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { AppState } from 'src/models/AppState';

import * as CouponUIActions from './couponUI.actions';
import { CouponUIDispatchers } from './couponUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { GetCoupons, GetCoupon } from 'src/models/ICoupon';

@Injectable()
export class CouponUiEffects {
  @Effect({ dispatch: false })
  getCoupons = this.actions$.pipe(
    ofType(
      CouponUIActions.CouponUIActionTypes.UI_COUPON_RECEIVED_HISTORY_CHUNK
    ),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, couponUiState]) => {
      couponUiState.currentPage.forEach(couponId => {
        this._socketGateway.sendSocketMessage(new GetCoupon(couponId));
      });
    })
  );

  @Effect({ dispatch: false })
  getNextCalendarPage = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, couponUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCoupons(
          0,
          couponUiState.hash,
          couponUiState.eop,
          couponUiState.couponsStatus,
          couponUiState.expire
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevCalendarPage = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.couponUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, couponUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetCoupons(
          1,
          couponUiState.hash,
          couponUiState.sop,
          couponUiState.couponsStatus,
          couponUiState.expire
        )
      );
    })
  );

  @Effect({ dispatch: false })
  afterCalendarSectionSelection = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_LIST_COUPON),
    map((action: CouponUIActions.ListCouponUI) => {
      this._couponDispatchers.getNextCouponPage();
    })
  );

  @Effect({ dispatch: false })
  afterCalendarResetPaging = this.actions$.pipe(
    ofType(CouponUIActions.CouponUIActionTypes.UI_COUPON_RESET_PAGING),
    map((action: CouponUIActions.ResetCouponPaging) => {
      this._couponDispatchers.getNextCouponPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>,
    private _couponDispatchers: CouponUIDispatchers
  ) {}
}
