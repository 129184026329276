import { Actions } from './smtp.actions';
import * as actions from './smtp.actions';
import { Smtp } from 'src/models/Smtp';

const INITIAL_STATE: Smtp = {};

export function smtpReducer(
  state = INITIAL_STATE,
  action: actions.Actions
): Smtp {
  switch (action.type) {
    case actions.SmtpActionTypes.RECEIVE_SMTP: {
      return action.payload.smtp;
    }

    case actions.SmtpActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
