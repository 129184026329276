import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  DoCheck,
  Input
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IMediaCenter } from 'src/models/IMediaCenter';

@Component({
  selector: 'app-reactive-media-input',
  templateUrl: './reactive-media-input.component.html',
  styleUrls: ['./reactive-media-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveMediaInputComponent implements DoCheck {
  /**
   * 'fieldId' is a unique field identification
   * Example: 'email', 'password'
   *    ---REQUIRED PROPERTY---
   */
  @Input() isInline = false;
  @Input() fieldId: string;
  @Input() filedLabel: string;
  @Input() showTooltip = false;
  @Input() descriptionTooltip: string;
  @Input() topTooltipHover: number;
  @Input() leftTooltipHover: number;
  /**
   * 'control' is a reactive form validator/value controller
   *    ---REQUIRED PROPERTY---
   */
  @Input() control: AbstractControl;
  @Input() displyControl: AbstractControl;

  private _validationErrors = new BehaviorSubject<object>(null);
  public validationErrors$ = this._validationErrors.asObservable();

  mediaCenterModalVisibility = false;

  ngDoCheck() {
    if (this.control && this.control.touched && this.control.invalid) {
      this._validationErrors.next(this.control['errors']);
    } else {
      this._validationErrors.next(null);
    }
  }

  get uploadedImage() {
    if (this.displyControl && this.displyControl.value !== null) {
      return {
        'background-image': 'url(' + this.displyControl.value + ')'
      };
    }
    return {
      'background-image': 'url(/assets/img/empty-image.svg)'
    };
  }

  toggleMediaCenter(e: boolean) {
    this.mediaCenterModalVisibility = e;
  }

  updateImage(value: IMediaCenter) {
    this.control.setValue(value.file);
    this.displyControl.setValue(value.url);
    this.toggleMediaCenter(false);
  }
}
