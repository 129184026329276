/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headerfull-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../abstract-tooltip/hover-tooltip-icon/hover-tooltip-icon.component.ngfactory";
import * as i3 from "../../abstract-tooltip/hover-tooltip-icon/hover-tooltip-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "./headerfull-frame.component";
var styles_HeaderfullFrameComponent = [i0.styles];
var RenderType_HeaderfullFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderfullFrameComponent, data: {} });
export { RenderType_HeaderfullFrameComponent as RenderType_HeaderfullFrameComponent };
function View_HeaderfullFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-arrow-left"]], null, null, null, null, null))], null, null); }
function View_HeaderfullFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip-icon", [], null, null, null, i2.View_HoverTooltipIconComponent_0, i2.RenderType_HoverTooltipIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipIconComponent, [], { title: [0, "title"], description: [1, "description"], top: [2, "top"], left: [3, "left"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleTooltip; var currVal_1 = _co.descriptionTooltip; var currVal_2 = _co.topHoverTooltip; var currVal_3 = _co.leftHoverTooltip; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_HeaderfullFrameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "./assets/img/cancel-key.svg"]], null, null, null, null, null))], null, null); }
export function View_HeaderfullFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "notify-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "notify-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderfullFrameComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderfullFrameComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "full-w"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderfullFrameComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "notify-modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBack; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.showTooltip; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.showClose; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_HeaderfullFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-headerfull-frame", [], null, null, null, View_HeaderfullFrameComponent_0, RenderType_HeaderfullFrameComponent)), i1.ɵdid(1, 49152, null, 0, i5.HeaderfullFrameComponent, [], null, null)], null, null); }
var HeaderfullFrameComponentNgFactory = i1.ɵccf("app-headerfull-frame", i5.HeaderfullFrameComponent, View_HeaderfullFrameComponent_Host_0, { title: "title", showClose: "showClose", showBack: "showBack", showTooltip: "showTooltip", titleTooltip: "titleTooltip", descriptionTooltip: "descriptionTooltip", topHoverTooltip: "topHoverTooltip", leftHoverTooltip: "leftHoverTooltip" }, { cancelNotfiy: "cancelNotfiy", backNotfiy: "backNotfiy" }, ["notify-action", "*"]);
export { HeaderfullFrameComponentNgFactory as HeaderfullFrameComponentNgFactory };
