<ng-container *ngIf="!email">
  <div class="login-qr">
    <div class="login-qr-header">
      Log In
    </div>

    <div class="login-qr-title">
      Log in as&nbsp;
      <app-link-btn (btnClicked)="goEmail()">
        <ng-container ngProjectAs="link-content-clicked">
          App Owner
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-container">
      <qr-code
        class="login-qr-qr"
        *ngIf="
          (authService.authCollection$ | async)?.qrCode &&
          (appService.online$ | async)
        "
        [size]="200"
        [value]="(authService.authCollection$ | async)?.qrCode"
      >
      </qr-code>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus !==
            authStatus.INIT_SOKET &&
          (authService.authCollection$ | async)?.qrExpire
        "
        class="login-qr-mask"
      >
        <div
          *ngIf="!(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
        >
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
        <div
          *ngIf="(authService.authCollection$ | async)?.qrExpire"
          class="circle-btn"
          (click)="qrCodeReload()"
        >
          <i class="fas fa-sync fan-btn"></i>
        </div>
      </div>

      <div
        *ngIf="
          (authService.authCollection$ | async)?.authStatus ===
          authStatus.INIT_SOKET
        "
        class="login-qr-mask"
      >
        <div class="circle-btn">
          <i class="fas fa-sync fan-btn fa-spin"></i>
        </div>
      </div>
    </div>

    <div class="login-qr-systems form-inline space-inbetween">
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/andriod-icon.svg" />
        <div class="system-spec-core">
          <div class="system-spec-title">Android</div>
          <div class="system-spec-desc">Menu > QR Scan</div>
        </div>
      </div>
      <div class="system-spec">
        <img class="system-spec-img" src="./assets/img/ios-icon.svg" />
        <div class="system-spec-core">
          <div class="system-spec-title">IOS</div>
          <div class="system-spec-desc">QR Scan Tab</div>
        </div>
      </div>
    </div>

    <div class="login-qr-manage">
      New to nandbox?&nbsp;
      <app-link-btn [openSelf]="true" [href]="signupLink">
        <ng-container ngProjectAs="link-content">
          Sign Up
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-rem form-inline space-between">
      <app-reactive-checkbox-input
        [filedId]="'inputRem'"
        [control]="remControl"
      >
        Remember me
      </app-reactive-checkbox-input>
      <app-link-btn (btnClicked)="showNeedHelp(true)">
        <ng-container ngProjectAs="link-content-clicked">
          Need help to get started?
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-qr-terms">
      By logging in, you agree to our
      <app-link-btn [href]="termsLink">
        <ng-container ngProjectAs="link-content">
          Terms of Use & Privacy Policy.
        </ng-container>
      </app-link-btn>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="email">
  <app-login (qrScan)="loginQr()"></app-login>
</ng-container>

<app-lightbox-container
  *ngIf="helpVideoVisibility"
  [titleHeader]="'Need help to get started?'"
  (cancelNotfiy)="showNeedHelp(false)"
>
  <iframe
    width="100%"
    height="100%"
    src="https://www.youtube.com/embed/bnAe54Tod18"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  >
  </iframe>
</app-lightbox-container>
