import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy
} from '@angular/core';

import { AuthService } from 'src/app/core/auth.service';
import { AuthStatus } from 'src/models/constants';
import { DashboardService } from 'src/app/+merchant/+dashboard/dashboard.service';
import { NavigateService } from 'src/app/core/navigate.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent implements OnInit, OnDestroy {
  authStatus: typeof AuthStatus = AuthStatus;

  constructor(
    public authService: AuthService,
    private _navigatedService: NavigateService,
    public dashboard: DashboardService
  ) {}

  ngOnInit() {
    this._navigatedService.preventBackButton();
  }

  ngOnDestroy() {
    this._navigatedService.goBack();
  }
}
