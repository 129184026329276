<div
  class="reactive-date"
  [class.form-group]="!isInline"
  [class.form-inline]="isInline"
>
  <label
    [for]="fieldId"
    class="reactive-date-label control-label"
    [class.md]="isInline"
  >
    {{ filedLabel }}
    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    >
    </app-hover-tooltip-icon>
  </label>
  <div class="reactive-date-group form-group lg-input">
    <div class="input-group" style="display: flex;">
      <input
        type="text"
        style="border-right: none;"
        class="reactive-date-input form-control"
        [id]="fieldId"
        [formControl]="control"
        [placeholder]="filedLabel ? filedLabel : fieldId"
        [value]="control?.value | date: 'yyyy-MM-dd'"
        bsDatepicker
        #dp="bsDatepicker"
        [bsConfig]="datePickerConfig"
        [attr.disabled]="true"
      />
      <div
        class="reactive-date-addon input-group-addon date-btn"
        [id]="uniqueClassName"
        (click)="dp.toggle()"
        appClickElsewhere
        (clickElsewhere)="dp.hide()"
        [clickId]="uniqueClassName"
      >
        <svg class="svg-icon" [class.icon-open]="dp.isOpen">
          <use
            xlink:href="./assets/svg/app-sections.svg#icon-date-picker"
          ></use>
        </svg>
      </div>
    </div>
    <ng-container *ngIf="validationErrors$ | async as errors">
      <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
      </app-validation-errors>
    </ng-container>
  </div>
</div>
