import { Actions } from './appConfig.actions';
import * as actions from './appConfig.actions';
import {
  SystemConfig,
  AppColors,
  Posts
} from '../../../models/ChannelAppConfig';
import {
  DEFAULT_APP_COLORS,
  EMPTY_STRING,
  DEFAULT_POST,
  LOGIN_TYPES,
  LOGIN_TAG_TYPE
} from 'src/models/constants';

const INITIAL_STATE: SystemConfig = {
  color: DEFAULT_APP_COLORS,
  posts: DEFAULT_POST
};

export function appConfigReducer(
  state: SystemConfig = INITIAL_STATE,
  action: Actions
): SystemConfig {
  switch (action.type) {
    case actions.AppConfigActionTypes.RECEIVE_APP_CONFIG:
    case actions.AppConfigActionTypes.RECEIVE_DEFAULT_APP_CONFIG: {
      let appColors: AppColors = {};
      let posts: Posts = {};
      const systemConfig: SystemConfig = {};
      const rcvdAppColors = action.offlineAppConfig.system.color;
      if (rcvdAppColors) {
        appColors.primary =
          rcvdAppColors.primary && rcvdAppColors.primary !== EMPTY_STRING
            ? rcvdAppColors.primary
            : state.color.primary;
        appColors.primary_dark =
          rcvdAppColors.primary_dark &&
          rcvdAppColors.primary_dark !== EMPTY_STRING
            ? rcvdAppColors.primary_dark
            : state.color.primary_dark;
        appColors.primary_light =
          rcvdAppColors.primary_light &&
          rcvdAppColors.primary_light !== EMPTY_STRING
            ? rcvdAppColors.primary_light
            : state.color.primary_light;
        appColors.secondary =
          rcvdAppColors.secondary && rcvdAppColors.secondary !== EMPTY_STRING
            ? rcvdAppColors.secondary
            : state.color.secondary;
        appColors.secondary_dark =
          rcvdAppColors.secondary_dark &&
          rcvdAppColors.secondary_dark !== EMPTY_STRING
            ? rcvdAppColors.secondary_dark
            : state.color.secondary_dark;
        appColors.secondary_light =
          rcvdAppColors.secondary_light &&
          rcvdAppColors.secondary_light !== EMPTY_STRING
            ? rcvdAppColors.secondary_light
            : state.color.secondary_light;
        appColors.primary_text =
          rcvdAppColors.primary_text &&
          rcvdAppColors.primary_text !== EMPTY_STRING
            ? rcvdAppColors.primary_text
            : state.color.primary_text;
        appColors.primary_text_inverted =
          rcvdAppColors.primary_text_inverted &&
          rcvdAppColors.primary_text_inverted !== EMPTY_STRING
            ? rcvdAppColors.primary_text_inverted
            : state.color.primary_text_inverted;
        appColors.primary_bg =
          rcvdAppColors.primary_bg && rcvdAppColors.primary_bg !== EMPTY_STRING
            ? rcvdAppColors.primary_bg
            : state.color.primary_bg;
        appColors.primary_icon_fill =
          rcvdAppColors.primary_icon_fill &&
          rcvdAppColors.primary_icon_fill !== EMPTY_STRING
            ? rcvdAppColors.primary_icon_fill
            : state.color.primary_icon_fill;
      } else {
        appColors = state.color;
      }
      systemConfig.color = appColors;
      if (action.offlineAppConfig.system.app_info) {
        systemConfig.app_info = action.offlineAppConfig.system.app_info;
      } else {
        systemConfig.app_info = {};
      }
      if (
        !action.offlineAppConfig.system.app_info ||
        !action.offlineAppConfig.system.app_info.login_type ||
        action.offlineAppConfig.system.app_info.login_type === EMPTY_STRING
      ) {
        systemConfig.app_info.login_type = LOGIN_TYPES.MSISDN;
      }
      if (
        action.offlineAppConfig.system.app_info &&
        !action.offlineAppConfig.system.app_info.logo_color_ios
      ) {
        systemConfig.app_info.logo_color_ios = systemConfig.app_info.logo_color;
      } else {
        systemConfig.app_info.logo_color_ios = {};
      }
      if (
        !action.offlineAppConfig.system.app_info ||
        action.offlineAppConfig.system.app_info.same_tone === null ||
        action.offlineAppConfig.system.app_info.same_tone === undefined
      ) {
        systemConfig.app_info.same_tone = 1;
      }
      const rcvdPosts = action.offlineAppConfig.system.posts;
      if (rcvdPosts) {
        posts.message = rcvdPosts.message
          ? rcvdPosts.message
          : state.posts.message;
        posts.url = rcvdPosts.url ? rcvdPosts.url : state.posts.url;
      } else {
        posts = state.posts;
      }
      systemConfig.posts = posts;
      return systemConfig;
    }
    case actions.AppConfigActionTypes.UPDATE_APP_INFO: {
      const systemConfig: SystemConfig = {};
      systemConfig.color = state.color;
      systemConfig.posts = state.posts;
      systemConfig.app_info = { ...state.app_info, ...action.payload };
      return systemConfig;
    }
    case actions.AppConfigActionTypes.UPDATE_APP_COLORS: {
      const systemConfig: SystemConfig = {};
      let appColors: AppColors = {};
      const updatedAppColors = action.appColors;
      if (updatedAppColors) {
        appColors.primary =
          updatedAppColors.primary && updatedAppColors.primary !== EMPTY_STRING
            ? updatedAppColors.primary
            : state.color.primary;
        appColors.primary_dark =
          updatedAppColors.primary_dark &&
          updatedAppColors.primary_dark !== EMPTY_STRING
            ? updatedAppColors.primary_dark
            : state.color.primary_dark;
        appColors.primary_light =
          updatedAppColors.primary_light &&
          updatedAppColors.primary_light !== EMPTY_STRING
            ? updatedAppColors.primary_light
            : state.color.primary_light;
        appColors.secondary =
          updatedAppColors.secondary &&
          updatedAppColors.secondary !== EMPTY_STRING
            ? updatedAppColors.secondary
            : state.color.secondary;
        appColors.secondary_dark =
          updatedAppColors.secondary_dark &&
          updatedAppColors.secondary_dark !== EMPTY_STRING
            ? updatedAppColors.secondary_dark
            : state.color.secondary_dark;
        appColors.secondary_light =
          updatedAppColors.secondary_light &&
          updatedAppColors.secondary_light !== EMPTY_STRING
            ? updatedAppColors.secondary_light
            : state.color.secondary_light;
        appColors.primary_text =
          updatedAppColors.primary_text &&
          updatedAppColors.primary_text !== EMPTY_STRING
            ? updatedAppColors.primary_text
            : state.color.primary_text;
        appColors.primary_text_inverted =
          updatedAppColors.primary_text_inverted &&
          updatedAppColors.primary_text_inverted !== EMPTY_STRING
            ? updatedAppColors.primary_text_inverted
            : state.color.primary_text_inverted;
        appColors.primary_bg =
          updatedAppColors.primary_bg &&
          updatedAppColors.primary_bg !== EMPTY_STRING
            ? updatedAppColors.primary_bg
            : state.color.primary_bg;
        appColors.primary_icon_fill =
          updatedAppColors.primary_icon_fill &&
          updatedAppColors.primary_icon_fill !== EMPTY_STRING
            ? updatedAppColors.primary_icon_fill
            : state.color.primary_icon_fill;
      } else {
        appColors = state.color;
      }
      systemConfig.color = appColors;
      systemConfig.app_info = state.app_info;
      systemConfig.posts = state.posts;
      console.log('systemConfig', systemConfig);
      return systemConfig;
    }
    case actions.AppConfigActionTypes.UPLOAD_APP_COLORED_LOGO_SUCCESS: {
      const systemConfig: SystemConfig = {};
      systemConfig.color = state.color;
      systemConfig.posts = state.posts;
      systemConfig.app_info = {
        ...state.app_info,
        logo_color: action.imageSet,
        image_id: action.imageId,
        image: action.imageUrl,
        dominant_color: action.dominantColor,
        color_logo_bg: action.dominantColor
      };
      return systemConfig;
    }

    case actions.AppConfigActionTypes.UPDATE_DOMAINANT_COLOR: {
      const systemConfig: SystemConfig = {};
      systemConfig.color = state.color;
      systemConfig.posts = state.posts;
      systemConfig.app_info = {
        ...state.app_info,
        dominant_color: action.dominantColor,
        color_logo_bg: action.dominantColor
      };
      return systemConfig;
    }

    case actions.AppConfigActionTypes.UPLOAD_APP_WHITE_LOGO_SUCCESS: {
      const systemConfig: SystemConfig = {};
      systemConfig.color = state.color;
      systemConfig.posts = state.posts;
      systemConfig.app_info = {
        ...state.app_info,
        logo_white: action.imageSet,
        white_logo_url: action.imageUrl
      };
      return systemConfig;
    }
    case actions.AppConfigActionTypes.UPLOAD_IOS_STORE_LOGO_SUCCESS: {
      const systemConfig: SystemConfig = {};
      systemConfig.color = state.color;
      systemConfig.posts = state.posts;
      systemConfig.app_info = {
        ...state.app_info,
        ios_store_url: action.imageUrl
      };
      return systemConfig;
    }
    case actions.AppConfigActionTypes.RECEIVE_TAG_VALUES: {
      const rcvdLoginTag =
        action.tabsTags &&
        action.tabsTags.find(tag => tag.type === LOGIN_TAG_TYPE);
      if (rcvdLoginTag) {
        const systemConfig: SystemConfig = {};
        systemConfig.color = state.color;
        systemConfig.posts = state.posts;
        systemConfig.app_info = {
          ...state.app_info,
          ...{ login_tags: rcvdLoginTag.value }
        };
        return systemConfig;
      } else {
        return state;
      }
    }
    case actions.AppConfigActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
