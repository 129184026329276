import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

import { MenusService } from '../../menus/menus.service';

import { BotMenu } from 'src/models/IBotMenu';
import { MENUS } from 'src/models/AppSections';
import { PrivilegesName } from 'src/models/privileges';

@Component({
  selector: 'app-menus-pool',
  templateUrl: './menus-pool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenusPoolComponent {
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectMenuNotfiy = new EventEmitter<BotMenu>();
  currentSelectMessage: BotMenu = null;
  menuFet = MENUS;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(public menusService: MenusService) {
    menusService.resetPaging();
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(message: BotMenu) {
    this.currentSelectMessage = { ...message };
  }
  resetSelectMessage() {
    this.currentSelectMessage = null;
  }
  insertEvent() {
    this.selectMenuNotfiy.emit(this.currentSelectMessage);
  }

  TrackByFunction(index, item: BotMenu) {
    return item.id;
  }
}
