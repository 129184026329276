import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as CouponActions from './coupon.actions';

import { AppState } from 'src/models/AppState';
import { ICoupon, RcvdCouponMessage } from 'src/models/ICoupon';

@Injectable({
  providedIn: 'root'
})
export class CouponDispatchers {
  constructor(private _store: Store<AppState>) {}

  couponReceived(payload: RcvdCouponMessage): void {
    this._store.dispatch(new CouponActions.CouponReceived(payload));
  }
  getCoupon(id: string): void {
    this._store.dispatch(new CouponActions.GetCoupon(id));
  }
  createCoupon(payload: ICoupon, isCreateFromBool: boolean): void {
    this._store.dispatch(
      new CouponActions.CreateCoupon(payload, isCreateFromBool)
    );
  }
  updateCoupon(payload: ICoupon): void {
    this._store.dispatch(new CouponActions.UpdateCoupon(payload));
  }
  deleteCoupon(payload: ICoupon): void {
    this._store.dispatch(new CouponActions.DeleteCoupon(payload));
  }
}
