<!-- Date Picker Box -->
<div
  class="btn-group clickable-cursor"
  dropdown
  [autoClose]="true"
  [dropup]="true"
  [isOpen]="isDatePickerKeyboardOpen"
  (isOpenChange)="toggleDatePickerKeyboard($event)"
>
  <div
    dropdownToggle
    type="button"
    class="schedule-btn clickable-cursor"
    [class.selected]="schedule_date"
  >
    <svg *ngIf="!schedule_date" class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-schedules"></use>
    </svg>

    <div *ngIf="schedule_date" class="inner-date">
      <span>{{ schedule_date | date: 'MMM' }}</span>
      <span>{{ schedule_date | date: 'dd' }}</span>
    </div>
  </div>

  <ul
    *dropdownMenu
    appCancelEvent
    class="dropdown-menu dropdown-menu-right datepicker-box"
    role="menu"
  >
    <div class="date-time-picker">
      <div class="Datepicker-Popover">
        <datepicker
          [(ngModel)]="scheduleDate"
          [minDate]="minDate"
          [showWeeks]="false"
          [minMode]="'day'"
        >
        </datepicker>
      </div>

      <div class="Timepicker-Popover">
        <timepicker [(ngModel)]="scheduleDate"> </timepicker>
      </div>
    </div>

    <div class="footer">
      <button
        type="button"
        class="schedule-btn"
        (click)="setScheduleTimeMessage()"
        [disabled]="!isScheduleValid"
      >
        Schedule
      </button>
      <button
        type="button"
        class="schedule-btn reset"
        (click)="resetScheduleDate()"
      >
        Reset
      </button>
    </div>
  </ul>
</div>
<!-- end date Picker -->
