import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-create-item-frame',
  templateUrl: './create-item-frame.component.html',
  styleUrls: ['./create-item-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateItemFrameComponent implements OnInit {
  @Output() addnewNotfiy = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  addnewEvent() {
    this.addnewNotfiy.emit(true);
  }
}
