import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input
} from '@angular/core';

import { TagsService } from '../../tags/tags.service';
import { ITag } from 'src/models/ITag';
import { ComposeMessageService } from '../compose-message.service';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { PrivilegesName } from 'src/models/privileges';
import { IChat } from 'src/models/IChat';
import { ChatTypes } from 'src/models/constants';
import { AuthService } from './../../../../core/auth.service';
import { AppMgmtService } from './../../app-mgmt/appMgmt.service';

@Component({
  selector: 'app-select-message-targets',
  templateUrl: './select-message-targets.component.html',
  styleUrls: ['./select-message-targets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageTargetsComponent implements OnInit {
  silentFlag = false;
  showTagsModal = false;
  showPrivateTagsModal = false;
  showSubgroupsModal = false;

  selectedTags: ITag[] = [];
  selectedSubgroups: IChat[] = [];
  localSelectedTab: LocalContainer = null;
  chatTypes: typeof ChatTypes = ChatTypes;

  @Input() selectedTab: LocalContainer;
  privilegesName: typeof PrivilegesName = PrivilegesName;
  hoverTags: boolean;
  hoverSegment: boolean;
  hoverGroup: boolean;
  hoverSilent: boolean;
  hoverFeed: boolean;
  hoverTooltipSendTo = false;
  silentTextTooltip: string;

  constructor(
    public composeMessageService: ComposeMessageService,
    public authService: AuthService,
    public appMgmtService: AppMgmtService,
    private _tagsService: TagsService
  ) {}

  ngOnInit() {
    if (!this.localSelectedTab) {
      this.localSelectedTab = this.selectedTab;
    }
  }

  /** Set Slient */
  setSilentFlag(e: boolean) {
    this.silentFlag = e;
  }
  /** Set Tab */
  setSelectedTab(tab: LocalContainer) {
    this.localSelectedTab = { ...tab };
  }

  /** Set Tags */
  showTagsModalEvent(show: boolean) {
    this.showTagsModal = show;
  }
  showPrivateTagsModalEvent(show: boolean) {
    this.showPrivateTagsModal = show;
  }
  setTagsEvent(tags: ITag[]) {
    this.showTagsModalEvent(false);
    this.selectedTags = [...tags];
  }
  setPrivateTagsEvent(tags: ITag[]) {
    this.showPrivateTagsModalEvent(false);
    this.selectedTags = [...tags];
  }
  removeTagEvent(id: string) {
    this.selectedTags = [...this.selectedTags.filter(tag => tag.id !== id)];
  }
  createTagEvent(tag: ITag) {
    this._tagsService.createTag(tag);
  }

  /** Set Subgroups */

  showSubgroupsModalEvent(show: boolean) {
    this.showSubgroupsModal = show;
  }
  setSubgroupsEvent(subgroups: IChat[]) {
    this.showSubgroupsModalEvent(false);
    this.selectedSubgroups = [...subgroups];
  }
  removeSubgroupsEvent(id: string) {
    this.selectedSubgroups = this.selectedSubgroups.filter(
      grp => grp.id !== id
    );
  }

  /** Selected  the selected */
  getSilentFlag(): boolean {
    return this.silentFlag;
  }
  getSelectedTags(): string[] {
    if (this.selectedTags) {
      return this.selectedTags.map(tag => tag.id);
    }
    return [];
  }
  getSelectedSubgroups(): IChat[] {
    if (this.selectedSubgroups) {
      return this.selectedSubgroups.map(grp => grp);
    }
    return [];
  }
  getSelectedTab(): string {
    if (this.localSelectedTab) {
      return this.localSelectedTab.id;
    }
    return null;
  }

  toggleHoverVisibility(type, e) {
    switch (type) {
      case 'sendTo':
        this.hoverTooltipSendTo = e;
        break;
      case 'Feed':
        this.hoverFeed = e;
        break;
      case 'Tags':
        this.hoverTags = e;
        break;
      case 'Segment':
        this.hoverSegment = e;
        break;
      case 'Groups':
        this.hoverGroup = e;
        break;
      case 'silent':
        this.hoverSilent = e;
        this.silentTextTooltip = `Push notification: ${
          this.silentFlag ? 'OFF' : 'ON'
        }`;
        break;
    }
  }
}
