<div [class.form-group]="!isInline" [class.form-inline]="isInline">
  <label
    class="control-label"
    [for]="fieldId"
    [class.md]="isInline"
    style="display: flex; align-items: center;"
  >
    {{ filedLabel }}

    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    ></app-hover-tooltip-icon>
  </label>
  <div class="form-group lg-input">
    <input hidden type="text" [formControl]="control" />
    <input hidden type="text" [formControl]="displyControl" />
    <div class="image-controller">
      <div class="image-input">
        <div
          class="uploaded-image clickable-cursor"
          [ngStyle]="uploadedImage"
          (click)="toggleMediaCenter(true)"
        ></div>
        <div class="image-label">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="./assets/svg/app-sections.svg#icon-photograph"
            ></use>
          </svg>
        </div>
      </div>
    </div>

    <ng-container *ngIf="validationErrors$ | async as errors">
      <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
      </app-validation-errors>
    </ng-container>
  </div>
</div>

<app-modal-container
  *ngIf="mediaCenterModalVisibility"
  (cancelNotfiy)="toggleMediaCenter(false)"
>
  <app-media-center-modal
    (mediaCenterNotify)="updateImage($event)"
    (cancelNotify)="toggleMediaCenter(false)"
    [isPhotoMedia]="true"
    [mimeTypes]="'image/*'"
  >
  </app-media-center-modal>
</app-modal-container>
