import { AppRelease } from 'src/models/AppRelease';
import { Actions } from './appRelease.actions';
import * as actions from './appRelease.actions';
import { IPA, APK } from 'src/models/constants';

const INITIAL_STATE = [];

export function appReleaseReducer(
  state: AppRelease[] = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppReleaseActionTypes.LIST_APP_RELEASES_SUCCESS:
      if (action.appReleases) {
        return action.appReleases.map(release => {
          release.type = release.os ? IPA : APK;
          return release;
        });
      }
      return state;
    case actions.AppReleaseActionTypes.RCVD_APP_RELEASE:
      return state.map(release => {
        if (release.id === action.appReleases.id) {
          let updatedRelease: AppRelease = {};
          updatedRelease = { ...action.appReleases };
          return updatedRelease;
        }
        return release;
      });
    case actions.AppReleaseActionTypes.REMOVE_APP_RELEASE_SUCCESS:
      return state.filter(release => release.id !== action.id);
    case actions.AppReleaseActionTypes.VIEW_APP_RELEASE_DETAILS:
      return state.map(release => {
        if (release.id === action.id) {
          let updatedRelease: AppRelease = {};
          updatedRelease = { ...release, showDetails: true };
          return updatedRelease;
        }
        return release;
      });

    case actions.AppReleaseActionTypes.RCVD_APP_RELEASE_VER:
      return state.map(release => {
        if (release.publish_id + '' === action.appReleases.id + '') {
          let updatedRelease: AppRelease = {};
          updatedRelease = {
            ...release,
            version_name: action.appReleases.version_name,
            version_desc: action.appReleases.version_desc
          };
          return updatedRelease;
        }
        return release;
      });
    case actions.AppReleaseActionTypes.HIDE_APP_RELEASE_DETAILS:
      return state.map(release => {
        if (release.id === action.id) {
          let updatedRelease: AppRelease = {};
          updatedRelease = { ...release, showDetails: false };
          return updatedRelease;
        }
        return release;
      });
    case actions.AppReleaseActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
