import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { BookingUiDispatchers } from 'src/app/store/bookingsUI/bookingUI.dispatchers';
import { BookingPeriodsDispatchers } from 'src/app/store/bookingPeriods/bookingPeriods.dispatchers';
import { BookingExceptionsDispatchers } from 'src/app/store/bookingExceptions/bookingExceptions.dispatchers';

import { IChat } from 'src/models/IChat';
import { BookingUI } from 'src/models/BookingUI';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { BookingExceptions } from 'src/models/BookingExceptions';
import {
  UPDATE_BOOKING_REF,
  CHANNEL_APP_SEARCH_CATEGORIES
} from 'src/models/constants';
import { BookingPeriods, DayPeriods } from 'src/models/BookingPeriods';
import { MyBookingsDispatchers } from './../../../store/myBookings/myBookings.dispatchers';
import { MyBookings } from 'src/models/MyBookings';
import { EnableServiceConfig } from './../../../../models/constants';
import { ProductsUiDispatchers } from './../../../store/productsUI/productsUI.dispatchers';

@Injectable({ providedIn: 'root' })
export class BookingsService {
  confirmDeleteWindow = false;
  selectedBooking: IChat;
  public _currentPhotoUploaded = new BehaviorSubject<any>({});
  public currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();
  constructor(
    private _selectors: RootStoreSelectors,
    private _chatDispatchers: ChatDispatchers,
    private _bookingUiDispatchers: BookingUiDispatchers,
    private _bookingPeriodsDispatchers: BookingPeriodsDispatchers,
    private _bookingExceptionsDispatchers: BookingExceptionsDispatchers,
    private _myBookingsDispatchers: MyBookingsDispatchers,
    private _productsDispatchers: ProductsUiDispatchers
  ) {}

  createBooking(booking: IChat): void {
    this._chatDispatchers.createBooking(booking);
  }

  deleteBooking(id: string) {
    this._chatDispatchers.deleteChat(id);
  }

  toggleConfirmDeleteWindow(e: boolean, booking?: IChat) {
    this.confirmDeleteWindow = e;
    this.selectedBooking = booking;
  }

  viewBooking(booking: IChat): void {
    this.getChatAdministrators(booking.id);
    this._bookingUiDispatchers.viewBookingUI(booking);
  }

  closeViewBooking(): void {
    this._currentPhotoUploaded.next({});
    this._bookingUiDispatchers.closeViewBookingUI();
  }

  showEditBookingScreen(booking: IChat): void {
    const photo = {
      file: null,
      url: booking.localThumbnail
    };
    this._currentPhotoUploaded.next(photo);
    this.getChatAdministrators(booking.id);
    this._bookingUiDispatchers.editBookingUI(booking);
  }

  showReservations(booking: IChat): void {
    this._bookingUiDispatchers.showReservations(booking);
  }

  showCreateBookingScreen() {
    this._bookingUiDispatchers.createBookingUI();
  }

  closeEditBookingScreen(): void {
    this._bookingUiDispatchers.closeEditBookingUI();
  }

  updateBooking(chatID: string, updatedBookingProps: IChat) {
    updatedBookingProps.id = chatID;
    this._chatDispatchers.updateChat(updatedBookingProps, UPDATE_BOOKING_REF);
  }

  updateStore(chatID: string, updatedBookingProps: IChat) {
    updatedBookingProps.id = chatID;
    this._chatDispatchers.updateStore(updatedBookingProps, UPDATE_BOOKING_REF);
    this._bookingUiDispatchers.closeEditBookingUI();
  }

  uploadBookingImage(file): void {
    console.log('upld =>', file);
    this._bookingUiDispatchers.uploadBookingImage(file);
  }

  bookingCreationComplete(): void {
    this._bookingUiDispatchers.bookingCreationComplete();
  }

  confirmDelete(id: string) {
    this.deleteBooking(id);
    this.toggleConfirmDeleteWindow(false);
  }

  //

  get bookingChannelList$(): Observable<IChat[]> {
    return this._selectors.getBookingList$();
  }

  getBookingPeriods$(bookingChannelID: string): Observable<BookingPeriods> {
    return this._selectors.getBookingPeriods$(bookingChannelID);
  }

  createBookingPeriods(bookingPeriods: BookingPeriods) {
    this._bookingPeriodsDispatchers.createBookingPeriods(bookingPeriods);
  }

  updateBookingPeriods(bookingPeriods: BookingPeriods) {
    this._bookingPeriodsDispatchers.updateBookingPeriods(bookingPeriods);
  }

  deleteBookingPeriods(chatId: string, data: DayPeriods[]) {
    this._bookingPeriodsDispatchers.deleteBookingPeriods(chatId, data);
  }

  get selectedBooking$(): Observable<IChat> {
    return this._selectors.getSelectedBooking$();
  }

  get selectedBookingPeriods$(): Observable<BookingPeriods> {
    return this._selectors.getSelectedBookingPeriods$();
  }

  get selectedBookingsHolidays$(): Observable<BookingExceptions> {
    return this._selectors.getSelectedBookingsHolidays$();
  }

  get bookingInCreationProcess$(): Observable<IChat> {
    return this._selectors.getBookingInCreationProcess$();
  }

  get bookingUiState$(): Observable<BookingUI> {
    return this._selectors.bookingUiState$;
  }
  // to get Holidays

  getBookingHolidays$(bookingChannelID: string): Observable<BookingExceptions> {
    return this._selectors.getBookingsHolidays$(bookingChannelID);
  }

  updateBookingHolidays(bookingChannelID: string, dates: string[]) {
    this._bookingExceptionsDispatchers.setBookingExceptions(
      bookingChannelID,
      dates
    );
  }

  setEnableService(config: EnableServiceConfig) {
    this._bookingUiDispatchers.setEnableService(config);
  }
  removeBookingHolidays(bookingChannelID: string, dates: string[]) {
    this._bookingExceptionsDispatchers.deleteBookingExceptions(
      bookingChannelID,
      dates
    );
  }

  getReservations(bookingChannelID: string, date: string) {
    this._myBookingsDispatchers.requestMyBookings(bookingChannelID, date);
  }

  getMyBookings$(): Observable<MyBookings> {
    return this._selectors.getMyBookings$();
  }

  getProductById(prdId, type, bundleId) {
    this._productsDispatchers.getProduct(prdId, null, bundleId);
  }

  get area$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.AREA
    );
  }
  get classification$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
    );
  }
  get category$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY
    );
  }

  getNextPage(): void {
    this._bookingUiDispatchers.getNextBookingPage();
  }

  getPreviousPage(): void {
    this._bookingUiDispatchers.getPrevBookingPage();
  }

  resetPaging(): void {
    this._bookingUiDispatchers.resetBookingPaging();
  }

  getBookingPeriods(chatId) {
    this._chatDispatchers.getBookingPeriods(chatId);
  }
  cancelReservation(
    chat_id: string,
    user_id: string,
    reference: string,
    date: string,
    time: string
  ) {
    this._myBookingsDispatchers.cancelMyBookingReservation(
      chat_id,
      user_id,
      reference,
      date,
      time
    );
  }

  getChatAdministrators(id: string) {
    this._chatDispatchers.getChatAdministrators(id);
  }

  setChat(chat: IChat) {
    this._bookingUiDispatchers.setChat(chat);
  }
}
