import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { SubGroupUiDispatchers } from './subGroupsUI/subGroupsUI.dispatchers';
import { AppConfigDispatchers } from './channelAppConfig/appConfig.dispatchers';
import * as MessagesMethos from 'src/models/IMessage';
import { ListAppMenus, GetAppMenu } from 'src/models/AppMenu';
import { UISections, APP_MENU_CATEGORIES, MERCHANT_PAGE_SIZE, REACHED_END, GROUP, CHANNEL, VIRTUAL_APP } from 'src/models/constants';
import { SubChatsService } from '../+merchant/+dashboard/sub-chats/sub-chats.service';
import { SubChannelUiDispatchers } from './subChannelsUI/subChannelsUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { VirtualAppUiDispatchers } from './virtualAppsUI/virtualAppsUI.dispatchers';
export class SectionEffects {
    constructor(_router, actions$, _store, _socketGateway, _subChatServices, _subGroupUiDispatchers, _subChannelUiDispatchers, _appConfigDispatchers, _virtualAppUiDispatchers) {
        this._router = _router;
        this.actions$ = actions$;
        this._store = _store;
        this._socketGateway = _socketGateway;
        this._subChatServices = _subChatServices;
        this._subGroupUiDispatchers = _subGroupUiDispatchers;
        this._subChannelUiDispatchers = _subChannelUiDispatchers;
        this._appConfigDispatchers = _appConfigDispatchers;
        this._virtualAppUiDispatchers = _virtualAppUiDispatchers;
        this.chatSelected = this.actions$.pipe(ofType("UI_CHAT_SELECTED" /* UI_CHAT_SELECTED */), withLatestFrom(this._store
            .select(state => state.authReducer.selectedTemplateID)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.progressWeb)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.mode)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer.appConfig)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.authReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, templateID, progressWeb, mode, appConfig, selectedChat, auth]) => {
            const action = val;
            if (templateID &&
                `${progressWeb}` === '0' &&
                !appConfig &&
                !selectedChat.subChannel) {
                this._appConfigDispatchers.requestAppTemplate(templateID);
            }
            else if (auth.chatId === auth.main_group_id ||
                (auth.chatId !== auth.main_group_id && auth.is_nandbox > 0)) {
                this._appConfigDispatchers.requestAppConfig();
            }
            this._socketGateway.sendSocketMessage(new ListAppMenus(APP_MENU_CATEGORIES.MAP));
        }));
        /********************************* */
        // page change effect
        this.pageChanged = this.actions$.pipe(ofType("UI_SET_PAGE_NUMBER" /* UI_SET_PAGE_NUMBER */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats, selectedChat]) => {
            const action = val;
            const targetChat = mainChats.find(chat => chat.id === selectedChat.id);
            if (action.payload === 'new' && targetChat.eopLevel1 !== REACHED_END) {
                this._socketGateway.sendSocketMessage(new MessagesMethos.GetMessagesHistoryFromBusinessServer(null, null, null, null, null, null, null, null, null, MERCHANT_PAGE_SIZE, 1, targetChat.eopLevel1, 0));
            }
        }));
        this.sectionSelected = this.actions$.pipe(ofType("UI_SET_SECTION" /* UI_SET_SECTION */), withLatestFrom(this._store
            .select(state => {
            return state.appMenuReducer.filter(menu => menu.category === APP_MENU_CATEGORIES.MAP && !menu.value);
        })
            .pipe(distinctUntilChanged(isEqual))), map(([val, emptyValueMapMenus]) => {
            const action = val;
            if ((action.payload === UISections.APP ||
                action.payload === UISections.CHANNEL) &&
                emptyValueMapMenus &&
                emptyValueMapMenus.length > 0) {
                emptyValueMapMenus.forEach(mapMenu => {
                    this._socketGateway.sendSocketMessage(new GetAppMenu(mapMenu.id));
                });
            }
        }));
        this.sectionChanged = this.actions$.pipe(ofType("UI_SET_SECTION" /* UI_SET_SECTION */), map((action) => {
            switch (action.payload) {
                /**
                 * Sections not pagination
                 */
                case UISections.CAMPAIGNS:
                    this._router.navigate(['/campaigns']);
                    break;
                case UISections.COUPONS:
                    this._router.navigate(['/coupons']);
                    break;
                case UISections.BOTS:
                    this._router.navigate(['/bots']);
                    break;
                case UISections.MENUS:
                    this._router.navigate(['/polls']);
                    break;
                case UISections.LOCATIONS:
                    this._router.navigate(['/locations']);
                    break;
                /******************************************/
                case UISections.SUBGROUP:
                    this._subGroupUiDispatchers.listSubGroupUI();
                    this._subChatServices.chatType = GROUP;
                    this._router.navigate(['/subgroups']);
                    break;
                case UISections.SUBCHANNEL:
                    this._subChannelUiDispatchers.listSubChannelUI();
                    this._subChatServices.chatType = CHANNEL;
                    this._router.navigate(['/subchannels']);
                    break;
                case UISections.VIRTUAL_APPS:
                    this._virtualAppUiDispatchers.listVirtualAppUI();
                    this._subChatServices.chatType = VIRTUAL_APP;
                    this._router.navigate(['/vapps']);
                    break;
                case UISections.MYCH:
                    this._subChatServices.chatType = CHANNEL;
                    this._router.navigate(['/my-channel']);
                    break;
                case UISections.BILLINGS:
                    this._router.navigate(['/billings']);
                    break;
                case UISections.APP:
                    this._router.navigate(['/app']);
                    break;
                case UISections.CHANNEL:
                    this._router.navigate(['/app']);
                    break;
                case UISections.POSTS:
                    this._router.navigate(['/messages']);
                    break;
                case UISections.SCHEDULE:
                    this._router.navigate(['/schedules']);
                    break;
                case UISections.BOOKING:
                    this._router.navigate(['/bookings']);
                    break;
                case UISections.CALENDAR:
                    this._router.navigate(['/calendars']);
                    break;
                case UISections.EVENTS:
                    this._router.navigate(['/events']);
                    break;
                case UISections.USERS:
                    this._router.navigate(['/users']);
                    break;
                case UISections.BLACKLIST:
                    this._router.navigate(['/blackList']);
                    break;
                case UISections.TAGS:
                    this._router.navigate(['/tags']);
                    break;
                case UISections.BULKUPLOAD:
                    this._router.navigate(['/bulkupload']);
                    break;
                case UISections.SETTINGS:
                    this._router.navigate(['/settings']);
                    break;
                case UISections.TRANSACTIONS:
                    this._router.navigate(['/transactions']);
                    break;
                case UISections.PACKAGES:
                    this._router.navigate(['/packages']);
                    break;
                case UISections.BUNDLES:
                    this._router.navigate(['/bundles']);
                    break;
                case UISections.CUSTOMERCARE:
                    this._router.navigate(['/customercare']);
                    break;
                case UISections.PRODUCTS:
                    this._router.navigate(['/products']);
                    break;
                case UISections.UPLOADTEMPS:
                    this._router.navigate(['/tempsupload']);
                    break;
                case UISections.MSTORE:
                    this._router.navigate(['/mStore']);
                    break;
                case UISections.STOREMENU:
                    this._router.navigate(['/storeMenu']);
                    break;
                case UISections.MAPSERVICE:
                    this._router.navigate(['/MS']);
                    break;
                case UISections.MAPROUTES:
                    this._router.navigate(['/MR']);
                    break;
                case UISections.TRIPS:
                    this._router.navigate(['/Trips']);
                    break;
                case UISections.TRIPSHISTORY:
                    this._router.navigate(['/TH']);
                    break;
                case UISections.TRIPNOTIFIERS:
                    this._router.navigate(['/TN']);
                    break;
                case UISections.WHITELIST:
                    this._router.navigate(['/wl']);
                    break;
                default:
                    this._router.navigate(['/']);
            }
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "chatSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "pageChanged", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "sectionSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], SectionEffects.prototype, "sectionChanged", void 0);
