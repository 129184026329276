import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { UiService } from 'src/app/core/ui.service';
import { ComposeMessageService } from '../compose-message.service';

import { SelectMessageTargetsComponent } from '../select-message-targets/select-message-targets.component';
import { ComposeTextInputComponent } from '../compose-text-input/compose-text-input.component';
import { SelectMessageStyleComponent } from '../select-message-style/select-message-style.component';
import { ComposeScheduleInputComponent } from '../compose-schedule-input/compose-schedule-input.component';
import { SelectMessageAttachsComponent } from '../select-message-attachs/select-message-attachs.component';
import { PreviewCalendarComponent } from '../preview-calendar/preview-calendar.component';

import { IMessage } from 'src/models/IMessage';
import { MessageControls } from 'src/models/MessageParts';
import { InstantArticle } from 'src/models/InstantArticle';
import { CalendarTimetable } from 'src/models/Calendar';
import { IChat } from 'src/models/IChat';
import { MainChat } from 'src/models/MainChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import {
  CHANNELS_THEME_COLOR,
  MessageSendingType,
  MessageTypes,
  LinkPreviewOptions,
  MessageLinkPreviewStatus
} from 'src/models/constants';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';

@Component({
  selector: 'app-compose-message-modal',
  templateUrl: './compose-message-modal.component.html',
  styleUrls: ['./compose-message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeMessageModalComponent implements OnInit {
  showPreview = false;
  themeColor = CHANNELS_THEME_COLOR;
  iv$: Observable<InstantArticle> = null;
  scheduleValid: boolean;
  massageInputValid: boolean;

  showTooltipHover = false;
  textTooltip: string;

  private _selectedCalendar = new BehaviorSubject<CalendarTimetable>(null);
  public selectedCalendar$ = this._selectedCalendar.asObservable();

  attachType: typeof MessageSendingType = MessageSendingType;
  mType: typeof MessageTypes = MessageTypes;

  @ViewChild('targetsComponent', { static: false })
  targetsComponent: SelectMessageTargetsComponent;
  @ViewChild('textInputComponent', { static: false })
  textInputComponent: ComposeTextInputComponent;
  @ViewChild('scheduleInputComponent', { static: false })
  scheduleInputComponent: ComposeScheduleInputComponent;
  @ViewChild('stylesComponent', { static: false })
  stylesComponent: SelectMessageStyleComponent;
  @ViewChild('attachComponent', { static: false })
  attachComponent: SelectMessageAttachsComponent;
  @ViewChild('calendarComponent', { static: false })
  calendarComponent: PreviewCalendarComponent;

  @Input() selectedChat: MainChat;
  @Input() selectedMessage: IMessage;
  @Input() selectedUserId: string;
  @Input() chatAdminName: string;
  @Input() messageSendingType: string;
  @Input() isSubChatSelected = false;

  @Output() cancelNotfiy = new EventEmitter<boolean>();

  constructor(
    public uiService: UiService,
    public composeMessageService: ComposeMessageService
  ) {}

  ngOnInit() {
    this.uiService._previewMessageType.next(this.mType.TEXT);
    // if (
    //   this.scheduleInputComponent &&
    //   this.scheduleInputComponent.isScheduleValid
    // ) {
    //   console.log(this.scheduleInputComponent.isScheduleValid);
    // }
  }
  /**************************************************************************/
  // Send Events
  /*************************************************************************/
  /** isText flag used to include bgColor or not */
  setMessageControl(isText?: boolean): MessageControls {
    let misc: MessageControls = {};
    const silentFlag = this.targetsComponent.getSilentFlag();
    const tags = this.targetsComponent.getSelectedTags();
    const tab = this.targetsComponent.getSelectedTab();
    const coupon = this.attachComponent.getSelectedCoupon();
    const menu = this.attachComponent.getSelectedMenu();
    if (this.calendarComponent) {
      const autoAcceptCalendar = this.calendarComponent.getAutoAcceptCalendar();
      if (autoAcceptCalendar) {
        misc = {
          ...misc,
          auto_accept: Number(autoAcceptCalendar)
        };
      }
    }

    if (silentFlag) {
      misc = {
        ...misc,
        disable_notification: true
      };
    }

    if (coupon) {
      misc = {
        ...misc,
        c_code: coupon.code,
        c_exp: coupon.expire.getTime()
      };
    }
    if (menu) {
      misc = {
        ...misc,
        inline_menu: [menu],
        menu_ref: menu.menu_ref,
        reply_to_admin: true
      };
    }

    misc = { ...misc, tags, tab };
    if (isText) {
      if (this.textInputComponent) {
        const bgColor = this.textInputComponent.getSelectedColor();
        if (bgColor) {
          misc = { ...misc, bg_color: bgColor };
        }
      }
    } else if (!isText) {
      const style = this.stylesComponent.getMessageStyle();
      const sticky = this.attachComponent.getStickyInput();
      if (typeof style === 'number') {
        misc = { ...misc, style };
      }
      if (sticky) {
        misc = {
          ...misc,
          sticky_title: sticky.title,
          sticky_desc: sticky.description
        };
      }
    }
    if (this.messageSendingType === MessageSendingType.AWARD) {
      misc = { ...misc, award: 2 };
    } else if (this.messageSendingType === MessageSendingType.WELCOME) {
      misc = { ...misc, welcome: 1, disable_reply: 1 };
    } else if (this.messageSendingType === MessageSendingType.SCHEDULE) {
      misc = {
        ...misc,
        schedule_date: this.scheduleInputComponent.getScheduleDate()
      };
    }
    return misc;
  }

  /** Send Text Message */
  sendTextMessageEvent(text: string) {
    const misc = this.setMessageControl(true);
    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendTextMessage(grp, null, null, text, misc);
      }
    } else {
      this.uiService.sendTextMessage(this.selectedChat, null, null, text, misc);
    }
    this.cancelEvent();
  }

  /** Send links */
  sendLinkPreviewEvent(link: InstantArticle) {
    if (link.isInstant) {
      this.sendInstantUrl(link);
    } else {
      this.sendLinkPreview(link);
    }
  }
  sendLinkPreview(instantArticle: InstantArticle) {
    let misc = this.setMessageControl(false);
    const subgroups = this.targetsComponent.getSelectedSubgroups();
    misc = {
      ...misc,
      web_page_preview: LinkPreviewOptions.WEB_PREVIEW_HIDE_LINK
    };

    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendTextMessage(
          grp,
          null,
          null,
          instantArticle.linkPreviewUrl,
          misc
        );
      }
    } else {
      this.uiService.sendTextMessage(
        this.selectedChat,
        null,
        null,
        instantArticle.linkPreviewUrl,
        misc
      );
    }
    this.cancelEvent();
  }
  sendInstantUrl(linkPreview: InstantArticle) {
    if (
      linkPreview.linkPreviewStatus ===
      MessageLinkPreviewStatus.LINK_PREVIEW_FAILED
    ) {
      this.sendTextMessageEvent(linkPreview.linkPreviewUrl);
    } else {
      let misc = this.setMessageControl(false);
      misc = {
        ...misc,
        web_page_preview: LinkPreviewOptions.WEB_PREVIEW_INSTANCE_WITHOUT_LINK
      };

      const subgroups = this.targetsComponent.getSelectedSubgroups();
      if (subgroups && subgroups.length > 0) {
        for (const group of subgroups) {
          const grp = this.getMainChatFromChat(group);
          this.uiService.sendInstantUrl(grp, null, null, linkPreview, misc);
        }
      } else {
        this.uiService.sendInstantUrl(
          this.selectedChat,
          null,
          null,
          linkPreview,
          misc
        );
      }
    }
    this.cancelEvent();
  }

  /** Send Media */
  sendMediaMessageEvent(mediaToSend: any) {
    const misc = this.setMessageControl(false);

    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendMediaMessage(
          grp,
          null,
          null,
          mediaToSend.files,
          mediaToSend.messageType,
          mediaToSend.mediaMetadata,
          null,
          misc
        );
      }
    } else {
      this.uiService.sendMediaMessage(
        this.selectedChat,
        null,
        null,
        mediaToSend.files,
        mediaToSend.messageType,
        mediaToSend.mediaMetadata,
        null,
        misc
      );
    }

    this.uiService.hidePreview();
    this.cancelEvent();
  }

  /** Send Calendar */
  sendCalendarEvent(calendar: CalendarTimetable) {
    const misc = this.setMessageControl(true);
    const subgroups = this.targetsComponent.getSelectedSubgroups();
    if (subgroups && subgroups.length > 0) {
      for (const group of subgroups) {
        const grp = this.getMainChatFromChat(group);
        this.uiService.sendCalendarMessage(grp, null, null, calendar, misc);
      }
    } else {
      this.uiService.sendCalendarMessage(
        this.selectedChat,
        null,
        null,
        calendar,
        misc
      );
    }
    this.cancelEvent();
  }
  /**************************************************************************/
  // Selected Events
  /*************************************************************************/
  urlSelectedEvent(n: number) {
    this.resetSelectedType();
    this.iv$ = this.composeMessageService.getNewArticle(n);
  }
  fileSelectedEvent(event: ISelectedFiles) {
    if (event.localFile) {
      this.resetSelectedType();
      this.uiService.getBlobUrl(event);
    }
  }
  calendarSelectedEvent(event: CalendarTimetable) {
    this.resetSelectedType();
    this._selectedCalendar.next(event);
    this.uiService._previewMessageType.next(this.mType.CALENDAR);
  }
  cancelEvent() {
    this.resetSelectedType();
    this.cancelNotfiy.emit(true);
  }

  /**************************************************************************/
  // Reset part
  /*************************************************************************/
  resetSelectedType() {
    this.iv$ = null;
    this._selectedCalendar.next(null);
    this.uiService.hidePreview();
    this.uiService._previewMessageType.next(this.mType.TEXT);
  }

  getMainChatFromChat(grp: IChat) {
    return ChatMiddleware.getMainChatFromChat(grp);
  }

  isValid(e) {
    this.scheduleValid = e;
  }

  get canSend() {
    this.massageInputValid = this.textInputComponent
      ? this.textInputComponent.canSend
      : null;

    if (this.messageSendingType === this.attachType.SCHEDULE) {
      if (this.scheduleValid && this.massageInputValid) {
        return false;
      } else {
        return true;
      }
    } else {
      return !this.massageInputValid;
    }
  }

  toggleHoverTooltip(e) {
    if (this.messageSendingType === this.attachType.SCHEDULE) {
      this.showTooltipHover = e;

      this.textTooltip = `Schedule publishing your post`;
    } else {
      this.showTooltipHover = e;
      this.textTooltip = `Publish immediately`;
    }
  }
}
