import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-hover-tooltip-icon',
  templateUrl: './hover-tooltip-icon.component.html',
  styleUrls: ['./hover-tooltip-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoverTooltipIconComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() top;
  @Input() left;

  icon = 'icon-info-tooltip';

  showTooltip = false;
  constructor() {}

  ngOnInit() {}

  toggleTooltipVisibility(e) {
    this.showTooltip = e;
  }
}
