import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { SubChatFormModule } from '../sub-chats/sub-chat-form.module';

import { SubChatsSelectorComponent } from './sub-chats-selector/sub-chats-selector.component';
import { SubChatsPoolComponent } from './sub-chats-pool/sub-chats-pool.component';
import { AssignSubChatsComponent } from './assign-sub-chat/assign-sub-chat.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    SubChatFormModule
  ],
  declarations: [
    SubChatsSelectorComponent,
    SubChatsPoolComponent,
    AssignSubChatsComponent
  ],
  exports: [
    SubChatsSelectorComponent,
    SubChatsPoolComponent,
    AssignSubChatsComponent
  ]
})
export class SubChatsPoolModule {}
