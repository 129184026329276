import { Injectable } from '@angular/core';

import { CouponDispatchers } from 'src/app/store/coupons/coupon.dispatchers';
import { CouponUIDispatchers } from 'src/app/store/couponsUI/couponUI.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';

import { ICoupon } from 'src/models/ICoupon';
import { STATUS_OPTIONS } from 'src/models/campaign';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  constructor(
    private _rootStoreSelector: RootStoreSelectors,
    private _couponDispatchers: CouponDispatchers,
    private _couponUIDispatchers: CouponUIDispatchers
  ) {}

  getBgStatus(item: ICoupon) {
    if (item.status === STATUS_OPTIONS.ACTIVE) {
      return 'linear-gradient(309deg, #caf7dc, #89d1a6)';
    } else if (item.status === STATUS_OPTIONS.DEACTIVE) {
      return 'linear-gradient(309deg, #f4e9d4, #f4d091)';
    } else if (item.status === STATUS_OPTIONS.EXPIRE) {
      return 'linear-gradient(309deg, #fbe8e7, #fba5a5)';
    }
  }

  setCoupon(msg: ICoupon, isCreateFromBool: boolean) {
    if (msg.couponId) {
      this._couponDispatchers.updateCoupon(msg);
      this._couponUIDispatchers.closeCreateCouponUI();
    } else {
      this._couponDispatchers.createCoupon(msg, isCreateFromBool);
      this._couponUIDispatchers.closeEditCouponUI();
    }
  }

  createCoupon() {
    this._couponUIDispatchers.createCouponUI();
  }
  viewCoupon(camp: ICoupon) {
    this._couponUIDispatchers.viewCouponUI(camp);
  }
  editCoupon(camp: ICoupon) {
    this._couponUIDispatchers.editCouponUI(camp);
  }
  closeViewCoupon() {
    this._couponUIDispatchers.closeViewCouponUI();
  }
  closeEditCoupon() {
    this._couponUIDispatchers.closeEditCouponUI();
  }
  getNextPage(): void {
    this._couponUIDispatchers.getNextCouponPage();
  }
  getPrevPage(): void {
    this._couponUIDispatchers.getPrevCouponPage();
  }
  resetPaging(status?: string, expire?: string): void {
    this._couponUIDispatchers.resetCouponPaging(status, expire);
  }

  get couponUiState$() {
    return this._rootStoreSelector.couponUiState$;
  }
  get getCouponList$() {
    return this._rootStoreSelector.getCouponList$();
  }
}
