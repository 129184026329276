import { Store } from '@ngrx/store';
import * as MediaCenterActions from './mediaCenter.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MediaCenterDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    /** Gallery */
    getMyMediaGallery() {
        this._store.dispatch(new MediaCenterActions.GetMyMediaGallery());
    }
    myMediaGalleryRcvd(rcvd) {
        this._store.dispatch(new MediaCenterActions.MyMediaGalleryRcvd(rcvd));
    }
    /***************************************** */
    /** Media Center */
    mediaCenterUploading(localFile, fileType, uploadPrams) {
        this._store.dispatch(new MediaCenterActions.MediaCenterUploading(localFile, fileType, uploadPrams));
    }
    mediaCenterUploadingProcessed(localMedia, uploadPrams) {
        this._store.dispatch(new MediaCenterActions.MediaCenterUploadingProcessed(localMedia, uploadPrams));
    }
    updateMediaCenterLoadingProgress(localMedia, progress) {
        this._store.dispatch(new MediaCenterActions.UpdateMediaCenterLoadingProgress(localMedia, progress));
    }
    updateMediaCenterStatus(msg, status, url, file) {
        this._store.dispatch(new MediaCenterActions.UpdateMediaCenterStatus(msg, status, url, file));
    }
    deleteMediaCenterItem(msg) {
        this._store.dispatch(new MediaCenterActions.DeleteMediaCenterItem(msg.filename));
    }
}
MediaCenterDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaCenterDispatchers_Factory() { return new MediaCenterDispatchers(i0.ɵɵinject(i1.Store)); }, token: MediaCenterDispatchers, providedIn: "root" });
