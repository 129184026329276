import { IProfile } from 'src/models/IProfile';
import { THEME_COLORS } from 'src/models/constants';
import { ProfileDetails } from 'src/models/ProfileDetails';

export class ProfileMiddleware {
  public static isNewProfile(senderID: string, state: IProfile[]): boolean {
    if (this.isContactExist(senderID, state)) {
      return false;
    } else {
      return true;
    }
  }

  public static isContactExist(contactId: string, contactList: IProfile[]) {
    const profile = contactList.find(prof => prof.user_id === contactId);
    if (profile) {
      return true;
    } else {
      return false;
    }
  }

  public static initProfile(profileDetails: ProfileDetails): IProfile {
    const profile: IProfile = {};
    profile.user_id = profileDetails.id;
    profile.name = profileDetails.name;
    profile.last_seen = profileDetails.last_seen;
    profile.online = profileDetails.online;
    profile.status = profileDetails.status;
    profile.version = profileDetails.version;
    profile.isBot = profileDetails.is_bot;
    profile.filter = profileDetails.filter;
    profile.isPublished = profileDetails.is_published;
    profile.about = profileDetails.about;
    profile.botType = profileDetails.type;
    profile.inline = profileDetails.inline;
    profile.domain = profileDetails.domain;
    profile.isPublic = profileDetails.is_public;
    profile.shortName = profileDetails.short_name;
    profile.email = profileDetails.email;
    profile.disallowGroup = profileDetails.disallow_group;

    if (profileDetails.image) {
      profile.imageThumbnail = `data:image/jpeg;base64,${profileDetails.image}`;
    } else {
      profile.imageThumbnail = null;
    }
    if (profileDetails.photo) {
      profile.image = profileDetails.photo.permanentUrl;
      profile.photo_id = profileDetails.photo.id;
      profile.photo_height = profileDetails.photo.height;
      profile.photo_width = profileDetails.photo.width;
      if (profileDetails.photo.thumbnail) {
        profile.thumbnail_id = profileDetails.photo.thumbnail.id;
        profile.thumbnail_width = profileDetails.photo.thumbnail.width;
        profile.thumbnail_height = profileDetails.photo.thumbnail.height;
      }
    }
    profile.backgroundColor =
      THEME_COLORS[Math.floor(Math.random() * THEME_COLORS.length)];
    return profile;
  }

  public static initProfileList(
    profiles: IProfile[],
    myBots?: boolean,
    templateBots?: boolean
  ): IProfile[] {
    return profiles.map(profile => {
      profile.contact = true;
      if (myBots) {
        profile.isMyBot = true;
      }
      if (templateBots) {
        profile.isTemplateBot = true;
      }
      return profile;
    });
  }

  public static addNewProfile(
    state: IProfile[],
    contact: IProfile
  ): IProfile[] {
    if (!state.find(prof => prof.user_id === contact.user_id)) {
      return state.length > 0 ? [contact, ...state] : [contact];
    } else {
      return state;
    }
  }
}
