import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as AppReleaseActions from './appRelease.actions';
import { AppReleaseActionTypes } from './appRelease.actions';
import { map } from 'rxjs/operators';

import * as AppReleasesMethods from 'src/models/AppRelease';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';
import {
  GetAppRealseVerData,
  SetAppleKeyCode
} from 'src/models/AppPublishInfo';

@Injectable()
export class AppReleaseEffects {
  @Effect({ dispatch: false })
  listAppReleases = this.actions$.pipe(
    ofType(AppReleaseActionTypes.LIST_APP_RELEASES),
    map((action: AppReleaseActions.ListAppReleases) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.ListAppRelease()
      );
    })
  );

  @Effect({ dispatch: false })
  listAppReleaseId = this.actions$.pipe(
    ofType(AppReleaseActionTypes.LIST_APP_RELEASE_ID),
    map((action: AppReleaseActions.ListAppReleaseId) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.ListAppReleaseId(action.payload.id)
      );
    })
  );

  @Effect({ dispatch: false })
  removeAppRelease = this.actions$.pipe(
    ofType(AppReleaseActionTypes.REMOVE_APP_RELEASE),
    map((action: AppReleaseActions.RemoveAppRelease) => {
      this._socketGateway.sendSocketMessage(
        new AppReleasesMethods.RemoveAppRelease(action.id)
      );
    })
  );

  @Effect({ dispatch: false })
  needAppleKey = this.actions$.pipe(
    ofType(AppReleaseActionTypes.SHOW_APPLE_KEY_FORM),
    map((action: AppReleaseActions.ShowAppleKeyForm) => {
      this._appMgmtService.showAppleForm(action.release_id);
    })
  );

  @Effect({ dispatch: false })
  sendAppleKeyCode = this.actions$.pipe(
    ofType(AppReleaseActionTypes.SEND_APPLE_KEY_CODE),
    map((action: AppReleaseActions.SendAppleKeyCode) => {
      this._socketGateway.sendSocketMessage(
        new SetAppleKeyCode(action.code, action.release_id)
      );
    })
  );

  @Effect({ dispatch: false })
  getAppRealseVerData = this.actions$.pipe(
    ofType(AppReleaseActionTypes.GET_APP_RELEASE_VER),
    map((action: AppReleaseActions.GetAppVer) => {
      this._socketGateway.sendSocketMessage(
        new GetAppRealseVerData(action.release_id + '')
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _appMgmtService: AppMgmtService
  ) {}
}
