import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HideIfSubChatDirective } from './hide-if-sub-chat.directive';
import { ShowIfHasPrivilegeDirective } from './show-if-has-privilege.directive';
import { CancelActionIfNotSubscribedDirective } from './cancel-action-if-not-subscribed.directive';
import { CancelClickIfNotSubscribedDirective } from './cancel-click-if-not-subscribed.directive';
import { HideIfMainChatDirective } from './hide-if-main-chat.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HideIfSubChatDirective,
    ShowIfHasPrivilegeDirective,
    CancelActionIfNotSubscribedDirective,
    CancelClickIfNotSubscribedDirective,
    HideIfMainChatDirective
  ],
  exports: [
    HideIfSubChatDirective,
    ShowIfHasPrivilegeDirective,
    CancelActionIfNotSubscribedDirective,
    CancelClickIfNotSubscribedDirective,
    HideIfMainChatDirective
  ]
})
export class AccessorsModule {}
