import { Store } from '@ngrx/store';
import * as AppConfigActions from './appConfig.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppConfigDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    appConfigDataChanged(onlineAppConfig, offlineAppConfig) {
        this._store.dispatch(new AppConfigActions.AppConfigDataChanged(onlineAppConfig, offlineAppConfig));
    }
    requestAppConfig() {
        this._store.dispatch(new AppConfigActions.RequestAppConfig());
    }
    requestConfigQrCode() {
        this._store.dispatch(new AppConfigActions.RequestConfigQrCode());
    }
    receiveAppConfig(onlineAppConfig, offlineAppConfig) {
        this._store.dispatch(new AppConfigActions.ReceiveAppConfig(onlineAppConfig, offlineAppConfig));
    }
    receiveDefaultAppConfig(payload) {
        this._store.dispatch(new AppConfigActions.ReceiveDefaultAppConfig(payload));
    }
    setAppConfig(channelConfOnly, selectedPageNumber, featuresId) {
        this._store.dispatch(new AppConfigActions.SetAppConfig(channelConfOnly, selectedPageNumber, featuresId));
    }
    setApp(userData) {
        this._store.dispatch(new AppConfigActions.SetApp(userData));
    }
    publishAppConfig(isChannelOnly, track, versionName, versionDesc) {
        this._store.dispatch(new AppConfigActions.PublishAppConfig(isChannelOnly, track, versionName, versionDesc));
    }
    generateApp(appId, os, android_format, ver_name, ver_desc) {
        this._store.dispatch(new AppConfigActions.GenerateApp(appId, os, android_format, ver_name, ver_desc));
    }
    updateAppInfo(appInfo) {
        this._store.dispatch(new AppConfigActions.UpdateAppInfo(appInfo));
    }
    appConfigAck(onlineAppConfig, offlineAppConfig) {
        this._store.dispatch(new AppConfigActions.AppConfigAck(onlineAppConfig, offlineAppConfig));
    }
    appConfigPublished(isChannelOnly) {
        this._store.dispatch(new AppConfigActions.AppConfigPublished(isChannelOnly));
    }
    requestAppTemplate(templateId) {
        this._store.dispatch(new AppConfigActions.RequestAppTemplate(templateId));
    }
    receiveAppTemplate(onlineAppConfig, offlineAppConfig, onlineAppConfigIOS, offlineAppConfigIOS) {
        this._store.dispatch(new AppConfigActions.ReceiveAppTemplate(onlineAppConfig, offlineAppConfig, onlineAppConfigIOS, offlineAppConfigIOS));
    }
    updateAppColors(appColors) {
        this._store.dispatch(new AppConfigActions.UpdateAppColors(appColors));
    }
    uploadAppColoredLogo(selectedFile) {
        this._store.dispatch(new AppConfigActions.UploadAppColoredLogo(selectedFile));
    }
    uploadAppChatImage(selectedFile) {
        this._store.dispatch(new AppConfigActions.UploadAppChatIcon(selectedFile));
    }
    uploadAppImage(selectedFile) {
        this._store.dispatch(new AppConfigActions.UploadAppIcon(selectedFile));
    }
    uploadAppColoredLogoSuccess(imageId, imageUrl, imageSet, dominantColor) {
        this._store.dispatch(new AppConfigActions.UploadAppColoredLogoSuccess(imageId, imageUrl, imageSet, dominantColor));
    }
    uploadAppWhiteLogo(selectedFile) {
        this._store.dispatch(new AppConfigActions.UploadAppWhiteLogo(selectedFile));
    }
    getDomainatColor(selectedFile) {
        this._store.dispatch(new AppConfigActions.GetDomainatColor(selectedFile));
    }
    uploadAppWhiteLogoSuccess(imageUrl, imageSet) {
        this._store.dispatch(new AppConfigActions.UploadAppWhiteLogoSuccess(imageUrl, imageSet));
    }
    uploadIosStoreLogo(selectedFile) {
        this._store.dispatch(new AppConfigActions.UploadIosStoreLogo(selectedFile));
    }
    uploadIosStoreLogoSuccess(imageUrl) {
        this._store.dispatch(new AppConfigActions.UploadIosStoreLogoSuccess(imageUrl));
    }
    createAppTemplate(androidImageUrl, iosImageUrl, categories, name, order, id) {
        this._store.dispatch(new AppConfigActions.CreateAppTemplate(androidImageUrl, iosImageUrl, categories, name, order, id));
    }
    saveInAppPurchases(json) {
        this._store.dispatch(new AppConfigActions.SetInAppPurchases(json));
    }
    UpldTempImageToProfile(imageId) {
        this._store.dispatch(new AppConfigActions.UploadProfileImage(imageId));
    }
    UpdateDominatColor(color) {
        this._store.dispatch(new AppConfigActions.UploadAppDomaintColor(color));
    }
}
AppConfigDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigDispatchers_Factory() { return new AppConfigDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppConfigDispatchers, providedIn: "root" });
