import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angular2-qrcode';
// Module
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
// Pipes
import { SearchPipe } from './pipes/search/search.pipe';
import { TwemojiPipe } from './pipes/twemoji/twemoji.pipe';
import { NickNamePipe } from './pipes/nickname/nick-name.pipe';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { MultiLinePipe } from './pipes/multi-line/multi-line.pipe';
import { CountSizePipe } from './pipes/count-size/count-size.pipe';
import { KeyValuesPipe } from './pipes/key-values/key-values.pipe';
import { LocalTypePipe } from './pipes/local-type/local-type.pipe';
import { KeepHtmlPipe } from './pipes/keep-html/keep-html.pipe';
import { MediaDurationPipe } from './pipes/media-duration/media-duration.pipe';
// Directives
import { MessageStatusIconDirective } from './directives/message-status-icon/message-status-icon.directive';
import { MessageTypesIconDirective } from './directives/message-types-icon/message-types-icon.directive';
import { CancelEventDirective } from './directives/cancel-event/cancel-event.directive';
import { AutoFocusDirective } from './directives/auto-focus/auto-focus.directive';
import { CancelDragDirective } from './directives/cancel-drag/cancel-drag.directive';
import { ClickElsewhereDirective } from './directives/click-elsewhere/click-elsewhere.directive';
// Components
import { ProfileCellComponent } from './components/profile-cell/profile-cell.component';
import { StandardDateComponent } from './components/standard-date/standard-date.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { PostShareComponent } from './components/post-share/post-share.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { EmptySectionComponent } from './components/empty-section/empty-section.component';
import { ChannelShareComponent } from './components/channel-share/channel-share.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { LightboxContainerComponent } from './components/lightbox-container/lightbox-container.component';
import { NoSupportComponent } from './components/no-support/no-support.component';
import { BorderQrCodeBtnComponent } from './components/border-qr-code-btn/border-qr-code-btn.component';
import { TranslateModule } from '@ngx-translate/core';
import { LagSelectorComponent } from './components/lang-selector/lang-selector.component';
import { AbstractTooltip } from '../abstract-tooltip/abstract-tooltip.module';
import { ProfileImageBlockComponent } from './components/profile-image-block/profile-image-block.component';
import { NgxModule } from '../ngx.module';
import { MatchPipe } from './pipes/match/match.pipe';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClipboardModule,
    QRCodeModule,
    AbstractBtnsModule,
    TranslateModule,
    AbstractTooltip,
    NgxModule
  ],
  declarations: [
    /* Pipes */
    SearchPipe,
    TwemojiPipe,
    CountSizePipe,
    KeyValuesPipe,
    MultiLinePipe,
    FileSizePipe,
    NickNamePipe,
    KeepHtmlPipe,
    LocalTypePipe,
    MediaDurationPipe,
    MatchPipe,
    /* Directives */
    AutoFocusDirective,
    CancelEventDirective,
    CancelDragDirective,
    MessageStatusIconDirective,
    MessageTypesIconDirective,
    ClickElsewhereDirective,
    /* Components */
    ProfileCellComponent,
    ProfileImageComponent,
    StandardDateComponent,
    PostShareComponent,
    ModalContainerComponent,
    LightboxContainerComponent,
    SearchFormComponent,
    EmptySectionComponent,
    ChannelShareComponent,
    QrScannerComponent,
    NoSupportComponent,
    LagSelectorComponent,
    BorderQrCodeBtnComponent,
    ProfileImageBlockComponent
  ],
  exports: [
    /* Pipes */
    SearchPipe,
    TwemojiPipe,
    CountSizePipe,
    KeyValuesPipe,
    MultiLinePipe,
    FileSizePipe,
    NickNamePipe,
    KeepHtmlPipe,
    LocalTypePipe,
    MediaDurationPipe,
    MatchPipe,
    /* Directives */
    AutoFocusDirective,
    CancelEventDirective,
    CancelDragDirective,
    MessageStatusIconDirective,
    MessageTypesIconDirective,
    ClickElsewhereDirective,
    /* Components */
    ProfileCellComponent,
    ProfileImageComponent,
    StandardDateComponent,
    PostShareComponent,
    ModalContainerComponent,
    LightboxContainerComponent,
    SearchFormComponent,
    EmptySectionComponent,
    ChannelShareComponent,
    LagSelectorComponent,
    QrScannerComponent,
    NoSupportComponent,
    BorderQrCodeBtnComponent,
    ProfileImageBlockComponent
  ]
})
export class SharedModule {}
