<!-- <div
  *ngIf="
    message?.mediaStatus === 'DOWNLOADED' ||
    message?.mediaStatus === 'UPLOADED' ||
    chat?.isAdmin
  "
  class="btn-group clickable-cursor"
  dropdown
>
  <div dropdownToggle type="button">
    <img
      src="./assets/img/post-heade-more.svg"
      class="post-header-more"
      alt="more"
    />
  </div>
  <ul *dropdownMenu class="dropdown-menu" role="menu">
    <li *ngIf="isDownloadable" role="menuitem" type="button">
      <a class="dropdown-item" [href]="media" [download]="message?.media_id"
        >Download</a
      >
    </li> -->
<!--
    <li *ngIf="chat?.isAdmin"
      role="menuitem"
      type="button"
      (click)="recallEvent()">
      <a class="dropdown-item">Delete</a>
    </li>
    <li *ngIf="chat?.isAdmin && message?.loggedInIsSender"
      role="menuitem"
      type="button"
      (click)="editEvent()">
      <a class="dropdown-item">Edit</a>
    </li>
    <li *ngIf="chat?.isAdmin"
      role="menuitem"
      type="button"
      (click)="repostEvent()">
      <a class="dropdown-item">Repost</a>
    </li>
  -->
<!-- </ul>
</div> -->

<div class="actions-container">
  <div
    *ngIf="isAdmin"
    class="action delete clickable-cursor"
    (click)="recallEvent()"
  >
    X
  </div>

  <!-- <div
      *ngIf="isAdmin && loggedInIsSender"
      class="action clickable-cursor"
      (click)="editEvent()"
    >
        <app-edit-icon></app-edit-icon>
    </div>

    <div *ngIf="isAdmin" class="action clickable-cursor" (click)="repostEvent()">
      <img src="./assets/img/repost-action.svg" alt="repost" />
    </div>  -->

  <a *ngIf="isMenu" class="action clickable-cursor" (click)="statsEvent()">
    <img src="./assets/img/analytics.svg" alt="repost" />
  </a>
  <a
    *ngIf="isDownloadable"
    class="action clickable-cursor"
    [href]="media"
    [download]="mediaId"
  >
    <img src="./assets/img/download-action.svg" alt="repost" />
  </a>
</div>
