<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">
    Select Poll
  </ng-container>

  <!-- <ng-container ngProjectAs="[form-selector-search]">
      <app-search-form (searchFormSubmitNotfiy)="onSearchFormSubmit($event)">
      </app-search-form>
    </ng-container> -->

  <!-- <ng-container ngProjectAs="[form-selector-create]">
      <app-create-item-frame>
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new Calender
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add new calendar from the "Add new calendar” field. Simply click
          the “+ Add new calendar” button and Create new calendar.
        </ng-container>
      </app-create-item-frame>
    </ng-container> -->

  <ng-container ngProjectAs="[form-selector-items]">
    <ng-container *ngIf="menusService.botMenus$ | async as botMenus">
      <ng-container *ngIf="botMenus?.length > 0; else isEmpty">
        <app-round-paging-btn
          class="paging-float-btn"
          (nextBtnClicked)="menusService.getNextPage()"
          (previousBtnClicked)="menusService.getPrevPage()"
        >
        </app-round-paging-btn>
        <app-selectable-cell-frame
          *ngFor="let menu of botMenus; trackBy: TrackByFunction"
          [symbolColor]="menuFet?.symbolColor"
          [symbolImage]="menuFet?.symbolImage"
          [coverColor]="menuFet?.coverColor"
          [title]="menu?.menu_name"
          [selectorId]="menu?.id"
          [isSelected]="menu?.id === currentSelectMessage?.id"
          (checkClick)="setSelectMessage(menu)"
          (checkChanged)="resetSelectMessage()"
        >
        </app-selectable-cell-frame>
      </ng-container>
    </ng-container>

    <ng-template #isEmpty>
      <app-empty-item-frame
        [emptySymbol]="menuFet?.symbolImage"
        [emptyTitle]="menuFet?.emptyTitle"
        [emptyDesc]="menuFet?.emptyPoolDesc"
      >
      </app-empty-item-frame>
    </ng-template>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn
        *ngIf="currentSelectMessage"
        submit
        (btnClicked)="insertEvent()"
      >
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>
