<app-form-frame>
  <ng-container *ngIf="chatEdit?.id" ngProjectAs="title">
    {{ chatEdit?.title }}
  </ng-container>
  <ng-container *ngIf="!chatEdit?.id" ngProjectAs="title">
    Create {{ subgroupService?.chatType && !createPopup ? chatName : '' }}
  </ng-container>
  <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
    <app-headerfull-frame
      *ngIf="chatEdit?.id"
      border-modal
      [title]="'Main Settings'"
      [showClose]="false"
    >
      <!-- <ng-container ngProjectAs="notify-action">ho</ng-container> -->
      <div class="form-group">
        <div class="form-inline">
          <label
            for="inputGroupName"
            class="control-label md"
            style="display: flex; align-items: center;"
          >
            Image

            <app-hover-tooltip-icon
              [title]="hoverImageTitle"
              [description]="hoverImageDescription"
              [top]="-40"
              [left]="30"
            ></app-hover-tooltip-icon>
          </label>
          <app-upld-img-with-title
            id="inputGroupName"
            [imageUrl]="image"
            [mimeType]="'image/png'"
            [cropFormat]="'png'"
            (imageCroppedUrlNotify)="getImage($event)"
            (composeMediaNotify)="onFilesSelected($event)"
          >
          </app-upld-img-with-title>
        </div>
      </div>

      <div class="form-group">
        <div class="form-inline">
          <div class="control-label md">
            ID
          </div>
          <app-reactive-locked-input [text]="chatEdit?.id">
          </app-reactive-locked-input>
        </div>
      </div>
      <app-reactive-text-input
        [filedType]="'text'"
        [isInline]="true"
        [filedLabel]="'Name'"
        [fieldId]="'inputSubgroupName'"
        [control]="form?.get('title')"
      >
      </app-reactive-text-input>
      <app-reactive-textarea-input
        [isInline]="true"
        [fieldId]="'inputDesc'"
        [filedLabel]="'Description'"
        [control]="form?.get('description')"
        [showTooltip]="true"
        [descriptionTooltip]="hoverIconSubChatDescription"
        [topTooltipHover]="-50"
        [leftTooltipHover]="30"
      >
      </app-reactive-textarea-input>
    </app-headerfull-frame>
    <!--search index-->
    <ng-container
      *ngIf="
        appManagementService?.activeSearchContainers$ | async as FiltersList
      "
    >
      <app-headerfull-frame
        *ngIf="chatEdit?.id && FiltersList?.length > 0"
        border-modal
        [title]="'Search Filter'"
        [showClose]="false"
        [showTooltip]="true"
        [titleTooltip]="'Search Filter'"
        [descriptionTooltip]="hoverSearchFilter"
        [topHoverTooltip]="-60"
        [leftHoverTooltip]="30"
      >
        <div class="form-inline space-inbetween">
          <ng-container *ngFor="let filter of FiltersList">
            <app-selector-input-area
              *ngIf="filter?.type === filterTypes?.AREA"
              [isInline]="false"
              [filedLabel]="subgroupService.chatType"
              [control]="form?.get('area')"
            >
            </app-selector-input-area>
          </ng-container>
          <ng-container *ngFor="let filter of FiltersList">
            <app-selector-input-category
              *ngIf="filter?.type === filterTypes?.CATEGORY"
              [isInline]="false"
              [filedLabel]="subgroupService.chatType"
              [control]="form?.get('category')"
            >
            </app-selector-input-category>
          </ng-container>
          <ng-container *ngFor="let filter of FiltersList">
            <app-selector-input-classification
              *ngIf="filter?.type === filterTypes?.CLASSIFICATIONS"
              [isInline]="false"
              [filedLabel]="subgroupService.chatType"
              [control]="form?.get('classification')"
            >
            </app-selector-input-classification>
          </ng-container>
        </div>
      </app-headerfull-frame>
    </ng-container>

    <app-headerfull-frame
      *ngIf="chatEdit?.id"
      border-no-padding
      [title]="'Splash'"
      [showClose]="false"
      [showTooltip]="true"
      [titleTooltip]="'Splash'"
      [descriptionTooltip]="hoverDescriptionSplash"
      [topHoverTooltip]="-75"
      [leftHoverTooltip]="30"
    >
      <div>
        <div class="form-inline space-inbetween" style="padding: 10px;">
          <p *ngIf="!toggelSwith" style="width: 100%;">
            Activate splash so that you can adjust its settings
          </p>
          <p *ngIf="toggelSwith" style="width: 100%;">
            Now Splash activated. Please complete the following settings
          </p>
          <app-switch-btn
            [isOn]="form?.get('splash').value"
            (switchIsOn)="toggelSwitch($event)"
          >
          </app-switch-btn>
        </div>
        <div *ngIf="form?.get('splash').value">
          <div style="border: 1px solid #d5d7e0; margin: 12px 0;"></div>
          <div style="padding: 10px;">
            <div class="form-group">
              <app-reactive-media-input
                [filedLabel]="'Splash Image'"
                [fieldId]="'Splash Image'"
                [isInline]="true"
                [control]="form?.get('splash_config')?.get('photo')"
                [displyControl]="form?.get('splash_config')?.get('image_url')"
                [showTooltip]="true"
                [descriptionTooltip]="hoverSplashImage"
                [topTooltipHover]="-50"
                [leftTooltipHover]="30"
              >
              </app-reactive-media-input>
            </div>
            <div style="margin: 12px 0;">
              <app-reactive-text-input
                [filedType]="'text'"
                [isInline]="true"
                [filedLabel]="'Title'"
                [fieldId]="'splashTitle'"
                [control]="form?.get('splash_config')?.get('title')"
              >
              </app-reactive-text-input>
            </div>
            <div style="margin: 12px 0;">
              <app-reactive-textarea-input
                [isInline]="true"
                [fieldId]="'Splash Description'"
                [filedLabel]="'Splash Description'"
                [control]="form?.get('splash_config')?.get('desc')"
                [showTooltip]="true"
                [descriptionTooltip]="hoverIconSubChatSplashDescription"
                [topTooltipHover]="-45"
                [leftTooltipHover]="30"
              >
              </app-reactive-textarea-input>
            </div>
          </div>
          <div style="border: 1px solid #d5d7e0; margin: 12px 0;"></div>
          <div class="form-inline space-inbetween" style="padding: 10px;">
            <div style="width: 100%;">
              <app-reactive-text-input
                [filedType]="'text'"
                [isInline]="false"
                [filedLabel]="'Button Text'"
                [fieldId]="'splashButtonText'"
                [control]="form?.get('splash_config')?.get('btn_text')"
                [showTooltip]="true"
                [descriptionTooltip]="
                  'Deliver your users a call to action in a button.'
                "
                [topTooltipHover]="-40"
                [leftTooltipHover]="30"
              >
              </app-reactive-text-input>
            </div>
            <app-reactive-color-input
              [colorSelected]="
                form?.get('splash_config')?.get('btn_bgcolor').value
              "
              [colorId]="'Button Background Color'"
              [colorLabel]="'Button Background Color'"
              [isInline]="false"
              (selectedColor)="colorChangedButtonBgColor($event)"
            >
            </app-reactive-color-input>
            <app-reactive-color-input
              [colorSelected]="
                form?.get('splash_config')?.get('btn_fgcolor').value
              "
              [colorId]="'splashButtonTextColor'"
              [colorLabel]="'Button Text Color'"
              [isInline]="false"
              (selectedColor)="colorChangedTextButtonColor($event)"
            >
            </app-reactive-color-input>
          </div>
        </div>
      </div>
    </app-headerfull-frame>

    <app-headerfull-frame
      *ngIf="
        chatEdit?.id &&
        chatEdit?.type === group &&
        tokenAuth?.ID === chatEdit?.owner_id
      "
      border-no-padding
      [title]="'Leave group option'"
      [showClose]="false"
    >
      <div class="form-inline space-inbetween" style="padding: 10px;">
        <p style="width: 100%;">
          Prevents your users from leaving the group.
        </p>
        <app-switch-btn
          [isOn]="form?.get('permanent').value == 0 ? false : true"
          (switchIsOn)="toggelPermanentSwitch($event)"
        >
        </app-switch-btn>
      </div>
    </app-headerfull-frame>
    <!-- Location -->
    <app-headerfull-frame
      *ngIf="chatEdit?.type === channel"
      border-modal
      [title]="'Location'"
      [showClose]="false"
    >
      <div class="form-group">
        <div class="form-inline">
          <ng-container *ngIf="selectedMapMarker; else noSelectedLocationText">
            <label for="actions" class="control-label md">
              Now you have a location. Add a new location from press Add
            </label>
          </ng-container>
          <ng-template #noSelectedLocationText>
            <label for="actions" class="control-label md">
              You have no location. Set your location now.
            </label>
          </ng-template>
          <div class="form-inline space-prefix">
            <app-border-btn
              *appShowIfHasPrivilege="privilegesName.listmarker"
              next
              (btnClicked)="toggleLocationsPoolVisabilty(true)"
            >
              Set
            </app-border-btn>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="selectedMapMarker">
        <div class="form-inline">
          <app-assign-text-action
            [title]="selectedMapMarker.title"
            [action]="selectedMapMarker"
            [hideRemove]="false"
            (remove)="removeSelectedMapMarkerAction($event)"
          ></app-assign-text-action>
        </div>
      </div>
    </app-headerfull-frame>
    <!-- Store -->
    <app-headerfull-frame
      *ngIf="chatEdit?.type === channel"
      border-modal
      [title]="'Store'"
      [showClose]="false"
    >
      <div class="form-group">
        <div class="form-inline">
          <ng-container *ngIf="selectedStore; else noSelectedStoreText">
            <label for="actions" class="control-label md">
              Now you have a Store. Add a new Store from press Add
            </label>
          </ng-container>
          <ng-template #noSelectedStoreText>
            <label for="actions" class="control-label md">
              You have no Store. Set your Store now.
            </label>
          </ng-template>
          <div class="form-inline space-prefix">
            <app-border-btn
              *appShowIfHasPrivilege="privilegesName.listmstore"
              next
              (btnClicked)="
                toggleStoresPoolVisabilty(selectedMapMarker ? 1 : 2)
              "
            >
              Set
            </app-border-btn>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="selectedStore">
        <div class="form-inline">
          <app-assign-text-action
            [title]="selectedStore.name"
            [action]="selectedStore"
            [hideRemove]="false"
            (remove)="removeSelectedStoreEvent($event)"
          ></app-assign-text-action>
        </div>
      </div>
    </app-headerfull-frame>
    <app-headerfull-frame
      *ngIf="chatEdit?.type === channel"
      border-modal
      [title]="'Ads'"
      [showClose]="false"
    >
      <div class="form-inline space-inbetween">
        <p style="width: 100%;">
          Toggle on this button to turn off ads on this channel.
        </p>

        <app-switch-btn
          [isOn]="form?.get('adFree').value == 0 ? false : true"
          (switchIsOn)="toggelSwitchAds($event)"
        >
        </app-switch-btn>
      </div>
    </app-headerfull-frame>
    <!-- Table -->
    <app-headerfull-frame
      *ngIf="chatEdit?.id"
      border-no-padding
      [title]="'Others'"
      [showClose]="false"
    >
      <app-reactive-table>
        <ng-container ngProjectAs="header-table-first">
          QR Code

          <app-hover-tooltip-icon
            [title]="'QR Code'"
            [description]="hoverIconQRCode"
            [top]="-50"
            [left]="30"
          ></app-hover-tooltip-icon>
        </ng-container>
        <ng-container ngProjectAs="header-table-tow">
          <div class="admin-contr">
            Admins

            <app-hover-tooltip-icon
              [title]="'Admins'"
              [description]="hoverIconAdmins"
              [top]="-40"
              [left]="30"
            ></app-hover-tooltip-icon>
          </div>
        </ng-container>
        <ng-container ngProjectAs="header-table-three">
          Invite Link

          <app-hover-tooltip-icon
            [title]="'Invite Link'"
            [description]="hoverIconInviteLink"
            [top]="-40"
            [left]="30"
          ></app-hover-tooltip-icon>
        </ng-container>
        <ng-container ngProjectAs="first-cell">
          <!-- <app-border-qr-code-btn></app-border-qr-code-btn> -->
          <app-qr-scanner
            [qrCodeText]="chatEdit?.inivite_link"
            [styleQROnEdit]="true"
            [styleInEditScreen]="true"
          ></app-qr-scanner>
        </ng-container>
        <ng-container ngProjectAs="second-cell">
          <div style="align-self: flex-end;">
            <app-add-btn
              (btnClicked)="membersAssign.showUsersModalEvent(true)"
            ></app-add-btn>
          </div>
          <div class="sort-text-admin" *ngIf="admins.length === 0">
            <p>
              No admins has been appointed
            </p>
            <a
              class="clickable-link"
              (click)="membersAssign.showUsersModalEvent(true)"
            >
              Add Now
            </a>
          </div>
          <app-members-assign
            #membersAssign
            [stayleOnEdit]="true"
            [showButton]="false"
            [admins]="admins"
            [chatId]="chatEdit?.id"
            [inform]="true"
            (addAdmin)="addAdmin($event)"
          >
          </app-members-assign>
        </ng-container>
        <ng-container ngProjectAs="third-cell">
          <app-channel-share
            [shareLink]="chatEdit?.inivite_link"
            [screenType]="subgroupService?.chatType"
          >
          </app-channel-share>
        </ng-container>
      </app-reactive-table>
    </app-headerfull-frame>

    <!--Welcome Message -->
    <app-headerfull-frame
      *ngIf="chatEdit?.id"
      border-modal
      [title]="'Welcome Message'"
      [showClose]="false"
      [showTooltip]="true"
      [titleTooltip]="'Welcome Message'"
      [descriptionTooltip]="
        'Show a warm welcome to your group visitors in a short message. Click reset to start creating your message again.'
      "
      [topHoverTooltip]="-70"
      [leftHoverTooltip]="30"
    >
      <div class="msg-form">
        <div class="form-inline space-between">
          <p *ngIf="!(selectedChatWelcomeMessage$ | async)?.welcome">
            You have no welcome messages. Set your message now.
          </p>
          <p *ngIf="selectedChatWelcomeMessage$ | async">
            Now you have a welcome message. And others press reset.
          </p>
          <div style="border-left: solid 1px #d5d7e0; padding: 5px 16px;">
            <app-border-btn
              *ngIf="!(selectedChatWelcomeMessage$ | async)?.welcome"
              next
              (btnClicked)="composeMessageEvent()"
            >
              Set Message
            </app-border-btn>
            <div
              *ngIf="selectedChatWelcomeMessage$ | async"
              style="position: relative;"
              (mouseover)="toggleTooltipVisibility(true, 'ResetMassage')"
              (mouseleave)="toggleTooltipVisibility(false, 'ResetMassage')"
            >
              <app-border-btn next (btnClicked)="composeMessageEvent()">
                Reset
              </app-border-btn>
              <app-hover-tooltip
                *ngIf="hoveResetMassageTooltip"
                [top]="130"
                [right]="-115"
                [text]="'Recreate your welcome message'"
                [width]="'max-content'"
              ></app-hover-tooltip>
            </div>
          </div>
        </div>
        <div
          *ngIf="chatEdit?.welcomeMessageId"
          class="form-inline"
          [ngStyle]="Style"
        >
          <label class="control-label md">
            Welcome Message
          </label>
          <app-post-cell
            class="no-copyable-item"
            [hideHeader]="true"
            [hideFooter]="true"
            [message]="selectedChatWelcomeMessage$ | async"
            (retryNotify)="retryEvent($event)"
            (cancelNotify)="cancelEvent($event)"
            (viewNotify)="viewMessageEvent($event)"
          >
          </app-post-cell>
          <!-- <button (click)="deleteWelcomeMassage()">delete</button> -->
        </div>
      </div>
    </app-headerfull-frame>

    <app-reactive-text-input
      *ngIf="!chatEdit?.id && chatType"
      [filedType]="'text'"
      [isInline]="true"
      [filedLabel]="'Name'"
      [fieldId]="'inputSubgroupName'"
      [control]="form?.get('title')"
    >
    </app-reactive-text-input>

    <app-reactive-textarea-input
      *ngIf="!chatEdit?.id && chatType"
      [isInline]="true"
      [fieldId]="'inputDesc'"
      [filedLabel]="'Description'"
      [control]="form?.get('description')"
    >
    </app-reactive-textarea-input>

    <app-reactive-radio-input
      *ngIf="!chatEdit?.id && !chatType"
      [isInline]="true"
      [filedLabel]="'Type'"
      [radioOptions]="typeOptions"
      [control]="form?.get('type')"
      (selectedOption)="isSelected($event)"
    >
    </app-reactive-radio-input>
    <app-reactive-text-input
      *ngIf="!chatEdit?.id && !chatType"
      [filedType]="'text'"
      [isInline]="true"
      [filedLabel]="nameLabelPopup"
      [fieldId]="'inputSubgroupName'"
      [control]="form?.get('title')"
    >
    </app-reactive-text-input>

    <app-reactive-textarea-input
      *ngIf="!chatEdit?.id && !chatType"
      [isInline]="true"
      [fieldId]="'inputDesc'"
      [filedLabel]="descriptionLabelPopup"
      [control]="form?.get('description')"
    >
    </app-reactive-textarea-input>

    <div class="form-group">
      <div class="form-submit">
        <app-border-btn submit [type]="'submit'">
          <ng-container *ngIf="chatEdit?.id">Save</ng-container>
          <ng-container *ngIf="!chatEdit?.id">
            Create
            {{
              subgroupService.chatType && !createPopup
                ? subgroupService.chatType
                : ''
            }}
          </ng-container>
        </app-border-btn>
        <app-border-btn cancel (btnClicked)="closeEvent()">
          Cancel
        </app-border-btn>
      </div>
    </div>
  </form>
</app-form-frame>
<app-modal-container
  *ngIf="locationsPoolVisibility"
  (cancelNotfiy)="toggleLocationsPoolVisabilty(false)"
>
  <app-locations-markers-pool
    (cancelNotfiy)="toggleLocationsPoolVisabilty(false)"
    [selectedMapMarker]="selectedMapMarker"
    (selectMapMarkerNotfiy)="setMapMarker($event)"
    [echoInput]="storesPoolVisibility === 2 ? 2 : null"
    [selectedChatId]="chatEdit?.id"
    (echoOutput)="locationsPoolEchoNotify($event)"
  ></app-locations-markers-pool>
</app-modal-container>
<app-modal-container
  *ngIf="storesPoolVisibility === 1"
  (cancelNotfiy)="toggleStoresPoolVisabilty(0)"
>
  <app-market-pool
    [prd]="false"
    [pck]="false"
    [mltipln]="false"
    [bundel]="false"
    [singleModeSinglePoolSelect]="true"
    [currentSelectMessage]="getSelectedStore()"
    [selectedChatId]="chatEdit?.id"
    (cancelNotfiy)="toggleStoresPoolVisabilty(0)"
    (selectProductNotfiy)="selectStoreEvent($event)"
  >
  </app-market-pool>
</app-modal-container>
