import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as ChatMemberActions from './chatMember.actions';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { ChatMemberMiddleware } from './chatMember.middleware';
import { ChatMemberDispatchers } from './chatMember.dispatchers';
import { ProfileDispatchers } from '../profile/profile.dispatchers';

import * as ProfileMethods from 'src/models/IProfile';
import * as ChatMemberMethods from 'src/models/IChatMember';
import { AppState } from 'src/models/AppState';
import { MainChat } from 'src/models/MainChat';
import { IChat } from 'src/models/IChat';
import { UserStatus, UserType } from 'src/models/constants';

import * as isEqual from 'lodash.isequal';
import { IChatMember } from 'src/models/IChatMember';

@Injectable()
export class ChatMemberEffects {
  @Effect({ dispatch: false })
  getChatMember = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.GET_CHAT_MEMBER),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <ChatMemberActions.GetChatMember>val;
      this._socketGateway.sendSocketMessage(
        new ChatMemberMethods.GetChatMember(
          selectedChat.id,
          action.payload.user_id
        )
      );
    })
  );

  @Effect({ dispatch: false })
  chatMemberReceiving = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.CHAT_MEMBER_RECEIVING),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.chatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.chatMemberReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat, chats, memberReducer]) => {
      const action = <ChatMemberActions.ChatMemberReceiving>val;
      this.initChatMemberBeforInsert(
        action.payload,
        selectedChat,
        chats,
        memberReducer
      );
    })
  );

  // After request list of members add it to store
  @Effect({ dispatch: false })
  chatMembersReceiving = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.CHAT_MEMBERS_RECEIVED),
    map(val => {
      const action = <ChatMemberActions.ChatMembersReceived>val;
      const profiles: ProfileMethods.IProfile[] = action.payload.map(ct => {
        // this._chatMemberDispatchers.getMemberChats(ct.user.id);
        this._chatMemberDispatchers.chatMemberReceiving(ct);
        // TODO: need to add ver in return object
        return { user_id: ct.user.id, version: ct.version };
      });
      this._profilesDispatchers.contactsReceived(profiles);
    })
  );

  @Effect({ dispatch: false })
  memberChatsReceived = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.MY_MEMBER_CHATS_RECEIVED),
    map(val => {
      const action = <ChatMemberActions.MyMemberChatsReceived>val;

      action.payload.members.map(ct => {
        const selectedChat: IChat = {};
        selectedChat.id = ct.chat.id;
        this._chatDispatcher.newChatReceived(selectedChat);
        this._chatDispatcher.requestChatDetails(selectedChat.id);
        this._chatMemberDispatchers.chatMemberReceiving(ct);
      });
    })
  );

  // after add to store request profile when no profile stored
  @Effect({ dispatch: false })
  chatMemberReceived = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.CHAT_MEMBER_RECEIVED),
    withLatestFrom(
      this._store
        .select(state => state.profileReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, profiles]) => {
      const action = <ChatMemberActions.ChatMemberReceived>val;
      const profile = profiles.find(
        pr => pr.user_id === action.payload.user_id
      );
      // IF No Profile we will request it
      if (!profile) {
        this._profilesDispatchers.unknownProfile(action.payload.user_id);
        setTimeout(() => {
          this._socketGateway.sendSocketMessage(
            new ProfileMethods.GetUser(action.payload.user_id)
          );
        }, 300);
      }
    })
  );

  @Effect({ dispatch: false })
  getMemberChats = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.GET_MEMBER_CHATS),
    map(val => {
      const action = <ChatMemberActions.GetMemberChats>val;
      this._socketGateway.sendSocketMessage(
        new ChatMemberMethods.GetMyMemberChats(
          action.payload,
          UserStatus.ACTIVE
        )
      );
    })
  );

  @Effect({ dispatch: false })
  promoteChatMember = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.PROMOTE_CHAT_MEMBER),
    withLatestFrom(
      this._store
        .select(st => st.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <ChatMemberActions.PromoteChatMember>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.PromoteChatMember(
            selectedChat.id,
            action.payload
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  addMemberToChat = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.ADD_MEMBER_TO_CHAT),
    map(val => {
      const action = <ChatMemberActions.AddMemberToChat>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.AddMemberToChat(
            action.payload.chat_id,
            action.payload.user_id,
            action.payload.recordIndex
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  addAdminToChat = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.ADD_ADMIN_TO_CHAT),
    map(val => {
      const action = <ChatMemberActions.AddAdminToChat>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.AddAdminToChat(
            action.payload.chat_id,
            action.payload.user_id
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  revokeAdminFromChat = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.REVOKE_ADMIN_FROM_CHAT),
    map(val => {
      const action = <ChatMemberActions.RevokeAdminFromChat>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.RemoveAdminFromChat(
            action.payload.chat_id,
            action.payload.user_id
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  revokeMemberFromChat = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.REVOKE_MEMBER_FROM_CHAT),
    map(val => {
      const action = <ChatMemberActions.RevokeMemberFromChat>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.RemoveMemberFromChat(
            action.payload.chat_id,
            action.payload.user_id
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  setChatMemberRole = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.SET_CHAT_MEMBER_ROLE),
    map(val => {
      const action = <ChatMemberActions.SetChatMemberRole>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.SetChatMemberRole(
            action.payload.chat_id,
            action.payload.user_id,
            action.payload.role
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  setChatMemberView = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.SET_CHAT_MEMBER_VIEW),
    map(val => {
      const action = <ChatMemberActions.SetChatMemberView>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.SetChatMemberView(
            action.payload.user_id,
            action.payload.view
          )
        );
      }
    })
  );

  @Effect({ dispatch: false })
  setMemberTesterFlag = this.actions$.pipe(
    ofType(ChatMemberActions.ChatMemberActionTypes.SET_MEMBER_TESTER_FLAG),
    map(val => {
      const action = <ChatMemberActions.SetMemberTesterFlag>val;
      if (action.payload) {
        this._socketGateway.sendSocketMessage(
          new ChatMemberMethods.SetMemberTesterFlag(
            action.payload.user_id,
            action.payload.tester
          )
        );
      }
    })
  );
  // @Effect({ dispatch: false })
  // getChatMembers = this.actions$.pipe(
  //   ofType(ChatMemberActions.ChatMemberActionTypes.LIST_CHAT_MEMBERS),
  //   map(val => {
  //     const action = <ChatMemberActions.ListChatMembers>val;

  //     this._socketGateway.sendSocketMessage(
  //       new ChatMemberMethods.GetChatMembers(action.chat_id)
  //     );
  //   })
  // );

  constructor(
    private _store: Store<AppState>,
    private actions$: Actions,
    private _chatMemberDispatchers: ChatMemberDispatchers,
    private _chatDispatcher: ChatDispatchers,
    private _profilesDispatchers: ProfileDispatchers,
    private _socketGateway: SocketGateway
  ) {}

  initChatMemberBeforInsert(
    payload: ChatMemberMethods.ChatMember,
    selectedChat: MainChat,
    chats: IChat[],
    memberReducer
  ) {
    let roles = [];
    const targetChat = chats.find(chat => chat.id === selectedChat.id);
    if (targetChat && targetChat.chatRoles) {
      roles = targetChat.chatRoles;
    }

    // const roles = chats.find(chat => chat.id === selectedChat.id).chatRoles
    //   ? chats.find(chat => chat.id === selectedChat.id).chatRoles
    //   : [];

    // chats.find(chat => chat.id === selectedChat.id).chatRoles || [];

    let selectedRole: IChatMember = {};
    const targetMember = memberReducer.find(
      member => member.user_id === payload.user.id
    );
    const targetMemberChat = memberReducer.find(
      member => member.chat_id === payload.chat.id
    );

    if (targetMember) {
      selectedRole = targetMember;
    }
    // const selectedRole = memberReducer.find(
    //   member => member.user_id === payload.user.id
    // )
    //   ? memberReducer.find(member => member.user_id === payload.user.id)
    //   : [];
    if (
      !payload.role &&
      selectedRole.role &&
      selectedRole.type === UserType.ADMIN &&
      payload.type === UserType.ADMIN &&
      payload.chat.id === selectedRole.chat_id
    ) {
      payload.role = selectedRole.role;
    } else if (
      !payload.role &&
      targetMemberChat &&
      targetMemberChat.role &&
      targetMemberChat.type === UserType.ADMIN &&
      payload.type === UserType.ADMIN &&
      targetMemberChat.chat_id === payload.chat.id
    ) {
      payload.role = targetMemberChat.role;
    } else if (!payload.role && payload.type === UserType.ADMIN) {
      payload.role = 3;
    }
    let roleName = null;
    const localMember = ChatMemberMiddleware.initChatMember(payload, true);
    if (localMember.role && roles && roles.length > 0) {
      roleName = roles.find(role => role.id === localMember.role).name;
    } else {
      roleName = payload.type;
    }
    localMember.role_name = roleName || '';
    this._chatMemberDispatchers.chatMemberReceived(localMember);
  }
}
