/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../cells/link-preview-cell/link-preview-cell.component.ngfactory";
import * as i3 from "../../../../cells/link-preview-cell/link-preview-cell.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i6 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i7 from "./preview-link.component";
var styles_PreviewLinkComponent = [i0.styles];
var RenderType_PreviewLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewLinkComponent, data: {} });
export { RenderType_PreviewLinkComponent as RenderType_PreviewLinkComponent };
function View_PreviewLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-preview-cell", [["class", "compose-component wrap-text"]], null, null, null, i2.View_LinkPreviewCellComponent_0, i2.RenderType_LinkPreviewCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.LinkPreviewCellComponent, [], { url: [0, "url"], title: [1, "title"], description: [2, "description"], image: [3, "image"], width: [4, "width"], height: [5, "height"], rootUrl: [6, "rootUrl"], style: [7, "style"], showSite: [8, "showSite"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewUrl); var currVal_1 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewTitle); var currVal_2 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewDescription); var currVal_3 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewImage); var currVal_4 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewWidth); var currVal_5 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewHeight); var currVal_6 = ((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewRootUrl); var currVal_7 = _co.stylesComponent; var currVal_8 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_PreviewLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "compose-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin"]], null, null, null, null, null))], null, null); }
function View_PreviewLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "compose-loader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We can't get any link Preview to this link we will send it as text "]))], null, null); }
export function View_PreviewLinkComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "compose-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewLinkComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewLinkComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewLinkComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), i1.ɵncd(null, 1), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "form-submit"]], null, null, null, null, null)), i1.ɵncd(null, 3), i1.ɵncd(null, 4), i1.ɵncd(null, 5), (_l()(), i1.ɵeld(16, 0, null, null, 2, "app-border-btn", [["cancel", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BorderBtnComponent_0, i5.RenderType_BorderBtnComponent)), i1.ɵdid(17, 573440, null, 0, i6.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Clear "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FETCHED); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FETCHING); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.instantArticle == null) ? null : _co.instantArticle.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FAILED); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_PreviewLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-link", [], null, null, null, View_PreviewLinkComponent_0, RenderType_PreviewLinkComponent)), i1.ɵdid(1, 49152, null, 0, i7.PreviewLinkComponent, [], null, null)], null, null); }
var PreviewLinkComponentNgFactory = i1.ɵccf("app-preview-link", i7.PreviewLinkComponent, View_PreviewLinkComponent_Host_0, { stylesComponent: "stylesComponent", instantArticle: "instantArticle" }, { cancelNotfiy: "cancelNotfiy" }, ["[message-style]", "[message-attachs]", "[message-target]", "[footer-tag]", "[footer-submit]", "[footer-cancel]"]);
export { PreviewLinkComponentNgFactory as PreviewLinkComponentNgFactory };
