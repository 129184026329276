import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrScannerComponent {
  @Input() title: string;
  @Input() qrCodeText: string;
  @Input() styleQROnEdit = false;
  @Input() styleInEditScreen = false;
  @Input() screenType: string;

  constructor() {}

  printEvent() {
    window.print();
  }
  // TODO: open share window
  shareEvent() {}
}
