<app-headerless-closable-frame
  (closeNotify)="cancelEvent()"
  [showCancel]="showCancel"
>
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">
        {{ title ? title : 'Error Message' }}
      </ng-container>
      <ng-container ngProjectAs="[form-body]">
        <div class="form-group">
          <div class="call" [innerHTML]="message"></div>
        </div>
        <div class="form-submit">
          <app-border-btn
            cancel
            (btnClicked)="cancelEvent()"
            *ngIf="!validationMsg"
          >
            Ok
          </app-border-btn>
          <app-border-btn
            cancel
            (btnClicked)="cancelEvent()"
            *ngIf="validationMsg"
          >
            {{ cancelTitle }}
          </app-border-btn>
          <app-border-btn
            submit
            (btnClicked)="agreeEvent()"
            *ngIf="validationMsg"
          >
            {{ validationTitle }}
          </app-border-btn>
        </div>
      </ng-container>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame>
