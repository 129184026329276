<div
  class="contact clickable-cursor"
  (click)="getReplies()"
  [class.selected]="!member?.lastMessage?.lastReplyIsRead"
>
  <app-profile-image
    class="avtar"
    [id]="member?.profile?.user_id"
    [chatType]="chatType"
    [size]="40"
  >
  </app-profile-image>
  <div class="core truncate-text">
    <div class="header">
      <span
        class="name text-capitalize text-muted"
        [innerHTML]="member?.profile?.name | twemoji"
      >
      </span>
    </div>
    <div class="body text-muted truncate-text" dir="auto">
      <svg
        *ngIf="member?.lastMessage?.lastReplyType"
        class="svg-icon icon-icon"
      >
        <use
          [appMessageTypesIcon]="member?.lastMessage?.lastReplyType"
          xlink:href=""
        ></use>
      </svg>

      <span
        *ngIf="
          member?.lastMessage?.lastReplyType &&
          !member?.lastMessage?.lastReplyMessage
        "
        class="truncate-text"
        dir="auto"
        [innerHTML]="member?.lastMessage?.lastReplyType | localType"
      >
      </span>
      <span
        *ngIf="member?.lastMessage?.lastReplyMessage"
        class="truncate-text"
        dir="auto"
        [innerHTML]="member?.lastMessage?.lastReplyMessage | twemoji"
      >
      </span>
    </div>
    <div class="footer">
      <svg
        *ngIf="member?.lastMessage?.lastReplyStatus"
        class="svg-icon icon-icon"
      >
        <use
          [appMessageStatusIcon]="member?.lastMessage?.lastReplyStatus"
          xlink:href=""
        ></use>
      </svg>
      <app-standard-date
        [timestamp]="member?.lastMessage?.lastReplyTime"
        class="time"
      >
      </app-standard-date>
      <span class="time">.</span>
      <span class="reply-btn" (click)="getReplies()">Reply</span>
    </div>
  </div>
</div>
