import { environment } from '../environments/environment';
import { IOption } from './campaign';
import { AppColors, Posts } from './ChannelAppConfig';

// ------------ Environment variables ------------------------------------------------------------------

export const ENV_NAME = environment.env_name;
export const IS_PRODUCTION = environment.production;
export const SENTRY_DNS = environment.sentry_dns;
export const GOOGLE_ANALYTICS_KEY = environment.googleAnalyticsKey;
// export const GOOGLE_ANALYTICS_SECOND_KEY =
//   environment.googleAnalyticsKeySecondKey;
export const MAP_API_KEY = environment.MAP_API_KEY;
export const YOUTUBE_API_CHANNEL_LINK = environment.YOUTUBE_API_CHANNEL_LINK;
export const YOUTUBE_API_PLAYLIST_LINK = environment.YOUTUBE_API_PLAYLIST_LINK;
export const MAP_AUTOCOMPLETE_API_LINK = environment.MAP_AUTOCOMPLETE_API_LINK;
export const MAP_PLACE_API_LINK = environment.MAP_PLACE_API_LINK;
export const GET_COUNTRY_DATA_API = environment.COUNTRY_DATA_API_LINK;
export const PLANS_DATA_API_LINK = environment.PLANS_DATA_API_LINK;
export const SMTP_API_LINK = environment.SMTP_API_LINK;
export const AVR_COLOR_LINK = environment.AVR_COLOR_LINK;
export const SIGNUP_LINK = environment.signup_link;
export const TERMS_LINK = environment.terms_link;
export const PRIVACY_LINK = environment.privacy_link;
export const DEFAULT_BASE_URL_API = environment.BASEURL;
export const CHANNEL_URL_API_SUFFIX = environment.CHANNELAPISUFFIX;
export const WS_URL_API_SUFFIX = environment.WSURLAPISUFFIX;
export const DOWNLOAD_URL_API_SUFFIX = environment.DOWNLOADURLAPISUFFIX;
export const UPLOAD_URL_API_SUFFIX = environment.UPLOADURLAPISUFFIX;
export const META_URL_API_SUFFIX = environment.METAURLAPISUFFIX;
export const CANCEL_PAY_SUFFIX = environment.CANCELPAYSUFFIX;
export const WEB_VERSION = environment.WEB_VERSION;
export const SHARE_LINK = environment.SHARE_LINK;
export const PAGE_LINK = environment.PAGE_LINK;
export const PAY_LINK = environment.PAY_LINK;
export const MAIN_WEB = environment.MAINWEB;
export const LICENSES = environment.LICENSES;
export const PROJECT_NAME = environment.PROJECT_NAME;
// use this names to compare and add new one if you plan to add new project on top of common core modules
export const MERCHANT_PROJECT = 'MERCHANT';
export const INDIVIDUAL_PROJECT = 'INDIVIDUAL';

export const IOS_DEFAULT_ICON_COLOR = '#007AFF';
export const IOS_DEFAULT_COLOR = '#8E8E93';
// ------------ Methods and Callbacks API From The server ---------------------------------------------------

export const SEND_QR_GET_METHOD = 'QR_CODE_GET';
export const SEND_PING_METHOD = 'PING';
export const SEND_TOKEN_AUTH_METHOD = 'TOKEN_AUTH';
export const SEND_MESSAGE_RECALL_METHOD = 'recallMessage';
export const SEND_INLINE_MESSAGE_CALLBACK_METHOD = 'sendInlineMessageCallback';
export const SEND_MESSAGE_DELETE_METHOD = 'deleteMessage';
export const SEND_MESSAGE_SEEN_METHOD = 'setMessageSeen';
export const SEND_MESSAGE_SCHEDULE_METHOD = 'MSG_SCHEDULE';
export const CONTACTS_GET_METHOD = 'getContacts';
export const GET_USER_METHOD = 'getUser';
export const GET_MY_PROFILES_METHOD = 'getMyProfiles';
export const GET_CHAT_LIST_METHOD = 'getChatList';
export const GET_UPGRADED_CHAT_LIST_METHOD = 'getUpgradedChatList';
export const GET_CHAT_METHOD = 'getChat';
export const GET_CHAT_COUNTERS_METHOD = 'getChatCounters';
export const LIKE_MESSAGE_METHOD = 'likeMessage';
export const SHARE_MESSAGE_METHOD = 'shareMessage';
export const GET_CHAT_HISTORY_METHOD = 'getChatHistory';
export const GET_CHAT_HISTORY_FROM_MOBILE_METHOD = 'getChatHistoryFromMobile';
export const GET_CHAT_MEMBER_COUNT_METHOD = 'getChatMemberCount';
export const GET_CHAT_MEMBERS_METHOD = 'getChatMembers';
export const GET_CHAT_MEMBER_METHOD = 'getChatMember';
export const GET_MY_CHAT_MEMBERS_METHOD = 'getMyMembers';
export const GET_MY_CHAT_ADMIS_METHOD = 'getMyAdmins';
export const GET_MY_MEMBER_CHATS_METHOD = 'getMyMemberChats';
export const PROMOTE_CHAT_MEMBER_METHOD = 'promoteChatMember';
export const ADD_ADMIN_TO_CHAT_METHOD = 'addChatAdmin';
export const ADD_MEMBER_TO_CHAT_METHOD = 'addChatMember';
export const REMOVE_ADMIN_FROM_CHAT_METHOD = 'demoteAdmin';
export const REMOVE_MEMBER_FROM_CHAT_METHOD = 'removeChatMember';
export const BAN_CHAT_MEMBER_METHOD = 'banChatMember';
export const UNBAN_CHAT_MEMBER_METHOD = 'unbanChatMember';
export const SET_CHAT_MEMBER_ROLE_METHOD = 'setRole';
export const SET_USER_VIEW_METHOD = 'setUserView';
export const GET_ROLES_METHOD = 'getRoles';
export const SEND_SELECT_CHAT_METHOD = 'selectChat';
export const SEND_SET_CHAT_METHOD = 'setChat';
export const SEND_MESSAGE_HISTORY_METHOD = 'getMessageList';
export const SEND_SCHEDULED_MESSAGE_HISTORY_METHOD = 'getScheduledMessageList';
export const SEND_AWARD_MESSAGE_HISTORY_METHOD = 'getAwardMessageList';
export const SEND_MESSAGE_SCHEDULE_CANCEL_METHOD = 'cancelMessageSchedule';
export const GET_APP_CONFIG_METHOD = 'getAppConfig';
export const SET_APP_CONFIG_METHOD = 'setAppConfig';
export const PUBLISH_APP_CONFIG_METHOD = 'publishAppConfig';
export const SET_CHAT_DEFAULTS_METHOD = 'setChatDefaults';
export const GET_EMAIL_TAC_METHOD = 'getEmailTac';
export const CREATE_ACCOUNT_METHOD = 'createAccount';
export const GET_TOKEN = 'getToken';
export const GET_CAMPAIGNS_METHOD = 'getCampaigns';
export const GET_CAMPAIGN_METHOD = 'getCampaign';
export const CREATE_CAMPAIGN_METHOD = 'createCampaign';
export const UPDATE_CAMPAIGN_METHOD = 'setCampaign';
export const SET_ACCUMULATOR_METHOD = 'setAccumulator';
export const SET_CAMPAIGN_ACCUMULATOR_METHOD = 'setCampaignAccumulator';
export const SET_AWARD_METHOD = 'setAward';
export const GET_COUPON_METHOD = 'getCoupon';
export const GET_COUPONS_METHOD = 'getCoupons';
export const GET_DRIVER_ASSIGNED_METHOD = 'getAccountMapAssignedTrips';
export const CREATE_COUPON_METHOD = 'createCoupon';
export const SET_DRIVER_ASSIGNED_METHOD = 'setAccountMapAssignedTrips';
export const OTHER_TRIPS_METHOD = 'getOtherMapAssignedTrips';
export const UPDATE_COUPON_METHOD = 'setCoupon';
export const TAGS_METHOD = 'getChatTags';
export const ADD_TAGS_METHOD = 'addChatTags';
export const DELETE_TAGS_METHOD = 'removeChatTags';
export const SET_CHAT_MEMBER_TAGS_METHOD = 'setChatMemberTags';
export const SEND_MESSAGE_METHOD = 'sendMessage';
export const SEND_INSTANT_URL = 'sendArticle';
export const SEND_CALENDAR_METHOD = 'sendCalendar';
export const SEND_PHOTO_METHOD = 'sendPhoto';
export const SEND_AUDIO_METHOD = 'sendAudio';
export const SEND_DOCUMENT_METHOD = 'sendDocument';
export const SEND_VIDEO_METHOD = 'sendVideo';
export const SEND_VOICE_METHOD = 'sendVoice';
export const SEND_LOCATION_METHOD = 'sendLocation';
export const SEND_CONTACT_METHOD = 'sendContact';
export const GET_SUB_CHAT_LIST_METHOD = 'getSubChatList';
export const GET_CHAT_ADMINISTRATORS_METHOD = 'getChatAdministrators';
export const CREATE_CHAT_METHOD = 'createChat';
export const DELETE_CHAT_METHOD = 'deleteGroup';
export const CREATE_ARTICLE_METHOD = 'createArticle';
export const GET_APP_TEMPLATE = 'getAppTemplate';
export const SET_APP_PROGRESS = 'setAppProgress';
export const SET_APP_MODE = 'setAppMode';
export const LIST_APP_RELEASE_METHOD = 'listAppRelease';
export const LIST_APP_RELEASE_ID_METHOD = 'getAppRelease';
export const REMOVE_APP_RELEASE_METHOD = 'removeAppRelease';
export const GENERATE_APP_METHOD = 'generateApp';
export const GET_TAGS_VALUE_METHOD = 'getTagsValue';
export const GET_ADMIN_QR_METHOD = 'getAdmQrCode';
export const GET_AUTH_PRIVILEGE_METHOD = 'getPrivileges';
export const CHANGE_PASSWORD_METHOD = 'setPassword';
export const GET_APP_TEMPLATE_LIST = 'listAppTemplates';
export const GET_MY_MENUS_METHOD = 'listMyMenu';
export const CREATE_MY_MENU_METHOD = 'createMyMenu';
export const SET_MY_MENU_METHOD = 'setMyMenu';
export const REMOVE_CHAT_ADMIN_METHOD = 'removeChatAdmin';
export const GET_APP_MENUS_METHOD = 'getAppMenus';
export const GET_APP_MENU_METHOD = 'getAppMenu';
export const REVOKE_METHOD = 'revoke';
export const SET_CHANNEL_CONFIG_METHOD = 'setChannelConfig';
export const GET_MYPAGE_METHOD = 'getMyPage';
export const SET_MYPAGE_METHOD = 'setMyPage';
export const CREATE_MYPAGE_METHOD = 'createMyPage';
export const GET_MY_PAGES_METHOD = 'getMyPages';
export const GET_INSTANT_PAGES_METHOD = 'getInstantPages';
export const GET_INSTANT_PAGE_METHOD = 'getInstantPage';
export const GET_MY_CALL_BACK_STATS_METHOD = 'getMyCallbackStats';
export const SET_APP_STORE_METHOD = 'setAppStore';
export const GET_APP_STORE_METHOD = 'getAppStore';
export const GET_MY_MEDIA_METHOD = 'getMyGallery';
export const DELETE_MY_MEDIA_METHOD = 'deleteMyGallery';
export const SET_APPLE_CODE_METHOD = 'setAppleCode';
export const GET_APP_REALSE_VER_DATA = 'appConfigHistoryInfo';
export const SET_BOOKING_PERIODS_METHOD = 'setBookingPeriods';
export const GET_BOOKING_PERIODS_METHOD = 'getBookingPeriods';
export const SET_ENABLE_SERVICE_METHOD = 'enableService';
export const GET_MY_BOOKINGS_METHOD = 'getMyBookings';
export const GET_MY_BOOKING_DAYS_METHOD = 'getMyBookingDays';
export const CANCEL_BOOKING_METHOD = 'cancelBooking';
export const GET_PAYMENT_TOKEN_METHOD = 'getPaymentToken';
export const GET_PAYMENT_INFO_METHOD = 'getPayment';
export const SET_FREE_PAYMENT_METHOD = 'setFreePaymentPlan';
export const CANCEL_FREE_PAYMENT_METHOD = 'cancelFreePaymentPlan';
export const REMOVE_MY_PAGE_METHOD = 'removeMyPage';
export const GET_APP_CONFIGS_HISTORY_METHOD = 'getAppConfigsHistory';
export const GET_APP_CONFIG_HISTORY_BY_ID_METHOD = 'getAppConfigHistory';
export const SET_APP_CHANNEL_SETTINGS = 'setAppChannelSettings';
export const GET_APP_CHANNEL_SETTINGS_METHOD = 'getAppChannelSettings';
export const APP_CHANNEL_SETTINGS_CALLBACK = 'appChannelSettings';
export const APP_CONFIG_HISTORY_INFO = 'appConfigHistoryInfo';
export const CREATE_BOT_METHOD = 'createBot';
export const GET_MY_BOTS_METHOD = 'getMyBots';
export const SET_BOT_METHOD = 'setBot';
export const GET_BOT_TOKEN_METHOD = 'getBotToken';
export const GET_BOT_TEMPLATES_METHOD = 'getBotTemplates';
export const REMOVE_BOT_METHOD = 'removeBot';
export const ADD_BOT_METHOD = 'addBot';
export const SET_PRIVILEGES_METHOD = 'setPrivileges';
export const SEND_EMAIL_METHOD = 'sendEmailTemplate';
export const CREATE_PAGE_TEMPLATE_METHOD = 'createInstantPage';
export const CREATE_APP_TEMPLATE_METHOD = 'createSignupTemplate';
export const SET_APP_CHANNEL_DATA_METHOD = 'setAppChannelData';
export const GET_APP_CHANNEL_DATA_METHOD = 'getAppChannelData';
export const SET_BOOKING_EXCEPTIONS_METHOD = 'setBookingExceptions';
export const GET_BOOKING_EXCEPTIONS_METHOD = 'getBookingExceptions';
export const PUBLISH_BOT_METHOD = 'publishBot';
export const REMOVE_BOOKING_PERIODS_METHOD = 'removeBookingPeriods';
export const REMOVE_BOOKING_EXCEPTIONS_METHOD = 'removeBookingExceptions';
export const GET_CONFIG_QR_CODE_METHOD = 'getCfgQrCode';
export const RESET_PASSWORD_METHOD = 'resetPassword';
export const GET_PAYMENT_PROVIDERS_METHOD = 'getPaymentProviders';
export const SET_PAYMENT_PROVIDER_METHOD = 'setPaymentProvider';

export const GET_SMTP_METHOD = 'getSmtp';
export const SET_SMTP_METHOD = 'setSmtp';
export const GET_ADS_PROVIDERS_METHOD = 'getAdsProviders';
export const SET_ADS_PROVIDER_METHOD = 'setAdsProvider';
export const GET_PAYMENT_ORDERS_METHOD = 'getPaymentOrders';
export const GET_SERVICE_ORDERS_METHOD = 'getServiceOrders';
export const CREATE_PRODUCT_METHOD = 'createProduct';
export const SET_PRODUCT_METHOD = 'setProduct';
export const GET_PRODUCT_METHOD = 'getProduct';
export const GET_PRODUCTS_METHOD = 'getProducts';
export const REMOVE_PRODUCT_METHOD = 'removeProduct';
export const CREATE_BUNDLE_METHOD = 'createBundle';
export const SET_BUNDLE_METHOD = 'setBundle';
export const GET_BUNDLE_METHOD = 'getBundle';
export const GET_BUNDLES_METHOD = 'getBundles';
export const ADD_BUNDLE_PRODUCTS_METHOD = 'addBundleProducts';
export const REMOVE_BUNDLE_PRODUCTS_METHOD = 'removeBundleProducts';
export const REMOVE_BUNDLE_METHOD = 'removeBundle';
export const PAY_CASH_METHOD = 'cashPayment';
export const ENABLE_SERVICE_METHOD = 'enableService';
export const ADD_INVENTORY_METHOD = 'addInventory';
export const GET_USER_BALANCE_METHOD = 'getUserBalance';
export const GET_USER_PRODUCT_BALANCE_METHOD = 'getUserProductBalance';
export const CREATE_PACKAGE_METHOD = 'createPackage';
export const SET_PACKAGE_METHOD = 'setPackage';
export const GET_PACKAGE_METHOD = 'getPackage';
export const GET_PACKAGES_METHOD = 'getPackages';
export const REMOVE_PACKAGE_ITEMS_METHOD = 'removePackageItems';
export const REMOVE_PACKAGE = 'removePackage';
export const ADD_PACKAGE_ITEMS_METHOD = 'addPackageItems';
export const ADD_APP_METHOD = 'addApp';
export const GET_APPS_METHOD = 'getApps';
export const GET_MY_MEMBER_METHOD = 'getMyMember';
export const GET_STORE_METHOD = 'getMStore';
export const GET_STORES_METHOD = 'getMStores';
export const CREATE_STORE_METHOD = 'createMStore';
export const SET_STORE_METHOD = 'setMStore';
export const ADD_STORE_ITEM_METHOD = 'addMStoreItems';
export const ASSIGN_STORE_CHANNEL_METHOD = 'assignMStoreChannel';
export const REMOVE_STORE_ITEM_METHOD = 'removeMStoreItems';
export const REMOVE_STORE_METHOD = 'removeMStore';
export const GET_STORE_REF = 'getMStore';
export const SET_APP = 'setApp';
export const GET_SUB_MENU_METHOD = 'getStoreSubMenu';
export const GET_SUB_MENUS_METHOD = 'getStoreSubMenus';
export const CREATE_SUB_MENU_METHOD = 'createStoreSubMenu';
export const SET_SUB_MENU_METHOD = 'setStoreSubMenu';
export const ADD_SUB_MENU_ITEM_METHOD = 'addStoreSubMenuItems';
export const REMOVE_SUB_MENU_ITEM_METHOD = 'removeSubMenuItem';
export const GET_WHITELIST_METHOD = 'getWhitelist';
export const WHITELIST_CALLBACK = 'whitelist';
export const ADD_WHITELIST_METHOD = 'addWhitelist';
export const ADD_WHITELIST_CALLBACK = 'addWhitelist_ack';
export const DELETE_WHITELIST_METHOD = 'deleteWhitelist';
export const DELETE_WHITELIST_CALLBACK = 'deleteWhitelist_ack';
export const GET_WHITELIST_PATTERNS_METHOD = 'getWhitelistPatterns';
export const WHITELIST_PATTERNS_CALLBACK = 'whitelistPatterns';
export const ADD_WHITELIST_PATTERNS_METHOD = 'addWhitelistPatterns';
export const ADD_WHITELIST_PATTERNS_CALLBACK = 'addWhitelistPatterns_ack';
export const DELETE_WHITELIST_PATTERNS_METHOD = 'deleteWhitelistPatterns';
export const DELETE_WHITELIST_PATTERNS_CALLBACK = 'deleteWhitelistPatterns_ack';

export const GET_STORE_MENU_METHOD = 'getStoreMenu';
export const GET_STORE_MENUS_METHOD = 'getStoreMenus';
export const CREATE_STORE_MENU_METHOD = 'createStoreMenu';
export const SET_STORE_MENU_METHOD = 'setStoreMenu';
export const ADD_STORE_MENU_ITEM_METHOD = 'addStoreMenuItems';
export const REMOVE_STORE_MENU_ITEM_METHOD = 'removeStoreMenuItems';
export const REMOVE_STORE_MENU_METHOD = 'removeStoreMenu';

export const GET_CHAT_INFO_METHOD = 'getChatInfo';
export const CREATE_CONNECTED_ACCOUNT = 'createConnectedAccount';
export const RESET_CONNECTED_ACCOUNT = 'removePaymentProvider';

// Maps
// Map Services (Calendar)
export const CREATE_MAP_SERVICE_METHOD = 'createMapService';
export const GET_MAP_SERVICE_METHOD = 'getMapService';
export const GET_MAP_SERVICES_METHOD = 'getMapServices';
export const REMOVE_MAP_SERVICE_METHOD = 'removeMapService';
export const SET_MAP_SERVICE_METHOD = 'setMapService';
// Map Markers
export const CREATE_MAP_MARKER_METHOD = 'createMapMarker';
export const GET_MAP_MARKER_METHOD = 'getMapMarker';
export const GET_MAP_MARKERS_METHOD = 'getMapMarkers';
export const SET_MAP_MARKER_METHOD = 'setMapMarker';
export const REMOVE_MAP_MARKER_METHOD = 'removeMapMarker';
// Map Routes
export const CREATE_MAP_ROUTE_METHOD = 'createMapRoute';
export const GET_MAP_ROUTE_METHOD = 'getMapRoute';
export const GET_MAP_ROUTES_METHOD = 'getMapRoutes';
export const SET_MAP_ROUTE_METHOD = 'setMapRoute';
export const REMOVE_MAP_ROUTE_METHOD = 'removeMapRoute';
// Map Trips
export const CREATE_MAP_TRIP_METHOD = 'createMapTrip';
export const GET_MAP_TRIP_METHOD = 'getMapTrip';
export const GET_MAP_TRIPS_METHOD = 'getMapTrips';
export const SET_MAP_TRIP_METHOD = 'setMapTrip';
export const REMOVE_MAP_TRIP_METHOD = 'removeMapTrip';

// Privilege Role
export const CREATE_PRIVILEG_ROLE_METHOD = 'addAccessRole';
export const GET_PRIVILEG_ROLE_METHOD = 'getAccessRole';
export const GET_PRIVILEG_ROLES_METHOD = 'getAccessRoles';
export const SET_PRIVILEG_ROLE_METHOD = 'setAccessRole';
export const REMOVE_PRIVILEG_ROLE_METHOD = 'removeAccessRole';
export const SET_ACCESS_PRIVILEG_ROLE_METHOD = 'setAccessRolePrivileges';

export const GET_MAP_LINKED_ACCOUNTS_METHOD = 'getMapLinkedAccounts';
export const CREATE_MAP_ACCOUNT_LINKS_METHOD = 'createMapAccountLinks';
export const SET_MAP_ACCOUNT_LINKS_METHOD = 'setMapAccountLinks';
export const MAP_LINKED_ACCOUNTS_CALLBACK = 'mapLinkedAccounts';
export const MAP_ACCOUNT_LINKS_CALLBACK = 'mapAccountLinks';
export const MAP_ACCOUNT_LINK_CALLBACK = 'mapAccountLink';
export const GET_MAP_ACCOUNT_LINKS_METHOD = 'getMapAccountLinks';
export const REMOVE_MAP_ACCOUNT_LINK_METHOD = 'removeMapAccountLink';
export const SET_MAP_ACCOUNT_LINKS_REF = 'setMapAccountLinks';

export const GET_MAP_ACTIVE_TRIPS_METHOD = 'getMapActiveTrips';

export const CREATE_MAP_STOP_TIME_METHOD = 'createMapStopTime';
export const GET_MAP_STOP_TIMES_METHOD = 'getMapStopTimes';
export const ADD_MAP_TRIP_STOP_TIMES_METHOD = 'addMapTripStopTimes';
export const MAP_STOP_TIME_CALLBACK = 'mapStopTime';
export const MAP_STOP_TIMES_CALLBACK = 'mapStopTimes';
// Map Trip Users
export const CREATE_MAP_TRIP_USER_METHOD = 'createMapTripUser';
export const GET_MAP_TRIP_USER_METHOD = 'getMapTripUser';
export const GET_MAP_DRIVER_USER_METHOD = 'getTripAssignedAccounts';

export const DELETE_MAP_DRIVER_USER_METHOD = 'removeMapAssignTrips';

export const GET_MAP_TRIP_USERS_METHOD = 'getMapTripUsers';
export const SET_MAP_TRIP_USER_METHOD = 'setMapTripUser';
export const REMOVE_MAP_TRIP_USER_METHOD = 'removeMapTripUser';
// Calendars
export const CREATE_CALENDAR_METHOD = 'createCalendar';
export const SET_CALENDAR_METHOD = 'setCalendar';
export const GET_CALENDAR_METHOD = 'getCalendar';
export const GET_CALENDARS_METHOD = 'getCalendars';
export const SET_CALENDAR_DETAIL_METHOD = 'setCalendarDetail';
export const REMOVE_CALENDAR_DETAIL_METHOD = 'removeCalendarDetail';
export const GET_CALENDAR_DETAILS_METHOD = 'getCalendarDetails';

// CALL BACKS
export const QR_CODE_CALLBACK = 'QR_CODE';
export const TOKEN_CALLBACK = 'TOKEN';
export const TOKEN_CHANGE_CALLBACK = 'TOKEN_CHANGE';
export const TOKEN_AUTH_OK_CALLBACK = 'TOKEN_AUTH_OK';
export const TOKEN_AUTH_NOT_CALLBACK = 'TOKEN_AUTH_NOT';
export const MESSAGE_CALLBACK = 'message';
export const MESSAGE_SCHEDULED_CALLBACK = 'scheduledMessage';
export const MESSAGE_SCHEDULED_CANCELED_CALLBACK = 'cancelMessageSchedule_ack';
export const CONTACTS_CALLBACK = 'contacts';
export const USER_CALLBACK = 'userDetails';
export const MESSAGE_ACK_CALLBACK = 'messageAck';
export const MY_PROFILE_CALLBACK = 'myProfile';
export const MESSAGE_DELIVERED_CALLBACK = 'messageDelivered';
export const MESSAGE_SEEN_CALLBACK = 'messageSeen';
export const INVALIDATE_USER_CALLBACK = 'invalidateUser';
export const DEFAULT_CHAT_LIST_CALLBACK = 'defaultChatList';
export const CHAT_LIST_CALLBACK = 'chatList';
export const CHAT_SELECTED_CALLBACK = 'chatSelected';
export const CHAT_CALLBACK = 'chatDetails';
export const CHAT_INVALID_CALLBACK = 'chatInvalid';
export const LIKE_MESSAGE_ACK = 'likeMessage_ack';
export const CHAT_MEMBER_CALLBACK = 'chatMember';
export const CHAT_MEMBER_COUNT = 'chatMemberCount';
export const CHAT_MEMBER_CALLBACK_ACK = 'chatMember_ack';
export const LEFT_CHAT_CALLBACK = 'leftChat';
export const CHAT_COUNTERS_CALLBACK = 'chatCounters';
export const ACCUMULATOR_CALLBACK = 'accumulator';
export const CAMPAIGNS_CALLBACK = 'campaigns';
export const CAMPAIGN_CALLBACK = 'campaign';
export const COUPON_CALLBACK = 'coupon';
export const COUPONS_CALLBACK = 'coupons';
export const APP_CONFIG_CALLBACK = 'appConfig';
export const TAGS_CALLBACK = 'chatTags';
export const CREATE_ARTICLE_ACK_CALLBACK = 'createArticle_ack';
export const TEMPLATE_CALLBACK = 'template';
export const APP_RELEASE_CALLBACK = 'appRelease';
export const APP_RELEASES_CALLBACK = 'appReleases';
export const TAGS_VALUE_CALLBACK = 'tags';
export const ROLES_CALLBACK = 'roles';
export const SET_CHAT_MEMBER_ROLE_CALLBACK = 'setRole_ack';
export const ADMIN_QR_CODE_CALLBACK = 'admQrCode';
export const AUTH_PRIVILEGE_CALLBACK = 'privileges';
export const SUB_CHAT_LIST_CALLBACK = 'subChatList';
export const CHAT_ADMINISTRATORS_CALLBACK = 'chatAdministrators';
export const MY_MEMBER_CHATS_CALLBACK = 'myMemberChats';
export const MY_MEMBERS_CALLBACK = 'myMembers';
export const MY_MEMBER_CALLBACK = 'myMember';
export const MY_ADMINS_CALLBACK = 'myAdmins';
export const MY_MENUS_CALLBACK = 'myMenus';
export const MY_MENU_CALLBACK = 'myMenu';
export const APP_MENU_CALLBACK = 'appMenu';
export const APP_MENUS_CALLBACK = 'appMenus';
export const REVOKE_CALLBACK = 'revoke_ack';
export const CHANNEL_CONFIG_CALLBACK = 'channelConfig';
export const MY_PAGE_CALLBACK = 'myPage';
export const MY_PAGES_CALLBACK = 'myPages';
export const INSTANT_PAGES_CALLBACK = 'instantPages';
export const INSTANT_PAGE_CALLBACK = 'instantPage';
export const MY_CALL_BACK_STATS_CALLBACK = 'myCallbackStats';
export const APP_STORE_CALLBACK = 'appStore';
export const MY_MEDIA_CALLBACK = 'myGallery';
export const REQUEST_APPLE_CODE_CALLBACK = 'requestAppleCode';
export const DELETE_MY_MEDIA_CALLBACK = 'deleteMyGallery_ack';
export const BOOKING_PERIODS_CALLBACK = 'bookingPeriods';
export const MY_BOOKINGS_CALLBACK = 'myBookings';
export const MY_BOOKING_DAYS_CALLBACK = 'myBookingDays';
export const PAYMENT_TOKEN_CALLBACK = 'paymentToken';
export const PAYMENT_SET_CALLBACK = 'paymentSet';
export const PAYMENT_CALLBACK = 'payment';
export const APP_CONFIGS_HISTORY_LIST_CALLBACK = 'appConfigsHistory';
export const APP_CONFIG_HISTORY_CALLBACK = 'appConfigHistory';
export const BOTS_CALLBACK = 'bots';
export const BOT_CALLBACK = 'bot';
export const BOT_TEMPLATES_CALLBACK = 'botTemplates';
export const PASSWORD_SET_CALLBACK = 'passwordSet';
export const APP_CHANNEL_DATA_CALLBACK = 'appChannelData';
export const SET_BOOKING_EXCEPTIONS_CALLBACK = 'setBookingExceptions_ack';
export const GET_BOOKING_EXCEPTIONS_CALLBACK = 'bookingExceptions';
export const PUBLISH_BOT_CALLBACK = 'botPublished';
export const CONFIG_QR_CODE_CALLBACK = 'cfgQrCode';
export const PAYMENT_PROVIDERS_CALLBACK = 'paymentProviders';
export const ADS_PROVIDERS_CALLBACK = 'adsProviders';
export const SET_PAYMENT_PROVIDER_CALLBACK = 'setPaymentProvider_ack';
export const GROUP_DELETED_CALLBACK = 'groupDeleted';
export const PAYMENT_ORDERS_CALLBACK = 'paymentOrders';
export const SERVICE_ORDERS_CALLBACK = 'serviceOrders';
export const CASH_PAYMENT_CALLBACK = 'cashPaymentStatus';
export const GET_MENU_METHOD = 'getMyMenus';
export const CONNECTE_STRIPE_CALLBACK = 'connectedAccount';

// Blacklist Method
export const GET_BLACKLIST_METHOD = 'getBlacklist';
export const ADD_BLACKLIST = 'addBlacklist';
export const BLACKLIST_CALLBACK = 'blacklist';
export const ADD_BLACKLIST_ACK_CALLBACK = 'addBlacklist_ack';
export const DELETE_BLACKLIST_METHOD = 'deleteBlacklist';
export const DELETE_BLACKLIST_ACK_CALLBACK = 'deleteBlacklist_ack';
export const GET_BLACKLIST_PATTERNS_METHOD = 'getBlacklistPatterns';
export const BLACKLIST_PATTERNS_CALLBACK = 'blacklistPatterns';
export const ADD_BLACKLIST_PATTERNS_METHOD = 'addBlacklistPatterns';
export const ADD_BLACKLIST_PATTERNS_ACK_CALLBACK = 'addBlacklistPatterns_ack';
export const DELETE_BLACKLIST_PATTERNS_METHOD = 'deleteBlacklistPatterns';
export const DELETE_BLACKLIST_PATTERNS_CALLBACK = 'deleteBlacklistPatterns_ack';

// Product
export const PRODUCT_CALLBACK = 'product';
export const PRODUCTS_CALLBACK = 'products';
// Bundle
export const BUNDLE_CALLBACK = 'bundle';
export const BUNDLES_CALLBACK = 'bundles';
// Package
export const PACKAGE_CALLBACK = 'packageGroup';
export const PACKAGES_CALLBACK = 'packages';
// MStore
export const MSTORE_CALLBACK = 'mStore';
export const MSTORES_CALLBACK = 'mStores';
// StoreSubMenu
export const SUBMENUS_CALLBACK = 'storeSubMenus';
export const SUBMENU_CALLBACK = 'storeSubMenu';
// StoreMenu
export const STOREMENU_CALLBACK = 'storeMenu';
export const STOREMENUS_CALLBACK = 'storeMenus';
// EnableService

export const ENABLE_SERVICE_CALLBACK = 'enableService_ack';
export const MAP_SERVICE_CALLBACK = 'mapService';
export const MAP_SERVICES_CALLBACK = 'mapServices';
export const MAP_MARKER_CALLBACK = 'mapMarker';
export const MAP_MARKERS_CALLBACK = 'mapMarkers';
export const MAP_ROUTE_CALLBACK = 'mapRoute';
export const MAP_ROUTES_CALLBACK = 'mapRoutes';
export const MAP_TRIP_CALLBACK = 'mapTrip';
export const MAP_TRIPS_CALLBACK = 'mapTrips';
export const MAP_TRIP_USER_CALLBACK = 'mapTripUser';
export const MAP_TRIP_USERS_CALLBACK = 'mapTripUsers';
export const MAP_ACTIVE_TRIPS_CALLBACK = 'mapActiveTrips';
export const MAP_ASSIGN_TRIPS_CALLBACK = 'mapAssignTrips';
export const MAP_TRIPS_ASSIGN_CALLBACK = 'mapTripAssignedAccounts';
export const OTHER_TRIPS_CALLBACK = 'otherMapAssignTrips';
export const GET_SMTP_CALLBACK = 'smtpConfig';
export const CALENDAR_CALLBACK = 'calendar';
export const CALENDARS_CALLBACK = 'calendars';
export const CALENDAR_DETAILS_CALLBACK = 'calendarDetails';
export const USER_BALANCE_CALLBACK = 'userBalance';
export const USER_PRODUCT_BALANCE_CALLBACK = 'userProductBalance';
export const APP_CALLBACK = 'app';
export const APPS_CALLBACK = 'apps';
export const TICKET_STATUS_CALLBACK = 'ticketStatus';
// --------- local storage keys ---------------------------------------------------------------

export const TOKEN_LOCAL_STORAGE_KEY = 'TOKEN';
export const ADMIN_NAME_LOCAL_STORAGE_KEY = 'NAME';
export const REM_LOCAL_STORAGE_KEY = 'REM';
export const TIMEZONE_KEY = 'TIMEZONE';
export const COUNTRY_CODE_KEY = 'COUNTRY_CODE';
export const WINDOW_LOCAL_STORAGE_KEY = 'WINDOWS_ARRAY';
export const WEB_VERSION_KEY = 'Version';
export const ID_LOCAL_STORAGE_KEY = 'ID';
export const OFFSET_STORAGE_KEY = 'OFFSET';
export const BASE_URL_LOCAL_STORAGE_KEY = 'BASE_URL';
export const CHATID_LOCAL_STORAGE_KEY = 'CHATID'; // Merchant Web Only
export const CACHED_FILES_DB = 'cachedFiles';
export const INFO_DB = '#pgmc';
export const CHAT_LIST_DB = '#clist';
export const PROFILE_LIST_DB = '#Plist';
export const TABS_LIST_DB = '#Tlist';
export const DB_VERSION = 1;
export const CHANKS = 50;
export const CHANKSNUM = 6;

// ------------ Auth status -----------------------------------------------------------------

export enum AuthStatus {
  INIT_SOKET = 'INIT',
  AUTH_LOGIN_BY_EMAIL = 'AUTH_LOGIN_BY_EMAIL',
  AUTH_QR_SET = 'AUTH_QR_SET',
  AUTH_QR_EXPIRE = 'AUTH_QR_EXPIRE',
  AUTH_TOKEN_NOT = 'AUTH_NOT',
  TOKEN_RECEIVED = 'TOKEN_RECEIVED',
  AUTH_TOKEN_OK = 'AUTH_OK',
  AUTH_CHANNEL_CREATED = 'AUTH_CHANNEL_CREATED',
  AUTH_CHATLIST_SET = 'AUTH_CHATLIST_SET',
  AUTH_CHAT_SELECTED = 'AUTH_CHAT_SELECTED',
  LOGGED_OUT = 'LOGGED_OUT',
  MULTI_LOGIN = 'MULTI_LOGIN'
}

export enum SignupStatus {
  ENTER_EMAIL = 'ENTER_EMAIL',
  EMAIL_TAC_SENT = 'EMAIL_TAC_SENT',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  EMAIL_TOKEN_RECEIVED = 'EMAIL_TOKEN_RECEIVED'
}

// ----------- UI Status ------------------------------------------------------------------

export const SELECT_READ_MESSAGES = 'SELECT_READ_MESSAGES';
export const SELECT_UNREAD_MESSAGES = 'SELECT_UNREAD_MESSAGES';
export const TOGGLE_SELECTED_MESSAGE = 'TOGGLE_SELECTED_MESSAGE';
export const TOGGLE_SELECTED_MESSAGES = 'TOGGLE_SELECTED_MESSAGES';
export const DELETE_SELECTED_MESSAGES = 'DELETE_SELECTED_MESSAGES';
export const RECALL_SELECTED_MESSAGES = 'RECALL_SELECTED_MESSAGES';
export const SELECT_ALL = 'SELECT_ALL';
export const SELECT_NONE = 'SELECT_NONE';
export const SELECT_READ = 'SELECT_READ';
export const SELECT_UNREAD = 'SELECT_UNREAD';
export const DELETE_ALL = 'DELETE_ALL';
export const RECALL_ALL = 'RECALL_ALL';

// ------------ URL --------------------------------------------------------------
export const CHANNEL_API = 'APP';
export const DOWNLOAD_API = 'DOWNLOAD';
export const UPLOAD_API = 'UPLOAD';
export const META_API = 'META';
export const WS_API = 'WS';

/// ---------- Message Position --------------------------------------------------------------
export const RIGHT = 'RIGHT';
export const LEFT = 'LEFT';

/// --------- Errors -------------------------------------------------------------------------
export enum HtttpMethodErrors {
  FREE_MAIL = 160001,
  WRONG_FORMAT = 160004,
  WRONG_TAC = 160006,
  TAC_EXPIRED = 160008,
  WRONG_TAC_2 = 160011,
  MAIL_USED_3 = 160003,
  MAIL_USED = 500021,
  MAIL_USED_2 = 500022,
  AUTH_ERROR = 100099,
  AUTH_ERROR_2 = 100022,
  GENRAL_ERROR = 199999,
  OPEN_IN_ANOTHER_TAB = 199992,
  PASSWORD_NOT_MATCH = 510031,
  PASSWORD_IN_HISTORY = 510030,
  EMAIL_DOES_NOT_EXIST = 160013,
  PASSWORD_TOO_SHORT = 510023,
  PASSWORD_TOO_LONG = 510019,
  PASSWORD_MUST_CONTAIN_LOWERCASE = 510024,
  PASSWORD_MUST_CONTAIN_UPPERCASE = 510025,
  PASSWORD_MUST_CONTAIN_DIGIT = 510026,
  PASSWORD_MUST_CONTAIN_SPECIAL = 510027,
  PASSWORD_MATCHES_PATTERN = 510028,
  PASSWORD_MATCHES_DICTIONARY = 510029
}
/// ------------------------
export enum RecordTypes {
  GROUP = 'group',
  CHANNEL = 'channel',
  VAPP = 'vapp',
  EVENT = 'event',
  CHAT = 'chat',
  TAG = 'tag',
  MEMBER = 'member',
  PRODUCT = 'product',
  BUNDLE = 'bundle',
  PACKAGE = 'package',
  BOOKING = 'booking',
  BOOKING_PERIODS = 'bookingPeriods',
  BOOKING_HOLIDAYS = 'bookingHolidays',
  MAP_TRACKING = 'mapTracking',
  BLACKLIST_SIGNUP = 'blacklistSignup',
  BLACKLIST_SIGNUP_PATTERN = 'blacklistSignupPattern'
}

/// ------------------ User Type ------------------------------------------------------------
export enum UserType {
  ADMIN = 'Admin',
  MEMBER = 'Member'
}

/// ------------------ Style -----------------------------------------------------------------
export enum MessageStyles {
  WIDE = 0,
  WIDE_BOOTTOM = 2,
  LEFT_SIDE = 1
}
// -------------- Message Media Status -------------------------------------------------------
export enum MessageMediaStatus {
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
  UPLOADING = 'UPLOADING',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  DOWNLOADED = 'DOWNLOADED',
  UPLOADED = 'UPLOADED'
}

// ------------ Link Preview Status ----------------------------------------------------------
export enum MessageLinkPreviewStatus {
  LINK_PREVIEW_FETCHING = 'FETCHING',
  LINK_PREVIEW_FETCHED = 'FETCHED',
  LINK_PREVIEW_FAILED = 'FAILED'
}

// ----------- Message Action Types ----------------------------------------------------------
export enum MessageActionTypes {
  NORMAL = 'NORMAL',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED'
}

// ----- Message Statuses as returned from server ------------------------------------------
export enum MessageStatuses {
  NEW = 'new',
  UPDATED = 'updated',
  DELETED = 'deleted'
}

// --- entity status
export enum entityStatuses {
  DELETED = 'deleted'
}

// ----------- Message Sending types -------------------------------------------------------
export enum MessageSendingType {
  AWARD = 'AWARD',
  SCHEDULE = 'SCHEDULE',
  NORMAL = 'NORMAL',
  WELCOME = 'WELCOME'
}
// ----------- Message sending status -------------------------------------------------------
export enum MessageSendingStatus {
  PENDING = 'PENDING',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN'
}
// ----------- Link Preview Options -------------------------------------------------------
export enum LinkPreviewOptions {
  WEB_PREVIEW_DISABLE = 1,
  WEB_PREVIEW_HIDE_LINK = 2,
  WEB_PREVIEW_INSTANCE_VIEW = 3,
  WEB_PREVIEW_INSTANCE_WITHOUT_LINK = 4
}

// -----------------Message Types-------------------------------------------------------------
export enum MessageTypes {
  PHOTO = 'photo',
  TEXT = 'text',
  TEXT_FILE = 'text_file',
  VIDEO = 'video',
  AUDIO = 'audio',
  VOICE = 'voice',
  LOCATION = 'location',
  STICKER = 'sticker',
  CONTACT = 'contact',
  GIF = 'gif',
  DOCUMENT = 'document',
  ARTICLE = 'article',
  CALENDAR = 'calendar'
}
export enum ClientMessageTypes {
  PHOTO = 'Image',
  TEXT = 'Text',
  VIDEO = 'Video',
  AUDIO = 'Audio',
  VOICE = 'Voice note',
  LOCATION = 'Location',
  STICKER = 'Sticker',
  CONTACT = 'Contact',
  GIF = 'GIF',
  DOCUMENT = 'File',
  TEXT_FILE = 'Text File',
  ARTICLE = 'Article'
}

export enum UISections {
  MEDIACENTER = 'MEDIACENTER',
  INSTANTARTICLE = 'IV',
  HOME = 'HOME',
  APP = 'MA',
  CHANNEL = 'MC',
  POSTS = 'AP',
  SCHEDULE = 'DM',
  EVENTS = 'ME',
  CAMPAIGNS = 'CM',
  COUPONS = 'CP',
  BILLINGS = 'BILLINGS',
  USERS = 'MU',
  TAGS = 'MT',
  SUBGROUP = 'Group',
  SUBCHANNEL = 'Channel',
  VIRTUAL_APPS = 'VirtualApp',
  MENUS = 'Polls',
  BOOKING = 'BOOKING',
  MY_BOOKINGS = 'MY_BOOKINGS',
  BOTS = 'BOTS',
  CUSTOMERCARE = 'customercare',
  BULKUPLOAD = 'BULKUPLOAD',
  SETTINGS = 'ST',
  UPLOADTEMPS = 'UPLOADTEMPLATES',
  CALENDAR = 'CALENDAR',
  TRANSACTIONS = 'TRANSACTIONS',
  BUNDLES = 'BUNDLES',
  PRODUCTS = 'PRODUCTS',
  PACKAGES = 'PACKAGES',
  MSTORE = 'MSTORE',
  SUBMENU = 'SUBMENU',
  STOREMENU = 'STOREMENU',
  BLACKLIST = 'BLACKLIST',
  LOCATIONS = 'LOCATIONS',
  MYCH = 'MYCH',
  MAPSERVICE = 'MAPSERVICE',
  MAPROUTES = 'MAPROUTES',
  TRIPS = 'TRIPS',
  TRIPSHISTORY = 'TRIPSHISTORY',
  TRIPNOTIFIERS = 'TRIPNOTIFIERS',
  WHITELIST = 'WHITELIST'
}

export enum UserViewTypes {
  DRIVER = 'Driver',
  CUSTOMER = 'Customer'
}

export enum UserStatus {
  ACTIVE = 'Active',
  BANNED = 'Banned',
  LEFT = 'Left',
  REMOVED = 'Removed',
  NOT_ACTIVE = 'Not active'
}

export enum GridStyle {
  card = 1,
  list
}
// ------------- Extentions
export const GIF_EXTENTION = '.gif';
export const MP4_EXTENTION = '.mp4';

// --- Chat Types --------------------
export const GROUP = 'Group';
export const CHANNEL = 'Channel';
export const VIRTUAL_APP = 'VirtualApp';
export const EVENT_CHANNEL = 'EventChannel';
export const EVENT_GROUP = 'EventGroup';
export const BOOKING_CHANNEL = 'BookingChannel';
export const BOOKING_GROUP = 'BookingGroup';
export const INDIVIDUAL = 'Individual';
// ----- terminal types ------------
export const API = 'API';
export const MOBILE = 'Mobile';
// --- List Types -----------
export const CHATS = 'chats';
export const CHANNELS = 'channals';
// --------------------------------------
export const ONE_MINUTE = 60000;
// --------------- Images Qulity -------------------------------------------------------------
export const MESSAGE_IMAGE_BASE64 = {
  width: 90,
  height: 90,
  ratio: 0.5,
  filter: true
};
export const MESSAGE_IMAGE_ORG = {
  width: 1280,
  height: 1125,
  ratio: 0.8,
  filter: false
};
export const BOT_IMAGE_ORG = {
  width: 1280,
  height: 1125,
  ratio: 0.55,
  filter: false
};
export const BOT_IMAGE_BASE64 = {
  width: 90,
  height: 90,
  ratio: 0.5,
  filter: false
};

export const FACEBOOK_SHARE_LINK = 'http://www.facebook.com/sharer?u=';
export const TWITTER_SHARE_LINK = 'https://twitter.com/intent/tweet?text=';
export const GOOGLEPLUS_SHARE_LINK = 'https://plus.google.com/share?url=';

// Chat, channels or merchant theme color
export const CHAT_THEME_COLOR = '#2ED473';
export const CHANNELS_THEME_COLOR =
  PROJECT_NAME === INDIVIDUAL_PROJECT ? '#3E3BED' : '#342C49';

export const SHOW_REPLIES_COUNT =
  PROJECT_NAME === INDIVIDUAL_PROJECT ? true : false;

// This color for pregress media download or upload
export const MEDIA_PROGRESS_THEME_COLOR = '#FFF';

export const THEME_COLORS = [
  '#3e3bed',
  '#2ed473',
  '#ff4757',
  '#fe7644',
  '#e7d1fa'
];

export const MERCHANT_PAGE_SIZE = 14;
export const CHANNEL_PAGE_SIZE = 10;
export const CHAT_PAGE_SIZE = 10;
export const CHATS_LIST_PAGE_SIZE = 14;
export const MAX_NUMBER_OF_PAGES_PER_DOM = 10;
export const SERVER_PAGE_SIZE = 10;

/** fields MIN & MAX */
export const MIN_GROUP_TITLE_LENGTH = 1;
export const MAX_GROUP_TITLE_LENGTH = 50;
export const MAX_PAYMENT_TITLE_LENGTH = 50;
export const MAX_GROUP_DESCRIPTION_LENGTH = 100;
export const MAX_PAYMENT_DESCRIPTION_LENGTH = 300;

// export const FIRST_CHAT_HISTORY_PAGE_SIZE = 20;
export const REACHED_END = -1;

export const LINK_SHARED_SUCCESSFULLY_TITLE = 'Link Copied';
export const LINK_SHARED_SUCCESSFULLY = 'Shared link copied to clipboard';
export const LINK_SHARE_ERROR = 'Your message is not sent yet';
export const FILE_SIZE_EXCEEDED =
  'The file you are trying to upload is too large';
export const FILE_SIZE_ZERO_LENGTH =
  'Kindly note that the file you tried to upload has no content';
export const ERROR_NOT_IMAGE = 'You can upload image file only';
export const ERROR_NOT_PNG_IMAGE = 'Please upload image of type png';
export const ERROR_NOT_JPG_IMAGE = 'Please upload image of type jpg';
export const ERROR_IMAGE_SHOULD_BE_1024X1024 =
  'Please upload a square image of size not less than 1024 x 1024';
export const NOT_SUPPORTED_FILE_TYPE =
  'The file you are trying to upload is not a supported file type';
export const NO_FILE_NAME = 'The file you are trying to upload has no name';
export const NO_MIC_FOUND = `We are unable to detect your microphone. Please check the microphone setup.`;
export const CAPTION_SIZE_EXCEEDED =
  'The caption you are pasting is too long. Try shortening it or sending it as separate message';
export const FAILED_TO_UPLOAD_IMAGE = 'Failed to upload image';
export const FAILED_TO_CREATE_PAGE_TEMPLATE =
  'Cannot create template for this page. As one or more linked pages are not derived from templates';
export const FAILED_TO_CREATE_APP_TEMPLATE =
  'Cannot create template for this App. As one or more pages have no template id';
export const ALREADY_HAS_TEMPLATE = 'This page already attached to template';
export const ONLY_PUBLIC_BOTS_COULD_BE_PUBLISHED =
  'Only public bots could be published';
export const PUBLISHING_ERROR = 'Publishing Error';
export const MAX_CAPTION_SIZE = 1024;
export const MAX_VIDEO_SIZE = 52428800;
export const MAX_FILE_SIZE = 20971520;
export const A_KEY_CODE = 65;
export const C_KEY_CODE = 67;
export const V_KEY_CODE = 86;
export const Z_KEY_CODE = 90;
export const X_KEY_CODE = 88;
export const MIN_VIEW_DURATION = 3000; // In milliseconds

export const BUBBLE_MIN_MEDIA_SIZE = 100;
export const BUBBLE_MAX_MEDIA_SIZE = 400;

export const TAC_RESET_PASSWORD_TYPE = 1;

// Those will be saved in the state in near future
export const CHANNEL_APP_EMAIL = 'zezaho@idx4.com';
export const CHANNEL_APP_PASSWORD = 'Khalifa123456#';

export const DEMO_PASSWORD = 'Khalifa123456#';

//
export const JSON_CONTENT_TYPE = 'application/json';
export const REQUEST_HEADER = { 'Content-Type': 'application/json' };

export enum MimeTypes {
  PNG = 'image/png',
  JPG = 'image/jpeg'
}

export enum AppClassNames {
  NAV_MENU = 'nav_menu',
  TABS = 'tabs',
  SECTIONS = 'sections',
  SPLASH = 'splash',
  PROFILE = 'profile',
  SEARCH = 'search_category',
  SETTINGS = 'settings',
  FORMS = 'forms',
  MENUS = 'menus'
}

export enum ProfileType {
  TEXT = 'text',
  INTEGER = 'integer',
  DATE = 'date',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  LIST = 'list'
}

export enum ContainerTypes {
  MENU = 'menu',
  HEADER = 'header',
  TAB = 'tab',
  FOOTER = 'footer',
  FIELD = 'field',
  SEARCH = 'search',
  HELP = 'help',
  ACCOUNT = 'account',
  CHATS = 'chats',
  CALLS = 'calls',
  MEDIA = 'media',
  NOTIFICATION = 'notification'
}

export enum ComponentTypes {
  MENU = 'menu',
  HEADER = 'header',
  TAB = 'tab',
  POST = 'post',
  FOOTER = 'footer',
  WALLET = 'wallet',
  CARD = 'card',
  WEB_VIEW = 'web_view',
  PAGE = 'page',
  BUTTON = 'button',
  SETTINGS_HELP = 'settings_help',
  SETTINGS_ACCOUNT = 'settings_account',
  SETTINGS_CHAT = 'settings_chat',
  SETTINGS_MEDIA = 'settings_media',
  SETTINGS_NOTIFICATION = 'settings_notification',
  INVITATION_ACCEPT = 'invitation_accept',
  INVITATION_CANCEL = 'invitation_cancel',
  TAG = 'tag',
  SEARCH = 'search',
  ABOUT = 'about',
  FAQ = 'faq',
  PRIVACY = 'privacy',
  SEND = 'send',
  GIF = 'gif',
  CHAT_HISTORY = 'chat_history',
  CALL = 'call',
  PERSONAL_MESSAGE = 'personal_message',
  GROUP_MESSAGE = 'group_message',
  CHANNEL_MESSAGE = 'channel_message',
  MAP_MESSAGE = 'map_message',
  MAP_SEARCH = 'map_search'
}
export enum MarkerTypes {
  Group = 'group',
  mStore = 'mstore',
  Booking = 'booking',
  Event = 'event',
  Channel = 'channel',
  Vapp = 'vapp'
}

export enum FooterActionTypes {
  LIKE = 'like',
  LIKE_HIGHLIGHT = 'like_highlight',
  REPLY = 'reply',
  VIEW = 'view',
  SHARE = 'share'
}

export enum TabTypes {
  FEED = 'feed',
  MEMBERSHIP = 'membership',
  WALLET = 'wallet',
  WEBVIEW = 'web_view',
  CHANNELS = 'channel',
  CHATS = 'chat',
  SETTINGS = 'setting',
  CALENDAR = 'event',
  INVITATION = 'invitation',
  OPENCHAT = 'open_chat',
  MYLIST = 'mylist',
  QR = 'qr',
  MAP = 'map',
  PAGE = 'page',
  SEARCH = 'search',
  GROUP = 'group',
  BOOKING = 'booking_list',
  CALL_LOG = 'call_log',
  SECTION = 'section',
  BUNDLE = 'bundle',
  VIDEO = 'video',
  PACKAGE = 'package',
  QR_PAGE = 'qr_page',
  VALIDATE_TICKET = 'validate_ticket', // fake type that will map to QR_PAGE with options = 1
  REDEEM_TICKET = 'redeem_ticket', // fake type that will map to QR_PAGE with options = 2
  MAP_SEARCH = 'map_search',
  MENUS = 'menu',
  ONLINECHANNEL = 'online_channels',
  ONLINEGROUP = 'online_groups',
  VIRTUALAPP = 'v_apps'
}

export enum ItemlinkList {
  LAST_SEEN = 'lastseen',
  READ_RECEIPTS = 'readreceipts',
  CHANGE_NUMBER = 'changenumber',
  CHANGE_LANGUAGE = 'changelanguage',
  SEND = 'send',
  AUTOPLAY = 'autoplay',
  DELETE_CHAT = 'deletechat',
  INCOMING_CALLS = 'incomingcalls',
  PUBLIC = 'public',
  FAMILY = 'family',
  FRIEND = 'friend',
  WORK = 'work',
  PRIORITY = 'priority',
  CONTACT = 'contact',
  GROUP = 'group',
  CHANNEL = 'channel'
}

export enum SubItemlinkList {
  MOBILE = 'mobile',
  WIFI = 'wifi',
  ROAMING = 'roaming'
}

export enum OpenChatTypes {
  CONTACT = 'contact',
  BOT = 'bot',
  GROUP = 'group'
}

export enum MyListTabTypes {
  CONTACT = 'contact',
  BOT = 'bot',
  GROUP = 'group',
  CHANNEL = 'channel',
  BOOKING = 'booking'
}

export enum PaymentStatus {
  PEND = 'Pending',
  DCLN = 'Declined',
  DCLN2 = 'Declined',
  POST = 'Posted',
  POST2 = 'Posted',
  INIT = 'Pending'
}

export enum ThemeStatus {
  Top = 'topBottom',
  Bottom = 'bottomBottom',
  Menu = 'Menu'
}

// scan: means only can scan and no user qr
// scan_user: scan camera open by default and user qr by clicking a button
// user_scan: user qr open by default and scan camera by clicking a button
export enum qrConfigOptions {
  SCAN = 'scan',
  SCAN_USER = 'scan_user',
  USER_SCAN = 'user_scan'
}

export const TAB_UPLOAD_TYPE = '3';
export const GROUP_UPLOAD_TYPE = '2';
export const COLORED_LOGO_UPLOAD_TYPE = 'c';
export const WHITE_LOGO_UPLOAD_TYPE = 'w';
export const SPLASH_PAGE_UPLOAD_TYPE = 's';
export const DOMINANT_COLOR = 'd';

export const LOGO_WIDTH = 1024;
export const LOGO_HEIGHT = 1024;

declare var require: any;
export const uuidv4 = require('uuid/v4');

export enum ChannelAppScreens {
  UPLOAD_LOGO = 1,
  TABS,
  NAV_MENU,
  MAIN,
  APP_RELEASES,
  PUBLISH,
  ACTIVATION,
  SEARCH
}

export interface FeaturesCategories {
  icon?: string;
  image?: string;
  title?: string;
  subTitle?: string;
  background?: string;
  id?: string;
  backgroundClicked?: string;
  link?: string;
  modules?: number[];
  type?: string;
}
export interface FeaturesModule {
  icon?: string;
  title?: string;
  defaultTitle?: string;
  subTitle?: string;
  id?: number;
  features?: FeatureAction[];
  category?: string[];
  must?: boolean;
  unique?: boolean;
  link?: string;
  parameter?: any;
  type?: string;
  desc?: string;
  is_vapp?: number;
}

export interface FeatureAction {
  id?: number;
  default?: boolean;
  read?: boolean;
}

export interface FeatureItem {
  image?: string;
  title?: string;
  subTitle?: string;
  icon?: string;
  id?: number;
  price?: number;
  duration?: number;
  action?: string;
}

// export enum AppManagementSteps {
//   UPLOAD_LOGO = 1,
//   // CHOOSE_VERSION,
//   MANAGE_DESIGN,
//   GET_APP
// }

export enum SECTION_CARRIED_TYPES {
  CONTAINER = 'container',
  COMPONENT = 'component',
  ITEM = 'item'
}

export enum LOGIN_TYPES {
  MSISDN = 'MSISDN',
  EMAIL = 'EMAIL',
  NO_LOGIN = 'NO_LOGIN',
  FACEBOOK = 'Facebook',
  LINKEDIN = 'Linkedin',
  TWITTER = 'Twitter'
}

export enum RELEASEAPP_STATUS {
  Pending = 'Pending',
  Complete = 'Complete',
  Download = 'Download',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Publishing = 'Publishing',
  Published = 'Published',
  Failed = 'Failed',
  CreatingApp = 'Creating App',
  Created = 'Created',
  Building = 'Building'
}

export const DEFAULT_APP_COLORS: AppColors = {
  primary: '#54bb6f',
  primary_dark: '#188a43',
  primary_light: '#87ee9e',
  secondary: '#775577',
  secondary_dark: '#623d62',
  secondary_light: '#8f748f',
  primary_text: '#2d2d2d',
  primary_text_inverted: '#ffffff',
  primary_bg: '#ffffff',
  primary_icon_fill: '#4a4a4a'
};

export const DEFAULT_POST: Posts = {
  message:
    'To find out about the offers in your country, please go to the Mercedes-Benz website for your country.',
  url:
    // tslint:disable-next-line:max-line-length
    'https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/c361/6196b96f8672fa9db4d21de185da4e177531e44db5d4580fb187f2a7c4fe1690.png?temp_url_sig=c886abcffc09643f086069dc7f5343bf05a44545&temp_url_expires=1862139424'
};

export enum POST_FORMATS {
  WIDE = 'wide',
  LEFT_SIDE = 'left_side'
}

export const EMPTY_STRING = '';

export const REACHED_MAX_ITEM_LIMIT =
  'Cannot add extra item. You have reached the max limit for items.';
export const REACHED_MIN_ITEM_LIMIT =
  'Cannot remove extra item. You have reached the min limit for items.';
export const NOT_ALLOWED_COLORS =
  'Please use another color. Off-white colors are not used for background.';
export const ALREADY_HAD_MAP =
  'You have already added the Map Tracking feature.';

export enum ChatTypes {
  GROUP = 'Group',
  CHANNEL = 'Channel',
  EVENT_CHANNEL = 'EventChannel',
  EVENT_GROUP = 'EventGroup',
  BOOKING_CHANNEL = 'BookingChannel',
  BOOKING_GROUP = 'BookingGroup',
  INDIVIDUAL = 'Individual',
  VIRTUAL_APP = 'VirtualApp'
}

export enum MarketTypes {
  PRODUCT,
  PACKAGE,
  BUNDLE,
  MSTORE,
  CHAT // groups, channels, events and bookings
}

export enum CHAT_DEFAULTS_JSON_KEYS {
  title = 'title',
  max_limit = 'max_limit',
  desc = 'desc',
  image_url = 'image_url'
}

export enum CampaignScreens {
  CAMPAIGN_LIST = 'CampaignList',
  CAMPAIGN_VIEW = 'CampaignView',
  CAMPAIGN_CREATION = 'CampaignCreation',
  CAMPAIGN_EDIT = 'CampaignEdit'
}
export enum CouponScreens {
  COUPON_LIST = 'CouponList',
  COUPON_VIEW = 'CouponView',
  COUPON_CREATION = 'CouponCreation',
  COUPON_EDIT = 'CouponEdit'
}

export enum EventScreens {
  EVENT_LIST = 'EventList',
  EVENT_VIEW = 'EventView',
  EVENT_CREATION = 'EventCreation',
  EVENT_EDIT = 'EventEdit',
  EVENT_PHOTO_UPLOADING = 'EventPhotoUploading'
}
export enum BookingScreens {
  BOOKING_LIST = 'BookingList',
  BOOKING_VIEW = 'BookingView',
  BOOKING_CREATION = 'BookingCreation',
  BOOKING_EDIT = 'BookingEdit',
  RESERVATIONS = 'Reservations',
  BOOKING_PHOTO_UPLOADING = 'BookingPhotoUploading'
}

export enum CalendarScreens {
  CALENDAR_LIST = 'CalendarList',
  CALENDAR_VIEW = 'CalendarView',
  CALENDAR_CREATION = 'CalendarCreation',
  CALENDAR_EDIT = 'CalendarEdit'
}

export enum BundleScreens {
  BUNDLE_LIST = 'BundleList',
  BUNDLE_VIEW = 'BundleView',
  BUNDLE_CREATION = 'BundleCreation',
  BUNDLE_EDIT = 'BundleEdit'
}

export enum ProductScreens {
  PRODUCT_LIST = 'ProductList',
  PRODUCT_VIEW = 'ProductView',
  PRODUCT_CREATION = 'ProductCreation',
  PRODUCT_EDIT = 'ProductEdit'
}

export enum PackageScreens {
  PACKAGE_LIST = 'PackageList',
  PACKAGE_VIEW = 'PackageView',
  PACKAGE_CREATION = 'PackageCreation',
  PACKAGE_EDIT = 'PackageEdit'
}

export enum mStoreScreens {
  mStore_LIST = 'mStoreList',
  mStore_VIEW = 'mStoreView',
  mStore_CREATION = 'mStoreCreation',
  mStore_EDIT = 'mStoreEdit'
}

export enum mSubMenuScreens {
  mSubMenu_LIST = 'mSubMenuList',
  mSubMenu_VIEW = 'mSubMenuView',
  mSubMenu_CREATION = 'mSubMenuCreation',
  mSubMenu_EDIT = 'mSubMenuEdit'
}

export enum mStoreMenuScreens {
  mStoreMenu_LIST = 'mStoreMenuList',
  mStoreMenu_VIEW = 'mStoreMenuView',
  mStoreMenu_CREATION = 'mStoreMenuCreation',
  mStoreMenu_EDIT = 'mStoreMenuEdit'
}

export enum BotScreens {
  BOT_LIST = 'BotList',
  BOT_VIEW = 'BotView',
  BOT_CREATION = 'BotCreation',
  BOT_EDIT = 'BotEdit'
}

export enum SubChatScreens {
  SUB_CHAT_LIST = 'SubChatList',
  SUB_CHAT_VIEW = 'SubChatView',
  SUB_CHAT_CREATION = 'SubChatCreation',
  SUB_CHAT_EDIT = 'SubChatEdit',
  SUB_CHAT_PHOTO_UPLOADING = 'SubChatPhotoUploading'
}

export enum LocationScreens {
  LOCATION_LIST = 'locationList',
  LOCATION_VIEW = 'locationView',
  LOCATION_CREATION = 'locationCreation',
  LOCATION_EDIT = 'locationEdit'
}
export enum MapServiceScreens {
  MAP_SERVICE_LIST = 'mapServiceList',
  MAP_SERVICE_VIEW = 'mapServiceView',
  MAP_SERVICE_CREATION = 'mapServiceCreation',
  MAP_SERVICE_EDIT = 'mapServiceEdit'
}

export enum MapRoutesScreens {
  MAP_ROUTES_LIST = 'mapRoutesList',
  MAP_ROUTES_VIEW = 'mapRoutesView',
  MAP_ROUTES_CREATION = 'mapRoutesCreation',
  MAP_ROUTES_EDIT = 'mapRoutesEdit'
}

export enum TripsScreens {
  TRIPS_LIST = 'tripsList',
  TRIPS_VIEW = 'tripsView',
  TRIPS_CREATION = 'tripsCreation',
  TRIPS_EDIT = 'tripsEdit',
  DRIVER_EDIT = 'driverEdit'
}

export enum TripsUsersScreens {
  TRIPS_USERS_LIST_OPEN = 'tripsUsersListOpen',
  TRIPS_USERS_LIST_CLOSE = 'tripsUsersListClose',
  TRIPS_USERS_CREATION = 'tripsUsersCreation',
  TRIPS_USERS_EDIT = 'tripsUsersEdit'
}

export enum TripsNotifiersFormScreens {
  OPEN_PASSENGER_POPUP_LIST_SELECTORS = 'openPassengerListSelectors',
  OPEN_NOTIFIERS_POPUP_LIST_SELECTORS = 'openNotifiersListSelectors',
  CLOSE_POPUP_SELECTORS = 'closePopupSelectors'
}

export enum PageFormContainerTypes {
  SLIDER = 'slider',
  TEXT = 'text',
  VIDEO = 'video',
  PDF = 'pdf',
  LOCATION = 'location',
  GALLERY = 'gallery',
  LIST = 'list',
  youTubeList = 'youtubeList'
}

export enum APP_CATEGORIES {
  Schools = 'D',
  Club = 'T',
  Community = 'S',
  Multitmedia = 'M',
  Cities = 'C',
  Retail = 'O',
  Entertainment = 'E',
  Health = 'H',
  Lifestyle = 'L',
  News = 'N',
  Religion = 'R',
  Government = 'G',
  NewType = 'U',
  NewType2 = 'K',
  NewType3 = 'I',
  Maps = 'P',
  Offeres = 'F'
}

export interface AppCategory {
  name: string;
  id: string;
}
export const APP_UPLOAD_TEMPLATES_CATEGORIES: AppCategory[] = [
  {
    name: 'Communication',
    id: 'U'
  },
  {
    name: 'Social Networking',
    id: 'K'
  },
  {
    name: 'Media Channel',
    id: 'M'
  },
  {
    name: 'Selling / Shopping',
    id: 'O'
  },
  {
    name: 'Ticketing / Events',
    id: 'I'
  },
  {
    name: 'Community',
    id: 'C'
  },
  {
    name: 'Education / Schools',
    id: 'D'
  },
  {
    name: 'Maps',
    id: 'P'
  },
  {
    name: 'Sports Teams / Fans',
    id: 'T'
  },
  {
    name: 'Offering Services',
    id: 'F'
  },
  {
    name: 'Entertainment',
    id: 'E'
  },
  {
    name: 'Health',
    id: 'H'
  },
  {
    name: 'Lifestyle',
    id: 'L'
  },
  {
    name: 'News',
    id: 'N'
  },
  {
    name: 'Religion',
    id: 'R'
  },
  {
    name: 'Government',
    id: 'G'
  }
];

export enum APP_MENU_CATEGORIES {
  MAP = 'MAP'
}

export interface TypeItem {
  title?: string;
  type?: string;
  page_id?: string;
}
export interface PagesLinks {
  title?: string;
  link?: string;
}

export interface CurrencyTypes {
  shortName?: string;
  longName?: string;
}

export interface UserData {
  category?: string;
  teq?: string;
}

export enum APP_REVOKE_TYPES {
  APP = 'app',
  CHANNEL = 'channel'
}

export const PUBLISH_APP = 'PublishApp';
export const PUBLISH_CHANNEL = 'PublishChannel';
export const SAVE = 'Save';
export const REQUEST_CONFIG = 'Request';
export const CREATE_BOT_REF = 'CreateBotRef';
export const ADD_BOT_AS_ADMIN_REF = 'AddBotToMyBotsRef';
export const UPDATE_GROUP_REF = 'UpdateGroupRef';
export const UPDATE_CHANNEL_REF = 'UpdateChannelRef';
export const UPDATE_EVENT_REF = 'UpdateEventRef';
export const UPDATE_BOOKING_REF = 'UpdateBookingRef';
export const UPDATE_APP_CHANNEL_REF = 'UpdateAppChannelRef';
export const CREATE_EVENT_REF = 'CreateEventRef';
export const CREATE_GROUP_REF = 'CreateGroupRef';
export const CREATE_CHANNEL_REF = 'CreateChannelRef';
export const CREATE_BOOKING_SPACE_REF = 'CreateBookingRef';
export const CREATE_BOOKING_PERIODS_REF = 'CreateBookingPeriodsRef';
export const GENERATE_APP_REF = 'GenerateAppRef';
export const CREATE_CAMPAIGN_REF = 'CreateCampaignRef';
export const UPDATE_CAMPAIGN_REF = 'UpdateCampaignRef';
export const CREATE_COUPON_REF = 'CreateCouponRef';
export const CREATE_COUPON_POPUP_REF = 'CreateCouponPopupRef';
export const UPDATE_COUPON_REF = 'UpdateCouponRef';
export const CREATE_APP_TEMPLATE_REF = 'CreateAppTemplateRef';
export const TAB_TAGS_REF_APP = 'TabTagsRefApp';
export const TAB_TAGS_REF_CHANNEL = 'TabTagsRefChannel';
export const UPDATE_VIRTUAL_APP_REF = 'UpdateVirtualAppRef';
export const CREATE_VIRTUAL_APP_REF = 'CreateVirtualAppRef';
//
export const CREATE_BULK_REF = 'CreateBulkRef';
export const UPDATE_BULK_REF = 'UpdateBulkRef';

export const CREATE_MAP_SERVICE_REF = 'CreateMapServiceRef';
export const CREATE_MAP_MARKER_REF = 'CreateMapMarkerRef';
export const GET_MAP_MARKER_REF = 'GetMapMarkerRef';
export const CREATE_MAP_ROUTE_REF = 'CreateMapRouteRef';
export const CREATE_MAP_TRIP_REF = 'CreateMapTripRef';
export const CREATE_MAP_TRIP_USER_REF = 'CreateMapTripUserRef';
//
export const CREATE_PRODUCT_REF = 'CreateProductRef';
export const CREATE_CALENDAR_REF = 'CreateCalendarRef';
export const CREATE_CALENDAR_DETAIL_REF = 'CreateCalendarDetailRef';
export const CREATE_BUNDLE_REF = 'CreateBundleRef';
export const CREATE_PACKAGE_REF = 'CreatePackageRef';
export const CREATE_STORE_REF = 'CreateStoreRef';
export const CREATE_SUB_MENU_REF = 'CreateSubMenuRef';
export const CREATE_STORE_MENU_REF = 'CreateStoreMenuRef';
//
export const PAYMENT_ORDER_USERS_REF = 'PaymentOrderUsers';
export const PAYMENT_ORDER_GROUP_REF = 'PaymentOrderGroups';
export const MY_BOOKING_USERS_REF = 'MyBookingUsers';
export const REQUEST_MY_BOOKING = 'RequestMyBooking';
export const CANCEL_MY_BOOKING_REF = 'CancelMyBookingRef';
//
export const CREATE_BOT_MENU_REF = 'CreateBotMenuRef';
export const UPDATE_BOT_MENU_REF = 'UpdateBotMenuRef';
export const DELETE_BOT_MENU_REF = 'DeleteBotMenuRef';
//
export const DELETE_MEMBER_FROM_MAIN_CHAT_REF =
  'DELETE_MEMBER_FROM_MAIN_CHAT_REF';
export const DELETE_MEMBER_FROM_SUB_CHAT_REF =
  'DELETE_MEMBER_FROM_SUB_CHAT_REF';
//
export const APK = 'APK';
export const IPA = 'IPA';
export const POST_TAG_TYPE = 'Post';
export const ADMIN_TAG_TYPE = 'Admin';
export const LOGIN_TAG_TYPE = 'Login';
export const APP_CONFIG_COMPLETE = '1000';
export const DEFAULT_MAX_SEARCH_CONTAINERS = 3;

export enum CHANNEL_CATEGORIES {
  Social = 'K',
  Entertainment = 'E',
  HealthAndFitness = 'H',
  Lifestyle = 'L',
  Media = 'M',
  NewsAndMagazines = 'N',
  Sports = 'T',
  Education = 'D',
  Corporate = 'C',
  Goverment = 'G',
  Professional = 'P',
  StoresAndRetail = 'O',
  Religion = 'R'
}

export const CHAT_CATEGORIES = [
  {
    key: 'S',
    value: 'Social'
  },
  {
    key: 'E',
    value: 'Entertainment'
  },
  {
    key: 'H',
    value: 'Health And Fitness'
  },
  {
    key: 'L',
    value: 'Lifestyle'
  },
  {
    key: 'M',
    value: 'Media'
  },
  {
    key: 'N',
    value: 'News And Magazines'
  },
  {
    key: 'T',
    value: 'Sports'
  },
  {
    key: 'D',
    value: 'Education'
  },
  {
    key: 'C',
    value: 'Corporate'
  },
  {
    key: 'G',
    value: 'Government'
  },
  {
    key: 'P',
    value: 'Professional'
  },
  {
    key: 'O',
    value: 'Stores And Retail'
  },
  {
    key: 'R',
    value: 'Religion'
  }
];

export const MOBILE_SYSTEM_ICON = ['android', 'apple'];
export enum MOBILE_TYPES {
  ANDROID = 'Android',
  IOS = 'IOS'
}
export enum MOBILE_SYSTEM {
  Android,
  IOS
}
export enum MOBILE_SYSTEM_FILE_TYPE {
  APK = 'APK',
  IPA = 'IPA',
  BUNDLE = 'Bundle'
}

export enum PAGING_DIRECTION {
  NEXT = 0,
  PREVIOUS = 1
}

export enum SCREEN_STATUS {
  REQUESTING = 'REQUESTING',
  IDLE = 'IDLE'
}

export enum ADMIN_PRIVILEGES {
  CHANGE_CHANNEL_INFO = '2',
  POST_MESSAGES = '4',
  EDIT_MESSAGE_OF_OTHERS = '8',
  DELETE_MESSAGE_OF_OTHERS = '16',
  INVITE_PARTICIPANT = '32',
  INVITE_ADMIN = '64',
  REPLY_TO_MESSAGES = '128',
  MOBILE_ACCESS = '256',
  WEB_ACCESS = '512',
  EDIT_ADMIN_PRIVILEGES = '1024',
  REMOVE_PARTICIPANT = '2048',
  BAN_PARTICIPANT = '4096',
  GET_USER_LOGIN_ID = '32768'
}

export enum PAYMENT_STATUS {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed'
}

export enum CHANNEL_APP_SEARCH_CATEGORIES {
  AREA = 'area',
  CATEGORY = 'category',
  CLASSIFICATIONS = 'classifications'
}

export enum TYPES_FOR_SEARCH {
  GROUP = 0,
  CHANNEL = 1,
  GROUP_EVENT = 2,
  CHANNEL_EVENT = 3,
  GROUP_BOOKING = 4,
  CHANNEL_BOOKING = 5,
  GROUP_QUEUE = 6,
  CHANNEL_QUEUE = 7,
  BOT = 100,
  MARKER = 103,
  STOREITEM = 206,
  STOREITEM_PRODUCT = 207,
  STOREITEM_BUNDLE = 208
}

export enum MENU_BOTTON_STYLE {
  TOPIMG = 'top_img',
  WIDELIFT = 'wide_left_img',
  SMALLHL = 'small_height_left_img',
  BGIMG = 'bg_img',
  WIDEBG = 'wide_bg_img',
  TEXTONLY = 'text_only',
  CENTERIMG = 'center_img',
  BACK = 'back'
}

export enum BOOKING_DAYS {
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday'
}

export const PERIODS = [
  {
    period: 'Week',
    period_server: 'WEEK',
    period_number: 1
  },
  {
    period: 'Month',
    period_server: 'MONTH',
    period_number: 1
  },
  {
    period: 'Month',
    period_server: 'MONTH',
    period_number: 3
  },
  {
    period: 'Month',
    period_server: 'MONTH',
    period_number: 6
  },
  {
    period: 'Year',
    period_server: 'YEAR',
    period_number: 1
  }
];
export const PRICES = {
  AED: [
    1.99,
    2.99,
    3.69,
    3.99,
    4.99,
    5.99,
    6.99,
    7.29,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    36.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    394.99,
    399.99,
    404.99,
    409.99,
    414.99,
    419.99,
    424.99,
    429.99,
    434.99,
    439.99,
    444.99,
    449.99,
    454.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    629.99,
    649.99,
    679.99,
    699.99,
    729.99,
    749.99,
    779.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    929.99,
    949.99,
    979.99,
    999.99,
    1099.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99,
    1699.99,
    1799.99,
    1899.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99,
    2999.99,
    3299.99,
    3499.99,
    3699.99
  ],
  AUD: [
    0.49,
    0.99,
    1.49,
    1.99,
    2.49,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    379.99,
    389.99,
    399.99,
    499.99,
    549.99,
    599.99,
    699.99,
    799.99,
    899.99,
    999.99,
    1099.99,
    1249.99,
    1399.99,
    1599.99
  ],
  BGN: [
    0.49,
    0.99,
    1.49,
    1.99,
    2.49,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    21.99,
    22.99,
    23.99,
    24.99,
    25.99,
    26.99,
    27.99,
    28.99,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    202.99,
    204.99,
    207.99,
    209.99,
    212.99,
    214.99,
    217.99,
    219.99,
    222.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    379.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    529.99,
    549.99,
    599.99,
    649.99,
    699.99,
    749.99,
    799.99,
    849.99,
    899.99,
    949.99,
    999.99,
    1099.99,
    1299.99,
    1499.99,
    1799.99,
    1999.99,
    2199.99
  ],
  BRL: [
    0.9,
    1.5,
    1.9,
    2.5,
    2.9,
    3.5,
    3.9,
    4.5,
    4.9,
    5.5,
    5.9,
    6.5,
    6.9,
    7.5,
    7.9,
    8.5,
    8.9,
    9.5,
    9.9,
    10.9,
    11.9,
    12.9,
    13.9,
    14.9,
    15.9,
    16.9,
    17.9,
    18.9,
    19.9,
    20.9,
    21.9,
    22.9,
    23.9,
    24.9,
    25.9,
    26.9,
    27.9,
    28.9,
    29.9,
    30.9,
    31.9,
    32.9,
    33.9,
    34.9,
    35.9,
    36.9,
    37.9,
    38.9,
    39.9,
    40.9,
    41.9,
    42.9,
    43.9,
    44.9,
    45.9,
    46.9,
    47.9,
    48.9,
    49.9,
    50.9,
    51.9,
    52.9,
    53.9,
    54.9,
    55.9,
    56.9,
    57.9,
    58.9,
    59.9,
    60.9,
    61.9,
    62.9,
    63.9,
    64.9,
    65.9,
    66.9,
    67.9,
    68.9,
    69.9,
    70.9,
    71.9,
    72.9,
    73.9,
    74.9,
    75.9,
    76.9,
    77.9,
    78.9,
    79.9,
    82.9,
    84.9,
    87.9,
    89.9,
    92.9,
    94.9,
    97.9,
    99.9,
    102.9,
    104.9,
    107.9,
    109.9,
    112.9,
    114.9,
    117.9,
    119.9,
    122.9,
    124.9,
    127.9,
    129.9,
    132.9,
    134.9,
    137.9,
    139.9,
    142.9,
    144.9,
    147.9,
    149.9,
    152.9,
    154.9,
    157.9,
    159.9,
    162.9,
    164.9,
    167.9,
    169.9,
    172.9,
    174.9,
    177.9,
    179.9,
    182.9,
    184.9,
    187.9,
    189.9,
    192.9,
    194.9,
    197.9,
    199.9,
    204.9,
    209.9,
    214.9,
    219.9,
    224.9,
    229.9,
    234.9,
    239.9,
    244.9,
    249.9,
    254.9,
    259.9,
    264.9,
    269.9,
    274.9,
    279.9,
    284.9,
    289.9,
    294.9,
    299.9,
    309.9,
    319.9,
    329.9,
    339.9,
    349.9,
    359.9,
    369.9,
    379.9,
    389.9,
    399.9,
    409.9,
    419.9,
    429.9,
    439.9,
    449.9,
    459.9,
    469.9,
    479.9,
    489.9,
    499.9,
    529.9,
    549.9,
    579.9,
    599.9,
    629.9,
    649.9,
    679.9,
    699.9,
    749.9,
    799.9,
    849.9,
    899.9,
    999.9,
    1099.9,
    1299.9,
    1499.9,
    1699.9,
    1999.9,
    2299.9,
    2499.9,
    2799.9,
    2999.9,
    3299.9
  ],
  CAD: [
    0.49,
    0.99,
    1.29,
    1.39,
    1.49,
    1.99,
    2.49,
    2.79,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    219.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    319.99,
    329.99,
    349.99,
    399.99,
    449.99,
    499.99,
    549.99,
    599.99,
    649.99,
    699.99,
    749.99,
    799.99,
    849.99,
    899.99,
    949.99,
    999.99,
    1099.99,
    1199.99,
    1299.99,
    1399.99
  ],
  CHF: [
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    12.5,
    12.9,
    13,
    13.5,
    14,
    14.5,
    14.9,
    15,
    15.5,
    16,
    16.5,
    17,
    17.5,
    17.9,
    18,
    18.5,
    19,
    19.5,
    19.9,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    28.5,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    47.5,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    79,
    80,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    90,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    106,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    119,
    120,
    121,
    122,
    123,
    124,
    125,
    126,
    127,
    128,
    129,
    130,
    135,
    140,
    145,
    150,
    155,
    160,
    165,
    170,
    175,
    180,
    185,
    190,
    195,
    200,
    205,
    210,
    215,
    220,
    225,
    230,
    235,
    240,
    245,
    250,
    255,
    260,
    265,
    270,
    275,
    280,
    285,
    290,
    295,
    300,
    350,
    400,
    450,
    500,
    600,
    700,
    800,
    900,
    1000,
    1100
  ],
  CLP: [
    190,
    290,
    390,
    490,
    590,
    690,
    790,
    890,
    990,
    1100,
    1200,
    1300,
    1400,
    1500,
    1600,
    1700,
    1800,
    1900,
    2200,
    2500,
    2700,
    2900,
    3200,
    3500,
    3700,
    3900,
    4200,
    4500,
    4700,
    4900,
    5200,
    5500,
    5700,
    5900,
    6200,
    6500,
    6700,
    6900,
    7200,
    7500,
    7700,
    7900,
    8200,
    8500,
    8700,
    8900,
    9200,
    9500,
    9700,
    9900,
    10200,
    10500,
    10700,
    10900,
    11200,
    11500,
    11700,
    11900,
    12200,
    12500,
    12700,
    12900,
    13200,
    13500,
    13900,
    14500,
    14900,
    15500,
    15900,
    16500,
    16900,
    17500,
    17900,
    18500,
    18900,
    19500,
    19900,
    20500,
    20900,
    21500,
    21900,
    22500,
    22900,
    23500,
    23900,
    24500,
    24900,
    25500,
    25900,
    26500,
    26900,
    27500,
    27900,
    28500,
    28900,
    29500,
    29900,
    30900,
    31900,
    32900,
    33900,
    34900,
    35900,
    36900,
    37900,
    38900,
    39900,
    40900,
    41900,
    42900,
    43900,
    44900,
    45900,
    46900,
    47900,
    48900,
    49900,
    50900,
    51900,
    52900,
    53900,
    54900,
    55900,
    56900,
    57900,
    58900,
    59900,
    60900,
    61900,
    62900,
    63900,
    64900,
    65900,
    66900,
    67900,
    68900,
    69900,
    70900,
    71900,
    72900,
    73900,
    74900,
    75900,
    76900,
    77900,
    78900,
    79900,
    80900,
    81900,
    82900,
    83900,
    84900,
    85900,
    86900,
    87900,
    88900,
    89900,
    90900,
    91900,
    92900,
    93900,
    94900,
    95900,
    96900,
    97900,
    98900,
    99900,
    104900,
    109900,
    114900,
    119900,
    124900,
    129900,
    134900,
    139900,
    144900,
    149900,
    159900,
    169900,
    179900,
    189900,
    199900,
    209900,
    219900,
    229900,
    239900,
    249900,
    259900,
    269900,
    279900,
    289900,
    299900,
    349900,
    399900,
    449900,
    499900,
    549900,
    599900,
    649900,
    699900
  ],
  CNY: [
    1,
    1.99,
    3,
    3.99,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    30,
    33,
    35,
    38,
    40,
    43,
    45,
    48,
    50,
    53,
    58,
    60,
    63,
    68,
    70,
    73,
    78,
    80,
    83,
    88,
    90,
    93,
    98,
    100,
    103,
    108,
    113,
    118,
    123,
    128,
    133,
    138,
    143,
    148,
    153,
    158,
    163,
    168,
    173,
    178,
    183,
    188,
    193,
    198,
    203,
    208,
    213,
    218,
    223,
    228,
    233,
    238,
    243,
    248,
    253,
    258,
    263,
    268,
    273,
    278,
    283,
    288,
    293,
    298,
    303,
    308,
    313,
    318,
    323,
    328,
    333,
    338,
    343,
    348,
    353,
    358,
    363,
    368,
    373,
    378,
    383,
    388,
    393,
    398,
    408,
    418,
    428,
    438,
    448,
    458,
    468,
    478,
    488,
    498,
    508,
    518,
    528,
    538,
    548,
    558,
    568,
    578,
    588,
    598,
    608,
    618,
    628,
    638,
    648,
    658,
    668,
    678,
    688,
    698,
    708,
    718,
    728,
    738,
    748,
    758,
    768,
    778,
    788,
    798,
    818,
    848,
    868,
    888,
    898,
    918,
    948,
    968,
    988,
    998,
    1048,
    1098,
    1148,
    1198,
    1248,
    1298,
    1348,
    1398,
    1448,
    1498,
    1548,
    1598,
    1648,
    1698,
    1748,
    1798,
    1848,
    1898,
    1948,
    1998,
    2098,
    2198,
    2298,
    2398,
    2498,
    2598,
    2698,
    2798,
    2898,
    2998,
    3298,
    3498,
    3998,
    4498,
    4998,
    5498,
    5898,
    6498
  ],
  COP: [
    900,
    1500,
    1900,
    2500,
    2900,
    3500,
    3900,
    4500,
    4900,
    5500,
    5900,
    6500,
    6900,
    7500,
    7900,
    8500,
    8900,
    9500,
    9900,
    10900,
    11900,
    12900,
    13900,
    14900,
    15900,
    16900,
    17900,
    18900,
    19900,
    20900,
    21900,
    22900,
    23900,
    24900,
    25900,
    26900,
    27900,
    28900,
    29900,
    30900,
    31900,
    32900,
    33900,
    34900,
    35900,
    36900,
    37900,
    38900,
    39900,
    40900,
    41900,
    42900,
    43900,
    44900,
    45900,
    46900,
    47900,
    48900,
    49900,
    52900,
    54900,
    57900,
    59900,
    62900,
    64900,
    67900,
    69900,
    72900,
    74900,
    77900,
    79900,
    82900,
    84900,
    87900,
    89900,
    92900,
    94900,
    97900,
    99900,
    102900,
    104900,
    107900,
    109900,
    112900,
    114900,
    117900,
    119900,
    122900,
    124900,
    127900,
    129900,
    132900,
    134900,
    137900,
    139900,
    142900,
    144900,
    147900,
    149900,
    152900,
    154900,
    157900,
    159900,
    162900,
    164900,
    167900,
    169900,
    172900,
    174900,
    177900,
    179900,
    182900,
    184900,
    187900,
    189900,
    192900,
    194900,
    197900,
    199900,
    204900,
    209900,
    214900,
    219900,
    224900,
    229900,
    234900,
    239900,
    244900,
    249900,
    254900,
    259900,
    264900,
    269900,
    274900,
    279900,
    284900,
    289900,
    294900,
    299900,
    304900,
    309900,
    314900,
    319900,
    324900,
    329900,
    334900,
    339900,
    344900,
    349900,
    354900,
    359900,
    364900,
    369900,
    374900,
    379900,
    384900,
    389900,
    394900,
    399900,
    409900,
    419900,
    429900,
    439900,
    449900,
    459900,
    469900,
    479900,
    489900,
    499900,
    529900,
    549900,
    579900,
    599900,
    629900,
    649900,
    679900,
    699900,
    729900,
    749900,
    779900,
    799900,
    829900,
    849900,
    879900,
    899900,
    929900,
    949900,
    979900,
    999900,
    1099900,
    1199900,
    1299900,
    1399900,
    1499900,
    1799900,
    1999900,
    2299900,
    2499900,
    2799900,
    2999900
  ],
  CZK: [
    9,
    19,
    29,
    39,
    49,
    59,
    69,
    79,
    89,
    99,
    109,
    119,
    129,
    139,
    149,
    159,
    169,
    179,
    189,
    199,
    209,
    219,
    229,
    239,
    249,
    259,
    269,
    279,
    289,
    299,
    309,
    319,
    329,
    339,
    349,
    359,
    369,
    379,
    389,
    399,
    409,
    419,
    429,
    439,
    449,
    459,
    469,
    479,
    489,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    699,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    799,
    809,
    819,
    829,
    839,
    849,
    859,
    869,
    879,
    889,
    899,
    909,
    919,
    929,
    939,
    949,
    959,
    969,
    979,
    989,
    999,
    1020,
    1050,
    1070,
    1090,
    1120,
    1150,
    1170,
    1190,
    1220,
    1250,
    1270,
    1290,
    1320,
    1350,
    1370,
    1390,
    1450,
    1490,
    1550,
    1590,
    1650,
    1690,
    1750,
    1790,
    1850,
    1890,
    1950,
    1990,
    2050,
    2090,
    2150,
    2190,
    2250,
    2290,
    2350,
    2390,
    2450,
    2490,
    2550,
    2590,
    2650,
    2690,
    2750,
    2790,
    2850,
    2890,
    2950,
    2990,
    3050,
    3090,
    3150,
    3190,
    3250,
    3290,
    3350,
    3390,
    3450,
    3490,
    3550,
    3590,
    3650,
    3690,
    3750,
    3790,
    3850,
    3890,
    3950,
    3990,
    4290,
    4490,
    4790,
    4990,
    5290,
    5490,
    5790,
    5990,
    6290,
    6490,
    6790,
    6990,
    7290,
    7490,
    7790,
    7990,
    8290,
    8490,
    8790,
    8990,
    9290,
    9490,
    9790,
    9990,
    11990,
    13990,
    14990,
    17990,
    19990,
    24990,
    26990,
    29990
  ],
  DKK: [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    25,
    29,
    35,
    39,
    45,
    49,
    55,
    59,
    65,
    69,
    75,
    79,
    85,
    89,
    95,
    99,
    105,
    109,
    115,
    119,
    125,
    129,
    135,
    139,
    145,
    149,
    155,
    159,
    165,
    169,
    175,
    179,
    185,
    189,
    195,
    199,
    205,
    209,
    215,
    219,
    225,
    229,
    235,
    239,
    245,
    249,
    255,
    259,
    265,
    269,
    275,
    279,
    285,
    289,
    295,
    299,
    305,
    309,
    315,
    319,
    325,
    329,
    335,
    339,
    345,
    349,
    355,
    359,
    365,
    369,
    375,
    379,
    385,
    389,
    395,
    399,
    405,
    409,
    415,
    419,
    425,
    429,
    435,
    439,
    445,
    449,
    455,
    459,
    465,
    469,
    475,
    479,
    485,
    489,
    495,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    699,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    799,
    809,
    819,
    829,
    839,
    849,
    859,
    869,
    879,
    889,
    899,
    909,
    919,
    929,
    939,
    949,
    959,
    969,
    979,
    989,
    999,
    1049,
    1099,
    1149,
    1199,
    1249,
    1299,
    1349,
    1399,
    1449,
    1499,
    1549,
    1599,
    1649,
    1699,
    1749,
    1799,
    1849,
    1899,
    1949,
    1999,
    2099,
    2199,
    2299,
    2399,
    2499,
    2799,
    2999,
    3299,
    3499,
    3799,
    3999,
    4299,
    4499,
    4799,
    4999,
    5999,
    6999,
    7999
  ],
  EGP: [
    2.99,
    3.99,
    4.99,
    5.99,
    6.99,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    104.99,
    109.99,
    114.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    159.99,
    169.99,
    179.99,
    189.99,
    199.99,
    209.99,
    219.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    379.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    609.99,
    619.99,
    629.99,
    639.99,
    649.99,
    659.99,
    669.99,
    679.99,
    689.99,
    699.99,
    709.99,
    719.99,
    729.99,
    739.99,
    749.99,
    759.99,
    769.99,
    779.99,
    789.99,
    799.99,
    809.99,
    819.99,
    829.99,
    839.99,
    849.99,
    859.99,
    869.99,
    879.99,
    889.99,
    899.99,
    909.99,
    919.99,
    929.99,
    939.99,
    949.99,
    959.99,
    969.99,
    979.99,
    989.99,
    999.99,
    1049.99,
    1099.99,
    1149.99,
    1199.99,
    1249.99,
    1299.99,
    1349.99,
    1399.99,
    1449.99,
    1499.99,
    1549.99,
    1599.99,
    1649.99,
    1699.99,
    1749.99,
    1799.99,
    1849.99,
    1899.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99,
    2999.99,
    3099.99,
    3199.99,
    3299.99,
    3399.99,
    3499.99,
    3599.99,
    3699.99,
    3799.99,
    3899.99,
    3999.99,
    4099.99,
    4199.99,
    4299.99,
    4399.99,
    4499.99,
    4799.99,
    5499.99,
    6499.99,
    7499.99,
    8499.99,
    9499.99,
    11999.99,
    12999.99,
    14999.99,
    16999.99,
    18999.99
  ],
  EUR: [
    0.49,
    0.99,
    1.49,
    1.99,
    2.49,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    120.99,
    121.99,
    122.99,
    123.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    329.99,
    349.99,
    399.99,
    449.99,
    499.99,
    599.99,
    699.99,
    799.99,
    899.99,
    999.99
  ],
  GBP: [
    0.49,
    0.79,
    0.99,
    1.29,
    1.49,
    1.79,
    1.99,
    2.29,
    2.49,
    2.79,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    120.99,
    121.99,
    122.99,
    123.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    349.99,
    399.99,
    449.99,
    499.99,
    549.99,
    599.99,
    649.99,
    699.99,
    749.99,
    799.99,
    849.99,
    899.99
  ],
  HKD: [
    5,
    8,
    10,
    13,
    15,
    18,
    20,
    23,
    25,
    28,
    33,
    38,
    43,
    48,
    53,
    58,
    63,
    68,
    73,
    78,
    83,
    88,
    93,
    98,
    103,
    108,
    113,
    118,
    123,
    128,
    133,
    138,
    143,
    148,
    153,
    158,
    163,
    168,
    173,
    178,
    183,
    188,
    193,
    198,
    203,
    208,
    213,
    218,
    223,
    228,
    233,
    238,
    243,
    248,
    253,
    258,
    263,
    268,
    273,
    278,
    283,
    288,
    293,
    298,
    303,
    308,
    313,
    318,
    323,
    328,
    333,
    338,
    343,
    348,
    353,
    358,
    363,
    368,
    373,
    378,
    383,
    388,
    393,
    398,
    403,
    408,
    413,
    418,
    423,
    428,
    433,
    438,
    443,
    448,
    453,
    458,
    463,
    468,
    473,
    478,
    483,
    488,
    493,
    498,
    508,
    518,
    528,
    538,
    548,
    558,
    568,
    578,
    588,
    598,
    608,
    618,
    628,
    638,
    648,
    658,
    668,
    678,
    688,
    698,
    708,
    718,
    728,
    738,
    748,
    758,
    768,
    778,
    788,
    798,
    808,
    818,
    828,
    838,
    848,
    858,
    868,
    878,
    888,
    898,
    908,
    918,
    928,
    938,
    948,
    958,
    968,
    978,
    988,
    998,
    1048,
    1088,
    1148,
    1188,
    1248,
    1288,
    1348,
    1388,
    1448,
    1488,
    1548,
    1588,
    1648,
    1688,
    1748,
    1788,
    1848,
    1888,
    1948,
    1988,
    2088,
    2188,
    2288,
    2388,
    2488,
    2588,
    2688,
    2788,
    2888,
    2988,
    3088,
    3188,
    3288,
    3388,
    3488,
    3588,
    3688,
    3788,
    3888,
    3988,
    4488,
    4988,
    5488,
    5988,
    6988,
    7888
  ],
  HRK: [
    1.99,
    2.99,
    3.99,
    4.99,
    5.99,
    6.99,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    104.99,
    109.99,
    114.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    394.99,
    399.99,
    404.99,
    409.99,
    414.99,
    419.99,
    424.99,
    429.99,
    434.99,
    439.99,
    444.99,
    449.99,
    454.99,
    459.99,
    464.99,
    469.99,
    474.99,
    479.99,
    484.99,
    489.99,
    494.99,
    499.99,
    504.99,
    509.99,
    514.99,
    519.99,
    524.99,
    529.99,
    534.99,
    539.99,
    544.99,
    549.99,
    554.99,
    559.99,
    564.99,
    569.99,
    574.99,
    579.99,
    584.99,
    589.99,
    594.99,
    599.99,
    629.99,
    649.99,
    679.99,
    699.99,
    729.99,
    749.99,
    779.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    929.99,
    949.99,
    979.99,
    999.99,
    1049.99,
    1099.99,
    1149.99,
    1199.99,
    1249.99,
    1299.99,
    1349.99,
    1399.99,
    1449.99,
    1499.99,
    1549.99,
    1599.99,
    1649.99,
    1699.99,
    1749.99,
    1799.99,
    1849.99,
    1899.99,
    1949.99,
    1999.99,
    2299.99,
    2499.99,
    2799.99,
    2999.99,
    3499.99,
    3999.99,
    4499.99,
    4999.99,
    5499.99,
    5999.99,
    6999.99,
    7999.99,
    8999.99
  ],
  HUF: [
    99,
    149,
    199,
    249,
    299,
    349,
    399,
    499,
    599,
    699,
    799,
    899,
    999,
    1090,
    1190,
    1290,
    1390,
    1490,
    1590,
    1690,
    1790,
    1890,
    1990,
    2090,
    2190,
    2290,
    2390,
    2490,
    2590,
    2690,
    2790,
    2890,
    2990,
    3090,
    3190,
    3290,
    3390,
    3490,
    3590,
    3690,
    3790,
    3890,
    3990,
    4090,
    4190,
    4290,
    4390,
    4490,
    4590,
    4690,
    4790,
    4890,
    4990,
    5190,
    5290,
    5390,
    5490,
    5590,
    5690,
    5790,
    5890,
    5990,
    6290,
    6490,
    6790,
    6990,
    7290,
    7490,
    7790,
    7990,
    8290,
    8490,
    8790,
    8990,
    9290,
    9490,
    9790,
    9990,
    10290,
    10490,
    10790,
    10990,
    11290,
    11490,
    11790,
    11990,
    12290,
    12490,
    12790,
    12990,
    13290,
    13490,
    13790,
    13990,
    14290,
    14490,
    14790,
    14990,
    15290,
    15490,
    15790,
    15990,
    16290,
    16490,
    16790,
    16990,
    17290,
    17490,
    17790,
    17990,
    18290,
    18490,
    18790,
    18990,
    19290,
    19490,
    19790,
    19990,
    20290,
    20490,
    20790,
    20990,
    21290,
    21490,
    21790,
    21990,
    22290,
    22490,
    22790,
    22990,
    23290,
    23490,
    23790,
    23990,
    24290,
    24490,
    24790,
    24990,
    25990,
    26990,
    27990,
    28990,
    29990,
    30990,
    31990,
    32990,
    33990,
    34990,
    35990,
    36990,
    37990,
    38990,
    39990,
    40990,
    41990,
    42990,
    43990,
    44990,
    45990,
    46990,
    47990,
    48990,
    49990,
    50990,
    51990,
    52990,
    53990,
    54990,
    55990,
    56990,
    57990,
    58990,
    59990,
    62990,
    64990,
    67990,
    69990,
    72990,
    74990,
    77990,
    79990,
    82990,
    84990,
    87990,
    89990,
    92990,
    94990,
    97990,
    99990,
    129990,
    149990,
    169990,
    179990,
    199990,
    229990,
    249990,
    279990,
    299990,
    329990,
    349990
  ],
  IDR: [
    3000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    11000,
    12000,
    13000,
    14000,
    15000,
    16000,
    17000,
    18000,
    19000,
    25000,
    29000,
    35000,
    39000,
    45000,
    49000,
    55000,
    59000,
    65000,
    69000,
    75000,
    79000,
    85000,
    89000,
    95000,
    99000,
    109000,
    119000,
    129000,
    139000,
    149000,
    159000,
    169000,
    179000,
    189000,
    199000,
    209000,
    219000,
    229000,
    239000,
    249000,
    259000,
    269000,
    279000,
    289000,
    299000,
    309000,
    319000,
    329000,
    339000,
    349000,
    359000,
    369000,
    379000,
    389000,
    399000,
    409000,
    419000,
    429000,
    439000,
    449000,
    459000,
    469000,
    479000,
    489000,
    499000,
    509000,
    519000,
    529000,
    539000,
    549000,
    559000,
    569000,
    579000,
    589000,
    599000,
    609000,
    619000,
    629000,
    639000,
    649000,
    659000,
    669000,
    679000,
    689000,
    699000,
    709000,
    719000,
    729000,
    739000,
    749000,
    759000,
    769000,
    779000,
    789000,
    799000,
    809000,
    819000,
    829000,
    839000,
    849000,
    859000,
    869000,
    879000,
    889000,
    899000,
    909000,
    919000,
    929000,
    939000,
    949000,
    959000,
    969000,
    979000,
    989000,
    999000,
    1009000,
    1019000,
    1029000,
    1039000,
    1049000,
    1059000,
    1069000,
    1079000,
    1089000,
    1099000,
    1109000,
    1119000,
    1129000,
    1139000,
    1149000,
    1159000,
    1169000,
    1179000,
    1189000,
    1199000,
    1249000,
    1299000,
    1349000,
    1399000,
    1449000,
    1499000,
    1549000,
    1599000,
    1649000,
    1699000,
    1749000,
    1799000,
    1849000,
    1899000,
    1949000,
    1999000,
    2099000,
    2199000,
    2299000,
    2399000,
    2499000,
    2599000,
    2699000,
    2799000,
    2899000,
    2999000,
    3099000,
    3199000,
    3299000,
    3399000,
    3499000,
    3599000,
    3699000,
    3799000,
    3899000,
    3999000,
    4099000,
    4199000,
    4299000,
    4399000,
    4499000,
    4599000,
    4999000,
    5499000,
    5899000,
    5999000,
    6599000,
    6999000,
    7399000,
    7999000,
    8499000,
    8999000,
    9999000,
    10999000,
    11999000,
    12999000,
    13999000,
    14999000
  ],
  ILS: [
    1.9,
    3.9,
    5.9,
    7.9,
    9.9,
    11.9,
    13.9,
    15.9,
    17.9,
    19.9,
    21.9,
    23.9,
    25.9,
    27.9,
    29.9,
    31.9,
    33.9,
    35.9,
    37.9,
    39.9,
    41.9,
    43.9,
    45.9,
    47.9,
    49.9,
    51.9,
    53.9,
    55.9,
    57.9,
    59.9,
    61.9,
    63.9,
    65.9,
    67.9,
    69.9,
    71.9,
    73.9,
    75.9,
    77.9,
    79.9,
    81.9,
    83.9,
    85.9,
    87.9,
    89.9,
    91.9,
    93.9,
    95.9,
    97.9,
    99.9,
    101.9,
    103.9,
    105.9,
    107.9,
    109.9,
    111.9,
    113.9,
    115.9,
    117.9,
    119.9,
    121.9,
    123.9,
    125.9,
    127.9,
    129.9,
    131.9,
    133.9,
    135.9,
    137.9,
    139.9,
    141.9,
    143.9,
    145.9,
    147.9,
    149.9,
    151.9,
    153.9,
    155.9,
    157.9,
    159.9,
    161.9,
    163.9,
    165.9,
    167.9,
    169.9,
    171.9,
    173.9,
    175.9,
    177.9,
    179.9,
    181.9,
    183.9,
    185.9,
    187.9,
    189.9,
    191.9,
    193.9,
    195.9,
    197.9,
    199.9,
    204.9,
    209.9,
    214.9,
    219.9,
    224.9,
    229.9,
    234.9,
    239.9,
    244.9,
    249.9,
    254.9,
    259.9,
    264.9,
    269.9,
    274.9,
    279.9,
    284.9,
    289.9,
    294.9,
    299.9,
    304.9,
    309.9,
    314.9,
    319.9,
    324.9,
    329.9,
    334.9,
    339.9,
    344.9,
    349.9,
    354.9,
    359.9,
    364.9,
    369.9,
    374.9,
    379.9,
    384.9,
    389.9,
    394.9,
    399.9,
    404.9,
    409.9,
    414.9,
    419.9,
    424.9,
    429.9,
    434.9,
    439.9,
    444.9,
    449.9,
    454.9,
    459.9,
    464.9,
    469.9,
    474.9,
    479.9,
    484.9,
    489.9,
    494.9,
    499.9,
    519.9,
    539.9,
    559.9,
    579.9,
    599.9,
    619.9,
    639.9,
    659.9,
    679.9,
    699.9,
    719.9,
    739.9,
    759.9,
    779.9,
    799.9,
    819.9,
    839.9,
    859.9,
    879.9,
    899.9,
    919.9,
    939.9,
    959.9,
    979.9,
    999.9,
    1199.9,
    1399.9,
    1599.9,
    1799.9,
    1999.9,
    2199.9,
    2399.9,
    2599.9,
    2799.9,
    2999.9,
    3199.9,
    3399.9,
    3599.9,
    3799.9,
    3999.9
  ],
  INR: [
    9,
    19,
    29,
    39,
    49,
    59,
    69,
    79,
    89,
    99,
    109,
    119,
    129,
    139,
    149,
    159,
    169,
    179,
    189,
    199,
    219,
    249,
    269,
    289,
    299,
    319,
    349,
    369,
    389,
    399,
    419,
    449,
    469,
    489,
    499,
    519,
    549,
    569,
    589,
    599,
    619,
    649,
    669,
    689,
    699,
    719,
    749,
    769,
    789,
    799,
    819,
    849,
    869,
    889,
    899,
    919,
    949,
    969,
    989,
    999,
    1049,
    1099,
    1149,
    1199,
    1249,
    1299,
    1349,
    1399,
    1449,
    1499,
    1549,
    1599,
    1649,
    1699,
    1749,
    1799,
    1849,
    1899,
    1949,
    1999,
    2049,
    2099,
    2149,
    2199,
    2249,
    2299,
    2349,
    2399,
    2449,
    2499,
    2549,
    2599,
    2649,
    2699,
    2749,
    2799,
    2849,
    2899,
    2949,
    2999,
    3049,
    3099,
    3149,
    3199,
    3249,
    3299,
    3349,
    3399,
    3449,
    3499,
    3549,
    3599,
    3649,
    3699,
    3749,
    3799,
    3849,
    3899,
    3949,
    3999,
    4099,
    4199,
    4299,
    4399,
    4499,
    4599,
    4699,
    4799,
    4899,
    4999,
    5100,
    5200,
    5300,
    5400,
    5500,
    5600,
    5700,
    5800,
    5900,
    6000,
    6100,
    6200,
    6300,
    6400,
    6500,
    6600,
    6700,
    6800,
    6900,
    7000,
    7100,
    7200,
    7500,
    7700,
    7900,
    8200,
    8500,
    8700,
    8900,
    9200,
    9500,
    9700,
    9900,
    10500,
    10900,
    11500,
    11900,
    12500,
    12900,
    13500,
    13900,
    14500,
    14900,
    15500,
    15900,
    16900,
    17900,
    18500,
    18900,
    19900,
    20900,
    21500,
    21900,
    22900,
    23900,
    24900,
    27900,
    29900,
    30900,
    32900,
    34900,
    37900,
    39900,
    44900,
    49900,
    54900,
    59900,
    61900,
    64900,
    69900
  ],
  JPY: [
    50,
    100,
    120,
    150,
    180,
    200,
    240,
    250,
    280,
    300,
    350,
    360,
    380,
    400,
    450,
    480,
    500,
    550,
    580,
    600,
    650,
    680,
    700,
    720,
    750,
    780,
    800,
    840,
    850,
    880,
    900,
    950,
    960,
    980,
    1000,
    1050,
    1080,
    1100,
    1150,
    1180,
    1200,
    1250,
    1280,
    1300,
    1350,
    1380,
    1400,
    1450,
    1480,
    1500,
    1550,
    1600,
    1650,
    1700,
    1750,
    1800,
    1850,
    1900,
    1950,
    2000,
    2100,
    2200,
    2300,
    2400,
    2500,
    2600,
    2700,
    2800,
    2900,
    3000,
    3100,
    3200,
    3300,
    3400,
    3500,
    3600,
    3700,
    3800,
    3900,
    4000,
    4100,
    4200,
    4300,
    4400,
    4500,
    4600,
    4700,
    4800,
    4900,
    5000,
    5100,
    5200,
    5300,
    5400,
    5500,
    5600,
    5700,
    5800,
    5900,
    6000,
    6100,
    6200,
    6300,
    6400,
    6500,
    6600,
    6700,
    6800,
    6900,
    7000,
    7100,
    7200,
    7300,
    7400,
    7500,
    7600,
    7700,
    7800,
    7900,
    8000,
    8100,
    8200,
    8300,
    8400,
    8500,
    8600,
    8700,
    8800,
    8900,
    9000,
    9100,
    9200,
    9300,
    9400,
    9500,
    9600,
    9700,
    9800,
    9900,
    10000,
    10200,
    10400,
    10600,
    10800,
    11000,
    11200,
    11400,
    11600,
    11800,
    12000,
    12200,
    12400,
    12600,
    12800,
    13000,
    13200,
    13400,
    13600,
    13800,
    14000,
    14400,
    14800,
    15400,
    15800,
    16400,
    16800,
    17400,
    17800,
    18400,
    18800,
    19400,
    19800,
    20800,
    21800,
    22800,
    23800,
    24800,
    25800,
    26800,
    27800,
    28800,
    29800,
    34800,
    38800,
    42800,
    44800,
    48800,
    54800,
    58800,
    64800,
    68800,
    74800,
    78800,
    84800,
    88800,
    94800,
    98800,
    104800,
    108800,
    118800
  ],
  KRW: [
    500,
    900,
    1000,
    1200,
    1500,
    2000,
    2500,
    2900,
    3000,
    3500,
    3900,
    4000,
    4500,
    4900,
    5000,
    5500,
    5900,
    6000,
    6500,
    6900,
    7000,
    7500,
    7900,
    8000,
    8500,
    8900,
    9000,
    9500,
    9900,
    10000,
    10500,
    11000,
    11500,
    12000,
    12500,
    13000,
    13500,
    14000,
    14500,
    15000,
    15500,
    16000,
    16500,
    17000,
    17500,
    18000,
    18500,
    19000,
    19500,
    20000,
    20500,
    21000,
    21500,
    22000,
    22500,
    23000,
    23500,
    24000,
    24500,
    25000,
    26000,
    27000,
    28000,
    29000,
    30000,
    31000,
    32000,
    33000,
    34000,
    35000,
    36000,
    37000,
    38000,
    39000,
    40000,
    41000,
    42000,
    43000,
    44000,
    45000,
    46000,
    47000,
    48000,
    49000,
    50000,
    51000,
    52000,
    53000,
    54000,
    55000,
    56000,
    57000,
    58000,
    59000,
    60000,
    61000,
    62000,
    63000,
    64000,
    65000,
    66000,
    67000,
    68000,
    69000,
    70000,
    71000,
    72000,
    73000,
    74000,
    75000,
    76000,
    77000,
    78000,
    79000,
    80000,
    81000,
    82000,
    83000,
    84000,
    85000,
    86000,
    87000,
    88000,
    89000,
    90000,
    91000,
    92000,
    93000,
    94000,
    95000,
    96000,
    97000,
    98000,
    99000,
    105000,
    109000,
    115000,
    119000,
    125000,
    129000,
    135000,
    139000,
    145000,
    149000,
    155000,
    159000,
    165000,
    169000,
    175000,
    179000,
    185000,
    189000,
    195000,
    199000,
    205000,
    209000,
    215000,
    219000,
    225000,
    229000,
    235000,
    239000,
    245000,
    249000,
    255000,
    259000,
    265000,
    269000,
    275000,
    279000,
    285000,
    289000,
    295000,
    299000,
    309000,
    319000,
    329000,
    339000,
    349000,
    359000,
    369000,
    379000,
    389000,
    399000,
    450000,
    490000,
    550000,
    590000,
    650000,
    690000,
    750000,
    790000,
    850000,
    890000,
    950000,
    990000,
    1050000,
    1090000,
    1190000,
    1290000
  ],
  KZT: [
    69,
    99,
    129,
    149,
    179,
    199,
    229,
    249,
    279,
    299,
    329,
    349,
    379,
    399,
    429,
    449,
    479,
    499,
    549,
    599,
    649,
    699,
    749,
    799,
    849,
    899,
    949,
    999,
    1050,
    1090,
    1150,
    1190,
    1250,
    1290,
    1350,
    1390,
    1450,
    1490,
    1550,
    1590,
    1650,
    1690,
    1750,
    1790,
    1850,
    1890,
    1950,
    1990,
    2090,
    2190,
    2290,
    2390,
    2490,
    2590,
    2690,
    2790,
    2890,
    2990,
    3090,
    3190,
    3290,
    3390,
    3490,
    3590,
    3690,
    3790,
    3890,
    3990,
    4090,
    4190,
    4290,
    4390,
    4490,
    4590,
    4690,
    4790,
    4890,
    4990,
    5290,
    5490,
    5790,
    5990,
    6290,
    6490,
    6790,
    6990,
    7290,
    7490,
    7790,
    7990,
    8290,
    8490,
    8790,
    8990,
    9290,
    9490,
    9790,
    9990,
    10290,
    10490,
    10790,
    10990,
    11290,
    11490,
    11790,
    11990,
    12290,
    12490,
    12790,
    12990,
    13290,
    13490,
    13790,
    13990,
    14290,
    14490,
    14790,
    14990,
    15290,
    15490,
    15790,
    15990,
    16290,
    16490,
    16790,
    16990,
    17290,
    17490,
    17790,
    17990,
    18290,
    18490,
    18790,
    18990,
    19290,
    19490,
    19790,
    19990,
    20990,
    21990,
    22990,
    23990,
    24990,
    25990,
    26990,
    27990,
    28990,
    29990,
    30990,
    31990,
    32990,
    33990,
    34990,
    35990,
    36990,
    37990,
    38990,
    39990,
    40990,
    41990,
    42990,
    43990,
    44990,
    45990,
    46990,
    47990,
    48990,
    49990,
    52990,
    54990,
    57990,
    59990,
    62990,
    64990,
    67990,
    69990,
    72990,
    74990,
    77990,
    79990,
    82990,
    84990,
    87990,
    89990,
    92990,
    94990,
    97990,
    99990,
    119990,
    139990,
    149990,
    169990,
    179990,
    199990,
    229990,
    249990,
    279990,
    299990,
    329990,
    349990
  ],
  MXN: [
    5,
    9,
    15,
    17,
    19,
    25,
    29,
    35,
    39,
    45,
    49,
    55,
    59,
    65,
    69,
    75,
    79,
    85,
    89,
    95,
    99,
    105,
    109,
    115,
    119,
    125,
    129,
    135,
    139,
    145,
    149,
    155,
    159,
    165,
    169,
    175,
    179,
    185,
    189,
    195,
    199,
    209,
    219,
    229,
    239,
    249,
    259,
    269,
    279,
    289,
    299,
    309,
    319,
    329,
    339,
    349,
    359,
    369,
    379,
    389,
    399,
    409,
    419,
    429,
    439,
    449,
    459,
    469,
    479,
    489,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    699,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    799,
    809,
    819,
    829,
    839,
    849,
    859,
    869,
    879,
    889,
    899,
    909,
    919,
    929,
    939,
    949,
    959,
    969,
    979,
    989,
    999,
    1009,
    1019,
    1029,
    1039,
    1049,
    1059,
    1069,
    1079,
    1089,
    1099,
    1109,
    1119,
    1129,
    1139,
    1149,
    1159,
    1169,
    1179,
    1189,
    1199,
    1209,
    1219,
    1229,
    1239,
    1249,
    1259,
    1269,
    1279,
    1289,
    1299,
    1349,
    1399,
    1449,
    1499,
    1549,
    1599,
    1649,
    1699,
    1749,
    1799,
    1849,
    1899,
    1949,
    1999,
    2099,
    2199,
    2299,
    2399,
    2499,
    2599,
    2699,
    2799,
    2899,
    2999,
    3099,
    3199,
    3299,
    3399,
    3499,
    3699,
    3799,
    3999,
    4199,
    4499,
    4999,
    5999,
    6999,
    7999,
    8999,
    9999,
    10999,
    11999,
    12999,
    13999,
    14999,
    15999,
    16999,
    17999,
    18999
  ],
  MYR: [
    0.9,
    1.9,
    2.9,
    3.9,
    4.9,
    5.9,
    6.9,
    7.9,
    8.9,
    9.9,
    10.9,
    11.9,
    12.9,
    13.9,
    14.9,
    15.9,
    16.9,
    17.9,
    18.9,
    19.9,
    20.9,
    21.9,
    22.9,
    23.9,
    24.9,
    25.9,
    26.9,
    27.9,
    28.9,
    29.9,
    30.9,
    31.9,
    32.9,
    33.9,
    34.9,
    35.9,
    36.9,
    37.9,
    38.9,
    39.9,
    40.9,
    41.9,
    42.9,
    43.9,
    44.9,
    45.9,
    46.9,
    47.9,
    48.9,
    49.9,
    52.9,
    54.9,
    57.9,
    59.9,
    62.9,
    64.9,
    67.9,
    69.9,
    72.9,
    74.9,
    77.9,
    79.9,
    82.9,
    84.9,
    87.9,
    89.9,
    92.9,
    94.9,
    97.9,
    99.9,
    102.9,
    104.9,
    107.9,
    109.9,
    112.9,
    114.9,
    117.9,
    119.9,
    122.9,
    124.9,
    127.9,
    129.9,
    132.9,
    134.9,
    137.9,
    139.9,
    142.9,
    144.9,
    147.9,
    149.9,
    152.9,
    154.9,
    157.9,
    159.9,
    162.9,
    164.9,
    167.9,
    169.9,
    172.9,
    174.9,
    177.9,
    179.9,
    182.9,
    184.9,
    187.9,
    189.9,
    192.9,
    194.9,
    197.9,
    199.9,
    204.9,
    209.9,
    214.9,
    219.9,
    224.9,
    229.9,
    234.9,
    239.9,
    244.9,
    249.9,
    254.9,
    259.9,
    264.9,
    269.9,
    274.9,
    279.9,
    284.9,
    289.9,
    294.9,
    299.9,
    309.9,
    319.9,
    329.9,
    339.9,
    349.9,
    359.9,
    369.9,
    379.9,
    389.9,
    399.9,
    409.9,
    419.9,
    429.9,
    439.9,
    449.9,
    459.9,
    469.9,
    479.9,
    489.9,
    499.9,
    509.9,
    519.9,
    529.9,
    539.9,
    549.9,
    579.9,
    599.9,
    629.9,
    649.9,
    679.9,
    699.9,
    729.9,
    749.9,
    779.9,
    799.9,
    829.9,
    849.9,
    879.9,
    899.9,
    929.9,
    949.9,
    979.9,
    999.9,
    1029.9,
    1049.9,
    1079.9,
    1099.9,
    1129.9,
    1149.9,
    1179.9,
    1199.9,
    1229.9,
    1249.9,
    1279.9,
    1299.9,
    1399.9,
    1499.9,
    1599.9,
    1699.9,
    1799.9,
    1899.9,
    1999.9,
    2299.9,
    2499.9,
    2799.9,
    2999.9,
    3299.9,
    3499.9,
    3799.9,
    3999.9
  ],
  NGN: [
    100,
    150,
    200,
    250,
    300,
    350,
    400,
    450,
    500,
    550,
    600,
    650,
    700,
    750,
    800,
    850,
    900,
    950,
    1000,
    1100,
    1200,
    1300,
    1400,
    1500,
    1600,
    1700,
    1800,
    1900,
    2000,
    2100,
    2200,
    2300,
    2400,
    2500,
    2600,
    2700,
    2800,
    2900,
    3000,
    3100,
    3200,
    3300,
    3400,
    3500,
    3600,
    3700,
    3800,
    3900,
    4000,
    4100,
    4200,
    4300,
    4400,
    4500,
    4600,
    4700,
    4800,
    4900,
    5000,
    5100,
    5200,
    5300,
    5400,
    5500,
    5600,
    5700,
    5800,
    5900,
    6000,
    6100,
    6200,
    6300,
    6400,
    6500,
    6600,
    6700,
    6800,
    6900,
    7000,
    7100,
    7200,
    7300,
    7400,
    7500,
    7600,
    7700,
    7800,
    7900,
    8200,
    8500,
    8700,
    8900,
    9200,
    9500,
    9700,
    9900,
    10200,
    10500,
    10700,
    10900,
    11200,
    11500,
    11700,
    11900,
    12200,
    12500,
    12700,
    12900,
    13200,
    13500,
    13700,
    13900,
    14200,
    14500,
    14700,
    14900,
    15200,
    15500,
    15700,
    15900,
    16200,
    16500,
    16700,
    16900,
    17200,
    17500,
    17700,
    17900,
    18200,
    18500,
    18700,
    18900,
    19200,
    19500,
    19700,
    19900,
    20200,
    20500,
    20700,
    20900,
    21200,
    21500,
    21700,
    21900,
    22900,
    23900,
    24900,
    25900,
    26900,
    27900,
    28900,
    29900,
    30900,
    31900,
    32900,
    33900,
    34900,
    35900,
    36900,
    37900,
    38900,
    39900,
    40900,
    41900,
    42900,
    43900,
    44900,
    45900,
    46900,
    47900,
    48900,
    49900,
    52900,
    54900,
    57900,
    59900,
    62900,
    64900,
    67900,
    69900,
    72900,
    74900,
    77900,
    79900,
    82900,
    84900,
    87900,
    89900,
    92900,
    94900,
    99900,
    109900,
    129900,
    139900,
    149900,
    199900,
    229900,
    249900,
    279900,
    299900
  ],
  NOK: [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    22,
    25,
    29,
    33,
    35,
    39,
    44,
    45,
    49,
    55,
    59,
    65,
    69,
    75,
    79,
    85,
    89,
    95,
    99,
    105,
    109,
    115,
    119,
    125,
    129,
    135,
    139,
    145,
    149,
    155,
    159,
    165,
    169,
    175,
    179,
    185,
    189,
    195,
    199,
    205,
    209,
    215,
    219,
    225,
    229,
    235,
    239,
    245,
    249,
    255,
    259,
    265,
    269,
    275,
    279,
    285,
    289,
    295,
    299,
    305,
    309,
    315,
    319,
    325,
    329,
    335,
    339,
    345,
    349,
    355,
    359,
    365,
    369,
    375,
    379,
    385,
    389,
    395,
    399,
    405,
    409,
    415,
    419,
    425,
    429,
    435,
    439,
    445,
    449,
    455,
    459,
    465,
    469,
    475,
    479,
    485,
    489,
    495,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    699,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    799,
    829,
    849,
    879,
    899,
    929,
    949,
    979,
    999,
    1050,
    1090,
    1150,
    1190,
    1250,
    1290,
    1350,
    1390,
    1450,
    1490,
    1550,
    1590,
    1650,
    1690,
    1750,
    1790,
    1850,
    1890,
    1950,
    1990,
    2050,
    2090,
    2150,
    2190,
    2250,
    2290,
    2350,
    2390,
    2450,
    2490,
    2590,
    2690,
    2790,
    2990,
    3290,
    3490,
    3790,
    3990,
    4290,
    4490,
    4790,
    5490,
    6490,
    7490,
    8490,
    9490,
    10990
  ],
  NZD: [
    0.49,
    0.99,
    1.49,
    1.69,
    1.99,
    2.49,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    209.99,
    219.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    399.99,
    449.99,
    499.99,
    549.99,
    599.99,
    649.99,
    699.99,
    749.99,
    799.99,
    899.99,
    999.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99
  ],
  PEN: [
    0.9,
    1.5,
    1.9,
    2.5,
    2.9,
    3.5,
    3.9,
    4.5,
    4.9,
    5.5,
    5.9,
    6.5,
    6.9,
    7.5,
    7.9,
    8.5,
    8.9,
    9.5,
    9.9,
    10.9,
    11.9,
    12.9,
    13.9,
    14.9,
    15.9,
    16.9,
    17.9,
    18.9,
    19.9,
    20.9,
    21.9,
    22.9,
    23.9,
    24.9,
    25.9,
    26.9,
    27.9,
    28.9,
    29.9,
    30.9,
    31.9,
    32.9,
    33.9,
    34.9,
    35.9,
    36.9,
    37.9,
    38.9,
    39.9,
    40.9,
    41.9,
    42.9,
    43.9,
    44.9,
    45.9,
    46.9,
    47.9,
    48.9,
    49.9,
    50.9,
    51.9,
    52.9,
    53.9,
    54.9,
    55.9,
    56.9,
    57.9,
    58.9,
    59.9,
    60.9,
    61.9,
    62.9,
    63.9,
    64.9,
    65.9,
    66.9,
    67.9,
    68.9,
    69.9,
    70.9,
    71.9,
    72.9,
    73.9,
    74.9,
    75.9,
    76.9,
    77.9,
    78.9,
    79.9,
    82.9,
    84.9,
    87.9,
    89.9,
    92.9,
    94.9,
    97.9,
    99.9,
    102.9,
    104.9,
    107.9,
    109.9,
    112.9,
    114.9,
    117.9,
    119.9,
    122.9,
    124.9,
    127.9,
    129.9,
    132.9,
    134.9,
    137.9,
    139.9,
    142.9,
    144.9,
    147.9,
    149.9,
    152.9,
    154.9,
    157.9,
    159.9,
    162.9,
    164.9,
    167.9,
    169.9,
    172.9,
    174.9,
    177.9,
    179.9,
    182.9,
    184.9,
    187.9,
    189.9,
    192.9,
    194.9,
    197.9,
    199.9,
    204.9,
    209.9,
    214.9,
    219.9,
    224.9,
    229.9,
    234.9,
    239.9,
    244.9,
    249.9,
    254.9,
    259.9,
    264.9,
    269.9,
    274.9,
    279.9,
    284.9,
    289.9,
    294.9,
    299.9,
    309.9,
    319.9,
    329.9,
    339.9,
    349.9,
    359.9,
    369.9,
    379.9,
    389.9,
    399.9,
    409.9,
    419.9,
    429.9,
    439.9,
    449.9,
    459.9,
    469.9,
    479.9,
    489.9,
    499.9,
    529.9,
    549.9,
    579.9,
    599.9,
    629.9,
    649.9,
    679.9,
    699.9,
    749.9,
    799.9,
    849.9,
    899.9,
    999.9,
    1099.9,
    1299.9,
    1499.9,
    1699.9,
    1999.9,
    2299.9,
    2499.9,
    2799.9,
    2999.9,
    3299.9
  ],
  PHP: [
    9,
    15,
    19,
    25,
    29,
    35,
    39,
    45,
    49,
    55,
    59,
    65,
    69,
    75,
    79,
    85,
    89,
    95,
    99,
    105,
    109,
    115,
    119,
    125,
    129,
    135,
    139,
    145,
    149,
    155,
    159,
    165,
    169,
    175,
    179,
    185,
    189,
    195,
    199,
    209,
    219,
    229,
    239,
    249,
    259,
    269,
    279,
    289,
    299,
    309,
    319,
    329,
    339,
    349,
    359,
    369,
    379,
    389,
    399,
    409,
    419,
    429,
    439,
    449,
    459,
    469,
    479,
    489,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    629,
    649,
    679,
    699,
    729,
    749,
    779,
    799,
    829,
    849,
    879,
    899,
    929,
    949,
    979,
    999,
    1050,
    1090,
    1150,
    1190,
    1250,
    1290,
    1350,
    1390,
    1450,
    1490,
    1550,
    1590,
    1650,
    1690,
    1750,
    1790,
    1850,
    1890,
    1950,
    1990,
    2050,
    2090,
    2150,
    2190,
    2250,
    2290,
    2350,
    2390,
    2450,
    2490,
    2550,
    2590,
    2650,
    2690,
    2750,
    2790,
    2850,
    2890,
    2950,
    2990,
    3090,
    3190,
    3290,
    3390,
    3490,
    3590,
    3690,
    3790,
    3890,
    3990,
    4090,
    4190,
    4290,
    4390,
    4490,
    4590,
    4690,
    4790,
    4890,
    4990,
    5090,
    5190,
    5290,
    5390,
    5490,
    5590,
    5690,
    5790,
    5890,
    5990,
    6090,
    6190,
    6290,
    6390,
    6490,
    6790,
    6990,
    7290,
    7490,
    7790,
    7990,
    8290,
    8490,
    8790,
    8990,
    9290,
    9490,
    9790,
    9990,
    10490,
    10990,
    11490,
    11990,
    12490,
    12990,
    14990,
    17490,
    19990,
    22490,
    24990,
    29990,
    34990,
    39990,
    44990,
    49990
  ],
  PKR: [
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
    220,
    250,
    270,
    290,
    300,
    320,
    350,
    370,
    390,
    400,
    420,
    450,
    470,
    490,
    500,
    520,
    550,
    570,
    590,
    600,
    620,
    650,
    670,
    690,
    700,
    720,
    750,
    770,
    790,
    800,
    820,
    850,
    870,
    890,
    900,
    920,
    950,
    970,
    990,
    1000,
    1050,
    1100,
    1150,
    1200,
    1250,
    1300,
    1350,
    1400,
    1450,
    1500,
    1550,
    1600,
    1650,
    1700,
    1750,
    1800,
    1850,
    1900,
    1950,
    2000,
    2050,
    2100,
    2150,
    2200,
    2250,
    2300,
    2350,
    2400,
    2450,
    2500,
    2550,
    2600,
    2650,
    2700,
    2750,
    2800,
    2850,
    2900,
    2950,
    3000,
    3050,
    3100,
    3150,
    3200,
    3250,
    3300,
    3350,
    3400,
    3450,
    3500,
    3550,
    3600,
    3650,
    3700,
    3750,
    3800,
    3850,
    3900,
    3950,
    4000,
    4100,
    4200,
    4300,
    4400,
    4500,
    4600,
    4700,
    4800,
    4900,
    5000,
    5100,
    5200,
    5300,
    5400,
    5500,
    5600,
    5700,
    5800,
    5900,
    6000,
    6100,
    6200,
    6300,
    6400,
    6500,
    6600,
    6700,
    6800,
    6900,
    7000,
    7100,
    7200,
    7300,
    7400,
    7500,
    7600,
    7700,
    7800,
    7900,
    8000,
    8100,
    8200,
    8300,
    8400,
    8500,
    8600,
    8700,
    8800,
    8900,
    9000,
    9100,
    9200,
    9500,
    9700,
    9900,
    10900,
    11900,
    12900,
    13900,
    14900,
    15900,
    16900,
    17900,
    18900,
    19900,
    20900,
    21900,
    22900,
    23900,
    24900,
    25900,
    26900,
    29900,
    34900,
    39900,
    44900,
    49900,
    59900,
    69900,
    79900,
    89900,
    99900
  ],
  PLN: [
    0.99,
    1.99,
    2.99,
    3.99,
    4.99,
    5.99,
    6.99,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    21.99,
    22.99,
    23.99,
    24.99,
    25.99,
    26.99,
    27.99,
    28.99,
    29.99,
    32.99,
    34.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    629.99,
    649.99,
    679.99,
    699.99,
    729.99,
    749.99,
    779.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    929.99,
    949.99,
    979.99,
    999.99,
    1029.99,
    1049.99,
    1079.99,
    1099.99,
    1129.99,
    1149.99,
    1179.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99,
    1699.99,
    1799.99,
    1899.99,
    1999.99,
    2299.99,
    2499.99,
    2799.99,
    2999.99,
    3299.99,
    3499.99,
    3799.99,
    3999.99,
    4299.99,
    4499.99,
    4799.99
  ],
  QAR: [
    1.99,
    2.99,
    3.69,
    3.99,
    4.99,
    5.99,
    6.99,
    7.29,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    36.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    394.99,
    399.99,
    404.99,
    409.99,
    414.99,
    419.99,
    424.99,
    429.99,
    434.99,
    439.99,
    444.99,
    449.99,
    454.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    629.99,
    649.99,
    679.99,
    699.99,
    729.99,
    749.99,
    779.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    929.99,
    949.99,
    979.99,
    999.99,
    1099.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99,
    1699.99,
    1799.99,
    1899.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99,
    2999.99,
    3299.99,
    3499.99,
    3699.99
  ],
  RON: [
    0.99,
    1.99,
    2.99,
    3.99,
    4.99,
    5.99,
    6.99,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    21.99,
    22.99,
    23.99,
    24.99,
    25.99,
    26.99,
    27.99,
    28.99,
    29.99,
    32.99,
    34.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    609.99,
    619.99,
    629.99,
    639.99,
    649.99,
    659.99,
    669.99,
    679.99,
    689.99,
    699.99,
    709.99,
    719.99,
    729.99,
    739.99,
    749.99,
    759.99,
    769.99,
    779.99,
    789.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    949.99,
    999.99,
    1049.99,
    1099.99,
    1149.99,
    1199.99,
    1249.99,
    1299.99,
    1399.99,
    1499.99,
    1799.99,
    1999.99,
    2299.99,
    2499.99,
    2999.99,
    3499.99,
    3999.99,
    4499.99,
    4999.99
  ],
  RUB: [
    15,
    29,
    49,
    59,
    69,
    75,
    99,
    129,
    149,
    169,
    199,
    229,
    249,
    269,
    299,
    329,
    349,
    379,
    399,
    429,
    449,
    459,
    499,
    529,
    549,
    599,
    649,
    699,
    749,
    799,
    849,
    899,
    949,
    999,
    1050,
    1090,
    1150,
    1190,
    1250,
    1290,
    1350,
    1390,
    1450,
    1490,
    1550,
    1590,
    1650,
    1690,
    1750,
    1790,
    1850,
    1890,
    1950,
    1990,
    2050,
    2090,
    2150,
    2190,
    2250,
    2290,
    2350,
    2390,
    2450,
    2490,
    2550,
    2590,
    2650,
    2690,
    2750,
    2790,
    2850,
    2890,
    2950,
    2990,
    3050,
    3090,
    3150,
    3190,
    3250,
    3290,
    3350,
    3390,
    3450,
    3490,
    3550,
    3590,
    3650,
    3690,
    3750,
    3790,
    3850,
    3890,
    3950,
    3990,
    4050,
    4090,
    4150,
    4190,
    4250,
    4290,
    4350,
    4390,
    4450,
    4490,
    4550,
    4590,
    4650,
    4690,
    4750,
    4790,
    4850,
    4890,
    4950,
    4990,
    5090,
    5190,
    5290,
    5390,
    5490,
    5590,
    5690,
    5790,
    5890,
    5990,
    6090,
    6190,
    6290,
    6390,
    6490,
    6590,
    6690,
    6790,
    6890,
    6990,
    7090,
    7190,
    7290,
    7390,
    7490,
    7590,
    7690,
    7790,
    7890,
    7990,
    8090,
    8190,
    8290,
    8390,
    8490,
    8590,
    8690,
    8790,
    8890,
    8990,
    9090,
    9190,
    9290,
    9390,
    9490,
    9590,
    9690,
    9790,
    9890,
    9990,
    10490,
    10990,
    11490,
    11990,
    12490,
    12990,
    13490,
    13990,
    14490,
    14990,
    15490,
    15990,
    16490,
    16990,
    17490,
    17990,
    18490,
    18990,
    19490,
    19990,
    22990,
    26990,
    29990,
    32990,
    34990,
    37990,
    39990,
    42990,
    44990,
    47990,
    49990,
    54990,
    59990,
    64990,
    69990,
    74990
  ],
  SAR: [
    1.99,
    2.99,
    3.69,
    3.99,
    4.99,
    5.99,
    6.99,
    7.29,
    7.99,
    8.99,
    9.99,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    36.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    52.99,
    54.99,
    57.99,
    59.99,
    62.99,
    64.99,
    67.99,
    69.99,
    72.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    157.99,
    159.99,
    162.99,
    164.99,
    167.99,
    169.99,
    172.99,
    174.99,
    177.99,
    179.99,
    182.99,
    184.99,
    187.99,
    189.99,
    192.99,
    194.99,
    197.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    304.99,
    309.99,
    314.99,
    319.99,
    324.99,
    329.99,
    334.99,
    339.99,
    344.99,
    349.99,
    354.99,
    359.99,
    364.99,
    369.99,
    374.99,
    379.99,
    384.99,
    389.99,
    394.99,
    399.99,
    404.99,
    409.99,
    414.99,
    419.99,
    424.99,
    429.99,
    434.99,
    439.99,
    444.99,
    449.99,
    454.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    629.99,
    649.99,
    679.99,
    699.99,
    729.99,
    749.99,
    779.99,
    799.99,
    829.99,
    849.99,
    879.99,
    899.99,
    929.99,
    949.99,
    979.99,
    999.99,
    1099.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99,
    1699.99,
    1799.99,
    1899.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99,
    2999.99,
    3299.99,
    3499.99,
    3699.99
  ],
  SEK: [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    25,
    29,
    30,
    35,
    39,
    40,
    45,
    49,
    50,
    55,
    59,
    65,
    69,
    75,
    79,
    85,
    89,
    95,
    99,
    105,
    109,
    115,
    119,
    125,
    129,
    135,
    139,
    145,
    149,
    155,
    159,
    165,
    169,
    175,
    179,
    185,
    189,
    195,
    199,
    205,
    209,
    215,
    219,
    225,
    229,
    235,
    239,
    245,
    249,
    255,
    259,
    265,
    269,
    275,
    279,
    285,
    289,
    295,
    299,
    305,
    309,
    315,
    319,
    325,
    329,
    335,
    339,
    345,
    349,
    355,
    359,
    365,
    369,
    375,
    379,
    385,
    389,
    395,
    399,
    409,
    419,
    429,
    439,
    449,
    459,
    469,
    479,
    489,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    595,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    695,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    795,
    809,
    819,
    829,
    839,
    849,
    859,
    869,
    879,
    895,
    929,
    949,
    979,
    995,
    1029,
    1049,
    1079,
    1095,
    1129,
    1149,
    1179,
    1195,
    1229,
    1249,
    1279,
    1295,
    1329,
    1349,
    1379,
    1395,
    1429,
    1449,
    1479,
    1495,
    1549,
    1595,
    1649,
    1695,
    1749,
    1795,
    1849,
    1895,
    1949,
    1995,
    2049,
    2095,
    2195,
    2295,
    2395,
    2495,
    2595,
    2995,
    3195,
    3495,
    3695,
    3995,
    4295,
    4495,
    4795,
    4995,
    5495,
    6495,
    7495,
    8495,
    9495,
    10995
  ],
  SGD: [
    0.48,
    0.98,
    1.48,
    1.98,
    2.48,
    2.98,
    3.48,
    3.98,
    4.48,
    4.98,
    5.48,
    5.98,
    6.48,
    6.98,
    7.48,
    7.98,
    8.48,
    8.98,
    9.48,
    9.98,
    10.48,
    10.98,
    11.48,
    11.98,
    12.48,
    12.98,
    13.48,
    13.98,
    14.48,
    14.98,
    15.48,
    15.98,
    16.48,
    16.98,
    17.48,
    17.98,
    18.48,
    18.98,
    19.48,
    19.98,
    20.98,
    21.98,
    22.98,
    23.98,
    24.98,
    25.98,
    26.98,
    27.98,
    28.98,
    29.98,
    30.98,
    31.98,
    32.98,
    33.98,
    34.98,
    35.98,
    36.98,
    37.98,
    38.98,
    39.98,
    40.98,
    41.98,
    42.98,
    43.98,
    44.98,
    45.98,
    46.98,
    47.98,
    48.98,
    49.98,
    50.98,
    51.98,
    52.98,
    53.98,
    54.98,
    55.98,
    56.98,
    57.98,
    58.98,
    59.98,
    60.98,
    61.98,
    62.98,
    63.98,
    64.98,
    65.98,
    66.98,
    67.98,
    68.98,
    69.98,
    70.98,
    71.98,
    72.98,
    73.98,
    74.98,
    75.98,
    76.98,
    77.98,
    78.98,
    79.98,
    80.98,
    81.98,
    82.98,
    83.98,
    84.98,
    85.98,
    86.98,
    87.98,
    88.98,
    89.98,
    90.98,
    91.98,
    92.98,
    93.98,
    94.98,
    95.98,
    96.98,
    97.98,
    98.98,
    99.98,
    100.98,
    101.98,
    102.98,
    103.98,
    104.98,
    105.98,
    106.98,
    107.98,
    108.98,
    109.98,
    110.98,
    111.98,
    112.98,
    113.98,
    114.98,
    115.98,
    116.98,
    117.98,
    118.98,
    119.98,
    120.98,
    121.98,
    122.98,
    123.98,
    124.98,
    125.98,
    126.98,
    127.98,
    128.98,
    129.98,
    134.98,
    138.98,
    144.98,
    148.98,
    154.98,
    158.98,
    164.98,
    168.98,
    174.98,
    178.98,
    184.98,
    188.98,
    194.98,
    198.98,
    208.98,
    218.98,
    228.98,
    238.98,
    248.98,
    258.98,
    268.98,
    278.98,
    288.98,
    298.98,
    308.98,
    318.98,
    328.98,
    338.98,
    348.98,
    358.98,
    368.98,
    378.98,
    388.98,
    398.98,
    448.98,
    488.98,
    548.98,
    588.98,
    648.98,
    688.98,
    748.98,
    788.98,
    848.98,
    888.98,
    988.98,
    1088.98,
    1188.98,
    1288.98,
    1388.98,
    1488.98
  ],
  THB: [
    9,
    15,
    19,
    25,
    29,
    35,
    39,
    45,
    49,
    59,
    69,
    79,
    89,
    99,
    109,
    119,
    129,
    139,
    149,
    159,
    169,
    179,
    189,
    199,
    209,
    219,
    229,
    239,
    249,
    259,
    269,
    279,
    289,
    299,
    309,
    319,
    329,
    339,
    349,
    359,
    369,
    379,
    389,
    399,
    409,
    419,
    429,
    439,
    449,
    459,
    469,
    479,
    489,
    499,
    509,
    519,
    529,
    539,
    549,
    559,
    569,
    579,
    589,
    599,
    609,
    619,
    629,
    639,
    649,
    659,
    669,
    679,
    689,
    699,
    709,
    719,
    729,
    739,
    749,
    759,
    769,
    779,
    789,
    799,
    809,
    819,
    829,
    839,
    849,
    859,
    869,
    879,
    889,
    899,
    909,
    919,
    929,
    939,
    949,
    959,
    969,
    979,
    989,
    1000,
    1020,
    1050,
    1100,
    1120,
    1150,
    1200,
    1220,
    1250,
    1300,
    1320,
    1350,
    1400,
    1420,
    1450,
    1500,
    1520,
    1550,
    1600,
    1620,
    1650,
    1700,
    1720,
    1750,
    1800,
    1820,
    1850,
    1900,
    1920,
    1950,
    2000,
    2100,
    2200,
    2300,
    2400,
    2500,
    2600,
    2700,
    2800,
    2900,
    3000,
    3100,
    3200,
    3300,
    3400,
    3500,
    3600,
    3700,
    3800,
    3900,
    4000,
    4100,
    4200,
    4300,
    4400,
    4500,
    4600,
    4700,
    4800,
    4900,
    5200,
    5500,
    5700,
    5900,
    6200,
    6500,
    6700,
    6900,
    7200,
    7500,
    7700,
    7900,
    8200,
    8500,
    8700,
    8900,
    9200,
    9500,
    9700,
    9900,
    10500,
    10900,
    11900,
    12500,
    12900,
    13900,
    14900,
    15900,
    16900,
    17900,
    18900,
    19900,
    20900,
    24900,
    27900,
    31900,
    34900
  ],
  TRY: [
    0.59,
    1.29,
    1.49,
    1.99,
    2.49,
    2.69,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.29,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.99,
    12.99,
    13.99,
    14.99,
    15.99,
    16.99,
    17.99,
    18.99,
    19.99,
    20.99,
    21.99,
    22.99,
    23.99,
    24.99,
    25.99,
    26.99,
    27.99,
    28.99,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    77.99,
    79.99,
    82.99,
    84.99,
    87.99,
    89.99,
    92.99,
    94.99,
    97.99,
    99.99,
    102.99,
    104.99,
    107.99,
    109.99,
    112.99,
    114.99,
    117.99,
    119.99,
    122.99,
    124.99,
    127.99,
    129.99,
    132.99,
    134.99,
    137.99,
    139.99,
    142.99,
    144.99,
    147.99,
    149.99,
    152.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    379.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    499.99,
    529.99,
    549.99,
    579.99,
    599.99,
    629.99,
    649.99,
    699.99,
    749.99,
    799.99,
    849.99,
    899.99,
    949.99,
    999.99,
    1099.99,
    1199.99,
    1299.99,
    1399.99,
    1499.99,
    1599.99,
    1699.99,
    1799.99,
    1899.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99
  ],
  TWD: [
    10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
    210,
    220,
    230,
    240,
    250,
    260,
    270,
    280,
    290,
    300,
    310,
    320,
    330,
    340,
    350,
    360,
    370,
    380,
    390,
    400,
    410,
    420,
    430,
    440,
    450,
    460,
    470,
    480,
    490,
    500,
    510,
    520,
    530,
    540,
    550,
    560,
    570,
    580,
    590,
    600,
    610,
    620,
    630,
    640,
    650,
    660,
    670,
    680,
    690,
    700,
    710,
    720,
    730,
    740,
    750,
    760,
    770,
    780,
    790,
    800,
    810,
    820,
    830,
    840,
    850,
    860,
    870,
    880,
    890,
    900,
    910,
    920,
    930,
    940,
    950,
    960,
    970,
    980,
    990,
    1000,
    1020,
    1050,
    1090,
    1120,
    1140,
    1170,
    1190,
    1230,
    1260,
    1290,
    1320,
    1350,
    1390,
    1420,
    1440,
    1470,
    1490,
    1530,
    1560,
    1590,
    1630,
    1660,
    1690,
    1730,
    1760,
    1790,
    1830,
    1860,
    1890,
    1930,
    1960,
    1990,
    2090,
    2190,
    2290,
    2390,
    2490,
    2590,
    2690,
    2790,
    2890,
    2990,
    3090,
    3190,
    3290,
    3390,
    3490,
    3590,
    3690,
    3790,
    3890,
    3990,
    4090,
    4190,
    4290,
    4390,
    4490,
    4590,
    4690,
    4790,
    4890,
    4990,
    5090,
    5190,
    5290,
    5390,
    5490,
    5590,
    5690,
    5990,
    6290,
    6590,
    6890,
    7190,
    7490,
    7790,
    8090,
    8390,
    8690,
    8990,
    9290,
    9590,
    9990,
    10900,
    11900,
    12900,
    13900,
    14900,
    15900,
    16900,
    17900,
    18900,
    19900,
    20900,
    21900,
    22900,
    23900,
    24900,
    26900,
    29900
  ],
  TZS: [
    500,
    700,
    900,
    1200,
    1500,
    1700,
    1900,
    2200,
    2500,
    2700,
    2900,
    3200,
    3500,
    3700,
    3900,
    4200,
    4500,
    4700,
    4900,
    5200,
    5500,
    5700,
    5900,
    6200,
    6500,
    6700,
    6900,
    7200,
    7500,
    7700,
    7900,
    8200,
    8500,
    8700,
    8900,
    9200,
    9500,
    9700,
    9900,
    10500,
    10900,
    11500,
    11900,
    12500,
    12900,
    13500,
    13900,
    14500,
    14900,
    15500,
    15900,
    16500,
    16900,
    17500,
    17900,
    18500,
    18900,
    19500,
    19900,
    20900,
    21900,
    22900,
    23900,
    24900,
    25900,
    26900,
    27900,
    28900,
    29900,
    30900,
    31900,
    32900,
    33900,
    34900,
    35900,
    36900,
    37900,
    38900,
    39900,
    40900,
    41900,
    42900,
    43900,
    44900,
    45900,
    46900,
    47900,
    48900,
    49900,
    50900,
    51900,
    52900,
    53900,
    54900,
    55900,
    56900,
    57900,
    58900,
    59900,
    60900,
    61900,
    62900,
    63900,
    64900,
    65900,
    66900,
    67900,
    68900,
    69900,
    70900,
    71900,
    72900,
    73900,
    74900,
    75900,
    76900,
    77900,
    78900,
    79900,
    82900,
    84900,
    87900,
    89900,
    92900,
    94900,
    96900,
    97900,
    99900,
    102900,
    104900,
    107900,
    109900,
    112900,
    114900,
    117900,
    119900,
    122900,
    124900,
    127900,
    129900,
    132900,
    134900,
    137900,
    139900,
    142900,
    144900,
    147900,
    149900,
    154900,
    159900,
    164900,
    169900,
    174900,
    179900,
    184900,
    189900,
    194900,
    199900,
    204900,
    209900,
    214900,
    219900,
    224900,
    229900,
    234900,
    239900,
    244900,
    249900,
    259900,
    269900,
    279900,
    289900,
    299900,
    309900,
    319900,
    329900,
    339900,
    349900,
    359900,
    369900,
    379900,
    389900,
    399900,
    429900,
    449900,
    459900,
    479900,
    499900,
    529900,
    549900,
    649900,
    749900,
    899900,
    999900,
    1099900,
    1299000,
    1499000,
    1799000,
    1999000,
    2199000
  ],
  USD: [
    0.49,
    0.99,
    1.49,
    1.99,
    2.49,
    2.99,
    3.49,
    3.99,
    4.49,
    4.99,
    5.49,
    5.99,
    6.49,
    6.99,
    7.49,
    7.99,
    8.49,
    8.99,
    9.49,
    9.99,
    10.49,
    10.99,
    11.49,
    11.99,
    12.49,
    12.99,
    13.49,
    13.99,
    14.49,
    14.99,
    15.49,
    15.99,
    16.49,
    16.99,
    17.49,
    17.99,
    18.49,
    18.99,
    19.49,
    19.99,
    20.49,
    20.99,
    21.49,
    21.99,
    22.49,
    22.99,
    23.49,
    23.99,
    24.49,
    24.99,
    25.49,
    25.99,
    26.49,
    26.99,
    27.49,
    27.99,
    28.49,
    28.99,
    29.49,
    29.99,
    30.99,
    31.99,
    32.99,
    33.99,
    34.99,
    35.99,
    36.99,
    37.99,
    38.99,
    39.99,
    40.99,
    41.99,
    42.99,
    43.99,
    44.99,
    45.99,
    46.99,
    47.99,
    48.99,
    49.99,
    50.99,
    51.99,
    52.99,
    53.99,
    54.99,
    55.99,
    56.99,
    57.99,
    58.99,
    59.99,
    60.99,
    61.99,
    62.99,
    63.99,
    64.99,
    65.99,
    66.99,
    67.99,
    68.99,
    69.99,
    70.99,
    71.99,
    72.99,
    73.99,
    74.99,
    75.99,
    76.99,
    77.99,
    78.99,
    79.99,
    80.99,
    81.99,
    82.99,
    83.99,
    84.99,
    85.99,
    86.99,
    87.99,
    88.99,
    89.99,
    90.99,
    91.99,
    92.99,
    93.99,
    94.99,
    95.99,
    96.99,
    97.99,
    98.99,
    99.99,
    100.99,
    101.99,
    102.99,
    103.99,
    104.99,
    105.99,
    106.99,
    107.99,
    108.99,
    109.99,
    110.99,
    111.99,
    112.99,
    113.99,
    114.99,
    115.99,
    116.99,
    117.99,
    118.99,
    119.99,
    120.99,
    121.99,
    122.99,
    123.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    204.99,
    209.99,
    214.99,
    219.99,
    224.99,
    229.99,
    234.99,
    239.99,
    244.99,
    249.99,
    254.99,
    259.99,
    264.99,
    269.99,
    274.99,
    279.99,
    284.99,
    289.99,
    294.99,
    299.99,
    329.99,
    349.99,
    399.99,
    449.99,
    499.99,
    599.99,
    699.99,
    799.99,
    899.99,
    999.99
  ],
  VND: [
    5000,
    7000,
    9000,
    12000,
    15000,
    17000,
    19000,
    22000,
    25000,
    27000,
    29000,
    32000,
    35000,
    37000,
    39000,
    42000,
    45000,
    47000,
    49000,
    52000,
    55000,
    57000,
    59000,
    62000,
    65000,
    67000,
    69000,
    72000,
    75000,
    77000,
    79000,
    82000,
    85000,
    87000,
    89000,
    92000,
    95000,
    97000,
    99000,
    109000,
    119000,
    129000,
    139000,
    149000,
    159000,
    169000,
    179000,
    189000,
    199000,
    209000,
    219000,
    229000,
    239000,
    249000,
    259000,
    269000,
    279000,
    289000,
    299000,
    309000,
    319000,
    329000,
    339000,
    349000,
    359000,
    369000,
    379000,
    389000,
    399000,
    409000,
    419000,
    429000,
    439000,
    449000,
    459000,
    469000,
    479000,
    489000,
    499000,
    509000,
    519000,
    529000,
    539000,
    549000,
    559000,
    569000,
    579000,
    589000,
    599000,
    609000,
    619000,
    629000,
    639000,
    649000,
    659000,
    669000,
    679000,
    689000,
    699000,
    709000,
    719000,
    729000,
    739000,
    749000,
    759000,
    769000,
    779000,
    789000,
    799000,
    809000,
    819000,
    829000,
    839000,
    849000,
    859000,
    869000,
    879000,
    889000,
    899000,
    909000,
    919000,
    929000,
    939000,
    949000,
    959000,
    969000,
    979000,
    989000,
    999000,
    1009000,
    1019000,
    1029000,
    1039000,
    1049000,
    1059000,
    1069000,
    1079000,
    1089000,
    1099000,
    1109000,
    1119000,
    1129000,
    1139000,
    1149000,
    1159000,
    1169000,
    1179000,
    1189000,
    1199000,
    1249000,
    1299000,
    1349000,
    1399000,
    1449000,
    1499000,
    1549000,
    1599000,
    1649000,
    1699000,
    1799000,
    1899000,
    1999000,
    2099000,
    2199000,
    2299000,
    2399000,
    2499000,
    2599000,
    2699000,
    2799000,
    2899000,
    2999000,
    3099000,
    3199000,
    3299000,
    3399000,
    3499000,
    3599000,
    3699000,
    3799000,
    3899000,
    3999000,
    4299000,
    4499000,
    4799000,
    4999000,
    5299000,
    5499000,
    5799000,
    5999000,
    6999000,
    7999000,
    8999000,
    9999000,
    10999000,
    12999000,
    14999000,
    17999000,
    19999000,
    21999000
  ],
  ZAR: [
    3.99,
    6.99,
    9.99,
    12.99,
    14.99,
    17.99,
    18.99,
    19.99,
    22.99,
    24.99,
    27.99,
    29.99,
    32.99,
    34.99,
    37.99,
    39.99,
    42.99,
    44.99,
    47.99,
    49.99,
    54.99,
    59.99,
    64.99,
    69.99,
    74.99,
    79.99,
    84.99,
    89.99,
    94.99,
    99.99,
    104.99,
    109.99,
    114.99,
    119.99,
    124.99,
    129.99,
    134.99,
    139.99,
    144.99,
    149.99,
    154.99,
    159.99,
    164.99,
    169.99,
    174.99,
    179.99,
    184.99,
    189.99,
    194.99,
    199.99,
    209.99,
    219.99,
    229.99,
    239.99,
    249.99,
    259.99,
    269.99,
    279.99,
    289.99,
    299.99,
    309.99,
    319.99,
    329.99,
    339.99,
    349.99,
    359.99,
    369.99,
    379.99,
    389.99,
    399.99,
    409.99,
    419.99,
    429.99,
    439.99,
    449.99,
    459.99,
    469.99,
    479.99,
    489.99,
    499.99,
    509.99,
    519.99,
    529.99,
    539.99,
    549.99,
    559.99,
    569.99,
    579.99,
    589.99,
    599.99,
    609.99,
    619.99,
    629.99,
    639.99,
    649.99,
    659.99,
    669.99,
    679.99,
    689.99,
    699.99,
    709.99,
    719.99,
    729.99,
    739.99,
    749.99,
    759.99,
    769.99,
    779.99,
    789.99,
    799.99,
    809.99,
    819.99,
    829.99,
    839.99,
    849.99,
    859.99,
    869.99,
    879.99,
    889.99,
    899.99,
    909.99,
    919.99,
    929.99,
    939.99,
    949.99,
    959.99,
    969.99,
    979.99,
    989.99,
    999.99,
    1049.99,
    1099.99,
    1149.99,
    1199.99,
    1249.99,
    1299.99,
    1349.99,
    1399.99,
    1449.99,
    1499.99,
    1549.99,
    1599.99,
    1649.99,
    1699.99,
    1749.99,
    1799.99,
    1849.99,
    1899.99,
    1949.99,
    1999.99,
    2099.99,
    2199.99,
    2299.99,
    2399.99,
    2499.99,
    2599.99,
    2699.99,
    2799.99,
    2899.99,
    2999.99,
    3099.99,
    3199.99,
    3299.99,
    3399.99,
    3499.99,
    3599.99,
    3699.99,
    3799.99,
    3899.99,
    3999.99,
    4099.99,
    4199.99,
    4299.99,
    4399.99,
    4499.99,
    4599.99,
    4699.99,
    4799.99,
    4899.99,
    4999.99,
    5499.99,
    5999.99,
    6499.99,
    6999.99,
    7499.99,
    7999.99,
    8499.99,
    8999.99,
    9499.99,
    9999.99,
    10999.99,
    11999.99,
    12999.99,
    13999.99,
    14999.99,
    15999.99,
    16999.99,
    17999.99,
    18999.99,
    19999.99
  ]
};

// ----------- Bubble Status ------------------------------------------------------------------
export enum BubbleStatus {
  nandbox_bubble = 'nandbox',
  whatsapp_bubble = 'whatsapp',
  viber_bubble = 'viber',
  darktheme_bubble = 'darktheme',
  wechat_bubble = 'wechat',
  telegram_bubble = 'telegram'
}

export enum PageDirection {
  NEXT = 'NEXT',
  PREV = 'PREV'
}

export interface ListRequestDetails {
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  direction?: PageDirection;
}
export interface AdsConfig {
  provider_id?: string;
  config?: KeysConfig;
}

export interface KeysConfig {
  ad_google_key?: string;
  ad_native_key?: string;
  ad_banner_key?: string;
  ios_ad_google_key?: string;
  ios_ad_native_key?: string;
  ios_ad_banner_key?: string;
}

export interface AdsGoogleConfig {
  disable_banner?: number;
  disable_native?: number;
  cell_count?: number;
}
export interface AdsGoogleIOSConfig {
  ios_disable_banner?: number;
  ios_disable_native?: number;
  ios_cell_count?: number;
}

export interface StartOverListItem {
  title?: string;
  desc?: string;
  icons?: string[];
  type?: string;
}
export interface EnableServiceConfig {
  chat_id?: string;
  provider_id?: string;
  currency?: string;
  price?: number;
  product_id?: number;
  name?: string;
  desc?: string;
  image?: string;
  url?: string;
  timezone?: string;
  units?: number;
  sku?: string;
  period?: string;
  period_number?: number;
}
export enum PaymentProviderIDs {
  WEACCEPT = 'WEACCEPT',
  STRIPE = 'STRIPE',
  PAYTABS = 'PAYTABS',
  PAYPAL = 'PAYPAL',
  GOOGLEPROVIDER = 'GOOGLEADS',
  CASH = 'CASH',
  INAPPGOOGLE = 'INAPP_GOOGLE',
  STRIPE_CONNECT = 'STRIPE_CONNECT'
}

export enum SectionStyles {
  SPLASH = 'splash',
  LEFT_TITLE = 'left_title',
  CENTER_TITLE = 'center_title',
  LITTLE = 'little'
}

export const ISOCountry: IOption[] = [
  {
    key: 'AF',
    value: 'Afghanistan'
  },
  {
    key: 'AL',
    value: 'Albania'
  },
  {
    key: 'DZ',
    value: 'Algeria'
  },
  {
    key: 'AS',
    value: 'American Samoa'
  },
  {
    key: 'AD',
    value: 'Andorra'
  },
  {
    key: 'AO',
    value: 'Angola'
  },
  {
    key: 'AI',
    value: 'Anguilla'
  },
  {
    key: 'AQ',
    value: 'Antarctica'
  },
  {
    key: 'AG',
    value: 'Antigua and Barbuda'
  },
  {
    key: 'AR',
    value: 'Argentina'
  },
  {
    key: 'AM',
    value: 'Armenia'
  },
  {
    key: 'AW',
    value: 'Aruba'
  },
  {
    key: 'AU',
    value: 'Australia'
  },
  {
    key: 'AT',
    value: 'Austria'
  },
  {
    key: 'AZ',
    value: 'Azerbaijan'
  },
  {
    key: 'BS',
    value: 'Bahamas'
  },
  {
    key: 'BH',
    value: 'Bahrain'
  },
  {
    key: 'BD',
    value: 'Bangladesh'
  },
  {
    key: 'BB',
    value: 'Barbados'
  },
  {
    key: 'BY',
    value: 'Belarus'
  },
  {
    key: 'BE',
    value: 'Belgium'
  },
  {
    key: 'BZ',
    value: 'Belize'
  },
  {
    key: 'BJ',
    value: 'Benin'
  },
  {
    key: 'BM',
    value: 'Bermuda'
  },
  {
    key: 'BT',
    value: 'Bhutan'
  },
  {
    key: 'BO',
    value: 'Bolivia'
  },
  {
    key: 'BA',
    value: 'Bosnia and Herzegovina'
  },
  {
    key: 'BW',
    value: 'Botswana'
  },
  {
    key: 'BR',
    value: 'Brazil'
  },
  {
    key: 'IO',
    value: 'British Indian Ocean Territory'
  },
  {
    key: 'VG',
    value: 'British Virgin Islands'
  },
  {
    key: 'BN',
    value: 'Brunei'
  },
  {
    key: 'BG',
    value: 'Bulgaria'
  },
  {
    key: 'BF',
    value: 'Burkina Faso'
  },
  {
    key: 'BI',
    value: 'Burundi'
  },
  {
    key: 'KH',
    value: 'Cambodia'
  },
  {
    key: 'CM',
    value: 'Cameroon'
  },
  {
    key: 'CA',
    value: 'Canada'
  },
  {
    key: 'CV',
    value: 'Cape Verde'
  },
  {
    key: 'KY',
    value: 'Cayman Islands'
  },
  {
    key: 'CF',
    value: 'Central African Republic'
  },
  {
    key: 'TD',
    value: 'Chad'
  },
  {
    key: 'CL',
    value: 'Chile'
  },
  {
    key: 'CN',
    value: 'China'
  },
  {
    key: 'CX',
    value: 'Christmas Island'
  },
  {
    key: 'CC',
    value: 'Cocos Islands'
  },
  {
    key: 'CO',
    value: 'Colombia'
  },
  {
    key: 'KM',
    value: 'Comoros'
  },
  {
    key: 'CK',
    value: 'Cook Islands'
  },
  {
    key: 'CR',
    value: 'Costa Rica'
  },
  {
    key: 'HR',
    value: 'Croatia'
  },
  {
    key: 'CU',
    value: 'Cuba'
  },
  {
    key: 'CW',
    value: 'Curacao'
  },
  {
    key: 'CY',
    value: 'Cyprus'
  },
  {
    key: 'CZ',
    value: 'Czech Republic'
  },
  {
    key: 'CD',
    value: 'Democratic Republic of the Congo'
  },
  {
    key: 'DK',
    value: 'Denmark'
  },
  {
    key: 'DJ',
    value: 'Djibouti'
  },
  {
    key: 'DM',
    value: 'Dominica'
  },
  {
    key: 'DO',
    value: 'Dominican Republic'
  },
  {
    key: 'DO',
    value: 'Dominican Republic'
  },
  {
    key: 'DO',
    value: 'Dominican Republic'
  },
  {
    key: 'TL',
    value: 'East Timor'
  },
  {
    key: 'EC',
    value: 'Ecuador'
  },
  {
    key: 'EG',
    value: 'Egypt'
  },
  {
    key: 'SV',
    value: 'El Salvador'
  },
  {
    key: 'GQ',
    value: 'Equatorial Guinea'
  },
  {
    key: 'ER',
    value: 'Eritrea'
  },
  {
    key: 'EE',
    value: 'Estonia'
  },
  {
    key: 'ET',
    value: 'Ethiopia'
  },
  {
    key: 'FK',
    value: 'Falkland Islands'
  },
  {
    key: 'FO',
    value: 'Faroe Islands'
  },
  {
    key: 'FJ',
    value: 'Fiji'
  },
  {
    key: 'FI',
    value: 'Finland'
  },
  {
    key: 'FR',
    value: 'France'
  },
  {
    key: 'PF',
    value: 'French Polynesia'
  },
  {
    key: 'GA',
    value: 'Gabon'
  },
  {
    key: 'GM',
    value: 'Gambia'
  },
  {
    key: 'GE',
    value: 'Georgia'
  },
  {
    key: 'DE',
    value: 'Germany'
  },
  {
    key: 'GH',
    value: 'Ghana'
  },
  {
    key: 'GI',
    value: 'Gibraltar'
  },
  {
    key: 'GR',
    value: 'Greece'
  },
  {
    key: 'GL',
    value: 'Greenland'
  },
  {
    key: 'GD',
    value: 'Grenada'
  },
  {
    key: 'GU',
    value: 'Guam'
  },
  {
    key: 'GT',
    value: 'Guatemala'
  },
  {
    key: 'GG',
    value: 'Guernsey'
  },
  {
    key: 'GN',
    value: 'Guinea'
  },
  {
    key: 'GW',
    value: 'Guinea-Bissau'
  },
  {
    key: 'GY',
    value: 'Guyana'
  },
  {
    key: 'HT',
    value: 'Haiti'
  },
  {
    key: 'HN',
    value: 'Honduras'
  },
  {
    key: 'HK',
    value: 'Hong Kong'
  },
  {
    key: 'HU',
    value: 'Hungary'
  },
  {
    key: 'IS',
    value: 'Iceland'
  },
  {
    key: 'IN',
    value: 'India'
  },
  {
    key: 'ID',
    value: 'Indonesia'
  },
  {
    key: 'IR',
    value: 'Iran'
  },
  {
    key: 'IQ',
    value: 'Iraq'
  },
  {
    key: 'IE',
    value: 'Ireland'
  },
  {
    key: 'IM',
    value: 'Isle of Man'
  },
  {
    key: 'IL',
    value: 'Israel'
  },
  {
    key: 'IT',
    value: 'Italy'
  },
  {
    key: 'CI',
    value: 'Ivory Coast'
  },
  {
    key: 'JM',
    value: 'Jamaica'
  },
  {
    key: 'JP',
    value: 'Japan'
  },
  {
    key: 'JE',
    value: 'Jersey'
  },
  {
    key: 'JO',
    value: 'Jordan'
  },
  {
    key: 'KZ',
    value: 'Kazakhstan'
  },
  {
    key: 'KE',
    value: 'Kenya'
  },
  {
    key: 'KI',
    value: 'Kiribati'
  },
  {
    key: 'XK',
    value: 'Kosovo'
  },
  {
    key: 'KW',
    value: 'Kuwait'
  },
  {
    key: 'KG',
    value: 'Kyrgyzstan'
  },
  {
    key: 'LA',
    value: 'Laos'
  },
  {
    key: 'LV',
    value: 'Latvia'
  },
  {
    key: 'LB',
    value: 'Lebanon'
  },
  {
    key: 'LS',
    value: 'Lesotho'
  },
  {
    key: 'LR',
    value: 'Liberia'
  },
  {
    key: 'LY',
    value: 'Libya'
  },
  {
    key: 'LI',
    value: 'Liechtenstein'
  },
  {
    key: 'LT',
    value: 'Lithuania'
  },
  {
    key: 'LU',
    value: 'Luxembourg'
  },
  {
    key: 'MO',
    value: 'Macao'
  },
  {
    key: 'MK',
    value: 'Macedonia'
  },
  {
    key: 'MG',
    value: 'Madagascar'
  },
  {
    key: 'MW',
    value: 'Malawi'
  },
  {
    key: 'MY',
    value: 'Malaysia'
  },
  {
    key: 'MV',
    value: 'Maldives'
  },
  {
    key: 'ML',
    value: 'Mali'
  },
  {
    key: 'MT',
    value: 'Malta'
  },
  {
    key: 'MH',
    value: 'Marshall Islands'
  },
  {
    key: 'MR',
    value: 'Mauritania'
  },
  {
    key: 'MU',
    value: 'Mauritius'
  },
  {
    key: 'YT',
    value: 'Mayotte'
  },
  {
    key: 'MX',
    value: 'Mexico'
  },
  {
    key: 'FM',
    value: 'Micronesia'
  },
  {
    key: 'MD',
    value: 'Moldova'
  },
  {
    key: 'MC',
    value: 'Monaco'
  },
  {
    key: 'MN',
    value: 'Mongolia'
  },
  {
    key: 'ME',
    value: 'Montenegro'
  },
  {
    key: 'MS',
    value: 'Montserrat'
  },
  {
    key: 'MA',
    value: 'Morocco'
  },
  {
    key: 'MZ',
    value: 'Mozambique'
  },
  {
    key: 'MM',
    value: 'Myanmar'
  },
  {
    key: 'NA',
    value: 'Namibia'
  },
  {
    key: 'NR',
    value: 'Nauru'
  },
  {
    key: 'NP',
    value: 'Nepal'
  },
  {
    key: 'NL',
    value: 'Netherlands'
  },
  {
    key: 'AN',
    value: 'Netherlands Antilles'
  },
  {
    key: 'NC',
    value: 'New Caledonia'
  },
  {
    key: 'NZ',
    value: 'New Zealand'
  },
  {
    key: 'NI',
    value: 'Nicaragua'
  },
  {
    key: 'NE',
    value: 'Niger'
  },
  {
    key: 'NG',
    value: 'Nigeria'
  },
  {
    key: 'NU',
    value: 'Niue'
  },
  {
    key: 'KP',
    value: 'North Korea'
  },
  {
    key: 'MP',
    value: 'Northern Mariana Islands'
  },
  {
    key: 'NO',
    value: 'Norway'
  },
  {
    key: 'OM',
    value: 'Oman'
  },
  {
    key: 'PK',
    value: 'Pakistan'
  },
  {
    key: 'PW',
    value: 'Palau'
  },
  {
    key: 'PS',
    value: 'Palestine'
  },
  {
    key: 'PA',
    value: 'Panama'
  },
  {
    key: 'PG',
    value: 'Papua New Guinea'
  },
  {
    key: 'PY',
    value: 'Paraguay'
  },
  {
    key: 'PE',
    value: 'Peru'
  },
  {
    key: 'PH',
    value: 'Philippines'
  },
  {
    key: 'PN',
    value: 'Pitcairn'
  },
  {
    key: 'PL',
    value: 'Poland'
  },
  {
    key: 'PT',
    value: 'Portugal'
  },
  {
    key: 'PR',
    value: 'Puerto Rico'
  },
  {
    key: 'PR',
    value: 'Puerto Rico'
  },
  {
    key: 'QA',
    value: 'Qatar'
  },
  {
    key: 'CG',
    value: 'Republic of the Congo'
  },
  {
    key: 'RE',
    value: 'Reunion'
  },
  {
    key: 'RO',
    value: 'Romania'
  },
  {
    key: 'RU',
    value: 'Russia'
  },
  {
    key: 'RW',
    value: 'Rwanda'
  },
  {
    key: 'BL',
    value: 'Saint Barthelemy'
  },
  {
    key: 'SH',
    value: 'Saint Helena'
  },
  {
    key: 'KN',
    value: 'Saint Kitts and Nevis'
  },
  {
    key: 'LC',
    value: 'Saint Lucia'
  },
  {
    key: 'MF',
    value: 'Saint Martin'
  },
  {
    key: 'PM',
    value: 'Saint Pierre and Miquelon'
  },
  {
    key: 'VC',
    value: 'Saint Vincent and the Grenadines'
  },
  {
    key: 'WS',
    value: 'Samoa'
  },
  {
    key: 'SM',
    value: 'San Marino'
  },
  {
    key: 'ST',
    value: 'Sao Tome and Principe'
  },
  {
    key: 'SA',
    value: 'Saudi Arabia'
  },
  {
    key: 'SN',
    value: 'Senegal'
  },
  {
    key: 'RS',
    value: 'Serbia'
  },
  {
    key: 'SC',
    value: 'Seychelles'
  },
  {
    key: 'SL',
    value: 'Sierra Leone'
  },
  {
    key: 'SG',
    value: 'Singapore'
  },
  {
    key: 'SX',
    value: 'Sint Maarten'
  },
  {
    key: 'SK',
    value: 'Slovakia'
  },
  {
    key: 'SI',
    value: 'Slovenia'
  },
  {
    key: 'SB',
    value: 'Solomon Islands'
  },
  {
    key: 'SO',
    value: 'Somalia'
  },
  {
    key: 'ZA',
    value: 'South Africa'
  },
  {
    key: 'KR',
    value: 'South Korea'
  },
  {
    key: 'SS',
    value: 'South Sudan'
  },
  {
    key: 'ES',
    value: 'Spain'
  },
  {
    key: 'LK',
    value: 'Sri Lanka'
  },
  {
    key: 'SD',
    value: 'Sudan'
  },
  {
    key: 'SR',
    value: 'Suriname'
  },
  {
    key: 'SJ',
    value: 'Svalbard and Jan Mayen'
  },
  {
    key: 'SZ',
    value: 'Swaziland'
  },
  {
    key: 'SE',
    value: 'Sweden'
  },
  {
    key: 'CH',
    value: 'Switzerland'
  },
  {
    key: 'SY',
    value: 'Syria'
  },
  {
    key: 'TW',
    value: 'Taiwan'
  },
  {
    key: 'TJ',
    value: 'Tajikistan'
  },
  {
    key: 'TZ',
    value: 'Tanzania'
  },
  {
    key: 'TH',
    value: 'Thailand'
  },
  {
    key: 'TG',
    value: 'Togo'
  },
  {
    key: 'TK',
    value: 'Tokelau'
  },
  {
    key: 'TO',
    value: 'Tonga'
  },
  {
    key: 'TT',
    value: 'Trinidad and Tobago'
  },
  {
    key: 'TN',
    value: 'Tunisia'
  },
  {
    key: 'TR',
    value: 'Turkey'
  },
  {
    key: 'TM',
    value: 'Turkmenistan'
  },
  {
    key: 'TC',
    value: 'Turks and Caicos Islands'
  },
  {
    key: 'TV',
    value: 'Tuvalu'
  },
  {
    key: 'VI',
    value: 'U.S. Virgin Islands'
  },
  {
    key: 'UG',
    value: 'Uganda'
  },
  {
    key: 'UA',
    value: 'Ukraine'
  },
  {
    key: 'AE',
    value: 'United Arab Emirates'
  },
  {
    key: 'GB',
    value: 'United Kingdom'
  },
  {
    key: 'US',
    value: 'United States'
  },
  {
    key: 'UY',
    value: 'Uruguay'
  },
  {
    key: 'UZ',
    value: 'Uzbekistan'
  },
  {
    key: 'VU',
    value: 'Vanuatu'
  },
  {
    key: 'VA',
    value: 'Vatican'
  },
  {
    key: 'VE',
    value: 'Venezuela'
  },
  {
    key: 'VN',
    value: 'Vietnam'
  },
  {
    key: 'WF',
    value: 'Wallis and Futuna'
  },
  {
    key: 'EH',
    value: 'Western Sahara'
  },
  {
    key: 'YE',
    value: 'Yemen'
  },
  {
    key: 'ZM',
    value: 'Zambia'
  },
  {
    key: 'ZW',
    value: 'Zimbabwe'
  },
  {
    key: 'AA',
    value: 'Options'
  }
];

export const ISOCountry3: IOption[] = [
  {
    key: 'AFG',
    value: 'Afghanistan'
  },
  {
    key: 'ALB',
    value: 'Albania'
  },
  {
    key: 'DZA',
    value: 'Algeria'
  },
  {
    key: 'ASM',
    value: 'American Samoa'
  },
  {
    key: 'AND',
    value: 'Andorra'
  },
  {
    key: 'AGO',
    value: 'Angola'
  },
  {
    key: 'AIA',
    value: 'Anguilla'
  },
  {
    key: 'ATA',
    value: 'Antarctica'
  },
  {
    key: 'ATG',
    value: 'Antigua and Barbuda'
  },
  {
    key: 'ARG',
    value: 'Argentina'
  },
  {
    key: 'ARM',
    value: 'Armenia'
  },
  {
    key: 'ABW',
    value: 'Aruba'
  },
  {
    key: 'AUS',
    value: 'Australia'
  },
  {
    key: 'AUT',
    value: 'Austria'
  },
  {
    key: 'AZE',
    value: 'Azerbaijan'
  },
  {
    key: 'BHS',
    value: 'Bahamas'
  },
  {
    key: 'BHR',
    value: 'Bahrain'
  },
  {
    key: 'BGD',
    value: 'Bangladesh'
  },
  {
    key: 'BRB',
    value: 'Barbados'
  },
  {
    key: 'BLR',
    value: 'Belarus'
  },
  {
    key: 'BEL',
    value: 'Belgium'
  },
  {
    key: 'BLZ',
    value: 'Belize'
  },
  {
    key: 'BEN',
    value: 'Benin'
  },
  {
    key: 'BMU',
    value: 'Bermuda'
  },
  {
    key: 'BTN',
    value: 'Bhutan'
  },
  {
    key: 'BOL',
    value: 'Bolivia'
  },
  {
    key: 'BIH',
    value: 'Bosnia and Herzegovina'
  },
  {
    key: 'BWA',
    value: 'Botswana'
  },
  {
    key: 'BRA',
    value: 'Brazil'
  },
  {
    key: 'IOT',
    value: 'British Indian Ocean Territory'
  },
  {
    key: 'VGB',
    value: 'British Virgin Islands'
  },
  {
    key: 'BRN',
    value: 'Brunei'
  },
  {
    key: 'BGR',
    value: 'Bulgaria'
  },
  {
    key: 'BFA',
    value: 'Burkina Faso'
  },
  {
    key: 'BDI',
    value: 'Burundi'
  },
  {
    key: 'KHM',
    value: 'Cambodia'
  },
  {
    key: 'CMR',
    value: 'Cameroon'
  },
  {
    key: 'CAN',
    value: 'Canada'
  },
  {
    key: 'CPV',
    value: 'Cape Verde'
  },
  {
    key: 'CYM',
    value: 'Cayman Islands'
  },
  {
    key: 'CAF',
    value: 'Central African Republic'
  },
  {
    key: 'TCD',
    value: 'Chad'
  },
  {
    key: 'CHL',
    value: 'Chile'
  },
  {
    key: 'CHN',
    value: 'China'
  },
  {
    key: 'CXR',
    value: 'Christmas Island'
  },
  {
    key: 'CCK',
    value: 'Cocos Islands'
  },
  {
    key: 'COL',
    value: 'Colombia'
  },
  {
    key: 'COM',
    value: 'Comoros'
  },
  {
    key: 'COK',
    value: 'Cook Islands'
  },
  {
    key: 'CRI',
    value: 'Costa Rica'
  },
  {
    key: 'HRV',
    value: 'Croatia'
  },
  {
    key: 'CUB',
    value: 'Cuba'
  },
  {
    key: 'CUW',
    value: 'Curacao'
  },
  {
    key: 'CYP',
    value: 'Cyprus'
  },
  {
    key: 'CZE',
    value: 'Czech Republic'
  },
  {
    key: 'COD',
    value: 'Democratic Republic of the Congo'
  },
  {
    key: 'DNK',
    value: 'Denmark'
  },
  {
    key: 'DJI',
    value: 'Djibouti'
  },
  {
    key: 'DMA',
    value: 'Dominica'
  },
  {
    key: 'DOM',
    value: 'Dominican Republic'
  },
  {
    key: 'DOM',
    value: 'Dominican Republic'
  },
  {
    key: 'DOM',
    value: 'Dominican Republic'
  },
  {
    key: 'TLS',
    value: 'East Timor'
  },
  {
    key: 'ECU',
    value: 'Ecuador'
  },
  {
    key: 'EGY',
    value: 'Egypt'
  },
  {
    key: 'SLV',
    value: 'El Salvador'
  },
  {
    key: 'GNQ',
    value: 'Equatorial Guinea'
  },
  {
    key: 'ERI',
    value: 'Eritrea'
  },
  {
    key: 'EST',
    value: 'Estonia'
  },
  {
    key: 'ETH',
    value: 'Ethiopia'
  },
  {
    key: 'FLK',
    value: 'Falkland Islands'
  },
  {
    key: 'FRO',
    value: 'Faroe Islands'
  },
  {
    key: 'FJI',
    value: 'Fiji'
  },
  {
    key: 'FIN',
    value: 'Finland'
  },
  {
    key: 'FRA',
    value: 'France'
  },
  {
    key: 'PYF',
    value: 'French Polynesia'
  },
  {
    key: 'GAB',
    value: 'Gabon'
  },
  {
    key: 'GMB',
    value: 'Gambia'
  },
  {
    key: 'GEO',
    value: 'Georgia'
  },
  {
    key: 'DEU',
    value: 'Germany'
  },
  {
    key: 'GHA',
    value: 'Ghana'
  },
  {
    key: 'GIB',
    value: 'Gibraltar'
  },
  {
    key: 'GRC',
    value: 'Greece'
  },
  {
    key: 'GRL',
    value: 'Greenland'
  },
  {
    key: 'GRD',
    value: 'Grenada'
  },
  {
    key: 'GUM',
    value: 'Guam'
  },
  {
    key: 'GTM',
    value: 'Guatemala'
  },
  {
    key: 'GGY',
    value: 'Guernsey'
  },
  {
    key: 'GIN',
    value: 'Guinea'
  },
  {
    key: 'GNB',
    value: 'Guinea-Bissau'
  },
  {
    key: 'GUY',
    value: 'Guyana'
  },
  {
    key: 'HTI',
    value: 'Haiti'
  },
  {
    key: 'HND',
    value: 'Honduras'
  },
  {
    key: 'HKG',
    value: 'Hong Kong'
  },
  {
    key: 'HUN',
    value: 'Hungary'
  },
  {
    key: 'ISL',
    value: 'Iceland'
  },
  {
    key: 'IND',
    value: 'India'
  },
  {
    key: 'IDN',
    value: 'Indonesia'
  },
  {
    key: 'IRN',
    value: 'Iran'
  },
  {
    key: 'IRQ',
    value: 'Iraq'
  },
  {
    key: 'IRL',
    value: 'Ireland'
  },
  {
    key: 'IMN',
    value: 'Isle of Man'
  },
  {
    key: 'ISR',
    value: 'Israel'
  },
  {
    key: 'ITA',
    value: 'Italy'
  },
  {
    key: 'CIV',
    value: 'Ivory Coast'
  },
  {
    key: 'JAM',
    value: 'Jamaica'
  },
  {
    key: 'JPN',
    value: 'Japan'
  },
  {
    key: 'JEY',
    value: 'Jersey'
  },
  {
    key: 'JOR',
    value: 'Jordan'
  },
  {
    key: 'KAZ',
    value: 'Kazakhstan'
  },
  {
    key: 'KEN',
    value: 'Kenya'
  },
  {
    key: 'KIR',
    value: 'Kiribati'
  },
  {
    key: 'XKX',
    value: 'Kosovo'
  },
  {
    key: 'KWT',
    value: 'Kuwait'
  },
  {
    key: 'KGZ',
    value: 'Kyrgyzstan'
  },
  {
    key: 'LAO',
    value: 'Laos'
  },
  {
    key: 'LVA',
    value: 'Latvia'
  },
  {
    key: 'LBN',
    value: 'Lebanon'
  },
  {
    key: 'LSO',
    value: 'Lesotho'
  },
  {
    key: 'LBR',
    value: 'Liberia'
  },
  {
    key: 'LBY',
    value: 'Libya'
  },
  {
    key: 'LIE',
    value: 'Liechtenstein'
  },
  {
    key: 'LTU',
    value: 'Lithuania'
  },
  {
    key: 'LUX',
    value: 'Luxembourg'
  },
  {
    key: 'MAC',
    value: 'Macao'
  },
  {
    key: 'MKD',
    value: 'Macedonia'
  },
  {
    key: 'MDG',
    value: 'Madagascar'
  },
  {
    key: 'MWI',
    value: 'Malawi'
  },
  {
    key: 'MYS',
    value: 'Malaysia'
  },
  {
    key: 'MDV',
    value: 'Maldives'
  },
  {
    key: 'MLI',
    value: 'Mali'
  },
  {
    key: 'MLT',
    value: 'Malta'
  },
  {
    key: 'MHL',
    value: 'Marshall Islands'
  },
  {
    key: 'MRT',
    value: 'Mauritania'
  },
  {
    key: 'MUS',
    value: 'Mauritius'
  },
  {
    key: 'MYT',
    value: 'Mayotte'
  },
  {
    key: 'MEX',
    value: 'Mexico'
  },
  {
    key: 'FSM',
    value: 'Micronesia'
  },
  {
    key: 'MDA',
    value: 'Moldova'
  },
  {
    key: 'MCO',
    value: 'Monaco'
  },
  {
    key: 'MNG',
    value: 'Mongolia'
  },
  {
    key: 'MNE',
    value: 'Montenegro'
  },
  {
    key: 'MSR',
    value: 'Montserrat'
  },
  {
    key: 'MAR',
    value: 'Morocco'
  },
  {
    key: 'MOZ',
    value: 'Mozambique'
  },
  {
    key: 'MMR',
    value: 'Myanmar'
  },
  {
    key: 'NAM',
    value: 'Namibia'
  },
  {
    key: 'NRU',
    value: 'Nauru'
  },
  {
    key: 'NPL',
    value: 'Nepal'
  },
  {
    key: 'NLD',
    value: 'Netherlands'
  },
  {
    key: 'ANT',
    value: 'Netherlands Antilles'
  },
  {
    key: 'NCL',
    value: 'New Caledonia'
  },
  {
    key: 'NZL',
    value: 'New Zealand'
  },
  {
    key: 'NIC',
    value: 'Nicaragua'
  },
  {
    key: 'NER',
    value: 'Niger'
  },
  {
    key: 'NGA',
    value: 'Nigeria'
  },
  {
    key: 'NIU',
    value: 'Niue'
  },
  {
    key: 'PRK',
    value: 'North Korea'
  },
  {
    key: 'MNP',
    value: 'Northern Mariana Islands'
  },
  {
    key: 'NOR',
    value: 'Norway'
  },
  {
    key: 'OMN',
    value: 'Oman'
  },
  {
    key: 'PAK',
    value: 'Pakistan'
  },
  {
    key: 'PLW',
    value: 'Palau'
  },
  {
    key: 'PSE',
    value: 'Palestine'
  },
  {
    key: 'PAN',
    value: 'Panama'
  },
  {
    key: 'PNG',
    value: 'Papua New Guinea'
  },
  {
    key: 'PRY',
    value: 'Paraguay'
  },
  {
    key: 'PER',
    value: 'Peru'
  },
  {
    key: 'PHL',
    value: 'Philippines'
  },
  {
    key: 'PCN',
    value: 'Pitcairn'
  },
  {
    key: 'POL',
    value: 'Poland'
  },
  {
    key: 'PRT',
    value: 'Portugal'
  },
  {
    key: 'PRI',
    value: 'Puerto Rico'
  },
  {
    key: 'PRI',
    value: 'Puerto Rico'
  },
  {
    key: 'QAT',
    value: 'Qatar'
  },
  {
    key: 'COG',
    value: 'Republic of the Congo'
  },
  {
    key: 'REU',
    value: 'Reunion'
  },
  {
    key: 'ROU',
    value: 'Romania'
  },
  {
    key: 'RUS',
    value: 'Russia'
  },
  {
    key: 'RWA',
    value: 'Rwanda'
  },
  {
    key: 'BLM',
    value: 'Saint Barthelemy'
  },
  {
    key: 'SHN',
    value: 'Saint Helena'
  },
  {
    key: 'KNA',
    value: 'Saint Kitts and Nevis'
  },
  {
    key: 'LCA',
    value: 'Saint Lucia'
  },
  {
    key: 'MAF',
    value: 'Saint Martin'
  },
  {
    key: 'SPM',
    value: 'Saint Pierre and Miquelon'
  },
  {
    key: 'VCT',
    value: 'Saint Vincent and the Grenadines'
  },
  {
    key: 'WSM',
    value: 'Samoa'
  },
  {
    key: 'SMR',
    value: 'San Marino'
  },
  {
    key: 'STP',
    value: 'Sao Tome and Principe'
  },
  {
    key: 'SAU',
    value: 'Saudi Arabia'
  },
  {
    key: 'SEN',
    value: 'Senegal'
  },
  {
    key: 'SRB',
    value: 'Serbia'
  },
  {
    key: 'SYC',
    value: 'Seychelles'
  },
  {
    key: 'SLE',
    value: 'Sierra Leone'
  },
  {
    key: 'SGP',
    value: 'Singapore'
  },
  {
    key: 'SXM',
    value: 'Sint Maarten'
  },
  {
    key: 'SVK',
    value: 'Slovakia'
  },
  {
    key: 'SVN',
    value: 'Slovenia'
  },
  {
    key: 'SLB',
    value: 'Solomon Islands'
  },
  {
    key: 'SOM',
    value: 'Somalia'
  },
  {
    key: 'ZAF',
    value: 'South Africa'
  },
  {
    key: 'KOR',
    value: 'South Korea'
  },
  {
    key: 'SSD',
    value: 'South Sudan'
  },
  {
    key: 'ESP',
    value: 'Spain'
  },
  {
    key: 'LKA',
    value: 'Sri Lanka'
  },
  {
    key: 'SDN',
    value: 'Sudan'
  },
  {
    key: 'SUR',
    value: 'Suriname'
  },
  {
    key: 'SJM',
    value: 'Svalbard and Jan Mayen'
  },
  {
    key: 'SWZ',
    value: 'Swaziland'
  },
  {
    key: 'SWE',
    value: 'Sweden'
  },
  {
    key: 'CHE',
    value: 'Switzerland'
  },
  {
    key: 'SYR',
    value: 'Syria'
  },
  {
    key: 'TWN',
    value: 'Taiwan'
  },
  {
    key: 'TJK',
    value: 'Tajikistan'
  },
  {
    key: 'TZA',
    value: 'Tanzania'
  },
  {
    key: 'THA',
    value: 'Thailand'
  },
  {
    key: 'TGO',
    value: 'Togo'
  },
  {
    key: 'TKL',
    value: 'Tokelau'
  },
  {
    key: 'TON',
    value: 'Tonga'
  },
  {
    key: 'TTO',
    value: 'Trinidad and Tobago'
  },
  {
    key: 'TUN',
    value: 'Tunisia'
  },
  {
    key: 'TUR',
    value: 'Turkey'
  },
  {
    key: 'TKM',
    value: 'Turkmenistan'
  },
  {
    key: 'TCA',
    value: 'Turks and Caicos Islands'
  },
  {
    key: 'TUV',
    value: 'Tuvalu'
  },
  {
    key: 'VIR',
    value: 'U.S. Virgin Islands'
  },
  {
    key: 'UGA',
    value: 'Uganda'
  },
  {
    key: 'UKR',
    value: 'Ukraine'
  },
  {
    key: 'ARE',
    value: 'United Arab Emirates'
  },
  {
    key: 'GBR',
    value: 'United Kingdom'
  },
  {
    key: 'USA',
    value: 'United States'
  },
  {
    key: 'URY',
    value: 'Uruguay'
  },
  {
    key: 'UZB',
    value: 'Uzbekistan'
  },
  {
    key: 'VUT',
    value: 'Vanuatu'
  },
  {
    key: 'VAT',
    value: 'Vatican'
  },
  {
    key: 'VEN',
    value: 'Venezuela'
  },
  {
    key: 'VNM',
    value: 'Vietnam'
  },
  {
    key: 'WLF',
    value: 'Wallis and Futuna'
  },
  {
    key: 'ESH',
    value: 'Western Sahara'
  },
  {
    key: 'YEM',
    value: 'Yemen'
  },
  {
    key: 'ZMB',
    value: 'Zambia'
  },
  {
    key: 'ZWE',
    value: 'Zimbabwe'
  },
  {
    key: 'AAA',
    value: 'Options'
  },
  {
    key: 'UMI',
    value: 'United States Minor Outlying Islands'
  },
  {
    key: 'ALA',
    value: 'Åland Islands'
  },
  {
    key: 'ATF',
    value: 'French Southern Territories'
  },
  {
    key: 'BES',
    value: 'Caribbean Netherlands'
  },
  {
    key: 'BVT',
    value: 'Bouvet Island'
  },
  {
    key: 'GLP',
    value: 'Guadeloupe'
  },
  {
    key: 'GUF',
    value: 'French Guiana'
  },
  {
    key: 'MTQ',
    value: 'Martinique'
  },
  {
    key: 'NFK',
    value: 'Norfolk Island'
  },
  {
    key: 'SGS',
    value: 'Georgia and the South Sandwich Islands.'
  }
];
