import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appChannelSettings.actions';

import { AppState } from 'src/models/AppState';

@Injectable({
  providedIn: 'root'
})
export class AppChannelSettingsDispatchers {
  constructor(private _store: Store<AppState>) {}

  setAppChannelSettings(state: boolean) {
    this._store.dispatch(new actions.SetAppChannelSettings(state));
  }

  getAppChannelSettings() {
    this._store.dispatch(new actions.GetAppChannelSettings());
  }

  receiveAppChannelSettingsHistory(state: boolean) {
    this._store.dispatch(new actions.ReceiveAppChannelSettingsHistory(state));
  }
}
