<div [class.qr-scanner]="!styleQROnEdit" [class.qr-edit]="styleQROnEdit">
  <div class="qr-scanner-title" *ngIf="!styleQROnEdit">
    {{ screenType }} QR Code
  </div>
  <div
    [class.qr-scanner-form]="!styleQROnEdit"
    [class.qr-scanner-onEdit]="styleQROnEdit"
  >
    <div class="qr-container" id="section-to-print">
      <div [class.qr-code]="!styleQROnEdit" [class.qr-onEdit]="styleQROnEdit">
        <qr-code
          class="qr"
          [value]="qrCodeText"
          [size]="150"
          *ngIf="!styleInEditScreen"
        >
        </qr-code>
        <qr-code
          class="qr"
          [value]="qrCodeText"
          [size]="60"
          *ngIf="styleInEditScreen"
        >
        </qr-code>
      </div>
    </div>
    <!-- <div class="actions-container">
      <div class="share clickable-cursor" (click)="shareEvent()">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-icons.svg#ic_share_24dp"></use>
        </svg>
      </div>

      <div class="or">Or</div>
      <div class="print clickable-cursor" (click)="printEvent()">
        <svg class="svg-icon">
          <use xlink:href="./assets/svg/app-icons.svg#ic_print_24dp"></use>
        </svg>
      </div>
      <div class="line"></div>
    </div> -->
  </div>
</div>
