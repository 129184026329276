import { Action } from '@ngrx/store';

import { IChat } from 'src/models/IChat';
import { IAuth, CountryData } from 'src/models/IAuth';

// RECIVE Part
export const REOPEN_NEW_CONNECTION = 'REOPEN_NEW_CONNECTION';
export const RECIVE_AUTH_QR = 'RECIVE_AUTH_QR';
export const RECIVE_AUTH_TOKEN = 'RECIVE_AUTH_TOKEN';
export const RECIVE_AUTH_TOKEN_CHANGE = 'RECIVE_AUTH_TOKEN_CHANGE';
export const RECIVE_AUTH_OK = 'RECIVED_AUTH_OK';
export const RECIVE_AUTH_NOT = 'RECIVE_AUTH_NOT';
export const RECIVE_MULTI_LOGIN = 'RECIVE_MULTI_LOGIN';
// SET Part
export const SET_AUTH_QR_EXPIRE = 'SET_AUTH_QR_EXPIRE';
// RESET Part
export const RESET = 'RESET';
export const LOGGED_OUT = 'LOGGED_OUT';

/* Merchant Web Only  */
export const RECIVE_AUTH_CHAT_LIST = 'RECIVE_AUTH_CHAT_LIST';
export const SET_CHAT_SELECT = 'SET_CHAT_SELECT';
export const RECEIVE_AUTH_CHAT_SELECTED = 'RECEIVE_AUTH_CHAT_SELECTED';
// SIGN UP Part
export const GET_COUNTRY_DATA = 'GET_COUNTRY_DATA';
export const GET_CHAT_DATA = 'GET_CHAT_DATA';
export const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';
export const GET_EMAIL_TAC = 'GET_EMAIL_TAC';
export const EMAIL_TAC_SUCCESS = 'EMAIL_TAC_SUCCESS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const GET_TOKEN_ACTION = 'GET_TOKEN_ACTION';
export const EMAIL_TOKEN_RECEIVED = 'EMAIL_TOKEN_RECEIVED';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const SET_APP_PROGRESS = 'SET_APP_PROGRESS';
export const SET_APP_MODE = 'SET_APP_MODE';
export const LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL';
export const RECEIVE_ADMIN_QR = 'RECEIVE_ADMIN_QR';
export const REQUEST_ADMIN_QR = 'REQUEST_ADMIN_QR';
export const RECEIVE_AUTH_PRIVILEGE = 'RECEIVE_AUTH_PRIVILEGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RECEIVE_CHANGE_PASSWORD_SET = 'RECEIVE_CHANGE_PASSWORD_SET';
export const RECEIVE_CHANGE_PASSWORD_ERROR = 'RECEIVE_CHANGE_PASSWORD_ERROR';

export class ReopenNewConnection implements Action {
  readonly type = REOPEN_NEW_CONNECTION;
}

export class ReciveAuthQR implements Action {
  readonly type = RECIVE_AUTH_QR;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthToken implements Action {
  readonly type = RECIVE_AUTH_TOKEN;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthTokenChange implements Action {
  readonly type = RECIVE_AUTH_TOKEN_CHANGE;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthOK implements Action {
  readonly type = RECIVE_AUTH_OK;
  constructor(public payload: IAuth) {}
}

export class ReciveAuthNot implements Action {
  readonly type = RECIVE_AUTH_NOT;
}

export class ReciveMultiLogin implements Action {
  readonly type = RECIVE_MULTI_LOGIN;
}

export class SetAuthQRExpire implements Action {
  readonly type = SET_AUTH_QR_EXPIRE;
}

export class LoggedOut implements Action {
  readonly type = LOGGED_OUT;
}
/* Merchant Web Only */
export class ReciveAuthChatList implements Action {
  readonly type = RECIVE_AUTH_CHAT_LIST;
  constructor(public payload: IChat[]) {}
}
export class SetChatSelect implements Action {
  readonly type = SET_CHAT_SELECT;
  constructor(public payload: string) {}
}

export class GetChatData implements Action {
  readonly type = GET_CHAT_DATA;
  constructor(public chat_id: string) {}
}
export class ReceiveAuthChatSelected implements Action {
  readonly type = RECEIVE_AUTH_CHAT_SELECTED;
  constructor(public payload: IAuth) {}
}

/* Channel App Sign Up*/
export class GetCountryData implements Action {
  readonly type = GET_COUNTRY_DATA;
}

export class SetCountryData implements Action {
  readonly type = SET_COUNTRY_DATA;
  constructor(public countryData: CountryData) {}
}

export class GetEmailTac implements Action {
  readonly type = GET_EMAIL_TAC;
  constructor(public email: string, public tacType?: number) {}
}

export class EmailTacSuccess implements Action {
  readonly type = EMAIL_TAC_SUCCESS;
  constructor(
    public email: string,
    public appName: string,
    public name: string,
    public password: string
  ) {}
}

export class CreateAccount implements Action {
  readonly type = CREATE_ACCOUNT;
  constructor(public tac: string) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = CREATE_ACCOUNT_SUCCESS;
  constructor(public password: string) {}
}

export class GetTokenAction implements Action {
  readonly type = GET_TOKEN_ACTION;
  constructor(public email: string, public password: string) {}
}

export class EmailTokenReceived implements Action {
  readonly type = EMAIL_TOKEN_RECEIVED;
  constructor(public token: string) {}
}

export class SelectTemplate implements Action {
  readonly type = SELECT_TEMPLATE;
  constructor(public templateID: string) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export class SetAppProgress implements Action {
  readonly type = SET_APP_PROGRESS;
  constructor(public progressWeb: string, public mode?: number) {}
}

export class SetAppMode implements Action {
  readonly type = SET_APP_MODE;
  constructor(public webMode: number) {}
}

export class LoginByEmail implements Action {
  readonly type = LOGIN_BY_EMAIL;
}

export class ReceiveAdminQR implements Action {
  readonly type = RECEIVE_ADMIN_QR;
  constructor(public adminQR: string, public chatID: string) {}
}

export class RequestAdminQR implements Action {
  readonly type = REQUEST_ADMIN_QR;
}

export class ReceiveAuthPrivilege implements Action {
  readonly type = RECEIVE_AUTH_PRIVILEGE;
  constructor(public payload: string[], public reference?: string) {}
}

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;
  constructor(
    public payload: {
      oldPassword: string;
      newPassword: string;
      confirmNewPassword: string;
    }
  ) {}
}

export class ReceiveChangePasswordSet implements Action {
  readonly type = RECEIVE_CHANGE_PASSWORD_SET;
}

export class ReceiveChangePasswordError implements Action {
  readonly type = RECEIVE_CHANGE_PASSWORD_ERROR;
  constructor(public payload: number) {}
}

export type AuthActions =
  | ReopenNewConnection
  | ReciveAuthQR
  | ReciveAuthToken
  | ReciveAuthTokenChange
  | ReciveAuthOK
  | ReciveAuthNot
  | ReciveMultiLogin
  | SetAuthQRExpire
  | LoggedOut
  | ReciveAuthChatList
  | SetChatSelect
  | ReceiveAuthChatSelected
  | GetCountryData
  | SetCountryData
  | GetEmailTac
  | EmailTacSuccess
  | CreateAccount
  | CreateAccountSuccess
  | GetTokenAction
  | EmailTokenReceived
  | SelectTemplate
  | SetAppProgress
  | LoginByEmail
  | ReceiveAdminQR
  | RequestAdminQR
  | ReceiveAuthPrivilege
  | ChangePassword
  | ReceiveChangePasswordSet
  | ReceiveChangePasswordError
  | Reset;
