import { map, distinctUntilChanged } from 'rxjs/operators';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import * as i0 from "@angular/core";
import * as i1 from "../../store/root-store.selectors";
import * as i2 from "../../store/ui/ui.dispatchers";
import * as i3 from "../../store/auth/auth.dispatchers";
import * as i4 from "../../store/messages/message.dispatchers";
import * as i5 from "../../store/chats/chat.dispatchers";
export class DashboardService {
    constructor(_selectors, _uiDispatchers, _authDispatchers, _messageDispatchers, _chatDispatchers) {
        this._selectors = _selectors;
        this._uiDispatchers = _uiDispatchers;
        this._authDispatchers = _authDispatchers;
        this._messageDispatchers = _messageDispatchers;
        this._chatDispatchers = _chatDispatchers;
        this.notifyCount = 0;
        this.profileSubsc = null;
        this._selectors
            .getEventList$()
            .pipe(distinctUntilChanged((n, p) => n.length === p.length))
            .subscribe(console.log);
    }
    logout() {
        this._authDispatchers.logOut();
        this._authDispatchers.resetStore();
    }
    setChannelInfo(chatID, updatedChatProps, reference) {
        updatedChatProps.id = chatID;
        this._chatDispatchers.updateChat(updatedChatProps, reference);
    }
    uploadChatImage(chatID, selectedFile) {
        this._chatDispatchers.uploadChatPhoto(chatID, selectedFile);
    }
    setPageNumber(action) {
        this._uiDispatchers.setPageNumber(action);
    }
    setSearchKeyword(searchString) {
        this._uiDispatchers.setSearchKeyWord(searchString);
    }
    setSection(direction) {
        this._uiDispatchers.setSection(direction);
    }
    setScheduleDate(scheduleDate) {
        this._uiDispatchers.dateSelected(scheduleDate);
    }
    deselectChannel() {
        this._messageDispatchers.deselectChannel();
    }
    setSelect(type) {
        // this._messageDispatchers.setSelect(type);
    }
    /*********************** Store Selectors *********************************************/
    // TODO: Move this selectors fro auth dispatchers
    get chatAdminName() {
        return this._authDispatchers.getLoginName();
    }
    get selectedChatName$() {
        return this._selectors.selectedChatName$;
    }
    get authCollection$() {
        return this._selectors.authCollection$;
    }
    get allChannels$() {
        return this._selectors.channels$.pipe(map(channels => channels.filter(channel => channel.detailsReceived && channel.isUpgradedChannel)));
    }
    get messagesCollection$() {
        return this._selectors.messagesCollection$;
    }
    get awardMessages$() {
        return this._selectors.awardMessages$;
    }
    get scheduleMessages$() {
        return this._selectors.scheduleMessages$;
    }
    get selectedChat$() {
        return this._selectors.getSelectedChat$();
    }
    get effectiveParentMessage$() {
        return this._selectors.getEffectiveChannelPost$();
    }
    get effectiveSelectedChat$() {
        return this._selectors.getEffectiveSelectedChat$();
    }
    get uiCollection$() {
        return this._selectors.uiCollection$;
    }
    get privileges$() {
        return this._selectors.privileges$;
    }
    getUserByID$(senderID) {
        return this._selectors.getProfileByID$(senderID);
    }
}
DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.UIDispatchers), i0.ɵɵinject(i3.AuthDispatchers), i0.ɵɵinject(i4.MessageDispatchers), i0.ɵɵinject(i5.ChatDispatchers)); }, token: DashboardService, providedIn: "root" });
