<a class="app-logo clickable-cursor" href="https://nandbox.com/en/">
  <img src="./assets/img/logo-w-text.svg" />
</a>

<app-scan
  *ngIf="
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.INIT_SOKET ||
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.AUTH_QR_SET ||
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.AUTH_QR_EXPIRE ||
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.AUTH_LOGIN_BY_EMAIL
  "
  class="auth no-copyable-item"
>
</app-scan>

<!-- Show this component if AUTH_CHATLIST_SET || AUTH_OK -->
<app-auth-list
  *ngIf="
    (authService.authCollection$ | async)?.authStatus ===
      authStatus.AUTH_CHATLIST_SET &&
    (dashboard.allChannels$ | async)?.length > 1
  "
  class="auth no-copyable-item"
>
</app-auth-list>

<!-- Show this component if in init state -->
<div
  *ngIf="
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.INIT_SOKET &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_QR_SET &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_QR_EXPIRE &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_LOGIN_BY_EMAIL &&
    (authService.authCollection$ | async)?.authStatus !==
      authStatus.AUTH_CHATLIST_SET
  "
  id="loader-wrapper"
>
  <div id="loader"></div>
</div>
