<div class="confirm-info">
  <div class="confirm-info-img"></div>
  <div class="confirm-info-body">
    <h2>Drag & Drop the Modules to Install Them!</h2>
    <p>
      Install up to four modules on your home menu and as many modules as you
      want in the side menu!
    </p>
  </div>
  <div class="confirm-info-footer">
    <div class="dont-show">
      <input
        type="checkbox"
        class="check-checkbox"
        id="dontShow"
        (change)="onCheck($event.target.checked)"
      />
      <label for="dontShow">Don't show again</label>
    </div>
    <button (click)="onSave()">Let's Start</button>
  </div>
</div>
