import { Actions } from './paymentProviders.actions';
import * as actions from './paymentProviders.actions';
import { BookingPeriods } from 'src/models/BookingPeriods';
import { PaymentProvider } from 'src/models/PaymentGateways';

const INITIAL_STATE: PaymentProvider[] = [];

export function paymentProvidersReducer(
  state = INITIAL_STATE,
  action: Actions
): PaymentProvider[] {
  switch (action.type) {
    case actions.PaymentProvidersActionTypes.RECEIVE_PAYMENT_PROVIDER: {
      if (
        !state.find(
          paymentProvider => paymentProvider.provider_id === action.providerId
        )
      ) {
        return [
          ...state,
          { provider_id: action.providerId, config: action.config }
        ];
      } else {
        return state.map(provider => {
          if (provider.provider_id === action.providerId) {
            let updatedProvider: PaymentProvider = {};
            updatedProvider = {
              provider_id: action.providerId,
              config: action.config
            };
            return updatedProvider;
          }
          return provider;
        });
      }
    }
    case actions.PaymentProvidersActionTypes.RECEIVE_PAYMENT_PROVIDERS: {
      // console.log('here i am ', action.paymentProviders);
      return action.paymentProviders;
    }
    case actions.PaymentProvidersActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
