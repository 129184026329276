import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { TabTypes } from 'src/models/constants';

@Injectable({ providedIn: 'root' })
export class ComposeMessageService {
  constructor(private _selectors: RootStoreSelectors) {}

  get tabsContainers$(): Observable<LocalContainer[]> {
    return this._selectors.tabsContainers$.pipe(
      map(tabs =>
        tabs.filter(
          tap =>
            tap.type === TabTypes.FEED ||
            tap.type === TabTypes.MEMBERSHIP ||
            tap.type === TabTypes.WALLET
        )
      )
    );
  }

  get selectedChatTags$() {
    return this._selectors.selectedChatTags$.pipe(
      map(tags => {
        if (tags !== null && tags.length > 0) {
          return tags.filter(tag => !tag.isPrivate);
        }
      })
    );
  }

  get selectedChatPrivateTags$() {
    return this._selectors.selectedChatTags$.pipe(
      map(tags => {
        if (tags !== null && tags.length > 0) {
          return tags.filter(tag => tag.isPrivate);
        }
      })
    );
  }

  get subChats$() {
    return this._selectors.subChats$;
  }

  getNewArticle(ref: number) {
    return this._selectors.getNewArticle$(ref);
  }
}
