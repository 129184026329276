<div class="closable-frame">
  <div
    class="header clickable-cursor"
    (click)="closeEvent()"
    *ngIf="showCancel"
  >
    <img src="./assets/img/cancel-key.svg" alt="" />
  </div>

  <div class="content">
    <ng-content select="content"></ng-content>
  </div>
</div>
