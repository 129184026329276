import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { GetInstantPageDetailsRequest } from 'src/models/MyPageConfig';
import { Store } from '@ngrx/store';
import * as isEqual from 'lodash.isequal';
export class InstantPageEffects {
    constructor(actions$, _socketGateway, _store) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this.getDetailsOfInstantPage = this.actions$.pipe(ofType("REQUEST_INSTANT_PAGE_DETAILS" /* REQUEST_INSTANT_PAGE_DETAILS */), withLatestFrom(this._store
            .select(state => state.instantPageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, pages]) => {
            const action = val;
            const selectedPage = pages.find(page => page.id === action.id);
            // console.log('requesting 2', selectedPage);
            if (!selectedPage ||
                (selectedPage &&
                    (!selectedPage.content ||
                        (selectedPage.content &&
                            (!selectedPage.content.container ||
                                selectedPage.content.container.length === 0))))) {
                // console.log('requesting 3');
                this._socketGateway.sendSocketMessage(new GetInstantPageDetailsRequest(action.id));
            }
        }));
        this.receiveInstantPages = this.actions$.pipe(ofType("RECEIVE_INSTANT_PAGES" /* RECEIVE_INSTANT_PAGES */), withLatestFrom(this._store
            .select(state => state.instantPageReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, pages]) => {
            const action = val;
            pages.forEach(page => {
                this._socketGateway.sendSocketMessage(new GetInstantPageDetailsRequest(page.id));
            });
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], InstantPageEffects.prototype, "getDetailsOfInstantPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], InstantPageEffects.prototype, "receiveInstantPages", void 0);
