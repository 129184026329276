import { Injectable } from '@angular/core';

import { HttpService } from '../services/http.service';

import { StorageService } from 'src/app/core/storage.service';

import * as AuthMethods from 'src/models/IAuth';
import {
  CHANNEL_API,
  JSON_CONTENT_TYPE,
  TAC_RESET_PASSWORD_TYPE,
  COUNTRY_CODE_KEY
} from 'src/models/constants';
import { CreateAccount } from 'src/models/IAuth';
import { GetTemplatesByCategory } from 'src/models/AppTemplate';

@Injectable()
export class AuthGateway {
  constructor(
    private _httpService: HttpService,
    private _localStorage: StorageService
  ) {}

  signUpForm(body: AuthMethods.AuthForm) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new AuthMethods.SendGetEmailTac(body.countryIso, body.email)
    );
  }

  getResetPasswordTac(email: string) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new AuthMethods.SendGetEmailTac(
        this._localStorage.getRecord(COUNTRY_CODE_KEY),
        email,
        TAC_RESET_PASSWORD_TYPE
      )
    );
  }

  resetPassword(
    email: string,
    tac: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new AuthMethods.ResetPassword(email, tac, newPassword, confirmNewPassword)
    );
  }

  tacForm(body: AuthMethods.AuthForm, tac: string) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new CreateAccount(
        tac,
        body.email,
        body.password,
        body.appName,
        body.templateID,
        body.name
      )
    );
  }

  loginForm(body: AuthMethods.AuthForm) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new AuthMethods.GetToken(body.email, body.password, body.su)
    );
  }

  getTemplatesByCategory(category?: string, id?: string) {
    return this._httpService.httpPostRequest(
      this._localStorage.getApiUrl(CHANNEL_API),
      JSON_CONTENT_TYPE,
      new GetTemplatesByCategory(category, id)
    );
  }
}
