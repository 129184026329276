import {
  GET_DRIVER_ASSIGNED_METHOD,
  OTHER_TRIPS_METHOD,
  SET_DRIVER_ASSIGNED_METHOD
} from './constants';

export interface DriverTrip {
  text?: string;
  employeeID?: number;
  startDate?: Date;
  endDate?: Date;
  diff?: string;
}

export class GetDriverAssigned {
  readonly method = GET_DRIVER_ASSIGNED_METHOD;
  constructor(
    public trip_id: number,
    public account_id: string,
    public start_day?: Date,
    public end_day?: Date
  ) {}
}

export class SetDriverAssigned {
  readonly method = SET_DRIVER_ASSIGNED_METHOD;
  constructor(
    public trip_id: string,
    public account_id: string,
    public trip_assignes: DriverTrip[]
  ) {}
}

export class GetOtherTrips {
  readonly method = OTHER_TRIPS_METHOD;
  constructor(public trip_id: number, public account_id: string) {}
}
