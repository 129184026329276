<!-- <app-headerless-closable-frame
  table
  (closeNotify)="cancelEvent()"
>
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">{{ title }}</ng-container>
      <form
        *ngIf="!isSingle"
        form-body
        class="tags-table"
        [formGroup]="selectorForm"
        (ngSubmit)="onSubmit()"
      >
        <app-search-form
          [allowSearchBy]="allowSearchBy"
          [searchOptions]="searchOptions"
          [showTooltip]="true"
          [positionTooltipTop]="115"
          [positionTooltipRight]="-110"
          [widthTooltip]="'max-content'"
          [textTooltip]="'Search in Registered Users'"
          (searchByNotify)="onSearchFormSubmit($event)"
        >
        </app-search-form>

        <app-configurable-table
          [isLoading]="false"
          [empty]="users"
          [searchTerm]="searchTerm$ | async"
          [tableService]="tableService"
          [tableColumns]="tableColumns"
          [statusUI]="membersService?.statusUI$ | async"
          [userState]="userStatus.ACTIVE"
          formArrayName="items"
          (nextBtnClicked)="updateSelectedItems()"
          (previousBtnClicked)="updateSelectedItems()"
        >
          <ng-template let-item let-index="index">
            <div class="checked">
              <input
                class="check-checkbox"
                type="checkbox"
                [id]="item?.id"
                [formControlName]="item?.id"
              />
              <label class="check-Label" [for]="item?.id"></label>
            </div>
          </ng-template>
        </app-configurable-table>

        <div class="form-submit">
          <app-border-btn submit [type]="'submit'">
            Add
          </app-border-btn>
        </div>
      </form>
      <form
        *ngIf="isSingle"
        form-body
        class="tags-table"
        [formGroup]="singleSelectorForm"
        (ngSubmit)="onSingleSubmit()"
      >
        <app-search-form
          [allowSearchBy]="allowSearchBy"
          [searchOptions]="searchOptions"
          (searchByNotify)="onSearchFormSubmit($event)"
        >
        </app-search-form>

        <app-configurable-table
          [isLoading]="false"
          [empty]="users"
          [statusUI]="membersService?.statusUI$ | async"
          [userState]="userStatus.ACTIVE"
          [searchTerm]="searchTerm$ | async"
          [tableService]="tableService"
          [tableColumns]="tableColumns"
          (nextBtnClicked)="updateSelectedItems()"
          (previousBtnClicked)="updateSelectedItems()"
        >
          <mat-radio-group formControlName="singleItem">
            <ng-template let-item let-index="index">
              <app-reactive-radio-input [radioValue]="item?.id">
              </app-reactive-radio-input>
            </ng-template>
          </mat-radio-group>
        </app-configurable-table>

        <div class="form-submit">
          <app-border-btn submit [type]="'submit'">
            Add
          </app-border-btn>
        </div>
      </form>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame> -->

<app-popup-setting-frame
  [title]="title"
  [hideFooter]="true"
  (closeNotifier)="cancelEvent()"
>
  <form
    *ngIf="!isSingle"
    form-body
    class="tags-table"
    [formGroup]="selectorForm"
    (ngSubmit)="onSubmit()"
  >
    <app-search-form
      [allowSearchBy]="allowSearchBy"
      [searchOptions]="searchOptions"
      [showTooltip]="true"
      [positionTooltipTop]="115"
      [positionTooltipRight]="-110"
      [widthTooltip]="'max-content'"
      [textTooltip]="'Search in Registered Users'"
      (searchByNotify)="onSearchFormSubmit($event)"
    >
    </app-search-form>

    <app-configurable-table
      [isLoading]="false"
      [empty]="users"
      [searchTerm]="searchTerm$ | async"
      [tableService]="tableService"
      [tableColumns]="tableColumns"
      [statusUI]="membersService?.statusUI$ | async"
      [userState]="userStatus.ACTIVE"
      formArrayName="items"
      [list]="membersService?.list$ | async"
      [mapRoles]="mapRoles"
      [accountTypes]="accountTypes"
      (nextBtnClicked)="updateSelectedItems()"
      (previousBtnClicked)="updateSelectedItems()"
    >
      <ng-template let-item let-index="index">
        <div class="dont-show">
          <input
            type="checkbox"
            class="check-checkbox"
            [id]="item?.id"
            [formControlName]="item?.id"
            [checked]="isMemberSelected(item?.id)"
          />
          <label [for]="item?.id"></label>
        </div>
      </ng-template>
    </app-configurable-table>

    <div class="form-submit" style="padding-bottom: 15px;">
      <app-border-btn next [type]="'submit'">
        Add
      </app-border-btn>
      <!-- <button type="submit">Add</button> -->
    </div>
  </form>
  <form
    *ngIf="isSingle"
    form-body
    class="tags-table"
    [formGroup]="singleSelectorForm"
    (ngSubmit)="onSingleSubmit()"
  >
    <app-search-form
      [allowSearchBy]="allowSearchBy"
      [searchOptions]="searchOptions"
      (searchByNotify)="onSearchFormSubmit($event)"
    >
    </app-search-form>

    <app-configurable-table
      [isLoading]="false"
      [empty]="users"
      [statusUI]="membersService?.statusUI$ | async"
      [userState]="userStatus.ACTIVE"
      [mapRoles]="mapRoles"
      [accountTypes]="accountTypes"
      [searchTerm]="searchTerm$ | async"
      [tableService]="tableService"
      [tableColumns]="tableColumns"
      [list]="membersService?.list$ | async"
      (nextBtnClicked)="updateSelectedItems()"
      (previousBtnClicked)="updateSelectedItems()"
    >
      <!-- <mat-radio-group formControlName="singleItem">
        <ng-template let-item let-index="index">
          <app-reactive-radio-input [radioValue]="item?.id">
          </app-reactive-radio-input>
        </ng-template>
      </mat-radio-group> -->
      <ng-template let-item let-index="index">
        <div class="inputGroup">
          <input
            [id]="item?.id"
            name="singleItem"
            type="radio"
            [value]="item?.id"
            formControlName="singleItem"
            [checked]="item?.id === selectedMembers[0]"
          />
          <label class="radio-but" [for]="item?.id"></label>
        </div>
      </ng-template>
    </app-configurable-table>

    <div class="form-submit" style="padding-bottom: 15px;">
      <app-border-btn next [type]="'submit'">
        Add
      </app-border-btn>
      <!-- <button type="submit">Add</button> -->
    </div>
  </form>
</app-popup-setting-frame>
