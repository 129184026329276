import { Component, OnInit } from '@angular/core';

import { AuthService } from '../core/auth.service';
import { WidgetService } from '../core/widget.service';
import { AnalyticsService } from '../core/analytics.service';
import { SocketHandlerService } from '../network/services/socket-merchant.service';

import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UIDispatchers } from './../store/ui/ui.dispatchers';
import { UISections } from 'src/models/constants';
import { Router } from '@angular/router';
@Component({
  selector: 'app-app',
  template: `
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
  /**
   * Required to inject SocketHandlerService not used but the constructor of SocketHandlerService
   * will do connection logic **Don't remove this import**
   */

  UISections = UISections;
  authSubscribtion = null;

  constructor(
    private _socketHandlerService: SocketHandlerService,
    private _authService: AuthService,
    private _analyticsService: AnalyticsService,
    private _widget: WidgetService,
    public translate: TranslateService,
    public _uiDispatchers: UIDispatchers
  ) {
    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang('en');

    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('locale')) {
      this.translate.use(localStorage.getItem('locale'));
    } else {
      this.translate.use('en');
    }

    this._authService.getCountryISO();
    if (environment.production) {
      this._analyticsService.init();
    }
  }
}
