<div [class.color-picker1]="!isInline" [class.color-picker2]="isInline">
  <label [for]="colorId" class="control-label">{{ colorLabel }}</label>
  <div
    class="color-picker1-input"
    [colorPicker]="colorSelected"
    [style.background]="colorSelected"
    [cpAlphaChannel]="'disabled'"
    [cpOutputFormat]="'hex'"
    [cpPosition]="'right'"
    (colorPickerChange)="onChangeColor($event)"
  ></div>
</div>
