<ng-container
  *ngIf="!(screenSize?.mobileQuerySmall | async)?.matches && isChrome"
>
  <div
    *ngIf="uiService.uiCollection$ | async as uiCollection"
    class="dashboard-layout"
  >
    <!-- Show Multi Login -->
    <ng-container
      *ngIf="
        (authService.authCollection$ | async)?.authStatus ===
        authStatus.MULTI_LOGIN
      "
    >
      <div class="call-to-action">
        <div class="call-to-action-inner">
          <div class="call">
            {{ dashboardService.selectedChatName$ | async }} is open in another
            Window. Click "Use Here" to use
            {{ dashboardService.selectedChatName$ | async }}
            in this window.
          </div>

          <div class="action">
            <app-border-btn submit (click)="useHereEvent()">
              Use Here
            </app-border-btn>
            <app-border-btn back (click)="closeWindowEvent()">
              Close
            </app-border-btn>
          </div>
        </div>
      </div>
    </ng-container>
    <!---------------------------------------------------------------------------->

    <!-- Dashboard -->
    <ng-container
      *ngIf="
        (authService.authCollection$ | async)?.authStatus ===
        authStatus.AUTH_CHAT_SELECTED
      "
    >
      <app-layout
        *ngIf="authService.authCollection$ | async as auth"
        [mode]="auth?.mode"
        [role]="auth?.role"
      >
        <ng-container ngProjectAs="main-layout-route">
          <!-- breadcrumbs component only for schedule -->
          <app-breadcrumbs
            *ngIf="uiCollection?.section === uiSections.SCHEDULE"
            class="breadcrumbs"
            [direction]="uiCollection?.section"
            (selectNotify)="selectEvent($event)"
            (filterDateNotify)="filterDateEvent($event)"
            (paginationNotify)="paginationEvent($event)"
          >
          </app-breadcrumbs>

          <div class="messages">
            <!-- offline message -->
            <div
              *ngIf="!(appServer.online$ | async)"
              class="offline-message"
              [@slideToDown]="!(appServer.online$ | async)"
            >
              <i class="fas fa-wifi" aria-hidden="true"></i>
              Check your internet connection and try again. We couldn’t connect
              to the channel
            </div>
            <!-- end offline message -->

            <div class="page-padding off-padding">
              <router-outlet></router-outlet>
            </div>
            <app-media-full-screen
              *ngIf="(uiService.uiCollection$ | async)?.showMediaScreen"
              [selectedChat]="uiService?.selectedChat$ | async"
              [message]="uiService?.effectiveMediaMessage$ | async"
              [currentMediaMessages]="uiService?.chatMediaMessages$ | async"
            >
            </app-media-full-screen>
          </div>
        </ng-container>
        <!-- <ng-container
          ngProjectAs="main-layout-route2"
          *ngIf="
            (authService.authCollection$ | async)?.mode == 0 ||
            (authService.authCollection$ | async)?.mode == 1
          "
        >
          <!-- breadcrumbs component only for schedule -->
        <!-- <app-breadcrumbs
            *ngIf="uiCollection?.section === uiSections.SCHEDULE"
            class="breadcrumbs"
            [direction]="uiCollection?.section"
            (selectNotify)="selectEvent($event)"
            (filterDateNotify)="filterDateEvent($event)"
            (paginationNotify)="paginationEvent($event)"
          >
          </app-breadcrumbs>

          <div class="messages">

            <div
              *ngIf="!(appServer.online$ | async)"
              class="offline-message"
              [@slideToDown]="!(appServer.online$ | async)"
            >
              <i class="fas fa-wifi" aria-hidden="true"></i>
              Check your internet connection and try again. We couldn’t connect
              to the channel
            </div>


            <div class="page-padding off-padding">
              <router-outlet></router-outlet>
            </div>
            <app-media-full-screen
              *ngIf="(uiService.uiCollection$ | async)?.showMediaScreen"
              [selectedChat]="uiService?.selectedChat$ | async"
              [message]="uiService?.effectiveMediaMessage$ | async"
              [currentMediaMessages]="uiService?.chatMediaMessages$ | async"
            >
            </app-media-full-screen>
          </div> -->
        <!-- </ng-container>   -->
        <ng-container ngProjectAs="main-layout-side">
          <app-messages-admin
            *ngIf="
              uiCollection?.selectedMessage || uiCollection?.selectedTalkToAdmin
            "
            class="talktoadmin"
          >
          </app-messages-admin>
        </ng-container>
      </app-layout>

      <!-- Start: Sections overlay components ( on-top of another components ) -->
      <!-- Compose Area -->
      <app-modal-container
        *ngIf="uiService.showCompose"
        (cancelNotfiy)="uiService.closeComposeEvent()"
      >
        <app-compose-message-modal
          *ngIf="!uiService.isComposeToGroup"
          [messageSendingType]="uiService.messageSendingType$ | async"
          [selectedChat]="uiService.selectedChat$ | async"
          [chatAdminName]="dashboardService.chatAdminName"
          (cancelNotfiy)="uiService.closeComposeEvent()"
        >
        </app-compose-message-modal>
        <app-compose-message-modal
          *ngIf="uiService.isComposeToGroup"
          [isSubChatSelected]="true"
          [messageSendingType]="uiService.messageSendingType$ | async"
          [selectedChat]="uiService.effectiveSubSelectedChat$ | async"
          [chatAdminName]="dashboardService.chatAdminName"
          (cancelNotfiy)="uiService.closeComposeEvent()"
        >
        </app-compose-message-modal>
      </app-modal-container>
      <!-- end compose section -->

      <!-- stats section -->
      <app-modal-container
        *ngIf="uiCollection?.selectedMessageForStats"
        (cancelNotfiy)="backFromGroupReplies()"
      >
        <app-menu-stats
          [messageStats]="
            uiService?.getMessageStats$(
              uiCollection?.selectedMessageForStats?.message_id
            ) | async
          "
          (closeNotfify)="backFromGroupReplies()"
        >
        </app-menu-stats>
      </app-modal-container>
      <!-- End: compose section -->

      <!-- Popup message -->

      <app-modal-container
        *ngIf="uiCollection?.showPopup"
        (cancelNotify)="uiService.hidePopup()"
      >
        <app-popup-notifier
          [title]="uiCollection?.popupTitle"
          [message]="uiCollection?.popupMessage"
          (hidePopupNotify)="uiService.hidePopup()"
        >
        </app-popup-notifier>
      </app-modal-container>
      <!-- end popup message -->
      <ng-container *ngIf="uiCollection?.showErrorPopup">
        <app-snackbaar-error-notifier
          [message]="uiCollection?.errorPopupMessage"
          (hidePopupNotify)="uiService.hideErrorSnackbar()"
        ></app-snackbaar-error-notifier>
      </ng-container>
      <!-- End: Sections Overlay -->
    </ng-container>
    <!---------------------------------------------------------------------------->

    <!-- Loading screen -->
    <ng-container
      *ngIf="
        (authService.authCollection$ | async)?.authStatus !==
          authStatus.AUTH_CHAT_SELECTED &&
        (authService.authCollection$ | async)?.authStatus !==
          authStatus.MULTI_LOGIN
      "
    >
      <div id="loader-wrapper">
        <div id="loader"></div>
      </div>
    </ng-container>
    <!---------------------------------------------------------------------------->
  </div>
</ng-container>

<ng-container
  *ngIf="(screenSize?.mobileQuerySmall | async)?.matches || !isChrome"
>
  <app-no-support></app-no-support>
</ng-container>
