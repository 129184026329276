import * as ChatActions from './chat.actions';
import { ChatMiddleware } from './chat.middleware';

import { IChat } from 'src/models/IChat';
import { CHANNEL, PROJECT_NAME, MERCHANT_PROJECT } from 'src/models/constants';

const descendingly = (first: IChat, second: IChat) => {
  const firstDate = first.createdDate ? first.createdDate : 0;
  const secondDate = second.createdDate ? second.createdDate : 0;
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  }
  return 0;
};

const updateChat = (local: IChat, remote: IChat) => {
  let updatedChat: IChat = {};
  if (local.version !== remote.version) {
    updatedChat = { ...local, ...remote };
  }
  updatedChat = { ...local };

  updatedChat.isUpgradedChannel = true;
  if (updatedChat.parent_id) {
    updatedChat.subChannel = true;
  } else {
    updatedChat.isChannelApp = true;
  }
  return updatedChat;
};

export function chatReducer(
  state: IChat[] = [],
  action: ChatActions.ChatActions
): IChat[] {
  switch (action.type) {
    case ChatActions.ChatActionTypes.CHAT_LIST_RECEIVED:
      if (PROJECT_NAME === MERCHANT_PROJECT) {
        return action.payload.map(chat => {
          const chatExist = state.find(cht => cht.id === chat.id);
          if (chatExist) {
            // console.log('here is the chat =>', chatExist);
            return updateChat(chatExist, chat);
          }

          let updatedChat: IChat = {};
          updatedChat = { ...chat };
          updatedChat.isUpgradedChannel = true;
          if (chat.parent_id) {
            updatedChat.subChannel = true;
          } else {
            updatedChat.isChannelApp = true;
          }
          return updatedChat;
        });
      } else {
        return action.payload;
      }
    case ChatActions.ChatActionTypes.SUB_CHAT_LIST_RECEIVED: {
      const rcvdList = action.payload;
      const newList = rcvdList.filter(chat => {
        if (!state.find(exitingChat => exitingChat.id === chat.id)) {
          return chat;
        }
      });
      const updatedState = state.map(chat => {
        if (
          rcvdList.find(rcvdChat => rcvdChat.id === chat.id) &&
          action.listType === CHANNEL
        ) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat };
          updatedChat.subChannel = true;
          return updatedChat;
        }
        return chat;
      });
      if (newList) {
        return [...updatedState, ...newList];
      } else {
        return [...updatedState];
      }
    }
    case ChatActions.ChatActionTypes.CHAT_DETAILS_RECEIVED:
      if (!state.find(chat => chat.id === action.payload.id)) {
        const newChat: IChat = action.payload;
        return [...state, newChat];
      }
      return state
        .map(chat => {
          if (chat.id === action.payload.id) {
            let updatedChat: IChat = {};
            updatedChat = {
              ...chat,
              ...action.payload,
              admin: chat.admin,
              subChannel:
                action.payload.type === CHANNEL &&
                (chat.subChannel || action.payload.parent_id)
                  ? true
                  : false,
              isChannelApp:
                action.payload.type === CHANNEL &&
                (chat.isChannelApp || !action.payload.parent_id)
                  ? true
                  : false,
              isUpgradedChannel: chat.isUpgradedChannel,
              welcomeMessageId: action.payload.welcome_message
                ? action.payload.welcome_message.message.message_id
                : null
            };
            if (chat.backgroundColor) {
              updatedChat.backgroundColor = chat.backgroundColor;
            }
            updatedChat.detailsReceived = true;
            return updatedChat;
          }

          return chat;
        })
        .sort(descendingly);
    case ChatActions.ChatActionTypes.CHAT_DETAILS_FROM_CACHE:
      return state
        .map(chat => {
          if (chat.id === action.payload.id) {
            let updatedChat: IChat = {};
            updatedChat = {
              ...chat,
              ...action.payload,
              admin: chat.admin,
              subChannel:
                action.payload.type === CHANNEL &&
                (chat.subChannel || action.payload.parent_id)
                  ? true
                  : false,
              isChannelApp:
                action.payload.type === CHANNEL &&
                (chat.isChannelApp || !action.payload.parent_id)
                  ? true
                  : false,
              isUpgradedChannel: chat.isUpgradedChannel,
              welcomeMessageId: action.payload.welcome_message
                ? action.payload.welcome_message.message.message_id
                : null
            };
            if (chat.backgroundColor) {
              updatedChat.backgroundColor = chat.backgroundColor;
            }
            updatedChat.detailsReceived = true;
            return updatedChat;
          }
          return chat;
        })
        .sort(descendingly);
    case ChatActions.ChatActionTypes.CHAT_MEMBER_COUNT_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.payload.id) {
          let updatedChat: IChat = {};
          const memberCount = action.payload.member_count;
          updatedChat = { ...chat, member_count: memberCount };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.TAGS_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.payload.id) {
          let updatedChat: IChat = {};
          const tags = action.payload.tagsDefinition
            ? action.payload.tagsDefinition
            : [];
          updatedChat = { ...chat, tagsDefinition: tags };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.ROLES_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.payload.id) {
          let updatedChat: IChat = {};
          const roles = action.payload.chatRoles
            ? action.payload.chatRoles
            : [];
          updatedChat = { ...chat, chatRoles: roles };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.LOGGED_IN_LEFT_CHAT:
      return state.map(chat => {
        if (chat.id === action.payload.chat_id) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat, admin: false };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.THUMBNAIL_DOWNLOAD:
      return state.map(chat => {
        if (chat.id === action.payload.id) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat, ...action.payload };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.CHAT_IMAGE_DOWNLOADED:
      return state.map(chat => {
        if (chat.id === action.payload.id) {
          const updatedChat: IChat = {};
          updatedChat.localMedia = action.payload.localMedia;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.DESELECT_CHAT:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: IChat = {};
          updatedChat.lastTypedMessage = action.lastTypedMessage;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.CHAT_DELETED:
      return state.filter(chat => chat.id !== action.chat_id);
    case ChatActions.ChatActionTypes.REQUEST_CHAT_HISTORY:
      return state.map(chat => {
        if (chat.id === action.payload) {
          const updatedChat: IChat = {};
          updatedChat.isHistoryRequested = true;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.NEW_CHAT_RECEIVED:
      if (!state.find(chat => chat.id === action.payload.id)) {
        const newChat: IChat = {};
        newChat.id = action.payload.id;
        newChat.admin = action.payload.admin;
        return ChatMiddleware.addNewChat(state, newChat).sort(descendingly);
      } else {
        return state;
      }
    case ChatActions.ChatActionTypes.CHAT_ADMINS_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.chatID) {
          const updatedChat: IChat = {};
          updatedChat.admins = action.admins;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.CONFIG_QR_CODE_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.chatID) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat, configQrCode: action.configQrCode };
          return updatedChat;
        }
        return chat;
      });
    case ChatActions.ChatActionTypes.UPDATE_CHAT:
      return state.map(chat => {
        console.log('action =>', action.chatDetails);
        if (chat.id === action.chatDetails.id) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat, ...action.chatDetails };
          console.log('updatedChat =>', updatedChat);
          return updatedChat;
        }
        return chat;
      });

    case ChatActions.ChatActionTypes.UPDATE_STORE:
      return state.map(chat => {
        console.log('action =>', action.chatDetails);
        if (chat.id === action.chatDetails.id) {
          let updatedChat: IChat = {};
          updatedChat = { ...chat, ...action.chatDetails };
          console.log('updatedChat =>', updatedChat);
          return updatedChat;
        }
        return chat;
      });

    case ChatActions.ChatActionTypes.SENDING_WELCOME_MESSAGE:
      return state
        .map(chat => {
          if (chat.id === action.payload.receiver_id) {
            let updatedChat: IChat = {};
            updatedChat = {
              ...chat,
              welcomeMessageId: action.payload.reference
                ? String(action.payload.reference)
                : null
            };
            return updatedChat;
          }
          return chat;
        })
        .sort(descendingly);
    case ChatActions.DESELECT_CHANNEL:
      return state.filter(
        chat => chat.type === CHANNEL && chat.isUpgradedChannel
      );
    case ChatActions.RESET:
      return [];
    default:
      return state;
  }
}
