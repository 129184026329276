import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { SELECT_ALL, SELECT_NONE, UISections } from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  uiSections: typeof UISections = UISections;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  scheduleDate: Date = new Date();
  minDate: Date = new Date();
  filterScheduleDate: Date;

  @Input() direction: string;

  @Output() selectNotify = new EventEmitter<string>();
  @Output() filterDateNotify = new EventEmitter<Date>();
  @Output() paginationNotify = new EventEmitter<string>();

  constructor(public layoutService: LayoutService) {}

  selectEvent($event: any): void {
    this.selectNotify.emit($event);
  }
  filterDateChanged($event: Date) {
    this.filterScheduleDate = $event;
    this.filterDateNotify.emit($event);
  }
  checkBoxChanged($event: any): void {
    const select = $event.target.checked ? SELECT_ALL : SELECT_NONE;
    $event.stopPropagation();
    this.selectNotify.emit(select);
  }
  paginationEvent(value: string): void {
    this.paginationNotify.emit(value);
  }
  resetFilter() {
    this.filterScheduleDate = null;
    this.filterDateNotify.emit(null);
  }
}
