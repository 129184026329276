import { Store } from '@ngrx/store';
import * as SubGroupUIActions from './subGroupsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SubGroupUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listSubGroupUI() {
        this._store.dispatch(new SubGroupUIActions.ListSubGroupUI());
    }
    createSubGroupUI() {
        this._store.dispatch(new SubGroupUIActions.CreateSubGroupUI());
    }
    viewSubGroupUI(selectedSubGroup) {
        this._store.dispatch(new SubGroupUIActions.ViewSubGroupUI(selectedSubGroup));
    }
    editSubGroupUI(selectedSubGroup) {
        this._store.dispatch(new SubGroupUIActions.EditSubGroupUI(selectedSubGroup));
    }
    uploadSubGroupImage(selectedFile) {
        this._store.dispatch(new SubGroupUIActions.UploadSubGroupImage(selectedFile));
    }
    subGroupPhotoUploadUI(createdSubGroup) {
        this._store.dispatch(new SubGroupUIActions.SubGroupPhotoUploadUI(createdSubGroup));
    }
    subGroupCreationComplete() {
        this._store.dispatch(new SubGroupUIActions.SubGroupCreationComplete());
    }
    closeViewSubGroupUI() {
        this._store.dispatch(new SubGroupUIActions.CloseViewSubGroupUI());
    }
    closeEditSubGroupUI() {
        this._store.dispatch(new SubGroupUIActions.CloseEditSubGroupUI());
    }
    // paging dispatchers
    getNextSubGroupPage() {
        this._store.dispatch(new SubGroupUIActions.GetNextSubGroupPage());
    }
    getPrevSubGroupPage() {
        this._store.dispatch(new SubGroupUIActions.GetPrevSubGroupPage());
    }
    receivedSubGroupHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new SubGroupUIActions.ReceivedSubGroupHistoryChunk(eop, sop, currentPage));
    }
    resetSubGroupPaging() {
        this._store.dispatch(new SubGroupUIActions.ResetSubGroupPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new SubGroupUIActions.SetStatusAsIdle());
    }
}
SubGroupUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubGroupUiDispatchers_Factory() { return new SubGroupUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: SubGroupUiDispatchers, providedIn: "root" });
