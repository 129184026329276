import { Actions } from './item.actions';
import * as actions from './item.actions';
import { LocalItem } from 'src/models/ChannelAppLocalConfig';

const INITIAL_STATE = [];

export function itemReducer(
  state: LocalItem[] = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.ItemActionTypes.APP_CONFIG_DATA_CHANGED: {
      return [
        ...action.onlineAppConfig.items,
        ...action.offlineAppConfig.items
      ];
    }
    case actions.ItemActionTypes.UPDATE_ITEM: {
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = { ...item, ...action.itemUpdatedProps };
          return updatedItem;
        }
        return item;
      });
    }
    case actions.ItemActionTypes.UPDATE_ITEM_LINK: {
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = {
            ...item,
            ...{ link: action.itemLink }
          };
          // updatedItem.limit = null;
          // updatedItem.map = null;
          // updatedItem.map_menu_id = null;
          // updatedItem.options = '0';
          // updatedItem.google_sync = 0;
          // updatedItem.view_style = 0;
          // updatedItem.chat_id = null;
          // updatedItem.sub_type = null;
          // updatedItem.video_url = null;
          // updatedItem.message = null;
          // updatedItem.nearby = null;
          // updatedItem.radius = null;
          // updatedItem.button = [];
          return updatedItem;
        }
        return item;
      });
    }
    case actions.ItemActionTypes.REORDER_ITEM: {
      const targetItem = state.find(item => item.ref === action.itemRef);
      const oldPosition = targetItem ? targetItem.order : null;
      const parentRef = targetItem ? targetItem.parentComponentRef : null;
      return state.map(item => {
        if (item.ref === action.itemRef) {
          let updatedItem: LocalItem = {};
          updatedItem = { ...item, order: action.newPosition };
          return updatedItem;
        } else if (
          item.parentComponentRef === parentRef &&
          oldPosition !== null &&
          item.order >= 0
        ) {
          if (action.newPosition < oldPosition && item.order < oldPosition) {
            // MOVING_UP
            let updatedItem: LocalItem = {};
            updatedItem = { ...item, order: item.order + 1 };
            return updatedItem;
          } else if (
            action.newPosition > oldPosition &&
            item.order <= action.newPosition
          ) {
            // MOVING_DOWN
            let updatedItem: LocalItem = {};
            updatedItem = { ...item, order: item.order - 1 };
            return updatedItem;
          }
        }
        return item;
      });
    }
    case actions.ItemActionTypes.DELETE_ITEM: {
      const orderOfDeletedItem = action.item.order;
      const parentRef = action.item.parentComponentRef;
      const remainingList = state.filter(item => item.ref !== action.item.ref);
      if (remainingList) {
        return remainingList.map(item => {
          if (
            item.parentComponentRef === parentRef &&
            (orderOfDeletedItem !== null && orderOfDeletedItem !== undefined) &&
            item.order >= 0
          ) {
            if (item.order > orderOfDeletedItem) {
              let updatedItem: LocalItem = {};
              updatedItem = { ...item, order: item.order - 1 };
              return updatedItem;
            }
          }
          return item;
        });
      } else {
        return INITIAL_STATE;
      }
    }
    case actions.ItemActionTypes.ADD_ITEM: {
      return [action.item, ...state];
    }
    case actions.ItemActionTypes.DELETE_ITEMS_OF_COMPONENT: {
      return state.filter(
        item => item.parentComponentRef !== action.component.ref
      );
    }
    case actions.ItemActionTypes.ADD_ITEMS: {
      return [...action.items, ...state];
    }
    case actions.ItemActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
