<div class="drp-list" *ngFor="let item of langs" (click)="showMenu()">
  <div class="title" *ngIf="item.key === selectedLang">
    <div class="lang">
      <div class="cat-icons">
        <img [src]="item.img" />
      </div>
      <span>{{ item.value }}</span>
    </div>
    <img src="./assets/img/arrow.png" class="arrow" />
  </div>
</div>

<div class="list" *ngIf="showLangMenu">
  <div
    class="list-item"
    *ngFor="let item of langs"
    [class.active]="item.key === selectedLang"
  >
    <div class="title" (click)="changeBrowserLang(item.key)">
      <div class="lang">
        <div class="cat-icons">
          <img [src]="item.img" />
        </div>
        <span>{{ item.value }}</span>
      </div>
      <img
        src="./assets/img/selectedSection.png"
        class="arrow"
        *ngIf="item.key === selectedLang"
      />
    </div>
  </div>
</div>
