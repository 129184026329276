import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { BotMenu, BotMenuButton } from 'src/models/IBotMenu';
import { MENU_BOTTON_STYLE } from 'src/models/constants';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { FeaturesSublistComponent } from '../../app-mgmt/custom/dragable-features/features-sublist/features-sublist.component';
import { BotsService } from '../../bots/bots.service';
import { AppMgmtService } from '../../app-mgmt/appMgmt.service';
import { OnDestroy } from '@angular/core';
import { LayoutService } from 'src/app/+merchant/+layout/layout.service';

@Component({
  selector: 'app-menu-holder',
  templateUrl: './menu-holder.component.html',
  styleUrls: ['./menu-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuHolderComponent implements OnChanges, OnInit, OnDestroy {
  @Input() menus: BotMenu;
  @Input() messageDetails: any;
  @Input() config = false;
  @Input() showRemove = false;
  @Input() bot = false;
  @Input() tabsList: LocalContainer[];
  rowStyles = MENU_BOTTON_STYLE;
  @Output() createButtonNotifiy = new EventEmitter<number>();
  @Output() readButtonNotifiy = new EventEmitter<{
    row: number;
    col: number;
  }>();
  @Output() deleteButtonNotifiy = new EventEmitter<{
    row: number;
    col: number;
  }>();
  @Output() deleteRowNotifiy = new EventEmitter<number>();
  @Output() dropEmitter = new EventEmitter<any>();
  @Input() mobView = false;
  feature: any;
  selectedFeature: any;
  @Input() ui_module_id: any;
  @Input() menu_ref: any;
  @Output() showSettingsNotify = new EventEmitter<LocalContainer>();
  @Input() showSettings: boolean;
  @Output() subMenuSelectNotify = new EventEmitter<any>();
  @Output() buttonClickedNotify = new EventEmitter<any>();
  menusSubscribe = null;
  getFeature = new FeaturesSublistComponent(
    this._ref,
    this.appManagementService,
    this.botService,
    this.layoutService
  );
  constructor(
    private _ref: ChangeDetectorRef,
    public appManagementService: AppMgmtService,
    public botService: BotsService,
    public layoutService: LayoutService
  ) {}
  ngOnDestroy() {
    if (this.menusSubscribe !== null) {
      this.menusSubscribe.unsubscribe();
    }
  }
  ngOnInit() {
    // console.log('bot => ', this.bot);
  }
  ngOnChanges() {
    if (this.mobView) {
      this._ref.detectChanges();
    }
  }

  getButtonStyle(button: BotMenuButton) {
    return {
      'background-color': button.button_bgcolor
        ? button.button_bgcolor
        : '#e7ebf8',
      color: button.button_textcolor ? button.button_textcolor : '#000000'
    };
  }

  createButton(pos: number) {
    this.createButtonNotifiy.emit(pos);
  }

  readButton(pos: { row: number; col: number }) {
    // console.log('here clicked button', pos);
    this.readButtonNotifiy.emit(pos);
  }

  deleteButton(pos: { row: number; col: number }) {
    this.deleteButtonNotifiy.emit(pos);
  }

  deleteRow(pos: number) {
    this.deleteRowNotifiy.emit(pos);
  }

  getFeatureModule(value) {
    return (this.feature = this.getFeature.getModuleData(value));
  }
  onDrop(event, buttonId) {
    let data = event.dataTransfer.getData('text');
    data = data + ',' + buttonId;

    this.dropEmitter.emit(data);
  }
  getTabContainer(menuCol) {
    const container: LocalContainer = this.tabsList.find(
      res => res.id === menuCol.button_id
    );
    return container;
  }

  get selectItem() {
    return './assets/svg/app-features-icons.svg#' + this.feature.icon;
  }
  get selectItem2() {
    if (this.selectedFeature && this.selectedFeature.icon) {
      return './assets/svg/app-features-icons.svg#' + this.selectedFeature.icon;
    } else {
      return '';
    }
  }
  showSetting(container) {
    this.showSettings = true;
    this.selectedFeature = this.getFeature.getModuleData(
      container.ui_module_id
    );

    this.appManagementService.selectTab(container);
    this.showSettingsNotify.emit(container);
  }

  deleteContainer(container) {
    const newContainer: LocalContainer = {};
    newContainer.type = '';
    newContainer.ui_module_id = null;
    this.appManagementService.updateContainer(container.ref, newContainer);
  }

  getBck(value) {
    return {
      'background-image': 'url(' + value.button_img_url + ')',
      'background-size': '200% 100%'
    };
  }

  subMenuSelect(id) {
    this.subMenuSelectNotify.emit(id);
  }

  buttonClicked(menuCol) {
    if (this.bot && this.messageDetails && menuCol.button_callback) {
      const buttonData: any = {};
      buttonData.message_id = this.messageDetails.message_id;
      buttonData.menu_ref = this.menu_ref;
      buttonData.button_label = menuCol.button_label;
      buttonData.button_callback = menuCol.button_callback;
      buttonData.reference = this.messageDetails.reference;
      this.buttonClickedNotify.emit(buttonData);
    }
  }

  getSubMenuTitle(id) {
    let subMenuTitle = '';

    this.menusSubscribe = this.appManagementService.menusAppClass$.subscribe(
      res => {
        if (res && res.menus) {
          res.menus.forEach(r => {
            if (r.menu_id === id) {
              subMenuTitle = r.menu_name;
            }
          });
        }
      }
    );
    return subMenuTitle;
  }
}
