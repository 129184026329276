import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { mStoreScreens, MarketTypes, ChatTypes } from 'src/models/constants';
import { StoreUiDispatchers } from 'src/app/store/storesUI/storeUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/storesUI/storeUI.dispatchers";
export class MobileStoresService {
    constructor(_rootStore, _storeDispatchers) {
        this._rootStore = _rootStore;
        this._storeDispatchers = _storeDispatchers;
        this._selectedStore = new BehaviorSubject(null);
        this.selectedStore$ = this._selectedStore.asObservable();
        this._currentStoreScreen = new BehaviorSubject(mStoreScreens.mStore_LIST);
        this.currentStoreScreen$ = this._currentStoreScreen.asObservable();
        this.marketTypes = MarketTypes;
        this.chatTypes = ChatTypes;
        this.confirmMstor = false;
    }
    get stores$() {
        return this._rootStore.getStores$();
    }
    getMStoreById(id) {
        this._storeDispatchers.getStore(id);
    }
    resetPaging(selectedChatId = null) {
        this._storeDispatchers.setStoreSelectedChat(selectedChatId);
    }
    getProduct$(id) {
        return this._rootStore.getProductById$(id);
    }
    getBundle$(id) {
        return this._rootStore.getBundle$(id);
    }
    getPackage$(id) {
        return this._rootStore.getPackageById$(id);
    }
    isBundle(prod) {
        return prod && prod.type === this.marketTypes.BUNDLE;
    }
    isPackage(prod) {
        return prod && prod.type === this.marketTypes.PACKAGE;
    }
    isProduct(prod) {
        return prod && prod.type === this.marketTypes.PRODUCT;
    }
    updateStore(store) {
        if (store && store.id) {
            this._storeDispatchers.setStore(store.id, store);
        }
        else {
            this._storeDispatchers.createStore(store);
        }
        this.closeItemCardEvent();
    }
    setStoreItem(storeId, items) {
        const itemsIds = items.map(item => {
            return {
                id: item.id,
                type: item.type
            };
        });
        this._storeDispatchers.addStoreItem(storeId, itemsIds);
    }
    deleteMStore(id) {
        this._storeDispatchers.deleteStoreMobile(id);
    }
    toggleDeleteConfirmMstore(e, mStore) {
        this.confirmMstor = e;
        this.selectedMstoreItem = mStore;
    }
    removeStoreItem(storeId, items) {
        this._storeDispatchers.removeStoreItem(storeId, items);
    }
    assignStoreChannel(storeId, chatIds) {
        this._storeDispatchers.assignStoreChannel(storeId, chatIds);
    }
    createItemEvent() {
        this.setCurrentScreen(mStoreScreens.mStore_CREATION);
        this.setSelectedStore(null);
    }
    readItemEvent(store) {
        this.setCurrentScreen(mStoreScreens.mStore_VIEW);
        this.setSelectedStore(store);
    }
    updateItemEvent(store) {
        this.setCurrentScreen(mStoreScreens.mStore_EDIT);
        this.setSelectedStore(store);
    }
    closeItemCardEvent() {
        this.setCurrentScreen(mStoreScreens.mStore_LIST);
        this.setSelectedStore(null);
    }
    setSelectedStore(store) {
        this._selectedStore.next(store);
    }
    setCurrentScreen(screen) {
        this._currentStoreScreen.next(screen);
    }
    getNextPage() {
        this._storeDispatchers.getNextStorePage();
    }
    getPreviousPage() {
        this._storeDispatchers.getPrevStorePage();
    }
    getGroup$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
    isGroup(prod) {
        return (prod &&
            (prod.type === this.chatTypes.GROUP ||
                prod.type === this.chatTypes.CHANNEL));
    }
    getMStoreDetailsById(id) {
        return this._rootStore.getMStoreByID$(id);
    }
    getChannelDetails$(id) {
        return this._rootStore.getGroupDetailsByID$(id);
    }
}
MobileStoresService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileStoresService_Factory() { return new MobileStoresService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.StoreUiDispatchers)); }, token: MobileStoresService, providedIn: "root" });
