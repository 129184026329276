<img
  *ngIf="profile.image"
  appCancelDrag
  [src]="thumbnail"
  [alt]="profile?.name"
  class="circle-color"
  [class.square]="isSquare"
  [ngStyle]="cssStyles"
/>

<span
  *ngIf="!profile.image"
  appCancelDrag
  class="circle-color"
  [class.square]="isSquare"
  [innerHTML]="profile?.name | nickName | twemoji"
  [ngStyle]="cssStyles"
>
</span>
