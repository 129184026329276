/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selector-input-area.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../reactive-select-input/reactive-select-input.component.ngfactory";
import * as i3 from "../reactive-select-input/reactive-select-input.component";
import * as i4 from "@angular/common";
import * as i5 from "./selector-input-area.component";
import * as i6 from "../abstract-controls.service";
var styles_SelectorInputAreaComponent = [i0.styles];
var RenderType_SelectorInputAreaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectorInputAreaComponent, data: {} });
export { RenderType_SelectorInputAreaComponent as RenderType_SelectorInputAreaComponent };
function View_SelectorInputAreaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reactive-select-input", [], null, null, null, i2.View_ReactiveSelectInputComponent_0, i2.RenderType_ReactiveSelectInputComponent)), i1.ɵdid(1, 311296, null, 0, i3.ReactiveSelectInputComponent, [], { isInline: [0, "isInline"], fieldId: [1, "fieldId"], filedLabel: [2, "filedLabel"], keyOptions: [3, "keyOptions"], control: [4, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInline; var currVal_1 = "inputGroupArea"; var currVal_2 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.title); var currVal_3 = _co.keyValueOptions; var currVal_4 = _co.control; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SelectorInputAreaComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SelectorInputAreaComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.abstractControls.area$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SelectorInputAreaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selector-input-area", [], null, null, null, View_SelectorInputAreaComponent_0, RenderType_SelectorInputAreaComponent)), i1.ɵdid(1, 49152, null, 0, i5.SelectorInputAreaComponent, [i6.AbstractControlsService], null, null)], null, null); }
var SelectorInputAreaComponentNgFactory = i1.ɵccf("app-selector-input-area", i5.SelectorInputAreaComponent, View_SelectorInputAreaComponent_Host_0, { isInline: "isInline", filedLabel: "filedLabel", control: "control" }, {}, []);
export { SelectorInputAreaComponentNgFactory as SelectorInputAreaComponentNgFactory };
