import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map } from 'rxjs/operators';

import { GetSmtpMethod, SetSmtpMethod } from 'src/models/Smtp';
import * as SmtpActions from './smtp.actions';

@Injectable()
export class SmtpEffects {
  @Effect({ dispatch: false })
  setStmp = this.actions$.pipe(
    ofType(SmtpActions.SmtpActionTypes.SET_SMTP),
    map((action: SmtpActions.SetStmp) => {
      this._socketGateway.sendSocketMessage(new SetSmtpMethod(action.smtp));
    })
  );

  @Effect({ dispatch: false })
  getPaymentProviders = this.actions$.pipe(
    ofType(SmtpActions.SmtpActionTypes.GET_SMTP),
    map((action: SmtpActions.GetStmp) => {
      this._socketGateway.sendSocketMessage(new GetSmtpMethod());
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway
  ) {}
}
