/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-cell-actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./post-cell-actions.component";
var styles_PostCellActionsComponent = [i0.styles];
var RenderType_PostCellActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostCellActionsComponent, data: {} });
export { RenderType_PostCellActionsComponent as RenderType_PostCellActionsComponent };
function View_PostCellActionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "action delete clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recallEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" X "]))], null, null); }
function View_PostCellActionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "action clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.statsEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "repost"], ["src", "./assets/img/analytics.svg"]], null, null, null, null, null))], null, null); }
function View_PostCellActionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "action clickable-cursor"]], [[8, "href", 4], [8, "download", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "repost"], ["src", "./assets/img/download-action.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; var currVal_1 = _co.mediaId; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_PostCellActionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "actions-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostCellActionsComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAdmin; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMenu; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isDownloadable; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_PostCellActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-cell-actions", [], null, null, null, View_PostCellActionsComponent_0, RenderType_PostCellActionsComponent)), i1.ɵdid(1, 49152, null, 0, i3.PostCellActionsComponent, [], null, null)], null, null); }
var PostCellActionsComponentNgFactory = i1.ɵccf("app-post-cell-actions", i3.PostCellActionsComponent, View_PostCellActionsComponent_Host_0, { media: "media", mediaId: "mediaId", isAdmin: "isAdmin", loggedInIsSender: "loggedInIsSender", isDownloadable: "isDownloadable", isMenu: "isMenu" }, { recallNotfiy: "recallNotfiy", repostNotfiy: "repostNotfiy", editNotfiy: "editNotfiy", statsNotfiy: "statsNotfiy" }, []);
export { PostCellActionsComponentNgFactory as PostCellActionsComponentNgFactory };
