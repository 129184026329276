import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-round-paging-btn',
  templateUrl: './round-paging-btn.component.html',
  styleUrls: ['./round-paging-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundPagingBtnComponent {
  @Output() nextBtnClicked = new EventEmitter<boolean>();
  @Output() previousBtnClicked = new EventEmitter<boolean>();

  getNextPage() {
    this.nextBtnClicked.emit(true);
  }

  getPreviousPage() {
    this.previousBtnClicked.emit(true);
  }
}
