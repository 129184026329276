import { Injectable } from '@angular/core';

import { RootStoreSelectors } from '../store/root-store.selectors';
import { CHANNEL_APP_SEARCH_CATEGORIES } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class AbstractControlsService {
  constructor(private _selectors: RootStoreSelectors) {}

  get area$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.AREA
    );
  }
  get classification$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
    );
  }
  get category$() {
    return this._selectors.getAppChannelDataByKey$(
      CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY
    );
  }
}
