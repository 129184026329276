import {
  LocalAppClass,
  LocalContainer,
  LocalComponent,
  TabComponent,
  ContainerChildren
} from 'src/models/ChannelAppLocalConfig';
import {
  AppConfig,
  AppClass,
  App,
  DefaultClassConfig,
  Container,
  Component,
  Item,
  WholeApp,
  SystemConfig,
  Sequence,
  TabKey,
  TagItem,
  DynamicForm
} from 'src/models/ChannelAppConfig';
import {
  AppClassNames,
  ContainerTypes,
  ComponentTypes,
  EMPTY_STRING,
  CHAT_DEFAULTS_JSON_KEYS,
  ADMIN_TAG_TYPE,
  POST_TAG_TYPE,
  TabTypes,
  OpenChatTypes,
  MyListTabTypes,
  IOS_DEFAULT_COLOR,
  IOS_DEFAULT_ICON_COLOR,
  TYPES_FOR_SEARCH,
  DEFAULT_MAX_SEARCH_CONTAINERS,
  FooterActionTypes
} from 'src/models/constants';
import {
  OnlineChannel,
  OnlineTabs,
  OnlineDefault,
  OnlineChannelConfig,
  OnlineTab,
  OnlineMenu,
  OnlineFooter,
  OnlinePost,
  OnlineCard,
  OnlineWallet,
  OnlineWebview,
  OnlineApp,
  OnlineAppConfig,
  SideMenu,
  SideMenuItem,
  SideMenuItemTitle,
  OnlineSearch,
  SearchItem,
  FooterAction,
  OnlineAppInfo,
  OnlineMapSearch,
  MapSearchButton,
  SearchItemList
} from 'src/models/ChannelAppOnlineConfig';
import { LocalItem } from 'src/models/ChannelAppLocalConfig';
import { LocalAppConfig } from 'src/models/ChannelAppLocalConfig';
import { uuidv4, ThemeStatus } from 'src/models/constants';
import { FOOTER_COMPONENT } from 'src/app/+merchant/+dashboard/app-mgmt/templates';
import { DynamicForms } from './../../../models/ChannelAppConfig';
import { BotMenu } from 'src/models/IBotMenu';

export const parseAppConfigData = (
  rcvdConfig: AppConfig,
  systemConfig: SystemConfig,
  onlineConfig: boolean,
  forceSystemColor?: boolean
): LocalAppConfig => {
  const appClasses: LocalAppClass[] = [];
  const containers: LocalContainer[] = [];
  const components: LocalComponent[] = [];
  const items: LocalItem[] = [];
  const subItems: LocalItem[] = [];
  const localAppConfig: LocalAppConfig = {
    subItems,
    items,
    components,
    containers,
    appClasses
  };
  let found = 0;
  if (!onlineConfig && rcvdConfig.nav_menu) {
    const confClass = initConfClass(
      rcvdConfig.nav_menu,
      AppClassNames.NAV_MENU,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    // console.log('here nav menu');
    extractDataFromAppClass(
      rcvdConfig.nav_menu,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  }
  if (onlineConfig && rcvdConfig.tabs) {
    const confClass = initConfClass(
      rcvdConfig.tabs,
      AppClassNames.TABS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.tabs,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  }
  if (onlineConfig && rcvdConfig.sections) {
    const confClass = initConfClass(
      rcvdConfig.sections,
      AppClassNames.SECTIONS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.sections,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  } else if (onlineConfig && !rcvdConfig.sections) {
    const emptyClass: AppClass = {};
    const confClass = initConfClass(
      emptyClass,
      AppClassNames.SECTIONS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
  }

  if (onlineConfig && rcvdConfig.search_category) {
    const confClass = initConfClass(
      rcvdConfig.search_category,
      AppClassNames.SEARCH,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.search_category,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  } else if (onlineConfig && !rcvdConfig.search_category) {
    const emptyClass: AppClass = {};
    const confClass = initConfClass(
      emptyClass,
      AppClassNames.SEARCH,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
  }

  if (!onlineConfig && rcvdConfig.splash) {
    const confClass = initConfClass(
      rcvdConfig.splash,
      AppClassNames.SPLASH,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.splash,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  }
  if (!onlineConfig && rcvdConfig.profile) {
    // console.log('i am here profile');
    const emptyAppClass: AppClass = {};
    // const confClass = initConfClass(
    //   emptyAppClass,
    //   AppClassNames.FORMS,
    //   systemConfig,
    //   forceSystemColor
    // );
    const confClass: LocalAppClass = {};
    const forms: DynamicForms = {};
    forms.signup = {};
    forms.signup.id = 'signup1';
    forms.signup.fields = [];
    confClass.forms = forms;
    confClass.name = AppClassNames.FORMS;
    // console.log('config', confClass);
    appClasses.push(confClass);
    extractDataFromAppClass(
      confClass,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
    found++;
  }
  if (!onlineConfig && rcvdConfig.forms) {
    // console.log('i am here forms');
    const confClass = initConfClass(
      rcvdConfig.forms,
      AppClassNames.FORMS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.forms,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
    found++;
  }

  if (!onlineConfig && rcvdConfig.settings) {
    const confClass = initConfClass(
      rcvdConfig.settings,
      AppClassNames.SETTINGS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.settings,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  } else if (!onlineConfig && !rcvdConfig.settings) {
    const emptyClass: AppClass = {};
    const confClass = initConfClass(
      emptyClass,
      AppClassNames.SETTINGS,
      systemConfig,
      forceSystemColor
    );

    appClasses.push(confClass);
  }
  if (!onlineConfig && rcvdConfig.menus) {
    const confClass = initConfClass(
      rcvdConfig.menus,
      AppClassNames.MENUS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
    extractDataFromAppClass(
      rcvdConfig.menus,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  } else if (!onlineConfig && !rcvdConfig.menus) {
    const emptyClass: AppClass = {};
    const confClass = initConfClass(
      emptyClass,
      AppClassNames.MENUS,
      systemConfig,
      forceSystemColor
    );
    appClasses.push(confClass);
  }
  if (found == 0) {
    found++;
    const emptyAppClass: AppClass = {};
    // const confClass = initConfClass(
    //   emptyAppClass,
    //   AppClassNames.FORMS,
    //   systemConfig,
    //   forceSystemColor
    // );
    const confClass: LocalAppClass = {};
    const forms: DynamicForms = {};
    forms.signup = {};
    forms.signup.id = 'signup1';
    forms.signup.fields = [];
    confClass.forms = forms;
    confClass.name = AppClassNames.FORMS;
    confClass.ref = uuidv4();
    // console.log('config', confClass);
    appClasses.push(confClass);
    extractDataFromAppClass(
      confClass,
      confClass,
      containers,
      components,
      items,
      subItems,
      systemConfig,
      forceSystemColor
    );
  }
  return localAppConfig;
};

const initConfClass = (
  rcvdConfClass: AppClass,
  name: string,
  systemConfig: SystemConfig,
  forceSystemColor?: boolean
): LocalAppClass => {
  const confClass: LocalAppClass = {};
  confClass.ref = rcvdConfClass.ref ? rcvdConfClass.ref : uuidv4();
  confClass.name = name;

  if (
    name === AppClassNames.TABS &&
    (!rcvdConfClass.color ||
      rcvdConfClass.color === EMPTY_STRING ||
      forceSystemColor)
  ) {
    confClass.color = systemConfig.color.primary_text_inverted;
  } else {
    confClass.color = rcvdConfClass.color;
  }
  if (
    name === AppClassNames.TABS &&
    (!rcvdConfClass.color_ios ||
      rcvdConfClass.color_ios === EMPTY_STRING ||
      forceSystemColor)
  ) {
    confClass.color_ios = IOS_DEFAULT_COLOR;
  } else {
    confClass.color_ios = rcvdConfClass.color_ios;
  }
  if (
    name === AppClassNames.TABS &&
    (!rcvdConfClass.icon_color ||
      rcvdConfClass.icon_color === EMPTY_STRING ||
      forceSystemColor)
  ) {
    confClass.icon_color = systemConfig.color.primary_text_inverted;
  } else {
    confClass.icon_color = rcvdConfClass.icon_color;
  }
  if (
    name === AppClassNames.TABS &&
    (!rcvdConfClass.icon_color_ios ||
      rcvdConfClass.icon_color_ios === EMPTY_STRING ||
      forceSystemColor)
  ) {
    confClass.icon_color_ios = IOS_DEFAULT_ICON_COLOR;
  } else {
    confClass.icon_color_ios = rcvdConfClass.icon_color_ios;
  }

  if (
    name === AppClassNames.TABS &&
    (!rcvdConfClass.bg || rcvdConfClass.bg === EMPTY_STRING || forceSystemColor)
  ) {
    confClass.bg = systemConfig.color.primary;
  } else {
    confClass.bg = rcvdConfClass.bg;
  }
  confClass.type = rcvdConfClass.type;
  confClass.talk_admin_bg = rcvdConfClass.talk_admin_bg;
  if (rcvdConfClass.default) {
    confClass.default_message = rcvdConfClass.default.message;
  }
  if (rcvdConfClass.sequence) {
    confClass.feed_seq = rcvdConfClass.sequence.feed;
    confClass.membership_seq = rcvdConfClass.sequence.membership;
    confClass.wallet_seq = rcvdConfClass.sequence.wallet;
    confClass.webview_seq = rcvdConfClass.sequence.web_view;
    confClass.channel_seq = rcvdConfClass.sequence.channel;
    confClass.chat_seq = rcvdConfClass.sequence.chat;
    confClass.settings_seq = rcvdConfClass.sequence.settings;
    confClass.calendar_seq = rcvdConfClass.sequence.calendar;
    confClass.booking_seq = rcvdConfClass.sequence.booking;
    confClass.invitation_seq = rcvdConfClass.sequence.invitation;
    confClass.open_chat_seq = rcvdConfClass.sequence.open_chat;
    confClass.mylist_seq = rcvdConfClass.sequence.mylist;
    confClass.qr_seq = rcvdConfClass.sequence.qr;
    confClass.map_seq = rcvdConfClass.sequence.map;
    confClass.search_seq = rcvdConfClass.sequence.search;
    confClass.call_log_seq = rcvdConfClass.sequence.call_log;
    confClass.section_seq = rcvdConfClass.sequence.section;
    confClass.bundle_seq = rcvdConfClass.sequence.bundle;
    confClass.package_seq = rcvdConfClass.sequence.package;
    confClass.video_seq = rcvdConfClass.sequence.video;
    confClass.qr_page_seq = rcvdConfClass.sequence.qr_page;
    confClass.map_search_seq = rcvdConfClass.sequence.map_search;
  }
  confClass.opened = rcvdConfClass.opened;
  confClass.currentlyOpened = rcvdConfClass.opened;
  confClass.profile_visible = rcvdConfClass.profile_visible;
  confClass.min_containers = rcvdConfClass.min_containers;
  confClass.max_containers = rcvdConfClass.max_containers;
  if (rcvdConfClass.splash) {
    confClass.splash = { ...rcvdConfClass.splash };
  }
  if (rcvdConfClass.signup) {
    confClass.forms = {};
    confClass.forms.signup = { ...rcvdConfClass.signup };
  }
  if (rcvdConfClass.menus) {
    confClass.menus = [];

    confClass.menus = rcvdConfClass.menus;
  }
  if (name === AppClassNames.SEARCH) {
    confClass.max_containers = rcvdConfClass.max_containers
      ? rcvdConfClass.max_containers
      : DEFAULT_MAX_SEARCH_CONTAINERS;
  }

  return confClass;
};

const extractDataFromAppClass = (
  rcvdConfClass: AppClass,
  parentConfClass: LocalAppClass,
  containers: LocalContainer[],
  components: LocalComponent[],
  items: LocalItem[],
  subItems: LocalItem[],
  systemConfig: SystemConfig,
  forceSystemColor?: boolean
) => {
  if (rcvdConfClass.signup) {
    rcvdConfClass.container = rcvdConfClass.signup.fields;
  }
  if (rcvdConfClass.container) {
    rcvdConfClass.container.forEach((container, index) => {
      const cont = getLocalContainerFromContainer(container);
      cont.parentClassRef = parentConfClass.ref;
      cont.appClassName = parentConfClass.name;
      if (
        parentConfClass.name === AppClassNames.SPLASH &&
        container.container_type === ContainerTypes.FOOTER &&
        (!container.link_color ||
          container.link_color === EMPTY_STRING ||
          forceSystemColor)
      ) {
        cont.color = systemConfig.color.primary_text;
        cont.link_color = systemConfig.color.primary;
      }
      if (
        parentConfClass.name === AppClassNames.SPLASH &&
        container.container_type === ContainerTypes.HEADER &&
        (!container.link_color ||
          container.link_color === EMPTY_STRING ||
          forceSystemColor)
      ) {
        cont.color = systemConfig.color.primary_text_inverted;
        cont.bg = systemConfig.color.primary;
      }
      cont.order = index;
      if (index === 0) {
        cont.selected = true;
      }
      containers.push(cont);
      if (container.component) {
        container.component.forEach((component, componentIndex) => {
          const comp = getLocalComponentFromComponent(component);
          comp.parentContainerRef = cont.ref;
          comp.order = componentIndex;
          if (componentIndex === 0) {
            comp.selected = true;
          }
          handleComponentColors(
            parentConfClass.name,
            systemConfig,
            comp,
            component,
            forceSystemColor
          );
          comp.appClassName = parentConfClass.name;
          comp.containerType = cont.container_type;
          components.push(comp);
          if (
            parentConfClass.name === AppClassNames.TABS &&
            component.component_type === ComponentTypes.FOOTER &&
            !component.item
          ) {
            component.item = (<Component>JSON.parse(FOOTER_COMPONENT)).item;
          }
          if (component.item) {
            // console.log('sec nav');
            component.item.forEach((item, itemIndex) => {
              const newItem = getLocalItemFromItem(item);
              newItem.parentComponentRef = comp.ref;
              newItem.icon_ios =
                parentConfClass.name === AppClassNames.NAV_MENU &&
                !item.icon_ios
                  ? item.icon
                  : item.icon_ios; // copy ios field from icon if not exists in case of side menu
              if (
                (!item.bg || item.bg === EMPTY_STRING || forceSystemColor) &&
                parentConfClass.name === AppClassNames.TABS &&
                component.component_type === ComponentTypes.FOOTER
              ) {
                newItem.bg = systemConfig.color.primary_bg;
              }
              newItem.order = itemIndex;
              newItem.appClassName = parentConfClass.name;
              newItem.containerType = cont.container_type;
              newItem.componentType = comp.component_type;
              items.push(newItem);
              if (item.component) {
                item.component.forEach(componentItem => {
                  components.push(componentItem);
                  if (componentItem.item) {
                    componentItem.item.forEach(itemItem => {
                      items.push(itemItem);
                    });
                  }
                });
              }
              if (item.sub_item) {
                item.sub_item.forEach((subItem, subItemIndex) => {
                  const newSubItem = getLocalItemFromItem(subItem);
                  newSubItem.parentComponentRef = comp.ref;
                  newSubItem.parentItemRef = newItem.ref;
                  newSubItem.order = subItemIndex;
                  newSubItem.appClassName = parentConfClass.name;
                  newSubItem.containerType = cont.container_type;
                  newSubItem.componentType = comp.component_type;
                  newSubItem.itemType = item.link;
                  subItems.push(newSubItem);
                });
              }
            });
          }
        });
      }
    });
  }
};

export const handleComponentColors = (
  parentConfClassName: string,
  systemConfig: SystemConfig,
  comp: LocalComponent,
  component: Component,
  forceSystemColor?: boolean
) => {
  if (
    !component.color ||
    component.color === EMPTY_STRING ||
    forceSystemColor
  ) {
    setDefaultColorForComponent(
      parentConfClassName,
      systemConfig,
      comp,
      component
    );
  } else {
    comp.color = component.color;
  }
  if (
    (!component.secondary_color ||
      component.secondary_color === EMPTY_STRING ||
      forceSystemColor) &&
    parentConfClassName === AppClassNames.NAV_MENU &&
    component.component_type === ComponentTypes.HEADER
  ) {
    comp.secondary_color = systemConfig.color.primary_text_inverted;
  } else {
    comp.secondary_color = component.secondary_color;
  }

  if (!component.bg || component.bg === EMPTY_STRING || forceSystemColor) {
    setDefaultBgColorForComponent(
      parentConfClassName,
      systemConfig,
      comp,
      component
    );
  } else {
    comp.bg = component.bg;
  }
  if (
    (!component.icon_color ||
      component.icon_color === EMPTY_STRING ||
      forceSystemColor) &&
    parentConfClassName === AppClassNames.NAV_MENU &&
    component.component_type === ComponentTypes.MENU
  ) {
    comp.icon_color = systemConfig.color.primary_icon_fill;
  } else {
    comp.icon_color = component.icon_color;
  }

  if (
    (!component.search_btn_bg ||
      component.search_btn_bg === EMPTY_STRING ||
      forceSystemColor) &&
    component.component_type === ComponentTypes.SEARCH
  ) {
    comp.search_btn_bg = systemConfig.color.primary;
  } else {
    comp.search_btn_bg = component.search_btn_bg;
  }
};

const setDefaultColorForComponent = (
  parentConfClassName: string,
  systemConfig: SystemConfig,
  comp: LocalComponent,
  component: Component
) => {
  switch (parentConfClassName) {
    case AppClassNames.NAV_MENU: {
      if (component.component_type === ComponentTypes.HEADER) {
        comp.color = systemConfig.color.primary_text_inverted;
      } else if (component.component_type === ComponentTypes.MENU) {
        comp.color = systemConfig.color.primary_text;
      }
      break;
    }
    case AppClassNames.TABS: {
      if (component.component_type === ComponentTypes.FOOTER) {
        comp.color = systemConfig.color.primary_icon_fill;
      }
      break;
    }
    case AppClassNames.SPLASH: {
      if (component.component_type === ComponentTypes.BUTTON) {
        comp.color = systemConfig.color.primary_text_inverted;
      }
      break;
    }
  }
};

export const setDefaultBgColorForComponent = (
  parentConfClassName: string,
  systemConfig: SystemConfig,
  comp: LocalComponent,
  component: Component
) => {
  switch (parentConfClassName) {
    case AppClassNames.NAV_MENU: {
      if (component.component_type === ComponentTypes.HEADER) {
        comp.bg = systemConfig.color.primary;
      } else if (component.component_type === ComponentTypes.MENU) {
        comp.bg = systemConfig.color.primary_bg;
      }
      break;
    }
    case AppClassNames.TABS: {
      if (component.component_type === ComponentTypes.FOOTER) {
        comp.bg = systemConfig.color.primary_bg;
      } else if (
        component.component_type === ComponentTypes.INVITATION_ACCEPT
      ) {
        comp.bg = systemConfig.color.primary;
      }
      break;
    }
    case AppClassNames.SPLASH: {
      if (component.component_type === ComponentTypes.BUTTON) {
        comp.bg = systemConfig.color.primary;
      }
      break;
    }
  }
};

export const constructAppConfig = (
  classes: LocalAppClass[],
  containers: LocalContainer[],
  components: LocalComponent[],
  items: LocalItem[],
  subItems: LocalItem[],
  systemConfig: SystemConfig,
  channelConfig: boolean,
  contactPermission?: number,
  mainMenu?: BotMenu[]
): WholeApp => {
  const wholeAppConfig: WholeApp = {};
  const webConfig: App = {};
  const appConfig: AppConfig = {};
  classes.forEach(appClass => {
    switch (appClass.name) {
      case AppClassNames.NAV_MENU: {
        if (!channelConfig) {
          appConfig.nav_menu = constructAppClass(
            appClass,
            containers,
            components,
            items,
            subItems,
            systemConfig
          );
        }
        break;
      }
      case AppClassNames.TABS: {
        if (channelConfig) {
          appConfig.tabs = constructAppClass(
            appClass,
            containers,
            components,
            items,
            subItems,
            systemConfig
          );
          if (
            appConfig.tabs.container.filter(tab => tab.type === TabTypes.BUNDLE)
              .length >= 1
          ) {
            appConfig.tabs.allow_cart = 1;
          } else {
            appConfig.tabs.allow_cart = 0;
          }
        }

        break;
      }
      case AppClassNames.SECTIONS: {
        if (channelConfig) {
          appConfig.sections = constructAppClass(
            appClass,
            containers,
            components,
            items,
            subItems,
            systemConfig
          );
        }
        break;
      }
      case AppClassNames.SEARCH: {
        // if (channelConfig) {
        appConfig.search_category = constructAppClass(
          appClass,
          containers,
          components,
          items,
          subItems,
          systemConfig
        );
        // }
        break;
      }
      case AppClassNames.SETTINGS: {
        if (!channelConfig) {
          appConfig.settings = constructAppClass(
            appClass,
            containers,
            components,
            items,
            subItems,
            systemConfig
          );
        }
        break;
      }
      case AppClassNames.SPLASH: {
        if (!channelConfig) {
          appConfig.splash = constructAppClass(
            appClass,
            containers,
            components,
            items,
            subItems,
            systemConfig
          );
        }
        break;
      }
      case AppClassNames.PROFILE: {
        if (!channelConfig) {
          const newContainers: LocalContainer[] = [];
          containers.map(cont => {
            if (cont.appClassName === AppClassNames.FORMS) {
              newContainers.push(cont);
            }
          });
          appClass.forms.signup.fields = newContainers.sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          appConfig.forms = appClass.forms;
        }
        break;
      }
      case AppClassNames.FORMS: {
        if (!channelConfig) {
          const newContainers: LocalContainer[] = [];
          containers.map(cont => {
            if (cont.appClassName === AppClassNames.FORMS) {
              newContainers.push(cont);
            }
          });

          appClass.forms.signup.fields = newContainers.sort((a, b) =>
            a.order > b.order ? 1 : -1
          );
          // console.log('forms', appClass.forms);
          appConfig.forms = appClass.forms;
        }
        break;
      }
      case AppClassNames.MENUS: {
        // if (!channelConfig) {

        appConfig.menus = appClass;

        break;
      }
    }
  });

  appConfig.system = systemConfig;
  webConfig.app = appConfig;
  wholeAppConfig.appConfig = webConfig;
  if (channelConfig) {
    wholeAppConfig.channelAppConfig = constructOnlineChannelConfig(appConfig);
  } else {
    wholeAppConfig.onlineAppConfig = constructOnlineAppConfig(
      appConfig,
      contactPermission,
      webConfig
    );
  }
  return wholeAppConfig;
};

const constructOnlineAppConfig = (
  appConfig: AppConfig,
  contactPermission = 0,
  webConfig: App
): OnlineApp => {
  const onlineApp: OnlineApp = {};
  const onlineAppConfig: OnlineAppConfig = {};
  const sideMenu: SideMenu = {};
  const menus: SideMenuItem[] = [];
  const navMenuAppClass: AppClass = appConfig.nav_menu;
  const searchAppClass: AppClass = webConfig.app.search_category;
  const forms: DynamicForms = {};
  const newContainers: LocalContainer[] = [];

  if (webConfig.app.forms) {
    webConfig.app.forms.signup.fields.map(cont => {
      const newCont: LocalContainer = {};
      newCont.type = cont.type;
      newCont.title_ios = cont.title_ios;
      newCont.title = cont.title;
      newCont.required = cont.required;
      newCont.options = cont.options;
      newCont.min = cont.min;
      newCont.max = cont.max;
      newCont.id = cont.id;
      newCont.error = cont.error;
      newCont.default = cont.default;
      newCont.hint = cont.hint;
      newCont.desc = cont.desc;
      if (cont.lines) {
        newCont.lines = cont.lines;
      }

      newCont.exclude = cont.exclude ? cont.exclude : 0;

      if (cont.items) {
        newCont.items = cont.items;
      }
      newContainers.push(newCont);
    });
    const signUp: DynamicForm = {};
    signUp.fields = newContainers;
    signUp.id = webConfig.app.forms.signup.id;
    forms.signup = signUp;
  }

  if (navMenuAppClass && navMenuAppClass.container) {
    const menuContainer = navMenuAppClass.container.find(
      cont => cont.container_type === ContainerTypes.MENU
    );

    if (menuContainer && menuContainer.component) {
      const menuComp = menuContainer.component.find(
        comp => comp.component_type === ComponentTypes.MENU
      );
      if (menuComp) {
        if (menuComp.bg) {
          sideMenu.bg = menuComp.bg;
        }
        if (menuComp.color) {
          sideMenu.color = menuComp.color;
        }
        if (menuComp.icon_color) {
          sideMenu.icon_color = menuComp.icon_color;
        }
        if (menuComp.type) {
          sideMenu.type = menuComp.type;
        }
        if (menuComp.item) {
          onlineAppConfig.allow_cart = 0;
          menuComp.item.forEach(item => {
            const sideMenuItem: SideMenuItem = {};
            const subType = item.sub_type
              ? getSubTypeMapping(item.link, item.sub_type)
              : null;
            sideMenuItem.type = subType
              ? subType
              : item.link === TabTypes.PAGE
              ? TabTypes.WEBVIEW
              : item.link;

            if (item.link === TabTypes.BUNDLE) {
              onlineAppConfig.allow_cart = 1;
              // console.log('here 1', item.link);
            }

            if (item.link === TabTypes.SEARCH) {
              const onlineSearch: OnlineSearch = {};

              const searchComp = item.component.find(
                comp => comp.component_type === ComponentTypes.SEARCH
              );
              if (searchComp) {
                let groupTypes: number[] = null;
                onlineSearch.search = [];
                onlineSearch.filter = [];
                const wholeTypes = searchComp.type as number[];
                if (wholeTypes) {
                  groupTypes = wholeTypes.filter(
                    num => num !== TYPES_FOR_SEARCH.BOT
                  );
                  if (groupTypes && groupTypes.length > 0) {
                    onlineSearch.type = groupTypes;
                    onlineSearch.search.push(1);
                  }
                  if (wholeTypes.find(num => num === TYPES_FOR_SEARCH.BOT)) {
                    onlineSearch.search.push(0);
                  }
                }
                onlineSearch.page_index = 1;
                if (searchComp.filter) {
                  onlineSearch.filter = searchComp.filter;
                }
                if (searchComp.desc) {
                  onlineSearch.desc = searchComp.desc;
                }
                if (searchComp.title) {
                  onlineSearch.title = searchComp.title;
                }
                if (searchComp.search_btn_bg) {
                  onlineSearch.search_btn_bg = searchComp.search_btn_bg;
                }
                if (searchComp.search_btn_icon) {
                  onlineSearch.search_btn_icon = searchComp.search_btn_icon;
                }
                if (searchComp.search_btn_icon_color) {
                  onlineSearch.search_btn_icon_color =
                    searchComp.search_btn_icon_color;
                }
                if (
                  searchComp.hide_search_keyword === 1 ||
                  searchComp.hide_search_keyword === 0
                ) {
                  onlineSearch.hide_search_keyword =
                    searchComp.hide_search_keyword;
                }
                if (searchComp.search_text_hint) {
                  onlineSearch.search_text_hint = searchComp.search_text_hint;
                }
                if (searchComp.search_by_hint) {
                  onlineSearch.search_by_hint = searchComp.search_by_hint;
                }
                if (searchComp.search_text_lines_count) {
                  onlineSearch.search_text_lines_count = Number(
                    searchComp.search_text_lines_count
                  );
                }
                if (searchComp.image_url) {
                  onlineSearch.image_url = searchComp.image_url;
                }
                if (searchComp.search_by_date) {
                  onlineSearch.search_by_date = 1;
                } else {
                  onlineSearch.search_by_date = 0;
                }
                if (searchComp.search_by_time) {
                  onlineSearch.search_by_time = 1;
                } else {
                  onlineSearch.search_by_time = 0;
                }
                sideMenuItem.search = onlineSearch;
              }

              if (searchAppClass) {
                const searchConfigContainers = searchAppClass.container.filter(
                  cont => cont.container_type === ContainerTypes.SEARCH
                );
                if (
                  searchConfigContainers &&
                  searchConfigContainers.length > 0
                ) {
                  onlineAppConfig.search_filters = [];
                  searchConfigContainers.forEach(container => {
                    const searchItem: SearchItem = {};
                    searchItem.key = container.type;
                    if (container.icon) {
                      searchItem.icon = container.icon;
                    }
                    if (container.title) {
                      searchItem.title = container.title;
                    }
                    if (container.list && container.list.length > 0) {
                      searchItem.list = container.list;
                    }
                    if (searchItem.list && searchItem.list.length > 0) {
                      searchItem.list2 = [];
                      searchItem.list.forEach(res => {
                        const listItem: SearchItemList = {};
                        listItem.key = res;
                        listItem.value = res;

                        searchItem.list2.push(listItem);
                      });
                    }
                    onlineAppConfig.search_filters.push(searchItem);
                  });
                }
              }
            }

            if (item.menu_id) {
              sideMenuItem.menu_id = item.menu_id;
            }
            if (item.store_id) {
              sideMenuItem.store_id = item.store_id;
            }
            if (item.chat_id) {
              sideMenuItem.chat_id = item.chat_id;
            }
            if (item.map) {
              sideMenuItem.map = item.map;
            }
            if (item.options !== null && item.options !== undefined) {
              sideMenuItem.options = item.options;
            }
            sideMenuItem.generic_qr = item.generic_qr;
            if (item.google_sync !== null && item.google_sync !== undefined) {
              sideMenuItem.google_sync = item.google_sync;
            }
            if (item.view_style !== null && item.view_style !== undefined) {
              sideMenuItem.view_style = Number(item.view_style);
            }
            if (item.video_url !== null && item.video_url !== undefined) {
              sideMenuItem.video_url = item.video_url;
            }
            if (item.desc) {
              sideMenuItem.desc = item.desc;
            }
            if (item.image_url) {
              sideMenuItem.image_url = item.image_url;
            }
            if (item.text_color) {
              sideMenuItem.text_color = item.text_color;
            }
            if (item.bg_color) {
              sideMenuItem.bg_color = item.bg_color;
            }
            if (item.limit) {
              sideMenuItem.limit = Number(item.limit);
            }
            if (item.ads) {
              // if (!item.ads.cell_count) {
              //   item.ads.cell_count = 5;
              // }
              sideMenuItem.ads = item.ads;
            }
            if (item.message) {
              sideMenuItem.message = item.message;
            }
            if (item.title) {
              const sideMenuItemTitle: SideMenuItemTitle = {};
              sideMenuItemTitle.default = item.title;
              sideMenuItem.title = sideMenuItemTitle;
            }
            if (item.icon) {
              sideMenuItem.icon = item.icon;
            }
            if (item.icon_ios) {
              sideMenuItem.icon_ios = item.icon_ios;
            }

            sideMenuItem.show_invite_friends = item.show_invite_friends;

            if (item.url) {
              sideMenuItem.url = item.url;
            }
            if (item.separator !== null && item.separator !== undefined) {
              sideMenuItem.separator = Number(item.separator);
            }
            if (TabTypes.MAP_SEARCH === sideMenuItem.type) {
              const onlineMapSearch: OnlineMapSearch = {};
              const mapSearchComponent = item.component.find(
                comp => comp.component_type === ComponentTypes.MAP_SEARCH
              );
              if (mapSearchComponent) {
                if (mapSearchComponent.nearby) {
                  onlineMapSearch.nearby = mapSearchComponent.nearby;
                }
                if (mapSearchComponent.radius) {
                  onlineMapSearch.radius = mapSearchComponent.radius;
                }
                if (mapSearchComponent.options) {
                  onlineMapSearch.options = mapSearchComponent.options;
                }
                if (mapSearchComponent.item) {
                  const buttonItems: MapSearchButton[] = [];
                  mapSearchComponent.item.forEach(mapItem => {
                    const btnItem: MapSearchButton = {};
                    btnItem.bg = mapItem.bg;
                    btnItem.link = mapItem.link;
                    btnItem.title = mapItem.title;
                    buttonItems.push(btnItem);
                  });
                  onlineMapSearch.button = buttonItems;
                }
              }
              sideMenuItem.map_search = onlineMapSearch;
            }

            menus.push(sideMenuItem);
          });
        }
      }
    }
  }

  sideMenu.menus = menus;
  const onlineAppInfo: OnlineAppInfo = {};
  onlineAppInfo.contact_permission = contactPermission;
  onlineAppConfig.forms = forms;
  onlineAppConfig.app_info = onlineAppInfo;
  onlineAppConfig.side_menu = sideMenu;
  onlineApp.app = onlineAppConfig;
  return onlineApp;
};

const constructOnlineChannelConfig = (appConfig: AppConfig): OnlineChannel => {
  const tabsAppClass: AppClass = appConfig.tabs;
  const sectionTabsAppClass: AppClass = appConfig.sections;
  const searchAppClass: AppClass = appConfig.search_category;
  const onlineChannel: OnlineChannel = {};
  const onlineTabs: OnlineTabs = {};
  const onlineDefault: OnlineDefault = {};

  if (tabsAppClass.bg) {
    onlineTabs.bg = tabsAppClass.bg;
  }
  if (tabsAppClass.color) {
    onlineTabs.color = tabsAppClass.color;
  }
  if (tabsAppClass.color_ios) {
    onlineTabs.color_ios = tabsAppClass.color_ios;
  }
  if (tabsAppClass.icon_color) {
    onlineTabs.icon_color = tabsAppClass.icon_color;
  }
  if (tabsAppClass.icon_color_ios) {
    onlineTabs.icon_color_ios = tabsAppClass.icon_color_ios;
  }
  if (tabsAppClass.talk_admin_bg) {
    onlineTabs.talk_admin_bg = appConfig.system.color.primary;
  }
  if (tabsAppClass.splash) {
    onlineTabs.splash = { ...tabsAppClass.splash };
  }
  if (tabsAppClass.type) {
    onlineTabs.type = tabsAppClass.type;
  }

  if (tabsAppClass.default && tabsAppClass.default.message) {
    const sortedContainers = tabsAppClass.container.sort(ascendingly);
    sortedContainers.forEach(res => {
      if (
        (res.type === TabTypes.FEED ||
          res.type === TabTypes.MEMBERSHIP ||
          res.type === TabTypes.WALLET) &&
        !onlineDefault.message
      ) {
        onlineDefault.message = res.id;
      }
    });

    // onlineDefault.message = tabsAppClass.default
    //   ? tabsAppClass.default.message
    //   : null;
    onlineTabs.default = onlineDefault;
  }
  const sortedContainers = tabsAppClass.container.sort(ascendingly);

  sortedContainers.forEach(res => {
    if (
      (res.type === TabTypes.FEED ||
        res.type === TabTypes.MEMBERSHIP ||
        res.type === TabTypes.WALLET) &&
      !onlineDefault.message
    ) {
      onlineDefault.message = res.id;
    }
  });

  // onlineDefault.message = tabsAppClass.default
  //   ? tabsAppClass.default.message
  //   : null;

  const onlineTabsContainers = getOnlineTabsConfig(
    tabsAppClass.container.filter(
      cont =>
        cont.container_type === ContainerTypes.TAB &&
        (cont.type !== '' && cont.type !== null)
    ),
    sectionTabsAppClass
  );

  if (
    onlineTabsContainers &&
    onlineTabsContainers.filter(tab => tab.type === TabTypes.BUNDLE).length >= 1
  ) {
    onlineTabs.allow_cart = 1;
  } else {
    onlineTabs.allow_cart = 0;
  }
  onlineTabs.tabs = onlineTabsContainers;
  const onlineChannelConfig: OnlineChannelConfig = {};
  onlineChannelConfig.tabs = onlineTabs;
  if (appConfig.system.app_info.layout === ThemeStatus.Bottom) {
    onlineChannelConfig.tabs.tab_style = 1;
  }
  if (searchAppClass) {
    const searchConfigContainers = searchAppClass.container.filter(
      cont => cont.container_type === ContainerTypes.SEARCH
    );
    if (searchConfigContainers && searchConfigContainers.length > 0) {
      onlineChannelConfig.search = [];
      searchConfigContainers.forEach(container => {
        const searchItem: SearchItem = {};
        searchItem.key = container.type;
        if (container.icon) {
          searchItem.icon = container.icon;
        }
        if (container.title) {
          searchItem.title = container.title;
        }
        if (container.list && container.list.length > 0) {
          searchItem.list = container.list;
        }
        if (searchItem.list && searchItem.list.length > 0) {
          searchItem.list2 = [];
          searchItem.list.forEach(res => {
            const listItem: SearchItemList = {};
            listItem.key = res;
            listItem.value = res;

            searchItem.list2.push(listItem);
          });
        }
        onlineChannelConfig.search.push(searchItem);
      });
    }
  }
  if (
    appConfig.menus &&
    appConfig.menus.menus &&
    appConfig.menus.menus.length > 0
  ) {
    onlineChannelConfig.menus = appConfig.menus.menus;

    // onlineChannelConfig.menus.push(appConfig.menus.menus[0]);
  }

  onlineChannel.app = onlineChannelConfig;

  return onlineChannel;
};

const getOnlineTabsConfig = (
  tabs: Container[],
  sectionTabsAppClass?: AppClass
): OnlineTab[] => {
  const onlineTabArray: OnlineTab[] = [];
  // console.log('all tabs', tabs);
  if (tabs) {
    tabs.forEach(tab => {
      let tabSections: Container[] = null;
      const onlineTab: OnlineTab = {};
      const onlineFooter: OnlineFooter = {};
      const onlinePost: OnlinePost = {};
      const onlineMenuArray: OnlineMenu[] = [];
      const settingsMenuArray: OnlineMenu[] = [];
      const onlineCard: OnlineCard = {};
      const onlineWallet: OnlineWallet = {};
      const onlineWebview: OnlineWebview = {};
      const onlineMapSearch: OnlineMapSearch = {};

      let menuItems: Item[] = [];

      if (
        tab.type === TabTypes.FEED ||
        tab.type === TabTypes.WALLET ||
        tab.type === TabTypes.MEMBERSHIP
      ) {
        const footerComp = tab.component.find(
          comp => comp.component_type === ComponentTypes.FOOTER
        );
        const postComp = tab.component.find(
          comp => comp.component_type === ComponentTypes.POST
        );
        const menuComp = tab.component.find(
          comp => comp.component_type === ComponentTypes.MENU
        );

        if (footerComp) {
          const footerActions = footerComp.item ? footerComp.item : [];
          const actions: FooterAction[] = [];
          onlineFooter.hidden = footerComp.hidden;
          footerActions.forEach(item => {
            if (!item.disabled) {
              const newAction: FooterAction = {};
              newAction.link = item.link;
              newAction.icon = item.icon;
              newAction.fill_color = item.color;
              actions.push(newAction);
              if (item.link === FooterActionTypes.LIKE && item.icon_highlight) {
                const highlightAction: FooterAction = {};
                highlightAction.link = FooterActionTypes.LIKE_HIGHLIGHT;
                highlightAction.icon = item.icon_highlight;
                highlightAction.fill_color = item.color_highlight;
                actions.push(highlightAction);
              }
            }
          });
          onlineFooter.action = actions;
          onlineTab.footer = onlineFooter;
        }
        if (postComp) {
          onlinePost.type = postComp.type;
          onlinePost.column = postComp.column ? Number(postComp.column) : null;
          if (postComp.sticky_visible) {
            onlinePost.sticky_id = postComp.sticky_id
              ? Number(postComp.sticky_id)
              : 1;
            if (postComp.sticky_bg) {
              onlinePost.sticky_bg = postComp.sticky_bg;
            }
            if (postComp.sticky_text_color) {
              onlinePost.sticky_text_color = postComp.sticky_text_color;
            }
            onlinePost.sticky_visible = postComp.sticky_visible;
          }
          onlineTab.post = onlinePost;
        }
        if (menuComp) {
          menuItems = menuComp.item ? menuComp.item : [];

          if (
            (tab.type === TabTypes.FEED ||
              tab.type === TabTypes.WALLET ||
              tab.type === TabTypes.MEMBERSHIP) &&
            tab.menu &&
            tab.menu.length > 0
          ) {
            menuComp.item = tab.menu;
            menuItems = menuComp.item ? menuComp.item : [];
          }
          if (menuItems.length > 0) {
            menuItems.forEach(item => {
              const onlineMenu: OnlineMenu = {};
              if (!item.disabled) {
                if (item.icon) {
                  onlineMenu.icon = item.icon;
                }
                if (item.link) {
                  onlineMenu.link = item.link;
                }
                if (item.title) {
                  onlineMenu.title = item.title;
                }
                onlineMenuArray.push(onlineMenu);
              }
            });
          }
          onlineTab.menu = onlineMenuArray;
        }
        if (tab.type === TabTypes.MEMBERSHIP) {
          const cardComp = tab.component.find(
            comp => comp.component_type === ComponentTypes.CARD
          );
          if (cardComp) {
            if (cardComp.desc) {
              onlineCard.desc = cardComp.desc;
            }
            if (cardComp.title) {
              onlineCard.title = cardComp.title;
            }
            if (cardComp.image) {
              onlineCard.image = cardComp.image;
            }
            if (cardComp.image_url) {
              onlineCard.image_url = cardComp.image_url;
            }
            onlineTab.card = onlineCard;
          }
        } else if (tab.type === TabTypes.WALLET) {
          const walletComp = tab.component.find(
            comp => comp.component_type === ComponentTypes.WALLET
          );
          if (walletComp) {
            if (walletComp.desc) {
              onlineWallet.desc = walletComp.desc;
            }
            if (walletComp.title) {
              onlineWallet.title = walletComp.title;
            }
            if (walletComp.target) {
              onlineWallet.target_default = Number(walletComp.target);
            }
            if (walletComp.bg_image) {
              onlineWallet.bg_image = walletComp.bg_image;
            }
            if (walletComp.text_color) {
              onlineWallet.text_color = walletComp.text_color;
            }
            if (walletComp.progress_earned_color) {
              onlineWallet.progress_earned_color =
                walletComp.progress_earned_color;
            }
            if (walletComp.progress_target_color) {
              onlineWallet.progress_target_color =
                walletComp.progress_target_color;
            }
            onlineTab.wallet = onlineWallet;
          }
        }
      } else if (tab.type === TabTypes.WEBVIEW) {
        const webviewComp = tab.component.find(
          comp => comp.component_type === ComponentTypes.WEB_VIEW
        );
        if (webviewComp) {
          if (webviewComp.wv_url) {
            onlineWebview.wv_url = webviewComp.wv_url;
          }
          onlineTab.web_view = onlineWebview;
        }
      } else if (tab.type === TabTypes.MAP_SEARCH) {
        const mapSearchComponent = tab.component.find(
          comp => comp.component_type === ComponentTypes.MAP_SEARCH
        );
        if (mapSearchComponent) {
          if (mapSearchComponent.nearby) {
            onlineMapSearch.nearby = mapSearchComponent.nearby;
          }
          if (mapSearchComponent.radius) {
            onlineMapSearch.radius = mapSearchComponent.radius;
          }
          if (mapSearchComponent.options) {
            onlineMapSearch.options = mapSearchComponent.options;
          }
          if (mapSearchComponent.item) {
            const buttonItems: MapSearchButton[] = [];
            mapSearchComponent.item.forEach(item => {
              const btnItem: MapSearchButton = {};
              btnItem.bg = item.bg;
              btnItem.link = item.link;
              btnItem.title = item.title;
              buttonItems.push(btnItem);
            });
            onlineMapSearch.button = buttonItems;
          }
          onlineTab.map_search = onlineMapSearch;
        }
      } else if (tab.type === TabTypes.PAGE) {
        if (tab.url) {
          onlineWebview.wv_url = tab.url;
        }
        onlineTab.web_view = onlineWebview;
      } else if (tab.type === TabTypes.SEARCH) {
        const onlineSearch: OnlineSearch = {};

        const searchComp = tab.component.find(
          comp => comp.component_type === ComponentTypes.SEARCH
        );
        if (searchComp) {
          let groupTypes: number[] = null;
          onlineSearch.search = [];
          onlineSearch.filter = [];
          const wholeTypes = searchComp.type as number[];
          if (wholeTypes) {
            groupTypes = wholeTypes.filter(num => num !== TYPES_FOR_SEARCH.BOT);
            if (groupTypes && groupTypes.length > 0) {
              onlineSearch.type = groupTypes;
              onlineSearch.search.push(1);
            }
            if (wholeTypes.find(num => num === TYPES_FOR_SEARCH.BOT)) {
              onlineSearch.search.push(0);
            }
          }
          onlineSearch.page_index = 1;
          if (searchComp.item && searchComp.item.length > 0) {
            searchComp.item.forEach(item => {
              onlineSearch.filter.push(item.type);
            });
          }
          if (searchComp.desc) {
            onlineSearch.desc = searchComp.desc;
          }
          if (searchComp.title) {
            onlineSearch.title = searchComp.title;
          }
          if (searchComp.search_btn_bg) {
            onlineSearch.search_btn_bg = searchComp.search_btn_bg;
          }
          if (searchComp.search_btn_icon) {
            onlineSearch.search_btn_icon = searchComp.search_btn_icon;
          }
          if (searchComp.search_btn_icon_color) {
            onlineSearch.search_btn_icon_color =
              searchComp.search_btn_icon_color;
          }
          if (
            searchComp.hide_search_keyword === 1 ||
            searchComp.hide_search_keyword === 0
          ) {
            onlineSearch.hide_search_keyword = searchComp.hide_search_keyword;
          }
          if (searchComp.search_text_hint) {
            onlineSearch.search_text_hint = searchComp.search_text_hint;
          }
          if (searchComp.search_by_hint) {
            onlineSearch.search_by_hint = searchComp.search_by_hint;
          }
          if (searchComp.search_text_lines_count) {
            onlineSearch.search_text_lines_count = Number(
              searchComp.search_text_lines_count
            );
          }
          if (searchComp.image_url) {
            onlineSearch.image_url = searchComp.image_url;
          }
          if (searchComp.search_by_date) {
            onlineSearch.search_by_date = 1;
          } else {
            onlineSearch.search_by_date = 0;
          }
          if (searchComp.search_by_time) {
            onlineSearch.search_by_time = 1;
          } else {
            onlineSearch.search_by_time = 0;
          }
          onlineTab.search = onlineSearch;
        }
      } else if (tab.type === TabTypes.SETTINGS) {
        const helpSettings = tab.component.find(
          comp => comp.component_type === ComponentTypes.SETTINGS_HELP
        );
        if (helpSettings) {
          const menuSetting: OnlineMenu = {
            link: ComponentTypes.SETTINGS_HELP
          };
          settingsMenuArray.push(menuSetting);
        }
        const accountSettings = tab.component.find(
          comp => comp.component_type === ComponentTypes.SETTINGS_ACCOUNT
        );
        if (accountSettings) {
          const menuSetting: OnlineMenu = {
            link: ComponentTypes.SETTINGS_ACCOUNT
          };
          settingsMenuArray.push(menuSetting);
        }
        const chatSettings = tab.component.find(
          comp => comp.component_type === ComponentTypes.SETTINGS_CHAT
        );
        if (chatSettings) {
          const menuSetting: OnlineMenu = {
            link: ComponentTypes.SETTINGS_CHAT
          };
          settingsMenuArray.push(menuSetting);
        }
        const mediaSettings = tab.component.find(
          comp => comp.component_type === ComponentTypes.SETTINGS_MEDIA
        );
        if (mediaSettings) {
          const menuSetting: OnlineMenu = {
            link: ComponentTypes.SETTINGS_MEDIA
          };
          settingsMenuArray.push(menuSetting);
        }
        const notificationSettings = tab.component.find(
          comp => comp.component_type === ComponentTypes.SETTINGS_NOTIFICATION
        );
        if (notificationSettings) {
          const menuSetting: OnlineMenu = {
            link: ComponentTypes.SETTINGS_NOTIFICATION
          };
          settingsMenuArray.push(menuSetting);
        }
        if (settingsMenuArray.length > 0) {
          onlineTab.menu = settingsMenuArray;
        }
      } else if (sectionTabsAppClass && tab.type === TabTypes.SECTION) {
        tabSections = sectionTabsAppClass.container.filter(
          cont =>
            cont.container_type === ContainerTypes.TAB &&
            cont.parent_tab_ref === tab.ref
        );
        const sectionsConfig = getOnlineTabsConfig(tabSections);
        onlineTab.sections = sectionsConfig;
      }

      const subType = tab.sub_type
        ? getSubTypeMapping(tab.type, tab.sub_type)
        : null;

      onlineTab.type = subType
        ? subType
        : tab.type === TabTypes.PAGE
        ? TabTypes.WEBVIEW
        : tab.type;
      if (onlineTab.type === TabTypes.MAP) {
        onlineTab.map = tab.map;
      }
      if (tab.menu_id) {
        onlineTab.menu_id = tab.menu_id;
      }
      if (tab.layout) {
        onlineTab.layout = tab.layout;
      }
      if (tab.store_id) {
        onlineTab.store_id = tab.store_id;
      }
      if (tab.title) {
        onlineTab.title = tab.title;
        // always take the title_ios from title and make each letter capital
        onlineTab.title_ios = ucfirst(tab.title); // copy ios value from android for now as no web conf for it
      }
      if (tab.talk_admin_tag) {
        onlineTab.talk_admin_tag = tab.talk_admin_tag;
      }
      if (tab.post_tag) {
        onlineTab.post_tag = tab.post_tag;
      }
      onlineTab.access = tab.access;
      if (tab.icon) {
        onlineTab.icon = tab.icon;
      }
      onlineTab.icon_ios = tab.icon_ios ? tab.icon_ios : tab.icon; // copy ios value from android for now as no web conf for it
      onlineTab.id = tab.id;
      onlineTab.options = tab.options ? tab.options : '0';
      onlineTab.generic_qr = tab.generic_qr;
      if (tab.chat_id) {
        onlineTab.chat_id = tab.chat_id;
      }
      if (tab.google_sync !== null && tab.google_sync !== undefined) {
        onlineTab.google_sync = tab.google_sync;
      }
      if (tab.view_style !== null && tab.view_style !== undefined) {
        onlineTab.view_style = Number(tab.view_style);
      }
      if (tab.limit !== null && tab.limit !== undefined) {
        onlineTab.limit = Number(tab.limit);
      }
      if (tab.ads) {
        // if (!tab.ads.cell_count) {
        //   tab.ads.cell_count = 5;
        // }
        onlineTab.ads = tab.ads;
      }
      if (tab.message) {
        onlineTab.message = tab.message;
      }

      onlineTab.show_invite_friends = tab.show_invite_friends;

      if (tab.desc) {
        onlineTab.desc = tab.desc;
      }
      if (tab.image_url) {
        onlineTab.image_url = tab.image_url;
      }
      if (tab.video_url) {
        onlineTab.video_url = tab.video_url;
      }
      if (tab.text_color) {
        onlineTab.text_color = tab.text_color;
      }
      if (tab.bg_color) {
        onlineTab.bg_color = tab.bg_color;
      }
      if (tab.section_style) {
        onlineTab.section_style = tab.section_style;
      }
      onlineTabArray.push(onlineTab);
    });
  }

  return onlineTabArray;
};

const getSubTypeMapping = (tabType: string, subType: string): string => {
  let res = null;
  if (TabTypes.OPENCHAT === tabType) {
    if (!subType) {
      res = 'open_contact';
    } else {
      switch (subType) {
        case OpenChatTypes.CONTACT:
          res = 'open_contact';
          break;
        case OpenChatTypes.BOT:
          res = 'open_bot';
          break;
        case OpenChatTypes.GROUP:
          res = 'open_group';
          break;
      }
    }
  } else if (TabTypes.MYLIST === tabType) {
    if (!subType) {
      res = 'contact';
    } else {
      switch (subType) {
        case MyListTabTypes.CONTACT:
          res = 'contact';
          break;
        case MyListTabTypes.BOT:
          res = 'bot';
          break;
        case MyListTabTypes.GROUP:
          res = 'group';
          break;
        case MyListTabTypes.CHANNEL:
          res = 'channel_list';
          break;
        case MyListTabTypes.BOOKING:
          res = 'booking';
          break;
      }
    }
  }
  return res;
};

const constructAppClass = (
  localAppClass: LocalAppClass,
  containers: LocalContainer[],
  components: LocalComponent[],
  items: LocalItem[],
  subItems: LocalItem[],
  systemConfig?: SystemConfig
): AppClass => {
  const appClass = getAppClassFromLocalAppClass(localAppClass, systemConfig);
  const containersArray: Container[] = [];
  const localContainers = containers.filter(
    container =>
      container.appClassName === localAppClass.name &&
      container.parentClassRef === localAppClass.ref
  );

  if (localContainers) {
    const sortedContainers = localContainers.sort(ascendingly);
    if (
      localAppClass.default_message &&
      sortedContainers
        .map(tab => tab.id)
        .indexOf(localAppClass.default_message) !== -1
    ) {
      const defaultClassConfig: DefaultClassConfig = {};
      defaultClassConfig.message = localAppClass.default_message;
      appClass.default = defaultClassConfig;
    } else {
      const defaultClassConfig: DefaultClassConfig = {};
      defaultClassConfig.message = sortedContainers[0]
        ? sortedContainers[0].id
        : EMPTY_STRING;
      appClass.default = defaultClassConfig;
    }

    sortedContainers.forEach(cont => {
      const localComponents = components.filter(
        component =>
          component.appClassName === localAppClass.name &&
          component.parentContainerRef === cont.ref
      );

      const container = getContainerFromLocalContainer(cont);

      if (localComponents) {
        const componentsArray: Component[] = [];
        const sortedComponents =
          localAppClass.name === AppClassNames.SPLASH &&
          cont.container_type === ContainerTypes.HEADER
            ? localComponents.sort(ascendingly)
            : localComponents;
        //
        sortedComponents.forEach(localComp => {
          const localItems = items.filter(
            item =>
              item.appClassName === localAppClass.name &&
              item.parentComponentRef === localComp.ref
          );
          const component: Component = getComponentFromLocalComponent(
            localComp
          );

          if (localItems) {
            const itemsArray: Item[] = [];
            localItems.sort(ascendingly).forEach(localItem => {
              const localSubItems = subItems.filter(
                subItem =>
                  subItem.appClassName === localAppClass.name &&
                  subItem.parentItemRef === localItem.ref
              );
              const item = getItemFromLocalItem(localItem);

              if (localSubItems) {
                const subItemsArray: Item[] = [];
                localSubItems.sort(ascendingly).forEach(localSubItem => {
                  const subItem = getItemFromLocalItem(localSubItem);
                  subItemsArray.push(subItem);
                });
                item.sub_item = subItemsArray;
              }
              itemsArray.push(item);
            });
            component.item = itemsArray;
          }
          componentsArray.push(component);
        });
        container.component = componentsArray;
      }
      containersArray.push(container);
    });
    appClass.container = containersArray;
  }
  return appClass;
};

export const ascendingly = (first: any, second: any) => {
  const firstOrder = first.order ? first.order : 0;
  const secondOrder = second.order ? second.order : 0;
  if (firstOrder > secondOrder) {
    return 1;
  } else if (firstOrder < secondOrder) {
    return -1;
  }
  return 0;
};

export const getTabKeys = (tabComponents: TabComponent[]): TabKey[] => {
  const tabKeys: TabKey[] = [];
  tabComponents.forEach(tab => {
    switch (tab.component.component_type) {
      case ComponentTypes.WALLET: {
        const tabKey1: TabKey = {};
        const tabKey2: TabKey = {};
        const tabKey3: TabKey = {};
        // title key
        tabKey1.tab = tab.tabID;
        tabKey1.key = CHAT_DEFAULTS_JSON_KEYS.title;
        tabKey1.value = tab.component.title;
        tabKeys.push(tabKey1);
        // target key
        tabKey2.tab = tab.tabID;
        tabKey2.key = CHAT_DEFAULTS_JSON_KEYS.max_limit;
        tabKey2.value =
          tab.component.target && tab.component.target >= 0
            ? tab.component.target + EMPTY_STRING
            : EMPTY_STRING;
        tabKeys.push(tabKey2);
        // desc key
        tabKey3.tab = tab.tabID;
        tabKey3.key = CHAT_DEFAULTS_JSON_KEYS.desc;
        tabKey3.value = tab.component.desc;
        tabKeys.push(tabKey3);
        break;
      }
      case ComponentTypes.CARD: {
        const tabKey1: TabKey = {};
        const tabKey2: TabKey = {};
        const tabKey3: TabKey = {};
        // title key
        tabKey1.tab = tab.tabID;
        tabKey1.key = CHAT_DEFAULTS_JSON_KEYS.title;
        tabKey1.value = tab.component.title;
        tabKeys.push(tabKey1);
        // desc key
        tabKey2.tab = tab.tabID;
        tabKey2.key = CHAT_DEFAULTS_JSON_KEYS.desc;
        tabKey2.value = tab.component.desc;
        tabKeys.push(tabKey2);
        // image url key
        tabKey3.tab = tab.tabID;
        tabKey3.key = CHAT_DEFAULTS_JSON_KEYS.image_url;
        tabKey3.value = tab.component.image_url;
        tabKeys.push(tabKey3);
        break;
      }
    }
  });
  return tabKeys.filter(
    tabKey => tabKey.value && tabKey.value !== EMPTY_STRING
  );
};

export const getTabTags = (containers: LocalContainer[]): TagItem[] => {
  const tabTagItems: TagItem[] = [];
  const tagTabs = containers.filter(
    cont =>
      cont.container_type === ContainerTypes.TAB &&
      ((cont.talk_admin_tag_values && cont.talk_admin_tag_values.length > 0) ||
        (cont.post_tag_values && cont.post_tag_values.length > 0))
  );
  if (tagTabs && tagTabs.length > 0) {
    tagTabs.forEach(tab => {
      if (tab.talk_admin_tag_values && tab.talk_admin_tag_values.length > 0) {
        const tagItem: TagItem = {};
        tagItem.id = tab.ref;
        tagItem.type = ADMIN_TAG_TYPE;
        tagItem.value = tab.talk_admin_tag_values;
        tabTagItems.push(tagItem);
      }
      if (tab.post_tag_values && tab.post_tag_values.length > 0) {
        const tagItem: TagItem = {};
        tagItem.id = tab.ref;
        tagItem.type = POST_TAG_TYPE;
        tagItem.value = tab.post_tag_values;
        tabTagItems.push(tagItem);
      }
    });
  }
  return tabTagItems;
};

const ucfirst = (name: string): string => {
  if (name) {
    const oneSpacedString = name.trim().replace(/\s\s+/g, ' ');
    return oneSpacedString
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  } else {
    return name;
  }
};

// Get Parent Container from Regular Component
const getLocalContainerFromContainer = (
  container: Container
): LocalContainer => {
  const cont: LocalContainer = {};
  cont.ref = container.ref ? container.ref : uuidv4();
  cont.parentTabRef = container.parent_tab_ref;
  cont.container_type = container.container_type;
  cont.type = container.type;
  cont.icon = container.icon;
  cont.title = container.title;
  cont.image_url = container.image_url;
  cont.text_color = container.text_color;
  cont.bg_color = container.bg_color;
  cont.video_url = container.video_url;
  cont.icon_ios = container.icon_ios;
  cont.title_ios = ucfirst(container.title); // always take the title_ios from title and make each letter capital
  cont.talk_admin_tag = container.talk_admin_tag;
  cont.post_tag = container.post_tag;
  cont.talk_admin_tag_values = container.talk_admin_tag_values;
  cont.post_tag_values = container.post_tag_values;
  cont.access = container.access;
  cont.id = container.id;
  cont.bg = container.bg;
  cont.min_components = container.min_components;
  cont.max_components = container.max_components;
  cont.image_set = container.image_set;
  cont.desc = container.desc;
  cont.key = container.key;
  cont.default = container.default;
  cont.hint = container.hint;
  cont.max = container.max;
  cont.min = container.min;
  cont.exclude = container.exclude;
  cont.lines = container.lines;
  cont.error = container.error;
  cont.required = container.required;
  cont.link = container.link;
  cont.options = container.options ? container.options + '' : '0';
  cont.sub_type = container.sub_type;
  cont.chat_id = container.chat_id;
  cont.google_sync = container.google_sync;
  cont.view_style = container.view_style;
  cont.message = container.message;
  cont.is_on = container.is_on;
  cont.limit = container.limit;
  cont.ads = container.ads;
  cont.url = container.url;
  cont.page_id = container.page_id;
  cont.page_ref = container.page_ref;
  cont.template_id = container.template_id;
  cont.map = container.map;
  cont.map_menu_id = container.map_menu_id;
  cont.list = container.list;
  cont.color = container.color;
  cont.link_color = container.link_color;
  cont.section_style = container.section_style;
  cont.ui_module_id = container.ui_module_id;
  cont.menu_id = container.menu_id;
  cont.features_count = container.features_count;
  cont.store_id = container.store_id;
  cont.items = container.items;
  cont.layout = container.layout;
  cont.generic_qr = container.generic_qr;
  cont.show_invite_friends = container.show_invite_friends;
  return cont;
};

export const getLocalComponentFromComponent = (
  component: Component
): LocalComponent => {
  const comp: LocalComponent = {};
  comp.ref = component.ref ? component.ref : uuidv4();
  comp.component_type = component.component_type;
  comp.column = component.column;
  comp.sticky_id = component.sticky_id ? component.sticky_id : 1;
  comp.sticky_bg = component.sticky_bg;
  comp.sticky_text_color = component.sticky_text_color;
  comp.sticky_visible = component.sticky_visible;
  comp.type = component.type;
  comp.icon = component.icon;
  comp.image = component.image;
  comp.title = component.title;
  comp.bg_image = component.bg_image;
  comp.text_color = component.text_color;
  comp.progress_earned_color = component.progress_earned_color;
  comp.progress_target_color = component.progress_target_color;
  comp.search_btn_bg = component.search_btn_bg;
  comp.search_btn_icon = component.search_btn_icon;
  comp.search_btn_icon_color = component.search_btn_icon_color;
  comp.hide_search_keyword = component.hide_search_keyword;
  comp.search_text_hint = component.search_text_hint;
  comp.search_by_hint = component.search_by_hint;
  comp.search_text_lines_count = component.search_text_lines_count;
  comp.subtitle = component.subtitle;
  comp.min_items = component.min_items;
  comp.max_items = component.max_items;
  comp.opened = component.opened;
  comp.currentlyOpened = component.opened;
  comp.hidden = component.hidden;
  comp.desc = component.desc;
  comp.key = component.key;
  comp.url = component.url;
  comp.image_url = component.image_url;
  comp.local_image = component.image_url;
  comp.target = component.target;
  comp.wv_url = component.wv_url;
  comp.nearby = component.nearby;
  comp.radius = component.radius;
  comp.search_by_date = component.search_by_date;
  comp.search_by_time = component.search_by_time;
  comp.item = component.item;
  comp.options = component.options;
  if (component.image_set) {
    comp.image_set = Object.assign({}, component.image_set);
  }
  return comp;
};

export const getLocalItemFromItem = (item: Item): LocalItem => {
  const newItem: LocalItem = {};
  newItem.ref = item.ref ? item.ref : uuidv4();
  newItem.icon = item.icon;
  newItem.icon_highlight = item.icon_highlight;
  newItem.color_highlight = item.color_highlight;
  newItem.icon_ios = item.icon_ios;
  newItem.link = item.link;
  newItem.title = item.title;
  newItem.limit = item.limit;
  newItem.ads = item.ads;
  newItem.message = item.message;
  if (item.data) {
    newItem.data = [...item.data];
  }
  newItem.key = item.key;
  newItem.sub_type = item.sub_type;
  newItem.url = item.url;
  newItem.page_id = item.page_id;
  newItem.page_ref = item.page_ref;
  newItem.template_id = item.template_id;
  newItem.separator = item.separator;
  newItem.google_sync = item.google_sync;
  newItem.view_style = item.view_style;
  newItem.chat_id = item.chat_id;
  newItem.options = item.options;
  newItem.color = item.color;
  newItem.type = item.type;
  newItem.bg = item.bg;
  newItem.fixed = item.fixed;
  newItem.readonly = item.readonly;
  newItem.ui_module_id = item.ui_module_id;
  newItem.map = item.map;
  newItem.map_menu_id = item.map_menu_id;
  newItem.desc = item.desc;
  newItem.disabled = item.disabled;
  newItem.video_url = item.video_url;
  newItem.radius = item.radius;
  newItem.nearby = item.nearby;
  newItem.button = item.button ? [...item.button] : [];
  newItem.menu_id = item.menu_id;
  newItem.features_count = item.features_count;
  newItem.store_id = item.store_id;
  newItem.component = item.component;
  newItem.image_url = item.image_url;
  newItem.text_color = item.text_color;
  newItem.bg_color = item.bg_color;
  newItem.generic_qr = item.generic_qr;
  newItem.show_invite_friends = item.show_invite_friends;
  return newItem;
};

const getAppClassFromLocalAppClass = (
  localAppClass: LocalAppClass,
  systemConfig?: SystemConfig
): AppClass => {
  // console.log(localAppClass.name);
  const appClass: AppClass = {};
  appClass.ref = localAppClass.ref;
  appClass.color = localAppClass.color;
  appClass.icon_color = localAppClass.icon_color;
  appClass.icon_color_ios = localAppClass.icon_color_ios;
  appClass.color_ios = localAppClass.color_ios;
  appClass.talk_admin_bg = systemConfig.color.primary;
  appClass.bg = localAppClass.bg;
  appClass.type = localAppClass.type;
  appClass.min_containers = localAppClass.min_containers;
  appClass.max_containers = localAppClass.max_containers;
  appClass.profile_visible = localAppClass.profile_visible;
  appClass.opened = localAppClass.opened;
  if (localAppClass.splash) {
    appClass.splash = { ...localAppClass.splash };
  }
  if (localAppClass.name === AppClassNames.TABS) {
    const seq: Sequence = {};
    seq.feed = localAppClass.feed_seq ? localAppClass.feed_seq : 0;
    seq.membership = localAppClass.membership_seq
      ? localAppClass.membership_seq
      : 0;
    seq.wallet = localAppClass.wallet_seq ? localAppClass.wallet_seq : 0;
    seq.web_view = localAppClass.webview_seq ? localAppClass.webview_seq : 0;
    seq.channel = localAppClass.channel_seq ? localAppClass.channel_seq : 0;
    seq.chat = localAppClass.chat_seq ? localAppClass.chat_seq : 0;
    seq.settings = localAppClass.settings_seq ? localAppClass.settings_seq : 0;
    seq.calendar = localAppClass.calendar_seq ? localAppClass.calendar_seq : 0;
    seq.booking = localAppClass.booking_seq ? localAppClass.booking_seq : 0;
    seq.invitation = localAppClass.invitation_seq
      ? localAppClass.invitation_seq
      : 0;
    seq.open_chat = localAppClass.open_chat_seq
      ? localAppClass.open_chat_seq
      : 0;
    seq.mylist = localAppClass.mylist_seq ? localAppClass.mylist_seq : 0;
    seq.qr = localAppClass.qr_seq ? localAppClass.qr_seq : 0;
    seq.map = localAppClass.map_seq ? localAppClass.map_seq : 0;
    seq.search = localAppClass.search_seq ? localAppClass.search_seq : 0;
    seq.call_log = localAppClass.call_log_seq ? localAppClass.call_log_seq : 0;
    seq.section = localAppClass.section_seq ? localAppClass.section_seq : 0;
    seq.bundle = localAppClass.bundle_seq ? localAppClass.bundle_seq : 0;
    seq.package = localAppClass.package_seq ? localAppClass.package_seq : 0;
    seq.video = localAppClass.video_seq ? localAppClass.video_seq : 0;
    seq.qr_page = localAppClass.qr_page_seq ? localAppClass.qr_page_seq : 0;
    seq.map_search = localAppClass.map_search_seq
      ? localAppClass.map_search_seq
      : 0;
    appClass.sequence = seq;
  }
  return appClass;
};

const getContainerFromLocalContainer = (cont: LocalContainer): Container => {
  const container: Container = {};
  container.ref = cont.ref;
  if (cont.parentTabRef) {
    container.parent_tab_ref = cont.parentTabRef;
  }
  container.container_type = cont.container_type;
  container.type = cont.type;
  container.icon = cont.icon;
  container.icon_ios = cont.icon_ios;
  container.title_ios = ucfirst(cont.title); // always take the title_ios from title and make each letter capital
  container.title = cont.title;
  container.image_url = cont.image_url;
  container.video_url = cont.video_url;
  container.text_color = cont.text_color;
  container.bg_color = cont.bg_color;
  container.talk_admin_tag = cont.talk_admin_tag;
  container.post_tag = cont.post_tag;
  container.talk_admin_tag_values = cont.talk_admin_tag_values;
  container.post_tag_values = cont.post_tag_values;
  container.access = cont.access;
  container.id = cont.id;
  container.color = cont.color;
  container.min_components = cont.min_components;
  container.max_components = cont.max_components;
  container.bg = cont.bg;
  container.image_set = cont.image_set;
  container.desc = cont.desc;
  container.key = cont.key;
  container.default = cont.default;
  container.hint = cont.hint;
  container.max = cont.max;
  container.min = cont.min;
  container.lines = cont.lines;
  container.exclude = cont.exclude;
  container.error = cont.error;
  container.items = cont.items;
  container.required = cont.required;
  container.link = cont.link;
  container.options = cont.options ? cont.options + '' : '0';
  container.sub_type = cont.sub_type;
  container.chat_id = cont.chat_id;
  container.google_sync = cont.google_sync;
  container.view_style = cont.view_style;
  container.message = cont.message;
  container.is_on = cont.is_on;
  container.limit = cont.limit;
  container.ads = cont.ads;
  container.url = cont.url;
  container.page_id = cont.page_id;
  container.page_ref = cont.page_ref;
  container.template_id = cont.template_id;
  container.map = cont.map;
  container.map_menu_id = cont.map_menu_id;
  container.link_color = cont.link_color;
  container.list = cont.list;
  container.section_style = cont.section_style;
  container.ui_module_id = cont.ui_module_id;
  container.features_count = cont.features_count;
  container.store_id = cont.store_id;
  container.menu_id = cont.menu_id;
  container.layout = cont.layout;
  container.generic_qr = cont.generic_qr;
  container.menu = cont.menu;
  container.show_invite_friends = cont.show_invite_friends;
  return container;
};

const getComponentFromLocalComponent = (
  localComp: LocalComponent
): Component => {
  const component: Component = {};
  component.ref = localComp.ref;
  component.component_type = localComp.component_type;
  component.color = localComp.color;
  component.secondary_color = localComp.secondary_color;
  component.bg = localComp.bg;
  component.type = localComp.type;
  component.column = localComp.column;
  component.sticky_id = localComp.sticky_id;
  component.sticky_bg = localComp.sticky_bg;
  component.sticky_text_color = localComp.sticky_text_color;
  component.sticky_visible = localComp.sticky_visible;
  component.icon = localComp.icon;
  component.icon_color = localComp.icon_color;
  component.image = localComp.image;
  component.title = localComp.title;
  component.bg_image = localComp.bg_image;
  component.text_color = localComp.text_color;
  component.progress_earned_color = localComp.progress_earned_color;
  component.progress_target_color = localComp.progress_target_color;
  component.search_btn_bg = localComp.search_btn_bg;
  component.search_btn_icon = localComp.search_btn_icon;
  component.search_btn_icon_color = localComp.search_btn_icon_color;
  component.hide_search_keyword = localComp.hide_search_keyword;
  component.search_text_hint = localComp.search_text_hint;
  component.search_by_hint = localComp.search_by_hint;
  component.search_text_lines_count = localComp.search_text_lines_count;
  component.subtitle = localComp.subtitle;
  component.min_items = localComp.min_items;
  component.max_items = localComp.max_items;
  component.opened = localComp.opened;
  component.hidden = localComp.hidden;
  component.desc = localComp.desc;
  component.key = localComp.key;
  component.url = localComp.url;
  component.image_url = localComp.image_url;
  component.target = localComp.target;
  component.wv_url = localComp.wv_url;
  component.nearby = localComp.nearby;
  component.radius = localComp.radius;
  component.search_by_date = localComp.search_by_date;
  component.search_by_time = localComp.search_by_time;
  component.item = localComp.item;
  component.options = localComp.options;
  if (localComp.image_set) {
    component.image_set = localComp.image_set;
  }
  return component;
};

// I can use this for Item or Sub Item
const getItemFromLocalItem = (localItem: LocalItem): Item => {
  const item: Item = {};
  item.ref = localItem.ref;
  item.icon = localItem.icon;
  item.icon_highlight = localItem.icon_highlight;
  item.color_highlight = localItem.color_highlight;
  item.icon_ios = localItem.icon_ios;
  item.link = localItem.link;
  item.title = localItem.title;
  item.limit = localItem.limit;
  item.ads = localItem.ads;
  item.message = localItem.message;
  if (localItem.data) {
    item.data = [...localItem.data];
  }
  item.key = localItem.key;
  item.sub_type = localItem.sub_type;
  item.url = localItem.url;
  item.page_id = localItem.page_id;
  item.page_ref = localItem.page_ref;
  item.template_id = localItem.template_id;
  item.separator = localItem.separator;
  item.google_sync = localItem.google_sync;
  item.view_style = localItem.view_style;
  item.chat_id = localItem.chat_id;
  item.options = localItem.options;
  item.color = localItem.color;
  item.bg = localItem.bg;
  item.readonly = localItem.readonly;
  item.fixed = localItem.fixed;
  item.type = localItem.type;
  item.ui_module_id = localItem.ui_module_id;
  item.map = localItem.map;
  item.map_menu_id = localItem.map_menu_id;
  item.desc = localItem.desc;
  item.disabled = localItem.disabled;
  item.video_url = localItem.video_url;
  item.image_url = localItem.image_url;
  item.bg_color = localItem.bg_color;
  item.text_color = localItem.text_color;
  item.nearby = localItem.nearby;
  item.radius = localItem.radius;
  item.button = localItem.button ? [...localItem.button] : [];
  item.menu_id = localItem.menu_id;
  item.store_id = localItem.store_id;
  item.features_count = localItem.features_count;
  item.component = localItem.component;
  item.generic_qr = localItem.generic_qr;
  item.show_invite_friends = localItem.show_invite_friends;
  return item;
};

export function extractComponentData(
  defaultComponent: Component,
  parentContainer: LocalContainer,
  systemConfig: SystemConfig
): ContainerChildren {
  const components: LocalComponent[] = [];
  const items: LocalItem[] = [];
  const sub_items: LocalItem[] = [];
  const comp = getLocalComponentFromComponent(defaultComponent);
  comp.parentContainerRef = parentContainer.ref;
  handleComponentColors(
    parentContainer.appClassName,
    systemConfig,
    comp,
    defaultComponent
  );
  setDefaultBgColorForComponent(
    parentContainer.appClassName,
    systemConfig,
    comp,
    defaultComponent
  );
  if (defaultComponent.image_set) {
    comp.image_set = Object.assign({}, defaultComponent.image_set);
  }
  comp.appClassName = parentContainer.appClassName;
  comp.containerType = parentContainer.container_type;
  components.push(comp);
  if (defaultComponent.item) {
    defaultComponent.item.forEach((item, i) => {
      const newItem = getLocalItemFromItem(item);
      newItem.parentComponentRef = comp.ref;

      if (
        (!item.bg || item.bg === EMPTY_STRING) &&
        parentContainer.appClassName === AppClassNames.TABS &&
        defaultComponent.component_type === ComponentTypes.FOOTER
      ) {
        newItem.bg = systemConfig.color.primary_bg;
      }
      newItem.order = i;
      newItem.appClassName = parentContainer.appClassName;
      newItem.containerType = parentContainer.container_type;
      newItem.componentType = comp.component_type;
      items.push(newItem);
      if (item.sub_item) {
        item.sub_item.forEach((subItem, i) => {
          const newSubItem = getLocalItemFromItem(subItem);
          newSubItem.itemType = item.link;
          newSubItem.parentComponentRef = comp.ref;
          newSubItem.order = i;
          newSubItem.appClassName = parentContainer.appClassName;
          sub_items.push(newSubItem);
        });
      }
    });
  }
  const children: ContainerChildren = { components, items, sub_items };
  return children;
}

// Check if Already having a Map

export function alreadyHadMap(
  containers: LocalContainer[],
  items: LocalItem[]
): boolean {
  let tabMaps: LocalContainer[] = [];
  tabMaps = containers.filter(tab => tab.type === TabTypes.MAP);
  let navMaps: LocalItem[] = [];
  if (items && items.length > 0) {
    navMaps = (<LocalItem[]>items).filter(
      item =>
        item.link === TabTypes.MAP &&
        item.appClassName === AppClassNames.NAV_MENU
    );
  }
  return (tabMaps && tabMaps.length > 0) || (navMaps && navMaps.length > 0)
    ? true
    : false;
}
