import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as AuthMethods from 'src/models/IAuth';

import { AuthService } from 'src/app/core/auth.service';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
import { HtttpMethodErrors, TERMS_LINK } from 'src/models/constants';
import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';

@Component({
  selector: 'app-support-log-in',
  templateUrl: './support-log-in.component.html',
  styleUrls: ['./support-log-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportLogInComponent implements OnInit {
  form: FormGroup;
  waiting = false;
  isPasswordForgot = false;

  termsLink = TERMS_LINK;

  constructor(
    private _validateForm: FormValidatorsService,
    private _ref: ChangeDetectorRef,
    public authService: AuthService,
    public appManagementService: AppMgmtService
  ) {}

  ngOnInit() {
    this.form = this.setFormGroup();
  }

  setFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        noWhitespaceValidator
      ]),
      su: new FormControl('', [Validators.required, Validators.email])
    });
  }

  onSubmit() {
    this.waiting = true;
    if (this.form.valid) {
      this.authService.loginByEmail(this.form.value).subscribe(response => {
        const res = <AuthMethods.HttpResult>response;
        if (res.result === 0) {
          this.authService.reciveAuthToken(res.token);
        } else if (
          res.error === HtttpMethodErrors.AUTH_ERROR ||
          res.error === HtttpMethodErrors.AUTH_ERROR_2
        ) {
          this.email.setErrors({ wrongCredentials: true });
          this.password.setErrors({ wrongCredentials: true });
          this.waiting = false;
          this._ref.detectChanges();
        }
      });
    } else {
      this.waiting = false;
      this._validateForm.validateAllFormFields(this.form);
      this._ref.detectChanges();
    }
  
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }
  get customerEmail() {
    return this.form.get('su');
  }
}
