/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../abstract-frames/create-item-frame/create-item-frame.component.ngfactory";
import * as i2 from "../../../../abstract-frames/create-item-frame/create-item-frame.component";
import * as i3 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component.ngfactory";
import * as i4 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component";
import * as i5 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component.ngfactory";
import * as i6 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i9 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i10 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i11 from "@angular/material/tabs";
import * as i12 from "../../../../accessors/show-if-has-privilege.directive";
import * as i13 from "../../../../store/root-store.selectors";
import * as i14 from "../../../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i15 from "../../../../shared/components/modal-container/modal-container.component";
import * as i16 from "../../sub-chats/sub-chat-form/sub-chat-form.component.ngfactory";
import * as i17 from "../../sub-chats/sub-chat-form/sub-chat-form.component";
import * as i18 from "@angular/forms";
import * as i19 from "../../../../validators/form-validators.service";
import * as i20 from "../../../../core/ui.service";
import * as i21 from "../../sub-chats/sub-chats.service";
import * as i22 from "../../mobile-store/mobile-store.service";
import * as i23 from "../../locations/locations.service";
import * as i24 from "../../members/members.service";
import * as i25 from "../../app-mgmt/appMgmt.service";
import * as i26 from "../../../../abstract-frames/selector-frame/selector-frame.component.ngfactory";
import * as i27 from "../../../../abstract-frames/selector-frame/selector-frame.component";
import * as i28 from "@angular/platform-browser/animations";
import * as i29 from "../../../../accessors/hide-if-sub-chat.directive";
import * as i30 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i31 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i32 from "./sub-chats-selector.component";
var styles_SubChatsSelectorComponent = [];
var RenderType_SubChatsSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubChatsSelectorComponent, data: {} });
export { RenderType_SubChatsSelectorComponent as RenderType_SubChatsSelectorComponent };
function View_SubChatsSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-create-item-frame", [], null, [[null, "addnewNotfiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addnewNotfiy" === en)) {
        var pd_0 = (_co.addNewEvent(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CreateItemFrameComponent_0, i1.RenderType_CreateItemFrameComponent)), i0.ɵdid(2, 114688, null, 0, i2.CreateItemFrameComponent, [], null, { addnewNotfiy: "addnewNotfiy" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Add new group "])), (_l()(), i0.ɵeld(5, 0, null, 1, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" You can add new Group/Channel from the \"Add new Group/Channel\u201D field. Simply click the \u201C+ Add new Group/Channel\u201D button and Create new Group/Channel. "]))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_SubChatsSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Channels "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", ((_co.subChannelFet == null) ? null : _co.subChannelFet.symbolImage), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_SubChatsSelectorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SelectableCellFrameComponent_0, i3.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i4.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.symbolColor); var currVal_1 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.symbolImage); var currVal_2 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.coverColor); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.localMedia); var currVal_4 = _co.isProductSelected(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_6 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); var currVal_7 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_SubChatsSelectorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.subChatsService.getNextPage(_co.chatTypes.CHANNEL) !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.subChatsService.getPreviousPage(_co.chatTypes.CHANNEL) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_RoundPagingBtnComponent_0, i5.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i6.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SubChatsSelectorComponent_6)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.subChatsService.getPagedSubChatsByType$(_co.chatTypes.CHANNEL))); var currVal_1 = _co.TrackByFunction; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_SubChatsSelectorComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i8.View_EmptyItemFrameComponent_0, i8.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i9.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.symbolImage); var currVal_1 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.emptyTitle); var currVal_2 = ((_co.subChannelFet == null) ? null : _co.subChannelFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SubChatsSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i10.View_MatTab_0, i10.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i11.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 2, { templateLabel: 0 }), i0.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SubChatsSelectorComponent_4)), i0.ɵdid(5, 16384, [[2, 4]], 0, i11.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_SubChatsSelectorComponent_5)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isSubChannelsEmpty", 2]], 0, 0, null, View_SubChatsSelectorComponent_7)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.subChatsService.getPagedSubChatsByType$(_co.chatTypes.CHANNEL)))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
function View_SubChatsSelectorComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Groups "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", ((_co.subGroupFet == null) ? null : _co.subGroupFet.symbolImage), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_SubChatsSelectorComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SelectableCellFrameComponent_0, i3.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i4.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.symbolColor); var currVal_1 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.symbolImage); var currVal_2 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.coverColor); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.localMedia); var currVal_4 = _co.isProductSelected(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_6 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); var currVal_7 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_SubChatsSelectorComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.subChatsService.getNextPage(_co.chatTypes.GROUP) !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.subChatsService.getPreviousPage(_co.chatTypes.GROUP) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_RoundPagingBtnComponent_0, i5.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i6.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SubChatsSelectorComponent_11)), i0.ɵdid(4, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.subChatsService.getPagedSubChatsByType$(_co.chatTypes.GROUP))); var currVal_1 = _co.TrackByFunction; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_SubChatsSelectorComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i8.View_EmptyItemFrameComponent_0, i8.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i9.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.symbolImage); var currVal_1 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.emptyTitle); var currVal_2 = ((_co.subGroupFet == null) ? null : _co.subGroupFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SubChatsSelectorComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i10.View_MatTab_0, i10.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i11.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 4, { templateLabel: 0 }), i0.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_SubChatsSelectorComponent_9)), i0.ɵdid(5, 16384, [[4, 4]], 0, i11.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_SubChatsSelectorComponent_10)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isSubGroupsEmpty", 2]], 0, 0, null, View_SubChatsSelectorComponent_12)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.subChatsService.getPagedSubChatsByType$(_co.chatTypes.GROUP)))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
function View_SubChatsSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubChatsSelectorComponent_3)), i0.ɵdid(2, 212992, null, 0, i12.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i13.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubChatsSelectorComponent_8)), i0.ɵdid(4, 212992, null, 0, i12.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i13.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.listchatgroup; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.privilegesName.listchatgroup; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SubChatsSelectorComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.addNewEvent(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_ModalContainerComponent_0, i14.RenderType_ModalContainerComponent)), i0.ɵdid(1, 49152, null, 0, i15.ModalContainerComponent, [i0.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-sub-chat-form", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.addNewEvent(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_SubChatFormComponent_0, i16.RenderType_SubChatFormComponent)), i0.ɵdid(3, 770048, null, 0, i17.SubChatFormComponent, [i0.ChangeDetectorRef, i18.FormBuilder, i19.FormValidatorsService, i20.UiService, i21.SubChatsService, i22.MobileStoresService, i23.LocationsService, i24.MembersService, i25.AppMgmtService], { createPopup: [0, "createPopup"] }, { closeNotify: "closeNotify" })], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SubChatsSelectorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "app-selector-frame", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i26.View_SelectorFrameComponent_0, i26.RenderType_SelectorFrameComponent)), i0.ɵdid(1, 114688, null, 0, i27.SelectorFrameComponent, [], null, { closeNotify: "closeNotify" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Group/Channel"])), (_l()(), i0.ɵeld(4, 0, null, 4, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubChatsSelectorComponent_1)), i0.ɵdid(6, 212992, null, 0, i12.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i13.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵeld(7, 0, null, 8, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i10.View_MatTabGroup_0, i10.RenderType_MatTabGroup)), i0.ɵdid(9, 3325952, null, 1, i11.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i11.MAT_TABS_CONFIG], [2, i28.ANIMATION_MODULE_TYPE]], null, null), i0.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubChatsSelectorComponent_2)), i0.ɵdid(12, 212992, null, 0, i29.HideIfSubChatDirective, [i0.ViewContainerRef, i0.TemplateRef, i13.RootStoreSelectors, i0.ChangeDetectorRef], { appHideIfSubChat: [0, "appHideIfSubChat"] }, null), (_l()(), i0.ɵeld(13, 0, null, 10, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "div", [["class", "form-submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "app-border-btn", [["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i30.View_BorderBtnComponent_0, i30.RenderType_BorderBtnComponent)), i0.ɵdid(16, 573440, null, 0, i31.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵted(-1, 0, [" Insert "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubChatsSelectorComponent_13)), i0.ɵdid(19, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.privilegesName.editchatgroup; _ck(_v, 6, 0, currVal_0); var currVal_3 = true; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.showCompose; _ck(_v, 19, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 9).dynamicHeight; var currVal_2 = (i0.ɵnov(_v, 9).headerPosition === "below"); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_SubChatsSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sub-chats-selector", [], null, null, null, View_SubChatsSelectorComponent_0, RenderType_SubChatsSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i32.SubChatsSelectorComponent, [i21.SubChatsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubChatsSelectorComponentNgFactory = i0.ɵccf("app-sub-chats-selector", i32.SubChatsSelectorComponent, View_SubChatsSelectorComponent_Host_0, {}, { cancelNotfiy: "cancelNotfiy", selectChatsNotfiy: "selectChatsNotfiy" }, []);
export { SubChatsSelectorComponentNgFactory as SubChatsSelectorComponentNgFactory };
