<div class="attaches-container">
  <!-- Attach Coupon -->
  <div *ngIf="attachCoupon" class="coupon-attache">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-coupons"></use>
    </svg>
    <span>Coupon</span>
    <ngx-barcode
      [bc-value]="attachCoupon?.code"
      [bc-display-value]="false"
      [bc-font-size]="12"
      [bc-element-type]="'img'"
      [bc-background]="'transparent'"
      [bc-width]="1"
      [bc-height]="20"
      [bc-margin]="0"
      [bc-margin-top]="0"
      [bc-margin-bottom]="0"
      [bc-margin-right]="0"
      [bc-margin-left]="0"
    >
    </ngx-barcode>
    <span class="close clickable-cursor" (click)="removeAttachCoupon()">X</span>
  </div>

  <!-- Attach Menu -->
  <div *ngIf="attachMenu" class="menu-attache">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-menus"></use>
    </svg>
    <span>Menu / {{ attachMenu?.menu_name }}</span>
    <span class="close clickable-cursor" (click)="removeAttachMenu()">X</span>
  </div>

  <!-- Attach Sticky -->
  <div *ngIf="attachSticky?.title" class="sticky">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-sticky"></use>
    </svg>
    <span>Sticky /</span>
    <span>{{ attachSticky?.title }} {{ attachSticky?.description }}</span>
    <span class="close clickable-cursor" (click)="removeAttachSticky()">X</span>
  </div>
</div>
