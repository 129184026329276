import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ProfileDispatchers } from 'src/app/store/profile/profile.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';
import { BotScreens } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/profile/profile.dispatchers";
import * as i3 from "../../../store/chatMembers/chatMember.dispatchers";
export class BotsService {
    constructor(_selectors, _profileDispatchers, _chatMemberDispatchers) {
        this._selectors = _selectors;
        this._profileDispatchers = _profileDispatchers;
        this._chatMemberDispatchers = _chatMemberDispatchers;
        this.selectedBot = null;
        this._currentBotScreen = new BehaviorSubject(BotScreens.BOT_LIST);
        this.currentBotScreen$ = this._currentBotScreen.asObservable();
    }
    /** -------------------------Lists ------------------------ */
    get botList$() {
        return this._selectors.getMyBots$();
    }
    get botTemplates$() {
        return this._selectors.getBotTemplates$();
    }
    getPrivileges$(userId) {
        return this._selectors.getMemberPrivileges$(userId);
    }
    /** ------------ Selector -------------------------------- */
    createItemEvent() {
        this.setCurrentScreen(BotScreens.BOT_CREATION);
        this.setSelectedBot(null);
    }
    readItemEvent(bot) {
        this.setCurrentScreen(BotScreens.BOT_VIEW);
        this.setSelectedBot(bot);
    }
    updateItemEvent(bot) {
        this.setCurrentScreen(BotScreens.BOT_EDIT);
        this.setSelectedBot(bot);
        this.getChatMember(bot);
    }
    closeItemCardEvent() {
        this.setCurrentScreen(BotScreens.BOT_LIST);
        this.setSelectedBot(null);
    }
    setSelectedBot(bot) {
        this.selectedBot = bot;
    }
    setCurrentScreen(screen) {
        this._currentBotScreen.next(screen);
    }
    /** -------------IProfile operations--------------------- */
    createBot(name) {
        this._profileDispatchers.createBot(name);
    }
    updateBot(botDetails) {
        this._profileDispatchers.updateBot(botDetails);
    }
    deleteBot(botId) {
        // botId is the IProfile.user_id
        this._profileDispatchers.deleteBot(botId);
    }
    publishBot(botId, username) {
        this._profileDispatchers.publishBot(botId, username);
    }
    cloneBot(templateID) {
        this._profileDispatchers.cloneBotFromTemplate(templateID);
    }
    setAdminPrivileges(userId, privileges) {
        this._profileDispatchers.updateAdminPrivileges(userId, privileges);
    }
    uploadBotImage(userId, file) {
        this._profileDispatchers.uploadBotImage(userId, file);
    }
    /*************************************************************************/
    updateStore(bot) {
        console.log('here i am ');
        this._profileDispatchers.updateStore(bot);
    }
    getBotToken(botId) {
        this._profileDispatchers.requestBotToken(botId);
    }
    getChatMember(bot) {
        this._chatMemberDispatchers.getChatMember(bot);
    }
}
BotsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BotsService_Factory() { return new BotsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ProfileDispatchers), i0.ɵɵinject(i3.ChatMemberDispatchers)); }, token: BotsService, providedIn: "root" });
