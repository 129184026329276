import {
  LocalAppClass,
  LocalContainer,
  LocalComponent,
  LocalItem,
  ContainerChildren
} from 'src/models/ChannelAppLocalConfig';
import {
  uuidv4,
  AppClassNames,
  ContainerTypes,
  TabTypes,
  CHANNEL_APP_SEARCH_CATEGORIES
} from 'src/models/constants';
import { Container, SystemConfig } from 'src/models/ChannelAppConfig';
import { extractComponentData } from '../channelAppConfig/appConfig.middleware';

interface TabID {
  id: string;
  seq: number;
}

export const enum TAB_ID_BASE_WORD {
  FEED = 'feed',
  MEMBERSHIP = 'member',
  WALLET = 'wallet',
  WEB_VIEW = 'webview',
  CHANNELS = 'channel',
  CHATS = 'chat',
  SETTINGS = 'settings',
  CALENDAR = 'event',
  INVITATION = 'invitation',
  OPENCHAT = 'open_chat',
  MYLIST = 'mylist',
  QR = 'qr',
  MAP = 'map',
  SEARCH = 'search',
  BOOKING = 'booking_list',
  CALL_LOG = 'call_log',
  SECTION = 'section',
  BUNDLE = 'bundle',
  VIDEO = 'video',
  PACKAGE = 'package',
  QR_PAGE = 'qr_page',
  MAP_SEARCH = 'map_search'
}

const DEFAULT_TAB_CONTAINER: LocalContainer = {
  container_type: ContainerTypes.TAB,
  icon: 'ic_feeds_books_white_24_dp',
  icon_ios: 'ic_feeds_books_white_24_dp',
  title: 'FEED',
  type: TabTypes.FEED
};

function getTabAccessValue(siblingTabs: LocalContainer[]): number {
  if (siblingTabs && siblingTabs.length > 0) {
    const allAccessValues = [0, 1, 2, 3];
    const usedAccessNumbers = siblingTabs.map(tab => tab.access);
    const availableAccessNumbers = allAccessValues.filter(
      num => usedAccessNumbers.indexOf(num) === -1
    );
    return Math.min(...availableAccessNumbers);
  } else {
    return 0;
  }
}

export function initNewContainer(
  parentAppClass: LocalAppClass,
  siblingContainers: LocalContainer[],
  specialContainerValues?: LocalContainer
): LocalContainer {
  let newContainer: LocalContainer = {};
  newContainer.ref = uuidv4();

  newContainer.parentClassRef = parentAppClass.ref;
  newContainer.order = siblingContainers ? siblingContainers.length : 0;
  newContainer.appClassName = parentAppClass.name;
  switch (parentAppClass.name) {
    case AppClassNames.TABS: {
      const tabID = getNewTabID(
        parentAppClass,
        specialContainerValues && specialContainerValues.type
          ? specialContainerValues.type
          : TabTypes.FEED
      );
      newContainer.id = tabID.id;
      newContainer.tempSeq = tabID.seq;
      newContainer.container_type = ContainerTypes.TAB;
      newContainer.access = getTabAccessValue(siblingContainers);

      newContainer = { ...newContainer, ...DEFAULT_TAB_CONTAINER };
      break;
    }
    case AppClassNames.SECTIONS: {
      newContainer.container_type = ContainerTypes.TAB;
      break;
    }
    case AppClassNames.PROFILE: {
      newContainer.container_type = ContainerTypes.FIELD;
      newContainer = {
        ...newContainer,
        default: '',
        title: '',
        type: 'string'
      };
      break;
    }
    case AppClassNames.SEARCH: {
      newContainer.container_type = ContainerTypes.SEARCH;
      const filterNumber = newContainer.order + 1;
      newContainer = {
        ...newContainer,
        type: getSearchType(filterNumber),
        title: 'Filter #' + filterNumber
      };
      break;
    }
  }
  if (specialContainerValues) {
    newContainer = { ...newContainer, ...specialContainerValues };
  }
  return newContainer;
}

export function extractContainerData(
  defaultContainer: Container,
  newContainer: LocalContainer,
  systemConfig: SystemConfig
): ContainerChildren {
  let components: LocalComponent[] = [];
  let items: LocalItem[] = [];
  const sub_items: LocalItem[] = [];
  if (defaultContainer.component) {
    // console.log(
    //   'here is the data of the menu container middelware',
    //   defaultContainer.menu,
    //   defaultContainer.type
    // );
    defaultContainer.component.forEach(component => {
      const compChildren = extractComponentData(
        component,
        newContainer,
        systemConfig
      );
      components = [...components, ...compChildren.components];
      items = [...items, ...compChildren.items];
    });
  }
  const children: ContainerChildren = { components, items, sub_items };
  return children;
}

export function getNewTabID(
  parentClass: LocalAppClass,
  tabType: string
): TabID {
  let baseID = '';
  let nextSeq = 1;
  // const sameTypeTabs = siblingContainers.filter(tab => tab.type === tabType);
  // if (sameTypeTabs && sameTypeTabs.length > 0) {
  switch (tabType) {
    case TabTypes.FEED: {
      baseID = TAB_ID_BASE_WORD.FEED;
      nextSeq =
        parentClass.feed_seq &&
        parentClass.feed_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.feed_seq
          : 1;
      break;
    }
    case TabTypes.MEMBERSHIP: {
      baseID = TAB_ID_BASE_WORD.MEMBERSHIP;
      nextSeq =
        parentClass.membership_seq &&
        parentClass.membership_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.membership_seq
          : 1;
      break;
    }
    case TabTypes.WALLET: {
      baseID = TAB_ID_BASE_WORD.WALLET;
      nextSeq =
        parentClass.wallet_seq &&
        parentClass.wallet_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.wallet_seq
          : 1;
      break;
    }
    case TabTypes.WEBVIEW:
    case TabTypes.PAGE: {
      baseID = TAB_ID_BASE_WORD.WEB_VIEW;
      nextSeq =
        parentClass.webview_seq &&
        parentClass.webview_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.webview_seq
          : 1;
      break;
    }
    case TabTypes.CHANNELS: {
      baseID = TAB_ID_BASE_WORD.CHANNELS;
      nextSeq =
        parentClass.channel_seq &&
        parentClass.channel_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.channel_seq
          : 1;
      break;
    }
    case TabTypes.CHATS: {
      baseID = TAB_ID_BASE_WORD.CHATS;
      nextSeq =
        parentClass.chat_seq &&
        parentClass.chat_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.chat_seq
          : 1;
      break;
    }
    case TabTypes.SETTINGS: {
      baseID = TAB_ID_BASE_WORD.SETTINGS;
      nextSeq =
        parentClass.settings_seq &&
        parentClass.settings_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.settings_seq
          : 1;
      break;
    }
    case TabTypes.CALENDAR: {
      baseID = TAB_ID_BASE_WORD.CALENDAR;
      nextSeq =
        parentClass.calendar_seq &&
        parentClass.calendar_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.calendar_seq
          : 1;
      break;
    }
    case TabTypes.BOOKING: {
      baseID = TAB_ID_BASE_WORD.BOOKING;
      nextSeq =
        parentClass.booking_seq &&
        parentClass.booking_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.booking_seq
          : 1;
      break;
    }
    case TabTypes.INVITATION: {
      baseID = TAB_ID_BASE_WORD.INVITATION;
      nextSeq =
        parentClass.invitation_seq &&
        parentClass.invitation_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.invitation_seq
          : 1;
      break;
    }
    case TabTypes.OPENCHAT: {
      baseID = TAB_ID_BASE_WORD.OPENCHAT;
      nextSeq =
        parentClass.open_chat_seq &&
        parentClass.open_chat_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.open_chat_seq
          : 1;
      break;
    }
    case TabTypes.MYLIST: {
      baseID = TAB_ID_BASE_WORD.MYLIST;
      nextSeq =
        parentClass.mylist_seq &&
        parentClass.mylist_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.mylist_seq
          : 1;
      break;
    }
    case TabTypes.QR: {
      baseID = TAB_ID_BASE_WORD.QR;
      nextSeq =
        parentClass.qr_seq &&
        parentClass.qr_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.qr_seq
          : 1;
      break;
    }
    case TabTypes.MAP: {
      baseID = TAB_ID_BASE_WORD.MAP;
      nextSeq =
        parentClass.map_seq &&
        parentClass.map_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.map_seq
          : 1;
      break;
    }
    case TabTypes.SEARCH: {
      baseID = TAB_ID_BASE_WORD.SEARCH;
      nextSeq =
        parentClass.search_seq &&
        parentClass.search_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.search_seq
          : 1;
      break;
    }
    case TabTypes.CALL_LOG: {
      baseID = TAB_ID_BASE_WORD.CALL_LOG;
      nextSeq =
        parentClass.call_log_seq &&
        parentClass.call_log_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.call_log_seq
          : 1;
      break;
    }
    case TabTypes.SECTION: {
      baseID = TAB_ID_BASE_WORD.SECTION;
      nextSeq =
        parentClass.section_seq &&
        parentClass.section_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.section_seq
          : 1;
      break;
    }
    case TabTypes.BUNDLE: {
      baseID = TAB_ID_BASE_WORD.BUNDLE;
      nextSeq =
        parentClass.bundle_seq &&
        parentClass.bundle_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.bundle_seq
          : 1;
      break;
    }
    case TabTypes.PACKAGE: {
      baseID = TAB_ID_BASE_WORD.PACKAGE;
      nextSeq =
        parentClass.package_seq &&
        parentClass.package_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.package_seq
          : 1;
      break;
    }
    case TabTypes.VIDEO: {
      baseID = TAB_ID_BASE_WORD.VIDEO;
      nextSeq =
        parentClass.video_seq &&
        parentClass.video_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.video_seq
          : 1;
      break;
    }
    case TabTypes.QR_PAGE: {
      baseID = TAB_ID_BASE_WORD.QR_PAGE;
      nextSeq =
        parentClass.qr_page_seq &&
        parentClass.qr_page_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.qr_page_seq
          : 1;
      break;
    }
    case TabTypes.MAP_SEARCH: {
      baseID = TAB_ID_BASE_WORD.MAP_SEARCH;
      nextSeq =
        parentClass.map_search_seq &&
        parentClass.map_search_seq.toString().indexOf('Infinity') === -1
          ? 1 + parentClass.map_search_seq
          : 1;
      break;
    }
  }
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  return { id: baseID + currentTimeInSeconds, seq: currentTimeInSeconds };
}

export function getSearchType(filterNumber: number): string {
  let searchKey = CHANNEL_APP_SEARCH_CATEGORIES.AREA;
  switch (filterNumber) {
    case 1: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.AREA;
      break;
    }
    case 2: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY;
      break;
    }
    case 3: {
      searchKey = CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS;
      break;
    }
  }
  return searchKey;
}
