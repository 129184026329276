<div class="edit-container">
  <div class="header">
    <div class="image-upload clickable-cursor">
      <label for="file-input"> <i class="far fa-image"></i> </label>
      <input
        #imageInputFile
        id="file-input"
        type="file"
        accept="image/*"
        (change)="getFile()"
      />
    </div>

    <i
      class="fas fa-times close clickable-cursor"
      aria-hidden="true"
      (click)="cancelEvent()"
    >
    </i>
  </div>
  <div class="body">
    <div class="edit-area">
      <h1
        class="title-row single-line"
        contenteditable
        #titleHeader
        placeholder="Title"
        (input)="setTitle(titleHeader.textContent)"
      ></h1>
      <div
        class="author-row single-line"
        contenteditable
        #authorFiled
        placeholder="Your Name"
        (input)="setAuthor(authorFiled.textContent)"
      ></div>
      <medium-editor
        [(editorModel)]="textVar"
        [editorOptions]="editorOptions"
        [editorPlaceholder]="placeholderVar"
      >
      </medium-editor>
    </div>
    <app-border-btn submit [disabled]="uploading" (btnClicked)="saveDraft()">
      <span *ngIf="!uploading">Publish </span>
      <i class="fas fa-spinner fa-spin" *ngIf="uploading"></i>
    </app-border-btn>
  </div>
</div>
