<div class="lightbox-modal-box">
  <div class="lightbox-modal-header">
    <span>{{ titleHeader }}</span>
    <img
      class="clickable-cursor"
      src="/assets/img/lightbox-close.svg"
      alt="close"
      (click)="cancelEvent()"
    />
  </div>
  <div class="lightbox-modal-content">
    <!-- <div class="lightbox-modal-desc">
      {{ videoHeader }}
    </div> -->

    <div class="lightbox-modal-video">
      <ng-content></ng-content>
    </div>
  </div>
</div>
