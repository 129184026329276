import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { FileService } from 'src/app/core/file.service';

@Component({
  selector: 'app-media-center-uploader-input',
  templateUrl: './media-center-uploader-input.component.html',
  styleUrls: ['./media-center-uploader-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterUploaderInputComponent {
  @Input() mimeTypes: string;
  @Output() filesSelectedNotify = new EventEmitter<ISelectedFiles>();
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  constructor(private _fileService: FileService) {}

  onFileSelectedEvent() {
    this.filesSelectedNotify.emit(this.getFile());
    this.resetEvent();
  }

  getFile(): ISelectedFiles {
    const file = this.fileUpload.nativeElement.files[0];
    if (file) {
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    }
  }

  resetEvent() {
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = '';
    }
  }
}
