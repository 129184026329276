import { Injectable } from '@angular/core';

import { HttpService } from '../services/http.service';
import {
  YOUTUBE_API_CHANNEL_LINK,
  YOUTUBE_API_PLAYLIST_LINK,
  MAP_AUTOCOMPLETE_API_LINK,
  MAP_PLACE_API_LINK
} from 'src/models/constants';

import { PermissionService } from 'src/app/core/permission.service';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable()
export class MapGateway {
  constructor(
    private _httpService: HttpService,
    private _permissionService: PermissionService
  ) {}

  getGooglePlacesAutoComplete(loc: string) {
    return this._permissionService.getBrowserLocation().pipe(
      switchMap((r: Position) => {
        const url = `${MAP_AUTOCOMPLETE_API_LINK}/${loc}?lat=${r.coords.latitude}&lng=${r.coords.longitude}`;
        return this._httpService.httpGetRequest(url);
      }),
      catchError(e => {
        const url = `${MAP_AUTOCOMPLETE_API_LINK}/${loc}?lat=null&lng=null`;
        return this._httpService.httpGetRequest(url);
      })
    );
  }
  getGooglePlace(loc: string) {
    return this._permissionService.getBrowserLocation().pipe(
      switchMap((r: Position) => {
        const url = `${MAP_PLACE_API_LINK}/${loc}?lat=${r.coords.latitude}&lng=${r.coords.longitude}`;
        return this._httpService.httpGetRequest(url);
      }),
      catchError(e => {
        const url = `${MAP_PLACE_API_LINK}/${loc}?lat=null&lng=null`;
        return this._httpService.httpGetRequest(url);
      })
    );
  }
  getYouTubeChannel(chanId: string) {
    const url = `${YOUTUBE_API_CHANNEL_LINK}/${chanId}`;
    return this._httpService.httpGetRequest(url);
  }
  getYouTubeChannelIDByChannelName(channelName: string) {
    const url = `https://www.googleapis.com/youtube/v3/search?part=id%2Csnippet&q=${channelName}&type=channel&key=AIzaSyCrWzV8qqHKL_jILlzj33qyUtpSCElNEOU`;
    return this._httpService.httpGetRequest(url);
  }
  getYouTubePlaylist(listId: string) {
    const url = `${YOUTUBE_API_PLAYLIST_LINK}/${listId}`;
    return this._httpService.httpGetRequest(url);
  }
}
