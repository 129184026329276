import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';

import { ProductsService } from '../../products/products.service';
import { BundlesService } from './../../bundles/bundles.service';
import { PackagesService } from './../../packages/packages.service';
import { MobileStoresService } from '../../mobile-store/mobile-store.service';

import { IChat } from 'src/models/IChat';
import { Product } from 'src/models/Product';
import { ChatTypes } from 'src/models/constants';
import { BundleProduct } from 'src/models/Bundle';
import { PrivilegesName } from 'src/models/privileges';
import { PRODUCTS, PACKAGES, BUNDLES, MSTORE } from 'src/models/AppSections';
import { UiService } from 'src/app/core/ui.service';

@Component({
  selector: 'app-market-pool',
  templateUrl: './market-pool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketPoolComponent implements OnInit, OnDestroy {
  @Output() selectProductNotfiy = new EventEmitter<BundleProduct[]>();
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Input() mStoreID: string;
  @Input() mStore = true;
  @Input() prd = true;
  @Input() bundel = true;
  @Input() pck = true;
  @Input() mltipln = true;
  @Input() selectedMessage: any;
  @Input() currentSelectMessage: any[] = [];
  @Input() ownerGroupId: string;
  /**
   * single mode Select work only with when single pool show not more than 1
   */
  @Input() singleModeSinglePoolSelect = false;
  @Input() selectedChatId: string;

  @Input() useValidation = false;
  @Input() currentCurrency: string;
  @Output() currentCurrencyNotfiy = new EventEmitter<string>();

  productsFet = PRODUCTS;
  packagesFet = PACKAGES;
  bundleFet = BUNDLES;
  mStoreFet = MSTORE;

  chatTypes: typeof ChatTypes = ChatTypes;

  privilegesName: typeof PrivilegesName = PrivilegesName;

  selectMessages: any[] = [];

  constructor(
    public productService: ProductsService,
    public bundleService: BundlesService,
    public packageService: PackagesService,
    public mStoreService: MobileStoresService,
    private uiService: UiService
  ) {}

  ngOnInit() {
    if (this.selectedMessage) this.selectMessages.push(this.selectedMessage);
    if (this.currentSelectMessage)
      this.selectMessages = [...this.currentSelectMessage];

    this.productService.resetPaging(null, this.ownerGroupId);
    this.bundleService.resetPaging();
    this.packageService.resetPaging();
    this.mStoreService.resetPaging(this.selectedChatId);
  }

  setSelectMessage(prod: Product | IChat | any) {
    if (this.singleModeSinglePoolSelect) {
      this.selectMessages = [];
    }
    if (!this.currentCurrency && prod.currency) {
      this.currentCurrency = prod.currency;
    } else if (
      !this.currentCurrency &&
      prod.items.length > 0 &&
      prod.type !== 3
    ) {
      this.currentCurrency = prod.items[0].currency;
    }

    if (
      prod.currency &&
      this.currentCurrency === prod.currency &&
      this.useValidation
    ) {
      this.selectMessages.push(prod);
    } else if (!this.useValidation) {
      this.selectMessages.push(prod);
    } else if (!prod.currency && this.useValidation && prod.type == 2) {
      this.uiService.showErrorToast(
        'Bundle is empty. Please add products to the bundle.'
      );
    } else if (prod.type == 3) {
      this.selectMessages.push(prod);
    } else if (
      !prod.currency &&
      prod.items.length > 0 &&
      prod.items[0].currency === this.currentCurrency
    ) {
      this.selectMessages.push(prod);
    } else if (prod.type == 1 && prod.items.length == 0 && this.useValidation) {
      this.uiService.showErrorToast(
        'Package is empty. Please add products to the Package.'
      );
    } else {
      this.uiService.showErrorToast(
        'Please make sure that all your items have the same currency.'
      );
    }
  }
  resetSelectMessage(prod: Product | IChat) {
    this.selectMessages = this.selectMessages.filter(res => res.id !== prod.id);
    if (this.selectMessages.length <= 0) {
      this.currentCurrency = null;
    }
    if (!this.currentCurrency) {
      this.currentCurrency = null;
    }
  }
  isProductSelected(prodId: string) {
    return this.selectMessages.filter(res => res.id === prodId).length > 0;
  }

  insertEvent() {
    const values: BundleProduct[] = this.selectMessages.map(res => {
      return {
        product_id: res.id,
        type: res.type ? res.type : 0,
        product: { ...res }
      };
    });

    if (values.length == 0) {
      return;
    }
    if (this.currentCurrency) {
      this.currentCurrencyNotfiy.emit(this.currentCurrency);
    }
    this.selectProductNotfiy.emit(values);
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  TrackByFunction(index, tag: any) {
    return tag.id;
  }

  ngOnDestroy(): void {}
}
