import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
  Input,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { UiService } from '../../core/ui.service';
import { HelperService } from '../../core/helper.service';
import { MainChat } from '../../../models/MainChat';
import { MessageTypes, NO_MIC_FOUND } from '../../../models/constants';
import { ISelectedFiles } from '../../../models/ISelectedFiles';
import { IMessage } from 'src/models/IMessage';
import { MessageControls } from 'src/models/MessageParts';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBoxComponent implements OnInit, OnChanges {
  @ViewChild('textBox', { static: false }) textBox: ElementRef;
  @ViewChild('dropdownEmoji', { static: false }) dropdownEmoji: ElementRef;

  @Input() selectedChat: MainChat;
  @Input() selectedMessage: IMessage;
  @Input() selectedUserId: string;
  @Input() groupReplies: boolean;
  @Input() replyToAdmin: boolean;
  @Input() talkToAdminBot: boolean;
  @Input() isScreenBot = false;
  @Input() show = true;
  index = 0;
  isEmojiKeyboardOpen = false;
  isRecord = false;

  constructor(
    public uiService: UiService,
    private elemRef: ElementRef,
    private _helperService: HelperService
  ) {}

  @HostListener('document:keydown', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (
      this.textBox &&
      this._helperService.clickedComponent === this.elemRef.nativeElement
    ) {
      if (event.keyCode === 13 && !event.shiftKey) {
        this._helperService.cancelEvent(event);
        this.sendMessage();
      } else if (event.keyCode === 8 || event.keyCode === 46) {
        const htmlContent = this.textBox.nativeElement;
        if (
          (htmlContent.textContent.length === 0 &&
            htmlContent.innerHTML.length === 0) ||
          htmlContent.innerHTML === '<br>'
        ) {
          this.textBox.nativeElement.textContent = '';
        }
      } else {
        // this.setFocus();
      }
    }
  }

  ngOnInit() {
    this._helperService.clickedComponent = this.elemRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['selectedChat'];
    if (chng && this.textBox) {
      if (chng.currentValue && !chng.previousValue) {
        this.textBox.nativeElement.innerHTML = chng.currentValue
          .lastTypedMessage
          ? chng.currentValue.lastTypedMessage
          : '';
      } else if (chng.previousValue && !chng.currentValue) {
        this.uiService.deselectChat(
          chng.previousValue,
          this.textBox.nativeElement.innerHTML
        );
      } else if (
        chng.currentValue &&
        chng.currentValue.id !== chng.previousValue.id
      ) {
        this.uiService.deselectChat(
          chng.previousValue,
          this.textBox.nativeElement.innerHTML
        );
        this.textBox.nativeElement.innerHTML = chng.currentValue
          .lastTypedMessage
          ? chng.currentValue.lastTypedMessage
          : '';
      }
    }
  }

  sendMessage() {
    this.isEmojiKeyboardOpen = false;
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        if (!this.talkToAdminBot) {
          this.uiService.sendTextMessage(
            this.selectedChat,
            this.selectedMessage,
            this.selectedUserId,
            textToSend
          );
        } else {
          let misc: MessageControls = {};
          misc = {
            ...misc,
            chat_settings: 1
          };
          this.uiService.sendTextMessage(
            this.selectedChat,
            this.selectedMessage,
            this.selectedUserId,
            textToSend,
            misc
          );
        }
        this.textBox.nativeElement.textContent = '';
      }
    }
  }

  setFocus() {
    if (this.textBox) {
      this.textBox.nativeElement.focus();
      // this._helperService.restoreSelection(this.index);
    }
  }

  emoijNotify($event: string) {
    this.setFocus();
    // this._helperService.restoreSelection(this.index);
    this._helperService.insertEmojiAtCursor($event);
  }

  onRichPaste(e) {
    const cData = (e.originalEvent || e).clipboardData;
    const items = (cData && cData.items) || [];
    let text;
    for (let i = 0; i < items.length; i++) {
      // paste image
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        this.sendMedia({
          localFile: file,
          type: MessageTypes.PHOTO
        });
      }
      if (items[i].kind === 'file') {
        e.preventDefault();
        return true;
      }
    }

    try {
      text = cData.getData('text/plain');
    } catch (e) {
      return true;
    }
    if (text.length) {
      const textAfter = this._helperService.convertUnifiedAndColonsToEmojiSpan(
        this._helperService.getMultiLine(text)
      );
      document.execCommand('insertHTML', false, textAfter);
      return this._helperService.cancelEvent(e);
    }
    return true;
  }

  lastfocus() {
    this.index = this._helperService.saveSelection();
  }

  keyUp(event) {
    this.index = this._helperService.saveSelection();
  }

  sendMedia(event: ISelectedFiles) {
    if (event.localFile) {
      this.uiService.getBlobUrl(event);
    }
  }

  sendVoiceNote($event: { file: File; url: string }) {
    this.uiService.sendMediaMessage(
      this.selectedChat,
      this.selectedMessage,
      this.selectedUserId,
      $event.file,
      MessageTypes.VOICE,
      null,
      $event.url
    );
  }

  toggleEmojiKeyboard($event): void {
    this.isEmojiKeyboardOpen = $event;
  }

  startRecordEvent($event) {
    this.isRecord = $event;
  }
  micNotFoundEvent() {
    this.uiService.showErrorToast(NO_MIC_FOUND);
  }
}
