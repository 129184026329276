import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import * as isEqual from 'lodash.isequal';

import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';

import { IAuth } from 'src/models/IAuth';
import { UIState } from 'src/models/UIState';
import { MainChat } from 'src/models/MainChat';
import { IProfile } from 'src/models/IProfile';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import * as ChatMethods from 'src/models/IChat';
import * as MessagesMethods from 'src/models/IMessage';
import { ProfileDispatchers } from './../../store/profile/profile.dispatchers';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  public notifyCount = 0;
  profileSubsc = null;
  constructor(
    private _selectors: RootStoreSelectors,
    private _uiDispatchers: UIDispatchers,
    private _authDispatchers: AuthDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _chatDispatchers: ChatDispatchers
  ) {
    this._selectors
      .getEventList$()
      .pipe(distinctUntilChanged((n, p) => n.length === p.length))
      .subscribe(console.log);
  }

  logout(): void {
    this._authDispatchers.logOut();
    this._authDispatchers.resetStore();
  }

  setChannelInfo(
    chatID: string,
    updatedChatProps: ChatMethods.IChat,
    reference: string
  ) {
    updatedChatProps.id = chatID;
    this._chatDispatchers.updateChat(updatedChatProps, reference);
  }
  uploadChatImage(chatID: string, selectedFile: ISelectedFiles) {
    this._chatDispatchers.uploadChatPhoto(chatID, selectedFile);
  }
  setPageNumber(action: string): void {
    this._uiDispatchers.setPageNumber(action);
  }
  setSearchKeyword(searchString: string): void {
    this._uiDispatchers.setSearchKeyWord(searchString);
  }
  setSection(direction: string): void {
    this._uiDispatchers.setSection(direction);
  }
  setScheduleDate(scheduleDate: Date): void {
    this._uiDispatchers.dateSelected(scheduleDate);
  }
  deselectChannel() {
    this._messageDispatchers.deselectChannel();
  }
  setSelect(type: string): void {
    // this._messageDispatchers.setSelect(type);
  }
  /*********************** Store Selectors *********************************************/
  // TODO: Move this selectors fro auth dispatchers
  get chatAdminName(): string {
    return this._authDispatchers.getLoginName();
  }
  get selectedChatName$(): Observable<string> {
    return this._selectors.selectedChatName$;
  }
  get authCollection$(): Observable<IAuth> {
    return this._selectors.authCollection$;
  }

  get allChannels$(): Observable<MainChat[]> {
    return this._selectors.channels$.pipe(
      map(channels =>
        channels.filter(
          channel => channel.detailsReceived && channel.isUpgradedChannel
        )
      )
    );
  }
  get messagesCollection$(): Observable<any> {
    return this._selectors.messagesCollection$;
  }
  get awardMessages$(): Observable<MessagesMethods.IMessage[]> {
    return this._selectors.awardMessages$;
  }
  get scheduleMessages$(): Observable<MessagesMethods.IMessage[]> {
    return this._selectors.scheduleMessages$;
  }
  get selectedChat$(): Observable<MainChat> {
    return this._selectors.getSelectedChat$();
  }
  get effectiveParentMessage$(): Observable<MessagesMethods.IMessage> {
    return this._selectors.getEffectiveChannelPost$();
  }
  get effectiveSelectedChat$(): Observable<MainChat> {
    return this._selectors.getEffectiveSelectedChat$();
  }
  get uiCollection$(): Observable<UIState> {
    return this._selectors.uiCollection$;
  }
  get privileges$() {
    return this._selectors.privileges$;
  }

  getUserByID$(senderID: string): Observable<IProfile> {
    return this._selectors.getProfileByID$(senderID);
  }
}
