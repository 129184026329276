import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { AppInfo } from 'src/models/ChannelAppConfig';
import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';

@Component({
  selector: 'app-confirm-info',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmInfoComponent implements OnInit {
  @Output() closeNotifier = new EventEmitter<boolean>(false);
  donutShowAgin = false;
  constructor(public appManagementService: AppMgmtService) {}

  ngOnInit() {}

  onCheck(checked) {
    this.donutShowAgin = checked;
  }

  onSave() {
    if (this.donutShowAgin) {
      const appInfo: AppInfo = {};
      appInfo.hide_module_added_msg = 1;
      this.appManagementService.updateAppInfo(appInfo);
      this.closeNotifier.emit(true);
      this.appManagementService.savePage(false);
    } else {
      const appInfo: AppInfo = {};
      appInfo.hide_module_added_msg = 0;
      this.appManagementService.updateAppInfo(appInfo);
      this.closeNotifier.emit(true);
      this.appManagementService.savePage(false);
    }
  }
}
