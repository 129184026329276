/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./validation-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./validation-errors.component";
import * as i4 from "../../validators/form-validators.service";
var styles_ValidationErrorsComponent = [i0.styles];
var RenderType_ValidationErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ValidationErrorsComponent, data: {} });
export { RenderType_ValidationErrorsComponent as RenderType_ValidationErrorsComponent };
function View_ValidationErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getError(); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidationErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldName; _ck(_v, 1, 0, currVal_0); }); }
export function View_ValidationErrorsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationErrorsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ValidationErrorsComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.usingCheckBox; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.usingCheckBox; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ValidationErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-validation-errors", [], null, null, null, View_ValidationErrorsComponent_0, RenderType_ValidationErrorsComponent)), i1.ɵdid(1, 49152, null, 0, i3.ValidationErrorsComponent, [i4.FormValidatorsService], null, null)], null, null); }
var ValidationErrorsComponentNgFactory = i1.ɵccf("app-validation-errors", i3.ValidationErrorsComponent, View_ValidationErrorsComponent_Host_0, { fieldErrors: "fieldErrors", fieldName: "fieldName", fieldType: "fieldType", usingCheckBox: "usingCheckBox" }, {}, []);
export { ValidationErrorsComponentNgFactory as ValidationErrorsComponentNgFactory };
