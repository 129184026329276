<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">Add Coupon</ng-container>

  <ng-container ngProjectAs="[form-selector-create]">
    <ng-container *appShowIfHasPrivilege="privilegesName.editcoupon">
      <app-create-item-frame
        (addnewNotfiy)="toggleCouponFormModalVisibility(true)"
      >
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new coupon
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add new coupon from the "Add new coupon” field. Simply click
          the “+ Add new coupon” button and Create new Coupon.
        </ng-container>
      </app-create-item-frame>
    </ng-container>
  </ng-container>

  <ng-container ngProjectAs="[form-selector-items]">
    <ng-container *ngIf="couponService.getCouponList$ | async as coupons">
      <ng-container *ngIf="coupons?.length > 0; else isEmpty">
        <app-round-paging-btn
          class="paging-float-btn"
          (nextBtnClicked)="couponService.getNextPage()"
          (previousBtnClicked)="couponService.getPrevPage()"
        >
        </app-round-paging-btn>
        <app-selectable-cell-frame
          *ngFor="let coupon of coupons; trackBy: TrackByFunction"
          [symbolColor]="couponFet?.symbolColor"
          [symbolImage]="couponFet?.symbolImage"
          [coverColor]="couponService.getBgStatus(coupon)"
          [title]="coupon?.name | twemoji"
          [upcCode]="coupon?.code"
          [selectorId]="coupon?.couponId"
          [isSelected]="coupon?.couponId === currentSelectMessage?.couponId"
          (checkClick)="setSelectMessage(coupon)"
          (checkChanged)="resetSelectMessage()"
        >
        </app-selectable-cell-frame>
      </ng-container>
    </ng-container>

    <ng-template #isEmpty>
      <app-empty-item-frame
        [emptySymbol]="couponFet?.symbolImage"
        [emptyTitle]="couponFet?.emptyTitle"
        [emptyDesc]="couponFet?.emptyPoolDesc"
      >
      </app-empty-item-frame>
    </ng-template>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn
        *ngIf="currentSelectMessage"
        submit
        (btnClicked)="insertEvent()"
      >
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>

<app-modal-container
  *ngIf="showCouponFormModal"
  (cancelNotfiy)="toggleCouponFormModalVisibility(false)"
>
  <app-coupon-form
    [showCancel]="true"
    [isCreateFromBool]="true"
    (cancelNotify)="toggleCouponFormModalVisibility(false)"
  >
  </app-coupon-form>
</app-modal-container>
