import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-add-icon',
  template: `
    <svg class="svg-icon icon-icon clickable-cursor">
      <use xlink:href="./assets/svg/app-sections.svg#icon-edit"></use>
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIconComponent {}
