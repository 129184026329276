import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-lightbox-container',
  templateUrl: './lightbox-container.component.html',
  styleUrls: ['./lightbox-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxContainerComponent implements OnInit {
  @Input() titleHeader: string;
  @Input() videoHeader: string;

  @Output() cancelNotfiy = new EventEmitter<boolean>(false);

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this._ref.nativeElement === event.target) {
      event.stopPropagation();
      this.cancelNotfiy.emit(true);
    }
  }

  constructor(private _ref: ElementRef) {}

  ngOnInit() {}

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }
}
