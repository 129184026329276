<app-headerless-closable-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">
        <span *ngIf="isInstant">Instant Article</span>
        <span *ngIf="!isInstant">Enter your URL</span>
      </ng-container>

      <ng-container ngProjectAs="action">
        <app-edit-btn
          *ngIf="isInstant"
          (btnClicked)="showInstantArticleEvent(true)"
        >
        </app-edit-btn>
      </ng-container>
      <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-reactive-text-input
          [filedType]="'url'"
          [filedLabel]="'Enter Your URL Here'"
          [fieldId]="'urlFiled'"
          [control]="form?.get('url')"
          [maxlength]="100"
        >
        </app-reactive-text-input>

        <div class="form-group">
          <div class="form-submit">
            <app-border-btn submit [type]="'submit'">
              Send
            </app-border-btn>
          </div>
        </div>
      </form>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame>

<app-modal-container
  *ngIf="showComposeInstance"
  (cancelNotfiy)="showInstantArticleEvent(false)"
>
  <app-instant-article-editor
    (cancelNotfiy)="showInstantArticleEvent(false)"
    (publishNotfiy)="publishInstantArticleEvent($event)"
  >
  </app-instant-article-editor>
</app-modal-container>
