import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  DoCheck,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
  BsDatepickerConfig,
  BsDatepickerDirective
} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-reactive-date-input',
  templateUrl: './reactive-date-input.component.html',
  styleUrls: ['./reactive-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveDateInputComponent
  implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  /**
   * 'fieldId' is a unique field identification
   * Example: 'email', 'password'
   *    ---REQUIRED PROPERTY---
   */
  @Input() isLocked = true;
  @Input() isInline = false;
  @Input() fieldId: string;
  @Input() filedLabel: string;
  @Input() filedType = 'text'; // default value is string
  @Input() showTooltip = false;
  @Input() descriptionTooltip: string;
  @Input() topTooltipHover: number;
  @Input() leftTooltipHover: number;

  /**
   * 'control' is a reactive form validator/value controller
   *    ---REQUIRED PROPERTY---
   */
  @Input() control: AbstractControl;
  @Output() selectedDate = new EventEmitter<Date>();
  @ViewChild('dp', { static: true }) datePicker: BsDatepickerDirective;

  datePickerConfig = {
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    appendToBody: true,
    minDate: new Date()
  };

  bsConfig: Partial<BsDatepickerConfig>;
  changeSubscription = null;

  private _validationErrors = new BehaviorSubject<object>(null);
  public validationErrors$ = this._validationErrors.asObservable();

  get uniqueClassName() {
    return `${this.fieldId}-addon`;
  }

  ngOnInit() {
    this.datePickerConfig = {
      ...this.datePickerConfig,
      minDate: this.isLocked ? new Date() : new Date(1970, 1, 1)
    };
  }

  ngDoCheck() {
    if (this.control && this.control.touched && this.control.invalid) {
      this._validationErrors.next(this.control['errors']);
    } else {
      this._validationErrors.next(null);
    }
  }

  ngAfterViewInit() {
    this.changeSubscription = this.datePicker.bsValueChange.subscribe(
      (date: Date) => {
        this.selectedDate.next(date);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = null;
    }
  }
}
