<div
  *ngIf="radioOptions"
  [class.form-group]="!isInline"
  [class.form-inline]="isInline"
>
  <label *ngIf="filedLabel" class="control-label" [class.md]="isInline">
    {{ filedLabel }}
    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    >
    </app-hover-tooltip-icon>
  </label>

  <mat-radio-group class="form-inline-radio" [formControl]="control">
    <mat-radio-button
      *ngFor="let radOpt of radioOptions"
      [value]="radOpt.value"
      (change)="onSelectOption(radOpt.value)"
    >
      {{ radOpt.key }}
    </mat-radio-button>
  </mat-radio-group>

  <ng-container *ngIf="validationErrors$ | async as errors">
    <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
    </app-validation-errors>
  </ng-container>
</div>

<!-- radio btn only -->
<mat-radio-button *ngIf="!radioOptions" [value]="radioValue" [id]="radioValue">
</mat-radio-button>
