import { Action } from '@ngrx/store';
import { Smtp } from 'src/models/Smtp';

export const enum SmtpActionTypes {
  RECEIVE_SMTP = 'RECEIVE_SMTP',
  SET_SMTP = 'SET_SMTP',
  GET_SMTP = 'GET_SMTP',
  RESET = 'RESET'
}

export class ReceiveStmp implements Action {
  readonly type = SmtpActionTypes.RECEIVE_SMTP;
  constructor(public payload: any) {}
}

export class SetStmp implements Action {
  readonly type = SmtpActionTypes.SET_SMTP;
  constructor(public smtp: Smtp) {}
}

export class GetStmp implements Action {
  readonly type = SmtpActionTypes.GET_SMTP;
  constructor() {}
}

export class Reset implements Action {
  readonly type = SmtpActionTypes.RESET;
}

export type Actions = ReceiveStmp | SetStmp | GetStmp | Reset;
