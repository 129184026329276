/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./edit-icon.component";
var styles_EditIconComponent = ["[_nghost-%COMP%] {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }"];
var RenderType_EditIconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_EditIconComponent, data: {} });
export { RenderType_EditIconComponent as RenderType_EditIconComponent };
export function View_EditIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-edit"]], null, null, null, null, null))], null, null); }
export function View_EditIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-icon", [], null, null, null, View_EditIconComponent_0, RenderType_EditIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.EditIconComponent, [], null, null)], null, null); }
var EditIconComponentNgFactory = i0.ɵccf("app-edit-icon", i1.EditIconComponent, View_EditIconComponent_Host_0, {}, {}, []);
export { EditIconComponentNgFactory as EditIconComponentNgFactory };
