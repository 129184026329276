import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { InstantArticle } from 'src/models/InstantArticle';
import { InstantArticleService } from '../../instant-article/instant-article.service';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';

@Component({
  selector: 'app-compose-url-input',
  templateUrl: './compose-url-input.component.html',
  styleUrls: ['./compose-url-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeUrlInputComponent implements OnInit {
  form: FormGroup;
  reg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  showComposeInstance = false;

  @Input() isInstant: boolean;
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() publishNotfiy = new EventEmitter<number>();

  constructor(
    private _instantArticleService: InstantArticleService,
    private _validateForm: FormValidatorsService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      url: new FormControl('', [
        Validators.required,
        Validators.pattern(this.reg)
      ])
    });
  }

  get url() {
    return this.form.get('url');
  }

  onSubmit() {
    if (this.form.valid) {
      const iv: InstantArticle = {
        reference: new Date().getTime(),
        linkPreviewUrl: this.form.value.url,
        isInstant: this.isInstant
      };
      this.publishNotfiy.emit(iv.reference);
      this._instantArticleService.setNewArticle(iv);
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  showInstantArticleEvent(e: boolean) {
    this.showComposeInstance = e;
  }

  publishInstantArticleEvent(ref: number) {
    this.publishNotfiy.emit(ref);
    this.showInstantArticleEvent(false);
  }
}
