import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-no-support',
  templateUrl: './no-support.component.html',
  styleUrls: ['./no-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoSupportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
