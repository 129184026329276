import { Injectable } from '@angular/core';
import { Subscription, interval, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { StorageService } from 'src/app/core/storage.service';
import { CredentialService } from 'src/app/core/credential.service';

import { environment } from 'src/environments/environment';
import * as MessagesMethods from 'src/models/IMessage';
import * as AuthMethods from 'src/models/IAuth';
import {
  PROJECT_NAME,
  INDIVIDUAL_PROJECT,
  MERCHANT_PROJECT,
  ID_LOCAL_STORAGE_KEY,
  OFFSET_STORAGE_KEY,
  BASE_URL_LOCAL_STORAGE_KEY,
  DEFAULT_BASE_URL_API,
  WS_URL_API_SUFFIX,
  AuthStatus,
  SignupStatus
} from 'src/models/constants';

@Injectable()
export class SocketService {
  private updatedWebSocketUrl = '';
  private ws: WebSocket = null;
  private pingTimerObserver = interval(25000);
  private pingTimerSubscription: Subscription = null;
  private currentAuthState: string = AuthStatus.INIT_SOKET;
  private preExpireTrialsCounter = 0;
  private authStateSubscribtion = null;
  private TWO_MINUTES = 120000;
  private qrCodeTimeout = null;
  private errorTimeout = null;
  private _webSocket = new BehaviorSubject<WebSocket>(null);
  public webSocketObservable$ = this._webSocket.asObservable();

  constructor(
    public credentialService: CredentialService,
    private _storageService: StorageService
  ) {
    const tempBaseUrl = _storageService.getRecord(BASE_URL_LOCAL_STORAGE_KEY);
    if (!tempBaseUrl) {
      this.setNewSocketUrl(DEFAULT_BASE_URL_API);
    } else if (tempBaseUrl) {
      this.setNewSocketUrl(tempBaseUrl);
    }
    this.initSocket();
  }

  get authStore$() {
    return this.credentialService.authStore;
  }

  private initSocket(webSocketURL: string = this.updatedWebSocketUrl) {
    this.closeSocketIfExist().then(() => {
      this.ws = new WebSocket(webSocketURL);
      if (environment.encodeSocket) {
        this.ws.binaryType = 'arraybuffer';
      }
      this.ws.onopen = (evt: any) => this.onSocketOpen();
      // this.ws.onmessage = (evt: any) => this.onSocketMessage(evt.data);
      this.ws.onerror = (evt: any) => this.onSocketError();
      this.ws.onclose = (evt: any) => this.onClose(evt);
    });
  }

  public openNewSocket(webSocketURL: string = this.updatedWebSocketUrl) {
    this.resetPingTimer();
    if (this.authStateSubscribtion) {
      this.authStateSubscribtion.unsubscribe();
    }
    const { token } = this.credentialService.credential;
    if (token !== null && token.length > 0) {
      this.credentialService.fireTokenReceivedEvent({ token });
    } else {
      this.credentialService.resetAuth();
    }
    this.initSocket(webSocketURL);
  }

  /** emmit message logic */
  emitSocketMessage(data: any) {
    this.checkWsStateAndSendMsg(JSON.stringify(data));
  }
  private checkWsStateAndSendMsg(data) {
    if (
      this.ws &&
      this.ws.readyState !== WebSocket.CLOSING &&
      this.ws.readyState !== WebSocket.CLOSED
    ) {
      this.waitForSocketConnAndSendMsg(data);
    } else {
      if (
        this.currentAuthState !== AuthStatus.AUTH_QR_EXPIRE &&
        this.currentAuthState !== AuthStatus.MULTI_LOGIN
      ) {
        this.openNewSocket();
      }
    }
  }
  // Make the function wait until the connection is made...
  private waitForSocketConnAndSendMsg(data) {
    setTimeout(() => {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        if (environment.encodeSocket) {
          const byteArray = this.convertMessageToArrayBuffer(data);
          this.ws.send(byteArray);
        } else {
          this.ws.send(data);
        }
      } else {
        this.checkWsStateAndSendMsg(data);
      }
    }, 10); // wait 10 milisecond for the connection... to open
  }
  /***************************************************************************************/

  private closeSocketIfExist() {
    return new Promise((resolve, reject) => {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
      resolve();
    });
  }
  /** timers */
  setPingTimer() {
    this.resetPingTimer();
    this.pingTimerSubscription = this.pingTimerObserver.subscribe(res => {
      if (this.currentAuthState !== AuthStatus.AUTH_QR_EXPIRE) {
        this.emitSocketMessage(new AuthMethods.Ping());
      }
    });
  }
  private resetPingTimer() {
    if (this.pingTimerSubscription) {
      this.pingTimerSubscription.unsubscribe();
    }
  }
  /*** ************************************************************* */
  private onSocketOpen() {
    this.notifyWebSocketUpdate();
    this.authStateSubscribtion = this.credentialService.authStore
      .pipe(
        distinctUntilChanged(
          (a, b) => a.authStatus === b.authStatus && a.token === b.token
        )
      )
      .subscribe(state => {
        this.currentAuthState = state.authStatus;
        switch (state.authStatus) {
          case AuthStatus.INIT_SOKET:
            if (this.preExpireTrialsCounter < 3) {
              this.emitSocketMessage(new AuthMethods.GetQRCode());
              this.preExpireTrialsCounter++;
            }
            break;
          case AuthStatus.LOGGED_OUT:
            this.preExpireTrialsCounter = 0;
            this.openNewSocket();
            break;
          case AuthStatus.AUTH_QR_SET:
            this.clearErrorTimeout();
            this.qrCodeTimeout = setTimeout(() => {
              if (this.preExpireTrialsCounter >= 3) {
                this.credentialService.setQRExpire();
                this.clearQrCodeTimeOut();
                this.clearErrorTimeout();
                this.resetPingTimer();
              } else if (AuthStatus.AUTH_QR_SET === this.currentAuthState) {
                this.openNewSocket();
              }
            }, this.TWO_MINUTES);
            break;
          case AuthStatus.AUTH_QR_EXPIRE:
            this.preExpireTrialsCounter = 0;
            break;
          case AuthStatus.AUTH_TOKEN_NOT:
            this.clearQrCodeTimeOut();
            this.clearErrorTimeout();
            this.preExpireTrialsCounter = 0;
            this.credentialService.removeLocalAuth();
            this.openNewSocket();
            break;
          case AuthStatus.TOKEN_RECEIVED:
            if (
              PROJECT_NAME === MERCHANT_PROJECT &&
              (!this.ws ||
                this.ws.readyState === WebSocket.CLOSING ||
                this.ws.readyState === WebSocket.CLOSED) &&
              state.signupStatus === SignupStatus.EMAIL_TOKEN_RECEIVED
            ) {
              this.openNewSocket();
            } else {
              this.clearQrCodeTimeOut();
              this.clearErrorTimeout();
              this.preExpireTrialsCounter = 0;
              const { token, rem } = this.credentialService.credential;
              this.emitSocketMessage(new AuthMethods.SendTokenAuth(token, rem));
            }
            break;
          case AuthStatus.AUTH_LOGIN_BY_EMAIL:
            this.clearQrCodeTimeOut();
            this.clearErrorTimeout();
            this.preExpireTrialsCounter = 0;
            break;
          case AuthStatus.AUTH_TOKEN_OK:
            this._storageService.setRecord(ID_LOCAL_STORAGE_KEY, state.ID);
            this._storageService.setRecord(OFFSET_STORAGE_KEY, state.offset);
            if (PROJECT_NAME === INDIVIDUAL_PROJECT) {
              this.emitSocketMessage(new MessagesMethods.GetContacts());
              this.emitSocketMessage(new MessagesMethods.GetChatList());
            } else if (PROJECT_NAME === MERCHANT_PROJECT) {
              this.emitSocketMessage(
                new MessagesMethods.GetUpgradedChatList(true)
              );
            }
            this.emitSocketMessage(new MessagesMethods.GetMyProfiles());
            break;
          case AuthStatus.AUTH_CHAT_SELECTED:
            if (PROJECT_NAME === MERCHANT_PROJECT) {
              this.emitSocketMessage(
                new MessagesMethods.GetUpgradedChatList(false)
              );
            }
            break;
          default:
            break;
        }
      });
  }

  private onSocketError() {
    this.clearQrCodeTimeOut();
    this.clearErrorTimeout();
    this.closeSocketIfExist();
    // this.setPingTimer();
    if (
      this.currentAuthState === AuthStatus.INIT_SOKET ||
      this.currentAuthState === AuthStatus.AUTH_QR_SET
    ) {
      this.resetPingTimer();
      this.preExpireTrialsCounter++;
      this.errorTimeout = setTimeout(() => {
        if (this.preExpireTrialsCounter >= 3) {
          this.preExpireTrialsCounter = 0;
          this.credentialService.setQRExpire();
        } else {
          this.openNewSocket();
        }
      }, 25000);
    }
  }

  private onClose(evt: any) {}

  private setNewSocketUrl(url: string) {
    this._storageService.setRecord(BASE_URL_LOCAL_STORAGE_KEY, url);
    this.updatedWebSocketUrl = `wss://${url}${WS_URL_API_SUFFIX}`;
  }

  clearQrCodeTimeOut() {
    if (this.qrCodeTimeout) {
      clearTimeout(this.qrCodeTimeout);
    }
  }

  clearErrorTimeout() {
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }
  }

  convertMessageToArrayBuffer(data: any) {
    return new (<any>window).TextEncoder().encode(data).buffer;
  }

  convertArrayBufferToMessage(byteArray: Uint8Array) {
    return new (<any>window).TextDecoder().decode(byteArray);
  }

  closeCurrentSocket() {
    if (this.ws) {
      this.ws.close();
    }
  }

  openNewSocketWithURL(url: string) {
    this.setNewSocketUrl(url);
    this.openNewSocket(this.updatedWebSocketUrl);
  }

  public getWebSocketObj() {
    return this.ws;
  }

  notifyWebSocketUpdate() {
    this._webSocket.next(this.ws);
  }
}
