import { ValidatorFn, FormControl } from '@angular/forms';
import {
  MIN_GROUP_TITLE_LENGTH,
  MAX_GROUP_TITLE_LENGTH,
  MAX_PAYMENT_TITLE_LENGTH
} from 'src/models/constants';

// custom validator to check that two fields match
export const groupNameValidator: ValidatorFn = (control: FormControl) => {
  if (control && control.value) {
    if (control.value.length < MIN_GROUP_TITLE_LENGTH) {
      return {
        groupName: true,
        minLength: MIN_GROUP_TITLE_LENGTH,
        maxLength: MAX_GROUP_TITLE_LENGTH
      };
    } else if (control.value.length > MAX_GROUP_TITLE_LENGTH) {
      return {
        groupName: true,
        minLength: MIN_GROUP_TITLE_LENGTH,
        maxLength: MAX_GROUP_TITLE_LENGTH
      };
    }
  }
  return null;
};

export const paymentNameValidator: ValidatorFn = (control: FormControl) => {
  if (control && control.value) {
    if (control.value.length <= MIN_GROUP_TITLE_LENGTH) {
      return {
        groupName: true,
        minLength: MIN_GROUP_TITLE_LENGTH,
        maxLength: MAX_PAYMENT_TITLE_LENGTH
      };
    } else if (control.value.length >= MAX_PAYMENT_TITLE_LENGTH) {
      return {
        groupName: true,
        minLength: MIN_GROUP_TITLE_LENGTH,
        maxLength: MAX_PAYMENT_TITLE_LENGTH
      };
    }
  }
  return null;
};
