import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { MainChat } from '../../../../models/MainChat';
import { LayoutService } from './../../../+merchant/+layout/layout.service';

@Component({
  selector: 'app-profile-cell',
  templateUrl: './profile-cell.component.html',
  styleUrls: ['./profile-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCellComponent {
  @Input() chat: MainChat;

  constructor(public layoutService: LayoutService) {}
}
