import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as BlacklistPatternsActions from './blacklistPatternsUI.actions';

import { AppState } from 'src/models/AppState';
import { BlackList } from 'src/models/BlackListsUI';

@Injectable({
  providedIn: 'root'
})
export class BlackListPatternsUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  resetBlacklistPatternsPaging() {
    this._store.dispatch(
      new BlacklistPatternsActions.ResetBlackListPatternsPaging()
    );
  }

  getNextBlacklistPatternsPage() {
    this._store.dispatch(
      new BlacklistPatternsActions.GetNextBlacklistPatternsPage()
    );
  }

  getPrevBlacklistPatternsPage() {
    this._store.dispatch(
      new BlacklistPatternsActions.GetPrevBlacklistPatternsPage()
    );
  }

  addPatternToBlacklist(
    data: BlackList[],
    mainChatID: string,
    reference?: string,
    recordIndex?: number
  ) {
    this._store.dispatch(
      new BlacklistPatternsActions.AddPatternToBlacklist(
        data,
        mainChatID,
        reference,
        recordIndex
      )
    );
  }

  receivedBlacklistsPatternsHistoryChunk(
    eop: number,
    sop: number,
    currentPage: [],
    hash: string
  ) {
    this._store.dispatch(
      new BlacklistPatternsActions.ReceivedBlacklistPatternsHistoryChunk(
        eop,
        sop,
        currentPage,
        hash
      )
    );
  }

  deletePatternFromBlacklist(data: string[], mainChatID: string) {
    this._store.dispatch(
      new BlacklistPatternsActions.DeletePatternFromBlacklist(data, mainChatID)
    );
  }

  deletePatternFromBlacklistSuccess(data: string[]) {
    this._store.dispatch(
      new BlacklistPatternsActions.DeletePatternFromBlacklistSuccess(data)
    );
  }

  setStatusAsIdle() {
    this._store.dispatch(new BlacklistPatternsActions.SetStatusAsIdle());
  }

  resetObject() {
    this._store.dispatch(new BlacklistPatternsActions.Reset());
  }
}
