import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ItemDispatchers } from 'src/app/store/channelAppItems/item.dispatchers';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { MyPageDispatchers } from 'src/app/store/channelMyPage/myPage.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ContainerDispatchers } from 'src/app/store/channelAppContainers/container.dispatchers';
import { AppClassDispatchers } from 'src/app/store/channelAppClasses/appClass.dispatchers';
import { AppConfigDispatchers } from 'src/app/store/channelAppConfig/appConfig.dispatchers';
import { ComponentDispatchers } from 'src/app/store/channelAppComponents/component.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';
import { AppReleaseDispatchers } from 'src/app/store/channelAppReleases/appRelease.dispatchers';
import { InstantPageDispatchers } from 'src/app/store/channelAppInstantPage/instantPage.dispatchers';
import { ChannelAppUiDispatchers } from 'src/app/store/channelAppUI/channelAppUI.dispatchers';
import { AppPublishingDispatchers } from 'src/app/store/appPublishing/appPublishing.dispatchers';
import { AppConfigHistoryDispatchers } from 'src/app/store/appConfigHistory/appConfigHistory.dispatchers';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';

import { AppMenu } from 'src/models/AppMenu';
import { IOption } from 'src/models/campaign';
import { AppRelease } from 'src/models/AppRelease';
import { IChatMember } from 'src/models/IChatMember';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ChannelAppUIState } from 'src/models/ChannelAppUIState';
import { AppPublishInfo, AppleKeyCodeUI } from 'src/models/AppPublishInfo';
import {
  AppClassNames,
  MessageTypes,
  PaymentProviderIDs,
  UserData,
  AdsConfig,
  FeaturesModule
} from 'src/models/constants';
import {
  LocalItem,
  LocalComponent,
  LocalContainer,
  LocalAppClass,
  LocalAppInfo
} from 'src/models/ChannelAppLocalConfig';
import {
  AppInfo,
  SystemConfig,
  AppColors,
  AppConfigHistoryRecord
} from 'src/models/ChannelAppConfig';
import {
  MyPage,
  MyPageContainer,
  MyPageComponent,
  MyPageForm
} from 'src/models/MyPageConfig';
import { ProviderConfig, PaymentProvider } from 'src/models/PaymentGateways';
import { AdsProvidersDispatchers } from './../../../store/adsProvider/adsProvider.dispatchers';
import { KeysConfig } from './../../../../models/constants';
import { BotMenuButton } from 'src/models/IBotMenu';
import { AppChannelSettingsDispatchers } from 'src/app/store/appChannelSettings/appChannelSettings.dispatchers';
import { StmpDispatchers } from './../../../store/smtp/smtp.dispatchers';
import { Smtp } from 'src/models/Smtp';
import { MainChatDispatchers } from './../../../store/mainChats/mainChat.dispatchers';
import { UploadAppIcon } from './../../../store/channelAppConfig/appConfig.actions';

@Injectable({ providedIn: 'root' })
export class AppMgmtService {
  private showAppleKeyForm = new BehaviorSubject<AppleKeyCodeUI>({
    show: false
  });
  public showAppleKeyFormObservable$ = this.showAppleKeyForm.asObservable();

  public _imageUrl = new BehaviorSubject<string>('');
  public imageUrl$ = this._imageUrl.asObservable();

  public _selectedCreditCard = new BehaviorSubject<FeaturesModule>({});
  public selectedCreditCard$ = this._selectedCreditCard.asObservable();

  public _buttonArray = new BehaviorSubject<IOption[]>([]);
  public buttonArray$ = this._buttonArray.asObservable();

  public _newCreatedButtons = new BehaviorSubject<BotMenuButton[]>([]);
  public newCreatedButtons$ = this._newCreatedButtons.asObservable();

  public _showConfirmMsg = new BehaviorSubject<string>(null);
  public showConfirmMsg$ = this._showConfirmMsg.asObservable();

  public _selectedItem = new BehaviorSubject<any>({});
  public selectedItem$ = this._selectedItem.asObservable();

  public _appIcon = new BehaviorSubject<{}>(null);
  public appIcon$ = this._appIcon.asObservable();

  public uploadedIcon: any;
  constructor(
    private _selectors: RootStoreSelectors,
    private _authDispatchers: AuthDispatchers,
    private _channelAppDispatchers: ChannelAppUiDispatchers,
    private _itemDispatchers: ItemDispatchers,
    private _componentDispatchers: ComponentDispatchers,
    private _containerDispatchers: ContainerDispatchers,
    private _appClassDispatchers: AppClassDispatchers,
    private _appConfigDispatchers: AppConfigDispatchers,
    private _appReleaseDispatchers: AppReleaseDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _instantPageDispatchers: InstantPageDispatchers,
    private _appPublishingDispatchers: AppPublishingDispatchers,
    private _appConfigHistoryDispatchers: AppConfigHistoryDispatchers,
    private _chatMemberDispatchers: ChatMemberDispatchers,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers,
    private _adsProvidersDispatchers: AdsProvidersDispatchers,
    private _appChannelSettingsDispatchers: AppChannelSettingsDispatchers,
    private _smtpDispatchers: StmpDispatchers,
    private _mainChatDispatchers: MainChatDispatchers
  ) {}

  /*
   * Channel APP Selectors
   */
  get currentPage$(): Observable<number> {
    return this._selectors.currentPage$.pipe();
  }

  get channelAppUiCollection$(): Observable<ChannelAppUIState> {
    return this._selectors.channelAppUiCollection$.pipe();
  }

  get appInfo$(): Observable<AppInfo> {
    return this._selectors.appInfo$.pipe();
  }

  // Navigation Screen selectors
  get navMenuItems$(): Observable<LocalItem[]> {
    return this._selectors.navMenuItems$.pipe();
  }

  get navMenuComponent$(): Observable<LocalComponent> {
    return this._selectors.navMenuComponent$.pipe();
  }

  get navHeaderComponent$(): Observable<LocalComponent> {
    return this._selectors.navHeaderComponent$.pipe();
  }

  // Tabs Selectors
  get tabsContainers$(): Observable<LocalContainer[]> {
    return this._selectors.tabsContainers$.pipe();
  }

  get tabsAppClass$(): Observable<LocalAppClass> {
    return this._selectors.tabsAppClass$.pipe();
  }

  get menusAppClass$(): Observable<LocalAppClass> {
    return this._selectors.menusAppClass$.pipe();
  }

  get effectiveSelectedTab$(): Observable<LocalContainer> {
    return this._selectors.getEffectiveSelectedTabOrSection$().pipe();
  }

  // Tab Footer
  get footerComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getFooterComponentOfSelectedTab$().pipe();
  }

  get footerItemsOfSelectedTab$(): Observable<LocalItem[]> {
    return this._selectors.getFooterItemsOfSelectedTab$().pipe();
  }
  // Tab Menu
  get menuComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getMenuComponentOfSelectedTab$().pipe();
  }

  get menuItemsOfSelectedTab$(): Observable<LocalItem[]> {
    return this._selectors.getMenuItemsOfSelectedTab$().pipe();
  }

  get getMenuRefOfSelectedTab$(): Observable<any> {
    return this._selectors.getMenuRefOfSelectedTab$().pipe();
  }
  // Tab Post
  get postComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getPostComponentOfSelectedTab$().pipe();
  }

  // Tab Card
  get cardComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getCardComponentOfSelectedTab$().pipe();
  }
  // Tab Wallet

  get walletComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getWalletComponentOfSelectedTab$().pipe();
  }

  // Tab Web View

  get webviewComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getWebviewComponentOfSelectedTab$().pipe();
  }

  // accept invitation component
  get acceptInvitationCompOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getAcceptInvitationCompOfSelectedTab$().pipe();
  }

  // reject invitation component
  get rejectInvitationCompOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getRejectInvitationCompOfSelectedTab$().pipe();
  }

  // Search Tab
  get searchComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getSearchComponentOfSelectedTab$().pipe();
  }

  // Search sidemenu
  get searchComponentOfSelectedItem$(): Observable<LocalComponent> {
    return this._selectors.getSearchComponentOfSelectedItem$().pipe();
  }

  // MapSearch Tab
  get mapSearchComponentOfSelectedTab$(): Observable<LocalComponent> {
    return this._selectors.getMapSearchComponentOfSelectedTab$().pipe();
  }

  // MapSearch sidemenu
  get mapSearchComponentOfSelectedItem$(): Observable<LocalComponent> {
    return this._selectors.getMapSearchComponentOfSelectedItem$().pipe();
  }
  get mapSearchItemsOfSelectedTab$(): Observable<LocalItem[]> {
    return this._selectors.getMapSearchItemsOfSelectedTab$().pipe();
  }

  get mapSearchItemsOfSelectedItem$(): Observable<LocalItem[]> {
    return this._selectors.getMapSearchItemsOfSelectedItem$().pipe();
  }

  get searchItemsOfSelectedTab$(): Observable<LocalItem[]> {
    return this._selectors.getSearchItemsOfSelectedTab$().pipe();
  }

  get searchItemsOfSelectedItem$(): Observable<LocalItem[]> {
    return this._selectors.getSearchItemsOfSelectedItem$().pipe();
  }

  // to get the search index containers
  get searchContainers$(): Observable<LocalContainer[]> {
    return this._selectors.searchContainers$.pipe();
  }

  get activeSearchContainers$(): Observable<LocalContainer[]> {
    return this._selectors.activeSearchContainers$.pipe();
  }

  get activeSearchItem$(): Observable<LocalContainer[]> {
    return this._selectors.activeSearchItem$.pipe();
  }

  get searchAppClass$(): Observable<LocalAppClass> {
    return this._selectors.searchAppClass$.pipe();
  }

  get paymentProviderConfg$(): Observable<PaymentProvider[]> {
    return this._selectors.getPaymentProviders$().pipe();
  }

  get adsProviderConfg$(): Observable<AdsConfig> {
    return this._selectors.getAdsroviders$().pipe();
  }
  // Channel APP Actions

  getNextPage() {
    this._channelAppDispatchers.getNextPage();
  }

  setAppChannelSettings(state: boolean) {
    this._appChannelSettingsDispatchers.setAppChannelSettings(state);
  }

  getAppChannelSettings() {
    this._appChannelSettingsDispatchers.getAppChannelSettings();
  }

  get getAppChannelSettings$() {
    return this._selectors.getAppChannelSettings$();
  }

  getPreviousPage() {
    this._channelAppDispatchers.getPreviousPage();
  }

  selectTab(selectedTab: LocalContainer): void {
    this._channelAppDispatchers.selectTab(selectedTab);
  }

  selectFirstTab(): void {
    // console.log('here is the selection of the first tab');
    this._containerDispatchers.selectFirstContainer(AppClassNames.TABS);
  }

  goToSpecificPage(pageNumber: number): void {
    this._channelAppDispatchers.goToSpecificPage(pageNumber);
  }

  updatePublishButtonFlag(canPublish: boolean): void {
    this._channelAppDispatchers.updatePublishButtonFlag(canPublish);
  }

  updateTotalAppConfFlag(totalAppConf: boolean): void {
    this._channelAppDispatchers.updateTotalAppConfFlag(totalAppConf);
  }

  toggleAndroidVersion(payload: boolean) {
    this._channelAppDispatchers.toggleAndroidVersion(payload);
  }

  toggleIosVersion(payload: boolean) {
    this._channelAppDispatchers.toggleIosVersion(payload);
  }

  updateItem(itemRef: string, itemUpdatedProps: LocalItem): void {
    this._itemDispatchers.updateItem(itemRef, itemUpdatedProps);
  }

  updateItemLink(itemRef: string, itemLink: string, pageId: string): void {
    this._itemDispatchers.requestUpdateItemLink(itemRef, itemLink, pageId);
  }

  reorderItem(itemRef: string, newPosition: number): void {
    this._itemDispatchers.reorderItem(itemRef, newPosition);
  }

  deleteItem(item: LocalItem): void {
    this._itemDispatchers.deleteItemRequest(item);
  }

  addItem(component: LocalComponent, specialItemValues?: LocalItem): void {
    this._itemDispatchers.addItemRequest(component, specialItemValues);
  }

  updateComponent(
    componetRef: string,
    componentUpdatedProps: LocalComponent
  ): void {
    this._componentDispatchers.updateComponent(
      componetRef,
      componentUpdatedProps
    );
  }

  openComponentSection(component: LocalComponent) {
    this._componentDispatchers.openSection(component);
  }

  closeComponentSection(component: LocalComponent) {
    this._componentDispatchers.closeSection(component);
  }

  openClassSection(appClass: LocalAppClass) {
    this._appClassDispatchers.openClassSection(appClass);
  }

  closeClassSection(appClass: LocalAppClass) {
    this._appClassDispatchers.closeClassSection(appClass);
  }

  updateContainer(
    containerRef: string,
    containerUpdatedProps: LocalContainer
  ): void {
    this._containerDispatchers.updateContainer(
      containerRef,
      containerUpdatedProps
    );
  }

  updateTabType(containerRef: string, tabType: any, pageId: string): void {
    this._containerDispatchers.updateTabTypeRequest(
      containerRef,
      tabType,
      pageId
    );
  }

  deleteContainer(container: LocalContainer): void {
    this._containerDispatchers.deleteContainerRequest(container);
  }

  deleteActulContainer(container: LocalContainer): void {
    this._containerDispatchers.deleteContainer(container);
  }
  addContainer(
    parentAppClass: LocalAppClass,
    specialContainerValues?: LocalContainer
  ): void {
    this._containerDispatchers.addContainerRequest(
      parentAppClass,
      specialContainerValues
    );
  }

  reorderContainer(containerRef: string, newPosition: number): void {
    this._containerDispatchers.reorderContainer(containerRef, newPosition);
  }

  updateAppClass(
    appClassRef: string,
    appClassUpdatedProps: LocalAppClass
  ): void {
    this._appClassDispatchers.updateAppClass(appClassRef, appClassUpdatedProps);
  }

  notAllowedClassBgColors(): void {
    this._appClassDispatchers.notAllowedClassBgColors();
  }

  savePage(
    channelConfOnly: boolean,
    selectedPageNumber?: number,
    featuresIds?: string[]
  ): void {
    // console.log('using mgm ser', this.uploadedIcon);
    if (this.uploadedIcon) {
      this._appConfigDispatchers.uploadAppImage(this.uploadedIcon);
    }
    this._appConfigDispatchers.setAppConfig(
      channelConfOnly,
      selectedPageNumber,
      featuresIds
    );
  }

  savePaymentProviderConfg(
    providerId: PaymentProviderIDs,
    config: ProviderConfig,
    active: number
  ): void {
    this._paymentProvidersDispatchers.setPaymentProvider(
      providerId,
      config,
      active
    );
  }

  saveAdsProviderConfg(adsProviderId: string, config: KeysConfig): void {
    this._adsProvidersDispatchers.setAdsProvider(adsProviderId, config);
  }

  getAdsConfig$(adsProviderId: string) {
    this._adsProvidersDispatchers.getAdsProviders(adsProviderId);
  }

  publishOnlineConfig(
    isChannelOnly: boolean,
    track: number = 0,
    versionName?: string,
    versionDesc?: string
  ): void {
    this._containerDispatchers.updateMapContainerOnPublish(isChannelOnly);
    setTimeout(
      () =>
        this._appConfigDispatchers.publishAppConfig(
          isChannelOnly,
          track,
          versionName,
          versionDesc
        ),
      1000
    );
  }

  generateApp(
    appId: string,
    os: number,
    android_format: string,
    ver_name,
    ver_desc
  ): void {
    if (+os === 2) {
      this._appConfigDispatchers.generateApp(
        appId,
        0,
        android_format,
        ver_name,
        ver_desc
      );
      this._appConfigDispatchers.generateApp(
        appId,
        1,
        android_format,
        ver_name,
        ver_desc
      );
    } else {
      this._appConfigDispatchers.generateApp(
        appId,
        +os,
        android_format,
        ver_name,
        ver_desc
      );
    }
  }
  // upload and download actions
  uploadComponentImage(
    file: File,
    component: LocalComponent,
    isBackground?: boolean
  ): void {
    const selectedFile: ISelectedFiles = {};
    selectedFile.localFile = file;
    selectedFile.type = MessageTypes.PHOTO;
    this._componentDispatchers.uploadComponentMedia(
      selectedFile,
      component,
      isBackground
    );
  }

  get systemConfig$(): Observable<SystemConfig> {
    return this._selectors.systemConfig$.pipe();
  }

  updateAppColors(appColors: AppColors) {
    this._appConfigDispatchers.updateAppColors(appColors);
  }

  uploadColoredLogo(selectedFile: ISelectedFiles) {
    this._appConfigDispatchers.uploadAppColoredLogo(selectedFile);
  }

  getDomaintColor(selectedFile: ISelectedFiles) {
    this._appConfigDispatchers.getDomainatColor(selectedFile);
  }

  uploadWhiteLogo(selectedFile: ISelectedFiles) {
    this._appConfigDispatchers.uploadAppWhiteLogo(selectedFile);
  }

  uploadIosStoreLogo(selectedFile: ISelectedFiles) {
    this._appConfigDispatchers.uploadIosStoreLogo(selectedFile);
  }
  //
  listAppReleases() {
    this._appReleaseDispatchers.listAppReleases();
  }

  listAppReleaseId(appRelese: AppRelease) {
    this._appReleaseDispatchers.listAppReleaseId(appRelese);
  }
  removeAppRelease(id: number) {
    this._appReleaseDispatchers.removeAppRelease(id);
  }

  get appReleaseList$(): Observable<AppRelease[]> {
    return this._selectors.appReleaseList$.pipe();
  }

  getAppReleaseById$(id: number): Observable<AppRelease> {
    return this._selectors.getAppReleaseById$(id);
  }

  hideAppReleaseDetails(id: number) {
    this._appReleaseDispatchers.hideAppReleaseDetails(id);
  }

  showAppReleaseDetails(id: number) {
    this._appReleaseDispatchers.viewAppReleaseDetails(id);
  }

  // Templates By Category
  get myProfileID(): string {
    return this._authDispatchers.getLoginID();
  }

  get localAppInfo$(): Observable<LocalAppInfo> {
    return this._selectors.localAppInfo$.pipe();
  }

  get availableApps$(): Observable<IOption[]> {
    return this._selectors.localAppInfo$.pipe(
      map(info => info.listOfAvailableApps),
      map(apps => {
        const options: IOption[] = [];
        if (apps && apps.length > 0) {
          apps.forEach((app, index) => {
            const option: IOption = { key: app.id, value: app.name };
            if (index === 0) {
              option.selected = true;
            }
            options.push(option);
          });
        }
        return options;
      })
    );
  }

  // Splash Selectors
  get splashPages$(): Observable<LocalComponent[]> {
    return this._selectors.splashPages$.pipe();
  }

  get splashFooter$(): Observable<LocalContainer> {
    return this._selectors.splashFooter$.pipe();
  }

  get splashFooterButton$(): Observable<LocalComponent> {
    return this._selectors.splashFooterButton$.pipe();
  }

  get splashHeader$(): Observable<LocalContainer> {
    return this._selectors.splashHeader$.pipe();
  }

  get selectedSplashPage$(): Observable<LocalComponent> {
    return this._selectors.selectedSplashPage$.pipe();
  }

  reorderComponent(componentRef: string, newPosition: number): void {
    this._componentDispatchers.reorderComponent(componentRef, newPosition);
  }

  addComponent(parentContainer: any): void {
    this._componentDispatchers.addComponentRequest(parentContainer);
  }

  deleteComponent(component: LocalComponent): void {
    this._componentDispatchers.deleteComponentRequest(component);
  }

  selectComponent(component: LocalComponent): void {
    this._componentDispatchers.selectComponent(component);
  }

  // Update app Info

  updateAppInfo(appInfo: AppInfo) {
    this._appConfigDispatchers.updateAppInfo(appInfo);
  }

  // Upload splash page logo

  uploadSplashPageLogo(componentRef: string, selectedFile: ISelectedFiles) {
    this._componentDispatchers.UploadSplashPageLogo(componentRef, selectedFile);
  }

  uploadVideoBackground(
    componentRef: string,
    currentPage: string,
    selectedFile: ISelectedFiles
  ) {
    this._componentDispatchers.uploadVideoBackground(
      componentRef,
      currentPage,
      selectedFile
    );
  }
  updateMainChannelImage(id, imageUrl) {
    this._mainChatDispatchers.updateMainChatImages(id, imageUrl);
  }

  // Profiles
  get profileFields$(): Observable<LocalContainer[]> {
    return this._selectors.profileContainers$.pipe();
  }

  get profilesAppClass$(): Observable<LocalAppClass> {
    return this._selectors.profilesAppClass$.pipe();
  }

  // Maps
  get mapAppMenus$(): Observable<AppMenu[]> {
    return this._selectors.mapAppMenus$.pipe();
  }

  // My Page
  deleteMyPage(pageID: string) {
    return this._myPageDispatchers.deleteMyPage(pageID);
  }

  getMyPagePyID$(pageID: string): Observable<MyPage> {
    // console.log('here i am ');
    return this._selectors.getMyPageByID$(pageID).pipe();
  }
  getLeatestMyPage$(name, temId): Observable<MyPage> {
    return this._selectors.getLeatestMyPage$(name, temId);
  }
  getMyPages$(pageIdToExclude: string): Observable<MyPage[]> {
    return this._selectors.getMyPages$(pageIdToExclude).pipe();
  }
  // Instant Pages
  // call this on click on a specific page
  requestPageDetails(pageId: string) {
    this._instantPageDispatchers.requestInstantPageDetails(pageId);
  }

  getInstantPageByID$(pageID: string): Observable<MyPage> {
    return this._selectors.getInstantPageByID$(pageID).pipe();
  }

  getInstantPages$(): Observable<MyPage[]> {
    return this._selectors.getInstantPages$().pipe();
  }
  //

  updateMyPage(id: string, myPageDetails: MyPage): void {
    this._myPageDispatchers.updateMyPage(id, myPageDetails);
  }

  getDetailsOfLinkedPage(linkedPageID: string): void {
    this._myPageDispatchers.getDetailsOfLinkedPage(linkedPageID);
  }

  createMyPage(
    name?: string,
    image?: string,
    desc?: string,
    myPageForm?: MyPageForm,
    templateId?: string
  ): void {
    this._myPageDispatchers.createMyPage(
      Date.now() + '',
      myPageForm,
      name,
      null,
      image,
      desc,
      templateId
    );
  }

  createPageTemplate(
    name?: string,
    image?: string,
    desc?: string,
    myPage?: MyPage
  ): void {
    this._myPageDispatchers.createPageTemplate(myPage, name, image, desc);
  }

  createAppTemplate(
    androidImageUrl: string,
    iosImageUrl: string,
    categories: string[],
    name: string,
    order: number,
    id?: any
  ) {
    this._appConfigDispatchers.createAppTemplate(
      androidImageUrl,
      iosImageUrl,
      categories,
      name,
      order,
      id
    );
  }
  requestAppTemplate(templateID) {
    this._appConfigDispatchers.requestAppTemplate(templateID);
  }
  updateMyPageForm(
    formID: string,
    title: string,
    image: string,
    template: number,
    background: string
  ): void {
    this._myPageDispatchers.updateMyPageForm(
      formID,
      title,
      image,
      template,
      background
    );
  }

  updateMyPageContainer(
    formID: string,
    containerID: string,
    containerUpdatedProps: MyPageContainer
  ): void {
    this._myPageDispatchers.updateMyPageContainer(
      formID,
      containerID,
      containerUpdatedProps
    );
  }

  deleteMyPageContainer(formID: string, container: MyPageContainer): void {
    this._myPageDispatchers.deleteMyPageContainer(formID, container);
  }

  addMyPageContainer(formID: string, container: MyPageContainer): void {
    this._myPageDispatchers.addMyPageContainer(formID, container);
  }

  reorderPageContainers(
    formID: string,
    containerID: string,
    newPosition: number
  ): void {
    this._myPageDispatchers.reorderPageContainers(
      formID,
      containerID,
      newPosition
    );
  }

  updateMyPageComponent(
    formID: string,
    containerID: string,
    componentID: string,
    componentUpdatedProps: MyPageComponent
  ): void {
    this._myPageDispatchers.updateMyPageComponent(
      formID,
      containerID,
      componentID,
      componentUpdatedProps
    );
  }

  deleteMyPageComponent(
    formID: string,
    containerID: string,
    component: MyPageComponent
  ): void {
    this._myPageDispatchers.deleteMyPageComponent(
      formID,
      containerID,
      component
    );
  }

  addMyPageComponent(
    formID: string,
    containerID: string,
    component: MyPageComponent
  ): void {
    this._myPageDispatchers.addMyPageComponent(formID, containerID, component);
  }

  reorderPageComponents(
    formID: string,
    containerID: string,
    componentID: string,
    newPosition: number
  ): void {
    this._myPageDispatchers.reorderPageComponents(
      formID,
      containerID,
      componentID,
      newPosition
    );
  }

  saveAppPublishingInfo(appPublishingInfo: AppPublishInfo): void {
    this._appPublishingDispatchers.setAppStoreInfo(appPublishingInfo);
  }

  getAppPublishingInfo$(): Observable<AppPublishInfo> {
    return this._selectors.getAppPublishingInfo$().pipe();
  }

  showAppleForm(releaseId: number) {
    this.showAppleKeyForm.next({ show: true, release_id: releaseId });
  }

  sendAppleKeyCode(code: string, release_id: number) {
    this._appReleaseDispatchers.sendAppleKeyCode(code, release_id);
    this.hideAppleKeyForm();
  }

  hideAppleKeyForm() {
    this.showAppleKeyForm.next({ show: false, release_id: null });
  }

  // App Config History
  getAppConfigHistoryList$(): Observable<AppConfigHistoryRecord[]> {
    return this._selectors.getAppConfigHistoryList$().pipe();
  }

  restoreAppConfigHistoryById(id: string) {
    this._appConfigHistoryDispatchers.restoreAppConfigByID(id);
  }

  requestAppConfigsHistory() {
    this._appConfigHistoryDispatchers.requestAppConfigsHistory();
  }

  requestAppStoreInfo(appId: string) {
    this._appPublishingDispatchers.requestAppStoreInfo(appId);
  }

  // App Testers
  // use it for the shown list in ui
  getAppTesters$(): Observable<IChatMember[]> {
    return this._selectors.testerMembers$.pipe();
  }

  removeTester(userId: string) {
    this._chatMemberDispatchers.setMemberTesterFlag(userId, 0);
  }

  // get qr code to be used in add
  getQrCodeToAddTester$(): Observable<string> {
    return this._selectors.qrCodeToAddTester$;
  }

  requestAppTesters() {
    this._appPublishingDispatchers.requestAppTesters();
  }

  getConfigQrCode$(): Observable<string> {
    return this._selectors.configQrCode$;
  }

  requestConfigQrCode() {
    this._appConfigDispatchers.requestConfigQrCode();
  }

  // Sections Selectors

  // parentTabRef (the ref of the selected tab that we want to get its sections)
  getTabSections$(parentTabRef: string): Observable<LocalContainer[]> {
    return this._selectors.getTabSections$(parentTabRef).pipe();
  }

  // sectionRef: is the ref of the selected container (this container is a section)
  getSectionDetails$(sectionRef: string): Observable<LocalContainer> {
    return this._selectors.getEffectiveSelectedTabOrSection$(sectionRef).pipe();
  }

  // Section Tab Footer
  getFooterComponentBySectionRef$(
    sectionRef: string
  ): Observable<LocalComponent> {
    return this._selectors.getFooterComponentOfSelectedTab$(sectionRef).pipe();
  }

  getFooterItemsBySectionRef$(sectionRef: string): Observable<LocalItem[]> {
    return this._selectors.getFooterItemsOfSelectedTab$(sectionRef).pipe();
  }
  // Section Tab Menu
  getMenuComponentBySectionRef$(
    sectionRef: string
  ): Observable<LocalComponent> {
    return this._selectors.getMenuComponentOfSelectedTab$(sectionRef).pipe();
  }

  getMenuItemsBySectionRef$(sectionRef): Observable<LocalItem[]> {
    return this._selectors.getMenuItemsOfSelectedTab$(sectionRef).pipe();
  }
  // Section Tab Post
  getPostComponentBySectionRef$(
    sectionRef: string
  ): Observable<LocalComponent> {
    return this._selectors.getPostComponentOfSelectedTab$(sectionRef).pipe();
  }

  // Section Tab Card
  getCardComponentBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors.getCardComponentOfSelectedTab$(sectionRef).pipe();
  }
  // Section Tab Wallet

  getWalletComponentBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors.getWalletComponentOfSelectedTab$(sectionRef).pipe();
  }

  // Section Tab Web View

  getWebviewComponentBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors.getWebviewComponentOfSelectedTab$(sectionRef).pipe();
  }

  //  Section Tab accept invitation component
  getAcceptInvitationCompBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors
      .getAcceptInvitationCompOfSelectedTab$(sectionRef)
      .pipe();
  }

  //  Section Tab reject invitation component
  getRejectInvitationCompBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors
      .getRejectInvitationCompOfSelectedTab$(sectionRef)
      .pipe();
  }

  // Section Tab  Search
  getSearchComponentBySectionRef$(sectionRef): Observable<LocalComponent> {
    return this._selectors.getSearchComponentOfSelectedTab$(sectionRef).pipe();
  }

  getSearchItemsBySectionRef$(): Observable<LocalItem[]> {
    return this._selectors.getSearchItemsOfSelectedTab$().pipe();
  }

  addSection(
    parentTabRef: string,
    specialContainerValues?: LocalContainer
  ): void {
    this._containerDispatchers.addSectionRequest(
      parentTabRef,
      specialContainerValues
    );
  }

  setUserData(userData: UserData) {
    this._appConfigDispatchers.setApp(userData);
  }

  requestAppConfig() {
    this._appConfigDispatchers.requestAppConfig();
  }

  saveInAppPurchases(json: string) {
    this._appConfigDispatchers.saveInAppPurchases(json);
  }

  getAppRealseVerData(id) {
    this._appReleaseDispatchers.getAppVer(id);
  }

  uploadTempImageToProfile(imageId) {
    this._appConfigDispatchers.UpldTempImageToProfile(imageId);
  }

  setSmtp(smtp: Smtp) {
    this._smtpDispatchers.setStmp(smtp);
  }

  getSmtp() {
    this._smtpDispatchers.getStmp();
  }
  get smtp$(): Observable<Smtp> {
    return this._selectors.getSmtp$().pipe();
  }

  setUploadedIcon(icon) {
    this.uploadedIcon = icon;
  }

  confirmMassage(item) {
    const appInfo: AppInfo = {};
    switch (item.id) {
      case 27:
        this._showConfirmMsg.next(null);
        this._selectedItem.next(null);
        this.setAppChannelSettings(true);
        break;
      case 28:
        this._showConfirmMsg.next(null);
        this._selectedItem.next(null);
        appInfo.public_links_hidden = true;
        this.updateAppInfo(appInfo);
        break;
    }
  }
}
