import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as EmojiConvertor from 'emoji-js/lib/emoji.js';

@Pipe({
  name: 'twemoji'
})
export class TwemojiPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: string): SafeHtml {
    if (value) {
      const emoji = new EmojiConvertor();
      emoji.use_sheet = true;
      emoji.include_title = true;
      emoji.img_sets.apple.path = './assets/emojis/';
      emoji.img_sets.apple.sheet =
        './assets/emojis/sheet_apple_64_indexed_128.png';
      value = emoji.replace_unified(value);

      value = value.replace(
        /<span class="emoji-outer emoji-sizer"><span class="emoji-inner" style="(.*?)" title="(.*?)" .*?>.*?<\/span><\/span>/g,
        `<img class="emoji-inner" src="./assets/img/blank.gif" style="$1" alt=":$2:"/>`
      );
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    return '';
  }
}
