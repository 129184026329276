/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headerless-closable-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./headerless-closable-frame.component";
var styles_HeaderlessClosableFrameComponent = [i0.styles];
var RenderType_HeaderlessClosableFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderlessClosableFrameComponent, data: {} });
export { RenderType_HeaderlessClosableFrameComponent as RenderType_HeaderlessClosableFrameComponent };
function View_HeaderlessClosableFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "./assets/img/cancel-key.svg"]], null, null, null, null, null))], null, null); }
export function View_HeaderlessClosableFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "closable-frame"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderlessClosableFrameComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCancel; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HeaderlessClosableFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-headerless-closable-frame", [], null, null, null, View_HeaderlessClosableFrameComponent_0, RenderType_HeaderlessClosableFrameComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderlessClosableFrameComponent, [], null, null)], null, null); }
var HeaderlessClosableFrameComponentNgFactory = i1.ɵccf("app-headerless-closable-frame", i3.HeaderlessClosableFrameComponent, View_HeaderlessClosableFrameComponent_Host_0, { showCancel: "showCancel" }, { closeNotify: "closeNotify" }, ["content"]);
export { HeaderlessClosableFrameComponentNgFactory as HeaderlessClosableFrameComponentNgFactory };
