import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { GOOGLE_ANALYTICS_KEY } from 'src/models/constants';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line: ban-types
declare var ga: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private router: Router) {}

  public init() {
    this.listenForRouteChanges();

    try {
      const script2 = document.createElement('script');
      script2.innerHTML = `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
              (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

          ga('create', '${GOOGLE_ANALYTICS_KEY}', 'auto');  // Change the UA-ID to the one you got from Google Analytics`;

      document.getElementsByTagName('footer')[0].appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  private listenForRouteChanges() {
    if (environment.production) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('config', GOOGLE_ANALYTICS_KEY, {
            page_path: event.urlAfterRedirects
          });
          // ga(
          //   'config',
          //   GOOGLE_ANALYTICS_SECOND_KEY,
          //   {
          //     page_path: event.urlAfterRedirects
          //   },
          //   'clientTracker'
          // );
          ga('send', 'pageview');
          // ga('clientTracker.send', 'pageview');
        }
      });
    }
  }

  // create our event emitter to send our data to Google Analytics
  public eventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (environment.production) {
      ga('send', 'event', {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue
      });
      ga('clientTracker.send', 'event', {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue
      });
    }
  }
}
