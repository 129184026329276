/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-calendar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../abstract-btns/switch-btn/switch-btn.component.ngfactory";
import * as i3 from "../../../../abstract-btns/switch-btn/switch-btn.component";
import * as i4 from "../../../../cells/calendar-preview-cell/calendar-preview-cell.component.ngfactory";
import * as i5 from "../../../../cells/calendar-preview-cell/calendar-preview-cell.component";
import * as i6 from "../../../../cells/cells.service";
import * as i7 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i8 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i9 from "./preview-calendar.component";
var styles_PreviewCalendarComponent = [i0.styles];
var RenderType_PreviewCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewCalendarComponent, data: {} });
export { RenderType_PreviewCalendarComponent as RenderType_PreviewCalendarComponent };
export function View_PreviewCalendarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "compose-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "calendar-flag"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "calendar-flag-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Auto Accept"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-switch-btn", [], null, [[null, "switchIsOn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("switchIsOn" === en)) {
        var pd_0 = (_co.setAutoAcceptCalendar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SwitchBtnComponent_0, i2.RenderType_SwitchBtnComponent)), i1.ɵdid(6, 49152, null, 0, i3.SwitchBtnComponent, [], { isOn: [0, "isOn"] }, { switchIsOn: "switchIsOn" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-calendar-preview-cell", [["class", "compose-component"]], null, null, null, i4.View_CalendarPreviewCellComponent_0, i4.RenderType_CalendarPreviewCellComponent)), i1.ɵdid(8, 49152, null, 0, i5.CalendarPreviewCellComponent, [i6.CellsService], { calendarAvtar: [0, "calendarAvtar"], calendarName: [1, "calendarName"], calendarDescription: [2, "calendarDescription"], calendarAccept: [3, "calendarAccept"] }, null), i1.ɵncd(null, 0), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "form-submit"]], null, null, null, null, null)), i1.ɵncd(null, 2), i1.ɵncd(null, 3), i1.ɵncd(null, 4), (_l()(), i1.ɵeld(16, 0, null, null, 2, "app-border-btn", [["cancel", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_BorderBtnComponent_0, i7.RenderType_BorderBtnComponent)), i1.ɵdid(17, 573440, null, 0, i8.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Clear "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoAcceptCalendar; _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.selectedCalendar == null) ? null : _co.selectedCalendar.url); var currVal_2 = ((_co.selectedCalendar == null) ? null : _co.selectedCalendar.title); var currVal_3 = ((_co.selectedCalendar == null) ? null : _co.selectedCalendar.description); var currVal_4 = !_co.autoAcceptCalendar; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PreviewCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-calendar", [], null, null, null, View_PreviewCalendarComponent_0, RenderType_PreviewCalendarComponent)), i1.ɵdid(1, 49152, null, 0, i9.PreviewCalendarComponent, [], null, null)], null, null); }
var PreviewCalendarComponentNgFactory = i1.ɵccf("app-preview-calendar", i9.PreviewCalendarComponent, View_PreviewCalendarComponent_Host_0, { selectedCalendar: "selectedCalendar" }, { cancelNotfiy: "cancelNotfiy" }, ["[message-attachs]", "[message-target]", "[footer-tag]", "[footer-submit]", "[footer-cancel]"]);
export { PreviewCalendarComponentNgFactory as PreviewCalendarComponentNgFactory };
