import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';

import { HelperService } from 'src/app/core/helper.service';

@Component({
  selector: 'app-compose-text-input',
  templateUrl: './compose-text-input.component.html',
  styleUrls: ['./compose-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComposeTextInputComponent {
  index = 0;

  showColors = true;
  currentBoxStyle = {
    'background-color': '#f3f7ff',
    'font-size': '14px',
    'font-weight': 'normal',
    color: '#4b4657',
    display: 'flex'
  };
  colorsList = [
    '#F5A623',
    '#d33540',
    '#4a90e2',
    '#f4644e',
    '#b6388f',
    '#3cc29a',
    '#f67d41',
    '#40b1c8',
    '#9ccf67',
    '#1d77e1',
    '#7c52a0',
    '#000000'
  ];

  hoverEmoji: boolean;

  @ViewChild('textBox', { static: true }) textBox: ElementRef;

  constructor(private _helperService: HelperService) {}

  @HostListener('document:keydown', ['$event'])
  onEnterKey(event: KeyboardEvent) {
    if (this.textBox) {
      // Send message when hit enter not shift key
      if (event.which === 13 || event.keyCode === 13) {
        // event.shiftKey
      }
      if (event.keyCode === 8 || event.keyCode === 46) {
        const htmlContent = this.textBox.nativeElement;
        if (
          (htmlContent.textContent.length === 0 &&
            htmlContent.innerHTML.length === 0) ||
          htmlContent.innerHTML === '<br>'
        ) {
          this.textBox.nativeElement.textContent = '';
        }
      }
    }
  }

  onRichPaste(e) {
    const cData = (e.originalEvent || e).clipboardData;
    const items = (cData && cData.items) || [];
    let text;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        e.preventDefault();
        return true;
      }
    }

    try {
      text = cData.getData('text/plain');
    } catch (e) {
      return true;
    }
    if (text.length) {
      const textAfter = this._helperService.convertUnifiedAndColonsToEmojiSpan(
        this._helperService.getMultiLine(text)
      );
      document.execCommand('insertHTML', false, textAfter);
      return this._helperService.cancelEvent(e);
    }
    return true;
  }

  emoijNotify($event: string) {
    this.setFocus();
    // this._helperService.restoreSelection(this.index);
    this._helperService.insertEmojiAtCursor($event);
  }

  setFocus() {
    if (this.textBox) {
      this.textBox.nativeElement.focus();
      // this._helperService.restoreSelection(this.index);
    }
  }

  lastfocus() {
    this.index = this._helperService.saveSelection();
  }

  checkIfMessageIsShort() {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0 && textToSend.length < 150) {
        this.showColors = true;
      } else if (textToSend && textToSend.length >= 100) {
        this.resetStyles();
        this.showColors = false;
      }
    }
  }

  createNewLine(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      const newValue = '<br><br>';
      document.execCommand('insertHTML', false, newValue);
      return this._helperService.cancelEvent(e);
    }
  }

  resetStyles() {
    this.currentBoxStyle = {
      ...this.currentBoxStyle,
      'background-color': '#f3f7ff',
      'font-size': '14px',
      'font-weight': 'normal',
      color: '#4b4657',
      display: 'flex'
    };
    this.setFocus();
  }

  setCurrentBoxBackgroundColor(color: string) {
    this.currentBoxStyle = {
      ...this.currentBoxStyle,
      'background-color': color,
      'font-size': '31px',
      'font-weight': 'bold',
      color: '#FFF',
      display: 'flex'
    };
    this.setFocus();
  }

  getTextToSend() {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        return textToSend;
      }
      return null;
    }
    return null;
  }

  get checkForm(): string {
    const htmlText = this.textBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        return textToSend;
      }
    }
    return null;
  }

  get canSend(): boolean {
    if (this.checkForm) {
      return true;
    }
    return false;
  }

  getSelectedColor() {
    if (this.canSend) {
      const textToSend = this.getTextToSend();
      if (textToSend && textToSend.length > 0 && textToSend.length < 150) {
        const color = this.colorsList.filter(
          cl => cl === this.currentBoxStyle['background-color']
        );
        if (color && color.length > 0) {
          return color[0];
        }
        return null;
      } else {
        return null;
      }
    }
  }
  toggleTooltip(e) {
    this.hoverEmoji = e;
  }

  onFocus() {
    if (this.textBox.nativeElement.innerHTML) {
      let range = new Range();

      range.setStartAfter(this.textBox.nativeElement.lastChild);
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }
  }
}
