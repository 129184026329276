import { Injectable } from '@angular/core';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';

import * as TagMethods from 'src/models/ITag';

@Injectable({ providedIn: 'root' })
export class TagsService {
  constructor(
    private _storeSelector: RootStoreSelectors,
    private _messageDispatchers: MessageDispatchers
  ) {
    // this._messageDispatchers.listTags();
  }

  getTagsForUser(tags: TagMethods.ITag[]): string[] {
    return tags.map(val => val.id);
  }

  // CRUD Operations
  createTag(tag: TagMethods.ITag) {
    this._messageDispatchers.sendingCreateTag(tag);
  }

  deleteTag(tag: TagMethods.ITag) {
    this._messageDispatchers.sendingDeleteTag(tag);
  }

  setMemberTags(memberId: string, tags: string[]) {
    this._messageDispatchers.sendingMemberTags(memberId, tags);
  }

  // Selectors
  get selectedChat$() {
    return this._storeSelector.getSelectedChat$();
  }
  get selectedChatTags$() {
    return this._storeSelector.selectedChatTags$;
  }
  getSelectedChatMemberTag$(tags: string[]) {
    return this._storeSelector.getFilterTagsByIds$(tags);
  }
}
