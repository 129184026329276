/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upld-img-with-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i3 from "ngx-image-cropper";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i6 from "../../shared/components/modal-container/modal-container.component";
import * as i7 from "@angular/common";
import * as i8 from "./upld-img-with-title.component";
import * as i9 from "../../core/file.service";
import * as i10 from "../../store/ui/ui.dispatchers";
var styles_UpldImgWithTitleComponent = [i0.styles];
var RenderType_UpldImgWithTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpldImgWithTitleComponent, data: {} });
export { RenderType_UpldImgWithTitleComponent as RenderType_UpldImgWithTitleComponent };
function View_UpldImgWithTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "uploader-image-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_UpldImgWithTitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "uploader-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_UpldImgWithTitleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [["cropperMaxHeight", "1024"], ["cropperMaxWidth", "1024"], ["outputType", "both"]], [[4, "text-align", null]], [[null, "mousedown"], [null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("mousedown" === en)) {
        var pd_5 = (_co.onDragEvents($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageCropped" === en)) {
        var pd_6 = (_co.imageCropped($event) !== false);
        ad = (pd_6 && ad);
    } if (("imageLoaded" === en)) {
        var pd_7 = (_co.imageLoaded() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageFileChanged: [0, "imageFileChanged"], imageChangedEvent: [1, "imageChangedEvent"], format: [2, "format"], outputType: [3, "outputType"], maintainAspectRatio: [4, "maintainAspectRatio"], aspectRatio: [5, "aspectRatio"], resizeToWidth: [6, "resizeToWidth"], roundCropper: [7, "roundCropper"], onlyScaleDown: [8, "onlyScaleDown"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.selectedFile == null) ? null : _co.selectedFile.localFile); var currVal_2 = _co.imageChangedEvent; var currVal_3 = _co.cropFormat; var currVal_4 = "both"; var currVal_5 = true; var currVal_6 = (1 / 1); var currVal_7 = _co.cropperMinWidth; var currVal_8 = false; var currVal_9 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
function View_UpldImgWithTitleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "image-cropper", [["cropperMaxHeight", "0"], ["cropperMaxWidth", "0"], ["outputType", "both"]], [[4, "text-align", null]], [[null, "mousedown"], [null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("mousedown" === en)) {
        var pd_5 = (_co.onDragEvents($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageCropped" === en)) {
        var pd_6 = (_co.imageCropped($event) !== false);
        ad = (pd_6 && ad);
    } if (("imageLoaded" === en)) {
        var pd_7 = (_co.imageLoaded() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(1, 573440, null, 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageFileChanged: [0, "imageFileChanged"], imageChangedEvent: [1, "imageChangedEvent"], format: [2, "format"], outputType: [3, "outputType"], maintainAspectRatio: [4, "maintainAspectRatio"], aspectRatio: [5, "aspectRatio"], resizeToWidth: [6, "resizeToWidth"], roundCropper: [7, "roundCropper"], onlyScaleDown: [8, "onlyScaleDown"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.selectedFile == null) ? null : _co.selectedFile.localFile); var currVal_2 = _co.imageChangedEvent; var currVal_3 = _co.cropFormat; var currVal_4 = "both"; var currVal_5 = true; var currVal_6 = (1 / 1); var currVal_7 = _co.cropperMinWidth; var currVal_8 = false; var currVal_9 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).alignImage; _ck(_v, 0, 0, currVal_0); }); }
function View_UpldImgWithTitleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.closePreview() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ModalContainerComponent_0, i5.RenderType_ModalContainerComponent)), i1.ɵdid(1, 49152, null, 0, i6.ModalContainerComponent, [i1.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 0, 15, "div", [["class", "image-crop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Crop Image"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "close"], ["src", "./assets/img/close.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "crop"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpldImgWithTitleComponent_4)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpldImgWithTitleComponent_5)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "upload-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "label", [["class", "icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilesSelected(_co.imageFile) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["alt", "gif"], ["src", "./assets/img/image-upload-card.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload Image "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLogo; _ck(_v, 9, 0, currVal_0); var currVal_1 = !_co.isLogo; _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.croppedImage; _ck(_v, 13, 0, currVal_2); }); }
export function View_UpldImgWithTitleComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { imageInputFile: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "uploader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "uploader-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "uploader-image-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "label", [["class", "uploader-image-logo"]], [[8, "htmlFor", 0]], null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgStyleImpl, i7.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i7.NgStyle, [i7.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["imageInputFile", 1]], null, 0, "input", [["accept", "image/*"], ["type", "file"]], [[8, "id", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.vaildateImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "label", [["class", "upload-image"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "image-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-photograph"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpldImgWithTitleComponent_1)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpldImgWithTitleComponent_2)), i1.ɵdid(15, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpldImgWithTitleComponent_3)), i1.ɵdid(17, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.uploadedImage; _ck(_v, 6, 0, currVal_1); var currVal_5 = _co.uploading; _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.imageTitle; _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.showCrop; _ck(_v, 17, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputId || "image-input"); _ck(_v, 4, 0, currVal_0); var currVal_2 = (_co.inputId || "image-input"); var currVal_3 = _co.disabled; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = (_co.inputId || "image-input"); _ck(_v, 8, 0, currVal_4); }); }
export function View_UpldImgWithTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upld-img-with-title", [], null, null, null, View_UpldImgWithTitleComponent_0, RenderType_UpldImgWithTitleComponent)), i1.ɵdid(1, 573440, null, 0, i8.UpldImgWithTitleComponent, [i1.ChangeDetectorRef, i9.FileService, i10.UIDispatchers], null, null)], null, null); }
var UpldImgWithTitleComponentNgFactory = i1.ɵccf("app-upld-img-with-title", i8.UpldImgWithTitleComponent, View_UpldImgWithTitleComponent_Host_0, { isLogo: "isLogo", imageUrl: "imageUrl", mimeType: "mimeType", uploading: "uploading", cropFormat: "cropFormat", inputId: "inputId", imageTitle: "imageTitle", systemConfig: "systemConfig", disabled: "disabled", iconBg: "iconBg" }, { closeNotify: "closeNotify", composeMediaNotify: "composeMediaNotify", imageCroppedUrlNotify: "imageCroppedUrlNotify" }, []);
export { UpldImgWithTitleComponentNgFactory as UpldImgWithTitleComponentNgFactory };
