import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-popup-setting-frame',
  templateUrl: './popup-setting-frame.component.html',
  styleUrls: ['./popup-setting-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupSettingFrameComponent implements OnInit {
  @Input() title: string;
  @Input() desc: string;

  @Input() hideFooter = false;
  @Input() hideclose = false;
  @Input() buttonTitle: string;
  @Output() closeNotifier = new EventEmitter<boolean>();
  @Output() clickNotifier = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  closePopup() {
    this.closeNotifier.emit();
  }

  onSave() {
    this.clickNotifier.emit();
    // this.closeNotifier.emit();
  }
}
