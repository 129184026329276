import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ProfileDispatchers } from 'src/app/store/profile/profile.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';

import { IProfile } from 'src/models/IProfile';
import { IChatMember } from 'src/models/IChatMember';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ADMIN_PRIVILEGES, BotScreens } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class BotsService {
  public selectedBot: IProfile = null;

  private _currentBotScreen = new BehaviorSubject<string>(BotScreens.BOT_LIST);
  public currentBotScreen$ = this._currentBotScreen.asObservable();

  constructor(
    private _selectors: RootStoreSelectors,
    private _profileDispatchers: ProfileDispatchers,
    private _chatMemberDispatchers: ChatMemberDispatchers
  ) {}

  /** -------------------------Lists ------------------------ */
  get botList$(): Observable<IProfile[]> {
    return this._selectors.getMyBots$();
  }
  get botTemplates$(): Observable<IProfile[]> {
    return this._selectors.getBotTemplates$();
  }
  getPrivileges$(userId: string): Observable<IChatMember> {
    return this._selectors.getMemberPrivileges$(userId);
  }

  /** ------------ Selector -------------------------------- */
  createItemEvent() {
    this.setCurrentScreen(BotScreens.BOT_CREATION);
    this.setSelectedBot(null);
  }
  readItemEvent(bot: IProfile) {
    this.setCurrentScreen(BotScreens.BOT_VIEW);
    this.setSelectedBot(bot);
  }
  updateItemEvent(bot: IProfile) {
    this.setCurrentScreen(BotScreens.BOT_EDIT);
    this.setSelectedBot(bot);
    this.getChatMember(bot);
  }
  closeItemCardEvent() {
    this.setCurrentScreen(BotScreens.BOT_LIST);
    this.setSelectedBot(null);
  }
  setSelectedBot(bot: IProfile) {
    this.selectedBot = bot;
  }
  setCurrentScreen(screen: string) {
    this._currentBotScreen.next(screen);
  }

  /** -------------IProfile operations--------------------- */
  createBot(name: string) {
    this._profileDispatchers.createBot(name);
  }
  updateBot(botDetails: IProfile) {
    this._profileDispatchers.updateBot(botDetails);
  }
  deleteBot(botId: string) {
    // botId is the IProfile.user_id
    this._profileDispatchers.deleteBot(botId);
  }
  publishBot(botId: string, username?: string) {
    this._profileDispatchers.publishBot(botId, username);
  }
  cloneBot(templateID: string) {
    this._profileDispatchers.cloneBotFromTemplate(templateID);
  }
  setAdminPrivileges(userId: string, privileges: ADMIN_PRIVILEGES[]) {
    this._profileDispatchers.updateAdminPrivileges(userId, privileges);
  }
  uploadBotImage(userId: string, file: ISelectedFiles) {
    this._profileDispatchers.uploadBotImage(userId, file);
  }
  /*************************************************************************/

  updateStore(bot) {
    console.log('here i am ');
    this._profileDispatchers.updateStore(bot);
  }

  getBotToken(botId: string) {
    this._profileDispatchers.requestBotToken(botId);
  }
  getChatMember(bot: IProfile) {
    this._chatMemberDispatchers.getChatMember(bot);
  }
}
