import { Actions } from './myPage.actions';
import * as actions from './myPage.actions';
import {
  MyPage,
  MyPageContainer,
  MyPageComponent
} from 'src/models/MyPageConfig';
import { ascendingly } from '../channelAppConfig/appConfig.middleware';
import { uuidv4 } from 'src/models/constants';

const INITIAL_STATE = [];

export function myPageReducer(
  state: MyPage[] = INITIAL_STATE,
  action: Actions
): MyPage[] {
  switch (action.type) {
    case actions.MyPageActionTypes.RECEIVE_MY_PAGES: {
      return action.rcvdPages;
    }
    case actions.MyPageActionTypes.MY_PAGE_DELETED: {
      return state.filter(page => page.id !== action.pageID);
    }
    // should add the page if not existing or update it by ID
    case actions.MyPageActionTypes.RECEIVE_MY_PAGE: {
      if (!state.find(myPage => myPage.id == action.rcvdPage.id)) {
        return [action.rcvdPage, ...state];
      } else {
        return state.map(page => {
          if (page.id == action.rcvdPage.id) {
            let updatedPage: MyPage = {};
            updatedPage = { ...page, ...action.rcvdPage };
            return updatedPage;
          }
          return page;
        });
      }
    }
    // should insert by timestamp reference
    case actions.MyPageActionTypes.CREATE_MY_PAGE: {
      return [
        {
          reference: action.reference,
          content: action.myPageForm,
          name: action.name
        },
        ...state
      ];
    }
    // should update the page by reference (need to know if I should set the url here as well .. how to get the url)
    case actions.MyPageActionTypes.MY_PAGE_CREATED: {
      return state.map(page => {
        if (page.reference == action.reference) {
          let updatedPage: MyPage = {};
          updatedPage = { ...page, ...action.createdPage };
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.UPDATE_MY_PAGE: {
      return state.map(page => {
        if (page.id == action.id) {
          const updatedPage: MyPage = { ...page, ...action.myPageDetails };
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.PAGE_TEMPLATE_CREATED: {
      return state.map(page => {
        if (page.id == action.pageId) {
          const updatedPage: MyPage = { ...page };
          updatedPage.content = {
            ...page.content,
            childTemplateId: action.childTemplateId
          };
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.UPDATE_MY_PAGE_FORM: {
      return state.map(page => {
        if (page.id == action.formID) {
          const updatedPage: MyPage = { ...page };
          updatedPage.content = {
            ...page.content,
            ...{
              title: action.title
                ? action.title
                : page.content
                ? page.content.title
                : null,
              image: action.image
                ? action.image
                : page.content
                ? page.content.image
                : null,
              template: action.template
                ? action.template
                : page.content
                ? page.content.template
                : null,
              background: action.background
                ? action.background
                : page.content
                ? page.content.background
                : null
            }
          };
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.UPDATE_MY_PAGE_CONTAINER: {
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          // console.log('here 1');
          const updatedPage: MyPage = { ...page };
          updatedPage.content.container = page.content.container.map(
            container => {
              if (container.id == action.containerID) {
                let updatedCont: MyPageContainer = {};
                updatedCont = { ...container, ...action.containerUpdatedProps };
                updatedPage.needUpdate = true;
                // console.log('here 2 => ', updatedCont);
                return updatedCont;
              }
              // console.log('here 3 => ', container);
              return container;
            }
          );

          updatedPage.needUpdate = true;
          // console.log('here 4 => ', updatedPage);
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.REORDER_MY_PAGE_CONTAINERS: {
      const targetContainer = state
        .find(page => page.id == action.formID)
        .content.container.find(
          container => container.id == action.containerID
        );
      const oldPosition = targetContainer ? targetContainer.order : null;
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };
          const orderedContainers = page.content.container
            .map(container => {
              if (container.id == action.containerID) {
                let updatedContainer: MyPageContainer = {};
                updatedContainer = { ...container, order: action.newPosition };
                return updatedContainer;
              } else if (oldPosition !== null && container.order >= 0) {
                if (
                  action.newPosition < oldPosition &&
                  container.order < oldPosition
                ) {
                  // MOVING_UP
                  let updatedContainer: MyPageContainer = {};
                  updatedContainer = {
                    ...container,
                    order: container.order + 1
                  };
                  return updatedContainer;
                } else if (
                  action.newPosition > oldPosition &&
                  container.order <= action.newPosition
                ) {
                  // MOVING_DOWN
                  let updatedContainer: MyPageContainer = {};
                  updatedContainer = {
                    ...container,
                    order: container.order !== 0 ? container.order - 1 : 0
                  };
                  return updatedContainer;
                }
              }
              return container;
            })
            .sort(ascendingly);
          updatedPage.content.container = orderedContainers;
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.DELETE_MY_PAGE_CONTAINER: {
      const orderOfDeletedContainer = action.container.order;
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };
          let remainingContainers = page.content.container.filter(
            container => container.id != action.container.id
          );
          if (remainingContainers) {
            remainingContainers = remainingContainers
              .map(container => {
                if (
                  orderOfDeletedContainer !== null &&
                  orderOfDeletedContainer !== undefined &&
                  container.order >= 0
                ) {
                  if (container.order > orderOfDeletedContainer) {
                    let updatedContainer: MyPageContainer = {};
                    updatedContainer = {
                      ...container,
                      order: container.order - 1
                    };
                    return updatedContainer;
                  }
                }
                return container;
              })
              .sort(ascendingly);
          }
          updatedPage.content.container = remainingContainers;
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.ADD_MY_PAGE_CONTAINER: {
      return state.map(page => {
        if (page.id == action.formID) {
          const updatedPage: MyPage = { ...page };
          // May be I'll need to take the other properties with me in case of new container
          updatedPage.content =
            page.content && page.content.container
              ? page.content
              : { container: [] };

          const newContainer: MyPageContainer = action.container;
          newContainer.order = updatedPage.content.container
            ? updatedPage.content.container.length
            : 0;
          newContainer.id = uuidv4();
          if (updatedPage.content.container) {
            updatedPage.content.container = [
              ...updatedPage.content.container,
              newContainer
            ];
          } else {
            updatedPage.content.container = [newContainer];
          }
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.UPDATE_MY_PAGE_COMPONENT: {
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };
          updatedPage.needPageID = action.needPageID;
          updatedPage.content.container = page.content.container.map(
            container => {
              if (container.id == action.containerID) {
                const updatedContainer: MyPageContainer = { ...container };
                updatedContainer.component = container.component.map(
                  component => {
                    if (component.id == action.componentID) {
                      let updatedComp: MyPageComponent = {};
                      updatedComp = {
                        ...component,
                        ...action.componentUpdatedProps
                      };
                      return updatedComp;
                    }
                    return component;
                  }
                );
                return updatedContainer;
              }
              return container;
            }
          );
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.REORDER_MY_PAGE_COMPONENTS: {
      const targetComponent = state
        .find(page => page.id == action.formID)
        .content.container.find(container => container.id == action.containerID)
        .component.find(comp => comp.id == action.componentID);
      const oldPosition = targetComponent ? targetComponent.order : null;
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };

          updatedPage.content.container = page.content.container.map(
            container => {
              if (container.id == action.containerID) {
                const updatedContainer: MyPageContainer = { ...container };
                updatedContainer.component = container.component
                  .map(component => {
                    if (component.id == action.componentID) {
                      let updatedComp: MyPageComponent = {};
                      updatedComp = {
                        ...component,
                        order: action.newPosition
                      };
                      return updatedComp;
                    } else if (oldPosition !== null && component.order >= 0) {
                      if (
                        action.newPosition < oldPosition &&
                        component.order < oldPosition
                      ) {
                        // MOVING_UP
                        let updatedComp: MyPageComponent = {};
                        updatedComp = {
                          ...component,
                          order: component.order + 1
                        };
                        return updatedComp;
                      } else if (
                        action.newPosition > oldPosition &&
                        component.order <= action.newPosition
                      ) {
                        // MOVING_DOWN
                        let updatedComp: MyPageComponent = {};
                        updatedComp = {
                          ...component,
                          order: component.order !== 0 ? component.order - 1 : 0
                        };
                        return updatedComp;
                      }
                    }
                    return component;
                  })
                  .sort(ascendingly);
                return updatedContainer;
              }
              return container;
            }
          );
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.DELETE_MY_PAGE_COMPONENT: {
      const orderOfDeletedComponent = action.component.order;
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };
          updatedPage.content.container = page.content.container.map(
            container => {
              if (container.id == action.containerID) {
                const updatedContainer: MyPageContainer = { ...container };
                const remainingComponents = container.component.filter(
                  comp => comp.id != action.component.id
                );
                if (remainingComponents) {
                  updatedContainer.component = remainingComponents.map(
                    component => {
                      if (
                        orderOfDeletedComponent !== null &&
                        orderOfDeletedComponent !== undefined &&
                        component.order >= 0
                      ) {
                        if (component.order > orderOfDeletedComponent) {
                          let updatedComponent: MyPageComponent = {};
                          updatedComponent = {
                            ...component,
                            order: component.order - 1
                          };
                          return updatedComponent;
                        }
                      }
                      return component;
                    }
                  );
                } else {
                  updatedContainer.component = [];
                }
                return updatedContainer;
              }
              return container;
            }
          );
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.ADD_MY_PAGE_COMPONENT: {
      return state.map(page => {
        if (
          page.id == action.formID &&
          page.content &&
          page.content.container
        ) {
          const updatedPage: MyPage = { ...page };
          updatedPage.content.container = page.content.container.map(
            container => {
              if (container.id == action.containerID) {
                const updatedContainer: MyPageContainer = container;
                const newComp: MyPageContainer = action.component;
                newComp.order = container.component
                  ? container.component.length
                  : 0;
                newComp.id = uuidv4();
                if (container.component) {
                  updatedContainer.component = [
                    ...container.component,
                    newComp
                  ];
                } else {
                  updatedContainer.component = [newComp];
                }
              }
              return container;
            }
          );
          updatedPage.needUpdate = true;
          return updatedPage;
        }
        return page;
      });
    }
    case actions.MyPageActionTypes.DESELECT_CHANNEL:
      return INITIAL_STATE;
    case actions.MyPageActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
