/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selectable-cell-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-barcode/ngx-barcode.ngfactory";
import * as i3 from "ngx-barcode";
import * as i4 from "@angular/common";
import * as i5 from "./selectable-cell-frame.component";
var styles_SelectableCellFrameComponent = [i0.styles];
var RenderType_SelectableCellFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectableCellFrameComponent, data: {} });
export { RenderType_SelectableCellFrameComponent as RenderType_SelectableCellFrameComponent };
function View_SelectableCellFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "selectable-cell-img"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.coverColor; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", _co.symbolImage, ""); _ck(_v, 3, 0, currVal_1); }); }
function View_SelectableCellFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "selectable-cell-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.coverPhoto; _ck(_v, 2, 0, currVal_0); }); }
function View_SelectableCellFrameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "selectable-cell-details"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.desc; _ck(_v, 0, 0, currVal_0); }); }
function View_SelectableCellFrameComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "selectable-cell-upc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ngx-barcode", [], null, null, null, i2.View_NgxBarcodeComponent_0, i2.RenderType_NgxBarcodeComponent)), i1.ɵdid(2, 573440, null, 0, i3.NgxBarcodeComponent, [i1.Renderer2], { elementType: [0, "elementType"], width: [1, "width"], height: [2, "height"], displayValue: [3, "displayValue"], fontSize: [4, "fontSize"], background: [5, "background"], margin: [6, "margin"], marginTop: [7, "marginTop"], marginBottom: [8, "marginBottom"], marginLeft: [9, "marginLeft"], marginRight: [10, "marginRight"], value: [11, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "img"; var currVal_1 = 1; var currVal_2 = 20; var currVal_3 = true; var currVal_4 = 12; var currVal_5 = "transparent"; var currVal_6 = 0; var currVal_7 = 0; var currVal_8 = 0; var currVal_9 = 0; var currVal_10 = 0; var currVal_11 = _co.upcCode; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_SelectableCellFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "selectable-cell"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectableCellFrameComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectableCellFrameComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "selectable-cell-symbol"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "selectable-cell-title truncate-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectableCellFrameComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectableCellFrameComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "selectable-cell-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.checkChangedEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "label", [], [[8, "htmlFor", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.coverPhoto; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.coverPhoto; _ck(_v, 4, 0, currVal_1); var currVal_5 = _co.desc; _ck(_v, 10, 0, currVal_5); var currVal_6 = _co.upcCode; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.symbolColor; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "./assets/svg/app-sections.svg#", _co.symbolImage, ""); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.title; _ck(_v, 8, 0, currVal_4); var currVal_7 = _co.selectorId; var currVal_8 = _co.isSelected; _ck(_v, 14, 0, currVal_7, currVal_8); var currVal_9 = _co.selectorId; _ck(_v, 15, 0, currVal_9); }); }
export function View_SelectableCellFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, null, null, View_SelectableCellFrameComponent_0, RenderType_SelectableCellFrameComponent)), i1.ɵdid(1, 114688, null, 0, i5.SelectableCellFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectableCellFrameComponentNgFactory = i1.ɵccf("app-selectable-cell-frame", i5.SelectableCellFrameComponent, View_SelectableCellFrameComponent_Host_0, { symbolColor: "symbolColor", symbolImage: "symbolImage", coverColor: "coverColor", coverPhoto: "coverPhoto", isSelected: "isSelected", selectorId: "selectorId", upcCode: "upcCode", title: "title", desc: "desc" }, { checkClick: "checkClick", checkChanged: "checkChanged" }, []);
export { SelectableCellFrameComponentNgFactory as SelectableCellFrameComponentNgFactory };
