import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as AuthMethods from 'src/models/IAuth';

import { AuthService } from 'src/app/core/auth.service';
import { HtttpMethodErrors } from 'src/models/constants';
import { AbstractNotifiersService } from 'src/app/abstract-notifiers/abstract-notifiers.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyAccountComponent implements OnInit {
  form: FormGroup;
  @Input() email: string;
  @Input() password: string;
  @Input() appName: string;
  @Input() countryIso: string;
  @Input() templateID: string;
  @Input() name: string;
  newTag = '';
  tacValue = '';
  waiting = false;
  constructor(
    public authService: AuthService,
    private _ref: ChangeDetectorRef,
    private _abstractNotifiersService: AbstractNotifiersService
  ) {}

  ngOnInit() {
    document.getElementById('1').focus();
    this.form = new FormGroup({
      appName: new FormControl(this.appName),
      password: new FormControl(this.password),
      email: new FormControl(this.email),
      countryIso: new FormControl(this.countryIso),
      templateID: new FormControl(this.templateID),
      fst: new FormControl('', [Validators.required]),
      sec: new FormControl('', [Validators.required]),
      third: new FormControl('', [Validators.required]),
      fourth: new FormControl('', [Validators.required]),
      tac: new FormControl(''),
      name: new FormControl(this.name)
    });
  }
  get fst() {
    return this.form.get('fst');
  }
  onInputEntry(event, id) {
    const input = event.target;
    document.getElementById(id).focus();
  }

  setTacValue(value) {
    this.tacValue = value;
  }
  onSubmit() {
    this.waiting = true;
    this.authService
      .createAccount(this.form.value, this.tacValue)
      .subscribe(response => {
        const res = <AuthMethods.HttpResult>response;
        if (res.result === 0) {
          this.authService.createAccountSuccess(this.form.value.password);
          this.waiting = false;
        } else if (
          res.error === HtttpMethodErrors.WRONG_TAC ||
          res.error === HtttpMethodErrors.WRONG_TAC_2
        ) {
          this.form.setErrors({ wrongTac: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (
          res.error === HtttpMethodErrors.PASSWORD_TOO_SHORT ||
          res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_LOWERCASE ||
          res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_UPPERCASE ||
          res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_DIGIT ||
          res.error === HtttpMethodErrors.PASSWORD_MUST_CONTAIN_SPECIAL ||
          res.error === HtttpMethodErrors.PASSWORD_MATCHES_PATTERN ||
          res.error === HtttpMethodErrors.PASSWORD_MATCHES_DICTIONARY ||
          res.error === HtttpMethodErrors.PASSWORD_TOO_LONG
        ) {
          this.form.setErrors({ patternIsNotMarch: true });
          this.waiting = false;
          this._ref.detectChanges();
        }
      });
  }
  reSend() {
    this.authService.getEmailTac(this.form.value).subscribe(response => {
      const res = <AuthMethods.HttpResult>response;
      if (res.result === 0) {
        this._abstractNotifiersService.showSnakbarNotifierMessage(
          'Verification code sent successfully',
          false
        );
        this.authService.emailTacSuccess(this.form.value);
        this._ref.detectChanges();
      }
    });
  }
  pasteUrl(e) {
    const tac = e;
    const splitted = e.split('', 4);
    this.setValue(splitted, e);
  }

  setValue(splitted, e) {
    this.form.reset();
    this.form.setValue({
      fst: splitted[0],
      sec: splitted[1],
      third: splitted[2],
      fourth: splitted[3],
      appName: this.appName,
      password: this.password,
      email: this.email,
      countryIso: this.countryIso,
      templateID: this.templateID,
      tac: e
    });
    this._ref.detectChanges();
  }
}
