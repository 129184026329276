import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ChannelMember } from 'src/models/ChannelMember';
import { IMessage } from 'src/models/IMessage';
import { UiService } from '../../core/ui.service';

@Component({
  selector: 'app-reply-cell',
  templateUrl: './reply-cell.component.html',
  styleUrls: ['./reply-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyCellComponent {
  @Input() member: ChannelMember;
  @Input() parentPost: IMessage;
  @Input() chatId: string;
  @Input() isSubChat: boolean;

  public chatType = 'Individual';

  constructor(public uiService: UiService) {}

  getReplies() {
    const parentMsgId =
      this.parentPost && this.parentPost.message_id
        ? this.parentPost.message_id
        : this.chatId;
    if (this.isSubChat) {
      this.uiService.subUserSelected(this.member.profile.user_id);
      this.uiService.requestHistory(
        parentMsgId,
        this.member.profile.user_id,
        true,
        this.chatId
      );
    } else {
      this.uiService.selectOneToOneReply(this.member.profile.user_id);
      this.uiService.requestHistory(
        parentMsgId,
        this.member.profile.user_id,
        true
      );
    }
  }
}
