import { Injectable } from '@angular/core';
import * as lf from 'localforage';
import { IOption } from 'src/models/campaign';

import {
  INFO_DB,
  CHAT_LIST_DB,
  CACHED_FILES_DB,
  PROFILE_LIST_DB,
  BASE_URL_LOCAL_STORAGE_KEY,
  DEFAULT_BASE_URL_API,
  UPLOAD_URL_API_SUFFIX,
  DOWNLOAD_URL_API_SUFFIX,
  META_URL_API_SUFFIX,
  WS_URL_API_SUFFIX,
  DOWNLOAD_API,
  UPLOAD_API,
  META_API,
  WS_API,
  CHANNEL_API,
  CHANNEL_URL_API_SUFFIX,
  TABS_LIST_DB
} from 'src/models/constants';
import { IChat } from 'src/models/IChat';
import { IProfile } from 'src/models/IProfile';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  filesDB: LocalForage = lf.createInstance({
    name: CACHED_FILES_DB,
    storeName: CACHED_FILES_DB,
    driver: lf.INDEXEDDB
  });
  infoDB: LocalForage = lf.createInstance({
    name: INFO_DB,
    storeName: INFO_DB,
    driver: lf.INDEXEDDB
  });
  chatListDB: LocalForage = lf.createInstance({
    name: CHAT_LIST_DB,
    storeName: CHAT_LIST_DB,
    driver: lf.INDEXEDDB
  });
  profileListDB: LocalForage = lf.createInstance({
    name: PROFILE_LIST_DB,
    storeName: PROFILE_LIST_DB,
    driver: lf.INDEXEDDB
  });

  constructor() {}

  /** Local Storage */
  getRecord(key: string): any {
    return localStorage.getItem(key);
  }
  setRecord(key: string, value: any): void {
    localStorage.setItem(key, value);
  }
  removeRecord(key: string) {
    localStorage.removeItem(key);
  }

  /********************************************************************/

  /** FileDB Media database */
  setMediaWithKey(key: string, value: Blob): void {
    this.filesDB.setItem(key, value);
  }
  getMediaWithKey(key: string): Promise<any> {
    return this.filesDB.getItem(key);
  }
  deleteMediaWithKey(key: string): Promise<any> {
    return this.filesDB.removeItem(key);
  }

  /********************************************************************/

  /** Info DB */
  setItemInInfoDB(key: string, value: string): void {
    this.infoDB.setItem(key, value);
  }
  getItemFromInfoDB(key: string): Promise<any> {
    return this.infoDB.getItem(key);
  }

  /********************************************************************/

  /** Cache of type IChat .. which are the groups and channels */
  setChatToCache(key: string, value: IChat): void {
    this.chatListDB
      .setItem(key, value)
      .catch(err => console.log('Could not set chat to cache: ', key));
  }
  getChatFromCache(key: string): Promise<IChat> {
    return this.chatListDB.getItem(key);
  }

  /********************************************************************/

  /** Cache of type IProfile .. which are the contacts */
  setProfileToCache(key: string, value: IProfile): void {
    this.profileListDB
      .setItem(key, value)
      .catch(err => console.log('Could not set profile to cache: ', key));
  }
  getProfileFromCache(key: string): Promise<IProfile> {
    return this.profileListDB.getItem(key);
  }

  /********************************************************************/

  // get api url
  getApiUrl(type: string): string {
    let tempUrl = this.getRecord(BASE_URL_LOCAL_STORAGE_KEY);
    if (!tempUrl) {
      tempUrl = DEFAULT_BASE_URL_API;
    }
    switch (type) {
      case UPLOAD_API:
        return `https://${tempUrl}${UPLOAD_URL_API_SUFFIX}`;
      case DOWNLOAD_API:
        return `https://${tempUrl}${DOWNLOAD_URL_API_SUFFIX}`;
      case META_API:
        return `https://${tempUrl}${META_URL_API_SUFFIX}`;
      case CHANNEL_API:
        return `https://${tempUrl}${CHANNEL_URL_API_SUFFIX}`;
      case WS_API:
        return `wss://${tempUrl}${WS_URL_API_SUFFIX}`;
      default:
        return;
    }
  }
}
