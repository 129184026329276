import { Action } from '@ngrx/store';
import { DriverTrip } from 'src/models/DrverAssigned';

export const enum DriverAssignedActionTypes {
  DRIVER_TRIPS_RECEIVED = 'DRIVER_TRIPS_RECEIVED',
  GET_DRIVER_TRIPS = 'GET_DRIVER_TRIPS',
  SET_DRIVER_TRIPS = 'SET_DRIVER_TRIPS'
}

// RESET Part
export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class GetDriverTrips implements Action {
  readonly type = DriverAssignedActionTypes.GET_DRIVER_TRIPS;
  constructor(
    public trip_id: number,
    public account_id: string,
    public start_day?: Date,
    public end_day?: Date
  ) {}
}

export class SetDriverTrips implements Action {
  readonly type = DriverAssignedActionTypes.SET_DRIVER_TRIPS;
  constructor(
    public trip_id: string,
    public account_id: string,
    public driverAssignedList: DriverTrip[]
  ) {}
}
export class DriverTipsReceived implements Action {
  readonly type = DriverAssignedActionTypes.DRIVER_TRIPS_RECEIVED;
  constructor(public payload: any) {}
}
// Reset Messages
export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type DriverAssignedActions =
  | GetDriverTrips
  | SetDriverTrips
  | DriverTipsReceived
  | DeselectChannel
  | Reset;
