import { Action } from '@ngrx/store';
import { IChat } from 'src/models/IChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { EnableServiceConfig } from 'src/models/constants';

export const enum BookingUIActionTypes {
  UI_LIST_BOOKING = 'UI_LIST_BOOKING',
  UI_CREATE_BOOKING = 'UI_CREATE_BOOKING',
  UI_VIEW_BOOKING = 'UI_VIEW_BOOKING',
  UI_EDIT_BOOKING = 'UI_EDIT_BOOKING',
  UPLOAD_BOOKING_IMAGE = 'UPLOAD_BOOKING_IMAGE',
  UI_UPLOAD_BOOKING_PHOTO = 'UI_UPLOAD_BOOKING_PHOTO',
  UI_BOOKING_CREATION_COMPLETE = 'UI_BOOKING_CREATION_COMPLETE',
  UI_CLOSE_VIEW_BOOKING = 'UI_CLOSE_VIEW_BOOKING',
  UI_CLOSE_EDIT_BOOKING = 'UI_CLOSE_EDIT_BOOKING ',
  UI_SHOW_RESERVATIONS = 'UI_SHOW_RESERVATIONS',
  UI_BOOKING_GET_NEXT_PAGE = 'UI_BOOKING_GET_NEXT_PAGE',
  UI_BOOKING_GET_PREV_PAGE = 'UI_BOOKING_GET_PREV_PAGE',
  UI_BOOKING_RECEIVED_HISTORY_CHUNK = 'UI_BOOKING_RECEIVED_HISTORY_CHUNK',
  UI_BOOKING_RESET_PAGING = 'UI_BOOKING_RESET_PAGING',
  SET_ENABLE_SERVICE = 'SET_ENABLE_SERVICE',
  SET_CHAT_UI_BOOKING = 'SET_CHAT_UI_BOOKING',
  UPDATE_CHAT_BOOKING = 'UPDATE_CHAT_BOOKING',
  RESET = 'RESET'
}

export class ListBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_LIST_BOOKING;
}

export class CreateBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_CREATE_BOOKING;
}

export class ViewBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_VIEW_BOOKING;
  constructor(public selectedBooking: IChat) {}
}
export class EditBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_EDIT_BOOKING;
  constructor(public selectedBooking: IChat) {}
}

export class UploadBookingImage implements Action {
  readonly type = BookingUIActionTypes.UPLOAD_BOOKING_IMAGE;
  constructor(public booking) {}
}

export class UploadBooking implements Action {
  readonly type = BookingUIActionTypes.UPDATE_CHAT_BOOKING;
  constructor(public booking) {}
}

export class BookingPhotoUploadUI implements Action {
  readonly type = BookingUIActionTypes.UI_UPLOAD_BOOKING_PHOTO;
  constructor(public createdBooking: IChat) {}
}

export class BookingCreationComplete implements Action {
  readonly type = BookingUIActionTypes.UI_BOOKING_CREATION_COMPLETE;
}
export class ShowReservationsUI implements Action {
  readonly type = BookingUIActionTypes.UI_SHOW_RESERVATIONS;
  constructor(public selectedBooking: IChat) {}
}

export class CloseViewBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_CLOSE_VIEW_BOOKING;
}

export class CloseEditBookingUI implements Action {
  readonly type = BookingUIActionTypes.UI_CLOSE_EDIT_BOOKING;
}
// Paging Actions

export class GetNextBookingPage implements Action {
  readonly type = BookingUIActionTypes.UI_BOOKING_GET_NEXT_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

export class GetPrevBookingPage implements Action {
  readonly type = BookingUIActionTypes.UI_BOOKING_GET_PREV_PAGE;
  constructor() {}
  // constructor(public searchName: string) {}
}

// we can detect that by listening to the return of getsubchatlist of type event
export class ReceivedBookingHistoryChunk implements Action {
  readonly type = BookingUIActionTypes.UI_BOOKING_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChat[]
  ) {}
}

export class ResetBookingPaging implements Action {
  readonly type = BookingUIActionTypes.UI_BOOKING_RESET_PAGING;
  constructor() {}
}

export class SetEnableService implements Action {
  readonly type = BookingUIActionTypes.SET_ENABLE_SERVICE;
  constructor(public config: EnableServiceConfig) {}
}
export class SetChat implements Action {
  readonly type = BookingUIActionTypes.SET_CHAT_UI_BOOKING;
  constructor(public chat: IChat) {}
}

export class Reset implements Action {
  readonly type = BookingUIActionTypes.RESET;
}

export type Actions =
  | ListBookingUI
  | CreateBookingUI
  | ViewBookingUI
  | EditBookingUI
  | UploadBookingImage
  | BookingPhotoUploadUI
  | BookingCreationComplete
  | ShowReservationsUI
  | CloseViewBookingUI
  | CloseEditBookingUI
  | GetNextBookingPage
  | GetPrevBookingPage
  | ReceivedBookingHistoryChunk
  | ResetBookingPaging
  | SetEnableService
  | Reset;
