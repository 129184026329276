import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as ProfileActions from './profile.actions';
import { ProfileMiddleware } from './profile.middleware';

import { AppState } from 'src/models/AppState';
import { IProfile } from 'src/models/IProfile';
import { ProfileDetails } from 'src/models/ProfileDetails';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { ADMIN_PRIVILEGES } from 'src/models/constants';
import { UserBalance, UserProductBalance } from 'src/models/UserBalance';

@Injectable({
  providedIn: 'root'
})
export class ProfileDispatchers {
  constructor(private _store: Store<AppState>) {}

  contactsReceived(
    payload: IProfile[],
    myBots?: boolean,
    templateBots?: boolean
  ): void {
    const profiles = ProfileMiddleware.initProfileList(
      payload,
      myBots,
      templateBots
    );
    this._store.dispatch(new ProfileActions.ContactsReceived(profiles));
  }

  contactDetailsReceived(payload: ProfileDetails): void {
    const profile = ProfileMiddleware.initProfile(payload);
    this._store.dispatch(new ProfileActions.ContactDetailsReceived(profile));
  }

  contactDetailsFromCache(payload: IProfile): void {
    this._store.dispatch(new ProfileActions.ContactDetailsFromCache(payload));
  }

  unknownProfile(userID: string, unknownContact?: boolean): void {
    this._store.dispatch(
      new ProfileActions.UnknownProfile(userID, unknownContact)
    );
  }

  thumbnailDownloaded(payload: IProfile): void {
    this._store.dispatch(new ProfileActions.ThumbnailDownloaded(payload));
  }

  imageDownloaded(payload: IProfile): void {
    this._store.dispatch(new ProfileActions.ImageDownloaded(payload));
  }

  profileInvalidated(userID: string): void {
    this._store.dispatch(new ProfileActions.ProfileInvalidated(userID));
  }

  deselectProfile(id: string, lastTypedMessage: string): void {
    this._store.dispatch(
      new ProfileActions.DeselectProfile(id, lastTypedMessage)
    );
  }

  createBot(name: string): void {
    this._store.dispatch(new ProfileActions.CreateBot(name));
  }

  botCreated(userID: string, name: string): void {
    this._store.dispatch(new ProfileActions.BotCreated(userID, name));
  }

  updateBot(botDetails: IProfile): void {
    this._store.dispatch(new ProfileActions.UpdateBot(botDetails));
  }

  uploadBotImage(botID: string, file: ISelectedFiles) {
    this._store.dispatch(new ProfileActions.UploadBotImage(botID, file));
  }

  activateBot(botID: string) {
    this._store.dispatch(new ProfileActions.ActivateBot(botID));
  }

  requestBotToken(botID: string) {
    this._store.dispatch(new ProfileActions.RequestBotToken(botID));
  }

  updateStore(bot) {
    this._store.dispatch(new ProfileActions.UpdateStore(bot));
  }

  deleteBot(botID: string) {
    this._store.dispatch(new ProfileActions.DeleteBot(botID));
  }

  publishBot(botID: string, username?: string) {
    this._store.dispatch(new ProfileActions.PublishBot(botID, username));
  }

  myBotDeleted(botID: string) {
    this._store.dispatch(new ProfileActions.MyBotDeleted(botID));
  }

  botAddedAsAdmin(userID: string): void {
    this._store.dispatch(new ProfileActions.BotAddedAsAdmin(userID));
  }

  cloneBotFromTemplate(userID: string): void {
    this._store.dispatch(new ProfileActions.CloneBotFromTemplate(userID));
  }

  updateAdminPrivileges(userID: string, privileges: ADMIN_PRIVILEGES[]): void {
    this._store.dispatch(
      new ProfileActions.UpdateAdminPrivileges(userID, privileges)
    );
  }

  receivePublishBotError(errorNumber: number) {
    this._store.dispatch(
      new ProfileActions.ReceivePublishBotError(errorNumber)
    );
  }

  requestUserBalance(userID: string) {
    this._store.dispatch(new ProfileActions.RequestUserBalance(userID));
  }

  requestUserProductBalance(userID: string) {
    this._store.dispatch(new ProfileActions.RequestUserProductBalance(userID));
  }

  userBalanceReceived(userID: string, userBalanceList: UserBalance[]) {
    this._store.dispatch(
      new ProfileActions.UserBalanceReceived(userID, userBalanceList)
    );
  }

  userProductBalanceReceived(
    userID: string,
    userProductBalanceList: UserProductBalance[]
  ) {
    this._store.dispatch(
      new ProfileActions.UserProductBalanceReceived(
        userID,
        userProductBalanceList
      )
    );
  }
}
