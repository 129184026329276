import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  AfterViewChecked,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { IMessage, ChatMessages } from '../../../models/IMessage';
import {
  MAX_NUMBER_OF_PAGES_PER_DOM,
  CHAT_PAGE_SIZE
} from '../../../models/constants';
import { Observable, BehaviorSubject } from 'rxjs';

import { UiService } from '../../core/ui.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-member-replies',
  templateUrl: './member-replies.component.html',
  styleUrls: ['./member-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberRepliesComponent
  implements OnChanges, AfterViewChecked, OnDestroy, OnInit, AfterViewInit {
  @ViewChild('ScrollContainer', { static: true }) scrollContainer: ElementRef;
  @ViewChild('messagesContainer', { static: true })
  messagesContainer: ElementRef;

  @Input() selectedMessageId: string;
  @Input() selectedUserId: string;
  @Input() selectedChatId: string;
  @Input() isSubChat: boolean;
  @Input() getMessagesByParams: boolean;

  public isInEnd = false;
  public autoScroll = false;
  private pageNumber: BehaviorSubject<number> = new BehaviorSubject(1);
  private pageNumber$: Observable<number> = this.pageNumber.asObservable();
  private currentPageNumber: number;
  private paginationSubscribtion = null;
  private scrollToBottomSubscription = null;
  private messageRcvdSubscription = null;
  private historyMsgRcvdSubscription = null;
  private lastScrollHeight = 0;
  private lastChatMessagesHeight = 0;
  private totalNumberOfPages = 1;
  private debounceTimer: number;
  private lastScrollPosition = 0;
  private firstMessageElement = null;
  private lastMessageElement = null;
  private timeout;
  private historyCounter = 0;
  private sentOrRcvMsgsCounter = 0;

  constructor(
    public uiService: UiService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.paginationSubscribtion = this.pageNumber$
      .pipe(distinctUntilChanged())
      .subscribe(pageNumber => {
        this.currentPageNumber = pageNumber;
        if (this.isSubChat) {
          this.uiService.setSubGroupMessagesPageNumber(this.currentPageNumber);
        } else {
          this.uiService.setMessagesPageNumber(this.currentPageNumber);
        }
      });

    this.scrollToBottomSubscription = this.uiService.scrollToBottom$
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (res) {
          this.sentOrRcvMsgsCounter++;
          if (
            this.sentOrRcvMsgsCounter + this.historyCounter >=
            CHAT_PAGE_SIZE
          ) {
            this.sentOrRcvMsgsCounter = 0;
            if (
              this.scrollContainer.nativeElement.scrollHeight <
              document.body.scrollHeight
            ) {
              this.pageNumber.next(++this.currentPageNumber);
            }
          }
          if (this.currentPageNumber > MAX_NUMBER_OF_PAGES_PER_DOM) {
            this.pageNumber.next(MAX_NUMBER_OF_PAGES_PER_DOM);
          }
          setTimeout(() => this.scrollToBottom(), 2);
        }
      });

    this.messageRcvdSubscription = this.uiService.replyRcvd$
      .pipe(distinctUntilChanged())
      .subscribe(replyMessage => {
        if (
          replyMessage &&
          replyMessage.reply_to_message_id === this.selectedMessageId &&
          (this.selectedUserId === replyMessage.sender_id ||
            (replyMessage.send_to_user_id === this.selectedUserId &&
              !replyMessage.loggedInIsSender))
        ) {
          this.sentOrRcvMsgsCounter++;
          if (
            this.sentOrRcvMsgsCounter + this.historyCounter >=
            CHAT_PAGE_SIZE
          ) {
            this.sentOrRcvMsgsCounter = 0;
            if (
              this.scrollContainer.nativeElement.scrollHeight <
              document.body.scrollHeight
            ) {
              this.pageNumber.next(++this.currentPageNumber);
            }
          }
          if (this.isInEnd && !this.autoScroll) {
            setTimeout(() => this.scrollToBottom(), 2);
          }
        }
      });

    this.historyMsgRcvdSubscription = this.uiService.historyMsgRcvd$
      .pipe(distinctUntilChanged())
      .subscribe(res => {
        if (
          res &&
          res.reply_to_message_id &&
          (res.reply_to_message_id === this.selectedMessageId ||
            res.reply_to_message_id === res.group_id)
        ) {
          this.historyCounter++;
          // don't remove unread counter
          // should scroll to first child message before history and increase page number
          if (this.historyCounter >= CHAT_PAGE_SIZE) {
            if (!this.firstMessageElement) {
              this.firstMessageElement = this.messagesContainer.nativeElement.firstElementChild;
            }
            this.historyCounter = 0;
            this.pageNumber.next(++this.currentPageNumber);
            if (
              this.scrollContainer.nativeElement.scrollHeight <
              document.body.scrollHeight
            ) {
              this.requestHistory(res.reply_to_message_id);
            }
          }

          if (this.firstMessageElement && !this.isInEnd) {
            setTimeout(
              () =>
                (<HTMLElement>this.firstMessageElement).scrollIntoView({
                  block: 'center'
                }),
              2
            );
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['selectedMessageId'];
    const selectedUserChange = changes['selectedUserId'];
    if (
      (chng &&
        ((chng.currentValue && !chng.previousValue) ||
          chng.currentValue !== chng.previousValue)) ||
      (selectedUserChange &&
        ((selectedUserChange.currentValue &&
          !selectedUserChange.previousValue) ||
          selectedUserChange.currentValue !== selectedUserChange.previousValue))
    ) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.increasePageNumberIfNeeded();
      this.isInEnd = true;
      this.autoScroll = true;
      this.lastScrollHeight = 0;
      this.lastChatMessagesHeight = 0;
      this.lastScrollPosition = 0;
      this.firstMessageElement = null;
      this.lastMessageElement = null;
      this.historyCounter = 0;
      this.sentOrRcvMsgsCounter = 0;
      this.pageNumber.next(1);
      this.changeDetector.markForCheck();
    }
  }

  ngAfterViewChecked() {
    if (this.autoScroll) {
      const unreadCellEl = document.querySelector('app-bubbel-cell.unread');
      if (unreadCellEl) {
        this.isInEnd = false;
        this.changeDetector.markForCheck();
        this.scrollToFirstUnReadMessage();
      } else {
        this.scrollToBottom();
      }
      this.autoScroll = false;
    } else if (
      this.isInEnd &&
      (this.scrollContainer.nativeElement.clientHeight !==
        this.lastChatMessagesHeight ||
        this.scrollContainer.nativeElement.scrollHeight !==
          this.lastScrollHeight)
    ) {
      this.scrollToBottom();
    }
    this.lastChatMessagesHeight = this.scrollContainer.nativeElement.clientHeight;
    this.updateLastScrollHeight(
      this.scrollContainer.nativeElement.scrollHeight
    );
  }

  ngAfterViewInit(): void {
    this.increasePageNumberIfNeeded();
  }

  increasePageNumberIfNeeded() {
    this.timeout = setTimeout(() => {
      if (
        this.scrollContainer.nativeElement.scrollHeight <
        document.body.scrollHeight
      ) {
        if (this.currentPageNumber < this.totalNumberOfPages) {
          this.pageNumber.next(++this.currentPageNumber);
          this.increasePageNumberIfNeeded();
        }
      }
    }, 10);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    if (this.debounceTimer) {
      window.clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = window.setTimeout(() => {
      this.scrollEventHandler();
    }, 66);
  }

  scrollEventHandler() {
    const newScrollPosition = this.scrollContainer.nativeElement.scrollTop;

    if (newScrollPosition < this.lastScrollPosition) {
      // upward
      this.onScrollEvent();
    } // else{} downward
    this.lastScrollPosition = newScrollPosition;
    if (this.scrollContainer.nativeElement.scrollTop === 0) {
      this.onYStart();
    } else if (
      Math.abs(
        this.scrollContainer.nativeElement.scrollTop +
          this.scrollContainer.nativeElement.clientHeight -
          this.scrollContainer.nativeElement.scrollHeight
      ) < 1
    ) {
      this.onYEnd();
    }
  }

  ngOnDestroy() {
    if (this.paginationSubscribtion) {
      this.paginationSubscribtion.unsubscribe();
    }
    if (this.scrollToBottomSubscription) {
      this.scrollToBottomSubscription.unsubscribe();
    }
    if (this.messageRcvdSubscription) {
      this.messageRcvdSubscription.unsubscribe();
    }
    if (this.historyMsgRcvdSubscription) {
      this.historyMsgRcvdSubscription.unsubscribe();
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onScrollEvent() {
    this.autoScroll = false;
    this.isInEnd = false;
    this.changeDetector.markForCheck();
  }

  onYEnd() {
    if (this.currentPageNumber <= MAX_NUMBER_OF_PAGES_PER_DOM) {
      this.isInEnd = true;
      this.changeDetector.markForCheck();
    } else {
      this.pageNumber.next(--this.currentPageNumber);
      this.lastMessageElement = this.messagesContainer.nativeElement.lastElementChild;
      if (this.lastMessageElement) {
        setTimeout(
          () =>
            (<HTMLElement>this.lastMessageElement).scrollIntoView({
              block: 'center'
            }),
          5
        );
      }
    }
  }

  onYStart() {
    this.firstMessageElement = this.messagesContainer.nativeElement.firstElementChild;
    if (this.currentPageNumber < this.totalNumberOfPages) {
      this.pageNumber.next(++this.currentPageNumber);
      if (this.firstMessageElement) {
        setTimeout(
          () =>
            (<HTMLElement>this.firstMessageElement).scrollIntoView({
              block: 'center'
            }),
          5
        );
      }
    } else {
      // should request history from mobile here
      const msgId = this.selectedMessageId
        ? this.selectedMessageId
        : this.selectedChatId;
      this.requestHistory(msgId);
    }
  }

  onLastMessage(totalNumberOfMessages: number) {
    if (totalNumberOfMessages !== this.totalNumberOfPages) {
      this.totalNumberOfPages = totalNumberOfMessages;
      this.changeDetector.markForCheck();
    }
  }

  scrollToFirstUnReadMessage() {
    const unreadCellEl = document.querySelector('app-bubbel-cell.unread');
    if (unreadCellEl) {
      if (this.currentPageNumber > MAX_NUMBER_OF_PAGES_PER_DOM) {
        this.pageNumber.next(MAX_NUMBER_OF_PAGES_PER_DOM);
      }

      unreadCellEl.scrollIntoView({ block: 'center' });
    }
  }

  viewMessageEvent(
    $event: { target: Element; visible: boolean },
    message: IMessage
  ) {
    this.uiService.viewMessageEvent($event, message);
  }

  retryEvent(message: IMessage) {
    this.uiService.retryMediaOperation(message);
  }

  cancelEvent(message: IMessage) {
    this.uiService.cancelMediaOperation(message);
  }

  TrackByFunction(index, item: IMessage) {
    return item.sender_id ? item.reference + item.sender_id : item.reference;
  }

  get chatMessages$(): Observable<ChatMessages> {
    if (this.getMessagesByParams) {
      // console.log('getMessagesByParams =>', this.getMessagesByParams);
      return this.uiService.getChatMessagesByParams$(
        this.selectedMessageId,
        this.selectedUserId
      );
    } else {
      if (this.isSubChat) {
        // console.log('isSubChat =>', this.isSubChat);
        return this.uiService.getSubChatMessages$();
      } else {
        // console.log('isSubChat No thing');
        return this.uiService.getChatMessages$();
      }
    }
  }

  scrollToBottom() {
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
  }

  updateLastScrollHeight(height: number) {
    this.lastScrollHeight = height;
  }

  requestHistory(msgId: string) {
    if (this.isSubChat) {
      this.uiService.requestHistory(
        msgId,
        this.selectedUserId,
        null,
        this.selectedChatId
      );
    } else {
      this.uiService.requestHistory(msgId, this.selectedUserId);
    }
  }

  buttonClicked(event) {
    const buttonData = event;
    buttonData.to_user_id = this.selectedUserId;
    buttonData.chat_id = this.selectedChatId;
    this.uiService.getInlineMessageCallBack(buttonData);
  }
}
