<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">Add Group/Channel</ng-container>

  <ng-container ngProjectAs="[form-selector-create]">
    <ng-container *appShowIfHasPrivilege="privilegesName.editchatgroup">
      <app-create-item-frame (addnewNotfiy)="addNewEvent(true)">
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new group
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add new Group/Channel from the "Add new Group/Channel” field.
          Simply click the “+ Add new Group/Channel” button and Create new
          Group/Channel.
        </ng-container>
      </app-create-item-frame>
    </ng-container>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-tabs]">
    <mat-tab-group>
      <ng-container *appHideIfSubChat="true">
        <mat-tab *appShowIfHasPrivilege="privilegesName.listchatgroup">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use
                attr.xlink:href="./assets/svg/app-sections.svg#{{
                  subChannelFet?.symbolImage
                }}"
              ></use>
            </svg>
            Channels
          </ng-template>
          <ng-container
            *ngIf="
              (
                subChatsService.getPagedSubChatsByType$(chatTypes.CHANNEL)
                | async
              )?.length > 0;
              else isSubChannelsEmpty
            "
          >
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="subChatsService.getNextPage(chatTypes.CHANNEL)"
              (previousBtnClicked)="
                subChatsService.getPreviousPage(chatTypes.CHANNEL)
              "
            >
            </app-round-paging-btn>
            <app-selectable-cell-frame
              *ngFor="
                let grp of subChatsService.getPagedSubChatsByType$(
                  chatTypes.CHANNEL
                ) | async;
                trackBy: TrackByFunction
              "
              [symbolColor]="subChannelFet?.symbolColor"
              [symbolImage]="subChannelFet?.symbolImage"
              [coverColor]="subChannelFet?.coverColor"
              [coverPhoto]="grp?.localMedia"
              [title]="grp?.title"
              [desc]="grp?.description"
              [selectorId]="grp?.id"
              [isSelected]="isProductSelected(grp?.id)"
              (checkClick)="setSelectMessage(grp)"
              (checkChanged)="resetSelectMessage(grp)"
            >
            </app-selectable-cell-frame>
          </ng-container>
          <ng-template #isSubChannelsEmpty>
            <app-empty-item-frame
              [emptySymbol]="subChannelFet?.symbolImage"
              [emptyTitle]="subChannelFet?.emptyTitle"
              [emptyDesc]="subChannelFet?.emptyPoolDesc"
            >
            </app-empty-item-frame>
          </ng-template>
        </mat-tab>
        <mat-tab *appShowIfHasPrivilege="privilegesName.listchatgroup">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use
                attr.xlink:href="./assets/svg/app-sections.svg#{{
                  subGroupFet?.symbolImage
                }}"
              ></use>
            </svg>
            Groups
          </ng-template>
          <ng-container
            *ngIf="
              (subChatsService.getPagedSubChatsByType$(chatTypes.GROUP) | async)
                ?.length > 0;
              else isSubGroupsEmpty
            "
          >
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="subChatsService.getNextPage(chatTypes.GROUP)"
              (previousBtnClicked)="
                subChatsService.getPreviousPage(chatTypes.GROUP)
              "
            >
            </app-round-paging-btn>
            <app-selectable-cell-frame
              *ngFor="
                let grp of subChatsService.getPagedSubChatsByType$(
                  chatTypes.GROUP
                ) | async;
                trackBy: TrackByFunction
              "
              [symbolColor]="subGroupFet?.symbolColor"
              [symbolImage]="subGroupFet?.symbolImage"
              [coverColor]="subGroupFet?.coverColor"
              [coverPhoto]="grp?.localMedia"
              [title]="grp?.title"
              [desc]="grp?.description"
              [selectorId]="grp?.id"
              [isSelected]="isProductSelected(grp?.id)"
              (checkClick)="setSelectMessage(grp)"
              (checkChanged)="resetSelectMessage(grp)"
            >
            </app-selectable-cell-frame>
          </ng-container>
          <ng-template #isSubGroupsEmpty>
            <app-empty-item-frame
              [emptySymbol]="subGroupFet?.symbolImage"
              [emptyTitle]="subGroupFet?.emptyTitle"
              [emptyDesc]="subGroupFet?.emptyPoolDesc"
            >
            </app-empty-item-frame>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>

  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn submit (btnClicked)="insertEvent()">
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>

<app-modal-container *ngIf="showCompose" (cancelNotfiy)="addNewEvent(false)">
  <app-sub-chat-form (closeNotify)="addNewEvent(false)" [createPopup]="true">
  </app-sub-chat-form>
</app-modal-container>
