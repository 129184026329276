/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reactive-textarea-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../abstract-tooltip/hover-tooltip-icon/hover-tooltip-icon.component.ngfactory";
import * as i3 from "../../abstract-tooltip/hover-tooltip-icon/hover-tooltip-icon.component";
import * as i4 from "../validation-errors/validation-errors.component.ngfactory";
import * as i5 from "../validation-errors/validation-errors.component";
import * as i6 from "../../validators/form-validators.service";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "./reactive-textarea-input.component";
var styles_ReactiveTextareaInputComponent = [i0.styles];
var RenderType_ReactiveTextareaInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReactiveTextareaInputComponent, data: {} });
export { RenderType_ReactiveTextareaInputComponent as RenderType_ReactiveTextareaInputComponent };
function View_ReactiveTextareaInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip-icon", [], null, null, null, i2.View_HoverTooltipIconComponent_0, i2.RenderType_HoverTooltipIconComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipIconComponent, [], { title: [0, "title"], description: [1, "description"], top: [2, "top"], left: [3, "left"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filedLabel; var currVal_1 = _co.descriptionTooltip; var currVal_2 = _co.topTooltipHover; var currVal_3 = _co.leftTooltipHover; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ReactiveTextareaInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-validation-errors", [], null, null, null, i4.View_ValidationErrorsComponent_0, i4.RenderType_ValidationErrorsComponent)), i1.ɵdid(2, 49152, null, 0, i5.ValidationErrorsComponent, [i6.FormValidatorsService], { fieldErrors: [0, "fieldErrors"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = _co.filedLabel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ReactiveTextareaInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ReactiveTextareaInputComponent_3)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.validationErrors$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ReactiveTextareaInputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], [[2, "form-group", null], [2, "form-inline", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "label", [["class", "control-label"], ["style", "display: flex; align-items: center;"]], [[8, "htmlFor", 0], [2, "md", null]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReactiveTextareaInputComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "form-group lg-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "textarea", [["class", "form-control"], ["style", "font-family: sans-serif;"]], [[8, "id", 0], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(9, 540672, null, 0, i8.FormControlDirective, [[8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR], [2, i8.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i8.NgControl, null, [i8.FormControlDirective]), i1.ɵdid(11, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReactiveTextareaInputComponent_2)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.showTooltip; _ck(_v, 4, 0, currVal_5); var currVal_15 = _co.control; _ck(_v, 9, 0, currVal_15); var currVal_16 = _co.showError; _ck(_v, 14, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isInline; var currVal_1 = _co.isInline; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.fieldId; var currVal_3 = _co.isInline; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _co.filedLabel; _ck(_v, 2, 0, currVal_4); var currVal_6 = _co.fieldId; var currVal_7 = (_co.placeHolder || _co.filedLabel); var currVal_8 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 11).ngClassValid; var currVal_13 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_ReactiveTextareaInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reactive-textarea-input", [], null, null, null, View_ReactiveTextareaInputComponent_0, RenderType_ReactiveTextareaInputComponent)), i1.ɵdid(1, 311296, null, 0, i9.ReactiveTextareaInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReactiveTextareaInputComponentNgFactory = i1.ɵccf("app-reactive-textarea-input", i9.ReactiveTextareaInputComponent, View_ReactiveTextareaInputComponent_Host_0, { isInline: "isInline", showError: "showError", fieldId: "fieldId", filedLabel: "filedLabel", placeHolder: "placeHolder", showTooltip: "showTooltip", descriptionTooltip: "descriptionTooltip", topTooltipHover: "topTooltipHover", leftTooltipHover: "leftTooltipHover", control: "control" }, {}, []);
export { ReactiveTextareaInputComponentNgFactory as ReactiveTextareaInputComponentNgFactory };
