/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./compose-attachs-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-barcode/ngx-barcode.ngfactory";
import * as i3 from "ngx-barcode";
import * as i4 from "@angular/common";
import * as i5 from "./compose-attachs-preview.component";
var styles_ComposeAttachsPreviewComponent = [i0.styles];
var RenderType_ComposeAttachsPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComposeAttachsPreviewComponent, data: {} });
export { RenderType_ComposeAttachsPreviewComponent as RenderType_ComposeAttachsPreviewComponent };
function View_ComposeAttachsPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "coupon-attache"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-coupons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coupon"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ngx-barcode", [], null, null, null, i2.View_NgxBarcodeComponent_0, i2.RenderType_NgxBarcodeComponent)), i1.ɵdid(6, 573440, null, 0, i3.NgxBarcodeComponent, [i1.Renderer2], { elementType: [0, "elementType"], width: [1, "width"], height: [2, "height"], displayValue: [3, "displayValue"], fontSize: [4, "fontSize"], background: [5, "background"], margin: [6, "margin"], marginTop: [7, "marginTop"], marginBottom: [8, "marginBottom"], marginLeft: [9, "marginLeft"], marginRight: [10, "marginRight"], value: [11, "value"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAttachCoupon() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "img"; var currVal_1 = 1; var currVal_2 = 20; var currVal_3 = false; var currVal_4 = 12; var currVal_5 = "transparent"; var currVal_6 = 0; var currVal_7 = 0; var currVal_8 = 0; var currVal_9 = 0; var currVal_10 = 0; var currVal_11 = ((_co.attachCoupon == null) ? null : _co.attachCoupon.code); _ck(_v, 6, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_ComposeAttachsPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "menu-attache"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-menus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Menu / ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAttachMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.attachMenu == null) ? null : _co.attachMenu.menu_name); _ck(_v, 4, 0, currVal_0); }); }
function View_ComposeAttachsPreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "sticky"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-sticky"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sticky /"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAttachSticky() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.attachSticky == null) ? null : _co.attachSticky.title); var currVal_1 = ((_co.attachSticky == null) ? null : _co.attachSticky.description); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_ComposeAttachsPreviewComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "attaches-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposeAttachsPreviewComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposeAttachsPreviewComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ComposeAttachsPreviewComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachCoupon; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.attachMenu; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.attachSticky == null) ? null : _co.attachSticky.title); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ComposeAttachsPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-compose-attachs-preview", [], null, null, null, View_ComposeAttachsPreviewComponent_0, RenderType_ComposeAttachsPreviewComponent)), i1.ɵdid(1, 49152, null, 0, i5.ComposeAttachsPreviewComponent, [], null, null)], null, null); }
var ComposeAttachsPreviewComponentNgFactory = i1.ɵccf("app-compose-attachs-preview", i5.ComposeAttachsPreviewComponent, View_ComposeAttachsPreviewComponent_Host_0, { attachCoupon: "attachCoupon", attachMenu: "attachMenu", attachSticky: "attachSticky" }, { removeAttachCouponNotfiy: "removeAttachCouponNotfiy", removeAttachMenuNotfiy: "removeAttachMenuNotfiy", removeAttachStickyNotfiy: "removeAttachStickyNotfiy" }, []);
export { ComposeAttachsPreviewComponentNgFactory as ComposeAttachsPreviewComponentNgFactory };
