import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule as Store } from '@ngrx/store';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SentryModule } from '../sentry.module';
import { AuthModule } from './+auth/auth.module';
import { SharedModule } from '../shared/shared.module';
import { NetworkModule } from '../network/network.module';
import { DashboardModule } from './+dashboard/dashboard.module';
import { RegistrationModule } from './+registration/registration.module';
import { AppRoutingModule } from './app-routing.module';
import { NbxSubscriptionModule } from '../subscription/subscription.module';
import { AppComponent } from './app.component';

import { BookingUiEffects } from '../store/bookingsUI/bookingUI.effects';
import { authReducer } from '../store/auth/auth.reducer';
import { messageReducer } from '../store/messages/message.reducer';
import { profileReducer } from '../store/profile/profile.reducer';
import { myProfileReducer } from '../store/myProfile/myProfile.reducer';
import { chatReducer } from '../store/chats/chat.reducer';
import { chatMemberReducer } from '../store/chatMembers/chatMember.reducer';
import { mainChatReducer } from '../store/mainChats/mainChat.reducer';
import { repliesEOPReducer } from '../store/repliesEOP/repliesEOP.reducer';
import { uiReducer } from '../store/ui/ui.reducer';
import { campaignReducer } from '../store/campaigns/campaign.reducer';
import { campaignUiReducer } from '../store/campaignsUI/campaignUI.reducer';
import { couponReducer } from '../store/coupons/coupon.reducer';
import { couponUiReducer } from '../store/couponsUI/couponUI.reducer';
import { scheduleReducer } from '../store/schedules/schedule.reducer';
import { awardReducer } from '../store/awards/award.reducer';
import { welcomeReducer } from '../store/welcomes/welcome.reducer';
import { itemReducer } from '../store/channelAppItems/item.reducer';
import { componentReducer } from '../store/channelAppComponents/component.reducer';
import { containerReducer } from '../store/channelAppContainers/container.reducer';
import { appClassReducer } from '../store/channelAppClasses/appClass.reducer';
import { appConfigReducer } from '../store/channelAppConfig/appConfig.reducer';
import { channelAppUiReducer } from '../store/channelAppUI/channelAppUI.reducer';
import { instantReducer } from '../store/instants/instant.reducer';
import { eventUiReducer } from '../store/eventsUI/eventUI.reducer';
import { subGroupUiReducer } from '../store/subGroupsUI/subGroupsUI.reducer';
import { subChannelUiReducer } from '../store/subChannelsUI/subChannelsUI.reducer';
import { virtualAppUiReducer } from '../store/virtualAppsUI/virtualAppsUI.reducer';
import { appReleaseReducer } from '../store/channelAppReleases/appRelease.reducer';
import { templateReducer } from '../store/templates/templates.reducer';
import { appLocalInfoReducer } from '../store/channelAppLocalInfo/appLocalInfo.reducer';
import { appMenuReducer } from '../store/channelAppMenu/appMenu.reducer';
import { myPageReducer } from '../store/channelMyPage/myPage.reducer';

import { AuthEffects } from '../store/auth/auth.effects';
import { MyProfileEffects } from '../store/myProfile/myProfile.effects';
import { ProfileEffects } from '../store/profile/profile.effects';
import { ChatMemberEffects } from '../store/chatMembers/chatMember.effects';
import { ChatEffects } from '../store/chats/chat.effects';
import { UiEffects } from '../store/ui/ui.effects';
import { SectionEffects } from '../store/section.effects';
import { MessageEffects } from '../store/messages/message.effects';
import { MainChatEffects } from '../store/mainChats/mainChat.effects';
import { CampaignEffects } from '../store/campaigns/campaign.effects';
import { CampaignUiEffects } from '../store/campaignsUI/campaignUI.effects';
import { CouponEffects } from '../store/coupons/coupon.effects';

import { CouponUiEffects } from '../store/couponsUI/couponUI.effects';
import { AppConfigEffects } from '../store/channelAppConfig/appConfig.effects';
import { ItemEffects } from '../store/channelAppItems/item.effects';
import { AppReleaseEffects } from '../store/channelAppReleases/appRelease.effects';
import { ContainerEffects } from '../store/channelAppContainers/container.effects';
import { AppClassEffects } from '../store/channelAppClasses/appClass.effects';
import { ChannelAppUIEffects } from '../store/channelAppUI/channelAppUI.effects';
import { ComponentEffects } from '../store/channelAppComponents/component.effects';
import { InstantEffects } from '../store/instants/instant.effects';
import { EventUiEffects } from '../store/eventsUI/eventUI.effects';
import { SubGroupUiEffects } from '../store/subGroupsUI/subGroupsUI.effects';
import { SubChannelUiEffects } from '../store/subChannelsUI/subChannelsUI.effects';
import { VirtualAppUiEffects } from '../store/virtualAppsUI/virtualAppsUI.effects';
import { AppMenuEffects } from '../store/channelAppMenu/appMenu.effects';
import { MyPageEffects } from '../store/channelMyPage/myPage.effects';
import { environment } from 'src/environments/environment';
import { appPublishingReducer } from '../store/appPublishing/appPublishing.reducer';
import { AppPublishingEffects } from '../store/appPublishing/appPublishing.effects';
import { mediaCenterReducer } from '../store/mediaCenter/mediaCenter.reducer';
import { MediaCenterEffects } from '../store/mediaCenter/mediaCenter.effect';
import { instantPageReducer } from '../store/channelAppInstantPage/instantPage.reducer';
import { InstantPageEffects } from '../store/channelAppInstantPage/instantPage.effects';
import { bookingPeriodsReducer } from '../store/bookingPeriods/bookingPeriods.reducer';
import { BookingPeriodsEffects } from '../store/bookingPeriods/bookingPeriods.effects';
import { myBookingsReducer } from '../store/myBookings/myBookings.reducer';
import { MyBookingsEffects } from '../store/myBookings/myBookings.effects';
import { paymentsReducer } from '../store/payments/payment.reducer';
import { PaymentEffects } from '../store/payments/payment.effects';
import { bookingUiReducer } from '../store/bookingsUI/bookingUI.reducer';
import { AppConfigHistoryEffects } from '../store/appConfigHistory/appConfigHistory.effects';
import { appConfigHistoryReducer } from '../store/appConfigHistory/appConfigHistory.reducer';
import { BulkEffects } from '../store/bulk/bulk.effects';
import { appChannelDataReducer } from '../store/appChannelData/appChannelData.reducer';
import { bookingExceptionsReducer } from '../store/bookingExceptions/bookingExceptions.reducer';
import { BookingExceptionsEffects } from '../store/bookingExceptions/bookingExceptions.effects';
import { mapServiceReducer } from '../store/mapServices/mapService.reducer';
import { MapServiceEffects } from '../store/mapServices/mapService.effects';
import { mapMarkerReducer } from '../store/mapMarkers/mapMarker.reducer';
import { mapRouteReducer } from '../store/mapRoutes/mapRoute.reducer';
import { mapTripReducer } from '../store/mapTrips/mapTrip.reducer';
import { mapTripUserReducer } from '../store/mapTripUsers/mapTripUser.reducer';
import { MapMarkerEffects } from '../store/mapMarkers/mapMarker.effects';
import { MapRouteEffects } from '../store/mapRoutes/mapRoute.effects';
import { MapTripEffects } from '../store/mapTrips/mapTrip.effects';
import { MapTripUserEffects } from '../store/mapTripUsers/mapTripUser.effects';
import { calendarUiReducer } from '../store/calendarUI/calendarUI.reducer';
import { CalendarUiEffects } from '../store/calendarUI/calendarUI.effects';
import { membersUiReducer } from '../store/membersUI/membersUI.reducer';
import { MembersUiEffects } from '../store/membersUI/membersUI.effects';
import { subItemReducer } from '../store/channelAppSubItems/subItem.reducer';
import { SubItemEffects } from '../store/channelAppSubItems/subItem.effects';
import { paymentOrdersUiReducer } from '../store/paymentOrdersUI/paymentOrdersUI.reducer';
import { serviceOrdersUiReducer } from '../store/serviceOrdersUI/serviceOrdersUI.reducer';
import { paymentProvidersReducer } from '../store/paymentProviders/paymentProviders.reducer';
import { PaymentProvidersEffects } from '../store/paymentProviders/paymentProviders.effects';

import { adsProvidersReducer } from '../store/adsProvider/adsProvider.reducer';
import { AdsProvidersEffects } from '../store/adsProvider/adsProvider.effects';
import { PaymentOrdersUiEffects } from '../store/paymentOrdersUI/paymentOrdersUI.effects';
import { ServiceOrdersUiEffects } from '../store/serviceOrdersUI/serviceOrdersUI.effects';
import { productsUiReducer } from '../store/productsUI/productsUI.reducer';
import { ProductsUiEffects } from '../store/productsUI/productsUI.effects';
import { bundlesUiReducer } from '../store/bundlesUI/bundlesUI.reducer';
import { BundlesUiEffects } from '../store/bundlesUI/bundlesUI.effects';
import { packagesUiReducer } from '../store/packagesUI/packagesUI.reducer';
import { PackagesUiEffects } from '../store/packagesUI/packagesUI.effects';
import { storeUiReducer } from './../store/storesUI/storesUI.reducer';
import { StoreUiEffects } from '../store/storesUI/storesUI.effects';
import { storeMenuUiReducer } from '../store/storeMenuUI/storeMenuUI.reducer';
import { StoreMenuUiEffects } from './../store/storeMenuUI/storeMenuUI.effects';
import { subMenuUiReducer } from './../store/subMenuUI/subMenuUI.reducer';
import { SubMenuUiEffects } from './../store/subMenuUI/subMenuUI.effects';
import { menuUiReducer } from './../store/menusUI/menuUI.reducer';
import { MenuUIEffects } from '../store/menusUI/menuUI.effects';
import { blackListUiReducer } from '../store/balckListUI/blackListUI.reducer';
import { BlackListUiEffects } from '../store/balckListUI/blackListUI.effects';
import { blackListPatternsUiReducer } from '../store/balckListPatternsUI/blackListPatternsUI.reducer';
import { BlackListPatternsUiEffects } from '../store/balckListPatternsUI/blackListPatternsUI.effects';
import { mapTripsHistoryReducer } from '../store/mapTripsHistory/mapTripsHistory.reducer';
import { MapTripsHistoryEffects } from '../store/mapTripsHistory/mapTripsHistory.effects';
import { MapStopTimeEffects } from '../store/mapStopTime/mapStopTime.effects';
import { mapTimeStopsReducer } from '../store/mapStopTime/mapStopTime.reducer';
import { driverTripsReducer } from '../store/driverAssigned/driverAssigned.reducuer';
import { mapTripsNotifiersReducer } from '../store/mapTripsNotifiers/mapTripsNotifiers.reducer';
import { MapTripsNotifiersEffects } from '../store/mapTripsNotifiers/mapTripsNotifiers.effects';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { WhitelistUIEffects } from '../store/whitelistMainUI/whitelistMainUI.effects';
import { whitelistUIReducer } from '../store/whitelistMainUI/whitelistMainUI.reducer';
import { whitelistPatternsUIReducer } from '../store/whitelistPatternsUI/whitelistPatternsUI.reducer';
import { WhitelistPatternsUIEffects } from '../store/whitelistPatternsUI/whitelistPatternsUI.effects';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

import { MapDriverEffects } from '../store/mapTripDriver/mapTripDriver.effects';
import { mapDriverReducer } from '../store/mapTripDriver/mapTripDriver.reducer';
import { DriverAssignedEffects } from '../store/driverAssigned/driverAssigned.effects';
import { AppChannelSettingsEffects } from '../store/appChannelSettings/appChannelSettings.effects';
import { appChannelSettingsReducer } from '../store/appChannelSettings/appChannelSettings.reducer';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { smtpReducer } from '../store/smtp/smtp.reducer';
import { SmtpEffects } from '../store/smtp/smtp.effects';
import { OtherTripsEffects } from '../store/otherDriverTrips/otherDriverTrips.effects';
import { otherTripsReducer } from '../store/otherDriverTrips/otherDriverTrips.reducuer';

const initialState = {};
export function getInitialState() {
  return initialState;
}
// AoT requires an exported function for factories
export function myHttpLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    AuthModule,
    DashboardModule,
    RegistrationModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    NetworkModule,
    SharedModule,
    ChartsModule,
    NbxSubscriptionModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: myHttpLoader,
        deps: [HttpClient]
      }
    }),
    environment.sentry ? SentryModule : [],
    Store.forRoot(
      {
        authReducer,
        messageReducer,
        profileReducer,
        myProfileReducer,
        chatReducer,
        chatMemberReducer,
        mainChatReducer,
        repliesEOPReducer,
        uiReducer,
        campaignReducer,
        campaignUiReducer,
        couponReducer,
        couponUiReducer,
        scheduleReducer,
        awardReducer,
        welcomeReducer,
        itemReducer,
        subItemReducer,
        componentReducer,
        containerReducer,
        appClassReducer,
        appConfigReducer,
        channelAppUiReducer,
        instantReducer,
        eventUiReducer,
        membersUiReducer,
        subGroupUiReducer,
        subChannelUiReducer,
        virtualAppUiReducer,
        appReleaseReducer,
        appPublishingReducer,
        templateReducer,
        appLocalInfoReducer,
        appMenuReducer,
        myPageReducer,
        instantPageReducer,
        mediaCenterReducer,
        bookingPeriodsReducer,
        myBookingsReducer,
        paymentsReducer,
        bookingUiReducer,
        appConfigHistoryReducer,
        bookingExceptionsReducer,
        appChannelDataReducer,
        mapServiceReducer,
        mapMarkerReducer,
        mapRouteReducer,
        mapTripReducer,
        mapTripUserReducer,
        calendarUiReducer,
        paymentOrdersUiReducer,
        serviceOrdersUiReducer,
        paymentProvidersReducer,
        adsProvidersReducer,
        productsUiReducer,
        bundlesUiReducer,
        packagesUiReducer,
        storeUiReducer,
        storeMenuUiReducer,
        subMenuUiReducer,
        menuUiReducer,
        blackListUiReducer,
        blackListPatternsUiReducer,
        mapTimeStopsReducer,
        mapTripsHistoryReducer,
        mapDriverReducer,
        driverTripsReducer,
        mapTripsNotifiersReducer,
        whitelistPatternsUIReducer,
        whitelistUIReducer,
        appChannelSettingsReducer,
        smtpReducer,
        otherTripsReducer
      },
      { initialState: getInitialState }
    ),
    EffectsModule.forRoot([
      AuthEffects,
      SectionEffects,
      MyProfileEffects,
      ProfileEffects,
      ChatMemberEffects,
      ChatEffects,
      UiEffects,
      MessageEffects,
      MainChatEffects,
      CampaignEffects,
      CampaignUiEffects,
      CouponEffects,
      CouponUiEffects,
      AppConfigEffects,
      ItemEffects,
      SubItemEffects,
      AppReleaseEffects,
      ContainerEffects,
      AppClassEffects,
      ChannelAppUIEffects,
      ComponentEffects,
      InstantEffects,
      EventUiEffects,
      MembersUiEffects,
      SubGroupUiEffects,
      SubChannelUiEffects,
      VirtualAppUiEffects,
      AppMenuEffects,
      MyPageEffects,
      InstantPageEffects,
      BookingUiEffects,
      AppPublishingEffects,
      MediaCenterEffects,
      BookingPeriodsEffects,
      BookingExceptionsEffects,
      MyBookingsEffects,
      PaymentEffects,
      BulkEffects,
      AppConfigHistoryEffects,
      MapServiceEffects,
      MapMarkerEffects,
      MapRouteEffects,
      MapTripEffects,
      MapDriverEffects,
      MapTripUserEffects,
      CalendarUiEffects,
      PaymentProvidersEffects,
      AdsProvidersEffects,
      PaymentOrdersUiEffects,
      ServiceOrdersUiEffects,
      ProductsUiEffects,
      BundlesUiEffects,
      PackagesUiEffects,
      StoreUiEffects,
      StoreMenuUiEffects,
      SubMenuUiEffects,
      MenuUIEffects,
      BlackListUiEffects,
      BlackListPatternsUiEffects,
      MapStopTimeEffects,
      MapTripsHistoryEffects,
      MapTripsNotifiersEffects,
      DriverAssignedEffects,
      WhitelistUIEffects,
      WhitelistPatternsUIEffects,
      AppChannelSettingsEffects,
      SmtpEffects,
      OtherTripsEffects
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({ maxAge: 1000 }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.serviceworker
    })
  ],
  declarations: [AppComponent],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule {}
