import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MapMarkerDispatchers } from 'src/app/store/mapMarkers/mapMarker.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { UISections } from 'src/models/constants';
import { MapMarker, MapMarkerAction } from 'src/models/MapMarker';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  constructor(
    private _rootStore: RootStoreSelectors,
    private _uiDispatchers: UIDispatchers,
    private _mapMarkerDispatchers: MapMarkerDispatchers
  ) {}

  selectMapMarker(mapMarker: MapMarker) {
    this._uiDispatchers.mapMarkerSelected(mapMarker);
  }

  setMapMarkerEdit(mapMarker: MapMarker) {
    this._uiDispatchers.mapMarkerSelected(mapMarker);
  }

  getMapMarkerWithId(id: string) {
    return this._rootStore.getMapMarkerWId$(id);
  }

  setMapMarker(
    id: string,
    mapMarker: MapMarker,
    backToLocation: boolean = true
  ) {
    if (id) {
      this._mapMarkerDispatchers.updateMapMarker(id, mapMarker);
    } else {
      this._mapMarkerDispatchers.createMapMarker(mapMarker);
    }
    if (backToLocation) {
      this.backToLocations();
    }
  }

  requestMapMarkerWithId(id: string) {
    return this._mapMarkerDispatchers.getMapMarker(id);
  }

  deleteMapMarker(id: string) {
    this._mapMarkerDispatchers.deleteMapMarker(id);
  }

  backToLocations() {
    this._uiDispatchers.setSection(UISections.LOCATIONS);
  }

  getNextPage(): void {
    this._mapMarkerDispatchers.getNextMapMarkerPage();
  }

  getPrevPage(): void {
    this._mapMarkerDispatchers.getPrevMapMarkerPage();
  }

  resetPaging(selected_chat: string = null, type?: string): void {
    this._mapMarkerDispatchers.setMapMarkerSelectedChat(selected_chat, type);
  }

  /** Selectors */
  get mapMarkers$() {
    return this._rootStore.mapMarkers$;
  }

  getMapActionTitle(action: MapMarkerAction): Observable<string> {
    if (!action) return null;
    /**
     *Action Type:
     *  0 → Group
     *  1 → mStore
     *  2 → Booking
     *  3 → Event
     *  4 → Channel
     *  5 → Vapp
     */
    switch (action.type) {
      case '0':
      case '4':
      case '5':
        return this._rootStore
          .getGroupDetailsByID$(action.id)
          .pipe(
            map(channel => (channel && channel.title ? channel.title : ''))
          );
      case '1':
        return this._rootStore
          .getMStoreByID$(action.id)
          .pipe(map(store => (store && store.name ? store.name : '')));
      case '2':
      case '3':
      default:
        return null;
    }
  }

  getMapMarkerDetailsById$(id: string): Observable<MapMarker> {
    return this._rootStore.getMapMarkerById$(id);
  }
}
