<ng-container>
  <mat-sidenav-container
    *ngIf="uiService.uiCollection$ | async as uiCollection"
    class="main-layout"
  >
    <mat-sidenav
      *ngIf="
        mode == 2 ||
        (uiService?.selectedChat$ | async)?.subChannel ||
        !role ||
        role == 2
      "
      #sidenav
      [mode]="
        (layoutService?.getSideNavStatus() | async)?.matches ? 'over' : 'side'
      "
      [opened]="!(layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus"
      class="main-layout-sidenav"
    >
      <app-side-menu
        class="no-copyable-item"
        appCancelDrag
        [isOpen]="true"
        [direction]="(uiService?.uiCollection$ | async)?.section"
        [progressWeb]="(authService.authCollection$ | async)?.progressWeb"
        [currentScreenNumber]="appManagementService.currentPage$ | async"
        (sectionNotify)="sectionEvent($event)"
      >
      </app-side-menu>
    </mat-sidenav>
    <!-- <mat-sidenav
      *ngIf="mode == 1 || mode == 0"
      #sidenav
      [mode]="
        (layoutService?.getSideNavStatus() | async)?.matches ? 'over' : 'side'
      "
      [opened]="!(layoutService?.getSideNavStatus() | async)?.matches"
    >
    </mat-sidenav> -->
    <mat-sidenav-content class="main-layout-content">
      <div
        *ngIf="
          mode == 2 ||
          (uiService?.selectedChat$ | async)?.subChannel ||
          !role ||
          role == 2
        "
        class="nav-bar-container"
        [class.sticky]="
          (uiService?.uiCollection$ | async)?.section === uiSection?.APP
        "
        [class.active]="
          ((uiService?.uiCollection$ | async)?.section === uiSection?.APP &&
            (!(layoutService?.getSideNavStatus() | async)?.matches &&
              (layoutService?.getsideMenuStatus$() | async)?.sideMenuStatus)) ||
          (layoutService?.getSideNavStatus() | async)?.matches
        "
      >
        <app-nav-bar
          *ngIf="
            (mode == 2 ||
              (uiService?.selectedChat$ | async)?.subChannel ||
              !role ||
              role == 2) &&
            (layoutService?.getsideMenuStatus$() | async) as sideMenu
          "
          class="main-layout-header"
          [selectedChatId]="uiCollection?.selectedChat?.id"
          (searchNotify)="searchEvent($event)"
          (logoutNotify)="logoutEvent($event)"
          (sectionNotify)="sectionEvent($event)"
          (talkToAdminNotify)="talkToAdminEvent($event)"
          (toggleSidenavNotify)="setSideNavStatus(sideMenu?.sideMenuStatus)"
        >
        </app-nav-bar>
      </div>
      <div class="main-layout-body">
        <div class="content">
          <ng-content select="main-layout-route"></ng-content>
        </div>
        <ng-content select="main-layout-side"></ng-content>
      </div>
      <!-- <div class="main-layout-content" *ngIf="mode == 0 || mode == 1">
        <div class="content">
          <ng-content select="main-layout-route2"></ng-content>
        </div>
      </div> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
  <app-modal-container
    *ngIf="
      (hidePopUp &&
        (appManagementService.channelAppUiCollection$ | async)
          ?.currentScreen === appScreens?.TABS &&
        (authService.authCollection$ | async)?.mode !== 0) ||
      (hidePopUp &&
        (appManagementService.channelAppUiCollection$ | async)
          ?.currentScreen === appScreens?.NAV_MENU &&
        (authService.authCollection$ | async)?.mode !== 0)
    "
  >
    <app-confirm-info (closeNotifier)="togglePopUp()"></app-confirm-info>
  </app-modal-container>
</ng-container>
