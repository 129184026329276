import { Injectable } from '@angular/core';
import * as EmojiConvertor from 'emoji-js/lib/emoji.js';
import { DateTime } from 'luxon';

import { StorageService } from './storage.service';

import { OFFSET_STORAGE_KEY, MessageTypes } from 'src/models/constants';
import { IMessage } from 'src/models/IMessage';
import { ReplyMessage } from 'src/models/ReplyMessage';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  clickedComponent = null;

  constructor(private _storageService: StorageService) {}

  /* Time helper */
  private getDateOffset(): number {
    return this._storageService.getRecord(OFFSET_STORAGE_KEY)
      ? this._storageService.getRecord(OFFSET_STORAGE_KEY)
      : 0;
  }

  public getCurrentTime(): number {
    return Date.now() - this.getDateOffset();
  }

  public convertMilliSecsToReadableTime(millis: number): string {
    if (!millis && millis !== 0) {
      return '';
    }
    const minutes = Math.floor(millis / 60000);
    const seconds = parseInt(((millis % 60000) / 1000).toString(), 10);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  public convertMillisecondsToReadableUnits(
    timeZone: string,
    startTime: number,
    endTime: number
  ) {
    const options = { zone: timeZone };
    const startDateT = DateTime.fromMillis(startTime, options);
    const endDateT = DateTime.fromMillis(endTime, options);
    const startHour = startDateT.hour;
    const endHour = endDateT.hour;
    const startMinute = startDateT.minute;
    const endMinute = endDateT.minute;
    const startDate = new Date(
      startDateT.year,
      startDateT.month - 1,
      startDateT.day
    );
    const endDate = new Date(endDateT.year, endDateT.month - 1, endDateT.day);

    return {
      startHour,
      endHour,
      startMinute,
      endMinute,
      startDate,
      endDate
    };
  }

  public convertReadableUnitsToMilliseconds(
    date: number,
    hour: number,
    minute: number,
    second: number,
    zone?: string
  ) {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1; // number start from zero
    const year = new Date(date).getFullYear();
    const monthString = month < 10 ? '0' + month : '' + month;
    const dateString = year + '-' + monthString + '-' + day;

    const dateUtc = DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
      second,
      zone
    });

    return {
      dateString,
      dateUtc: dateUtc.toMillis()
    };
  }

  public convertMillisecondsToUTCTime(
    date: Date,
    hour: number,
    minute: number,
    second: number,
    zone: string
  ) {
    const day = new Date(date).getDate();
    const month = new Date(date).getMonth() + 1; // number start from zero
    const year = new Date(date).getFullYear();
    const monthString = month < 10 ? '0' + month : '' + month;
    const dateString = year + '-' + monthString + '-' + day;

    const dateUtc = DateTime.fromObject({
      year,
      month,
      day,
      hour,
      minute,
      second,
      zone
    });

    return {
      dateString,
      dateUtc: dateUtc.toMillis()
    };
  }

  /* string helper */
  public getMultiLine(str: string) {
    return str.replace(/\n/g, '<br>');
  }

  public convertUnifiedAndColonsToEmojiSpan(value: string) {
    if (value) {
      const emoji = new EmojiConvertor();
      emoji.use_sheet = true;
      emoji.include_title = true;
      emoji.img_sets.apple.path = './assets/emojis/';
      emoji.img_sets.apple.sheet =
        './assets/emojis/sheet_apple_64_indexed_128.png';

      value = emoji.replace_unified(emoji.replace_colons(value));
      return value.replace(
        /<span class="emoji-outer emoji-sizer"><span class="emoji-inner" style="(.*?)" title="(.*?)" .*?>.*?<\/span><\/span>/g,
        `<img src="./assets/img/blank.gif" class="emoji-inner" style="$1" alt=":$2:"/>`
      );
    }
    return '';
  }

  public stripEmojis(html: string) {
    // convert colns emojis to unicode emojis
    const emoji = new EmojiConvertor();
    emoji.replace_mode = 'unified';
    emoji.allow_native = true;

    // Create a new div element
    const temporalDivElement = document.createElement('div');
    const emojisDivElement = document.createElement('div');

    // Set the HTML content with the providen ( twemoji )
    // Retrieve the text property of the element ( cross-browser support )
    temporalDivElement.innerHTML = html
      .replace(/\n/g, '')
      .replace(/<br\s*[\/]?>/g, '\n')
      .replace(/<img.*?alt="(.*?)".*?>/g, '$1');

    emojisDivElement.innerHTML = emoji.replace_colons(
      temporalDivElement.innerHTML
    );

    return (
      emojisDivElement.textContent.trim() ||
      emojisDivElement.innerText.trim() ||
      null
    );
  }

  public stripHtml(html: string) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html.replace(/<br\s*[\/]?>/gi, '\n').trim();
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  /* cursor helper  */
  public getCaretCharacter(element) {
    const range = window.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    const tmp = document.createElement('div');
    let caretPosition;

    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tmp.appendChild(preCaretRange.cloneContents());
    caretPosition = tmp.innerHTML.length;
    return caretPosition;
  }

  public insertEmojiAtCursor(html: string) {
    const doc: any = document;
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        const el = document.createElement('div');
        el.innerHTML = html;
        const node = el.firstChild;
        let range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(' '));
        range.insertNode(node);
        range.setStart(node, 0);

        setTimeout(() => {
          range = document.createRange();
          range.setStartAfter(node);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 0);
      }
    } else if (doc.selection && doc.selection.type !== 'Control') {
      doc.selection.createRange().pasteHTML(html);
    }
  }

  public saveSelection() {
    const doc: any = document;
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    } else if (doc.selection && doc.selection.createRange) {
      return doc.selection.createRange();
    }
    return null;
  }

  public getSelection() {
    const doc: any = document;
    if (window.getSelection) {
      const sel: Selection = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const content = range.cloneContents();
        const span = document.createElement('span');
        span.appendChild(content);
        const htmlContent = span.innerHTML;
        return this.stripEmojis(htmlContent);
      }
    } else if (doc.selection && doc.selection.createRange) {
      const range = doc.selection.createRange();
      const content = range.cloneContents();
      const span = document.createElement('span');
      span.appendChild(content);
      const htmlContent = span.innerHTML;
      return this.stripEmojis(htmlContent);
    }
    return '';
  }

  public restoreSelection(range) {
    const doc: any = document;
    if (range) {
      if (window.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (doc.selection && range.select) {
        range.select();
      }
    }
  }

  setSelectionRange(input, selectionStart, selectionEnd) {
    // if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
    // } else if (input.createTextRange) {
    //   const range = input.createTextRange();
    //   range.collapse(true);
    //   range.moveEnd('character', selectionEnd);
    //   range.moveStart('character', selectionStart);
    //   range.select();
    // }
  }

  setCaretToPos(input, pos) {
    this.setSelectionRange(input, pos, pos);
  }

  handleDifferentReplyTypes(msg: IMessage): ReplyMessage {
    const replyDetails: ReplyMessage = {};
    replyDetails.lastReplyID = msg.message_id;
    replyDetails.lastReplyReference = msg.reference;
    replyDetails.lastReplySenderID = msg.sender_id;
    replyDetails.lastReplyTime = msg.date;
    replyDetails.lastReplyStatus = msg.status;
    replyDetails.lastReplyIsRead = msg.isRead;
    if (msg.caption) {
      replyDetails.lastReplyMessage = msg.caption;
    }
    if (msg.type === MessageTypes.TEXT || msg.type === MessageTypes.TEXT_FILE) {
      replyDetails.lastReplyMessage = msg.text;
    } else if (msg.type !== MessageTypes.TEXT_FILE) {
      replyDetails.lastReplyType = msg.type;
    }
    return replyDetails;
  }

  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this;
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  public cancelEvent(event) {
    event = event || window.event;
    if (event) {
      event = event.originalEvent || event;

      if (event.stopPropagation) {
        event.stopPropagation();
      }
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.returnValue = false;
      event.cancelBubble = true;
    }
    return false;
  }
}
