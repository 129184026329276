import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { LocationsService } from '../../locations/locations.service';
import { PrivilegesName } from 'src/models/privileges';
import { LOCATIONS } from 'src/models/AppSections';
import { MapMarker } from 'src/models/MapMarker';
import { AuthService } from 'src/app/core/auth.service';
import { UiService } from 'src/app/core/ui.service';

@Component({
  selector: 'app-locations-markers-pool',
  templateUrl: './locations-markers-pool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsMarkersPoolComponent implements OnInit {
  @Input() selectedMapMarker: MapMarker;
  @Input() echoInput: any;
  @Input() selectedChatId: string;
  @Input() type: string;
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectMapMarkerNotfiy = new EventEmitter<MapMarker>();
  @Output() echoOutput = new EventEmitter<any>();

  locationsFet = LOCATIONS;
  privilegesName: typeof PrivilegesName = PrivilegesName;
  currentSelectMessage: MapMarker;
  showCreateCompose = false;
  showEmptySec = false;

  constructor(
    public _locationsService: LocationsService,
    public _authService: AuthService,
    public _uiService: UiService
  ) {}

  ngOnInit() {
    if (this.selectedMapMarker) {
      this.currentSelectMessage = { ...this.selectedMapMarker };
    }
    this._locationsService.resetPaging(this.selectedChatId, this.type);
    // this._uiService.privileges$.subscribe(res =>
    //   res.indexOf(this.privilegesName.listmarker) === -1
    //     ? (this.showEmptySec = true)
    //     : (this.showEmptySec = false)
    // );
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(message: MapMarker) {
    this.currentSelectMessage = { ...message };
  }

  resetSelectMessage() {
    this.currentSelectMessage = null;
  }

  insertEvent() {
    if (this.currentSelectMessage) {
      this.selectMapMarkerNotfiy.emit(this.currentSelectMessage);
      if (this.echoInput) this.echoOutput.emit(this.echoInput);
    } else {
      return;
    }
  }

  isMessageSelected(id: string) {
    return this.currentSelectMessage
      ? this.currentSelectMessage.id === id
      : false;
  }

  TrackByFunction(index, item: MapMarker) {
    return item.id;
  }

  addNewLocationEvent(visible: boolean) {
    this.showCreateCompose = visible;
  }
}
