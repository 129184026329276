import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ScreenSizeService } from 'src/app/core/screen-size.service';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';

import { SIDE_MENU } from 'src/models/AppSections';
import { GridStyle } from 'src/models/constants';
import { BreakpointState } from '@angular/cdk/layout';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  appMenu = SIDE_MENU;
  private _listGridStyle = new BehaviorSubject<number>(GridStyle.card);
  public listGridStyle$ = this._listGridStyle.asObservable();

  constructor(
    private _selectors: RootStoreSelectors,
    private _messageDispatchers: MessageDispatchers,
    private _screenSize: ScreenSizeService,
    private _uiDispatcher: UIDispatchers
  ) {}

  getSideNavStatus(): Observable<BreakpointState> {
    return this._screenSize.mobileQuery;
  }
  setListGridStyle(n: number) {
    this._listGridStyle.next(n);
  }
  setSideMenuStatus(status: number) {
    this._uiDispatcher.setSideMenuStatus(status);
  }
  get messagesCollection$() {
    return this._selectors.messagesCollection$;
  }

  get uiCollection$() {
    return this._selectors.uiCollection$;
  }

  get selectedChat$() {
    return this._selectors.getSelectedChat$();
  }

  get allChannels$() {
    return this._selectors.getChatsMenu$();
  }
  getsideMenuStatus$() {
    // this._selectors
    //   .getSideMenuStatus()
    //   .subscribe(res => console.log('status', res));
    return this._selectors.getSideMenuStatus();
  }

  deselectChannel() {
    this._messageDispatchers.deselectChannel();
  }
}
