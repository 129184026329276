import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from '../../shared/shared.module';
import { AccessorsModule } from '../../accessors/accessors.module';
import { LayoutModule } from '../+layout/layout.module';
import { PreviewsModule } from 'src/app/previews/previews.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractIconsModule } from 'src/app/abstract-icons/abstract-icons.module';
import { AbstractNotifiersModule } from 'src/app/abstract-notifiers/abstract-notifiers.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { StatisticsModule } from './statistics/statistics.module';
import { MessagesAdminModule } from './messages-admin/messages-admin.module';
import { ComposeMessageModule } from './compose-message/compose-message.module';
import { DashboardComponent } from './dashboard.component';
import { DragableFeaturesModule } from './app-mgmt/custom/dragable-features/dragable-features.module';

@NgModule({
  imports: [
    DashboardRoutingModule,
    CommonModule,
    NgxModule,
    SharedModule,
    PreviewsModule,
    LayoutModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractIconsModule,
    AbstractNotifiersModule,
    StatisticsModule,
    ComposeMessageModule,
    MessagesAdminModule,
    DragableFeaturesModule
  ],
  declarations: [DashboardComponent],
  exports: [DashboardComponent]
})
export class DashboardModule {}
