import { Store } from '@ngrx/store';
import * as CouponUIActions from './couponUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CouponUIDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    couponListUI() {
        this._store.dispatch(new CouponUIActions.ListCouponUI());
    }
    createCouponUI() {
        this._store.dispatch(new CouponUIActions.CreateCouponUI());
    }
    viewCouponUI(selectedCoupon) {
        this._store.dispatch(new CouponUIActions.ViewCouponUI(selectedCoupon));
    }
    editCouponUI(selectedCoupon) {
        this._store.dispatch(new CouponUIActions.EditCouponUI(selectedCoupon));
    }
    closeCreateCouponUI() {
        this._store.dispatch(new CouponUIActions.CloseCreateCouponUI());
    }
    closeViewCouponUI() {
        this._store.dispatch(new CouponUIActions.CloseViewCouponUI());
    }
    closeEditCouponUI() {
        this._store.dispatch(new CouponUIActions.CloseEditCouponUI());
    }
    getNextCouponPage() {
        this._store.dispatch(new CouponUIActions.GetNextCouponPage());
    }
    getPrevCouponPage() {
        this._store.dispatch(new CouponUIActions.GetPrevCouponPage());
    }
    receivedCouponHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new CouponUIActions.ReceivedCouponHistoryChunk(eop, sop, currentPage));
    }
    resetCouponPaging(status, expire) {
        this._store.dispatch(new CouponUIActions.ResetCouponPaging(status, expire));
    }
    setStatusAsIdle() {
        this._store.dispatch(new CouponUIActions.SetStatusAsIdle());
    }
    reset() {
        this._store.dispatch(new CouponUIActions.Reset());
    }
}
CouponUIDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CouponUIDispatchers_Factory() { return new CouponUIDispatchers(i0.ɵɵinject(i1.Store)); }, token: CouponUIDispatchers, providedIn: "root" });
