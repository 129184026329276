import {
  GET_CHAT_HISTORY_FROM_MOBILE_METHOD,
  GET_CHAT_MEMBER_COUNT_METHOD
} from './constants';
import { ITag } from './ITag';
import { IRole } from './IRole';

export interface MainChat {
  id?: string;
  name?: string;
  desc?: string;
  image?: string;
  imageThumbnail?: string;
  lastMsgTime?: number;
  status?: string; // status message
  priority?: boolean;
  pinned_date?: number;
  backgroundColor?: string;
  last_seen?: number;
  type?: string;
  isAdmin?: boolean;
  unreadCounter?: number;
  firstLevelUnreadCounter?: number;
  memberCount?: number;
  lastTypedMessage?: string;
  online?: boolean;
  lastSenderIDInGroup?: string;
  lastSenderNameInGroup?: string;
  isPublic?: boolean;
  isHistoryRequested?: boolean;
  detailsReceived?: boolean;
  inivite_link?: string;
  contact?: boolean;
  unknownContact?: boolean;
  lastReplyStatus?: string;
  lastReplyMessage?: string;
  lastReplyType?: string;
  lastReplyID?: string;
  lastReplyReference?: number;
  selected?: boolean;
  unreadMessagesNumberOnSelection?: number;
  notViewedCounter?: number;
  endOfPage?: number;
  category?: string;
  eopLevel1?: number;
  tagsDefinition?: ITag[];
  chatRoles?: IRole[];
  subChannel?: boolean;
  isChannelApp?: boolean;
  isUpgradedChannel?: boolean;
  createdDate?: number;
}

export class GetChatHistoryFromMobile {
  readonly method = GET_CHAT_HISTORY_FROM_MOBILE_METHOD;
  constructor(
    public chat_id: string,
    public reference,
    public count,
    public pid?: string,
    public lv?: number,
    public accountId?: string
  ) {}
}

export class GetChatMemberCount {
  readonly method = GET_CHAT_MEMBER_COUNT_METHOD;
  constructor(public chat_id) {}
}

export type MainChatMethods = GetChatHistoryFromMobile | GetChatMemberCount;
