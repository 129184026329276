import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { LICENSES } from 'src/models/constants';

@Directive({
  selector: '[appShowIfHasPrivilege]'
})
export class ShowIfHasPrivilegeDirective implements OnInit, OnDestroy {
  @Input() appShowIfHasPrivilege: string;
  isVisible = false;
  stop$ = new Subject();

  constructor(
    private _ref: ChangeDetectorRef,
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _rootStore: RootStoreSelectors
  ) {}

  ngOnInit(): void {
    this._rootStore.privileges$.pipe(takeUntil(this.stop$)).subscribe(prs => {
      if (
        !prs ||
        prs.findIndex(r =>
          this.appShowIfHasPrivilege.split(',').includes(r)
        ) === -1 ||
        LICENSES.findIndex(r =>
          this.appShowIfHasPrivilege.split(',').includes(r)
        ) === -1
      ) {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        if (this.isVisible) {
          this.isVisible = false;
          this._viewContainerRef.clear();
        }
      } else {
        this.setEmbedded();
      }
    });
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.stop$.next();
  }

  setEmbedded() {
    if (!this.isVisible) {
      // We update the `isVisible` property and add the
      // templateRef to the view using the
      // 'createEmbeddedView' method of the viewContainerRef
      this.isVisible = true;

      this._viewContainerRef.createEmbeddedView(this._templateRef);
      this._ref.detectChanges();
    }
  }
}
