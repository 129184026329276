import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './+auth/auth.component';
import { SupportLogInComponent } from './+auth/support-log-in/support-log-in.component';
import { RegistrationComponent } from './+registration/registration.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'login', component: AuthComponent },
  { path: 'signup', component: RegistrationComponent },
  { path: 'spt', component: SupportLogInComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
