import { BehaviorSubject } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { BookingUiDispatchers } from 'src/app/store/bookingsUI/bookingUI.dispatchers';
import { BookingPeriodsDispatchers } from 'src/app/store/bookingPeriods/bookingPeriods.dispatchers';
import { BookingExceptionsDispatchers } from 'src/app/store/bookingExceptions/bookingExceptions.dispatchers';
import { UPDATE_BOOKING_REF, CHANNEL_APP_SEARCH_CATEGORIES } from 'src/models/constants';
import { MyBookingsDispatchers } from './../../../store/myBookings/myBookings.dispatchers';
import { ProductsUiDispatchers } from './../../../store/productsUI/productsUI.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/chats/chat.dispatchers";
import * as i3 from "../../../store/bookingsUI/bookingUI.dispatchers";
import * as i4 from "../../../store/bookingPeriods/bookingPeriods.dispatchers";
import * as i5 from "../../../store/bookingExceptions/bookingExceptions.dispatchers";
import * as i6 from "../../../store/myBookings/myBookings.dispatchers";
import * as i7 from "../../../store/productsUI/productsUI.dispatchers";
export class BookingsService {
    constructor(_selectors, _chatDispatchers, _bookingUiDispatchers, _bookingPeriodsDispatchers, _bookingExceptionsDispatchers, _myBookingsDispatchers, _productsDispatchers) {
        this._selectors = _selectors;
        this._chatDispatchers = _chatDispatchers;
        this._bookingUiDispatchers = _bookingUiDispatchers;
        this._bookingPeriodsDispatchers = _bookingPeriodsDispatchers;
        this._bookingExceptionsDispatchers = _bookingExceptionsDispatchers;
        this._myBookingsDispatchers = _myBookingsDispatchers;
        this._productsDispatchers = _productsDispatchers;
        this.confirmDeleteWindow = false;
        this._currentPhotoUploaded = new BehaviorSubject({});
        this.currentPhotoUploaded$ = this._currentPhotoUploaded.asObservable();
    }
    createBooking(booking) {
        this._chatDispatchers.createBooking(booking);
    }
    deleteBooking(id) {
        this._chatDispatchers.deleteChat(id);
    }
    toggleConfirmDeleteWindow(e, booking) {
        this.confirmDeleteWindow = e;
        this.selectedBooking = booking;
    }
    viewBooking(booking) {
        this.getChatAdministrators(booking.id);
        this._bookingUiDispatchers.viewBookingUI(booking);
    }
    closeViewBooking() {
        this._currentPhotoUploaded.next({});
        this._bookingUiDispatchers.closeViewBookingUI();
    }
    showEditBookingScreen(booking) {
        const photo = {
            file: null,
            url: booking.localThumbnail
        };
        this._currentPhotoUploaded.next(photo);
        this.getChatAdministrators(booking.id);
        this._bookingUiDispatchers.editBookingUI(booking);
    }
    showReservations(booking) {
        this._bookingUiDispatchers.showReservations(booking);
    }
    showCreateBookingScreen() {
        this._bookingUiDispatchers.createBookingUI();
    }
    closeEditBookingScreen() {
        this._bookingUiDispatchers.closeEditBookingUI();
    }
    updateBooking(chatID, updatedBookingProps) {
        updatedBookingProps.id = chatID;
        this._chatDispatchers.updateChat(updatedBookingProps, UPDATE_BOOKING_REF);
    }
    updateStore(chatID, updatedBookingProps) {
        updatedBookingProps.id = chatID;
        this._chatDispatchers.updateStore(updatedBookingProps, UPDATE_BOOKING_REF);
        this._bookingUiDispatchers.closeEditBookingUI();
    }
    uploadBookingImage(file) {
        console.log('upld =>', file);
        this._bookingUiDispatchers.uploadBookingImage(file);
    }
    bookingCreationComplete() {
        this._bookingUiDispatchers.bookingCreationComplete();
    }
    confirmDelete(id) {
        this.deleteBooking(id);
        this.toggleConfirmDeleteWindow(false);
    }
    //
    get bookingChannelList$() {
        return this._selectors.getBookingList$();
    }
    getBookingPeriods$(bookingChannelID) {
        return this._selectors.getBookingPeriods$(bookingChannelID);
    }
    createBookingPeriods(bookingPeriods) {
        this._bookingPeriodsDispatchers.createBookingPeriods(bookingPeriods);
    }
    updateBookingPeriods(bookingPeriods) {
        this._bookingPeriodsDispatchers.updateBookingPeriods(bookingPeriods);
    }
    deleteBookingPeriods(chatId, data) {
        this._bookingPeriodsDispatchers.deleteBookingPeriods(chatId, data);
    }
    get selectedBooking$() {
        return this._selectors.getSelectedBooking$();
    }
    get selectedBookingPeriods$() {
        return this._selectors.getSelectedBookingPeriods$();
    }
    get selectedBookingsHolidays$() {
        return this._selectors.getSelectedBookingsHolidays$();
    }
    get bookingInCreationProcess$() {
        return this._selectors.getBookingInCreationProcess$();
    }
    get bookingUiState$() {
        return this._selectors.bookingUiState$;
    }
    // to get Holidays
    getBookingHolidays$(bookingChannelID) {
        return this._selectors.getBookingsHolidays$(bookingChannelID);
    }
    updateBookingHolidays(bookingChannelID, dates) {
        this._bookingExceptionsDispatchers.setBookingExceptions(bookingChannelID, dates);
    }
    setEnableService(config) {
        this._bookingUiDispatchers.setEnableService(config);
    }
    removeBookingHolidays(bookingChannelID, dates) {
        this._bookingExceptionsDispatchers.deleteBookingExceptions(bookingChannelID, dates);
    }
    getReservations(bookingChannelID, date) {
        this._myBookingsDispatchers.requestMyBookings(bookingChannelID, date);
    }
    getMyBookings$() {
        return this._selectors.getMyBookings$();
    }
    getProductById(prdId, type, bundleId) {
        this._productsDispatchers.getProduct(prdId, null, bundleId);
    }
    get area$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.AREA);
    }
    get classification$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS);
    }
    get category$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY);
    }
    getNextPage() {
        this._bookingUiDispatchers.getNextBookingPage();
    }
    getPreviousPage() {
        this._bookingUiDispatchers.getPrevBookingPage();
    }
    resetPaging() {
        this._bookingUiDispatchers.resetBookingPaging();
    }
    getBookingPeriods(chatId) {
        this._chatDispatchers.getBookingPeriods(chatId);
    }
    cancelReservation(chat_id, user_id, reference, date, time) {
        this._myBookingsDispatchers.cancelMyBookingReservation(chat_id, user_id, reference, date, time);
    }
    getChatAdministrators(id) {
        this._chatDispatchers.getChatAdministrators(id);
    }
    setChat(chat) {
        this._bookingUiDispatchers.setChat(chat);
    }
}
BookingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingsService_Factory() { return new BookingsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ChatDispatchers), i0.ɵɵinject(i3.BookingUiDispatchers), i0.ɵɵinject(i4.BookingPeriodsDispatchers), i0.ɵɵinject(i5.BookingExceptionsDispatchers), i0.ɵɵinject(i6.MyBookingsDispatchers), i0.ɵɵinject(i7.ProductsUiDispatchers)); }, token: BookingsService, providedIn: "root" });
