<div class="message-container">
  <div class="box-container" [ngStyle]="currentBoxStyle">
    <div
      class="edit wrap-text  copyable-item text-cursor"
      appAutoFocus
      data-text="Type your Post here and give it a colorful background..."
      contenteditable
      dir="auto"
      #textBox
      (paste)="onRichPaste($event)"
      (input)="checkIfMessageIsShort()"
      (mouseup)="lastfocus()"
      (keyup)="lastfocus()"
      (keydown)="createNewLine($event)"
    ></div>

    <div
      class="emoji-btn"
      [popover]="emojiPopover"
      [outsideClick]="true"
      [placement]="'left'"
      (mouseover)="toggleTooltip(true)"
      (mouseleave)="toggleTooltip(false)"
      (click)="onFocus()"
    >
      <svg
        class="svg-icon icon-icon"
        [ngStyle]="{ fill: currentBoxStyle?.color }"
        style="font-size: 18px;"
      >
        <use xlink:href="./assets/svg/app-sections.svg#icon-smile"></use>
      </svg>
      <app-hover-tooltip
        *ngIf="hoverEmoji"
        [top]="-38"
        [right]="140"
        [text]="'Add emojis'"
        [width]="'max-content'"
        style="font-size: 11px;"
      ></app-hover-tooltip>
    </div>
  </div>

  <div *ngIf="showColors" class="color-container">
    <div
      class="color-item clickable-cursor reset-color"
      (click)="resetStyles(false)"
    ></div>
    <div
      *ngFor="let color of colorsList"
      class="color-item clickable-cursor"
      (click)="setCurrentBoxBackgroundColor(color)"
      [ngStyle]="{ 'background-color': color }"
    ></div>
  </div>
</div>

<!-- Message Attachs -->
<ng-content select="[message-attachs]"></ng-content>
<!------------------------------------------------->
<!-- Message Targets ( Tags and Tab ) -->
<ng-content select="[message-target]"></ng-content>
<!------------------------------------------------->

<div class="footer">
  <div class="form-submit">
    <ng-content select="[footer-tag]"></ng-content>
    <ng-content select="[footer-submit]"></ng-content>
    <ng-content select="[footer-cancel]"></ng-content>
  </div>
</div>
<!-- Emoji popover -->
<ng-template #emojiPopover>
  <app-emoji-keyboard (emoijNotify)="emoijNotify($event)"></app-emoji-keyboard>
</ng-template>
