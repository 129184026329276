<div class="tags-container">
  <span class="tags-container-title">Groups</span>
  <div *ngFor="let grp of assignSubgroups" class="tag-slot">
    <img
      *ngIf="grp?.localThumbnail"
      class="tag-slot-image"
      [src]="grp?.localThumbnail"
      alt="grp?.name"
    />
    <span class="tag-slot-name">{{ grp?.title }}</span>
    <span class="close clickable-cursor" (click)="removeSubgroupEvent(grp?.id)">
      X
    </span>
  </div>
</div>
