import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { AppMgmtService } from '../../+dashboard/app-mgmt/appMgmt.service';
import { LayoutService } from '../layout.service';

import { ISection } from 'src/models/ISection';
import { UISections, ChannelAppScreens } from 'src/models/constants';
import { AuthService } from './../../../core/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent {
  uiSections: typeof UISections = UISections;

  @Input() direction: string;
  @Input() isOpen: boolean;
  @Input() currentScreenNumber: number;
  @Input() progressWeb: string;

  @Output() toggleMenuNotify = new EventEmitter<boolean>();
  @Output() sectionNotify = new EventEmitter<string>();

  constructor(
    public appMgmtService: AppMgmtService,
    public layoutService: LayoutService,
    public authService: AuthService
  ) {}

  toggleMenuEvent() {
    this.toggleMenuNotify.emit(true);
  }

  sectionEvent(section: string, subMenuScreen: number) {
    if (section) {
      this.sectionNotify.emit(section);
      if (
        section === this.uiSections.APP ||
        section === this.uiSections.CHANNEL
      ) {
        this.goToSpecificPage(section, subMenuScreen);
      }
    }
  }

  goToSpecificPage(section: string, screen: number) {
    const progressWebNum = parseInt(this.progressWeb, 10);

    if (screen && progressWebNum <= screen) {
      this.appMgmtService.goToSpecificPage(screen);
    } else {
      if (section === this.uiSections.APP) {
        const selectedScreen = screen ? screen : ChannelAppScreens.UPLOAD_LOGO;
        this.appMgmtService.goToSpecificPage(selectedScreen);
      } else if (section === this.uiSections.CHANNEL) {
        this.appMgmtService.goToSpecificPage(ChannelAppScreens.TABS);
      }
    }
  }

  setSubMenuActive(section: string, screenNumber: number): boolean {
    if (section === this.uiSections.APP) {
      return this.currentScreenNumber === screenNumber;
    } else if (section === this.uiSections.CHANNEL) {
      return this.currentScreenNumber === screenNumber;
    }
    return this.direction === section;
  }

  isActive(section: ISection) {
    return section.subMenuSections.includes(this.direction);
  }

  setItemStyle(section: ISection) {
    const isSectionActive = this.isActive(section);
    if (isSectionActive) {
      return {
        'border-right': `3px solid ${section.symbolColor}`,
        'not-open': !this.isOpen
      };
    }
  }
}
