import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { IChatMember, usersTableColumns } from 'src/models/IChatMember';
import { MembersService } from '../../members/members.service';
import { AbstractTableColumn } from 'src/models/AbstractItem';

@Component({
  selector: 'app-members-assign',
  templateUrl: './members-assign.component.html',
  styleUrls: ['./members-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersAssignComponent implements OnChanges {
  @Input() chatId: string;
  @Input() chatMembers: IChatMember[];
  @Input() stayleOnEdit = false;
  @Input() showButton = true;
  @Input() screenType: string;
  @Input() card = false;
  @Input() admins: string[];
  @Input() inform = false;
  @Output() addNotifiy = new EventEmitter<boolean>();
  @Output() addAdmin = new EventEmitter<any[]>();
  toggelText: boolean;

  searchOptions = [
    {
      key: 0,
      value: 'Search by Name'
    },
    {
      key: 1,
      value: 'Search by Login ID'
    }
  ];

  usersModal = false;
  tableColumns: AbstractTableColumn[] = usersTableColumns;
  selectedMembers: any[];

  constructor(
    public membersService: MembersService,
    private _cdref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.admins && this.admins.length > 0) {
      this.selectedMembers = this.admins.map(res => {
        const item = { id: res };
        return item;
      });
    }
  }

  get chatAdmins$() {
    return this.membersService.getSubChatAdmins$(this.chatId);
  }

  showUsersModalEvent(show: boolean) {
    this.usersModal = show;
    this._cdref.detectChanges();
    this.membersService.resetSelectedItems();
  }

  toggleText(showtext) {
    this.toggelText = showtext;
  }

  setSubChatsEvent() {
    this.membersService.selectedItems.forEach(res => {
      if (this.admins.indexOf(res) <= -1) {
        this.admins.push(res);
      }
    });
    console.log('admins =>', this.admins);
    // this.admins = [...this.admins, ...this.membersService.selectedItems];
    this._cdref.detectChanges();
    if (!this.inform) {
      this.membersService.assignAdminsToChat(this.chatId);
      this.addAdmin.emit(this.membersService.selectedItems);
    } else {
      this.addAdmin.emit(this.membersService.selectedItems);
    }
    this.showUsersModalEvent(false);
    this.toggleText(true);
  }

  TrackByFunction(index, chatMember: IChatMember) {
    return chatMember.user_id;
  }
}
