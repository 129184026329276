/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switch-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/slide-toggle/typings/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/material/slide-toggle";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./switch-btn.component";
var styles_SwitchBtnComponent = [i0.styles];
var RenderType_SwitchBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchBtnComponent, data: {} });
export { RenderType_SwitchBtnComponent as RenderType_SwitchBtnComponent };
export function View_SwitchBtnComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-slide-toggle", [["class", "mat-slide-toggle"]], [[8, "id", 0], [1, "tabindex", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0], [2, "mat-checked", null], [2, "mat-disabled", null], [2, "mat-slide-toggle-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatSlideToggle_0, i2.RenderType_MatSlideToggle)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MatSlideToggle]), i1.ɵdid(2, 1228800, null, 0, i4.MatSlideToggle, [i1.ElementRef, i5.FocusMonitor, i1.ChangeDetectorRef, [8, null], i1.NgZone, i4.MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, [2, i6.ANIMATION_MODULE_TYPE], [2, i7.Directionality]], { disabled: [0, "disabled"], checked: [1, "checked"] }, { change: "change" }), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.isDisabled; var currVal_9 = _co.isOn; _ck(_v, 2, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = (i1.ɵnov(_v, 2).disabled ? null : (0 - 1)); var currVal_2 = null; var currVal_3 = null; var currVal_4 = i1.ɵnov(_v, 2).checked; var currVal_5 = i1.ɵnov(_v, 2).disabled; var currVal_6 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_SwitchBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-switch-btn", [], null, null, null, View_SwitchBtnComponent_0, RenderType_SwitchBtnComponent)), i1.ɵdid(1, 49152, null, 0, i8.SwitchBtnComponent, [], null, null)], null, null); }
var SwitchBtnComponentNgFactory = i1.ɵccf("app-switch-btn", i8.SwitchBtnComponent, View_SwitchBtnComponent_Host_0, { isOn: "isOn", isDisabled: "isDisabled" }, { switchIsOn: "switchIsOn" }, ["*"]);
export { SwitchBtnComponentNgFactory as SwitchBtnComponentNgFactory };
