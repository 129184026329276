export interface AnalyticsKeys {
  category: string;
  label: string;
  action: string;
  value: string;
}

export const SIGN_UP_EVENT = {
  category: 'Registration',
  label: '',
  action: 'Sign Up Clicked',
  value: 1
};

export const ANALYTICS_EVENTS = {
  SIGN_UP_EVENT
};
