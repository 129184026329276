/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./messages-admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../containers/channel-reply/channel-replies.component.ngfactory";
import * as i3 from "../../../../containers/channel-reply/channel-replies.component";
import * as i4 from "../../../../core/ui.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../containers/member-replies/member-replies.component.ngfactory";
import * as i7 from "../../../../containers/member-replies/member-replies.component";
import * as i8 from "../../../../chatbox/chat-box/chat-box.component.ngfactory";
import * as i9 from "../../../../chatbox/chat-box/chat-box.component";
import * as i10 from "../../../../core/helper.service";
import * as i11 from "../../../../previews/preview-media/preview-media.component.ngfactory";
import * as i12 from "../../../../previews/preview-media/preview-media.component";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i15 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i16 from "../message-post-operations/message-post-operations.component.ngfactory";
import * as i17 from "../message-post-operations/message-post-operations.component";
import * as i18 from "./messages-admin.component";
import * as i19 from "../../dashboard.service";
var styles_MessagesAdminComponent = [i0.styles];
var RenderType_MessagesAdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagesAdminComponent, data: {} });
export { RenderType_MessagesAdminComponent as RenderType_MessagesAdminComponent };
function View_MessagesAdminComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-channel-replies", [["class", "body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ChannelRepliesComponent_0, i2.RenderType_ChannelRepliesComponent)), i1.ɵdid(1, 4964352, null, 0, i3.ChannelRepliesComponent, [i4.UiService, i1.ChangeDetectorRef], { message: [0, "message"], chat: [1, "chat"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dashboardService.effectiveParentMessage$)); var currVal_1 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.selectedChat); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MessagesAdminComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-member-replies", [["class", "body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MemberRepliesComponent_0, i6.RenderType_MemberRepliesComponent)), i1.ɵdid(1, 13352960, null, 0, i7.MemberRepliesComponent, [i4.UiService, i1.ChangeDetectorRef], { selectedMessageId: [0, "selectedMessageId"], selectedUserId: [1, "selectedUserId"], selectedChatId: [2, "selectedChatId"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dashboardService.effectiveParentMessage$))) == null) ? null : tmp_0_0.message_id); var currVal_1 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.selectedUserId); var currVal_2 = ((_v.parent.parent.context.ngIf == null) ? null : ((_v.parent.parent.context.ngIf.selectedChat == null) ? null : _v.parent.parent.context.ngIf.selectedChat.id)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MessagesAdminComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-box", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onEnterKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ChatBoxComponent_0, i8.RenderType_ChatBoxComponent)), i1.ɵdid(1, 638976, null, 0, i9.ChatBoxComponent, [i4.UiService, i1.ElementRef, i10.HelperService], { selectedChat: [0, "selectedChat"], selectedMessage: [1, "selectedMessage"], selectedUserId: [2, "selectedUserId"], groupReplies: [3, "groupReplies"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.selectedChat); var currVal_1 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.selectedMessage); var currVal_2 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.selectedUserId); var currVal_3 = ((_v.parent.parent.context.ngIf == null) ? null : _v.parent.parent.context.ngIf.groupReplies); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_MessagesAdminComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesAdminComponent_3)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesAdminComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesAdminComponent_5)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedUserId); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedUserId); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedUserId); _ck(_v, 5, 0, currVal_2); }, null); }
function View_MessagesAdminComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-preview-media", [["class", "body"]], null, [[null, "sendMediaNotfiy"], [null, "click"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onComponentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onkeypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("sendMediaNotfiy" === en)) {
        var pd_2 = (_co.sendReplyMediaPreview(((i1.ɵnov(_v, 1) == null) ? null : i1.ɵnov(_v, 1).getMediaToSend())) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_PreviewMediaComponent_0, i11.RenderType_PreviewMediaComponent)), i1.ɵdid(1, 245760, [["replyMediaPreview", 4]], 0, i12.PreviewMediaComponent, [i1.ElementRef, i13.DomSanitizer, i4.UiService, i10.HelperService], { selectedChat: [0, "selectedChat"], selectedMessage: [1, "selectedMessage"], selectedUserId: [2, "selectedUserId"], files: [3, "files"], messageType: [4, "messageType"], mediaMetadata: [5, "mediaMetadata"], mediaError: [6, "mediaError"], isErrorMessageShown: [7, "isErrorMessageShown"], themeColor: [8, "themeColor"] }, { sendMediaNotfiy: "sendMediaNotfiy" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, 4, 2, "app-border-btn", [["footer-submit", ""], ["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.sendReplyMediaPreview(((i1.ɵnov(_v, 1) == null) ? null : i1.ɵnov(_v, 1).getMediaToSend())) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_BorderBtnComponent_0, i14.RenderType_BorderBtnComponent)), i1.ɵdid(7, 573440, null, 0, i15.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Send "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedChat); var currVal_1 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedMessage); var currVal_2 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.selectedUserId); var currVal_3 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 2).transform(_co.uiService.previewFiles$)); var currVal_4 = i1.ɵunv(_v, 1, 4, i1.ɵnov(_v, 3).transform(_co.uiService.previewMessageType$)); var currVal_5 = i1.ɵunv(_v, 1, 5, i1.ɵnov(_v, 4).transform(_co.uiService.previewMediaMetadata$)); var currVal_6 = i1.ɵunv(_v, 1, 6, i1.ɵnov(_v, 5).transform(_co.uiService.previewMediaError$)); var currVal_7 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.showPopup); var currVal_8 = _co.themeColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_MessagesAdminComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "detail-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-message-post-operations", [["class", "header"]], null, [[null, "backNotify"], [null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backNotify" === en)) {
        var pd_0 = (_co.backFromOneToOneReply() !== false);
        ad = (pd_0 && ad);
    } if (("closeNotify" === en)) {
        var pd_1 = (_co.backFromGroupReplies() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i16.View_MessagePostOperationsComponent_0, i16.RenderType_MessagePostOperationsComponent)), i1.ɵdid(2, 49152, null, 0, i17.MessagePostOperationsComponent, [], { message: [0, "message"], parentId: [1, "parentId"], isNested: [2, "isNested"] }, { closeNotify: "closeNotify", backNotify: "backNotify" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesAdminComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagesAdminComponent_6)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.selectedMessage); var currVal_1 = ((_v.context.ngIf == null) ? null : ((_v.context.ngIf.selectedMessage == null) ? null : _v.context.ngIf.selectedMessage.message_id)); var currVal_2 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.selectedUserId); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !((_v.context.ngIf == null) ? null : _v.context.ngIf.showPreview); _ck(_v, 4, 0, currVal_3); var currVal_4 = (((_v.context.ngIf == null) ? null : _v.context.ngIf.showPreview) && ((_v.context.ngIf == null) ? null : _v.context.ngIf.selectedUserId)); _ck(_v, 6, 0, currVal_4); }, null); }
export function View_MessagesAdminComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessagesAdminComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.uiService.uiCollection$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MessagesAdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages-admin", [], null, null, null, View_MessagesAdminComponent_0, RenderType_MessagesAdminComponent)), i1.ɵdid(1, 114688, null, 0, i18.MessagesAdminComponent, [i4.UiService, i19.DashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessagesAdminComponentNgFactory = i1.ɵccf("app-messages-admin", i18.MessagesAdminComponent, View_MessagesAdminComponent_Host_0, {}, {}, []);
export { MessagesAdminComponentNgFactory as MessagesAdminComponentNgFactory };
