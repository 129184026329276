<!-- <app-form-frame>
  <ng-container ngProjectAs="title">
    Add Media
  </ng-container>
  <ng-container ngProjectAs="desc">
    Upload your media for use in your app.
  </ng-container>
  <ng-container ngProjectAs="[form-body]">
    <app-media-center-uploader-input
      (filesSelectedNotify)="fileSelectedEvent($event)"
      [mimeTypes]="mimeTypes"
    >
    </app-media-center-uploader-input>

    <app-media-center-tabs
      class="media-types"
      [isPhotoMedia]="isPhotoMedia"
      [selectedMediaTab]="mediaCenterType?.type"
      (mediaCenterTypeNotify)="mediaCenterTypeEvent($event)"
    >
    </app-media-center-tabs>
    <app-media-center-uploader-list
      [selectedMediaType]="mediaCenterType?.type"
      (mediaCenterNotify)="mediaCenterEvent($event)"
    >
    </app-media-center-uploader-list>
  </ng-container>
</app-form-frame> -->

<app-popup-setting-frame
  [title]="'Add Media'"
  [desc]="'Upload your media for use in your app.'"
  [hideFooter]="true"
  (closeNotifier)="cancelNotifyClick()"
>
  <div class="media-body">
    <app-media-center-uploader-input
      (filesSelectedNotify)="fileSelectedEvent($event)"
      [mimeTypes]="mimeTypes"
    >
    </app-media-center-uploader-input>

    <app-media-center-tabs
      class="media-types"
      [isPhotoMedia]="isPhotoMedia"
      [selectedMediaTab]="mediaCenterType?.type"
      (mediaCenterTypeNotify)="mediaCenterTypeEvent($event)"
    >
    </app-media-center-tabs>
    <app-media-center-uploader-list
      [selectedMediaType]="mediaCenterType?.type"
      (mediaCenterNotify)="mediaCenterEvent($event)"
    >
    </app-media-center-uploader-list>
  </div>
</app-popup-setting-frame>
