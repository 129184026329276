import { Action } from '@ngrx/store';
import { BlackList } from 'src/models/BlackListsUI';

export const enum BlacklistPatternsUIActionTypes {
  UI_BLACKLIST_PATTERNS_RESET_PAGING = 'UI_BLACKLIST_PATTERNS_RESET_PAGING',
  UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE = 'UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE',
  UI_BLACKLIST_PATTERNS_GET_PREV_PAGE = 'UI_BLACKLIST_PATTERNS_GET_PREV_PAGE',
  UI_ADD_BLACKLIST_PATTERNS = 'UI_ADD_BLACKLIST_PATTERNS',
  UI_BLACKLIST_PATTERNS_SET_IDLE_STATUS = 'UI_BLACKLIST_PATTERNS_SET_IDLE_STATUS',
  UI_BLACKLIST_PATTERNS_RECEIVED_HISTORY_CHUNK = 'UI_BLACKLIST_PATTERNS_RECEIVED_HISTORY_CHUNK',
  UI_DELETE_PATTERNS_FROM_BLACKLIST = 'UI_DELETE_PATTERNS_FROM_BLACKLIST',
  DELETE_PATTERNS_FROM_BLACKLIST_SUCCESS = 'DELETE_PATTERNS_FROM_BLACKLIST_SUCCESS',
  RESET_BLACKLIST_PATTERN = 'RESET_BLACKLIST_PATTERN'
}

export class ResetBlackListPatternsPaging implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_RESET_PAGING;
}

export class GetNextBlacklistPatternsPage implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevBlacklistPatternsPage implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedBlacklistPatternsHistoryChunk implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: [],
    public hash: string
  ) {}
}

export class AddPatternToBlacklist implements Action {
  readonly type = BlacklistPatternsUIActionTypes.UI_ADD_BLACKLIST_PATTERNS;
  constructor(
    public data: BlackList[],
    public mainChatID: string,
    public reference?: string,
    public recordIndex?: number
  ) {}
}

export class DeletePatternFromBlacklist implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_DELETE_PATTERNS_FROM_BLACKLIST;
  constructor(public data: string[], public mainChatID: string) {}
}

export class DeletePatternFromBlacklistSuccess implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.DELETE_PATTERNS_FROM_BLACKLIST_SUCCESS;
  constructor(public data: string[]) {}
}

export class SetStatusAsIdle implements Action {
  readonly type =
    BlacklistPatternsUIActionTypes.UI_BLACKLIST_PATTERNS_SET_IDLE_STATUS;
}

export class Reset implements Action {
  readonly type = BlacklistPatternsUIActionTypes.RESET_BLACKLIST_PATTERN;
}

export type Actions =
  | ResetBlackListPatternsPaging
  | GetNextBlacklistPatternsPage
  | GetPrevBlacklistPatternsPage
  | ReceivedBlacklistPatternsHistoryChunk
  | AddPatternToBlacklist
  | DeletePatternFromBlacklist
  | DeletePatternFromBlacklistSuccess
  | SetStatusAsIdle
  | Reset;
