<ng-container *ngIf="!showLoginScreen">
  <div class="change-password">
    <div class="change-password-header">
      Reset Your Password
    </div>
    <div class="change-password-title">
      Please enter the reset code we sent to your email
      {{ accountEmail }}
    </div>

    <form
      form-body
      class="change-password-form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <!-- TAC Filed -->
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Reset Code"
            type="text"
            formControlName="tac"
            autocomplete="off"
            cdkAutofill="off"
          />
        </mat-form-field>

        <app-validation-errors
          class="change-password-error"
          *ngIf="tac?.errors && tac?.touched"
          [fieldName]="'Reset Code'"
          [fieldErrors]="tac?.errors"
        >
        </app-validation-errors>
      </div>

      <!-- New Password Filed -->
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Enter New Password"
            type="password"
            formControlName="newPassword"
            autocomplete="new-password"
          />
        </mat-form-field>
        <app-validation-errors
          class="change-password-error"
          *ngIf="newPassword?.errors && newPassword?.touched"
          [fieldName]="'Password'"
          [fieldErrors]="newPassword?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Retype New Password"
            type="password"
            formControlName="confirmNewPassword"
            autocomplete="off"
            cdkAutofill="off"
          />
        </mat-form-field>
        <app-validation-errors
          class="change-password-error"
          *ngIf="confirmNewPassword?.errors && confirmNewPassword?.touched"
          [fieldName]="'Password'"
          [fieldErrors]="confirmNewPassword?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-submit">
        <app-border-btn next invert lg [type]="'submit'">
          Reset Password <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
      </div>
    </form>
    <div class="reset-password-form">
      <div>Didn't receive the reset code?</div>
      <div>Please check your junk/spam folder or click to resend code</div>
      <app-link-btn (btnClicked)="reSendCode()">
        <ng-container ngProjectAs="link-content-clicked">
          Resend Code
        </ng-container>
      </app-link-btn>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showLoginScreen">
  <app-login [newEmail]="accountEmail"></app-login>
</ng-container>
