<div class="tags-container" *ngIf="assignTags?.length > 0">
  <span *ngIf="showLabel" class="tags-container-title">Tags</span>
  <div *ngFor="let tag of assignTags" class="tag-slot">
    <span style="padding: 15px 10px 15px 0;">{{ tag?.name }}</span>

    <svg
      class="svg-icon icon-icon clickable-cursor"
      (click)="removeTagEvent(tag)"
    >
      <use xlink:href="./assets/svg/app-sections.svg#icon-trash"></use>
    </svg>
    <!-- <span class="close clickable-cursor" (click)="removeTagEvent(tag)">X </span> -->
  </div>
</div>

<div class="tags-container-empty" *ngIf="assignTags?.length === 0">
  <p>There are no tags for this user</p>
</div>
