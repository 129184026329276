import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';

import { SharedModule } from '../shared/shared.module';

import { UploadFileBtnComponent } from './upload-file-btn/upload-file-btn.component';
import { UpldImgWithTitleComponent } from './upld-img-with-title/upld-img-with-title.component';

@NgModule({
  imports: [CommonModule, SharedModule, ImageCropperModule],
  declarations: [UpldImgWithTitleComponent, UploadFileBtnComponent],
  exports: [UpldImgWithTitleComponent, UploadFileBtnComponent]
})
export class AbstractUploadModule {}
