import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SubChatFormComponent } from './sub-chat-form/sub-chat-form.component';
import { SubChatFormImageComponent } from './sub-chat-form-image/sub-chat-form-image.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { CellsModule } from 'src/app/cells/cells.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractIconsModule } from 'src/app/abstract-icons/abstract-icons.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';
import { AbstractUploadModule } from 'src/app/abstract-upload/abstract-upload.module';
import { MembersPoolModule } from '../members-pool/members-pool.module';
import { LocationsPoolModule } from '../locations-pool/locations-pool.module';
import { MarketPoolModule } from '../market-pool/market-pool.module';
import { AbstractTooltip } from 'src/app/abstract-tooltip/abstract-tooltip.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CellsModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractIconsModule,
    AbstractFramesModule,
    AbstractControlsModule,
    AbstractUploadModule,
    MembersPoolModule,
    LocationsPoolModule,
    MarketPoolModule,
    AbstractTooltip,
    AccessorsModule
  ],
  declarations: [SubChatFormComponent, SubChatFormImageComponent],
  exports: [SubChatFormComponent, SubChatFormImageComponent]
})
export class SubChatFormModule {}
