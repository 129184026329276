import { ValidatorFn, FormControl } from '@angular/forms';
import {
  MAX_GROUP_DESCRIPTION_LENGTH,
  MAX_PAYMENT_DESCRIPTION_LENGTH
} from 'src/models/constants';

// custom validator to check that two fields match
export const groupDescValidator: ValidatorFn = (control: FormControl) => {
  if (
    control &&
    control.value &&
    control.value.length >= MAX_GROUP_DESCRIPTION_LENGTH
  ) {
    return { groupDesc: true, maxLength: MAX_GROUP_DESCRIPTION_LENGTH };
  }
  return null;
};

export const paymentDescValidator: ValidatorFn = (control: FormControl) => {
  if (
    control &&
    control.value &&
    control.value.length >= MAX_PAYMENT_DESCRIPTION_LENGTH
  ) {
    return { groupDesc: true, maxLength: MAX_PAYMENT_DESCRIPTION_LENGTH };
  }
  return null;
};
