<app-headerless-closable-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">
        Add Sticky Note ( Title and description )
      </ng-container>

      <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-reactive-text-input
          [filedType]="'text'"
          [filedLabel]="'Title'"
          [fieldId]="'titleFiled'"
          [control]="form?.get('title')"
        >
        </app-reactive-text-input>
        <app-reactive-text-input
          [filedType]="'text'"
          [filedLabel]="'Description'"
          [fieldId]="'descriptionFiled'"
          [control]="form?.get('description')"
        >
        </app-reactive-text-input>

        <div class="form-group">
          <div class="form-submit">
            <app-border-btn submit [type]="'submit'">
              Send
            </app-border-btn>
          </div>
        </div>
      </form>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame>
