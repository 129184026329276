<div class="selectable-cell">
  <!-- Image -->
  <!-- In case no background image-->
  <ng-container *ngIf="!coverPhoto">
    <div class="selectable-cell-img" [style.background]="coverColor">
      <svg class="svg-icon icon-icon">
        <use
          attr.xlink:href="./assets/svg/app-sections.svg#{{ symbolImage }}"
        ></use>
      </svg>
    </div>
  </ng-container>

  <!-- In case coverPhoto-->
  <ng-container *ngIf="coverPhoto">
    <div class="selectable-cell-img">
      <img [src]="coverPhoto" alt="" />
    </div>
  </ng-container>
  <!--------------------------------------->

  <!-- Symbol -->
  <div class="selectable-cell-symbol" [style.background]="symbolColor">
    <svg class="svg-icon icon-icon">
      <use
        attr.xlink:href="./assets/svg/app-sections.svg#{{ symbolImage }}"
      ></use>
    </svg>
  </div>

  <!-- Title & desc -->
  <div class="selectable-cell-title truncate-text" [innerHTML]="title"></div>
  <div *ngIf="desc" class="selectable-cell-details" [innerHTML]="desc"></div>
  <div *ngIf="upcCode" class="selectable-cell-upc">
    <ngx-barcode
      [bc-value]="upcCode"
      [bc-display-value]="true"
      [bc-font-size]="12"
      [bc-element-type]="'img'"
      [bc-background]="'transparent'"
      [bc-width]="1"
      [bc-height]="20"
      [bc-margin]="0"
      [bc-margin-top]="0"
      [bc-margin-bottom]="0"
      [bc-margin-right]="0"
      [bc-margin-left]="0"
    >
    </ngx-barcode>
  </div>

  <!-- checkbox -->
  <div class="selectable-cell-checkbox">
    <input
      type="checkbox"
      [id]="selectorId"
      [checked]="isSelected"
      (change)="checkChangedEvent($event)"
    />
    <label [for]="selectorId"></label>
  </div>
</div>
