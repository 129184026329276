import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import { MStore, MStoreItem } from 'src/models/Stores';
import * as StoresUIAction from './storesUI.actions';

@Injectable({
  providedIn: 'root'
})
export class StoreUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createStore(storeDetails: MStore): void {
    this._store.dispatch(new StoresUIAction.CreateStore(storeDetails));
  }

  getStore(storeId: string): void {
    this._store.dispatch(new StoresUIAction.GetStore(storeId));
  }

  setStore(storeID: string, storeDetails: MStore): void {
    this._store.dispatch(new StoresUIAction.SetStore(storeID, storeDetails));
  }

  addStoreItem(storeID: string, itemIDs: MStoreItem[]): void {
    this._store.dispatch(new StoresUIAction.AddStoreItem(storeID, itemIDs));
  }

  assignStoreChannel(storeID: string, chatIds: any[]): void {
    this._store.dispatch(
      new StoresUIAction.AssignStoreChannel(storeID, chatIds)
    );
  }

  removeStoreItem(storeID: string, itemIDs: any[]): void {
    this._store.dispatch(new StoresUIAction.RemoveStoreItem(storeID, itemIDs));
  }

  deleteStoreMobile(id: string): void {
    this._store.dispatch(new StoresUIAction.DeleteStore(id));
  }
  storeMobileDeleted(id: string): void {
    this._store.dispatch(new StoresUIAction.StoreDelete(id));
  }
  // paging dispatchers
  getNextStorePage(): void {
    this._store.dispatch(new StoresUIAction.GetNextStorePage());
  }

  getPrevStorePage(): void {
    this._store.dispatch(new StoresUIAction.GetPrevStorePage());
  }

  receivedStoreHistoryChunk(
    eop: number,
    sop: number,
    currentPage: MStore[]
  ): void {
    this._store.dispatch(
      new StoresUIAction.ReceivedStoreHistoryChunk(eop, sop, currentPage)
    );
  }

  storeReceived(pkg: MStore) {
    this._store.dispatch(new StoresUIAction.StoreReceived(pkg));
  }

  resetStorePaging(): void {
    this._store.dispatch(new StoresUIAction.ResetStorePaging());
  }

  setStoreSelectedChat(selected_chat: string = null): void {
    this._store.dispatch(
      new StoresUIAction.SetStoreSelectedChat(selected_chat)
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new StoresUIAction.SetStatusAsIdle());
  }
}
