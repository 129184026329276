/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lightbox-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lightbox-container.component";
var styles_LightboxContainerComponent = [i0.styles];
var RenderType_LightboxContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LightboxContainerComponent, data: {} });
export { RenderType_LightboxContainerComponent as RenderType_LightboxContainerComponent };
export function View_LightboxContainerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "lightbox-modal-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "lightbox-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "close"], ["class", "clickable-cursor"], ["src", "/assets/img/lightbox-close.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "lightbox-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "lightbox-modal-video"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleHeader; _ck(_v, 3, 0, currVal_0); }); }
export function View_LightboxContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lightbox-container", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LightboxContainerComponent_0, RenderType_LightboxContainerComponent)), i1.ɵdid(1, 114688, null, 0, i2.LightboxContainerComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LightboxContainerComponentNgFactory = i1.ɵccf("app-lightbox-container", i2.LightboxContainerComponent, View_LightboxContainerComponent_Host_0, { titleHeader: "titleHeader", videoHeader: "videoHeader" }, { cancelNotfiy: "cancelNotfiy" }, ["*"]);
export { LightboxContainerComponentNgFactory as LightboxContainerComponentNgFactory };
