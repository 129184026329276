import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MessageTypes } from 'src/models/constants';

@Component({
  selector: 'app-media-center-tabs',
  templateUrl: './media-center-tabs.component.html',
  styleUrls: ['./media-center-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterTabsComponent {
  @Input() selectedMediaTab: string;
  @Input() isPhotoMedia: boolean;
  @Output() mediaCenterTypeNotify = new EventEmitter<string>();

  messageTypes: typeof MessageTypes = MessageTypes;

  mediaCenterTypeEvent(e: string) {
    this.mediaCenterTypeNotify.emit(e);
  }
}
