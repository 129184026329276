/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reactive-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reactive-table.component";
var styles_ReactiveTableComponent = [i0.styles];
var RenderType_ReactiveTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReactiveTableComponent, data: {} });
export { RenderType_ReactiveTableComponent as RenderType_ReactiveTableComponent };
export function View_ReactiveTableComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(8, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), i1.ɵncd(null, 3), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), i1.ɵncd(null, 4), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), i1.ɵncd(null, 5)], null, null); }
export function View_ReactiveTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reactive-table", [], null, null, null, View_ReactiveTableComponent_0, RenderType_ReactiveTableComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReactiveTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReactiveTableComponentNgFactory = i1.ɵccf("app-reactive-table", i2.ReactiveTableComponent, View_ReactiveTableComponent_Host_0, {}, {}, ["header-table-first", "header-table-tow", "header-table-three", "first-cell", "second-cell", "third-cell"]);
export { ReactiveTableComponentNgFactory as ReactiveTableComponentNgFactory };
