import { IChat } from './IChat';

import {
  TAGS_METHOD,
  ADD_TAGS_METHOD,
  DELETE_TAGS_METHOD,
  SET_CHAT_MEMBER_TAGS_METHOD
} from './constants';

export interface ITag {
  id?: string;
  name?: string;
  isPrivate?: number;
  description?: string;
  createdDate?: string;
  selected?: boolean;
  recordIndex?: number;
}

export interface ReceivedTagsMessage {
  method?: string;
  chat?: IChat;
}

export class GetTags {
  readonly method = TAGS_METHOD;
}
export class SetTags {
  readonly method = ADD_TAGS_METHOD;
  constructor(public tagsDefinition: ITag[], public recordIndex?: number) {}
}
export class DeleteTags {
  readonly method = DELETE_TAGS_METHOD;
  constructor(public tagsDefinition: string[]) {}
}
export class SetMemberTags {
  readonly method = SET_CHAT_MEMBER_TAGS_METHOD;
  constructor(public user_id: string, public tags: string[] | number) {}
}

export type TagMethods = GetTags | SetTags | DeleteTags | SetMemberTags;
