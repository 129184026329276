import {
  REMOVE_APP_RELEASE_METHOD,
  LIST_APP_RELEASE_METHOD,
  LIST_APP_RELEASE_ID_METHOD
} from './constants';

export interface AppRelease {
  id?: number;
  date?: number;
  reference?: string;
  os?: number; // 0 means android so the Type should be APK so if 1 the type should be IPA
  domain?: string;
  version?: string;
  releaseNote?: string;
  coreVersion?: string;
  nandboxCoreVersion?: string;
  url?: string;
  status?: string;
  showDetails?: boolean;
  screenLayout?: string;
  targetSdk?: string;
  nativePlatforms?: string;
  apiLevel?: string;
  type?: string;
  duration?: number;
  publish_id?: string;
  app_name?: string;
  version_name?: string;
  version_desc?: string;
  error_msg?: string;
  android_format?: string;
  has_error_msg?: number; // 0 or 1
}

export class ListAppRelease {
  readonly method = LIST_APP_RELEASE_METHOD;
  constructor() {}
}

export class ListAppReleaseId {
  readonly method = LIST_APP_RELEASE_ID_METHOD;
  constructor(public id: number) {}
}

export class RemoveAppRelease {
  readonly method = REMOVE_APP_RELEASE_METHOD;
  constructor(public id: number) {}
}

export type AppReleasesMethods =
  | ListAppRelease
  | ListAppReleaseId
  | RemoveAppRelease;
