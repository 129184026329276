<app-headerfull-frame
  action
  [title]="'Your billing account'"
  [showBack]="true"
  (cancelNotfiy)="closeEvent()"
  (backNotfiy)="backEvent()"
>
  <div class="plan-confirm-header">
    <span>Selected Plan</span>
    <span>
      See full plan details
      <app-link-btn [href]="plan?.url">
        <ng-container ngProjectAs="link-content">
          here
        </ng-container>
      </app-link-btn>
    </span>
  </div>
  <div class="plan-confirm-selected" [style.background]="plan?.mainColor">
    <div class="plan-header">
      <div class="name">
        {{ plan?.title }}
      </div>
      <div class="tagline">
        {{ plan.tagline }}
      </div>
    </div>
    <div class="plan-rate">
      {{ (plan?.options)[1] }}
    </div>
  </div>
  <div class="plan-confirm-notice">
    Projects are shared between nandbox and Google Cloud Platform. To upgrade
    your project, you will be taken to the second step to create your billing
    account, Which is the payment profile for your APP.
  </div>
  <div class="form-submit">
    <app-border-btn
      [disabled]="!url"
      (btnClicked)="goToPaymentPage()"
      [style.color]="plan?.mainColor"
    >
      Start Free Trial
    </app-border-btn>
  </div>
</app-headerfull-frame>
