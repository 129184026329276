import { ComponentTypes } from 'src/models/constants';

export const HELP_SETTINGS_CONTAINER = `{
	"container_type": "help",
	"icon": "ic_help_24dp",
	"title": "Help",
	"key": "@+id/pref_header_help",
	"component": [
		{
			"component_type": "about",
			"key": "about",
			"url": "https://nandbox.com",
			"title": "about"
		},
		{
			"component_type": "faq",
			"title": "FAQ",
			"key": "faq"
		}
	]
}`;
export const ACCOUNT_SETTINGS_CONTAINER = `{
	"container_type": "account",
	"icon": "ic_baseline_vpn_key_24dp",
	"title": "Account",
	"key": "@+id/pref_header_account",
	"component": [
		{
			"component_type": "privacy",
			"title": "Privacy",
			"key": "account_privacy",
			"item": [
				{
					"link": "lastseen",
					"title": "Last Seen",
					"key": "account_last_seen",
					"desc": "if you don't share your last seen status, you won't be able to see others people's last seen statues"
				},
				{
					"link": "readreceipts",
					"title": "Read Receipts",
					"key": "account_read_receipts",
					"desc": "if you turn off read receipts, you won't be able to see read receipts from others people."
				},
				{
					"link": "changenumber",
					"title": "Change Number",
					"key": "current_number",
					"desc": "if you turn off read receipts, you won't be able to see read receipts from others people."
				},
				{
					"link": "changelanguage",
					"title": "Change Language",
					"key": "account_language",
					"data": [
						{
							"title": "English",
							"value": "en"
						},
						{
							"title": "العربيه",
							"value": "ar"
						},
						{
							"title": "Deutsch",
							"value": "de"
						},
						{
							"title": "Espanol",
							"value": "es"
						},
						{
							"title": "Francais",
							"value": "fr"
						}
					]
				},
				{
					"link": "event",
					"title": "Synchronize events",
					"key": "sync_events",
					"desc": "Synchronize events with system calendar"
				}
			]
		}
	]
}`;
export const CHATS_SETTINGS_CONTAINER = `{
	"container_type": "chats",
	"icon": "ic_chats_24dp",
	"title": "Chats",
	"key": "@+id/pref_header_chat_settings",
	"component": [
		{
			"component_type": "send",
			"title": "Tap enter to send.",
			"key": "chats_enter_is_send"
		},
		{
			"component_type": "gif",
			"title": "Tap enter to send.",
			"key": "chats_auto_play_gif"
		},
		{
			"component_type": "chat_history",
			"title": "Chat History",
			"key": "pref_chat_history",
			"item": [
				{
					"link": "deletechat",
					"title": "Delete all chats",
					"key": "pref_delete_all_chats"
				}
			]
		}
	]
}`;
export const MEDIA_SETTINGS_CONTAINER = `{
	"container_type": "media",
	"icon": "ic_perm_media_24dp",
	"title": "Media auto download",
	"key": "@+id/pref_header_media_auto_download",
	"component": [
		{
			"component_type": "personal_message",
			"title": "Peronsal Messaging",
			"key": "pref_screen_contact_media_auto_download",
			"item": [
				{
					"link": "priority",
					"title": "Priority Contacts",
					"key": "media_download_priority_contacts",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_contact_fav_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_contact_fav_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_contact_fav_roaming"
						}
					]
				},
				{
					"link": "contact",
					"title": "Contacts",
					"key": "media_download_contacts",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_contact_other_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_contact_other_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_contact_other_roaming"
						}
					]
				}
			]
		},
		{
			"component_type": "group_message",
			"title": "Group Messaging",
			"key": "pref_screen_group_media_auto_download",
			"item": [
				{
					"link": "priority",
					"title": "Priority Group",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_group_fav_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_group_fav_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_group_fav_roaming"
						}
					]
				},
				{
					"link": "group",
					"title": "Groups",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_group_other_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_group_other_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_group_other_roaming"
						}
					]
				}
			]
		},
		{
			"component_type": "channel_message",
			"title": "Channel Messaging",
			"key": "pref_screen_channel_media_auto_download",
			"item": [
				{
					"link": "priority",
					"title": "Priority channels",
					"key": "media_download_priority_channels",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_channel_fav_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_channel_fav_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_channel_fav_roaming"
						}
					]
				},
				{
					"link": "channel",
					"title": "Channels",
					"key": "media_download_channels",
					"sub_item": [
						{
							"title": "When using mobile data",
							"link": "mobile",
							"key": "media_download_channel_other_3g"
						},
						{
							"title": "When connected to WIFI",
							"link": "wifi",
							"key": "media_download_channel_other_wifi"
						},
						{
							"title": "When roaming",
							"link": "roaming",
							"key": "media_download_channel_other_roaming"
						}
					]
				}
			]
		}
	]
}`;
export const NOTIFICATION_SETTINGS_CONTAINER = `{
	"container_type": "notification",
	"icon": "ic_baseline_notifications_24dp",
	"title": "Notifications",
	"key": "@+id/pref_header_notifications",
	"component": [
		{
			"component_type": "personal_message",
			"title": "Peronsal Messaging",
			"key": "pref_contact_notifications_settings",
			"item": [
				{
					"link": "priority",
					"title": "Priority Contacts",
					"key": "notifications_priority_contacts",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_contact_fav_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_contact_fav_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_contact_fav_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_contact_fav_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_contact_fav_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_contact_fav_ringtone"
						}
					]
				},
				{
					"link": "contact",
					"title": "Contacts",
					"key": "notifications_contacts",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_contact_other_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_contact_other_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_contact_other_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_contact_other_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_contact_other_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_contact_other_ringtone"
						}
					]
				}
			]
		},
		{
			"component_type": "group_message",
			"title": "Group Messaging",
			"key": "pref_group_notifications_settings",
			"item": [
				{
					"link": "priority",
					"title": "Priority Contacts",
					"key": "notifications_priority_groups",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_group_fav_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_group_fav_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_group_fav_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_group_fav_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_group_fav_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_group_fav_ringtone"
						}
					]
				},
				{
					"link": "group",
					"title": "Groups",
					"key": "notifications_groups",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_group_other_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_group_other_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_group_other_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_group_other_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_group_other_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_group_other_ringtone"
						}
					]
				}
			]
		},
		{
			"component_type": "channel_message",
			"title": "Channel Messaging",
			"key": "pref_channel_notifications_settings",
			"item": [
				{
					"link": "priority",
					"title": "Priority Contacts",
					"key": "notifications_priority_channels",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_channel_fav_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_channel_fav_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_channel_fav_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_channel_fav_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_channel_fav_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_channel_fav_ringtone"
						}
					]
				},
				{
					"link": "channel",
					"title": "Channels",
					"key": "notifications_channels",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_group_channel_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_channel_other_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_channel_other_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_channel_other_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_channel_other_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_channel_other_ringtone"
						}
					]
				}
			]
		},
		{
			"component_type": "map_message",
			"title": "Map Messaging",
			"key": "pref_map_notifications_settings",
			"item": [
				{
					"link": "priority",
					"title": "Priority Map",
					"key": "notifications_priority_map",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_map_fav_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_map_fav_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_map_fav_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_map_fav_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_map_fav_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_map_fav_ringtone"
						}
					]
				},
				{
					"link": "map",
					"title": "Map",
					"key": "notifications_map",
					"sub_item": [
						{
							"title": "Incoming message alert",
							"link": "incoming_alert",
							"key": "notification_map_other_incoming_alert"
						},
						{
							"title": "Outgoing message alert",
							"link": "outgoing_alert",
							"key": "notification_map_other_outgoing_alert"
						},
						{
							"title": "Vibration",
							"link": "vibration",
							"key": "notification_map_other_vibrate_state"
						},
						{
							"title": "Light",
							"link": "light",
							"key": "notification_map_other_led",
							"data": [
								{
									"title": "None",
									"value": ""
								},
								{
									"title": "White",
									"value": "#FFFFFFFF"
								},
								{
									"title": "Red",
									"value": "#FFFF0000"
								},
								{
									"title": "Yellow",
									"value": "#FFFFFF00"
								},
								{
									"title": "Green",
									"value": "#FF00FF00"
								},
								{
									"title": "Blue",
									"value": "#FF0000FF"
								}
							]
						},
						{
							"title": "Popup Notifications",
							"link": "popup",
							"key": "notification_map_other_popup"
						},
						{
							"title": "Ringtones",
							"link": "ring",
							"key": "notification_map_other_ringtone"
						}
					]
				}
			]
		}
	]
}`;

export const TAB_TEMPLATE = `{
	"container_type": "tab",
	"type": "feed",
	"icon": "ic_feeds_books_white_24_dp",
	"title": "FEED",
	"options": "0",
	"component": [
		{
			"type": "wide",
			"opened": 1,
			"component_type": "post",
			"sticky_id": 1,
			"item": [],
			"sticky_visible": 0,
			"column": 1,
			"sticky_text_color": "#ffffff",
			"sticky_bg": "#ff2121"
		},
		{
			"component_type": "footer",
			"opened": 1,
			"color": "",
			"bg": "",
			"type": "classic",
			"icon": "icon1",
			"item": [
				{
					"icon": "ic_unlike_heart",
					"link": "like",
					"color_highlight": "#F20000",
					"icon_highlight": "ic_like_heart"
				},
				{
					"icon": "ic_comment",
					"link": "reply"
				},
				{
					"icon": "ic_view",
					"link": "view"
				},
				{
					"icon": "ic_share",
					"link": "share"
				}
			]
		},
		{
			"component_type": "menu",
			"opened": 1,
			"item": [
        {
          "disabled": false,
			    "icon": "ic_outline_share_24dp",
			    "link": "share",
			    "title": "Share"
		    }, {
          "disabled": false,
			    "icon": "ic_baseline_forward_24dp",
			    "link": "forward",
			    "title": "Forward"
		    }, {
          "disabled": false,
			    "icon": "ic_copy_24dp",
			    "link": "copy",
			    "title": "Copy"
		    }, {
          "disabled": false,
			    "icon": "ic_baseline_edit_24dp",
			    "link": "edit",
			    "title": "Edit"
		    }, {
          "disabled": false,
			    "icon": "icon-image-gallery-1",
			    "link": "save_to_gallery",
			    "title": "Save to Gallery"
		    }, {
          "disabled": false,
			    "icon": "icon-musical-note",
			    "link": "save_to_music",
			    "title": "Save to Music"
		    }, {
          "disabled": false,
			    "icon": "icon-download-to-storage-drive-1",
			    "link": "save_to_downloads",
			    "title": "Save to Downloads"
		    }, {
          "disabled": false,
			    "icon": "ic_report_abuse_24dp",
			    "link": "abuse",
			    "title": "Report abuse"
		    }, {
          "disabled": false,
			    "icon": "ic_baseline_delete_24dp",
			    "link": "delete",
			    "title": "Delete"

		    }
		  ]
		},
		{
			"component_type": "wallet",
			"opened": 1,
			"title": "J2 VAPP",
			"desc": "Achieve your goal",
			"target": 40
		},
		{
			"component_type": "card",
			"opened": 1,
			"title": "Membership Card",
			"desc": "Any text goes here",
			"image": "base64",
			"image_url": ""
		},
		{
			"component_type": "web_view",
			"wv_url": "https://nandbox.com/"
		},
		{
			"component_type": "tag"
		},
		{
			"component_type": "invitation_accept",
			"title": "Accept",
			"link": "accept"
		},
		{
			"component_type": "invitation_cancel",
			"bg": "#FFFFFF",
			"title": "Reject",
			"link": "cancel"
		},
		{
			"component_type": "search",
			"search_btn_icon_color": "#FFFFFF",
			"search_btn_icon": "ic_baseline_search_24dp",
			"search_text_hint": "What are you searching for?",
			"max_items": 3,
			"type": [],
			"item": []
		}
	]
}`;

// used by the function that add missing components to the added tab
export const POST_COMPONENT = `{
	"component_type": "post",
	"type": "wide",
	"opened": 1,
	"sticky_id": 1,
	"item": [],
	"sticky_visible": 0,
	"column": 1,
	"sticky_text_color": "#ffffff",
	"sticky_bg": "#ff2121"
}`;

export const FOOTER_COMPONENT = `{
	"component_type": "footer",
	"opened": 1,
	"color": "",
	"bg": "",
	"type": "classic",
	"icon": "icon1",
	"item": [{
			"icon": "ic_unlike_heart",
			"link": "like",
			"color_highlight": "#F20000",
			"icon_highlight":"ic_like_heart"
		}, {
			"icon": "ic_comment",
			"link": "reply"
		}, {
			"icon": "ic_view",
			"link": "view"
		}, {
			"icon": "ic_share",
			"link": "share"
		}
	]
}`;

const MENU_COMPONENT = `{
	"component_type": "menu",
	"opened": 1,
	"item": [
    {
      "disabled": false,
			"icon": "ic_outline_share_24dp",
			"link": "share",
			"title": "Share"
		}, {
      "disabled": false,
			"icon": "ic_baseline_forward_24dp",
			"link": "forward",
			"title": "Forward"
		}, {
      "disabled": false,
			"icon": "ic_copy_24dp",
			"link": "copy",
			"title": "Copy"

		}, {
      "disabled": false,
			"icon": "ic_baseline_edit_24dp",
			"link": "edit",
			"title": "Edit"
		}, {
      "disabled": false,
			"icon": "icon-image-gallery-1",
			"link": "save_to_gallery",
			"title": "Save to Gallery"
		}, {
      "disabled": false,
			"icon": "icon-musical-note",
			"link": "save_to_music",
			"title": "Save to Music"
		}, {
      "disabled": false,
			"icon": "icon-download-to-storage-drive-1",
			"link": "save_to_downloads",
			"title": "Save to Downloads"
		}, {
      "disabled": false,
			"icon": "ic_report_abuse_24dp",
			"link": "abuse",
			"title": "Report abuse"
		}, {
      "disabled": false,
			"icon": "ic_baseline_delete_24dp",
			"link": "delete",
			"title": "Delete"

		}
	]
}`;

const WALLET_COMPONENT = `{
	"component_type": "wallet",
	"opened": 1,
	"title": "J2 VAPP",
	"desc": "Achieve your goal",
	"target": 40
}`;

const CARD_COMPONENT = `{
	"component_type": "card",
	"opened": 1,
	"title": "Membership Card",
	"desc": "Any text goes here",
	"image": "base64",
	"image_url": ""
}`;

const WEB_VIEW_COMPONENT = `{
	"component_type": "web_view",
	"wv_url": "https://nandbox.com/"
}`;

const TAG_COMPONENT = `{
	"component_type": "tag"
}`;

const INVITATION_ACCEPT_COMPONENT = `{
	"component_type": "invitation_accept",
	"title": "Accept",
	"link": "accept"
}`;

const INVITATION_CANCEL_COMPONENT = `{
	"component_type": "invitation_cancel",
	"bg": "#FFFFFF",
	"title": "Reject",
	"link": "cancel"
}`;

export const SEARCH_COMPONENT = `{
	"component_type": "search",
	"search_btn_icon_color": "#FFFFFF",
	"search_btn_icon": "ic_baseline_search_24dp",
	"search_text_hint": "What are you searching for?",
	"max_items": 3,
	"type": [],
	"item": []
}`;

export const MAP_SEARCH_COMPONENT = `{
	"component_type": "map_search",
    "nearby": "map_center",
    "radius": "2.0",
    "item": [{
            "link": "mstore",
            "title": "Order",
            "bg": "#F03A52"
        }, {
            "link": "chat",
            "title": "Open",
            "bg": "#F03A52"
        }
    ]
}`;

interface ComponentMap {
  key: ComponentTypes;
  value: string;
}

export const TAB_COMPONENT_KEYS: ComponentMap[] = [
  { key: ComponentTypes.POST, value: POST_COMPONENT },
  { key: ComponentTypes.FOOTER, value: FOOTER_COMPONENT },
  { key: ComponentTypes.MENU, value: MENU_COMPONENT },
  { key: ComponentTypes.WALLET, value: WALLET_COMPONENT },
  { key: ComponentTypes.CARD, value: CARD_COMPONENT },
  { key: ComponentTypes.WEB_VIEW, value: WEB_VIEW_COMPONENT },
  { key: ComponentTypes.TAG, value: TAG_COMPONENT },
  { key: ComponentTypes.INVITATION_ACCEPT, value: INVITATION_ACCEPT_COMPONENT },
  { key: ComponentTypes.INVITATION_CANCEL, value: INVITATION_CANCEL_COMPONENT },
  { key: ComponentTypes.SEARCH, value: SEARCH_COMPONENT },
  { key: ComponentTypes.MAP_SEARCH, value: MAP_SEARCH_COMPONENT }
];

// Settings Components
const ABOUT_COMPONENT = `{
	"component_type": "about",
	"key": "about",
	"url": "https://nandbox.com",
	"title": "about"
}`;

const FAQ_COMPONENT = `{
	"component_type": "faq",
	"title": "FAQ",
	"key": "faq"
}`;

const PRIVACY_COMPONENT = `{
	"component_type": "privacy",
	"title": "Privacy",
	"key": "account_privacy",
	"item": [
		{
			"link": "lastseen",
			"title": "Last Seen",
			"key": "account_last_seen",
			"desc": "if you don't share your last seen status, you won't be able to see others people's last seen statues"
		},
		{
			"link": "readreceipts",
			"title": "Read Receipts",
			"key": "account_read_receipts",
			"desc": "if you turn off read receipts, you won't be able to see read receipts from others people."
		},
		{
			"link": "changenumber",
			"title": "Change Number",
			"key": "current_number",
			"desc": "if you turn off read receipts, you won't be able to see read receipts from others people."
		},
		{
			"link": "changelanguage",
			"title": "Change Language",
			"key": "account_language",
			"data": [
				{
					"title": "English",
					"value": "en"
				},
				{
					"title": "العربيه",
					"value": "ar"
				},
				{
					"title": "Deutsch",
					"value": "de"
				},
				{
					"title": "Espanol",
					"value": "es"
				},
				{
					"title": "Francais",
					"value": "fr"
				}
			]
		},
		{
			"link": "event",
			"title": "Synchronize events",
			"key": "sync_events",
			"desc": "Synchronize events with system calendar"
		}
	]
}`;

const SEND_COMPONENT = `{
	"component_type": "send",
	"title": "Tap enter to send.",
	"key": "chats_enter_is_send"
}`;

const GIF_COMPONENT = `{
	"component_type": "gif",
	"title": "Tap enter to send.",
	"key": "chats_auto_play_gif"
}`;

const CHAT_HISTORY_COMPONENT = `{
	"component_type": "chat_history",
	"title": "Chat History",
	"key": "pref_chat_history",
	"item": [
		{
			"link": "deletechat",
			"title": "Delete all chats",
			"key": "pref_delete_all_chats"
		}
	]
}`;

const MEDIA_PERSONAL_MESSAGE_COMPONENT = `{
	"component_type": "personal_message",
	"title": "Peronsal Messaging",
	"key": "pref_screen_contact_media_auto_download",
	"item": [
		{
			"link": "priority",
			"title": "Priority Contacts",
			"key": "media_download_priority_contacts",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_contact_fav_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_contact_fav_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_contact_fav_roaming"
				}
			]
		},
		{
			"link": "contact",
			"title": "Contacts",
			"key": "media_download_contacts",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_contact_other_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_contact_other_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_contact_other_roaming"
				}
			]
		}
	]
}`;

const MEDIA_GROUP_MESSAGE_COMPONENT = `{
	"component_type": "group_message",
	"title": "Group Messaging",
	"key": "pref_screen_group_media_auto_download",
	"item": [
		{
			"link": "priority",
			"title": "Priority Group",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_group_fav_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_group_fav_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_group_fav_roaming"
				}
			]
		},
		{
			"link": "group",
			"title": "Groups",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_group_other_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_group_other_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_group_other_roaming"
				}
			]
		}
	]
}`;

const MEDIA_CHANNEL_MESSAGE_COMPONENT = `{
	"component_type": "channel_message",
	"title": "Channel Messaging",
	"key": "pref_screen_channel_media_auto_download",
	"item": [
		{
			"link": "priority",
			"title": "Priority channels",
			"key": "media_download_priority_channels",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_channel_fav_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_channel_fav_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_channel_fav_roaming"
				}
			]
		},
		{
			"link": "channel",
			"title": "Channels",
			"key": "media_download_channels",
			"sub_item": [
				{
					"title": "When using mobile data",
					"link": "mobile",
					"key": "media_download_channel_other_3g"
				},
				{
					"title": "When connected to WIFI",
					"link": "wifi",
					"key": "media_download_channel_other_wifi"
				},
				{
					"title": "When roaming",
					"link": "roaming",
					"key": "media_download_channel_other_roaming"
				}
			]
		}
	]
}`;
//
const NOTIFICATION_PERSONAL_MESSAGE_COMPONENT = `{
	"component_type": "personal_message",
	"title": "Peronsal Messaging",
	"key": "pref_contact_notifications_settings",
	"item": [{
			"link": "priority",
			"title": "Priority Contacts",
			"key": "notifications_priority_contacts",
			"sub_item": [{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_contact_fav_incoming_alert"
				}, {
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_contact_fav_outgoing_alert"
				}, {
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_contact_fav_vibrate_state"
				}, {
					"title": "Light",
					"link": "light",
					"key": "notification_contact_fav_led",
					"data": [{
							"title": "None",
							"value": ""
						}, {
							"title": "White",
							"value": "#FFFFFFFF"
						}, {
							"title": "Red",
							"value": "#FFFF0000"
						}, {
							"title": "Yellow",
							"value": "#FFFFFF00"
						}, {
							"title": "Green",
							"value": "#FF00FF00"
						}, {
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				}, {
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_contact_fav_popup"
				}, {
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_contact_fav_ringtone"
				}
			]
		}, {
			"link": "contact",
			"title": "Contacts",
			"key": "notifications_contacts",
			"sub_item": [{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_contact_other_incoming_alert"
				}, {
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_contact_other_outgoing_alert"
				}, {
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_contact_other_vibrate_state"
				}, {
					"title": "Light",
					"link": "light",
					"key": "notification_contact_other_led",
					"data": [{
							"title": "None",
							"value": ""
						}, {
							"title": "White",
							"value": "#FFFFFFFF"
						}, {
							"title": "Red",
							"value": "#FFFF0000"
						}, {
							"title": "Yellow",
							"value": "#FFFFFF00"
						}, {
							"title": "Green",
							"value": "#FF00FF00"
						}, {
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				}, {
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_contact_other_popup"
				}, {
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_contact_other_ringtone"
				}
			]
		}
	]
}`;

const NOTIFICATION_GROUP_MESSAGE_COMPONENT = `{
	"component_type": "group_message",
	"title": "Group Messaging",
	"key": "pref_group_notifications_settings",
	"item": [
		{
			"link": "priority",
			"title": "Priority Contacts",
			"key": "notifications_priority_groups",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_group_fav_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_group_fav_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_group_fav_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_group_fav_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_group_fav_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_group_fav_ringtone"
				}
			]
		},
		{
			"link": "group",
			"title": "Groups",
			"key": "notifications_groups",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_group_other_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_group_other_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_group_other_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_group_other_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_group_other_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_group_other_ringtone"
				}
			]
		}
	]
}`;

const NOTIFICATION_CHANNEL_MESSAGE_COMPONENT = `{
	"component_type": "channel_message",
	"title": "Channel Messaging",
	"key": "pref_channel_notifications_settings",
	"item": [
		{
			"link": "priority",
			"title": "Priority Contacts",
			"key": "notifications_priority_channels",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_channel_fav_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_channel_fav_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_channel_fav_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_channel_fav_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_channel_fav_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_channel_fav_ringtone"
				}
			]
		},
		{
			"link": "channel",
			"title": "Channels",
			"key": "notifications_channels",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_group_channel_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_channel_other_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_channel_other_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_channel_other_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_channel_other_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_channel_other_ringtone"
				}
			]
		}
	]
}`;

const NOTIFICATION_MAP_MESSAGE_COMPONENT = `{
	"component_type": "map_message",
	"title": "Map Messaging",
	"key": "pref_map_notifications_settings",
	"item": [
		{
			"link": "priority",
			"title": "Priority Map",
			"key": "notifications_priority_map",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_map_fav_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_map_fav_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_map_fav_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_map_fav_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_map_fav_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_map_fav_ringtone"
				}
			]
		},
		{
			"link": "map",
			"title": "Map",
			"key": "notifications_map",
			"sub_item": [
				{
					"title": "Incoming message alert",
					"link": "incoming_alert",
					"key": "notification_map_other_incoming_alert"
				},
				{
					"title": "Outgoing message alert",
					"link": "outgoing_alert",
					"key": "notification_map_other_outgoing_alert"
				},
				{
					"title": "Vibration",
					"link": "vibration",
					"key": "notification_map_other_vibrate_state"
				},
				{
					"title": "Light",
					"link": "light",
					"key": "notification_map_other_led",
					"data": [
						{
							"title": "None",
							"value": ""
						},
						{
							"title": "White",
							"value": "#FFFFFFFF"
						},
						{
							"title": "Red",
							"value": "#FFFF0000"
						},
						{
							"title": "Yellow",
							"value": "#FFFFFF00"
						},
						{
							"title": "Green",
							"value": "#FF00FF00"
						},
						{
							"title": "Blue",
							"value": "#FF0000FF"
						}
					]
				},
				{
					"title": "Popup Notifications",
					"link": "popup",
					"key": "notification_map_other_popup"
				},
				{
					"title": "Ringtones",
					"link": "ring",
					"key": "notification_map_other_ringtone"
				}
			]
		}
	]
}`;

export const HELP_COMPONENT_KEYS: ComponentMap[] = [
  { key: ComponentTypes.ABOUT, value: ABOUT_COMPONENT },
  { key: ComponentTypes.FAQ, value: FAQ_COMPONENT }
];

export const ACCOUNT_COMPONENT_KEYS: ComponentMap[] = [
  { key: ComponentTypes.PRIVACY, value: PRIVACY_COMPONENT }
];

export const CHATS_COMPONENT_KEYS: ComponentMap[] = [
  { key: ComponentTypes.SEND, value: SEND_COMPONENT },
  { key: ComponentTypes.GIF, value: GIF_COMPONENT },
  { key: ComponentTypes.CHAT_HISTORY, value: CHAT_HISTORY_COMPONENT }
];

export const MEDIA_COMPONENT_KEYS: ComponentMap[] = [
  {
    key: ComponentTypes.PERSONAL_MESSAGE,
    value: MEDIA_PERSONAL_MESSAGE_COMPONENT
  },
  { key: ComponentTypes.GROUP_MESSAGE, value: MEDIA_GROUP_MESSAGE_COMPONENT },
  {
    key: ComponentTypes.CHANNEL_MESSAGE,
    value: MEDIA_CHANNEL_MESSAGE_COMPONENT
  }
];

export const NOTIFICATION_COMPONENT_KEYS: ComponentMap[] = [
  {
    key: ComponentTypes.PERSONAL_MESSAGE,
    value: NOTIFICATION_PERSONAL_MESSAGE_COMPONENT
  },
  {
    key: ComponentTypes.GROUP_MESSAGE,
    value: NOTIFICATION_GROUP_MESSAGE_COMPONENT
  },
  {
    key: ComponentTypes.CHANNEL_MESSAGE,
    value: NOTIFICATION_CHANNEL_MESSAGE_COMPONENT
  },
  { key: ComponentTypes.MAP_MESSAGE, value: NOTIFICATION_MAP_MESSAGE_COMPONENT }
];
