import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-post-cell-actions',
  templateUrl: './post-cell-actions.component.html',
  styleUrls: ['./post-cell-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCellActionsComponent {
  @Input() media: string;
  @Input() mediaId: string;
  @Input() isAdmin: boolean;
  @Input() loggedInIsSender: boolean;
  @Input() isDownloadable: boolean;
  @Input() isMenu: boolean;

  @Output() recallNotfiy = new EventEmitter<boolean>();
  @Output() repostNotfiy = new EventEmitter<boolean>();
  @Output() editNotfiy = new EventEmitter<boolean>();
  @Output() statsNotfiy = new EventEmitter<boolean>();

  constructor() {}

  recallEvent() {
    this.recallNotfiy.emit(true);
  }
  editEvent() {
    this.editNotfiy.emit(true);
  }
  repostEvent() {
    this.repostNotfiy.emit(true);
  }
  statsEvent() {
    this.statsNotfiy.emit(true);
  }
}
