/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./circle-btn/circle-btn.component.ngfactory";
import * as i2 from "./circle-btn/circle-btn.component";
import * as i3 from "../abstract-icons/add-icon.component.ngfactory";
import * as i4 from "../abstract-icons/add-icon.component";
import * as i5 from "./add-btn.component";
var styles_AddBtnComponent = [];
var RenderType_AddBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddBtnComponent, data: {} });
export { RenderType_AddBtnComponent as RenderType_AddBtnComponent };
export function View_AddBtnComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-circle-btn", [["edit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.btnEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CircleBtnComponent_0, i1.RenderType_CircleBtnComponent)), i0.ɵdid(1, 49152, null, 0, i2.CircleBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-add-icon", [], null, null, null, i3.View_AddIconComponent_0, i3.RenderType_AddIconComponent)), i0.ɵdid(3, 49152, null, 0, i4.AddIconComponent, [], null, null)], null, null); }
export function View_AddBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-btn", [], null, null, null, View_AddBtnComponent_0, RenderType_AddBtnComponent)), i0.ɵdid(1, 49152, null, 0, i5.AddBtnComponent, [], null, null)], null, null); }
var AddBtnComponentNgFactory = i0.ɵccf("app-add-btn", i5.AddBtnComponent, View_AddBtnComponent_Host_0, { disabled: "disabled" }, { btnClicked: "btnClicked" }, []);
export { AddBtnComponentNgFactory as AddBtnComponentNgFactory };
