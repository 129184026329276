<ng-container *ngIf="!showChangePasswordScreen">
  <div class="forgot-password">
    <div class="forgot-password-header">
      Forgot Your Password?
    </div>
    <div class="forgot-password-title">
      Enter your email address & we'll send you a code to reset your password.
    </div>

    <form
      form-body
      class="forgot-password-form"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            type="email"
            formControlName="email"
            autocomplete="off"
          />
        </mat-form-field>

        <app-validation-errors
          class="forgot-password-error"
          *ngIf="email?.errors && email?.touched"
          [fieldName]="'Email'"
          [fieldErrors]="email?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-submit">
        <app-border-btn next invert lg [type]="'submit'">
          Send <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
        <app-border-btn next lg (btnClicked)="goBackEvent()">
          Back
        </app-border-btn>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="showChangePasswordScreen">
  <app-change-password [accountEmail]="email.value"> </app-change-password>
</ng-container>
