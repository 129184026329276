/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../accessors/hide-if-sub-chat.directive";
import * as i3 from "../../../store/root-store.selectors";
import * as i4 from "../../../shared/components/profile-cell/profile-cell.component.ngfactory";
import * as i5 from "../../../shared/components/profile-cell/profile-cell.component";
import * as i6 from "../layout.service";
import * as i7 from "@angular/common";
import * as i8 from "../navbar-pagename/navbar-pagename.component.ngfactory";
import * as i9 from "../navbar-pagename/navbar-pagename.component";
import * as i10 from "../../../accessors/show-if-has-privilege.directive";
import * as i11 from "../../../shared/components/lang-selector/lang-selector.component.ngfactory";
import * as i12 from "../../../shared/components/lang-selector/lang-selector.component";
import * as i13 from "@ngx-translate/core";
import * as i14 from "ngx-bootstrap/dropdown";
import * as i15 from "ngx-bootstrap/component-loader";
import * as i16 from "../../../shared/directives/click-elsewhere/click-elsewhere.directive";
import * as i17 from "../../../shared/components/profile-image/profile-image.component.ngfactory";
import * as i18 from "../../../shared/components/profile-image/profile-image.component";
import * as i19 from "@angular/platform-browser";
import * as i20 from "./navbar.component";
import * as i21 from "../../../core/auth.service";
import * as i22 from "../../../core/ui.service";
import * as i23 from "@angular/router";
import * as i24 from "../../../store/chats/chat.dispatchers";
import * as i25 from "../../../store/paymentProviders/paymentProviders.dispatchers";
var styles_NavBarComponent = [i0.styles];
var RenderType_NavBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavBarComponent, data: {} });
export { RenderType_NavBarComponent as RenderType_NavBarComponent };
function View_NavBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "clickable-cursor svg-icon icon-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sectionEvent(_co.uiSections.SETTINGS) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-settings"]], null, null, null, null, null))], null, null); }
function View_NavBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavBarComponent_2)), i1.ɵdid(2, 212992, null, 0, i2.HideIfSubChatDirective, [i1.ViewContainerRef, i1.TemplateRef, i3.RootStoreSelectors, i1.ChangeDetectorRef], { appHideIfSubChat: [0, "appHideIfSubChat"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.editsettings; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-cell", [], null, null, null, i4.View_ProfileCellComponent_0, i4.RenderType_ProfileCellComponent)), i1.ɵdid(1, 49152, null, 0, i5.ProfileCellComponent, [i6.LayoutService], { chat: [0, "chat"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NavBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "menuitem clickable-cursor"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectChatEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavBarComponent_5)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit == null) ? null : _v.context.$implicit.subChannel); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavBarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-cell", [], null, null, null, i4.View_ProfileCellComponent_0, i4.RenderType_ProfileCellComponent)), i1.ɵdid(1, 49152, null, 0, i5.ProfileCellComponent, [i6.LayoutService], { chat: [0, "chat"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NavBarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "menuitem clickable-cursor"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectChatEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavBarComponent_8)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.subChannel); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavBarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavBarComponent_7)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.layoutService.allChannels$)); var currVal_1 = _co.TrackByFunction; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NavBarComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "no-channels"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There is no Sub Channel "]))], null, null); }
function View_NavBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ul", [["class", "dropdown-menu no-copyable-item"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Main App "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavBarComponent_4)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "sub"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sub Channels "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavBarComponent_6)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NavBarComponent_9)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "menuitem-logout clickable-cursor"], ["role", "menuitem"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.layoutService.allChannels$)); var currVal_1 = _co.TrackByFunction; _ck(_v, 5, 0, currVal_0, currVal_1); var tmp_2_0 = null; var currVal_2 = ((((tmp_2_0 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.layoutService.allChannels$))) == null) ? null : tmp_2_0.length) > 1); _ck(_v, 11, 0, currVal_2); var tmp_3_0 = null; var currVal_3 = ((((tmp_3_0 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.layoutService.allChannels$))) == null) ? null : tmp_3_0.length) <= 1); _ck(_v, 14, 0, currVal_3); }, null); }
export function View_NavBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "main-header no-copyable-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "main-header-sidenav clickable-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "fas-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "fas-border"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidenavEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-bars"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "fas-separetor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-navbar-pagename", [], null, null, null, i8.View_NavbarPagenameComponent_0, i8.RenderType_NavbarPagenameComponent)), i1.ɵdid(7, 114688, null, 0, i9.NavbarPagenameComponent, [i6.LayoutService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "full-w"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 18, "div", [["class", "top-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "notification clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTalkToAdmin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, ":svg:svg", [["class", "clickable-cursor svg-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-bell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "span", [["class", "messages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavBarComponent_1)), i1.ɵdid(15, 212992, null, 0, i10.ShowIfHasPrivilegeDirective, [i1.ChangeDetectorRef, i1.TemplateRef, i1.ViewContainerRef, i3.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-lang-selector", [], null, null, null, i11.View_LagSelectorComponent_0, i11.RenderType_LagSelectorComponent)), i1.ɵdid(17, 638976, null, 0, i12.LagSelectorComponent, [i13.TranslateService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(18, 16777216, null, null, 9, "div", [["appClickElsewhere", ""], ["class", "btn-group clickable-cursor list"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "clickElsewhere"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickElsewhere" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).hide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i14.BsDropdownState, i14.BsDropdownState, []), i1.ɵdid(20, 212992, [["dd", 4]], 0, i14.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i15.ComponentLoaderFactory, i14.BsDropdownConfig, i14.BsDropdownState], null, null), i1.ɵdid(21, 16384, null, 0, i16.ClickElsewhereDirective, [i1.ElementRef], null, { clickElsewhere: "clickElsewhere" }), (_l()(), i1.ɵeld(22, 0, null, null, 3, "app-profile-image", [["class", "head"], ["dropdownToggle", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_ProfileImageComponent_0, i17.RenderType_ProfileImageComponent)), i1.ɵdid(23, 147456, null, 0, i14.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i14.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i14.BsDropdownState], null, null), i1.ɵdid(24, 704512, null, 0, i18.ProfileImageComponent, [i19.DomSanitizer, i3.RootStoreSelectors, i1.ChangeDetectorRef], { id: [0, "id"], size: [1, "size"], chatType: [2, "chatType"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavBarComponent_3)), i1.ɵdid(27, 16384, null, 0, i14.BsDropdownMenuDirective, [i14.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 7, 0); var currVal_0 = _co.privilegesName.editsettings; _ck(_v, 15, 0, currVal_0); _ck(_v, 17, 0); _ck(_v, 20, 0); var tmp_7_0 = null; var currVal_7 = (((tmp_7_0 = i1.ɵunv(_v, 24, 0, i1.ɵnov(_v, 25).transform(_co.layoutService.selectedChat$))) == null) ? null : tmp_7_0.id); var currVal_8 = 32; var currVal_9 = "Channel"; _ck(_v, 24, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 20).dropup; var currVal_2 = i1.ɵnov(_v, 20).isOpen; var currVal_3 = (i1.ɵnov(_v, 20).isOpen && i1.ɵnov(_v, 20).isBs4); _ck(_v, 18, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 23).isDisabled; var currVal_6 = i1.ɵnov(_v, 23).isOpen; _ck(_v, 22, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_NavBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-bar", [], null, null, null, View_NavBarComponent_0, RenderType_NavBarComponent)), i1.ɵdid(1, 245760, null, 0, i20.NavBarComponent, [i21.AuthService, i6.LayoutService, i22.UiService, i13.TranslateService, i23.Router, i24.ChatDispatchers, i25.PaymentProvidersDispatchers], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavBarComponentNgFactory = i1.ɵccf("app-nav-bar", i20.NavBarComponent, View_NavBarComponent_Host_0, { selectedChatId: "selectedChatId" }, { searchNotify: "searchNotify", toggleSidenavNotify: "toggleSidenavNotify", sectionNotify: "sectionNotify", talkToAdminNotify: "talkToAdminNotify", logoutNotify: "logoutNotify" }, []);
export { NavBarComponentNgFactory as NavBarComponentNgFactory };
