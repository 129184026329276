/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-setting-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popup-setting-frame.component";
var styles_PopupSettingFrameComponent = [i0.styles];
var RenderType_PopupSettingFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupSettingFrameComponent, data: {} });
export { RenderType_PopupSettingFrameComponent as RenderType_PopupSettingFrameComponent };
function View_PopupSettingFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-close-circle"]], null, null, null, null, null))], null, null); }
function View_PopupSettingFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "popup-settings-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonTitle; _ck(_v, 2, 0, currVal_0); }); }
export function View_PopupSettingFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "popup-settings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "popup-settings-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "header-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupSettingFrameComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "popup-settings-body"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupSettingFrameComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.hideclose; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.hideFooter; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.desc; _ck(_v, 6, 0, currVal_1); }); }
export function View_PopupSettingFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup-setting-frame", [], null, null, null, View_PopupSettingFrameComponent_0, RenderType_PopupSettingFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.PopupSettingFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupSettingFrameComponentNgFactory = i1.ɵccf("app-popup-setting-frame", i3.PopupSettingFrameComponent, View_PopupSettingFrameComponent_Host_0, { title: "title", desc: "desc", hideFooter: "hideFooter", hideclose: "hideclose", buttonTitle: "buttonTitle" }, { closeNotifier: "closeNotifier", clickNotifier: "clickNotifier" }, ["*"]);
export { PopupSettingFrameComponentNgFactory as PopupSettingFrameComponentNgFactory };
