import { Store } from '@ngrx/store';
import * as BlacklistPatternsActions from './blacklistPatternsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BlackListPatternsUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    resetBlacklistPatternsPaging() {
        this._store.dispatch(new BlacklistPatternsActions.ResetBlackListPatternsPaging());
    }
    getNextBlacklistPatternsPage() {
        this._store.dispatch(new BlacklistPatternsActions.GetNextBlacklistPatternsPage());
    }
    getPrevBlacklistPatternsPage() {
        this._store.dispatch(new BlacklistPatternsActions.GetPrevBlacklistPatternsPage());
    }
    addPatternToBlacklist(data, mainChatID, reference, recordIndex) {
        this._store.dispatch(new BlacklistPatternsActions.AddPatternToBlacklist(data, mainChatID, reference, recordIndex));
    }
    receivedBlacklistsPatternsHistoryChunk(eop, sop, currentPage, hash) {
        this._store.dispatch(new BlacklistPatternsActions.ReceivedBlacklistPatternsHistoryChunk(eop, sop, currentPage, hash));
    }
    deletePatternFromBlacklist(data, mainChatID) {
        this._store.dispatch(new BlacklistPatternsActions.DeletePatternFromBlacklist(data, mainChatID));
    }
    deletePatternFromBlacklistSuccess(data) {
        this._store.dispatch(new BlacklistPatternsActions.DeletePatternFromBlacklistSuccess(data));
    }
    setStatusAsIdle() {
        this._store.dispatch(new BlacklistPatternsActions.SetStatusAsIdle());
    }
    resetObject() {
        this._store.dispatch(new BlacklistPatternsActions.Reset());
    }
}
BlackListPatternsUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlackListPatternsUiDispatchers_Factory() { return new BlackListPatternsUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: BlackListPatternsUiDispatchers, providedIn: "root" });
