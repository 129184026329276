import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { RootStoreService } from 'src/app/store/root-store.service';
import { AuthStatus } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./socket.service";
import * as i3 from "../../store/root-store.service";
export class SocketHandlerService {
    constructor(_router, _socketService, _rootStoreService) {
        this._router = _router;
        this._socketService = _socketService;
        this._rootStoreService = _rootStoreService;
        this.webSocketSubscription = null;
        this.webSocketSubscription = this._socketService.webSocketObservable$
            .pipe(distinctUntilChanged())
            .subscribe(skObj => {
            if (skObj) {
                skObj.onmessage = (evt) => this.onSocketMessage(evt.data);
            }
        });
        this._socketService.authStore$
            .pipe(distinctUntilChanged((p, q) => p.authStatus === q.authStatus))
            .subscribe(state => {
            switch (state.authStatus) {
                case AuthStatus.AUTH_QR_SET:
                    this._router.navigate(['/login']);
                    break;
                case AuthStatus.AUTH_CHAT_SELECTED:
                    this._router.navigate(['/']);
                    break;
            }
        });
    }
    onSocketMessage(dataOnSocket) {
        this._socketService.setPingTimer();
        let data = '';
        if (environment.encodeSocket) {
            data = this.convertArrayBufferToMessage(dataOnSocket);
        }
        else {
            data = dataOnSocket;
        }
        this._rootStoreService.redirectMessage(JSON.parse(data));
    }
    convertArrayBufferToMessage(byteArray) {
        return new window.TextDecoder().decode(byteArray);
    }
}
SocketHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketHandlerService_Factory() { return new SocketHandlerService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SocketService), i0.ɵɵinject(i3.RootStoreService)); }, token: SocketHandlerService, providedIn: "root" });
