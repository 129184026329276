import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AppConfigActions from './appConfig.actions';

import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { LocalAppConfig } from 'src/models/ChannelAppLocalConfig';
import {
  AppConfig,
  AppInfo,
  App,
  AppColors,
  ImageSet
} from 'src/models/ChannelAppConfig';
import { UserData } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class AppConfigDispatchers {
  constructor(private _store: Store<AppState>) {}

  appConfigDataChanged(
    onlineAppConfig: LocalAppConfig,
    offlineAppConfig: LocalAppConfig
  ): void {
    this._store.dispatch(
      new AppConfigActions.AppConfigDataChanged(
        onlineAppConfig,
        offlineAppConfig
      )
    );
  }

  requestAppConfig() {
    this._store.dispatch(new AppConfigActions.RequestAppConfig());
  }

  requestConfigQrCode() {
    this._store.dispatch(new AppConfigActions.RequestConfigQrCode());
  }

  receiveAppConfig(
    onlineAppConfig: AppConfig,
    offlineAppConfig: AppConfig
  ): void {
    this._store.dispatch(
      new AppConfigActions.ReceiveAppConfig(onlineAppConfig, offlineAppConfig)
    );
  }

  receiveDefaultAppConfig(payload: AppConfig): void {
    this._store.dispatch(new AppConfigActions.ReceiveDefaultAppConfig(payload));
  }

  setAppConfig(
    channelConfOnly: boolean,
    selectedPageNumber?: number,
    featuresId?: string[]
  ) {
    this._store.dispatch(
      new AppConfigActions.SetAppConfig(
        channelConfOnly,
        selectedPageNumber,
        featuresId
      )
    );
  }

  setApp(userData: UserData) {
    this._store.dispatch(new AppConfigActions.SetApp(userData));
  }
  publishAppConfig(
    isChannelOnly: boolean,
    track: number,
    versionName: string,
    versionDesc: string
  ) {
    this._store.dispatch(
      new AppConfigActions.PublishAppConfig(
        isChannelOnly,
        track,
        versionName,
        versionDesc
      )
    );
  }

  generateApp(
    appId: string,
    os: number,
    android_format: string,
    ver_name,
    ver_desc
  ) {
    this._store.dispatch(
      new AppConfigActions.GenerateApp(
        appId,
        os,
        android_format,
        ver_name,
        ver_desc
      )
    );
  }

  updateAppInfo(appInfo: AppInfo) {
    this._store.dispatch(new AppConfigActions.UpdateAppInfo(appInfo));
  }

  appConfigAck(onlineAppConfig: App, offlineAppConfig: App) {
    this._store.dispatch(
      new AppConfigActions.AppConfigAck(onlineAppConfig, offlineAppConfig)
    );
  }

  appConfigPublished(isChannelOnly: boolean) {
    this._store.dispatch(
      new AppConfigActions.AppConfigPublished(isChannelOnly)
    );
  }

  requestAppTemplate(templateId: string) {
    this._store.dispatch(new AppConfigActions.RequestAppTemplate(templateId));
  }

  receiveAppTemplate(
    onlineAppConfig: App,
    offlineAppConfig: App,
    onlineAppConfigIOS: App,
    offlineAppConfigIOS: App
  ) {
    this._store.dispatch(
      new AppConfigActions.ReceiveAppTemplate(
        onlineAppConfig,
        offlineAppConfig,
        onlineAppConfigIOS,
        offlineAppConfigIOS
      )
    );
  }

  updateAppColors(appColors: AppColors) {
    this._store.dispatch(new AppConfigActions.UpdateAppColors(appColors));
  }

  uploadAppColoredLogo(selectedFile: ISelectedFiles) {
    this._store.dispatch(
      new AppConfigActions.UploadAppColoredLogo(selectedFile)
    );
  }

  uploadAppChatImage(selectedFile: ISelectedFiles) {
    this._store.dispatch(new AppConfigActions.UploadAppChatIcon(selectedFile));
  }

  uploadAppImage(selectedFile: ISelectedFiles) {
    this._store.dispatch(new AppConfigActions.UploadAppIcon(selectedFile));
  }

  uploadAppColoredLogoSuccess(
    imageId: string,
    imageUrl: string,
    imageSet: ImageSet,
    dominantColor?: string
  ) {
    this._store.dispatch(
      new AppConfigActions.UploadAppColoredLogoSuccess(
        imageId,
        imageUrl,
        imageSet,
        dominantColor
      )
    );
  }

  uploadAppWhiteLogo(selectedFile: ISelectedFiles) {
    this._store.dispatch(new AppConfigActions.UploadAppWhiteLogo(selectedFile));
  }

  getDomainatColor(selectedFile: ISelectedFiles) {
    this._store.dispatch(new AppConfigActions.GetDomainatColor(selectedFile));
  }

  uploadAppWhiteLogoSuccess(imageUrl: string, imageSet: ImageSet) {
    this._store.dispatch(
      new AppConfigActions.UploadAppWhiteLogoSuccess(imageUrl, imageSet)
    );
  }

  uploadIosStoreLogo(selectedFile: ISelectedFiles) {
    this._store.dispatch(new AppConfigActions.UploadIosStoreLogo(selectedFile));
  }

  uploadIosStoreLogoSuccess(imageUrl: string) {
    this._store.dispatch(
      new AppConfigActions.UploadIosStoreLogoSuccess(imageUrl)
    );
  }

  createAppTemplate(
    androidImageUrl: string,
    iosImageUrl: string,
    categories: string[],
    name: string,
    order: number,
    id?: string
  ) {
    this._store.dispatch(
      new AppConfigActions.CreateAppTemplate(
        androidImageUrl,
        iosImageUrl,
        categories,
        name,
        order,
        id
      )
    );
  }

  saveInAppPurchases(json: string) {
    this._store.dispatch(new AppConfigActions.SetInAppPurchases(json));
  }

  UpldTempImageToProfile(imageId) {
    this._store.dispatch(new AppConfigActions.UploadProfileImage(imageId));
  }

  UpdateDominatColor(color) {
    this._store.dispatch(new AppConfigActions.UploadAppDomaintColor(color));
  }
}
