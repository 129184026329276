<div class="types">
  <div
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, 0, mType.TEXT)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.TEXT)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.TEXT)"
      (click)="setText()"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-text"></use>
      </svg>
    </label>
    <app-hover-tooltip
      *ngIf="hoverText"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendphoto"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -50, mType.PHOTO)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.PHOTO)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.PHOTO)"
      for="image-upload"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-photo"></use>
      </svg>
    </label>
    <input
      #imageInputFile
      id="image-upload"
      type="file"
      accept="image/*"
      (change)="onFilesSelected()"
    />
    <app-hover-tooltip
      *ngIf="hoverPhoto"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendvideo"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -30.3, mType.VIDEO)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.VIDEO)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.VIDEO)"
      for="video-upload"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-video"></use>
      </svg>
    </label>
    <input
      #videoInputFile
      id="video-upload"
      type="file"
      accept="video/mp4,video/x-m4v,video/*"
      (change)="onFilesSelected()"
    />
    <app-hover-tooltip
      *ngIf="hoverVideo"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendgif"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -110.5, mType.GIF)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.GIF)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.GIF)"
      for="gif-upload"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-gif"></use>
      </svg>
    </label>
    <input
      #gifInputFile
      id="gif-upload"
      type="file"
      accept=".gif"
      (change)="onFilesSelected()"
    />
    <app-hover-tooltip
      *ngIf="hoverGif"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendaudio"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -60, mType.AUDIO)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.AUDIO)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.AUDIO)"
      for="audio-upload"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-audio"></use>
      </svg>
    </label>
    <input
      #audioInputFile
      id="audio-upload"
      type="file"
      accept="audio/*"
      (change)="onFilesSelected()"
    />
    <app-hover-tooltip
      *ngIf="hoverAudio"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.senddoc"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -40, mType.DOCUMENT)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.DOCUMENT)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.DOCUMENT)"
      for="document-upload"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-file"></use>
      </svg>
    </label>
    <input
      #documentInputFile
      id="document-upload"
      type="file"
      (change)="onFilesSelected()"
    />
    <app-hover-tooltip
      *ngIf="hoverDocument"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.sendarticle"
    class="upload-label"
    (mouseover)="toggleTooltip(true, 110, -95, mType.ARTICLE)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.ARTICLE)"
    (click)="showComposeUrlEvent(true, true)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleInstantUrlStyle()"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-article"></use>
      </svg>
    </label>
    <app-hover-tooltip
      *ngIf="hoverArticle"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    class="upload-label"
    (click)="showComposeUrlEvent(true, false)"
    (mouseover)="toggleTooltip(true, 110, -60, mType.CONTACT)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.CONTACT)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleTextUrlStyle()"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-link"></use>
      </svg>
    </label>
    <app-hover-tooltip
      *ngIf="hoverContact"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>

  <div
    *appShowIfHasPrivilege="privilegesName.editcalendar"
    class="upload-label"
    (click)="showComposeCalendarEvent(true)"
    (mouseover)="toggleTooltip(true, 110, -65, mType.CALENDAR)"
    (mouseleave)="toggleTooltip(false, 0, 0, mType.CALENDAR)"
  >
    <label
      class="type-btn clickable-cursor"
      [ngStyle]="getCircleStyle(mType.CALENDAR)"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-calendar"></use>
      </svg>
    </label>
    <app-hover-tooltip
      *ngIf="hoverCalendar"
      [top]="top"
      [right]="right"
      [text]="text"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </div>
</div>

<app-modal-container
  *ngIf="showComposeUrl"
  (cancelNotfiy)="showComposeUrlEvent(false)"
>
  <app-compose-url-input
    (cancelNotfiy)="showComposeUrlEvent(false)"
    (publishNotfiy)="urlSelectedEvent($event)"
    [isInstant]="isInstant$ | async"
  >
  </app-compose-url-input>
</app-modal-container>

<app-modal-container
  *ngIf="showComposeCalendar"
  (cancelNotfiy)="showComposeCalendarEvent(false)"
>
  <app-calendars-pool
    (selectCalendarNotfiy)="calendarSelectedEvent($event)"
    (cancelNotfiy)="showComposeCalendarEvent(false)"
  >
  </app-calendars-pool>
</app-modal-container>
