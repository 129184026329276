import { Actions } from './membersUI.actions';
import * as actions from './membersUI.actions';
import {
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection
} from '../../../models/constants';
import { MembersUI } from 'src/models/MembersUI';

const INITIAL_STATE: MembersUI = {
  eop: 0,
  sop: null,
  currentPage: [],
  hash: '',
  status: SCREEN_STATUS.IDLE,
  direction: null,
  previousRequest: {},
  name: '',
  searchBy: 0,
  view: null,
  accountTypes: null
};

export function membersUiReducer(
  state = INITIAL_STATE,
  action: Actions
): MembersUI {
  switch (action.type) {
    case actions.MembersUIActionTypes.UI_MEMBERS_GET_NEXT_PAGE: {
      const newHash = state.name;
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.NEXT
      };
    }
    case actions.MembersUIActionTypes.UI_MEMBERS_GET_PREV_PAGE: {
      const newHash = state.name;
      return {
        ...state,
        hash: newHash,
        direction: PageDirection.PREV
      };
    }
    case actions.MembersUIActionTypes.UI_MEMBERS_SET_IDLE_STATUS: {
      return {
        ...state
      };
    }
    case actions.MembersUIActionTypes.UI_MEMBERS_RECEIVED_HISTORY_CHUNK: {
      // if (action.hash === state.hash) {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
      // }
      // else {
      //   return state;
      // }
    }
    case actions.MembersUIActionTypes.UI_MEMBERS_RESET_PAGING: {
      return {
        ...INITIAL_STATE,
        status: action.status,
        name: action.name,
        searchBy: action.searchBy,
        view: action.view,
        accountTypes: action.accountTypes
          ? action.accountTypes
          : INITIAL_STATE.accountTypes
      };
    }
    case actions.MembersUIActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
