import { AbstractTableColumn } from './AbstractItem';
import {
  DELETE_MAP_DRIVER_USER_METHOD,
  GET_MAP_DRIVER_USER_METHOD
} from './constants';

export interface MapDriverUser {
  ids: string[];
}

export class GetMapTripUser {
  readonly method = GET_MAP_DRIVER_USER_METHOD;
  constructor(public trip_id: string) {}
}

export class DeleteMapTripUser {
  readonly method = DELETE_MAP_DRIVER_USER_METHOD;
  constructor(public account_id: string, public trip_id: string) {}
}
