import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { FileService } from 'src/app/core/file.service';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { SubGroupUIActionTypes } from './subGroupsUI.actions';
import * as SubGroupActions from './subGroupsUI.actions';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { Photo } from 'src/models/Photo';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IChat, SetChat, GetSubChats } from 'src/models/IChat';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  ERROR_NOT_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  NOT_SUPPORTED_FILE_TYPE,
  NO_FILE_NAME,
  GROUP_UPLOAD_TYPE,
  GROUP,
  PageDirection
} from 'src/models/constants';
import { SubGroupUiDispatchers } from './subGroupsUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { SubChatsService } from 'src/app/+merchant/+dashboard/sub-chats/sub-chats.service';

@Injectable()
export class SubGroupUiEffects {
  @Effect({ dispatch: false })
  uploadSubGroupImage = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UPLOAD_SUB_GROUP_IMAGE),
    withLatestFrom(
      this._store
        .select(state => state.subGroupUiReducer.creationProcessSubChat)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.subGroupUiReducer.selectedSubGroup)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, creationSubGroup, updatedSubGroup]) => {
      const action = <SubGroupActions.UploadSubGroupImage>val;

      if (this.validateFile(action.selectedFile.file, 'image')) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.file.localFile)
          .then(fileAsArrayBuffer => {
            this._uploadGateway
              .uploadWithProgress(
                fileAsArrayBuffer,
                action.selectedFile.file.localFile.type,
                action.selectedFile.file.localFile.name,
                false,
                GROUP_UPLOAD_TYPE,
                null,
                creationSubGroup ? creationSubGroup.id : updatedSubGroup.id,
                true
              )
              .subscribe(
                event => {
                  if (event.type === HttpEventType.Response) {
                    const res = <IUploadResponse>event.body;
                    let chat: IChat = {};
                    const photo: Photo = { id: res.file };
                    chat.id = creationSubGroup
                      ? creationSubGroup.id
                      : updatedSubGroup.id;
                    chat.photo = photo;
                    chat = { ...chat, ...action.selectedFile.chat };
                    // this.subChatsService._currentPhotoUploaded.next(res);
                    this._socketGateway.sendSocketMessage(new SetChat(chat));
                  }
                },
                error => {
                  console.log('Failed to upload subGroup image');
                }
              );
          });
      }
    })
  );

  @Effect({ dispatch: false })
  afterSubGroupCreation = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UI_UPLOAD_SUB_GROUP_PHOTO),
    map((action: SubGroupActions.SubGroupPhotoUploadUI) => {
      this._chatDispatchers.chatInvalidated(action.createdSubGroup.id);
    })
  );

  @Effect({ dispatch: false })
  getNextSubGroupPage = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UI_SUB_GROUP_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subGroupUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subGroupUiState]) => {
      const action = <SubGroupActions.GetNextSubGroupPage>val;
      const prevRequest = subGroupUiState.previousRequest;
      if (
        !(
          subGroupUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          subGroupUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats([GROUP], 0, subGroupUiState.hash, subGroupUiState.eop)
        );
      } else {
        this._subGroupUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevSubGroupPage = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UI_SUB_GROUP_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.subGroupUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, subGroupUiState]) => {
      const action = <SubGroupActions.GetPrevSubGroupPage>val;
      const prevRequest = subGroupUiState.previousRequest;
      if (
        !(
          subGroupUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          subGroupUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetSubChats([GROUP], 1, subGroupUiState.hash, subGroupUiState.sop)
        );
      } else {
        this._subGroupUiDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterSubGroupSectionSelection = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UI_LIST_SUB_GROUP),
    map((action: SubGroupActions.ListSubGroupUI) => {
      this._subGroupUiDispatchers.resetSubGroupPaging();
    })
  );

  @Effect({ dispatch: false })
  afterSubChatResetPaging = this.actions$.pipe(
    ofType(SubGroupUIActionTypes.UI_SUB_GROUP_RESET_PAGING),
    map((action: SubGroupActions.ResetSubGroupPaging) => {
      this._subGroupUiDispatchers.getNextSubGroupPage();
    })
  );

  constructor(
    private actions$: Actions,
    private _chatDispatchers: ChatDispatchers,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _subGroupUiDispatchers: SubGroupUiDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private subChatsService: SubChatsService,
    private _store: Store<AppState>
  ) {}

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
