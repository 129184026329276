/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assign-sub-chat.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./assign-sub-chat.component";
var styles_AssignSubChatsComponent = [i0.styles];
var RenderType_AssignSubChatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssignSubChatsComponent, data: {} });
export { RenderType_AssignSubChatsComponent as RenderType_AssignSubChatsComponent };
function View_AssignSubChatsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "grp?.name"], ["class", "tag-slot-image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.localThumbnail); _ck(_v, 0, 0, currVal_0); }); }
function View_AssignSubChatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "tag-slot"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignSubChatsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "tag-slot-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeSubgroupEvent(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" X "]))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.localThumbnail); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 4, 0, currVal_1); }); }
export function View_AssignSubChatsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tags-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "tags-container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Groups"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignSubChatsComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assignSubgroups; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AssignSubChatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-assign-sub-chat", [], null, null, null, View_AssignSubChatsComponent_0, RenderType_AssignSubChatsComponent)), i1.ɵdid(1, 49152, null, 0, i3.AssignSubChatsComponent, [], null, null)], null, null); }
var AssignSubChatsComponentNgFactory = i1.ɵccf("app-assign-sub-chat", i3.AssignSubChatsComponent, View_AssignSubChatsComponent_Host_0, { assignSubgroups: "assignSubgroups" }, { removeSubgroupNotfiy: "removeSubgroupNotfiy" }, []);
export { AssignSubChatsComponentNgFactory as AssignSubChatsComponentNgFactory };
