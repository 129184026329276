/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assign-text-action.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./assign-text-action.component";
var styles_AssignTextActionComponent = [i0.styles];
var RenderType_AssignTextActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssignTextActionComponent, data: {} });
export { RenderType_AssignTextActionComponent as RenderType_AssignTextActionComponent };
function View_AssignTextActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["src", "./assets/img/deletholiday.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_AssignTextActionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "action-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "delete"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignTextActionComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.hideRemove; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_AssignTextActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-assign-text-action", [], null, null, null, View_AssignTextActionComponent_0, RenderType_AssignTextActionComponent)), i1.ɵdid(1, 49152, null, 0, i3.AssignTextActionComponent, [], null, null)], null, null); }
var AssignTextActionComponentNgFactory = i1.ɵccf("app-assign-text-action", i3.AssignTextActionComponent, View_AssignTextActionComponent_Host_0, { action: "action", title: "title", hideRemove: "hideRemove" }, { remove: "remove" }, []);
export { AssignTextActionComponentNgFactory as AssignTextActionComponentNgFactory };
