/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selector-input-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/dropdown";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./selector-input-icon.component";
var styles_SelectorInputIconComponent = [i0.styles];
var RenderType_SelectorInputIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectorInputIconComponent, data: {} });
export { RenderType_SelectorInputIconComponent as RenderType_SelectorInputIconComponent };
function View_SelectorInputIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-icons.svg#", ((_co.svgPath == null) ? null : _co.svgPath.name), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectorInputIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-ios-icons.svg#", ((_co.svgPath == null) ? null : _co.svgPath.name), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectorInputIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-features-icons.svg#", ((_co.svgPath == null) ? null : _co.svgPath.name), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectorInputIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-markers-icons.svg#", ((_co.svgPath == null) ? null : _co.svgPath.name), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SelectorInputIconComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["aria-labelledby", "button-split"], ["class", "icon-element"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateItemIconEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-icons.svg#", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SelectorInputIconComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "icon-seactions"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 2).show() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "seaction"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectIconSeaction("firstSeaction") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(-1, null, [" General "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "sec-seaction"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectIconSeaction("secSeaction") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(-1, null, [" Action "])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "seaction"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectIconSeaction("thirdSeaction") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵted(-1, null, [" Social "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "icon-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_7)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconFirstTabStyle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.iconSecTabStyle; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.iconThirdTabStyle; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.iconNames; _ck(_v, 16, 0, currVal_3); }, null); }
function View_SelectorInputIconComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["aria-labelledby", "button-split"], ["class", "icon-element"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateItemIconEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-ios-icons.svg#", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SelectorInputIconComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_9)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iosNames; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SelectorInputIconComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["aria-labelledby", "button-split"], ["class", "icon-element"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateItemIconEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-features-icons.svg#", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SelectorInputIconComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_11)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.postFooterName; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SelectorInputIconComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["aria-labelledby", "button-split"], ["class", "icon-element"], ["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateItemIconEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "./assets/svg/app-markers-icons.svg#", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SelectorInputIconComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "icon-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent.parent, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_13)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.locationIconNames; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SelectorInputIconComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ul", [["aria-labelledby", "button-split"], ["class", "dropdown-menu"], ["id", "dropdown-split"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_12)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconList === "Android"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.iconList === "IOS"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.iconList === "postFooter"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.iconList === "markers"); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SelectorInputIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, null, null, 17, "div", [["class", "btn-group"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.autoCloseDisplay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.BsDropdownState, i3.BsDropdownState, []), i1.ɵdid(2, 212992, [["dropdown", 4]], 0, i3.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i3.BsDropdownConfig, i3.BsDropdownState], { autoClose: [0, "autoClose"], isDisabled: [1, "isDisabled"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "button", [["class", "btn-svg"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["aria-controls", "dropdown-split"], ["class", "btn btn-svg dropdown-toggle dropdown-toggle-split"], ["dropdownToggle", ""], ["id", "button-split"], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 147456, null, 0, i3.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i3.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i3.BsDropdownState], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectorInputIconComponent_5)), i1.ɵdid(17, 16384, null, 0, i3.BsDropdownMenuDirective, [i3.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.autoClose; var currVal_4 = _co.disabled; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = (_co.iconList === "Android"); _ck(_v, 6, 0, currVal_5); var currVal_6 = (_co.iconList === "IOS"); _ck(_v, 8, 0, currVal_6); var currVal_7 = (_co.iconList === "postFooter"); _ck(_v, 10, 0, currVal_7); var currVal_8 = (_co.iconList === "markers"); _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_9 = true; var currVal_10 = i1.ɵnov(_v, 14).isDisabled; var currVal_11 = i1.ɵnov(_v, 14).isOpen; _ck(_v, 13, 0, currVal_9, currVal_10, currVal_11); }); }
export function View_SelectorInputIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selector-input-icon", [], null, null, null, View_SelectorInputIconComponent_0, RenderType_SelectorInputIconComponent)), i1.ɵdid(1, 638976, null, 0, i5.SelectorInputIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectorInputIconComponentNgFactory = i1.ɵccf("app-selector-input-icon", i5.SelectorInputIconComponent, View_SelectorInputIconComponent_Host_0, { disabled: "disabled", iconName: "iconName", iconList: "iconList" }, { updateItemIconNotify: "updateItemIconNotify" }, []);
export { SelectorInputIconComponentNgFactory as SelectorInputIconComponentNgFactory };
