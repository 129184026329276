import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UiService } from 'src/app/core/ui.service';
import { SubChatsService } from '../sub-chats.service';
import { ChatMiddleware } from 'src/app/store/chats/chat.middleware';

import { AppForm } from 'src/models/AppForm';
import { IOption } from 'src/models/campaign';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import {
  GROUP,
  CHANNEL,
  UISections,
  VIRTUAL_APP,
  CHANNEL_APP_SEARCH_CATEGORIES
} from 'src/models/constants';
import { groupNameValidator } from 'src/app/validators/groupNameValidator';
import { groupDescValidator } from 'src/app/validators/groupDescValidator';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
import { IMessage } from 'src/models/IMessage';
import { MapMarker } from 'src/models/MapMarker';
import { Product } from 'src/models/Product';
import { BundleProduct } from 'src/models/Bundle';
import { MobileStoresService } from '../../mobile-store/mobile-store.service';
import { LocationsService } from '../../locations/locations.service';
import { PrivilegesName } from 'src/models/privileges';
import { MembersService } from './../../members/members.service';
import { AppMgmtService } from '../../app-mgmt/appMgmt.service';
import { IAuth } from 'src/models/IAuth';
import { Photo } from 'src/models/Photo';
import { IChat } from './../../../../../models/IChat';

@Component({
  selector: 'app-sub-chat-form',
  templateUrl: './sub-chat-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubChatFormComponent
  implements OnInit, OnDestroy, AppForm, OnChanges {
  privilegesName: typeof PrivilegesName = PrivilegesName;
  filterTypes = CHANNEL_APP_SEARCH_CATEGORIES;
  typeOptions: IOption[] = [
    {
      key: GROUP,
      value: GROUP
    },
    {
      key: CHANNEL,
      value: CHANNEL
    }
  ];
  channel = CHANNEL;
  group = GROUP;
  virtualApp = VIRTUAL_APP;
  form: FormGroup;
  uiSections: typeof UISections = UISections;
  toggelSwith: boolean;
  hoverImageTitle: string;
  hoverImageDescription: string;
  hoverDescriptionSplash: string;
  hoverSplashImage: string;
  hoverIconQRCode: string;
  hoverIconAdmins: string;
  hoverIconInviteLink: string;
  hoverIconSubChatDescription: string;
  hoverIconSubChatSplashDescription: string;
  hoverSearchFilter: string;

  hoveResetMassageTooltip = false;

  nameLabelPopup: string;
  descriptionLabelPopup: string;

  @Input() chatEdit: IChat = {};
  @Input() chatType;
  @Input() createPopup: boolean;
  @Input() tokenAuth: IAuth;
  @Input() currentPhotoUploaded: any;

  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;

  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() composeMediaNotify = new EventEmitter<ISelectedFiles>();
  @Output() composeMessageNotify = new EventEmitter<boolean>();

  selectedMapMarker: MapMarker;
  selectedStore: Product;
  locationsPoolVisibility = false;

  /**
   * 0 -> close selected store
   * 1 -> open select store
   * 2 -> open select location then open store
   */
  storesPoolVisibility = 0;
  image: any;
  uploadedImage: any;
  storeSubscribtion = null;
  locationSubscribtion = null;
  chatName: string = null;
  admins: string[] = [];
  newSelectedAdmins: any[];
  constructor(
    private _ref: ChangeDetectorRef,
    private _fb: FormBuilder,
    private _validateForm: FormValidatorsService,
    public uiService: UiService,
    public subgroupService: SubChatsService,
    public _mobileStoresService: MobileStoresService,
    public _locationsService: LocationsService,
    public _memberService: MembersService,
    public appManagementService: AppMgmtService
  ) {}

  ngOnChanges() {
    if (this.chatEdit && this.chatEdit.admins) {
      this.admins = [...this.chatEdit.admins];
    }
  }

  ngOnDestroy() {
    if (this.storeSubscribtion) {
      this.storeSubscribtion.unsubscribe();
    }
    if (this.locationSubscribtion) {
      this.locationSubscribtion.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.chatEdit) {
      console.log('chat edit =>', this.chatEdit);
      if (this.chatEdit.localThumbnail) {
        this.image = this.chatEdit.localThumbnail;
        // this._ref.detectChanges();
      }
      if (this.chatEdit.marker && this.chatEdit.marker !== '-1') {
        this.selectedMapMarker = {
          id: this.chatEdit.marker
        };
      }
      if (this.chatEdit.mStore && this.chatEdit.mStore !== '-1') {
        this.selectedStore = {
          id: this.chatEdit.mStore
        };
      }
    }

    this.form = this.setFormGroup();
    if (this.chatEdit && this.chatEdit.marker) {
      this.locationSubscribtion = this._locationsService
        .getMapMarkerDetailsById$(this.chatEdit.marker)
        .subscribe(marker => {
          if (
            this.selectedMapMarker &&
            marker &&
            this.selectedMapMarker.id === marker.id
          ) {
            this.selectedMapMarker = marker;
            this._ref.detectChanges();
            if (this.locationSubscribtion) {
              this.locationSubscribtion.unsubscribe();
            }
          }
        });
      this._locationsService.requestMapMarkerWithId(this.chatEdit.marker);
    }

    if (this.chatEdit && this.chatEdit.mStore) {
      this.storeSubscribtion = this._mobileStoresService
        .getMStoreDetailsById(this.chatEdit.mStore)
        .subscribe(store => {
          if (
            this.selectedStore &&
            store &&
            this.selectedStore.id === store.id
          ) {
            this.selectedStore = store;
            this._ref.detectChanges();
            if (this.storeSubscribtion) {
              this.storeSubscribtion.unsubscribe();
            }
          }
        });
      this._mobileStoresService.getMStoreById(this.chatEdit.mStore);
    }

    this.chatName =
      this.subgroupService.chatType === 'Channel'
        ? this.subgroupService.chatType
        : this.subgroupService.chatType === VIRTUAL_APP
        ? 'Virutal app'
        : 'Chat Group';

    this.hoverImageTitle = `${this.chatName} Image`;
    this.hoverImageDescription = `Upload your ${this.chatName} image here.`;
    this.hoverIconSubChatDescription = `Elaborate your ${this.chatName}'s topic in a catchy language to encourage users to join.`;
    this.hoverSplashImage = `Upload the image that users will find on first joining the ${this.chatName}.`;
    this.hoverDescriptionSplash = `Make it capturing. Splash is the first page that users view on first joining your ${name}.
*Activate Splash and create in it engaging content.`;
    this.hoverIconSubChatSplashDescription = `Type your tagline or brief the main topic of the ${this.chatName}.`;
    this.hoverIconQRCode = `This is your ${this.chatName}'s QR code that users can share or scan to join.`;
    this.hoverIconAdmins = `Select your ${this.chatName} admins from your app users.`;
    this.hoverIconInviteLink = `Sharable links to publicize your ${this.chatName} on social media platforms and invite more users.`;
    this.hoverSearchFilter = `Create the search filters you need while configuring your App's Search module amd link them  here.`;

    this.isSelected(GROUP);
    if (this.createPopup) {
      this.form.patchValue({
        type: GROUP
      });
    }
  }

  addAdmin(selectedItems) {
    this.newSelectedAdmins = selectedItems;
    // console.log('here i am ', this.admins);
    this.admins = [...this.admins];
    this._ref.detectChanges();
  }
  getImage(event) {
    this.image = event;
    this._ref.detectChanges();
  }

  setFormGroup(): FormGroup {
    return this._fb.group({
      id: [(this.chatEdit && this.chatEdit.id) || null],
      title: [
        (this.chatEdit && this.chatEdit.title) || null,
        [Validators.required, groupNameValidator, noWhitespaceValidator]
      ],
      description: [
        (this.chatEdit && this.chatEdit.description) || null,
        [groupDescValidator]
      ],
      type: [
        (this.chatEdit && this.chatEdit.type) ||
          (this.chatType === VIRTUAL_APP ? CHANNEL : this.chatType)
      ],
      vapp: [
        (this.chatEdit && this.chatEdit.vapp) ||
          (this.chatType === VIRTUAL_APP ? 1 : 0)
      ],
      area: [(this.chatEdit && this.chatEdit.area) || 'null'],
      classification: [
        (this.chatEdit && this.chatEdit.classification) || 'null'
      ],
      category: [(this.chatEdit && this.chatEdit.category) || 'null'],
      splash: [(this.chatEdit && this.chatEdit.splash) || false],
      adFree: [(this.chatEdit && this.chatEdit.adFree) || 0],
      permanent: [(this.chatEdit && this.chatEdit.permanent) || 0],
      splash_config: this.setSplashFormGroup(),
      welcomeMessageId: [
        (this.chatEdit && this.chatEdit.welcomeMessageId) || null
      ]
    });
  }

  setSplashFormGroup() {
    const splashForm = this._fb.group({
      photo: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.image_url
            : null
          : null) || null
      ],
      image_url: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.image_url
            : null
          : null) || null
      ],
      title: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.title
            : null
          : null) || '',
        [Validators.required]
      ],
      desc: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.desc
            : null
          : null) || '',
        [Validators.required]
      ],
      btn_text: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.btn_text
            : null
          : null) || '',
        [Validators.required]
      ],
      btn_bgcolor: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.btn_bgcolor
            : null
          : null) || '#000000'
      ],
      btn_fgcolor: [
        (this.chatEdit
          ? this.chatEdit.splash_config
            ? this.chatEdit.splash_config.btn_fgcolor
            : null
          : null) || '#ffffff'
      ]
    });
    if (this.chatEdit && this.chatEdit.splash) {
      splashForm.enable();
    } else {
      splashForm.disable();
    }
    return splashForm;
  }
  onSubmit() {
    if (this.form.valid) {
      let groupFormValues = { ...this.form.value };
      groupFormValues = {
        ...groupFormValues,
        title: groupFormValues.title.trim(),
        splash: groupFormValues.splash ? 1 : 0,
        marker: this.selectedMapMarker ? this.selectedMapMarker.id : -1,
        mStore: this.selectedStore ? this.selectedStore.id : -1
      };

      if (this.form.value.id) {
        if (this.newSelectedAdmins && this.newSelectedAdmins.length > 0) {
          this._memberService.assignAdminsToChatInForm(
            this.chatEdit.id,
            this.newSelectedAdmins
          );
        }
        if (this.uploadedImage) {
          const subChat: any = {};
          subChat.file = this.uploadedImage;
          subChat.chat = groupFormValues;
          this.composeMediaNotify.emit(subChat);
          const newObject: IChat = { ...groupFormValues };
          newObject.localThumbnail = this.image;
          newObject.localMedia = this.image;
          this.subgroupService.updateStore(this.chatEdit.id, newObject);
        } else {
          this.subgroupService.updateSubGroup(
            this.chatEdit.id,
            groupFormValues
          );
        }
      } else {
        this.subgroupService.createSubGroup(groupFormValues);
      }

      if (this.currentPhotoUploaded.file && this.chatEdit && this.chatEdit.id) {
        const chat: IChat = {};
        const photo: Photo = { id: this.currentPhotoUploaded.file };
        chat.id = this.chatEdit.id;
        chat.photo = photo;
        this.subgroupService.setChatPhoto(chat);
      }

      if (this.createPopup) {
        this.closeEvent();

        this.subgroupService.subGroupCreationComplete(
          groupFormValues.type,
          groupFormValues.vapp
        );
      }
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  closeEvent() {
    if (!this.createPopup) {
      this.subgroupService.closeViewSubGroup();
    } else {
      this.closeNotify.emit(false);
    }
  }

  onFilesSelected(event: ISelectedFiles) {
    if (event.localFile) {
      // this.composeMediaNotify.emit(event);
      // this.closeEvent();
      this.uploadedImage = event;
    }
  }

  composeMessageEvent() {
    this.composeMessageNotify.emit(true);
  }

  get selectedChatWelcomeMessage$() {
    return this.subgroupService.getChatWelcomeMessage$(
      this.chatEdit.welcomeMessageId
    );
  }

  viewMessageEvent(message: IMessage) {
    if (message) {
      this.uiService.viewMessageEvent({ visible: true }, message);
    }
  }
  retryEvent(message: IMessage) {
    this.uiService.retryMediaOperation(message);
  }
  cancelEvent(message: IMessage) {
    this.uiService.cancelMediaOperation(message);
  }

  get mainChatEdit() {
    return ChatMiddleware.getMainChatFromChat(this.chatEdit);
  }

  toggelSwitch(e: boolean) {
    this.toggelSwith = e;
    this.form.patchValue({
      splash: e
    });
    if (!e && this.form) {
      this.form.get('splash_config').disable();
    } else if (this.form) {
      this.form.get('splash_config').enable();
    }
  }

  toggelSwitchAds(e: boolean) {
    this.form.patchValue({
      adFree: Number(e)
    });
  }

  toggelPermanentSwitch(event) {
    // console.log(event);
    this.form.patchValue({
      permanent: Number(event)
    });
  }

  colorChangedButtonBgColor(e) {
    this.form.patchValue({
      splash_config: {
        btn_bgcolor: e
      }
    });
  }

  colorChangedTextButtonColor(e) {
    this.form.patchValue({
      splash_config: {
        btn_fgcolor: e
      }
    });
  }
  setMapMarker(mapMarker: MapMarker) {
    this.selectedMapMarker = mapMarker;
    this.toggleLocationsPoolVisabilty(false);
  }
  removeSelectedMapMarkerAction(action: MapMarker) {
    this.selectedMapMarker = null;
  }
  locationsPoolEchoNotify(mode: number) {
    if (mode === 2) {
      this.toggleStoresPoolVisabilty(1);
    }
  }

  toggleLocationsPoolVisabilty(visible: boolean) {
    this.locationsPoolVisibility = visible;
  }
  toggleStoresPoolVisabilty(mode: number) {
    this.storesPoolVisibility = mode;
    if (mode === 2) {
      this.toggleLocationsPoolVisabilty(true);
    }
  }

  selectStoreEvent(storesBundel: BundleProduct[]) {
    this.selectedStore =
      storesBundel && storesBundel.length > 0 ? storesBundel[0].product : null;
    this.toggleStoresPoolVisabilty(0);
  }
  removeSelectedStoreEvent(action: BundleProduct) {
    this.selectedStore = null;
  }
  getSelectedStore() {
    return this.selectedStore ? [this.selectedStore] : [];
  }

  toggleTooltipVisibility(e) {
    this.hoveResetMassageTooltip = e;
  }

  isSelected(event) {
    switch (event) {
      case GROUP:
        this.nameLabelPopup = 'Chat Group Name';
        this.descriptionLabelPopup = 'Chat Group Description';
        break;
      case CHANNEL:
        this.nameLabelPopup = 'Channel Name';
        this.descriptionLabelPopup = 'Channel Description';
        break;
    }
  }
  get Style() {
    return { 'padding-top': '30px' };
  }

  deleteWelcomeMassage() {
    this.form.patchValue({
      welcomeMessageId: null
    });
  }
}
