<app-selector-frame
  style="width: 87em; display: contents;"
  (closeNotify)="cancelEvent()"
>
  <ng-container ngProjectAs="title">
    Select Tag(s)
  </ng-container>

  <ng-container ngProjectAs="[form-selector-search]">
    <app-search-form
      (searchFormSubmitNotfiy)="onSearchFormSubmit($event)"
      [showTooltip]="true"
      [positionTooltipTop]="115"
      [positionTooltipRight]="-110"
      [widthTooltip]="'max-content'"
      [textTooltip]="'Search users'"
    >
    </app-search-form>
  </ng-container>

  <ng-container ngProjectAs="[form-selector-create]">
    <ng-container *appShowIfHasPrivilege="privilegesName.edittag">
      <app-create-item-frame (addnewNotfiy)="addnewEvent(true)">
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new tag
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add new tag from the "Add new tag" field. Simply click the “+
          Add new tag” button and Create new Tag.
        </ng-container>
      </app-create-item-frame>
    </ng-container>
  </ng-container>

  <form
    form-selector-table
    class="tags-table"
    [formGroup]="selectorForm"
    (ngSubmit)="onSubmit()"
  >
    <app-tags-table
      [isPublic]="isPublic"
      [isPrivate]="isPrivate"
      [searchTerm]="searchTerm$ | async"
      formArrayName="tags"
    >
      <ng-template let-tag let-index="index">
        <div class="checked">
          <input
            class="check-checkbox"
            type="checkbox"
            [id]="tag?.id"
            [formControlName]="index"
          />
          <label class="check-Label" [for]="tag?.id"></label>
        </div>
      </ng-template>
    </app-tags-table>

    <div class="form-submit">
      <app-border-btn
        submit
        [type]="'submit'"
        [disabled]="!selectorForm?.get('tags')?.value"
        >Add</app-border-btn
      >
    </div>
  </form>
</app-selector-frame>

<app-modal-container *ngIf="showTagForm" (cancelNotfiy)="addnewEvent(false)">
  <app-tag-form
    [groupId]="(tagsService.selectedChat$ | async)?.inivite_link"
    (createTagNotify)="createTagEvent($event)"
    (cancelNotify)="addnewEvent(false)"
  >
  </app-tag-form>
</app-modal-container>
