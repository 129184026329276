import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { UiService } from '../../core/ui.service';

import { MainChat } from 'src/models/MainChat';
import { IMessage } from 'src/models/IMessage';
import {
  MEDIA_PROGRESS_THEME_COLOR,
  CHANNELS_THEME_COLOR,
  SHOW_REPLIES_COUNT,
  MessageMediaStatus,
  MessageTypes,
  MessageStyles,
  LinkPreviewOptions,
  MessageLinkPreviewStatus
} from 'src/models/constants';
import { slideToUp } from 'src/app/app-animations';

@Component({
  selector: 'app-post-cell',
  templateUrl: './post-cell.component.html',
  styleUrls: ['./post-cell.component.scss'],
  animations: [slideToUp],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCellComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chat: MainChat;
  @Input() message: IMessage;
  @Input() isSubChat: boolean;
  @Input() fixedShare = false;
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() isPublicChannel = true;

  @Output() retryNotify = new EventEmitter<IMessage>();
  @Output() cancelNotify = new EventEmitter<IMessage>();
  @Output() viewNotify = new EventEmitter<IMessage>();

  openShare = false;
  mType: typeof MessageTypes = MessageTypes;
  mStyles: typeof MessageStyles = MessageStyles;
  linkPreviewStyle: typeof LinkPreviewOptions = LinkPreviewOptions;
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  showRepliesCount = SHOW_REPLIES_COUNT;

  alerts: any[] = [
    {
      isShow: false,
      msg: `Link Copied`,
      timeout: 5000
    }
  ];
  themeColorMedia = MEDIA_PROGRESS_THEME_COLOR;
  themeColorFile = CHANNELS_THEME_COLOR;

  constructor(public uiService: UiService, private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.message && this.message.message_id) {
      this.uiService.getMessageCounters(this.message.group_id, [
        this.message.message_id
      ]);
    }
  }

  ngOnChanges(chng: SimpleChanges) {
    this.viewNotify.emit(this.message);
  }

  ngOnDestroy() {
    this.openShare = false;
  }

  get isShareable() {
    if (
      this.message &&
      this.message.message_id &&
      this.message.type &&
      (this.message.type === MessageTypes.TEXT ||
        this.message.type === MessageTypes.TEXT_FILE ||
        this.message.type === MessageTypes.PHOTO ||
        (this.message.type === MessageTypes.GIF &&
          this.message.media_id &&
          !this.message.media_id.endsWith('mp4')))
    ) {
      return true;
    }
    return false;
  }

  /* Component Events */
  selectEvent() {
    if (this.isSubChat) {
      this.uiService.selectSubChannelPost(this.message);
    } else {
      this.uiService.selectChannelPost(this.message);
    }
    if (!this.message.rcvdHistoryReply) {
      if (this.isSubChat) {
        this.uiService.requestHistory(
          this.message.message_id,
          null,
          null,
          this.message.group_id
        );
      } else {
        this.uiService.requestHistory(this.message.message_id);
      }
    }
  }

  likeEvent() {
    if (this.message && this.message.myLike) {
      this.uiService.unlikePost(this.message);
    } else if (this.message && !this.message.myLike) {
      this.uiService.likePost(this.message);
    }
  }

  closeShareEvent() {
    this.openShare = false;
  }

  shareEvent() {
    if (this.isShareable) {
      this.openShare = true;
      if (this.message && this.message.message_id) {
        this.uiService.sharePost(this.message);
      }
    }
  }

  get isDownloadable() {
    if (this.message) {
      if (
        (this.message.mediaStatus === MessageMediaStatus.DOWNLOADED ||
          this.message.mediaStatus === MessageMediaStatus.UPLOADED) &&
        (this.message.type === MessageTypes.PHOTO ||
          this.message.type === MessageTypes.GIF ||
          this.message.type === MessageTypes.VIDEO ||
          this.message.type === MessageTypes.VOICE ||
          this.message.type === MessageTypes.AUDIO ||
          this.message.type === MessageTypes.DOCUMENT)
      ) {
        return true;
      }
    }
    return false;
  }

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }

  fullScreenEvent(mediaStatus: string) {
    if (
      !this.hideHeader &&
      (mediaStatus === MessageMediaStatus.DOWNLOADED ||
        mediaStatus === MessageMediaStatus.UPLOADED)
    ) {
      this.uiService.showMediaScreen(this.message);
    }
  }

  recallEvent() {
    this.uiService.recallMessage(this.message);
  }

  editEvent() {
    // TODO: add edit message event
  }

  repostEvent() {
    // TODO: add repost message event
  }

  retryEvent($event) {
    if ($event) {
      this.retryNotify.emit(this.message);
    }
  }

  cancelEvent($event) {
    if ($event) {
      this.cancelNotify.emit(this.message);
    }
  }

  getMessageMenuStats() {
    this.uiService.getMessageMenuStats(this.message);
  }
  buttonClicked(event) {
    const buttonData = event;
    console.log('send_to_user_id =>', this.message);
    buttonData.to_user_id = this.message.sender_id;
    buttonData.chat_id = this.chat.id;
    this.uiService.getInlineMessageCallBack(buttonData);
  }
}
