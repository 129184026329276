<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">Add Location</ng-container>

  <ng-container ngProjectAs="[form-selector-create]">
    <ng-container *appShowIfHasPrivilege="privilegesName.editmarker">
      <app-create-item-frame (addnewNotfiy)="addNewLocationEvent(true)">
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new group
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add a new location and select it from the list by clicking on
          the Create New button
        </ng-container>
      </app-create-item-frame>
    </ng-container>
  </ng-container>

  <ng-container
    ngProjectAs="[form-selector-items]"
    *appShowIfHasPrivilege="privilegesName.listmarker"
  >
    <ng-container *ngIf="_locationsService.mapMarkers$ | async as mapMarkers">
      <ng-container *ngIf="mapMarkers?.length > 0; else isEmpty">
        <app-round-paging-btn
          class="paging-float-btn"
          (nextBtnClicked)="_locationsService.getNextPage()"
          (previousBtnClicked)="_locationsService.getPrevPage()"
        >
        </app-round-paging-btn>
        <app-selectable-cell-frame
          *ngFor="let mapMarker of mapMarkers; trackBy: TrackByFunction"
          [symbolColor]="locationsFet?.symbolColor"
          [symbolImage]="locationsFet?.symbolImage"
          [coverColor]="locationsFet?.coverColor"
          [coverPhoto]="mapMarker?.image_url"
          [title]="mapMarker?.title"
          [selectorId]="mapMarker?.id"
          [isSelected]="isMessageSelected(mapMarker?.id)"
          (checkClick)="setSelectMessage(mapMarker)"
          (checkChanged)="resetSelectMessage()"
        >
        </app-selectable-cell-frame>
      </ng-container>
    </ng-container>

    <ng-template #isEmpty>
      <app-empty-item-frame
        [emptySymbol]="locationsFet?.symbolImage"
        [emptyTitle]="locationsFet?.emptyTitle"
        [emptyDesc]="locationsFet?.emptyPoolDesc"
      >
      </app-empty-item-frame>
    </ng-template>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-items]">
    <ng-container *ngIf="_uiService?.privileges$ | async as Privileges">
      <ng-container
        *ngIf="Privileges?.indexOf(privilegesName.listmarker) === -1"
      >
        <app-empty-item-frame
          [emptySymbol]="locationsFet?.symbolImage"
          [emptyTitle]="locationsFet?.emptyTitle"
          [emptyDesc]="locationsFet?.emptyPoolDesc"
        >
        </app-empty-item-frame>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn
        *ngIf="currentSelectMessage"
        next
        (btnClicked)="insertEvent()"
      >
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>
<app-modal-container
  *ngIf="showCreateCompose"
  (cancelNotfiy)="addNewLocationEvent(false)"
>
  <app-location-mini-form
    (closeMiniFormNotifiy)="addNewLocationEvent(false)"
    [showCancel]="true"
    [mainGroupId]="(_authService.authCollection$ | async).main_group_id"
    [owner]="selectedChatId"
  >
  </app-location-mini-form>
</app-modal-container>
