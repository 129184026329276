import { MarketPoolComponent } from './market-pool/market-pool.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule
  ],
  declarations: [MarketPoolComponent],
  exports: [MarketPoolComponent]
})
export class MarketPoolModule {}
