import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from 'src/models/AppState';
import * as StoreMenuUIActionTypes from './storeMenuUI.actions';
import { StoreMenu } from 'src/models/StoreMenu';

@Injectable({
  providedIn: 'root'
})
export class StoreMenuUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  createStoreMenu(storeMenusDetails: StoreMenu): void {
    this._store.dispatch(
      new StoreMenuUIActionTypes.CreateStoreMenu(storeMenusDetails)
    );
  }

  getStoreMenu(id: string): void {
    this._store.dispatch(new StoreMenuUIActionTypes.GetStoreMenu(id));
  }

  setStoreMenu(storeMenu: string, storeMenusDetails: StoreMenu): void {
    this._store.dispatch(
      new StoreMenuUIActionTypes.SetStoreMenu(storeMenu, storeMenusDetails)
    );
  }

  addStoreMenuItem(storeMenu: string, items: any[]): void {
    this._store.dispatch(
      new StoreMenuUIActionTypes.AddStoreMenuItem(storeMenu, items)
    );
  }

  RemoveStoreMenuItem(storeMenu: string, itemIDs: string[]): void {
    this._store.dispatch(
      new StoreMenuUIActionTypes.RemoveStoreMenuItem(storeMenu, itemIDs)
    );
  }

  // paging dispatchers
  getNextStoreMenuPage(): void {
    this._store.dispatch(new StoreMenuUIActionTypes.GetNextStoreMenuPage());
  }

  getPrevStoreMenuPage(): void {
    this._store.dispatch(new StoreMenuUIActionTypes.GetPrevStoreMenuPage());
  }

  receivedStoreMenuHistoryChunk(
    eop: number,
    sop: number,
    currentPage: StoreMenu[]
  ): void {
    this._store.dispatch(
      new StoreMenuUIActionTypes.ReceivedStoreMenuHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  storeMenuReceived(sMenu: StoreMenu): void {
    this._store.dispatch(new StoreMenuUIActionTypes.StoreMenuReceived(sMenu));
  }

  resetStoreMenuPaging(): void {
    this._store.dispatch(new StoreMenuUIActionTypes.ResetStoreMenuPaging());
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new StoreMenuUIActionTypes.SetStatusAsIdle());
  }
  deleteStoreMenu(id: string) {
    this._store.dispatch(new StoreMenuUIActionTypes.DeleteStoreMenu(id));
  }
  storeMenuDeleted(id: string) {
    this._store.dispatch(new StoreMenuUIActionTypes.StoreMenuDeleted(id));
  }

  refreshStoreMenu() {
    this._store.dispatch(new StoreMenuUIActionTypes.RefreshStoreMenu());
  }
}
