import { StorageService } from './storage.service';
import { RootStoreSelectors } from '../store/root-store.selectors';
import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { AuthGateway } from 'src/app/network/gateway/auth.gateway';
import { TOKEN_LOCAL_STORAGE_KEY } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../network/gateway/auth.gateway";
import * as i2 from "../store/root-store.selectors";
import * as i3 from "../store/auth/auth.dispatchers";
import * as i4 from "./storage.service";
export class AuthService {
    constructor(_authGateway, _selectors, _authDispatchers, _storageService) {
        this._authGateway = _authGateway;
        this._selectors = _selectors;
        this._authDispatchers = _authDispatchers;
        this._storageService = _storageService;
        // this.authCollection$.subscribe(console.log);
    }
    reopenNewConnection() {
        this._authDispatchers.reopenNewConnection();
    }
    /** Auth dispacters actions on store  */
    get authCollection$() {
        return this._selectors.authCollection$;
    }
    get authRem() {
        return this._authDispatchers.authRem;
    }
    get token() {
        return this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
    }
    setRem(rem) {
        this._authDispatchers.setAuthRem(rem);
    }
    logout() {
        this._authDispatchers.logOut();
    }
    selectChat(chatId) {
        this._authDispatchers.setChatSelect(chatId);
    }
    updateProgressWeb(no, mode) {
        this._authDispatchers.setAppProgress(no, mode);
    }
    updateWebMode(no) {
        this._authDispatchers.setAppMode(no);
    }
    getCountryISO() {
        return this._authDispatchers.getCountryData();
    }
    emailTacSuccess(form) {
        this._authDispatchers.emailTacSuccess(form.email, form.appName, form.name, form.password);
    }
    reciveAuthToken(token) {
        const auth = {};
        auth.token = token;
        this._authDispatchers.reciveAuthToken(auth);
    }
    createAccountSuccess(password) {
        this._authDispatchers.createAccountSuccess(password);
    }
    selectTemplate(templateID) {
        this._authDispatchers.selectTemplate(templateID);
    }
    loginUseEmail() {
        this._authDispatchers.loginByEmail();
    }
    requestAdminQR() {
        this._authDispatchers.requestAdminQR();
    }
    // Auth Gateway //
    loginByEmail(form) {
        return this._authGateway.loginForm(form);
    }
    createAccount(form, tac) {
        return this._authGateway.tacForm(form, tac);
    }
    getEmailTac(form) {
        return this._authGateway.signUpForm(form);
    }
    getResetPasswordTac(email) {
        return this._authGateway.getResetPasswordTac(email);
    }
    resetPassword(email, tac, newPassword, confirmNewPassword) {
        return this._authGateway.resetPassword(email, tac, newPassword, confirmNewPassword);
    }
    getTemplatesByCategory(category) {
        return this._authGateway.getTemplatesByCategory(category);
    }
    getTemplateById(id) {
        return this._authGateway.getTemplatesByCategory(null, id);
    }
    getChatData(chat_id) {
        this._authDispatchers.getChatData(chat_id);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AuthGateway), i0.ɵɵinject(i2.RootStoreSelectors), i0.ɵɵinject(i3.AuthDispatchers), i0.ɵɵinject(i4.StorageService)); }, token: AuthService, providedIn: "root" });
