import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ItemDispatchers } from './item.dispatchers';
import * as ItemActions from './item.actions';
import { ItemActionTypes } from './item.actions';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { initNewItem, extractItemData } from './item.middleware';
import { LocalItem, ContainerChildren } from 'src/models/ChannelAppLocalConfig';
import { UIDispatchers } from '../ui/ui.dispatchers';
import {
  REACHED_MAX_ITEM_LIMIT,
  REACHED_MIN_ITEM_LIMIT,
  TabTypes,
  ALREADY_HAD_MAP
} from 'src/models/constants';
import { MyPageDispatchers } from '../channelMyPage/myPage.dispatchers';
import { alreadyHadMap } from '../channelAppConfig/appConfig.middleware';

import * as isEqual from 'lodash.isequal';
import {
  SEARCH_COMPONENT,
  MAP_SEARCH_COMPONENT
} from 'src/app/+merchant/+dashboard/app-mgmt/templates';
import { ComponentDispatchers } from '../channelAppComponents/component.dispatchers';

@Injectable()
export class ItemEffects {
  @Effect({ dispatch: false })
  addItemRequest = this.actions$.pipe(
    ofType(ItemActionTypes.ADD_ITEM_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.itemReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.containerReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.appConfigReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, items, containers, sysConfig]) => {
      const action: ItemActions.AddItemRequest = <ItemActions.AddItemRequest>(
        val
      );
      let itemsOfComponent: LocalItem[] = [];
      itemsOfComponent = items.filter(
        item => item.parentComponentRef === action.component.ref
      );
      if (
        action.component.max_items &&
        itemsOfComponent.length >= action.component.max_items
      ) {
        this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
      } else {
        const newItem = initNewItem(
          action.component,
          action.specialItemValues,
          items
        );
        if (newItem.link === TabTypes.MAP && alreadyHadMap(containers, items)) {
          this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
        } else {
          let containerChildren: ContainerChildren = null;
          switch (newItem.link) {
            case TabTypes.SEARCH: {
              containerChildren = extractItemData(
                JSON.parse(SEARCH_COMPONENT),
                newItem,
                sysConfig
              );

              break;
            }
            default:
              break;
          }

          if (containerChildren) {
            this._componentDispatchers.addComponents(
              containerChildren.components
            );
            newItem.component = containerChildren.components;
          }

          this._itemDispatchers.addItem(newItem);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  deleteItemRequest = this.actions$.pipe(
    ofType(ItemActionTypes.DELETE_ITEM_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.itemReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.componentReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, items, components]) => {
      const action: ItemActions.DeleteItemRequest = <
        ItemActions.DeleteItemRequest
      >val;
      let itemsOfComponent: LocalItem[] = [];
      itemsOfComponent = items.filter(
        item => item.parentComponentRef === action.item.parentComponentRef
      );
      const parentComponent = components.find(
        comp => comp.ref === action.item.parentComponentRef
      );
      if (
        parentComponent &&
        parentComponent.min_items &&
        itemsOfComponent.length <= parentComponent.min_items
      ) {
        this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
      } else {
        this._itemDispatchers.deleteItem(action.item);
      }
    })
  );

  @Effect({ dispatch: false })
  updateItemLinkRequest = this.actions$.pipe(
    ofType(ItemActionTypes.REQUEST_UPDATE_ITEM_LINK),
    withLatestFrom(
      this._store
        .select(state => state.itemReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.containerReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, items, containers]) => {
      const action: ItemActions.RequestUpdateItemLink = <
        ItemActions.RequestUpdateItemLink
      >val;
      const targetItem = items.find(item => item.ref === action.itemRef);
      if (
        action.itemLink === TabTypes.MAP &&
        targetItem.link !== TabTypes.MAP &&
        alreadyHadMap(containers, items)
      ) {
        this._uiDispatchers.showPopup(ALREADY_HAD_MAP);
      } else {
        this._itemDispatchers.updateItemLink(
          action.itemRef,
          action.itemLink,
          action.pageId
        );
      }
    })
  );

  @Effect({ dispatch: false })
  updateItemLink = this.actions$.pipe(
    ofType(ItemActionTypes.UPDATE_ITEM_LINK),
    map((action: ItemActions.UpdateItemLink) => {
      if (action.itemLink === TabTypes.PAGE && !action.pageId) {
        const pageRef = Date.now() + '';
        this._itemDispatchers.updateItem(action.itemRef, {
          page_ref: pageRef,
          page_id: null,
          template_id: null
        });
        this._myPageDispatchers.createMyPage(pageRef);
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _componentDispatchers: ComponentDispatchers,
    private _itemDispatchers: ItemDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _myPageDispatchers: MyPageDispatchers,
    private _store: Store<AppState>
  ) {}
}
