<div class="action">
  <div class="action-title">
    {{ title }}
  </div>
  <div class="delete">
    <img
      *ngIf="!hideRemove"
      src="./assets/img/deletholiday.png"
      (click)="delete()"
    />
  </div>
</div>
