<div class="container">
  <div
    appThrottleClick
    (throttleClick)="getPreviousPage()"
    [throttleTime]="300"
    class="left-arrow clickable-cursor"
  >
    <img src="./assets/img/back-btn.svg" />
  </div>
  <div
    appThrottleClick
    (throttleClick)="getNextPage()"
    [throttleTime]="300"
    class="right-arrow clickable-cursor"
  >
    <img src="./assets/img/next-btn.svg" />
  </div>
</div>
