<div class="login-form no-copyable-item">
  <div class="login-form-header">
    Verify your Account
  </div>

  <div class="login-form-title">
    Please enter the verification code we sent to your email {{ email }}
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="text" hidden formControlName="appName" />
    <input type="text" hidden formControlName="password" />
    <input type="text" hidden formControlName="email" />
    <input type="text" hidden formControlName="tac" [(value)]="tacValue" />

    <div class="login-form-form">
      <mat-form-field floatLabel="never" class="login-form-filed">
        <input
          matInput
          autofocus
          #fst
          (paste)="pasteUrl($event.clipboardData.getData('text/plain'))"
          type="text"
          id="1"
          formControlName="fst"
          min="0"
          max="9"
          maxlength="1"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (input)="onInputEntry($event, '2')"
          (change)="
            setTacValue(fst?.value + sec?.value + thrid?.value + fourth?.value)
          "
        />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="login-form-filed">
        <input
          matInput
          #sec
          type="text"
          id="2"
          formControlName="sec"
          min="0"
          max="9"
          maxlength="1"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (input)="onInputEntry($event, '3')"
          (change)="
            setTacValue(fst?.value + sec?.value + thrid?.value + fourth?.value)
          "
        />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="login-form-filed">
        <input
          #thrid
          matInput
          type="text"
          id="3"
          formControlName="third"
          min="0"
          max="9"
          maxlength="1"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (input)="onInputEntry($event, '4')"
          (change)="
            setTacValue(fst?.value + sec?.value + thrid?.value + fourth?.value)
          "
        />
      </mat-form-field>

      <mat-form-field floatLabel="never" class="login-form-filed">
        <input
          #fourth
          matInput
          type="text"
          id="4"
          formControlName="fourth"
          min="0"
          max="9"
          maxlength="1"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          (change)="
            setTacValue(fst?.value + sec?.value + thrid?.value + fourth?.value)
          "
        />
      </mat-form-field>
    </div>

    <div class="errors" *ngIf="form?.errors?.wrongTac">Wrong code</div>

    <div class="login-form-submit">
      <app-border-btn next invert lg [type]="'submit'" [disabled]="!form.valid">
        Get Started <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
      </app-border-btn>
    </div>
  </form>

  <div class="login-form-terms">
    <div>Didn't receive the verification code?</div>
    <div>Please check your junk/spam folder or click to resend code</div>
    <app-link-btn (btnClicked)="reSend()">
      <ng-container ngProjectAs="link-content-clicked">
        Resend Code
      </ng-container>
    </app-link-btn>
  </div>
</div>
