/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subscription-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i3 from "../../shared/components/modal-container/modal-container.component";
import * as i4 from "../subscription-price-table/subscription-price-table.component.ngfactory";
import * as i5 from "../subscription-price-table/subscription-price-table.component";
import * as i6 from "../subscription.service";
import * as i7 from "@angular/common";
import * as i8 from "../subscription-confirm-cancel/subscription-confirm-cancel.component.ngfactory";
import * as i9 from "../subscription-confirm-cancel/subscription-confirm-cancel.component";
import * as i10 from "../subscription-confirm/subscription-confirm.component.ngfactory";
import * as i11 from "../subscription-confirm/subscription-confirm.component";
import * as i12 from "./subscription-modal.component";
var styles_SubscriptionModalComponent = [i0.styles];
var RenderType_SubscriptionModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubscriptionModalComponent, data: {} });
export { RenderType_SubscriptionModalComponent as RenderType_SubscriptionModalComponent };
function View_SubscriptionModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalContainerComponent_0, i2.RenderType_ModalContainerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalContainerComponent, [i1.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-subscription-price-table", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SubscriptionPriceTableComponent_0, i4.RenderType_SubscriptionPriceTableComponent)), i1.ɵdid(3, 638976, null, 0, i5.SubscriptionPriceTableComponent, [i6.SubscriptionService], { plans: [0, "plans"], payStore: [1, "payStore"] }, { closeNotify: "closeNotify" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.pricePlans); var currVal_1 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform(((_co.subscriptionService == null) ? null : _co.subscriptionService.paymentStore$))); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_SubscriptionModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalContainerComponent_0, i2.RenderType_ModalContainerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalContainerComponent, [i1.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-subscription-confirm-cancel", [], null, [[null, "closeNotify"], [null, "backNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } if (("backNotify" === en)) {
        var pd_1 = (_co.resetPlan() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SubscriptionConfirmCancelComponent_0, i8.RenderType_SubscriptionConfirmCancelComponent)), i1.ɵdid(3, 49152, null, 0, i9.SubscriptionConfirmCancelComponent, [], { plan: [0, "plan"], url: [1, "url"] }, { closeNotify: "closeNotify", backNotify: "backNotify" })], function (_ck, _v) { var currVal_0 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.canceled_plan); var currVal_1 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.canceled_url); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_SubscriptionModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-modal-container", [], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.closeEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ModalContainerComponent_0, i2.RenderType_ModalContainerComponent)), i1.ɵdid(1, 49152, null, 0, i3.ModalContainerComponent, [i1.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-subscription-confirm", [], null, [[null, "closeNotify"], [null, "backNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } if (("backNotify" === en)) {
        var pd_1 = (_co.resetPlan() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_SubscriptionConfirmComponent_0, i10.RenderType_SubscriptionConfirmComponent)), i1.ɵdid(3, 49152, null, 0, i11.SubscriptionConfirmComponent, [], { plan: [0, "plan"], url: [1, "url"] }, { closeNotify: "closeNotify", backNotify: "backNotify" })], function (_ck, _v) { var currVal_0 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.plan); var currVal_1 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.url); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_SubscriptionModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubscriptionModalComponent_4)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!((_v.context.ngIf == null) ? null : _v.context.ngIf.plan) && !((_v.context.ngIf == null) ? null : _v.context.ngIf.canceled_plan)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.canceled_plan); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.plan); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SubscriptionModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_SubscriptionModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.subscriptionService == null) ? null : _co.subscriptionService.paymentStore$))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubscriptionModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subscription-modal", [], null, null, null, View_SubscriptionModalComponent_0, RenderType_SubscriptionModalComponent)), i1.ɵdid(1, 245760, null, 0, i12.SubscriptionModalComponent, [i6.SubscriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubscriptionModalComponentNgFactory = i1.ɵccf("app-subscription-modal", i12.SubscriptionModalComponent, View_SubscriptionModalComponent_Host_0, {}, {}, []);
export { SubscriptionModalComponentNgFactory as SubscriptionModalComponentNgFactory };
