import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContainerComponent {
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this._ref.nativeElement === event.target) {
      event.stopPropagation();
      this.cancelNotfiy.emit(true);
    }
  }

  constructor(private _ref: ElementRef) {}
  onDragEvents(event) {
    // event.preventDefault();
    event.stopPropagation();
    console.log('here i am');
  }
}
