import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit
} from '@angular/core';

import { TableService } from '../table.service';
import { AbstractItem, AbstractTableColumn } from 'src/models/AbstractItem';
import { ISection } from 'src/models/ISection';
import { UserStatus } from 'src/models/constants';

// note: styles of this component shared
@Component({
  selector: 'app-configurable-table',
  templateUrl: './configurable-table.component.html',
  styleUrls: ['./configurable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurableTableComponent implements OnChanges, OnInit {
  @Output() nextBtnClicked = new EventEmitter<boolean>();
  @Output() previousBtnClicked = new EventEmitter<boolean>();

  @Input() searchTerm: string;
  @Input() searchBy: number;
  @Input() tableService: TableService;
  @Input() list: any[];
  @Input() tableColumns: AbstractTableColumn[];
  @Input() statusUI;
  @Input() userState: string = UserStatus.ACTIVE;

  @Input() empty: ISection;
  @Input() mapRoles = null;
  @Input() accountTypes: string[];
  @Input() isLoading;
  userStatus = UserStatus;

  @ContentChild(TemplateRef, { static: false }) templateRef;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['searchTerm'];
    const searchByChange = changes['searchBy'];
    if (chng && !chng.firstChange) {
      this.tableService.resetPaging(
        this.searchTerm,
        this.userState,
        this.mapRoles,
        this.accountTypes
      );

      // this.tableService.resetPaging(this.searchTerm, this.userState);
    } else if (searchByChange && !searchByChange.firstChange) {
      this.tableService.resetPaging(
        this.searchTerm,
        this.userState,
        this.mapRoles,
        this.accountTypes
      );

      // this.tableService.resetPaging(this.searchTerm, this.userState);
    }
  }

  TrackByFunction(index, item: AbstractItem) {
    return item.id;
  }

  TrackByFields(index) {
    return index;
  }

  getNextPage() {
    this.tableService.getNextPage(this.searchTerm);
    this.nextBtnClicked.emit(true);
  }

  getPreviousPage() {
    this.tableService.getPreviousPage(this.searchTerm);
    this.previousBtnClicked.emit(true);
  }
}
