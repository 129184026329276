// libs import
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxMasonryComponent } from 'ngx-masonry';

// store import
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ProfileDispatchers } from 'src/app/store/profile/profile.dispatchers';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { MainChatDispatchers } from 'src/app/store/mainChats/mainChat.dispatchers';

// schema import
import { MainChat } from 'src/models/MainChat';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UrlPreviewMeta } from 'src/models/MetaData';
import { IProfile } from 'src/models/IProfile';
import { IMyProfile } from 'src/models/IMyProfile';
import { ChannelMemberReplies } from 'src/models/ChannelMember';
import { UIState } from 'src/models/UIState';
import * as MessageMethods from 'src/models/IMessage';
import { MessageControls } from 'src/models/MessageParts';
import { InstantArticle } from 'src/models/InstantArticle';
import { MenuCallBackStats } from 'src/models/MenuCallBackStats';
import {
  CHANNELS,
  CHATS,
  INDIVIDUAL,
  MessageMediaStatus,
  MessageTypes
} from 'src/models/constants';
import { CalendarTimetable } from 'src/models/Calendar';
import { IChat } from 'src/models/IChat';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private _replyRcvd = new BehaviorSubject<MessageMethods.IMessage>(null);
  public replyRcvd$ = this._replyRcvd.asObservable();

  private _historyMsgRcvd = new BehaviorSubject<MessageMethods.IMessage>(null);
  public historyMsgRcvd$ = this._historyMsgRcvd.asObservable();

  private _msgRcvd = new BehaviorSubject<boolean>(false);
  public msgRcvd$ = this._msgRcvd.asObservable();

  private _scrollToUp = new BehaviorSubject<boolean>(false);
  public scrollToUp$ = this._scrollToUp.asObservable();

  private _scrollToBottom = new BehaviorSubject<boolean>(false);
  public scrollToBottom$ = this._scrollToBottom.asObservable();

  public _previewMessageType = new BehaviorSubject<string>(null);
  public previewMessageType$ = this._previewMessageType.asObservable();

  public _previewFiles: BehaviorSubject<File> = new BehaviorSubject(null);
  public previewFiles$: Observable<File> = this._previewFiles.asObservable();

  public _previewMediaMetadata = new BehaviorSubject<UrlPreviewMeta>({});
  public previewMediaMetadata$ = this._previewMediaMetadata.asObservable();

  public _previewMediaError = new BehaviorSubject<boolean>(false);
  public previewMediaError$ = this._previewMediaError.asObservable();

  public _recordAdd = new Subject<{
    index: number;
    recordType: string;
    id: string;
    ref: string;
  }>();
  public recordAdd$ = this._recordAdd.asObservable();

  masonryComponent: NgxMasonryComponent = null;

  // UI variables
  showCompose = false;
  isComposeToGroup = true;

  private _messageSendingType = new BehaviorSubject<string>(null);
  public messageSendingType$ = this._messageSendingType.asObservable();

  // variables used by notify service
  private timeout;
  public notifyCount = 0;
  private prevTitle: string;
  public chatsTab: any;
  public channalsTab: any;

  constructor(
    private _selectors: RootStoreSelectors,
    private _messageDispatchers: MessageDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _mainChatDispatchers: MainChatDispatchers,
    private _profileDispatchers: ProfileDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _titleService: Title
  ) {}

  composeEvent(type: string, isCompose?: boolean): void {
    this.backFromGroupReplies();
    this._messageSendingType.next(type);
    this.isComposeToGroup = isCompose || false;
    this.showCompose = true;
  }
  closeComposeEvent() {
    this.showCompose = false;
  }

  replyRcvdInSelectedChatNotify(msg: MessageMethods.IMessage) {
    this._replyRcvd.next(msg);
  }

  historyMsgRcvdInSelectedChatNotify(msg: MessageMethods.IMessage) {
    this._historyMsgRcvd.next(msg);
  }

  msgRcvdInSelectedChatNotify() {
    this._msgRcvd.next(true);
    setTimeout(() => {
      this._msgRcvd.next(false);
    }, 10);
  }

  scrollToUpMethod() {
    this._scrollToUp.next(true);
    setTimeout(() => {
      this._scrollToUp.next(false);
    }, 300);
  }

  scrollToBottomMethod() {
    this._scrollToBottom.next(true);
    setTimeout(() => {
      this._scrollToBottom.next(false);
    }, 300);
  }

  blink(msg: string, count: number = 5): void {
    if (this.notifyCount === 0) {
      this.prevTitle = this._titleService.getTitle();
    }
    this.notifyCount = this.notifyCount + 1;
    msg = this.notifyCount + ' ' + msg;
    const step = () => {
      const newTitle =
        this._titleService.getTitle() === this.prevTitle ? msg : this.prevTitle;

      this._titleService.setTitle(newTitle);

      if (document.hasFocus() || count === 0) {
        this.notifyCount = 0;
        this._titleService.setTitle(this.prevTitle);
      } else {
        this.timeout = setTimeout(step.bind(this), 1000);
      }
    };

    clearTimeout(this.timeout);
    step();
  }

  setTitle() {
    // TODO: set title for web app
  }

  selectTab(type: string) {
    if (CHANNELS === type) {
      if (this.channalsTab) {
        this.channalsTab.nativeElement.click();
      } else {
        // TODO: for merchant select message
      }
    } else if (CHATS === type) {
      if (this.chatsTab) {
        this.chatsTab.nativeElement.click();
      }
    }
  }

  /** Profile, MainChat and Chat actions */
  requestHistory(
    parentMsgID?: string,
    userID?: string,
    requestOnce?: boolean,
    subChatID?: string
  ) {
    this._mainChatDispatchers.requestHistory(
      parentMsgID,
      userID,
      requestOnce,
      subChatID
    );
    if (this.masonryComponent) {
      this.masonryComponent.layout();
    }
  }

  deselectChat(mainChat: MainChat, lastTypedMsg: string): void {
    if (mainChat.type === INDIVIDUAL) {
      this._profileDispatchers.deselectProfile(mainChat.id, lastTypedMsg);
    } else {
      this._chatDispatchers.deselectChat(mainChat.id, lastTypedMsg);
    }
  }

  getMessageCounters(chatID: string, messageIDs: string[]): void {
    this._chatDispatchers.requestChatCounters(chatID, messageIDs);
  }

  /** Message Actions */
  resetNumberOfNotViewedMessages(mainChatID: string) {
    this._mainChatDispatchers.resetNumberOfNotViewedMessages(mainChatID);
  }

  get effectiveSelectedSubGroupMessage$(): Observable<MessageMethods.IMessage> {
    return this._selectors.getEffectiveSelectedSubGroupMessage$();
  }

  resetNumberOfUnreadReplies(messageID: string) {
    this._messageDispatchers.resetNumberOfUnreadReplies(messageID);
  }

  /************************************************************************************/

  sendTextMessage(
    mainChat: MainChat,
    parentMessage: MessageMethods.IMessage,
    userId: string,
    text: string,
    misc?: MessageControls
  ) {
    this._messageDispatchers.sendTextMessage(
      mainChat,
      parentMessage,
      userId,
      text,
      misc
    );
  }

  sendInstantUrl(
    mainChat: MainChat,
    parentMessage: MessageMethods.IMessage,
    userId: string,
    instantArticle: InstantArticle,
    misc?: MessageControls
  ) {
    this._messageDispatchers.sendInstantUrlMessage(
      mainChat,
      parentMessage,
      userId,
      instantArticle,
      misc
    );
  }

  sendMediaMessage(
    mainChat: MainChat,
    parentMessage: MessageMethods.IMessage,
    userId: string,
    file: File,
    mediaType: string,
    previewMetadata?: UrlPreviewMeta,
    blobUrl?: string,
    misc?: MessageControls
  ) {
    this._messageDispatchers.sendMediaMessage(
      mainChat,
      parentMessage,
      userId,
      file,
      mediaType,
      previewMetadata,
      blobUrl,
      misc
    );
  }

  sendCalendarMessage(
    mainChat: MainChat,
    parentMessage: MessageMethods.IMessage,
    userId: string,
    calendar: CalendarTimetable,
    misc?: MessageControls
  ) {
    this._messageDispatchers.sendCalendarMessage(
      mainChat,
      parentMessage,
      userId,
      calendar,
      misc
    );
  }

  /*****************************************************************************************************/

  getBlobUrl(selectedFiles: ISelectedFiles) {
    this._messageDispatchers.getBlobUrl(selectedFiles);
  }

  cancelMediaOperation(message: MessageMethods.IMessage): void {
    this._messageDispatchers.cancelMediaOperation(message);
  }

  retryMediaOperation(message: MessageMethods.IMessage): void {
    this._messageDispatchers.retryMediaOperation(message);
  }

  previewLinkMessage(message: MessageMethods.IMessage): void {
    this._messageDispatchers.previewLinkMessage(message);
  }

  likePost(msg: MessageMethods.IMessage) {
    if (!msg.message_id) {
      return;
    }
    this._messageDispatchers.likeMessage(msg);
  }

  unlikePost(msg: MessageMethods.IMessage) {
    if (!msg.message_id) {
      return;
    }
    this._messageDispatchers.unlikeMessage(msg);
  }

  sharePost(msg: MessageMethods.IMessage) {
    if (!msg.message_id) {
      return;
    }
    this._messageDispatchers.shareMessage(msg);
  }

  recallMessage(msg: MessageMethods.IMessage) {
    this._messageDispatchers.recallMessage(msg);
  }

  viewMessageEvent($event, message: MessageMethods.IMessage) {
    if ($event.visible) {
      // Message in view port
      if (
        (message.type === MessageTypes.TEXT ||
          message.type === MessageTypes.ARTICLE) &&
        !message.linkPreviewStatus
      ) {
        this._messageDispatchers.previewLinkMessage(message);
      }
      if (!message.isViewed) {
        this.viewMessage(message);
      }

      if (
        !message.localMedia &&
        !message.mediaStatus &&
        message.type !== MessageTypes.VIDEO &&
        message.type !== MessageTypes.AUDIO &&
        message.message_id &&
        message.media_id
      ) {
        this.downloadMediaFile(message);
      }

      if (
        (message.type === MessageTypes.VIDEO ||
          message.type === MessageTypes.AUDIO) &&
        (message.mediaStatus !== MessageMediaStatus.DOWNLOADED &&
          message.mediaStatus !== MessageMediaStatus.UPLOADED)
      ) {
        this._messageDispatchers.cancelDownloadMediaMessage(message);
      }
    } else {
      // Message Out Of view port
      if (
        message.mediaStatus === MessageMediaStatus.DOWNLOADING &&
        message.loadingProgress &&
        message.loadingProgress < 40
      ) {
        this._messageDispatchers.cancelDownloadMediaMessage(message);
      }
    }
  }

  downloadMediaFile(message: MessageMethods.IMessage): void {
    this._messageDispatchers.downloadMediaMessage(message);
  }

  messageViewed(mainChat) {
    this._mainChatDispatchers.decrementNumberOfNotViewedMessages(mainChat);
  }

  private viewMessage(msg: MessageMethods.IMessage) {
    if (!msg.isViewed && !msg.loggedInIsSender && !msg.reply_to_message_id) {
      const mainChat: MainChat = {};
      if (!msg.group_id) {
        mainChat.id = msg.sender_id;
      } else {
        mainChat.id = msg.group_id;
      }
      this._mainChatDispatchers.decrementNumberOfNotViewedMessages(mainChat);
    }
    this._messageDispatchers.messageIsViewed(msg);
  }
  getMessageMenuStats(message: MessageMethods.IMessage) {
    this.selectedMessageForStats(message);
    this._messageDispatchers.getMessageMenuStats(message.message_id);
  }
  /** UI Actions */
  selectChat(chat: MainChat): void {
    this._uiDispatchers.hideDragDropScreen();
    this._uiDispatchers.hidePreview();
    this._uiDispatchers.chatSelected(chat);
  }

  selectedMessage(parentMessage: MessageMethods.IMessage) {
    this._uiDispatchers.messageSelected(parentMessage);
    this._uiDispatchers.groupRepliesSelected();
  }
  selectSubGroupMessage(parentMessage: MessageMethods.IMessage) {
    this._uiDispatchers.subParentMessageSelected(parentMessage);
  }

  selectedMessageForStats(message: MessageMethods.IMessage) {
    this._uiDispatchers.messageSelectedForStats(message);
  }

  backFromGroupReplies() {
    this._uiDispatchers.backFromGroupReplies();
    this._uiDispatchers.hidePreview();
    if (this.masonryComponent) {
      this.masonryComponent.layout();
    }
  }

  backFromOneToOneReply() {
    this._uiDispatchers.backFromOneToOneReply();
    this._uiDispatchers.hidePreview();
  }

  selectChannelPost(parentMessage: MessageMethods.IMessage) {
    this._uiDispatchers.selectChannelPost(parentMessage);
  }
  selectSubChannelPost(parentMessage: MessageMethods.IMessage) {
    this._uiDispatchers.selectSubChannelPost(parentMessage);
  }

  selectOneToOneReply(userID: string) {
    this._uiDispatchers.selectOneToOneReply(userID);
  }

  subUserSelected(userID: string) {
    this._uiDispatchers.subUserSelected(userID);
  }

  subUserDeselected() {
    this._uiDispatchers.subUserDeselected();
  }

  selectTalkToAdmin() {
    this._uiDispatchers.selectTalkToAdmin();
  }

  setPostRepliesPageNumber(pageNumber: number) {
    this._uiDispatchers.setPostRepliesPageNumber(pageNumber);
  }

  setMessagesPageNumber(pageNumber: number) {
    this._uiDispatchers.setMessagesPageNumber(pageNumber);
  }

  setPostsPageNumber(pageNumber: number) {
    this._uiDispatchers.setPostsPageNumber(pageNumber);
  }

  setSubPostsPageNumber(pageNumber: number) {
    this._uiDispatchers.setSubPostsPageNumber(pageNumber);
  }

  setSubPostRepliesPageNumber(pageNumber: number) {
    this._uiDispatchers.setSubPostRepliesPageNumber(pageNumber);
  }

  setSubGroupMessagesPageNumber(pageNumber: number) {
    this._uiDispatchers.setSubGroupMessagesPageNumber(pageNumber);
  }

  showPreview() {
    this._uiDispatchers.showPreview();
  }

  hidePreview() {
    this._uiDispatchers.hidePreview();
  }

  showMediaScreen(message: MessageMethods.IMessage) {
    this._uiDispatchers.showMediaScreen(message);
  }

  hideMediaScreen() {
    this._uiDispatchers.hideMediaScreen();
  }

  showPopupMessage(popupMessage: string, title?: string) {
    this._uiDispatchers.showPopup(popupMessage, title);
  }

  showErrorToast(massage: string) {
    this._uiDispatchers.showErrorPopup(massage);
  }

  hidePopup() {
    this._uiDispatchers.hidePopup();
  }

  hideErrorSnackbar() {
    this._uiDispatchers.hideErrorSnackbar();
  }

  requestChatDetails(chatId: string) {
    this._chatDispatchers.requestChatDetails(chatId);
  }
  /* Selectors */
  getProfileByID$(userID: string): Observable<IProfile> {
    return this._selectors.getProfileByID$(userID);
  }

  getChatMessages$(): Observable<MessageMethods.ChatMessages> {
    return this._selectors.getMessagesToView$();
  }

  getSubChatMessages$(): Observable<MessageMethods.ChatMessages> {
    return this._selectors.getSubMessagesToView$();
  }

  getChatMessagesByParams$(
    selectedMessageId: string,
    selectedUserId: string
  ): Observable<MessageMethods.ChatMessages> {
    return this._selectors.getMessagesToViewByParams$(
      selectedMessageId,
      selectedUserId
    );
  }

  getChatByID$(chatID: string): Observable<MainChat> {
    return this._selectors.getChatByID$(chatID);
  }

  getMessageStats$(message_id: string): Observable<MenuCallBackStats[]> {
    return this._selectors.getMessageStats$(message_id);
  }

  get subChats$(): Observable<MainChat[]> {
    return this._selectors.subChats$;
  }

  get profilesWhoRepliedToChannelPost$(): Observable<ChannelMemberReplies> {
    return this._selectors.getProfilesWhoRepliedToChannelPost$();
  }

  get profilesWhoRepliedToSubChannelPost$(): Observable<ChannelMemberReplies> {
    return this._selectors.getProfilesWhoRepliedToSubChannelPost$();
  }

  get profilesRepliedToAdmin$(): Observable<ChannelMemberReplies> {
    return this._selectors.getProfilesWhoRepliedToAdmin$();
  }

  get myPublicProfile$(): Observable<IMyProfile> {
    return this._selectors
      .myProfiles$()
      .pipe(map(items => items.find(profile => profile.relation === 'Other')));
  }

  get selectedUser$(): Observable<IProfile> {
    return this._selectors.getSelectedUser$();
  }

  get effectiveSelectedChat$(): Observable<MainChat> {
    return this._selectors.getEffectiveSelectedChat$();
  }

  get effectiveSubSelectedChat$(): Observable<MainChat> {
    return this._selectors.getEffectiveSubSelectedChat$();
  }

  get numberOfNotViewedMessagesOfSelectedUser$() {
    return this._selectors.getNumberOfNotViewedMessagesOfSelectedUser$();
  }

  get channelMessagesToView$(): Observable<MessageMethods.ChannelPosts> {
    return this._selectors.getChannelMessagesToView$();
  }
  get subChannelMessagesToView$(): Observable<MessageMethods.ChannelPosts> {
    return this._selectors.getSubChannelMessagesToView$();
  }
  get uiCollection$(): Observable<UIState> {
    return this._selectors.uiCollection$;
  }

  get chatMediaMessages$(): Observable<MessageMethods.IMessage[]> {
    return this._selectors.getMediaMessagesInChat$();
  }

  get effectiveMediaMessage$(): Observable<MessageMethods.IMessage> {
    return this._selectors.getEffectiveMediaMessage$();
  }

  get selectedChat$(): Observable<MainChat> {
    return this._selectors.getSelectedChat$();
  }

  getInlineMessageCallBack(buttonData) {
    this._messageDispatchers.getInlineMessageCallBack(buttonData);
  }

  get privileges$() {
    return this._selectors.privileges$;
  }
}
