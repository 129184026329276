<div class="menu-holder" [class.clickable-cursor]="showRemove">
  <ng-container *ngIf="!showSettings">
    <div
      class="menu-holder-row"
      [class.active]="mobView"
      *ngFor="let menuRows of menus?.rows; let i = index"
    >
      <div *ngIf="showRemove" class="menu-actions">
        <div
          class="clickable-cursor menu-actions-icon delete"
          (click)="deleteRow(i)"
        >
          <i class="fas fa-trash-alt"></i>
        </div>
        <div
          *ngIf="
            menuRows.buttons[0].button_style !== rowStyles.SMALLHL &&
            menuRows.buttons[0].button_style !== rowStyles.WIDELIFT &&
            menuRows.buttons[0].button_style !== rowStyles.BACK &&
            menuRows.buttons.length < 3
          "
          class="clickable-cursor menu-actions-icon add"
          (click)="createButton(i)"
        >
          <i class="fas fa-plus"></i>
        </div>
      </div>
      <div
        class="menu-holder-row-button"
        *ngFor="let menuCol of menuRows?.buttons; let j = index"
        (click)="readButton({ row: i, col: j })"
        [ngStyle]="getButtonStyle(menuCol)"
      >
        <!-- <div class="inner">
        <svg
          class="svg-icon icon-icon"
          [ngStyle]="{ fill: menuCol?.button_icon_bgcolor }"
        >
          <use
            attr.xlink:href="./assets/svg/app-icons.svg#{{
              menuCol?.button_icon
            }}"
          ></use>
        </svg>
        <span>{{ {{menuCol?.button_label}} }}</span>
      </div> -->
        <div
          class="center-image"
          [class.active]="mobView"
          *ngIf="menuCol?.button_style == rowStyles.CENTERIMG"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <img class="image" [src]="menuCol?.button_img_url" />
          <span>{{ menuCol?.button_label }}</span>
          <div class="desc" [class.active]="mobView">
            {{ menuCol?.button_description }}
          </div>
        </div>
        <div
          class="top-image"
          [class.active]="mobView"
          *ngIf="menuCol?.button_style == rowStyles.TOPIMG"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <div class="imag-seaction">
            <img class="image" [src]="menuCol?.button_img_url" />
          </div>
          <span>{{ menuCol?.button_label }}</span>
          <div class="desc" [class.active]="mobView">
            {{ menuCol?.button_description }}
          </div>
        </div>
        <div
          class="bck-image"
          [class.active]="mobView"
          [ngStyle]="getBck(menuCol)"
          *ngIf="
            menuCol?.button_style == rowStyles.BGIMG ||
            menuCol?.button_style == rowStyles.WIDEBG
          "
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <span>{{ menuCol?.button_label }}</span>
          <div class="desc" [class.active]="mobView">
            {{ menuCol?.button_description }}
          </div>
        </div>
        <div
          class="text-only"
          *ngIf="menuCol?.button_style == rowStyles.TEXTONLY"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <span>{{ menuCol?.button_label }}</span>
          <div class="desc" [class.active]="mobView">
            {{ menuCol?.button_description }}
          </div>
        </div>
        <div
          class="button"
          (click)="buttonClicked(menuCol)"
          *ngIf="menuCol?.button_style == rowStyles.BACK || bot"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <span>{{ menuCol?.button_label }}</span>
        </div>
        <div
          class="wide-left-image"
          *ngIf="menuCol?.button_style == rowStyles.WIDELIFT"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <img class="image" [src]="menuCol?.button_img_url" />
          <div class="data">
            <span>{{ menuCol?.button_label }}</span>
            <div class="desc" [class.active]="mobView">
              {{ menuCol?.button_description }}
            </div>
          </div>
        </div>
        <div
          class="small-image"
          *ngIf="menuCol?.button_style == rowStyles.SMALLHL"
        >
          <img
            class="middle"
            src="./assets/img/setting-menu-btn.png"
            *ngIf="!mobView && !config && !bot"
          />
          <img class="image" [src]="menuCol?.button_img_url" />
          <div class="data">
            <span>{{ menuCol?.button_label }}</span>
            <div class="desc" [class.active]="mobView">
              {{ menuCol?.button_description }}
            </div>
          </div>
        </div>
        <div
          class="drag"
          *ngIf="
            config && !getTabContainer(menuCol)?.type && !menuCol.next_menu
          "
          (drop)="onDrop($event, menuCol.button_id)"
          ngxDroppable
          moves="true"
        >
          {{ getTabContainer(menuCol)?.type }}
          <div
            class="drag-feature"
            [class.active]="menuRows?.buttons.length >= 3"
          >
            <img src="./assets/img/drag.svg" />
            <div class="drag-intro">
              <span class="title-intro">Drop Module here</span>
              <span *ngIf="menuRows?.buttons.length <= 1"
                >Drag and drop features to create your app's Tab.</span
              >
            </div>
          </div>
        </div>
        <div
          class="drag"
          *ngIf="config && menuCol.next_menu"
          (click)="subMenuSelect(menuCol.next_menu)"
        >
          <div class="next-menu" [class.active]="menuRows?.buttons.length >= 3">
            <div class="title-title">Assigned to Next Menu:</div>
            <div class="feature-title">
              {{ getSubMenuTitle(menuCol.next_menu) }}
            </div>
          </div>
        </div>
        <div class="drag" *ngIf="config && getTabContainer(menuCol)?.type">
          <div>
            <div
              class="feature"
              (click)="showSetting(getTabContainer(menuCol))"
              [class.active]="menuRows?.buttons.length >= 3"
            >
              <div
                class="container"
                [class.active]="menuRows?.buttons.length >= 3"
              >
                <div
                  class="tab-icon"
                  *ngIf="
                    menuRows?.buttons.length <= 1 &&
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                  "
                >
                  <svg class="tab-icon-svg">
                    <use [attr.xlink:href]="selectItem" xlink:href=""></use>
                  </svg>
                  <!-- <img [src]="selectItem"/> -->
                </div>
                <div class="feature-title-container">
                  <div class="title-title">
                    Added Module:
                  </div>
                  <div class="feature-title">
                    {{
                      getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                        ?.title || getTabContainer(menuCol)?.title
                    }}
                  </div>
                </div>
                <div class="controller" *ngIf="menuRows?.buttons.length >= 3">
                  <app-delete-btn
                    (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                  ></app-delete-btn>
                </div>
              </div>
              <div class="container">
                <!-- <div
                  class="feature-count"
                  [class.active]="menuRows?.buttons.length >= 3"
                  *ngIf="
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                      ?.features?.length >= 1 && menuRows?.buttons.length < 3
                  "
                >
                  <div class="count-no">
                    {{ getTabContainer(menuCol)?.features_count }}/{{
                      getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                        ?.features?.length
                    }}
                  </div>
                  <div
                    *ngIf="
                      menuRows?.buttons.length != 2 &&
                      menuRows?.buttons.length != 3
                    "
                  >
                    Selected Features
                  </div>
                </div> -->
                <!-- <div
                  class="feature-count"
                  [class.active]="menuRows?.buttons.length >= 3"
                  *ngIf="
                    getFeatureModule(getTabContainer(menuCol)?.ui_module_id)
                      ?.features?.length <= 0 && menuRows?.buttons.length < 3
                  "
                >
                  <div class="count-no">1/1</div>
                  <div
                    *ngIf="
                      menuRows?.buttons.length != 2 &&
                      menuRows?.buttons.length != 3
                    "
                  >
                    Selected Features
                  </div>
                </div> -->
                <div class="controller" *ngIf="menuRows?.buttons.length < 3">
                  <app-delete-btn
                    (btnClicked)="deleteContainer(getTabContainer(menuCol))"
                  ></app-delete-btn>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="showRemove"
            class="close-container clickable-cursor"
            (click)="deleteButton({ row: i, col: j })"
          >
            <img class="close-btn" src="./assets/img/close.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="drag" *ngIf="showSettings">
    <div>
      <div class="feature">
        <div class="container">
          <div class="tab-icon">
            <svg class="tab-icon-svg">
              <use [attr.xlink:href]="selectItem2" xlink:href=""></use>
            </svg>
            <!-- <img [src]="selectItem"/> -->
          </div>
          <div class="feature-title-container">
            <div class="title-title">
              Added Module:
            </div>
            <div class="feature-title">
              {{ selectedFeature?.title }}
            </div>
          </div>
        </div>
        <div class="container">
          <!-- <div class="feature-count">
            <div class="count-no">
              {{ selectedFeature?.features?.length }}
            </div>
            <div>
              Selected Features
            </div>
          </div> -->
          <!-- <div
            class="feature-count"
            *ngIf="selectedFeature?.features?.length <= 0"
          >
            <div class="count-no">1/1</div>
            <div>
              Selected Features
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
