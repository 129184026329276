import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import * as StoresUIAction from './storesUI.actions';
import { StoresUIActionTypes } from './storesUI.actions';
import { StoreUiDispatchers } from './storeUI.dispatchers';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import {
  CreateStoreMethod,
  MStore,
  SetStoreMethod,
  RemoveStoreItemMethod,
  AddStoreItemMethod,
  GetStoresMethod,
  AssignStoreChannelMethod,
  RemoveStoreMethod,
  GetStoreMethod
} from 'src/models/Stores';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class StoreUiEffects {
  @Effect({ dispatch: false })
  createStore = this.actions$.pipe(
    ofType(StoresUIActionTypes.CREATE_STORE),
    map((action: StoresUIAction.CreateStore) => {
      this._socketGateway.sendSocketMessage(
        new CreateStoreMethod(action.storesDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  getStore = this.actions$.pipe(
    ofType(StoresUIActionTypes.GET_STORE),
    map((action: StoresUIAction.GetStore) => {
      this._socketGateway.sendSocketMessage(new GetStoreMethod(action.storeID));
    })
  );

  @Effect({ dispatch: false })
  updateStore = this.actions$.pipe(
    ofType(StoresUIActionTypes.SET_STORE),
    map((action: StoresUIAction.SetStore) => {
      const storeDetails: MStore = {
        ...action.storeDetails,
        id: action.storeID
      };
      this._socketGateway.sendSocketMessage(new SetStoreMethod(storeDetails));
    })
  );

  @Effect({ dispatch: false })
  addStoreItem = this.actions$.pipe(
    ofType(StoresUIActionTypes.ADD_STORE_ITEM),
    map((action: StoresUIAction.AddStoreItem) => {
      this._socketGateway.sendSocketMessage(
        new AddStoreItemMethod(action.storeID, action.items)
      );
    })
  );

  @Effect({ dispatch: false })
  assignStoreChannel = this.actions$.pipe(
    ofType(StoresUIActionTypes.ASSIGN_STORE_CHANNEL),
    map((action: StoresUIAction.AssignStoreChannel) => {
      this._socketGateway.sendSocketMessage(
        new AssignStoreChannelMethod(action.storeID, action.chatIds)
      );
    })
  );

  @Effect({ dispatch: false })
  removeStoreItem = this.actions$.pipe(
    ofType(StoresUIActionTypes.REMOVE_ITEM),
    map((action: StoresUIAction.RemoveStoreItem) => {
      this._socketGateway.sendSocketMessage(
        new RemoveStoreItemMethod(action.storeID, action.itemIDs)
      );
    })
  );

  //
  @Effect({ dispatch: false })
  getNextStorePage = this.actions$.pipe(
    ofType(StoresUIActionTypes.UI_STORE_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.storeUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, storeUiState]) => {
      const action = <StoresUIAction.GetNextStorePage>val;
      const prevRequest = storeUiState.previousRequest;
      if (
        !(
          storeUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          storeUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetStoresMethod(0, storeUiState.eop, storeUiState.selected_chat)
        );
      } else {
        this._uiStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevStorePage = this.actions$.pipe(
    ofType(StoresUIActionTypes.UI_STORE_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.storeUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, storeUiState]) => {
      const action = <StoresUIAction.GetPrevStorePage>val;
      const prevRequest = storeUiState.previousRequest;
      if (
        !(
          storeUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          storeUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetStoresMethod(1, storeUiState.sop, storeUiState.selected_chat)
        );
      } else {
        this._uiStoreDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterPackagesResetPaging = this.actions$.pipe(
    ofType(StoresUIActionTypes.UI_STORE_RESET_PAGING),
    map((action: StoresUIAction.ResetStorePaging) => {
      this._uiStoreDispatchers.getNextStorePage();
    })
  );

  @Effect({ dispatch: false })
  deleteStore = this.actions$.pipe(
    ofType(StoresUIActionTypes.DELETE_STORE),
    map((action: StoresUIAction.DeleteStore) => {
      this._socketGateway.sendSocketMessage(
        new RemoveStoreMethod(action.storeID)
      );
    })
  );

  @Effect({ dispatch: false })
  setStoreSelectedChat = this.actions$.pipe(
    ofType(StoresUIActionTypes.UI_STORE_SET_SELECTED_CHAT),
    map((action: StoresUIAction.SetStoreSelectedChat) => {
      this._uiStoreDispatchers.resetStorePaging();
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiStoreDispatchers: StoreUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
