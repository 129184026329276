<div class="styles-container">
  <div class="style-box wide">
    <div class="header">
      <span>Wide Type</span>
      <div class="checked">
        <input
          type="checkbox"
          class="check-checkbox"
          [checked]="localState?.slectedWide"
          (change)="selectEvent(mStyles.WIDE)"
          id="wide"
        />
        <label class="check-Label" for="wide"></label>
      </div>
    </div>
    <div class="body"><img src="assets/img/wide-style.svg" alt="wide" /></div>
  </div>
  <!-- <div *ngIf="messageType !== mType.ARTICLE" class="style-box wide">
    <div class="header">
      <span>Wide Type</span>
      <div class="checked">
        <input
          type="checkbox"
          class="check-checkbox"
          [checked]="localState?.slectedWideBottom"
          (change)="selectEvent(mStyles.WIDE_BOOTTOM)"
          id="wideBottom"
        />
        <label class="check-Label" for="wideBottom"></label>
      </div>
    </div>
    <div class="body"><img src="assets/img/wide2-style.svg" alt="wide" /></div>
  </div> -->
  <div class="style-box text">
    <div class="header">
      <span>Text Type</span>
      <div class="checked">
        <input
          type="checkbox"
          class="check-checkbox"
          [checked]="localState?.slectedLeftSide"
          (change)="selectEvent(mStyles.LEFT_SIDE)"
          id="leftSide"
        />
        <label class="check-Label" for="leftSide"></label>
      </div>
    </div>
    <div class="body"><img src="assets/img/text-style.svg" alt="text" /></div>
  </div>
</div>
