/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-replies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../cells/post-cell/post-cell.component.ngfactory";
import * as i3 from "../../cells/post-cell/post-cell.component";
import * as i4 from "../../core/ui.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../cells/reply-cell/reply-cell.component.ngfactory";
import * as i7 from "../../cells/reply-cell/reply-cell.component";
import * as i8 from "@angular/common";
import * as i9 from "./channel-replies.component";
var styles_ChannelRepliesComponent = [i0.styles];
var RenderType_ChannelRepliesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelRepliesComponent, data: {} });
export { RenderType_ChannelRepliesComponent as RenderType_ChannelRepliesComponent };
function View_ChannelRepliesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-cell", [], null, null, null, i2.View_PostCellComponent_0, i2.RenderType_PostCellComponent)), i1.ɵdid(1, 770048, null, 0, i3.PostCellComponent, [i4.UiService, i5.DomSanitizer], { chat: [0, "chat"], message: [1, "message"], isSubChat: [2, "isSubChat"], fixedShare: [3, "fixedShare"], isPublicChannel: [4, "isPublicChannel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chat; var currVal_1 = _co.message; var currVal_2 = _co.isSubChat; var currVal_3 = true; var currVal_4 = _co.isPublicChannel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ChannelRepliesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i6.View_ReplyCellComponent_0, i6.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i7.ReplyCellComponent, [i4.UiService], { member: [0, "member"], parentPost: [1, "parentPost"], chatId: [2, "chatId"], isSubChat: [3, "isSubChat"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.message; var currVal_2 = ((_co.chat == null) ? null : _co.chat.id); var currVal_3 = _co.isSubChat; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var tmp_4_0 = null; var currVal_4 = (_v.context.last ? _co.onLastElement((((tmp_4_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_4_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_4); }); }
function View_ChannelRepliesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_3)), i1.ɵdid(1, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.profilesRepliedToChannel$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChannelRepliesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-reply-cell", [], null, null, null, i6.View_ReplyCellComponent_0, i6.RenderType_ReplyCellComponent)), i1.ɵdid(2, 49152, null, 0, i7.ReplyCellComponent, [i4.UiService], { member: [0, "member"], chatId: [1, "chatId"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = ((_co.chat == null) ? null : _co.chat.id); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var tmp_2_0 = null; var currVal_2 = (_v.context.last ? _co.onLastElement((((tmp_2_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_2_0.totalNumberOfPages)) : ""); _ck(_v, 3, 0, currVal_2); }); }
function View_ChannelRepliesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ChannelRepliesComponent_5)), i1.ɵdid(1, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i8.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.uiService.profilesRepliedToAdmin$))) == null) ? null : tmp_0_0.channelMemberList); var currVal_1 = _co.TrackByFunction; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ChannelRepliesComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { scrollContainer: 0 }), i1.ɵqud(402653184, 2, { messagesContainer: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["ScrollContainer", 1]], null, 9, "div", [["class", "copyable-item scrollable-body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "content-scroll-area"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_1)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["messagesContainer", 1]], null, 4, "div", [["class", "replies"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_2)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelRepliesComponent_4)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "header-scroll-area"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.message; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_ChannelRepliesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-channel-replies", [], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; if (("scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChannelRepliesComponent_0, RenderType_ChannelRepliesComponent)), i1.ɵdid(1, 4964352, null, 0, i9.ChannelRepliesComponent, [i4.UiService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelRepliesComponentNgFactory = i1.ɵccf("app-channel-replies", i9.ChannelRepliesComponent, View_ChannelRepliesComponent_Host_0, { message: "message", chat: "chat", selected: "selected", isSubChat: "isSubChat", isPublicChannel: "isPublicChannel" }, {}, []);
export { ChannelRepliesComponentNgFactory as ChannelRepliesComponentNgFactory };
