/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component.ngfactory";
import * as i2 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component";
import * as i3 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component.ngfactory";
import * as i4 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i7 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i8 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i9 from "@angular/material/tabs";
import * as i10 from "../../../../accessors/show-if-has-privilege.directive";
import * as i11 from "../../../../store/root-store.selectors";
import * as i12 from "../../../../shared/pipes/twemoji/twemoji.pipe";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../../abstract-frames/selector-frame/selector-frame.component.ngfactory";
import * as i15 from "../../../../abstract-frames/selector-frame/selector-frame.component";
import * as i16 from "@angular/platform-browser/animations";
import * as i17 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i18 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i19 from "./market-pool.component";
import * as i20 from "../../products/products.service";
import * as i21 from "../../bundles/bundles.service";
import * as i22 from "../../packages/packages.service";
import * as i23 from "../../mobile-store/mobile-store.service";
import * as i24 from "../../../../core/ui.service";
var styles_MarketPoolComponent = [];
var RenderType_MarketPoolComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MarketPoolComponent, data: {} });
export { RenderType_MarketPoolComponent as RenderType_MarketPoolComponent };
function View_MarketPoolComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-product"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Products "]))], null, null); }
function View_MarketPoolComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableCellFrameComponent_0, i1.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" }), i0.ɵppd(2, 1), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.productsFet == null) ? null : _co.productsFet.symbolColor); var currVal_1 = ((_co.productsFet == null) ? null : _co.productsFet.symbolImage); var currVal_2 = ((_co.productsFet == null) ? null : _co.productsFet.coverColor); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); var currVal_4 = _co.isProductSelected(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_6 = i0.ɵunv(_v, 1, 6, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.name))); var currVal_7 = i0.ɵunv(_v, 1, 7, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.desc))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MarketPoolComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.productService.getNextPage() !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.productService.getPreviousPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_RoundPagingBtnComponent_0, i3.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i4.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MarketPoolComponent_5)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.productService.products$)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_MarketPoolComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i6.View_EmptyItemFrameComponent_0, i6.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i7.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.productsFet == null) ? null : _co.productsFet.symbolImage); var currVal_1 = ((_co.productsFet == null) ? null : _co.productsFet.emptyTitle); var currVal_2 = ((_co.productsFet == null) ? null : _co.productsFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MarketPoolComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i8.View_MatTab_0, i8.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i9.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 2, { templateLabel: 0 }), i0.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MarketPoolComponent_3)), i0.ɵdid(5, 16384, [[2, 4]], 0, i9.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_MarketPoolComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isProductsEmpty", 2]], 0, 0, null, View_MarketPoolComponent_6)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.productService.products$))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
function View_MarketPoolComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_2)), i0.ɵdid(2, 212992, null, 0, i10.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i11.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.listproduct; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MarketPoolComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-bundle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Bundles "]))], null, null); }
function View_MarketPoolComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableCellFrameComponent_0, i1.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" }), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.bundleFet == null) ? null : _co.bundleFet.symbolColor); var currVal_1 = ((_co.bundleFet == null) ? null : _co.bundleFet.symbolImage); var currVal_2 = ((_co.bundleFet == null) ? null : _co.bundleFet.coverColor); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); var currVal_4 = _co.isProductSelected(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_6 = i0.ɵunv(_v, 1, 6, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.name))); var currVal_7 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.desc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MarketPoolComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.bundleService.getNextPage() !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.bundleService.getPreviousPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_RoundPagingBtnComponent_0, i3.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i4.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MarketPoolComponent_11)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.bundleService.bundles$)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_MarketPoolComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i6.View_EmptyItemFrameComponent_0, i6.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i7.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.bundleFet == null) ? null : _co.bundleFet.symbolImage); var currVal_1 = ((_co.bundleFet == null) ? null : _co.bundleFet.emptyTitle); var currVal_2 = ((_co.bundleFet == null) ? null : _co.bundleFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MarketPoolComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i8.View_MatTab_0, i8.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i9.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 4, { templateLabel: 0 }), i0.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MarketPoolComponent_9)), i0.ɵdid(5, 16384, [[4, 4]], 0, i9.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_MarketPoolComponent_10)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isBundelEmpty", 2]], 0, 0, null, View_MarketPoolComponent_12)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.bundleService.bundles$))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
function View_MarketPoolComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_8)), i0.ɵdid(2, 212992, null, 0, i10.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i11.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.listbundle; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MarketPoolComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-package"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Multi tiered plans "]))], null, null); }
function View_MarketPoolComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableCellFrameComponent_0, i1.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" }), i0.ɵppd(2, 1), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.packagesFet == null) ? null : _co.packagesFet.symbolColor); var currVal_1 = ((_co.packagesFet == null) ? null : _co.packagesFet.symbolImage); var currVal_2 = ((_co.packagesFet == null) ? null : _co.packagesFet.coverColor); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); var currVal_4 = _co.isProductSelected(((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_6 = i0.ɵunv(_v, 1, 6, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.name))); var currVal_7 = i0.ɵunv(_v, 1, 7, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.date))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MarketPoolComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.packageService.getNextPage() !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.packageService.getPreviousPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_RoundPagingBtnComponent_0, i3.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i4.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MarketPoolComponent_17)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.packageService.packages$)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_MarketPoolComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i6.View_EmptyItemFrameComponent_0, i6.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i7.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.packagesFet == null) ? null : _co.packagesFet.symbolImage); var currVal_1 = ((_co.packagesFet == null) ? null : _co.packagesFet.emptyTitle); var currVal_2 = ((_co.packagesFet == null) ? null : _co.packagesFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MarketPoolComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i8.View_MatTab_0, i8.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i9.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 6, { templateLabel: 0 }), i0.ɵqud(335544320, 7, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MarketPoolComponent_15)), i0.ɵdid(5, 16384, [[6, 4]], 0, i9.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_MarketPoolComponent_16)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isPackagesEmpty", 2]], 0, 0, null, View_MarketPoolComponent_18)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.packageService.packages$))) == null) ? null : tmp_0_0.length) > 0); var currVal_1 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_0, currVal_1); }, null); }
function View_MarketPoolComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_14)), i0.ɵdid(2, 212992, null, 0, i10.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i11.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.listpackage; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MarketPoolComponent_21(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-mstore"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" mStore "]))], null, null); }
function View_MarketPoolComponent_24(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableCellFrameComponent_0, i1.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], coverPhoto: [3, "coverPhoto"], isSelected: [4, "isSelected"], selectorId: [5, "selectorId"], title: [6, "title"], desc: [7, "desc"] }, { checkClick: "checkClick", checkChanged: "checkChanged" }), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mStoreFet == null) ? null : _co.mStoreFet.symbolColor); var currVal_1 = ((_co.mStoreFet == null) ? null : _co.mStoreFet.symbolImage); var currVal_2 = ((_co.mStoreFet == null) ? null : _co.mStoreFet.coverColor); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_4 = _co.isProductSelected(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id)); var currVal_5 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.id); var currVal_6 = i0.ɵunv(_v, 1, 6, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent.parent, 0), ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.name))); var currVal_7 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.desc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MarketPoolComponent_23(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_24)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.id) !== _co.mStoreID); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MarketPoolComponent_22(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.mStoreService.getNextPage() !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.mStoreService.getPreviousPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_RoundPagingBtnComponent_0, i3.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i4.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MarketPoolComponent_23)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.mStoreService.stores$)); _ck(_v, 4, 0, currVal_0); }, null); }
function View_MarketPoolComponent_20(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 8, "mat-tab", [], null, null, null, i8.View_MatTab_0, i8.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i9.MatTab, [i0.ViewContainerRef], null, null), i0.ɵqud(603979776, 8, { templateLabel: 0 }), i0.ɵqud(335544320, 9, { _explicitContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_MarketPoolComponent_21)), i0.ɵdid(5, 16384, [[8, 4]], 0, i9.MatTabLabel, [i0.TemplateRef, i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(16777216, null, 0, 2, null, View_MarketPoolComponent_22)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.mStoreService.stores$))) == null) ? null : tmp_0_0.length) > 0); _ck(_v, 7, 0, currVal_0); }, null); }
function View_MarketPoolComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_20)), i0.ɵdid(2, 212992, null, 0, i10.ShowIfHasPrivilegeDirective, [i0.ChangeDetectorRef, i0.TemplateRef, i0.ViewContainerRef, i11.RootStoreSelectors], { appShowIfHasPrivilege: [0, "appShowIfHasPrivilege"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.privilegesName.listmstore; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MarketPoolComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i12.TwemojiPipe, [i13.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 20, "app-selector-frame", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_SelectorFrameComponent_0, i14.RenderType_SelectorFrameComponent)), i0.ɵdid(2, 114688, null, 0, i15.SelectorFrameComponent, [], null, { closeNotify: "closeNotify" }), (_l()(), i0.ɵeld(3, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add Items"])), (_l()(), i0.ɵeld(5, 0, null, 8, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 10, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i8.View_MatTabGroup_0, i8.RenderType_MatTabGroup)), i0.ɵdid(7, 3325952, null, 1, i9.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i9.MAT_TABS_CONFIG], [2, i16.ANIMATION_MODULE_TYPE]], null, null), i0.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_1)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_7)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_13)), i0.ɵdid(14, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MarketPoolComponent_19)), i0.ɵdid(16, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, 10, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 3, "div", [["class", "form-submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "app-border-btn", [["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_BorderBtnComponent_0, i17.RenderType_BorderBtnComponent)), i0.ɵdid(20, 573440, null, 0, i18.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵted(-1, 0, [" Insert "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_2 = _co.prd; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.bundel; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.pck; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.mStore; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).dynamicHeight; var currVal_1 = (i0.ɵnov(_v, 7).headerPosition === "below"); _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_MarketPoolComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-market-pool", [], null, null, null, View_MarketPoolComponent_0, RenderType_MarketPoolComponent)), i0.ɵdid(1, 245760, null, 0, i19.MarketPoolComponent, [i20.ProductsService, i21.BundlesService, i22.PackagesService, i23.MobileStoresService, i24.UiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MarketPoolComponentNgFactory = i0.ɵccf("app-market-pool", i19.MarketPoolComponent, View_MarketPoolComponent_Host_0, { mStoreID: "mStoreID", mStore: "mStore", prd: "prd", bundel: "bundel", pck: "pck", mltipln: "mltipln", selectedMessage: "selectedMessage", currentSelectMessage: "currentSelectMessage", ownerGroupId: "ownerGroupId", singleModeSinglePoolSelect: "singleModeSinglePoolSelect", selectedChatId: "selectedChatId", useValidation: "useValidation", currentCurrency: "currentCurrency" }, { selectProductNotfiy: "selectProductNotfiy", cancelNotfiy: "cancelNotfiy", currentCurrencyNotfiy: "currentCurrencyNotfiy" }, []);
export { MarketPoolComponentNgFactory as MarketPoolComponentNgFactory };
