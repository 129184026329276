import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/core/analytics.service';

import { AuthService } from 'src/app/core/auth.service';
import { checkChecked } from 'src/app/validators/checkChecked';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';
import { templateIdValidators } from 'src/app/validators/templateIdValidator';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';

import * as AuthMethods from 'src/models/IAuth';
import { COUNTRIES } from 'src/models/Countries';
import { AppForm } from 'src/models/AppForm';
import {
  SignupStatus,
  HtttpMethodErrors,
  SIGNUP_LINK,
  PRIVACY_LINK,
  TERMS_LINK
} from 'src/models/constants';
import { ANALYTICS_EVENTS } from 'src/models/IAnalytics';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent implements OnInit, OnChanges, AppForm {
  @Input() countryIso = '';

  form: FormGroup;
  hidePassword = true;
  waiting = false;
  signupLink = SIGNUP_LINK;
  privacyLink = PRIVACY_LINK;
  termsLink = TERMS_LINK;
  countryOptions = COUNTRIES;

  signupStatus: typeof SignupStatus = SignupStatus;

  constructor(
    private _ref: ChangeDetectorRef,
    private _validateForm: FormValidatorsService,
    public authService: AuthService,
    private _analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.form = this.setFormGroup();
  }

  ngOnChanges(chngs: SimpleChanges) {
    const countryIsoChng = chngs['countryIso'];
    if (countryIsoChng && countryIsoChng.currentValue && this.form) {
      this.form.get('countryIso').setValue(countryIsoChng.currentValue);
    }
  }

  setFormGroup() {
    return new FormGroup({
      appName: new FormControl('My App', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(8),
        noWhitespaceValidator
      ]),
      terms: new FormControl(true, [checkChecked]),
      privacy: new FormControl(true, [checkChecked]),
      countryIso: new FormControl(this.countryIso, [Validators.required]),
      agree: new FormControl(false, [checkChecked])
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this._analyticsService.eventEmitter(
        ANALYTICS_EVENTS.SIGN_UP_EVENT.category,
        ANALYTICS_EVENTS.SIGN_UP_EVENT.action,
        ANALYTICS_EVENTS.SIGN_UP_EVENT.label,
        ANALYTICS_EVENTS.SIGN_UP_EVENT.value
      );
      this.waiting = true;
      this._ref.detectChanges();
      this.authService.getEmailTac(this.form.value).subscribe(response => {
        const res = <AuthMethods.HttpResult>response;
        if (res.result === 0) {
          this.authService.emailTacSuccess(this.form.value);
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.FREE_MAIL) {
          this.email.setErrors({ noFreeMail: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.WRONG_FORMAT) {
          this.email.setErrors({ wrongFormat: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED) {
          this.email.setErrors({ mailUsed: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED_2) {
          this.email.setErrors({ mailUsed2: true });
          this.waiting = false;
          this._ref.detectChanges();
        } else if (res.error === HtttpMethodErrors.MAIL_USED_3) {
          this.email.setErrors({ mailUsed3: true });
          this.waiting = false;
          this._ref.detectChanges();
        }
      });
    } else {
      // Validate on submit
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  get email() {
    return this.form.get('email');
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
