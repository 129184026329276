<div
  [class.form-group]="!isInline"
  [class.form-inline]="isInline"
  [class.fullWidth]="!isInline"
>
  <label
    *ngIf="filedLabel"
    [for]="fieldId"
    class="control-label"
    [class.md]="isInline"
    style="margin-bottom: 6px;"
  >
    {{ filedLabel }}
  </label>
  <div class="form-group lg-input">
    <div class="normal-border" [class.error]="validationErrors$ | async">
      <select
        style="height: 30px; border: none; width: 100%;"
        class="form-control"
        [formControl]="control"
        [id]="fieldId"
        [attr.disabled]="disabled ? '' : null"
      >
        <!-- For key value -->
        <ng-container *ngIf="keyOptions">
          <option
            *ngFor="let option of keyOptions"
            [value]="option?.key"
            (click)="isSelected(option?.key)"
          >
            {{ option?.value }}
          </option>
        </ng-container>
        <!-- For array string -->
        <ng-container *ngIf="options">
          <option *ngFor="let option of options" [value]="option">
            {{ option }}
          </option>
        </ng-container>
      </select>
    </div>

    <ng-container *ngIf="showError">
      <ng-container *ngIf="validationErrors$ | async as errors">
        <app-validation-errors [fieldErrors]="errors" [fieldName]="filedLabel">
        </app-validation-errors>
      </ng-container>
    </ng-container>
  </div>
</div>
