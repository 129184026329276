import { map } from 'rxjs/operators';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { TabTypes } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
export class ComposeMessageService {
    constructor(_selectors) {
        this._selectors = _selectors;
    }
    get tabsContainers$() {
        return this._selectors.tabsContainers$.pipe(map(tabs => tabs.filter(tap => tap.type === TabTypes.FEED ||
            tap.type === TabTypes.MEMBERSHIP ||
            tap.type === TabTypes.WALLET)));
    }
    get selectedChatTags$() {
        return this._selectors.selectedChatTags$.pipe(map(tags => {
            if (tags !== null && tags.length > 0) {
                return tags.filter(tag => !tag.isPrivate);
            }
        }));
    }
    get selectedChatPrivateTags$() {
        return this._selectors.selectedChatTags$.pipe(map(tags => {
            if (tags !== null && tags.length > 0) {
                return tags.filter(tag => tag.isPrivate);
            }
        }));
    }
    get subChats$() {
        return this._selectors.subChats$;
    }
    getNewArticle(ref) {
        return this._selectors.getNewArticle$(ref);
    }
}
ComposeMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ComposeMessageService_Factory() { return new ComposeMessageService(i0.ɵɵinject(i1.RootStoreSelectors)); }, token: ComposeMessageService, providedIn: "root" });
