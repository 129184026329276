import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as PaymentProvidersActions from './paymentProviders.actions';

import { AppState } from 'src/models/AppState';
import { PaymentProvider, ProviderConfig } from 'src/models/PaymentGateways';
import { PaymentProviderIDs, AdsConfig } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentProvidersDispatchers {
  constructor(private _store: Store<AppState>) {}

  getPaymentProviders(): void {
    this._store.dispatch(new PaymentProvidersActions.GetPaymentProviders());
  }

  receivePaymentProviders(paymentProviders: PaymentProvider[]): void {
    this._store.dispatch(
      new PaymentProvidersActions.ReceivePaymentProviders(paymentProviders)
    );
  }

  receivePaymentProvider(
    providerId: PaymentProviderIDs,
    config: ProviderConfig
  ): void {
    this._store.dispatch(
      new PaymentProvidersActions.ReceivePaymentProvider(providerId, config)
    );
  }

  setPaymentProvider(
    providerId: PaymentProviderIDs,
    config: ProviderConfig,
    active: number
  ): void {
    this._store.dispatch(
      new PaymentProvidersActions.SetPaymentProvider(providerId, config, active)
    );
  }
}
