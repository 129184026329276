import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import * as UiActions from './ui.actions';
import { UIMiddleware } from './ui.middleware';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MessageDispatchers } from '../messages/message.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import * as isEqual from 'lodash.isequal';
export class UiEffects {
    constructor(actions$, _store, _uiDispatchers, _messageDispatchers, _mainChatDispatchers) {
        this.actions$ = actions$;
        this._store = _store;
        this._uiDispatchers = _uiDispatchers;
        this._messageDispatchers = _messageDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this.evaluateShowDashboard = this.actions$.pipe(ofType("UI_EVALUATE_SHOW_DASHBOARD" /* UI_EVALUATE_SHOW_DASHBOARD */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.uiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(res => {
            const isProfileAndChatsRcvd = UIMiddleware.isAllProfilesAndChatsRcvd(res[1], res[2]);
            if (isProfileAndChatsRcvd && !res[2].detailsReceived) {
                this._uiDispatchers.detailsReceived();
            }
        }));
        this.postReplyButtonSelected = this.actions$.pipe(ofType("UI_SELECT_CHANNEL_POST" /* UI_SELECT_CHANNEL_POST */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats]) => {
            const action = val;
            this._uiDispatchers.messageSelected(action.payload);
            const adminToPost = mainChats.find(chat => chat.id === action.payload.group_id && chat.isAdmin);
            if (adminToPost) {
                this._uiDispatchers.groupRepliesSelected();
            }
            else {
                this._uiDispatchers.selectReplyToAdmin();
            }
        }));
        this.postSubReplyButtonSelected = this.actions$.pipe(ofType("UI_SELECT_SUB_CHANNEL_POST" /* UI_SELECT_SUB_CHANNEL_POST */), withLatestFrom(this._store
            .select(state => state.mainChatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, mainChats]) => {
            const action = val;
            this._uiDispatchers.subParentMessageSelected(action.payload);
            const adminToPost = mainChats.find(chat => chat.id === action.payload.group_id && chat.isAdmin);
            if (adminToPost) {
                this._uiDispatchers.groupRepliesSelected();
            }
            else {
                this._uiDispatchers.selectReplyToAdmin();
            }
        }));
        this.downloadMediaMessage = this.actions$.pipe(ofType("UI_SHOW_MEDIA_SCREEN" /* UI_SHOW_MEDIA_SCREEN */), map((action) => {
            const message = action.payload;
            if (!message.localThumbnail &&
                message.thumbnail_id &&
                !message.thumbnailStatus) {
                this._messageDispatchers.downloadMessageThumbnail(message);
            }
            if (!message.localMedia &&
                !message.mediaStatus &&
                message.message_id &&
                message.media_id) {
                this._messageDispatchers.downloadMediaMessage(message);
            }
        }));
        this.listSelected = this.actions$.pipe(ofType("UI_LIST_SELECTED" /* UI_LIST_SELECTED */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            if (selectedChat) {
                this._mainChatDispatchers.mainChatSelected(selectedChat);
            }
        }));
        this.chatSelected = this.actions$.pipe(ofType("UI_CHAT_SELECTED" /* UI_CHAT_SELECTED */), withLatestFrom(this._store
            .select(state => state.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            if (selectedChat) {
                this._mainChatDispatchers.mainChatSelected(selectedChat);
            }
        }));
        this.channelDeselected = this.actions$.pipe(ofType(UiActions.DESELECT_CHANNEL), withLatestFrom(this._store
            .select(state => state.authReducer.chatId)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatID]) => {
            const action = val;
            this._mainChatDispatchers.mainChatDeselected(chatID, '');
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "evaluateShowDashboard", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "postReplyButtonSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "postSubReplyButtonSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "downloadMediaMessage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "listSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "chatSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], UiEffects.prototype, "channelDeselected", void 0);
