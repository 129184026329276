import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'app-add-btn',
  template: `
    <app-circle-btn edit (btnClicked)="btnEvent()">
      <app-add-icon></app-add-icon>
    </app-circle-btn>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBtnComponent {
  @Input() disabled: boolean;
  @Output() btnClicked = new EventEmitter<boolean>();

  btnEvent() {
    this.btnClicked.emit(true);
  }
}
