/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/cancel-event/cancel-event.directive";
import * as i3 from "../../emojibox/emoji-keyboard/emoji-keyboard.component.ngfactory";
import * as i4 from "../../emojibox/emoji-keyboard/emoji-keyboard.component";
import * as i5 from "../../emojibox/emojibox.service";
import * as i6 from "ngx-bootstrap/dropdown";
import * as i7 from "ngx-bootstrap/component-loader";
import * as i8 from "../upload-files/upload-files.component.ngfactory";
import * as i9 from "../upload-files/upload-files.component";
import * as i10 from "../../core/file.service";
import * as i11 from "../record-voice-note/record-voice-note.component.ngfactory";
import * as i12 from "../record-voice-note/record-voice-note.component";
import * as i13 from "@angular/common";
import * as i14 from "./chat-box.component";
import * as i15 from "../../core/ui.service";
import * as i16 from "../../core/helper.service";
var styles_ChatBoxComponent = [i0.styles];
var RenderType_ChatBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatBoxComponent, data: {} });
export { RenderType_ChatBoxComponent as RenderType_ChatBoxComponent };
function View_ChatBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EmojiKeyboardComponent_0, i3.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i4.EmojiKeyboardComponent, [i5.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
function View_ChatBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "chat-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[2, 0], ["dropdownEmoji", 1]], null, 7, "div", [["class", "btn-group align-end clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.BsDropdownState, i6.BsDropdownState, []), i1.ɵdid(4, 212992, null, 0, i6.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i7.ComponentLoaderFactory, i6.BsDropdownConfig, i6.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i6.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i6.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i6.BsDropdownState], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "smile-emoji far fa-smile"]], [[2, "hide", null], [2, "open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_2)), i1.ɵdid(9, 16384, null, 0, i6.BsDropdownMenuDirective, [i6.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["textBox", 1]], null, 0, "div", [["class", "edit wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Type a message..."]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-upload-files", [["class", "align-end clickable-cursor"]], [[2, "hide", null]], [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.sendMedia($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_UploadFilesComponent_0, i8.RenderType_UploadFilesComponent)), i1.ɵdid(12, 114688, null, 0, i9.UploadFilesComponent, [i10.FileService], null, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-record-voice-note", [["class", "align-end clickable-cursor"]], null, [[null, "recordVoice"], [null, "sendVoiceNotify"], [null, "micNotFoundNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recordVoice" === en)) {
        var pd_0 = (_co.startRecordEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendVoiceNotify" === en)) {
        var pd_1 = (_co.sendVoiceNote($event) !== false);
        ad = (pd_1 && ad);
    } if (("micNotFoundNotify" === en)) {
        var pd_2 = (_co.micNotFoundEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_RecordVoiceNoteComponent_0, i11.RenderType_RecordVoiceNoteComponent)), i1.ɵdid(14, 573440, null, 0, i12.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], { selectedChat: [0, "selectedChat"] }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.isEmojiKeyboardOpen; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); _ck(_v, 12, 0); var currVal_13 = _co.selectedChat; _ck(_v, 14, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 6).isDisabled; var currVal_8 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.isRecord; var currVal_10 = _co.isEmojiKeyboardOpen; _ck(_v, 7, 0, currVal_9, currVal_10); var currVal_11 = _co.isRecord; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.isRecord; _ck(_v, 11, 0, currVal_12); }); }
function View_ChatBoxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EmojiKeyboardComponent_0, i3.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i4.EmojiKeyboardComponent, [i5.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
function View_ChatBoxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "chat-bot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "chat-bot-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[2, 0], ["dropdownEmoji", 1]], null, 7, "div", [["class", "btn-group align-end clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.BsDropdownState, i6.BsDropdownState, []), i1.ɵdid(4, 212992, null, 0, i6.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i7.ComponentLoaderFactory, i6.BsDropdownConfig, i6.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i6.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i6.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i6.BsDropdownState], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "smile-emoji far fa-smile"]], [[2, "hide", null], [2, "open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_4)), i1.ɵdid(9, 16384, null, 0, i6.BsDropdownMenuDirective, [i6.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["textBox", 1]], null, 0, "div", [["class", "edit wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Type a message..."]], [[2, "hide", null]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-upload-files", [["class", "align-end clickable-cursor"]], [[2, "hide", null]], [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.sendMedia($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_UploadFilesComponent_0, i8.RenderType_UploadFilesComponent)), i1.ɵdid(12, 114688, null, 0, i9.UploadFilesComponent, [i10.FileService], { isBotScreen: [0, "isBotScreen"] }, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-record-voice-note", [["class", "align-end clickable-cursor"]], null, [[null, "recordVoice"], [null, "sendVoiceNotify"], [null, "micNotFoundNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("recordVoice" === en)) {
        var pd_0 = (_co.startRecordEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("sendVoiceNotify" === en)) {
        var pd_1 = (_co.sendVoiceNote($event) !== false);
        ad = (pd_1 && ad);
    } if (("micNotFoundNotify" === en)) {
        var pd_2 = (_co.micNotFoundEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i11.View_RecordVoiceNoteComponent_0, i11.RenderType_RecordVoiceNoteComponent)), i1.ɵdid(14, 573440, null, 0, i12.RecordVoiceNoteComponent, [i1.ChangeDetectorRef], { selectedChat: [0, "selectedChat"], isBotScreen: [1, "isBotScreen"] }, { recordVoice: "recordVoice", sendVoiceNotify: "sendVoiceNotify", micNotFoundNotify: "micNotFoundNotify" }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "send-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co.isEmojiKeyboardOpen; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); var currVal_13 = true; _ck(_v, 12, 0, currVal_13); var currVal_14 = _co.selectedChat; var currVal_15 = true; _ck(_v, 14, 0, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).dropup; var currVal_1 = i1.ɵnov(_v, 4).isOpen; var currVal_2 = (i1.ɵnov(_v, 4).isOpen && i1.ɵnov(_v, 4).isBs4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 6).isDisabled; var currVal_8 = i1.ɵnov(_v, 6).isOpen; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.isRecord; var currVal_10 = _co.isEmojiKeyboardOpen; _ck(_v, 7, 0, currVal_9, currVal_10); var currVal_11 = _co.isRecord; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.isRecord; _ck(_v, 11, 0, currVal_12); }); }
export function View_ChatBoxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { textBox: 0 }), i1.ɵqud(671088640, 2, { dropdownEmoji: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_1)), i1.ɵdid(3, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatBoxComponent_3)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isScreenBot; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isScreenBot; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ChatBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-box", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onEnterKey($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChatBoxComponent_0, RenderType_ChatBoxComponent)), i1.ɵdid(1, 638976, null, 0, i14.ChatBoxComponent, [i15.UiService, i1.ElementRef, i16.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatBoxComponentNgFactory = i1.ɵccf("app-chat-box", i14.ChatBoxComponent, View_ChatBoxComponent_Host_0, { selectedChat: "selectedChat", selectedMessage: "selectedMessage", selectedUserId: "selectedUserId", groupReplies: "groupReplies", replyToAdmin: "replyToAdmin", talkToAdminBot: "talkToAdminBot", isScreenBot: "isScreenBot", show: "show" }, {}, []);
export { ChatBoxComponentNgFactory as ChatBoxComponentNgFactory };
