/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./qr-scanner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular2-qrcode/lib/angular2-qrcode.ngfactory";
import * as i3 from "angular2-qrcode";
import * as i4 from "@angular/common";
import * as i5 from "./qr-scanner.component";
var styles_QrScannerComponent = [i0.styles];
var RenderType_QrScannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QrScannerComponent, data: {} });
export { RenderType_QrScannerComponent as RenderType_QrScannerComponent };
function View_QrScannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "qr-scanner-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " QR Code "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screenType; _ck(_v, 1, 0, currVal_0); }); }
function View_QrScannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qr-code", [["class", "qr"]], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(1, 573440, null, 0, i3.QRCodeComponent, [i1.ElementRef], { size: [0, "size"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 150; var currVal_1 = _co.qrCodeText; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_QrScannerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "qr-code", [["class", "qr"]], null, null, null, i2.View_QRCodeComponent_0, i2.RenderType_QRCodeComponent)), i1.ɵdid(1, 573440, null, 0, i3.QRCodeComponent, [i1.ElementRef], { size: [0, "size"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 60; var currVal_1 = _co.qrCodeText; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_QrScannerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], [[2, "qr-scanner", null], [2, "qr-edit", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [], [[2, "qr-scanner-form", null], [2, "qr-scanner-onEdit", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "qr-container"], ["id", "section-to-print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], [[2, "qr-code", null], [2, "qr-onEdit", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QrScannerComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.styleQROnEdit; _ck(_v, 2, 0, currVal_2); var currVal_7 = !_co.styleInEditScreen; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.styleInEditScreen; _ck(_v, 9, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.styleQROnEdit; var currVal_1 = _co.styleQROnEdit; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = !_co.styleQROnEdit; var currVal_4 = _co.styleQROnEdit; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = !_co.styleQROnEdit; var currVal_6 = _co.styleQROnEdit; _ck(_v, 5, 0, currVal_5, currVal_6); }); }
export function View_QrScannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-qr-scanner", [], null, null, null, View_QrScannerComponent_0, RenderType_QrScannerComponent)), i1.ɵdid(1, 49152, null, 0, i5.QrScannerComponent, [], null, null)], null, null); }
var QrScannerComponentNgFactory = i1.ɵccf("app-qr-scanner", i5.QrScannerComponent, View_QrScannerComponent_Host_0, { title: "title", qrCodeText: "qrCodeText", styleQROnEdit: "styleQROnEdit", styleInEditScreen: "styleInEditScreen", screenType: "screenType" }, {}, []);
export { QrScannerComponentNgFactory as QrScannerComponentNgFactory };
