import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MapGateway } from './gateway/map.gateway';
import { AuthGateway } from './gateway/auth.gateway';
import { SocketGateway } from './gateway/socket.gateway';
import { UploadGateway } from './gateway/upload.gateway';
import { DownloadGateway } from './gateway/download.gateway';
import { MessageGateway } from './gateway/message.gateway';
import { PlansGateway } from './gateway/plans.gateway';
import { LinkPreviewGateway } from './gateway/link-preview.gateway';

import { TokenInterceptor } from './interceptors/token.interceptor';

import { SocketService } from './services/socket.service';
import { HttpService } from './services/http.service';
import { DownloadImageService } from './services/download-image.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGateway,
    MessageGateway,
    MapGateway,
    PlansGateway,
    DownloadGateway,
    LinkPreviewGateway,
    UploadGateway,
    SocketGateway,
    HttpService,
    SocketService,
    DownloadImageService
  ]
})
export class NetworkModule {}
