import * as MediaCenterActions from './mediaCenter.actions';

import { IMediaCenter } from 'src/models/IMediaCenter';
import { MediaCenterMiddleware } from './mediaCenter.middleware';

export function mediaCenterReducer(
  state: IMediaCenter[] = [],
  action: MediaCenterActions.MediaCenterActions
): IMediaCenter[] {
  switch (action.type) {
    case MediaCenterActions.MediaCenterActionTypes.MY_MEDIA_GALLERY_RCVD:
      const items = [];
      action.payload.forEach(item => {
        const localMedia = MediaCenterMiddleware.mapMediaGallaeryRcvdToMediaCenter(
          item
        );
        if (!state.find(media => media.id === localMedia.id)) {
          items.push(localMedia);
        }
      });
      return [...state, ...items];
    case MediaCenterActions.MediaCenterActionTypes.MEDIA_UPLOADING_PROCESSED:
      return [action.payload, ...state];
    case MediaCenterActions.MediaCenterActionTypes
      .UPDATE_MEDIA_CENTER_LOADING_PROGRESS:
      return state.map(val => {
        if (action.msg.reference && action.msg.reference === val.reference) {
          const newMsg: IMediaCenter = {};
          newMsg.loadingProgress = action.progress;
          return { ...val, ...newMsg };
        }
        return val;
      });
    case MediaCenterActions.MediaCenterActionTypes.UPDATE_MEDIA_CENTER_STATUS:
      return state.map(val => {
        if (action.msg.reference && action.msg.reference === val.reference) {
          const newMsg: IMediaCenter = {};
          newMsg.mediaStatus = action.status;
          newMsg.url = action.url || val.url;
          newMsg.file = action.file || val.file;
          newMsg.filename = action.file || val.file;
          return { ...val, ...newMsg };
        }
        return val;
      });
    case MediaCenterActions.MediaCenterActionTypes.DELETE_MEDIA_CENTER_ITEM:
      return state.filter(media => media.file !== action.payload);
    case MediaCenterActions.DESELECT_CHANNEL:
    case MediaCenterActions.RESET:
      return [];
    default:
      return state;
  }
}
