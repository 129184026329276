import { map } from 'rxjs/operators';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';
import { MembersUiDispatchers } from 'src/app/store/membersUI/membersUI.dispatchers';
import { BundlesUiDispatchers } from 'src/app/store/bundlesUI/bundlesUI.dispatchers';
import { TableService } from 'src/app/abstract-tables/table.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/chats/chat.dispatchers";
import * as i3 from "../../../store/chatMembers/chatMember.dispatchers";
import * as i4 from "../../../store/membersUI/membersUI.dispatchers";
import * as i5 from "../../../store/bundlesUI/bundlesUI.dispatchers";
export class MembersService extends TableService {
    constructor(_selectors, _chatDispatchers, _chatMemberDispatchers, _membersUiDispatchers, _bundlesUiDispatchers) {
        super();
        this._selectors = _selectors;
        this._chatDispatchers = _chatDispatchers;
        this._chatMemberDispatchers = _chatMemberDispatchers;
        this._membersUiDispatchers = _membersUiDispatchers;
        this._bundlesUiDispatchers = _bundlesUiDispatchers;
        this.searchBy = 0;
    }
    getMyMemberChats(userId) {
        this._chatMemberDispatchers.getMemberChats(userId);
    }
    promoteChatMember(userId) {
        this._chatMemberDispatchers.promoteChatMember(userId);
    }
    buyBundleOnBehalfOfUser(userId, bundle) {
        this._bundlesUiDispatchers.buyBundleOnBehalfOfUser(userId, bundle);
    }
    buySubscriptionOnBehalfOfUser(userId, chat, currency) {
        this._chatDispatchers.buySubscriptionOnBehalfOfUser(chat.product_id, userId, currency);
    }
    assignAdminsToChat(chatId) {
        this.selectedItems.forEach(memberId => {
            this._chatMemberDispatchers.addAdminToChat(chatId, memberId);
        });
    }
    assignAdminsToChatInForm(chatId, selectedItems) {
        selectedItems.forEach(memberId => {
            this._chatMemberDispatchers.addAdminToChat(chatId, memberId);
        });
    }
    assignMembersToChat(chatId) {
        this.selectedItems.forEach(memberId => {
            this._chatMemberDispatchers.addMemberToChat(chatId, memberId);
        });
    }
    returnSelectedItems() {
        return this.selectedItems;
    }
    assignMemberToChat(chatId, userId) {
        this._chatMemberDispatchers.addMemberToChat(chatId, userId);
    }
    assignAdminToChat(chatId, userId) {
        this._chatMemberDispatchers.addAdminToChat(chatId, userId);
    }
    revokeMemberFromChat(chatId, userId) {
        this._chatMemberDispatchers.revokeMemberFromChat(chatId, userId);
    }
    revokeAdminFromChat(chatId, userId) {
        this._chatMemberDispatchers.revokeAdminFromChat(chatId, userId);
    }
    setChatMemberRole(chatId, userId, roleId) {
        this._chatMemberDispatchers.setChatMemberRole(chatId, userId, roleId);
    }
    setChatMemberView(userId, viewId) {
        this._chatMemberDispatchers.setChatMemberView(userId, viewId);
    }
    get selectedChatRoles$() {
        return this._selectors.selectedChatRoles$;
    }
    get selectedChat$() {
        return this._selectors.getSelectedChat$();
    }
    get subChats$() {
        return this._selectors.subChats$;
    }
    // get selectedChatMembers$() {
    //   return this._selectors.selectedChatMembers$;
    // }
    get list$() {
        return this._selectors.selectedChatMembers$.pipe(map(members => {
            return members.map(member => {
                const item = {};
                item.id = member.user_id;
                item.date = member.member_since;
                item.name = member.name;
                item.msidin = member.msisdn;
                item.role = member.role_name;
                return item;
            });
        }));
    }
    getMemberDetailsByID$(id) {
        return this._selectors.selectedChatMembers$.pipe(map(members => {
            return members.find(member => member.user_id === id);
        }));
    }
    getMemberSubChats$(userId) {
        return this._selectors.getMemberSubChats$(userId);
    }
    getSubChatMember$(userId, chatId) {
        return this._selectors.getSubChatMember$(userId, chatId);
    }
    getSubChatMembers$(chatId) {
        return this._selectors.getSubChatMembers$(chatId);
    }
    getSubChatAdmins$(chatId) {
        return this._selectors.getSubChatAdmins$(chatId);
    }
    getNextPage(name) {
        this._membersUiDispatchers.getNextMembersPage();
    }
    getPreviousPage(name) {
        this._membersUiDispatchers.getPrevMembersPage();
    }
    resetPaging(name, status, view, accountTypes) {
        this._membersUiDispatchers.resetMembersPaging(name, this.searchBy, status, view, accountTypes);
    }
    get membersUI$() {
        return this._selectors.membersUi$;
    }
    get statusUI$() {
        return this._selectors.membersUi$.pipe(map(ui => {
            const abstractUI = {};
            abstractUI.status = ui.status;
            return abstractUI;
        }));
    }
    setSearchBy(searchBy) {
        this.searchBy = searchBy;
    }
    deleteUser(userID, chatID, ref) {
        this._membersUiDispatchers.deleteUser(userID, chatID, ref);
    }
    banMemberFromChat(userID, chatID) {
        this._membersUiDispatchers.banUser(userID, chatID);
    }
    unBanMemberFromChat(userID, chatID) {
        this._membersUiDispatchers.unbanUser(userID, chatID);
    }
    getBuyerUser$(userId) {
        return this._selectors.getProfileByID$(userId);
    }
}
MembersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MembersService_Factory() { return new MembersService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.ChatDispatchers), i0.ɵɵinject(i3.ChatMemberDispatchers), i0.ɵɵinject(i4.MembersUiDispatchers), i0.ɵɵinject(i5.BundlesUiDispatchers)); }, token: MembersService, providedIn: "root" });
