<table class="table-container" role="table">
  <thead>
    <tr
      class="flex-table header"
      role="rowgroup"
      style="background: #d9e2ec;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: none;
      "
    >
      <ng-container *ngFor="let col of tableColumns; trackBy: TrackByFields">
        <th
          *ngIf="
            col?.colType !== 'msidin' &&
            col?.colType !== 'action' &&
            col?.colType !== 'date'
          "
          role="columnheader"
          [class]="col.class"
          style="padding: 5px;"
        >
          {{ col.title }}
        </th>
        <ng-container *ngIf="col?.colType === 'msidin'">
          <th
            *appHideIfSubChat="true"
            role="columnheader"
            style="padding: 5px;"
            [class]="col.class"
          >
            {{ col.title }}
          </th>
        </ng-container>
        <ng-container *ngIf="col?.colType === 'date'">
          <th
            role="columnheader"
            [class]="col.class"
            style="margin-right: 5px;"
          >
            {{ col.title }}
          </th>
        </ng-container>

        <ng-container *ngIf="col?.colType === 'action'">
          <th
            role="columnheader"
            [class]="col.class"
            style="position: relative;"
          >
            <app-round-paging-btn
              (nextBtnClicked)="getNextPage()"
              (previousBtnClicked)="getPreviousPage()"
            >
            </app-round-paging-btn>
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </thead>

  <tbody
    style="background: #eef3f9;
    padding: 5px;"
  >
    <div
      style="height: 300px; display: flex; flex-direction: column; align-items: center; justify-content: center;"
      *ngIf="isLoading"
    >
      <i class="fas fa-spinner fa-spin" style="font-size: 25px;"></i>
    </div>
    <div *ngIf="list.length > 0 && !isLoading">
      <tr
        *ngFor="let item of list; let i = index; trackBy: TrackByFunction"
        class="flex-table row"
        role="rowgroup"
        style="border-radius: 4px;box-shadow: 0 0 5px 0 #d6e2f0;
                background: #fff; border:none;"
      >
        <ng-container *ngFor="let col of tableColumns; trackBy: TrackByFields">
          <td *ngIf="col?.colType !== 'msidin'" role="cell" [class]="col.class">
            <app-profile-image
              *ngIf="
                col?.colType === 'avatar' && userState === userStatus?.ACTIVE
              "
              [id]="item?.id"
              [chatType]="col?.avatar?.type"
              [size]="col?.avatar?.size"
            >
            </app-profile-image>
            <app-profile-image-block
              *ngIf="
                col?.colType === 'avatar' && userState === userStatus?.BANNED
              "
              [id]="item?.id"
              [chatType]="col?.avatar?.type"
              [size]="col?.avatar?.size"
            ></app-profile-image-block>
            <span *ngIf="col?.colType === 'date'" style="margin: 5px;">
              {{
                col.dateFormat
                  ? (item?.date | date: col.dateFormat)
                  : (item?.date | date: 'MM dd,yyyy')
              }}
            </span>
            <span *ngIf="col?.colType === 'name'"> {{ item?.name }}</span>
            <span *ngIf="col?.colType === 'msidin'"> {{ item?.msidin }}</span>
            <span *ngIf="col?.colType === 'role'" style="padding: 6px;">
              {{ item?.role }}</span
            >

            <ng-template
              *ngIf="col?.colType === 'action'"
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{
                $implicit: item,
                index: item.id
              }"
            >
            </ng-template>
          </td>
          <ng-container *ngIf="col?.colType === 'msidin'">
            <td *appHideIfSubChat="true" role="cell" [class]="col.class">
              <span> {{ item?.msidin }}</span>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </div>

    <div *ngIf="list.length === 0 && !isLoading">
      <div style="height: 300px;">
        <app-empty-section [section]="empty"></app-empty-section>
      </div>
    </div>
  </tbody>
</table>
