<!-- header with title -->
<div
  *ngIf="!hideHeader && !isBot"
  class="header"
  [style.background-color]="themeColor"
>
  Preview
  <i
    class="fas fa-times close clickable-cursor"
    (click)="cancelMediaPreview()"
    aria-hidden="true"
  ></i>
</div>
<div *ngIf="isBot" class="bot-header">
  Preview
  <svg class="svg-icon icon-icon close" (click)="cancelMediaPreview()">
    <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
  </svg>
</div>

<!-- preview section -->
<div class="preview">
  <!-- disply if no media -->
  <span *ngIf="!mediaMetadata?.mediaUrl && !mediaError" class="spiner-loader">
    <div class="preview-spinner" [ngStyle]="theme"></div>
  </span>

  <!-- if media not suportted -->
  <span *ngIf="!mediaMetadata?.mediaUrl && mediaError">
    The file you are trying to upload is not a supported file type
  </span>

  <!-- if media supported and exist -->

  <div *ngIf="mediaMetadata?.mediaUrl" class="media-container">
    <!-- image tag -->
    <img
      *ngIf="
        messageType === mTypes.PHOTO ||
        (messageType === mTypes.GIF && files?.name?.endsWith('.gif'))
      "
      class="media"
      [src]="media"
      [alt]="mediaMetadata?.fileName"
    />

    <!-- video tag -->
    <video
      *ngIf="
        messageType === mTypes.VIDEO ||
        (messageType === mTypes.GIF && files?.name?.endsWith('.mp4'))
      "
      class="media"
      controls
      #videoTag
    >
      <source [src]="media" type="video/mp4" />
    </video>

    <!-- audio tag -->
    <audio
      *ngIf="messageType === mTypes.AUDIO"
      class="media"
      controls
      #audioTag
    >
      <source [src]="media" type="audio/mp4" />
    </audio>

    <!-- document tag -->
    <div
      *ngIf="messageType === mTypes.DOCUMENT"
      class="media document wrap-text"
    >
      <i class="fas fa-file"></i>
      <div class="wrap-text" dir="auto">{{ mediaMetadata?.fileName }}</div>
    </div>

    <!-- Message Targets ( Tags and Tab ) -->
    <ng-content
      *ngIf="
        messageType === mTypes.PHOTO ||
        messageType === mTypes.VIDEO ||
        messageType === mTypes.GIF
      "
      select="[message-style]"
    ></ng-content>
    <!------------------------------------------------->
  </div>
</div>

<!-- chat Box -->
<div [hidden]="messageType === 'document'" class="chat-box">
  <!-- Emoji Box -->
  <div
    class="btn-group clickable-cursor"
    dropdown
    [autoClose]="true"
    [dropup]="true"
    [isOpen]="isEmojiKeyboardOpen"
    (isOpenChange)="toggleEmojiKeyboard($event)"
  >
    <div dropdownToggle type="button">
      <i
        class="emoji far fa-smile"
        [class.open]="isEmojiKeyboardOpen"
        aria-hidden="true"
      ></i>
    </div>
    <ul
      *dropdownMenu
      appCancelEvent
      class="dropdown-menu emoji-box"
      role="menu"
    >
      <app-emoji-keyboard
        (emoijNotify)="emoijNotify($event)"
      ></app-emoji-keyboard>
    </ul>
  </div>

  <!-- Caption Box -->
  <div
    class="caption wrap-text copyable-item text-cursor"
    appAutoFocus
    data-text="Add a caption…"
    contenteditable
    dir="auto"
    #captionBox
    [selectedChat]="selectedChat?.id"
    (paste)="onRichPaste($event)"
    (mouseup)="lastfocus()"
    (keyup)="lastfocus()"
  ></div>
</div>
<!-- Message Attachs -->
<ng-content select="[message-attachs]"></ng-content>
<!------------------------------------------------->
<!-- Message Targets ( Tags and Tab ) -->
<ng-content select="[message-target]"></ng-content>
<!------------------------------------------------->

<!-- buttons -->
<div class="footer">
  <div class="form-submit">
    <ng-content select="[footer-tag]"></ng-content>
    <ng-content select="[footer-submit]"></ng-content>
    <ng-content select="[footer-cancel]"></ng-content>
    <app-border-btn cancel (btnClicked)="cancelMediaPreview()">
      Cancel
    </app-border-btn>
  </div>
</div>
