import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as UIActions from './ui.actions';

import { AppState } from 'src/models/AppState';
import { IMessage } from 'src/models/IMessage';
import { MainChat } from 'src/models/MainChat';
import { BotMenu } from 'src/models/IBotMenu';
import { MapMarker } from 'src/models/MapMarker';

@Injectable({
  providedIn: 'root'
})
export class UIDispatchers {
  constructor(private _store: Store<AppState>) {}

  chatSelected(chat: MainChat) {
    this._store.dispatch(new UIActions.ChatSelected(chat));
  }

  subChatSelected(chat: MainChat) {
    this._store.dispatch(new UIActions.SubChatSelected(chat));
  }

  subChatDeselected() {
    this._store.dispatch(new UIActions.SubChatDeselected());
  }
  messageSelectedForStats(msg: IMessage) {
    this._store.dispatch(new UIActions.MessageSelectedForStat(msg));
  }
  messageSelected(msg: IMessage) {
    this._store.dispatch(new UIActions.MessageSelected(msg));
  }

  subParentMessageSelected(msg: IMessage) {
    this._store.dispatch(new UIActions.SubParentMessageSelected(msg));
  }

  backFromSubGroupReplies() {
    this._store.dispatch(new UIActions.BackFromSubGroupReplies());
  }

  userSelected(userID: string) {
    this._store.dispatch(new UIActions.UserSelected(userID));
  }

  groupRepliesSelected() {
    this._store.dispatch(new UIActions.GroupRepliesSelected());
  }

  backFromGroupReplies() {
    this._store.dispatch(new UIActions.BackFromGroupReplies());
  }

  listSelected(selectedList: string) {
    this._store.dispatch(new UIActions.ListSelected(selectedList));
  }

  selectAllChannels() {
    this._store.dispatch(new UIActions.SelectAllChannels());
  }

  selectOneToOneReply(selectedUserID: string) {
    this._store.dispatch(new UIActions.SelectOneToOneReply(selectedUserID));
  }

  selectTalkToAdmin() {
    this._store.dispatch(new UIActions.SelectTalkToAdmin());
  }

  selectReplyToAdmin() {
    this._store.dispatch(new UIActions.SelectReplyToAdmin());
  }

  backFromOneToOneReply() {
    this._store.dispatch(new UIActions.BackFromOneToOneReply());
  }

  showPopup(popupMessage: string, popupTitle?: string) {
    this._store.dispatch(new UIActions.ShowPopup(popupMessage, popupTitle));
  }

  showErrorPopup(popupErrorMessage: string) {
    this._store.dispatch(new UIActions.ShowErrorPopup(popupErrorMessage));
  }

  hidePopup() {
    this._store.dispatch(new UIActions.HidePopup());
  }

  hideErrorSnackbar() {
    this._store.dispatch(new UIActions.HideErrorSnackbar());
  }

  showPreview() {
    this._store.dispatch(new UIActions.ShowPreview());
  }

  hidePreview() {
    this._store.dispatch(new UIActions.HidePreview());
  }

  showDragDropScreen() {
    this._store.dispatch(new UIActions.ShowDragDropScreen());
  }

  hideDragDropScreen() {
    this._store.dispatch(new UIActions.HideDragDropScreen());
  }

  setChannelsListPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetChannelsListPageNumber(pageNumber));
  }

  setChatsListPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetChatsListPageNumber(pageNumber));
  }

  // Normal Post in main channel
  setPostsPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetPostsPageNumber(pageNumber));
  }
  setPostRepliesPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetPostRepliesPageNumber(pageNumber));
  }
  setMessagesPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetMessagesPageNumber(pageNumber));
  }
  // Set Sub-Post Actions
  setSubGroupMessagesPageNumber(pageNumber: number) {
    this._store.dispatch(
      new UIActions.SetSubGroupMessagesPageNumber(pageNumber)
    );
  }
  setSubPostRepliesPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetSubPostRepliesPageNumber(pageNumber));
  }
  setSubPostsPageNumber(pageNumber: number) {
    this._store.dispatch(new UIActions.SetSubPostsPageNumber(pageNumber));
  }

  setSearchKeyWord(searchKeyWord: string) {
    this._store.dispatch(new UIActions.SetSearchKeyWord(searchKeyWord));
  }

  setContactsCount(count: number) {
    this._store.dispatch(new UIActions.SetContactsCount(count));
  }

  setGroupsCount(count: number) {
    this._store.dispatch(new UIActions.SetGroupsCount(count));
  }

  evaluateShowDashboard() {
    this._store.dispatch(new UIActions.EvaluateShowDashboard());
  }
  detailsReceived() {
    this._store.dispatch(new UIActions.DetailsReceived());
    setTimeout(() => {
      this._store.dispatch(new UIActions.ShowDashboard());
    }, 1000);
  }

  setHasNoContacts() {
    this._store.dispatch(new UIActions.SetHasNoContacts());
  }

  showMediaScreen(message: IMessage) {
    this._store.dispatch(new UIActions.ShowMediaScreen(message));
  }

  hideMediaScreen() {
    this._store.dispatch(new UIActions.HideMediaScreen());
  }

  selectChannelPost(message: IMessage) {
    this._store.dispatch(new UIActions.SelectChannelPost(message));
  }
  selectSubChannelPost(message: IMessage) {
    this._store.dispatch(new UIActions.SelectSubChannelPost(message));
  }

  /* Merchant Web Only */

  subUserSelected(userID: string) {
    this._store.dispatch(new UIActions.SubUserSelected(userID));
  }

  subUserDeselected() {
    this._store.dispatch(new UIActions.SubUserDeselected());
  }

  setClickedMessage(message: IMessage) {
    this._store.dispatch(new UIActions.SetClickedMessage(message));
  }
  setSection(payload: string): void {
    this._store.dispatch(new UIActions.SetSection(payload));
  }
  setPageNumber(payload: string): void {
    this._store.dispatch(new UIActions.SetPageNumber(payload));
  }
  dateSelected(payload: Date): void {
    this._store.dispatch(new UIActions.DateSelected(payload));
  }
  botMenuSelected(payload: BotMenu) {
    this._store.dispatch(new UIActions.BotMenuSelected(payload));
  }
  setAuthPrivilege(payload: string[]) {
    this._store.dispatch(new UIActions.SetAuthPrivilege(payload));
  }

  setSideMenuStatus(status: number) {
    this._store.dispatch(new UIActions.SetSideMenuStatus(status));
  }

  mapMarkerSelected(payload: MapMarker) {
    this._store.dispatch(new UIActions.MapMarkerSelected(payload));
  }
}
