import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBarcodeModule } from 'ngx-barcode';

import { NgxModule } from 'src/app/ngx.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { CouponFormModule } from '../coupons/coupon-form.module';

import { CouponsPoolComponent } from './coupons-pool/coupons-pool.component';
import { CouponPreviewComponent } from './coupon-preview/coupon-preview.component';

@NgModule({
  imports: [
    CommonModule,
    NgxBarcodeModule,
    NgxModule,
    SharedModule,
    AccessorsModule,
    CouponFormModule,
    AbstractBtnsModule,
    AbstractFramesModule
  ],
  declarations: [CouponsPoolComponent, CouponPreviewComponent],
  exports: [CouponsPoolComponent, CouponPreviewComponent]
})
export class CouponsPoolModule {}
