/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./members-assign.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/profile-image/profile-image.component.ngfactory";
import * as i3 from "../../../../shared/components/profile-image/profile-image.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../store/root-store.selectors";
import * as i6 from "../../../../abstract-btns/add-btn.component.ngfactory";
import * as i7 from "../../../../abstract-btns/add-btn.component";
import * as i8 from "../../../../shared/components/modal-container/modal-container.component.ngfactory";
import * as i9 from "../../../../shared/components/modal-container/modal-container.component";
import * as i10 from "../../../../abstract-pools/list-selector-pool/list-selector-pool.component.ngfactory";
import * as i11 from "../../../../abstract-pools/list-selector-pool/list-selector-pool.component";
import * as i12 from "../../members/members.service";
import * as i13 from "@angular/common";
import * as i14 from "./members-assign.component";
var styles_MembersAssignComponent = [i0.styles];
var RenderType_MembersAssignComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MembersAssignComponent, data: {} });
export { RenderType_MembersAssignComponent as RenderType_MembersAssignComponent };
function View_MembersAssignComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "projection-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " Admins "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screenType; _ck(_v, 1, 0, currVal_0); }); }
function View_MembersAssignComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-profile-image", [["class", "projection-avatar"]], null, null, null, i2.View_ProfileImageComponent_0, i2.RenderType_ProfileImageComponent)), i1.ɵdid(2, 704512, null, 0, i3.ProfileImageComponent, [i4.DomSanitizer, i5.RootStoreSelectors, i1.ChangeDetectorRef], { id: [0, "id"], size: [1, "size"], chatType: [2, "chatType"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = 24; var currVal_2 = "Individual"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MembersAssignComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "count"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" + ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.admins == null) ? null : _co.admins.length) - 5); _ck(_v, 1, 0, currVal_0); }); }
function View_MembersAssignComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-add-btn", [], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.showUsersModalEvent(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_AddBtnComponent_0, i6.RenderType_AddBtnComponent)), i1.ɵdid(2, 49152, null, 0, i7.AddBtnComponent, [], null, { btnClicked: "btnClicked" })], null, null); }
function View_MembersAssignComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-modal-container", [["no-scroll", ""]], null, [[null, "cancelNotfiy"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancelNotfiy" === en)) {
        var pd_1 = (_co.showUsersModalEvent(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_ModalContainerComponent_0, i8.RenderType_ModalContainerComponent)), i1.ɵdid(1, 49152, null, 0, i9.ModalContainerComponent, [i1.ElementRef], null, { cancelNotfiy: "cancelNotfiy" }), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "app-list-selector-pool", [["class", "customer-modal-form"]], null, [[null, "cancelNotfiy"], [null, "submitItemsFormNotfiy"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancelNotfiy" === en)) {
        var pd_0 = (_co.showUsersModalEvent(false) !== false);
        ad = (pd_0 && ad);
    } if (("submitItemsFormNotfiy" === en)) {
        var pd_1 = (_co.setSubChatsEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_ListSelectorPoolComponent_0, i10.RenderType_ListSelectorPoolComponent)), i1.ɵdid(3, 638976, null, 0, i11.ListSelectorPoolComponent, [i12.MembersService], { items: [0, "items"], tableColumns: [1, "tableColumns"], tableService: [2, "tableService"], title: [3, "title"], allowSearchBy: [4, "allowSearchBy"], searchOptions: [5, "searchOptions"], selectedMembers: [6, "selectedMembers"] }, { submitItemsFormNotfiy: "submitItemsFormNotfiy", cancelNotfiy: "cancelNotfiy" }), i1.ɵpid(131072, i13.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.membersService.list$)); var currVal_1 = _co.tableColumns; var currVal_2 = _co.membersService; var currVal_3 = "Add Member"; var currVal_4 = true; var currVal_5 = _co.searchOptions; var currVal_6 = _co.selectedMembers; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_MembersAssignComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], [[2, "projection", null], [2, "onEdit", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MembersAssignComponent_1)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "projection-value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MembersAssignComponent_2)), i1.ɵdid(5, 278528, null, 0, i13.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MembersAssignComponent_3)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MembersAssignComponent_4)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MembersAssignComponent_5)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.stayleOnEdit; _ck(_v, 2, 0, currVal_2); var currVal_3 = ((_co.admins == null) ? null : _co.admins.slice(0, 5)); _ck(_v, 5, 0, currVal_3); var currVal_4 = (((_co.admins == null) ? null : _co.admins.length) > 5); _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.showButton; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.usersModal; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.stayleOnEdit; var currVal_1 = _co.stayleOnEdit; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_MembersAssignComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-members-assign", [], null, null, null, View_MembersAssignComponent_0, RenderType_MembersAssignComponent)), i1.ɵdid(1, 573440, null, 0, i14.MembersAssignComponent, [i12.MembersService, i1.ChangeDetectorRef], null, null)], null, null); }
var MembersAssignComponentNgFactory = i1.ɵccf("app-members-assign", i14.MembersAssignComponent, View_MembersAssignComponent_Host_0, { chatId: "chatId", chatMembers: "chatMembers", stayleOnEdit: "stayleOnEdit", showButton: "showButton", screenType: "screenType", card: "card", admins: "admins", inform: "inform" }, { addNotifiy: "addNotifiy", addAdmin: "addAdmin" }, []);
export { MembersAssignComponentNgFactory as MembersAssignComponentNgFactory };
