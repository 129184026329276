
    <ng-content></ng-content>
    <ng-template [ngIf]="!isPost">
      {{
        (todayDate | date: 'yMd') === (timestamp | date: 'yMd')
          ? (timestamp | date: 'hh:mm a')
          : (timestamp | date: 'MMM d')
      }}
    </ng-template>
    <ng-template [ngIf]="isPost">
      {{
        (todayDate | date: 'y') === (timestamp | date: 'y')
          ? (timestamp | date: "MMM dd 'at' hh:mm a")
          : (timestamp | date: "MMM dd,y 'at' hh:mm a")
      }}
    </ng-template>
  