import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxBarcodeModule } from 'ngx-barcode';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { NgxModule } from '../ngx.module';
import { SharedModule } from '../shared/shared.module';
import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';

import { ContactCellComponent } from './contact-cell/contact-cell.component';
import { AudioCellComponent } from './audio-cell/audio-cell.component';
import { VideoCellComponent } from './video-cell/video-cell.component';
import { ImageCellComponent } from './image-cell/image-cell.component';
import { StickerCellComponent } from './sticker-cell/sticker-cell.component';
import { TextFileCellComponent } from './text-file-cell/text-file-cell.component';
import { MapCellComponent } from './map-cell/map-cell.component';
import { GifCellComponent } from './gif-cell/gif-cell.component';
import { FileCellComponent } from './file-cell/file-cell.component';
import { TextCellComponent } from './text-cell/text-cell.component';
import { LinkPreviewCellComponent } from './link-preview-cell/link-preview-cell.component';
import { BubbelCellComponent } from './bubbel-cell/bubbel-cell.component';
import { PostCellComponent } from './post-cell/post-cell.component';
import { ReplyCellComponent } from './reply-cell/reply-cell.component';
import { MessageOptionsComponent } from './message-options/message-options.component';
import { InstantCellComponent } from './instant-cell/instant-cell.component';
import { PostCellActionsComponent } from './post-cell-actions/post-cell-actions.component';
import { CalendarPreviewCellComponent } from './calendar-preview-cell/calendar-preview-cell.component';
import { ColoredTextCellComponent } from './colored-text-cell/colored-text-cell.component';
import { MenuFormModule } from '../+merchant/+dashboard/menus/menu-form/menu.form.module';

@NgModule({
  imports: [
    CommonModule,
    AngularSvgIconModule,
    NgCircleProgressModule.forRoot({
      backgroundPadding: 0,
      radius: 34,
      space: 0,
      toFixed: 0,
      maxPercent: 100,
      outerStrokeWidth: 1.5,
      outerStrokeLinecap: 'inherit',
      outerStrokeColor: '#E7E8EA',
      animateTitle: false,
      animationDuration: 300,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      showBackground: false,
      showInnerStroke: false,
      clockwise: true
    }),
    NgxBarcodeModule,
    SharedModule,
    NgxModule,
    AbstractIconsModule,
    MenuFormModule
  ],
  declarations: [
    ImageCellComponent,
    VideoCellComponent,
    AudioCellComponent,
    ContactCellComponent,
    TextFileCellComponent,
    MapCellComponent,
    StickerCellComponent,
    GifCellComponent,
    FileCellComponent,
    TextCellComponent,
    LinkPreviewCellComponent,
    BubbelCellComponent,
    PostCellComponent,
    ReplyCellComponent,
    MessageOptionsComponent,
    InstantCellComponent,
    PostCellActionsComponent,
    CalendarPreviewCellComponent,
    ColoredTextCellComponent
  ],
  exports: [
    ImageCellComponent,
    VideoCellComponent,
    AudioCellComponent,
    ContactCellComponent,
    TextFileCellComponent,
    MapCellComponent,
    StickerCellComponent,
    GifCellComponent,
    FileCellComponent,
    TextCellComponent,
    LinkPreviewCellComponent,
    BubbelCellComponent,
    PostCellComponent,
    ReplyCellComponent,
    MessageOptionsComponent,
    InstantCellComponent,
    CalendarPreviewCellComponent,
    ColoredTextCellComponent
  ]
})
export class CellsModule {}
