/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./round-paging-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/directives/throttle-click/throttle-click.directive";
import * as i3 from "./round-paging-btn.component";
var styles_RoundPagingBtnComponent = [i0.styles];
var RenderType_RoundPagingBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoundPagingBtnComponent, data: {} });
export { RenderType_RoundPagingBtnComponent as RenderType_RoundPagingBtnComponent };
export function View_RoundPagingBtnComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["appThrottleClick", ""], ["class", "left-arrow clickable-cursor"]], null, [[null, "throttleClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("throttleClick" === en)) {
        var pd_1 = (_co.getPreviousPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.ThrottleClickDirective, [], { throttleTime: [0, "throttleTime"] }, { throttleClick: "throttleClick" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["src", "./assets/img/back-btn.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["appThrottleClick", ""], ["class", "right-arrow clickable-cursor"]], null, [[null, "throttleClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onHostClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("throttleClick" === en)) {
        var pd_1 = (_co.getNextPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 212992, null, 0, i2.ThrottleClickDirective, [], { throttleTime: [0, "throttleTime"] }, { throttleClick: "throttleClick" }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "./assets/img/next-btn.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = 300; _ck(_v, 2, 0, currVal_0); var currVal_1 = 300; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RoundPagingBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-round-paging-btn", [], null, null, null, View_RoundPagingBtnComponent_0, RenderType_RoundPagingBtnComponent)), i1.ɵdid(1, 49152, null, 0, i3.RoundPagingBtnComponent, [], null, null)], null, null); }
var RoundPagingBtnComponentNgFactory = i1.ɵccf("app-round-paging-btn", i3.RoundPagingBtnComponent, View_RoundPagingBtnComponent_Host_0, {}, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }, []);
export { RoundPagingBtnComponentNgFactory as RoundPagingBtnComponentNgFactory };
