import { Injectable } from '@angular/core';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import { PageDirection } from 'src/models/constants';
import { StoreMenuUIActionTypes } from './storeMenuUI.actions';
import { Effect, Actions, ofType } from '@ngrx/effects';
import * as StoreMenuUIAction from './storeMenuUI.actions';
import { StoreMenuUiDispatchers } from './storeMenuUI.dispatchers';
import {
  CreateStoreMenuMethod,
  StoreMenu,
  SetStoreMenuMethod,
  AddStoreMenuItemMethod,
  RemoveStoreMenuItemMethod,
  GetStoreMenusMethod,
  GetStoreMenuMethod,
  RemoveStoreMenuMethod
} from 'src/models/StoreMenu';
import { GetSubMenusMethod } from 'src/models/SubMenu';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class StoreMenuUiEffects {
  @Effect({ dispatch: false })
  createStoreMenu = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.CREATE_STOERE_MENU),
    map((action: StoreMenuUIAction.CreateStoreMenu) => {
      this._socketGateway.sendSocketMessage(
        new CreateStoreMenuMethod(action.storeMenusDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  getStoreMenu = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.GET_STOERE_MENU),
    map((action: StoreMenuUIAction.GetStoreMenu) => {
      console.log('store menu =>', action.id);
      this._socketGateway.sendSocketMessage(new GetStoreMenuMethod(action.id));
    })
  );

  @Effect({ dispatch: false })
  updateStoreMenu = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.SET_STORE_MENU),
    map((action: StoreMenuUIAction.SetStoreMenu) => {
      const storeMenuDetails: StoreMenu = {
        ...action.storeMenuDetails,
        id: action.storeMenuID
      };
      this._socketGateway.sendSocketMessage(
        new SetStoreMenuMethod(storeMenuDetails)
      );
    })
  );

  @Effect({ dispatch: false })
  addStoreMenuItem = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.ADD_STORE_MENU_ITEM),
    map((action: StoreMenuUIAction.AddStoreMenuItem) => {
      this._socketGateway.sendSocketMessage(
        new AddStoreMenuItemMethod(action.storeMenuID, action.items)
      );
    })
  );

  @Effect({ dispatch: false })
  removeStoreMenuItem = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.REMOVE_STORE_MENU_ITEM),
    map((action: StoreMenuUIAction.RemoveStoreMenuItem) => {
      this._socketGateway.sendSocketMessage(
        new RemoveStoreMenuItemMethod(action.storeMenuID, action.itemIDs)
      );
    })
  );

  //   //
  @Effect({ dispatch: false })
  getNextStoreMenuPage = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.UI_STORE_MENU_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.storeMenuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, storeMenuUiState]) => {
      const action = <StoreMenuUIAction.GetNextStoreMenuPage>val;
      const prevRequest = storeMenuUiState.previousRequest;
      if (
        !(
          storeMenuUiState.eop === prevRequest.eop &&
          PageDirection.NEXT === prevRequest.direction &&
          storeMenuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetStoreMenusMethod(0, storeMenuUiState.eop)
        );
      } else {
        this._uiStoreMenuDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  getPrevStoreMenuPage = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.UI_STORE_MENU_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.storeMenuUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, storeMenuUiState]) => {
      const action = <StoreMenuUIAction.GetPrevStoreMenuPage>val;
      const prevRequest = storeMenuUiState.previousRequest;
      if (
        !(
          storeMenuUiState.sop === prevRequest.sop &&
          PageDirection.PREV === prevRequest.direction &&
          storeMenuUiState.hash === prevRequest.hash
        )
      ) {
        this._socketGateway.sendSocketMessage(
          new GetStoreMenusMethod(1, storeMenuUiState.sop)
        );
      } else {
        this._uiStoreMenuDispatchers.setStatusAsIdle();
      }
    })
  );

  @Effect({ dispatch: false })
  afterStoreMenuResetPaging = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.UI_STORE_MENU_RESET_PAGING),
    map((action: StoreMenuUIAction.ResetStoreMenuPaging) => {
      this._uiStoreMenuDispatchers.getNextStoreMenuPage();
    })
  );

  @Effect({ dispatch: false })
  deletePackage = this.actions$.pipe(
    ofType(StoreMenuUIActionTypes.DELETE_MENU),
    map((action: StoreMenuUIAction.DeleteStoreMenu) => {
      this._socketGateway.sendSocketMessage(
        new RemoveStoreMenuMethod(action.menuID)
      );
    })
  );
  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiStoreMenuDispatchers: StoreMenuUiDispatchers,
    private _store: Store<AppState>
  ) {}
}
