import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MembersUIActions from './membersUI.actions';

import { AppState } from 'src/models/AppState';
import { IChatMember } from 'src/models/IChatMember';

@Injectable({
  providedIn: 'root'
})
export class MembersUiDispatchers {
  constructor(private _store: Store<AppState>) {}
  // paging dispatchers
  getNextMembersPage(): void {
    this._store.dispatch(new MembersUIActions.GetNextMembersPage());
  }

  getPrevMembersPage(): void {
    this._store.dispatch(new MembersUIActions.GetPrevMembersPage());
  }

  receivedMembersHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChatMember[],
    hash: string
  ): void {
    this._store.dispatch(
      new MembersUIActions.ReceivedMembersHistoryChunk(
        eop,
        sop,
        currentPage,
        hash
      )
    );
  }

  resetMembersPaging(
    name?: string,
    searchBy?: number,
    status?: any,
    view?: number,
    accountTypes?: string[]
  ): void {
    this._store.dispatch(
      new MembersUIActions.ResetMembersPaging(
        name,
        searchBy,
        status,
        view,
        accountTypes
      )
    );
  }

  setStatusAsIdle(): void {
    this._store.dispatch(new MembersUIActions.SetStatusAsIdle());
  }

  deleteUser(userID: string, chatID: string, ref: string) {
    this._store.dispatch(new MembersUIActions.DeleteUser(userID, chatID, ref));
  }

  banUser(userID: string, chatID: string) {
    this._store.dispatch(new MembersUIActions.BanUser(userID, chatID));
  }

  unbanUser(userID: string, chatID: string) {
    this._store.dispatch(new MembersUIActions.UnBanUser(userID, chatID));
  }

  getMyMembers() {
    this._store.dispatch(new MembersUIActions.GetMyMembers());
  }
}
