<div class="header">
  <!-- Profile Cell -->
  <div class="profile">
    <app-profile-image
      [id]="selectedChat?.id"
      [chatType]="'MyProfile'"
      [size]="32"
    >
    </app-profile-image>
    <span class="text-muted"> {{ chatAdminName }}</span>
  </div>
  <!------------------------------------------------>
  <!-- Close btn -->
  <i
    class="fas fa-times close clickable-cursor"
    aria-hidden="true"
    (click)="cancelEvent()"
  >
  </i>
  <!------------------------------------------------>
</div>

<div class="body">
  <div class="head">
    <app-select-message-type
      [messageType]="uiService.previewMessageType$ | async"
      [messageMediaPreview]="(uiService.uiCollection$ | async)?.showPreview"
      [messageLinkPreview]="iv$ | async"
      (textNotifiy)="resetSelectedType()"
      (urlSelectedNotifiy)="urlSelectedEvent($event)"
      (fileSelectedNotifiy)="fileSelectedEvent($event)"
      (calendarSelectedNotifiy)="calendarSelectedEvent($event)"
    >
    </app-select-message-type>
    <app-select-message-attachs #attachComponent></app-select-message-attachs>
  </div>

  <div class="content">
    <!-- Media -->
    <app-preview-media
      *ngIf="
        (uiService.uiCollection$ | async)?.showPreview &&
        !(iv$ | async) &&
        !(selectedCalendar$ | async)
      "
      class="preview"
      no-padding
      #postMediaPreview
      [hideHeader]="true"
      [selectedChat]="uiService.selectedChat$ | async"
      [mediaMetadata]="uiService.previewMediaMetadata$ | async"
      [mediaError]="uiService.previewMediaError$ | async"
      [files]="uiService.previewFiles$ | async"
      [messageType]="uiService.previewMessageType$ | async"
      [isErrorMessageShown]="(uiService.uiCollection$ | async)?.showPopup"
    >
      <app-compose-attachs-preview
        message-attachs
        [attachCoupon]="attachComponent?.getSelectedCoupon()"
        [attachMenu]="attachComponent?.getSelectedMenu()"
        [attachSticky]="attachComponent?.getStickyInput()"
        (removeAttachCouponNotfiy)="attachComponent?.resetCouponEvent()"
        (removeAttachMenuNotfiy)="attachComponent?.resetMenuEvent()"
        (removeAttachStickyNotfiy)="attachComponent?.resetStickyInput()"
      >
      </app-compose-attachs-preview>

      <app-select-message-style
        message-style
        #stylesComponent
        [messageType]="uiService.previewMessageType$ | async"
      >
      </app-select-message-style>

      <app-select-message-targets
        message-target
        #targetsComponent
        [selectedTab]="(composeMessageService.tabsContainers$ | async)[0]"
      >
      </app-select-message-targets>

      <app-border-btn
        submit
        (btnClicked)="sendMediaMessageEvent(postMediaPreview?.getMediaToSend())"
        footer-submit
      >
        {{ messageSendingType === attachType?.SCHEDULE ? 'Schedule' : 'Send' }}
      </app-border-btn>

      <app-compose-schedule-input
        *ngIf="messageSendingType === attachType?.SCHEDULE"
        footer-tag
        #scheduleInputComponent
      >
      </app-compose-schedule-input>
    </app-preview-media>
    <!----------------------------------------------------->

    <!-- Text -->
    <app-compose-text-input
      *ngIf="
        !(uiService.uiCollection$ | async)?.showPreview &&
        !(iv$ | async) &&
        !(selectedCalendar$ | async)
      "
      #textInputComponent
    >
      <app-compose-attachs-preview
        message-attachs
        [attachCoupon]="attachComponent?.getSelectedCoupon()"
        [attachMenu]="attachComponent?.getSelectedMenu()"
        [attachSticky]="attachComponent?.getStickyInput()"
        (removeAttachCouponNotfiy)="attachComponent?.resetCouponEvent()"
        (removeAttachMenuNotfiy)="attachComponent?.resetMenuEvent()"
        (removeAttachStickyNotfiy)="attachComponent?.resetStickyInput()"
      >
      </app-compose-attachs-preview>

      <app-select-message-targets
        message-target
        #targetsComponent
        [selectedTab]="(composeMessageService.tabsContainers$ | async)[0]"
      >
      </app-select-message-targets>
      <span
        style="position: relative;"
        (mouseover)="toggleHoverTooltip(true)"
        (mouseleave)="toggleHoverTooltip(false)"
        footer-submit
      >
        <app-border-btn
          submit
          [disabled]="canSend"
          (btnClicked)="
            sendTextMessageEvent(textInputComponent?.getTextToSend())
          "
        >
          {{
            messageSendingType === attachType?.SCHEDULE ? 'Schedule' : 'Send'
          }}
        </app-border-btn>

        <app-hover-tooltip
          *ngIf="showTooltipHover"
          [top]="0"
          [right]="112"
          [text]="textTooltip"
          [width]="'max-content'"
        ></app-hover-tooltip>
      </span>

      <app-compose-schedule-input
        *ngIf="messageSendingType === attachType?.SCHEDULE"
        footer-tag
        #scheduleInputComponent
        (isScheduleDateValid)="isValid($event)"
      >
      </app-compose-schedule-input>
    </app-compose-text-input>
    <!----------------------------------------------------->

    <!-- Link Preview -->
    <app-preview-link
      *ngIf="
        !(uiService.uiCollection$ | async)?.showPreview &&
        (iv$ | async) &&
        !(selectedCalendar$ | async)
      "
      #instantArticleComponent
      [instantArticle]="iv$ | async"
      [stylesComponent]="stylesComponent?.getMessageStyle()"
      (cancelNotfiy)="resetSelectedType()"
    >
      <app-compose-attachs-preview
        message-attachs
        [attachCoupon]="attachComponent?.getSelectedCoupon()"
        [attachMenu]="attachComponent?.getSelectedMenu()"
        [attachSticky]="attachComponent?.getStickyInput()"
        (removeAttachCouponNotfiy)="attachComponent?.resetCouponEvent()"
        (removeAttachMenuNotfiy)="attachComponent?.resetMenuEvent()"
        (removeAttachStickyNotfiy)="attachComponent?.resetStickyInput()"
      >
      </app-compose-attachs-preview>

      <app-select-message-style
        message-style
        #stylesComponent
        [messageType]="mType.ARTICLE"
      >
      </app-select-message-style>

      <app-select-message-targets
        message-target
        #targetsComponent
        [selectedTab]="(composeMessageService.tabsContainers$ | async)[0]"
      >
      </app-select-message-targets>

      <app-border-btn
        submit
        (btnClicked)="
          sendLinkPreviewEvent(instantArticleComponent?.getInstantArticle())
        "
        footer-submit
      >
        {{ messageSendingType === attachType?.SCHEDULE ? 'Schedule' : 'Send' }}
      </app-border-btn>

      <app-compose-schedule-input
        *ngIf="messageSendingType === attachType?.SCHEDULE"
        footer-tag
        #scheduleInputComponent
      >
      </app-compose-schedule-input>
    </app-preview-link>
    <!----------------------------------------------------->

    <!-- Calendar Preview -->
    <app-preview-calendar
      *ngIf="
        !(uiService.uiCollection$ | async)?.showPreview &&
          !(iv$ | async) &&
          selectedCalendar$ | async
      "
      #calendarComponent
      [selectedCalendar]="selectedCalendar$ | async"
      (cancelNotfiy)="resetSelectedType()"
    >
      <app-compose-attachs-preview
        message-attachs
        [attachCoupon]="attachComponent?.getSelectedCoupon()"
        [attachMenu]="attachComponent?.getSelectedMenu()"
        [attachSticky]="attachComponent?.getStickyInput()"
        (removeAttachCouponNotfiy)="attachComponent?.resetCouponEvent()"
        (removeAttachMenuNotfiy)="attachComponent?.resetMenuEvent()"
        (removeAttachStickyNotfiy)="attachComponent?.resetStickyInput()"
      >
      </app-compose-attachs-preview>
      <app-select-message-targets
        message-target
        #targetsComponent
        [selectedTab]="(composeMessageService.tabsContainers$ | async)[0]"
      >
      </app-select-message-targets>

      <app-border-btn
        submit
        (btnClicked)="sendCalendarEvent(calendarComponent?.getCalendar())"
        footer-submit
      >
        {{ attachType?.SCHEDULE === messageSendingType ? 'Schedule' : 'Send' }}
      </app-border-btn>

      <app-compose-schedule-input
        *ngIf="messageSendingType === attachType?.SCHEDULE"
        footer-tag
        #scheduleInputComponent
      >
      </app-compose-schedule-input>
    </app-preview-calendar>
    <!----------------------------------------------------->
  </div>
</div>
