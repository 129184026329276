import { RcvdMessage } from 'src/models/MessageParts';
import { IMessage } from 'src/models/IMessage';
import { MainChat } from 'src/models/MainChat';
import { UIState } from 'src/models/UIState';
import { IMyProfile } from 'src/models/IMyProfile';
import {
  RIGHT,
  MessageSendingStatus,
  LEFT,
  MessageTypes,
  API,
  GROUP,
  CHANNEL,
  MessageStatuses
} from 'src/models/constants';

export class MessageMiddleware {
  // Init Rcvd Message
  public static initRcvdMessage(msg: RcvdMessage, loginID: string): IMessage {
    const localMessage: IMessage = {};
    const message = msg.message;

    if (message.chat) {
      if (message.chat.type === GROUP || message.chat.type === CHANNEL) {
        localMessage.group_name = message.chat.title
          ? message.chat.title
          : message.chat.name;
        localMessage.group_id = message.chat.id;
        localMessage.group_type = message.chat.type === 'Group' ? 100 : 101;
      }
    }
    localMessage.seen = msg.seen;
    localMessage.likes = msg.likes;
    localMessage.shares = msg.shares;
    localMessage.views = msg.views;
    localMessage.reply_to_message_id = message.reply_to_message_id;
    localMessage.receiver_id = message.sent_to ? message.sent_to.id : null;
    if (message.eop) {
      localMessage.endOfPage = message.eop;
      localMessage.level = message.lv;
      localMessage.numberOfReplies = message.rc;
      localMessage.isRead = true;
      localMessage.isViewed = true;
      localMessage.isChatViewed = true;
      if (
        localMessage.group_type === 101 &&
        !message.from_admin &&
        localMessage.reply_to_message_id &&
        localMessage.receiver_id
      ) {
        localMessage.from_admin = 1;
      }
    }
    if (message.from_admin) {
      localMessage.from_admin = message.from_admin;
    }
    localMessage.sender_id = message.from.id;
    localMessage.updateStatus = message.status;
    if (
      localMessage.from_admin &&
      localMessage.group_type === 101 &&
      localMessage.reply_to_message_id
    ) {
      localMessage.send_to_user_id = localMessage.receiver_id;
    }
    if (localMessage.sender_id === loginID) {
      localMessage.position = RIGHT;
      localMessage.status = MessageSendingStatus.SENT;
      localMessage.loggedInIsSender = true;
      localMessage.isRead = true;
      localMessage.isViewed = true;
      localMessage.isChatViewed = true;
      if (
        localMessage.from_admin &&
        localMessage.group_type === 101 &&
        localMessage.reply_to_message_id
      ) {
        localMessage.isAdminReply = true;
      }
    } else if (
      localMessage.from_admin &&
      localMessage.group_type === 101 &&
      localMessage.reply_to_message_id
    ) {
      localMessage.isAdminReply = true;
      if (localMessage.sender_id !== loginID) {
        // if I am not admin then I should be the receiver id
        if (localMessage.receiver_id === loginID) {
          localMessage.position = LEFT;
        } else {
          // if I am an admin then this message is from the other admin
          localMessage.position = RIGHT;
        }
      }
    } else {
      localMessage.position = LEFT;
    }
    localMessage.sender_name = message.from.name;
    localMessage.sender_version = message.from.version;
    localMessage.sender_terminal = message.from.terminal; // 'Mobile', 'API'
    localMessage.sender_type = message.from.type;
    localMessage.sender_language_code = message.from.language_code
      ? message.from.language_code
      : 'en'; // Default is “en”  English
    localMessage.message_id = message.message_id;
    localMessage.date = message.date;
    localMessage.reference = message.reference;
    localMessage.type = message.type;
    localMessage.caption = message.caption;
    if (localMessage.group_type === 101 && !localMessage.reply_to_message_id) {
      localMessage.isChannelPost = true;
    }

    /** Switch for message type */
    /**
     * text, photo, video, audio, file, voice, text_file, contact, location, gif, sticker
     */
    switch (message.type) {
      case MessageTypes.ARTICLE:
        localMessage.url = message.url;
        break;
      case MessageTypes.TEXT:
        localMessage.text = message.text;
        break;
      case MessageTypes.TEXT_FILE:
        const textFile = message.text_file;
        localMessage.text = message.text;
        localMessage.document_name = textFile.name;
        localMessage.media_id = textFile.id;
        localMessage.file_size = textFile.size;
        break;
      case MessageTypes.LOCATION:
        const location = message.location;
        localMessage.longitude = location.longitude;
        localMessage.latitude = location.latitude;
        localMessage.location_name = location.name;
        localMessage.location_details = location.details;
        break;
      case MessageTypes.CONTACT:
        const contact = message.contact;
        localMessage.contact_name = contact.name;
        localMessage.phone_number = contact.phone_number;
        localMessage.contact_id = contact.user_id;
        localMessage.file_size = contact.file_size;
        break;
      case MessageTypes.PHOTO:
        const photo = message.photo;
        localMessage.media_id = photo.id;
        localMessage.media_width = photo.width;
        localMessage.media_height = photo.height;
        localMessage.file_size = photo.size;
        const photoThumbnail = photo.thumbnail;
        if (photoThumbnail) {
          localMessage.thumbnail_id = photoThumbnail.id;
          localMessage.thumbnail_width = photoThumbnail.width;
          localMessage.thumbnail_height = photoThumbnail.height;
        }
        break;
      case MessageTypes.STICKER:
        const sticker = message.sticker;
        localMessage.media_id = sticker.id;
        break;
      case MessageTypes.GIF:
        const gif = message.gif;
        localMessage.media_id = gif.id;
        localMessage.media_width = gif.width;
        localMessage.media_height = gif.height;
        localMessage.file_size = gif.size;
        const gifThumbnail = gif.thumbnail;
        if (gifThumbnail) {
          localMessage.thumbnail_id = gifThumbnail.id;
          localMessage.thumbnail_width = gifThumbnail.width;
          localMessage.thumbnail_height = gifThumbnail.height;
        }
        break;
      case MessageTypes.VIDEO:
        const video = message.video;
        localMessage.media_id = video.id;
        localMessage.media_duration = video.duration;
        localMessage.media_width = video.width;
        localMessage.media_height = video.height;
        localMessage.file_size = video.size;
        const thumbnail = video.thumbnail;
        if (thumbnail) {
          localMessage.thumbnail_id = thumbnail.id;
          localMessage.thumbnail_width = thumbnail.width;
          localMessage.thumbnail_height = thumbnail.height;
        }
        break;
      case MessageTypes.AUDIO:
        const audio = message.audio;
        localMessage.media_id = audio.id;
        localMessage.media_duration = audio.duration;
        localMessage.performer = audio.performer;
        localMessage.title = audio.title;
        localMessage.file_size = audio.size;
        break;
      case MessageTypes.VOICE:
        const voice = message.voice;
        localMessage.media_id = voice.id;
        localMessage.media_duration = voice.duration;
        localMessage.file_size = voice.size;
        break;
      case MessageTypes.DOCUMENT:
        const document = message.document;
        localMessage.media_id = document.id;
        localMessage.document_name = document.name;
        localMessage.file_size = document.size;
        break;
      default:
        break;
    }

    /************************************************** */
    // Message Misc
    if (message.schedule_date) {
      localMessage.schedule_date = message.schedule_date;
    }
    if (message.award) {
      localMessage.award = message.award;
    }
    if (message.welcome) {
      localMessage.welcome = message.welcome;
    }
    if (message.tags) {
      localMessage.tags = message.tags;
    }
    if (message.tab) {
      localMessage.tab = message.tab;
    }
    if (typeof message.style === 'number') {
      localMessage.style = message.style;
    }
    if (typeof message.web_page_preview === 'number') {
      localMessage.web_page_preview = message.web_page_preview;
    }
    if (message.bg_color) {
      localMessage.bg_color = message.bg_color;
    }
    if (message.sticky_title) {
      localMessage.sticky_title = message.sticky_title;
    }
    if (message.sticky_desc) {
      localMessage.sticky_desc = message.sticky_desc;
    }
    if (message.c_code) {
      localMessage.c_code = message.c_code;
    }
    if (message.disable_reply) {
      localMessage.disable_reply = message.disable_reply;
    }
    if (message.c_exp) {
      localMessage.c_exp = message.c_exp;
    }
    if (message.menu_ref) {
      localMessage.menu_ref = message.menu_ref;
    }
    // inline_menu for menu attached
    if (message.inline_menu) {
      localMessage.inline_menu = message.inline_menu;
      localMessage.hasMenu = true;
    }
    // Json is for calendar
    if (message.json) {
      localMessage.json = { ...message.json };
    }
    /********************************************************************** */
    // last reply date
    localMessage.lastReplyDate = message.date;

    return Object.assign({}, localMessage);
  }

  // Init Message to be Sent
  public static initMessageToBeSent(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    uistate: UIState,
    loggedInProfile: IMyProfile
  ): IMessage {
    const msg: IMessage = {};

    // Set Default for all send message
    msg.isRead = true;
    msg.isViewed = true;
    msg.isChatViewed = true;
    msg.loggedInIsSender = true;

    if (loggedInProfile) {
      msg.sender_id = loggedInProfile.id;
      msg.sender_name = loggedInProfile.name;
      msg.sender_version = loggedInProfile.version;
      msg.sender_terminal = API;
    }
    // Set Replay send_to_user_id
    if (userId) {
      msg.send_to_user_id = userId;
      msg.isAdminReply = true;
    }

    // Set reply_to_message_id
    if (
      parentMessage &&
      ((mainChat && mainChat.type === GROUP) ||
        (mainChat && mainChat.type === CHANNEL) ||
        !mainChat)
    ) {
      msg.reply_to_message_id = parentMessage.message_id;
    } else if (
      !parentMessage &&
      (mainChat && mainChat.type === CHANNEL) &&
      ((mainChat.isAdmin && userId) || !mainChat.isAdmin)
    ) {
      msg.reply_to_message_id = mainChat.id;
    }

    /** Set receiver_id in case no chat selected */
    if (!mainChat) {
      // All Channels
      if (parentMessage) {
        msg.receiver_id = parentMessage.group_id;
      }
    } else {
      msg.receiver_id = mainChat.id;
    }
    if (
      (mainChat && mainChat.type === GROUP) ||
      (mainChat && mainChat.type === CHANNEL)
    ) {
      msg.group_id = mainChat.id;
      msg.group_name = mainChat.name;
      msg.group_type = mainChat.type === GROUP ? 100 : 101;
    } else if (!mainChat && parentMessage && parentMessage.group_id) {
      // All Channels section
      msg.group_id = parentMessage.group_id;
      msg.group_name = parentMessage.group_name;
      msg.group_type = parentMessage.group_type;
    }

    /** Set If Message is POST */
    if (
      mainChat &&
      mainChat.type === CHANNEL &&
      (mainChat.isAdmin && !parentMessage && !uistate.selectedTalkToAdmin)
    ) {
      msg.isChannelPost = true;
    }
    return msg;
  }

  public static sortMessages(msgs: IMessage[]): IMessage[] {
    return msgs.sort(this.compare);
  }
  public static compare(first: IMessage, second: IMessage) {
    let firstDate = 0;
    let secondDate = 0;
    if (first.isChannelPost) {
      firstDate = first.lastReplyDate ? first.lastReplyDate : first.date;
    } else {
      firstDate = first.date ? first.date : 0;
    }

    if (second.isChannelPost) {
      secondDate = second.lastReplyDate ? second.lastReplyDate : second.date;
    } else {
      secondDate = second.date ? second.date : 0;
    }

    if (firstDate > secondDate) {
      return 1;
    } else if (firstDate < secondDate) {
      return -1;
    }
    return 0;
  }

  public static isMessageExisting(msg: IMessage, msgList: IMessage[]): boolean {
    const existingMessage = msgList.find(
      message =>
        (message.message_id && message.message_id === msg.message_id) ||
        (message.sender_id &&
          message.sender_id === msg.sender_id &&
          message.reference &&
          message.reference === msg.reference) ||
        (!message.sender_id &&
          message.reference &&
          message.reference === msg.reference)
    );
    if (existingMessage) {
      return true;
    } else {
      return false;
    }
  }

  public static updateMessage(state: IMessage[], rcvdMsg: IMessage) {
    return this.sortMessages(
      state.map(msg => {
        if (
          (msg.message_id && msg.message_id === rcvdMsg.message_id) ||
          (!msg.message_id &&
            msg.reference &&
            msg.reference === rcvdMsg.reference)
        ) {
          const newMsg: IMessage = {};
          if (!rcvdMsg.status || rcvdMsg.status !== MessageStatuses.UPDATED) {
            newMsg.date = rcvdMsg.date;
          }
          newMsg.message_id = rcvdMsg.message_id
            ? rcvdMsg.message_id
            : msg.message_id;
          if (rcvdMsg.receiver_id) {
            newMsg.receiver_id = rcvdMsg.receiver_id;
          }
          if (rcvdMsg.sender_id) {
            newMsg.sender_name = rcvdMsg.sender_name;
            newMsg.sender_id = rcvdMsg.sender_id;
            newMsg.sender_terminal = rcvdMsg.sender_terminal;
            newMsg.sender_type = rcvdMsg.sender_type;
            newMsg.sender_version = rcvdMsg.sender_version;
          }
          newMsg.type = msg.type ? msg.type : rcvdMsg.type;

          if (msg.loggedInIsSender) {
            newMsg.position = RIGHT;
            if (
              msg.status !== MessageSendingStatus.DELIVERED &&
              msg.status !== MessageSendingStatus.SEEN
            ) {
              newMsg.status = MessageSendingStatus.SENT;
            }
          }
          return { ...msg, ...newMsg };
        }
        return msg;
      })
    );
  }
}
