export const environment = {
  // same
  CHANNELAPISUFFIX: '/nandbox/business/',
  WSURLAPISUFFIX: '/nandbox/api/',
  DOWNLOADURLAPISUFFIX: '/nandbox/download/',
  UPLOADURLAPISUFFIX: '/nandbox/upload/',
  METAURLAPISUFFIX: '/nandbox/metadata/?url=',
  CANCELPAYSUFFIX: '/cancel/',
  // diff
  env_name: 'staging',
  sentry_dns: 'https://2037eff118ae48c8b99c3a68e31f87d1@sentry.io/1858550',
  production: false,
  serviceworker: true,
  encodeSocket: false,
  sentry: true,
  hotjar: 1707311,
  inspectlet: '',
  formilla: '',
  signup_link: '/signup',
  terms_link: 'https://nandbox.com/terms',
  privacy_link: 'https://nandbox.com/terms',
  MAP_API_KEY: 'AIzaSyDYC0eyYdnbU9rgIKqT35Bb237WBhPkDZY',
  MAINWEB: 'https://nandbox.com',
  SHARE_LINK: 'https://s1.nandbox.ca/',
  PAGE_LINK: 'https://iv.nandbox.com',
  PAY_LINK: 'http://pay1.nandbox.com',
  YOUTUBE_API_CHANNEL_LINK: 'https://godev.nandbox.com/ytc',
  YOUTUBE_API_PLAYLIST_LINK: 'https://godev.nandbox.com/ytp',
  MAP_AUTOCOMPLETE_API_LINK: 'https://godev.nandbox.com/places',
  MAP_PLACE_API_LINK: 'https://godev.nandbox.com/place',
  COUNTRY_DATA_API_LINK: 'https://godev.nandbox.com/country-data',
  PLANS_DATA_API_LINK: 'https://godev.nandbox.com/plans',
  SMTP_API_LINK: 'https://godev.nandbox.com/validate-smtp',
  AVR_COLOR_LINK: 'https://godev.nandbox.com/average-color',
  googleAnalyticsKey: 'UA-144808415-2',
  // googleAnalyticsKeySecondKey: 'G-ZSY0XB2W88',
  BASEURL: 'd1.nandbox.net:5080',
  PROJECT_NAME: 'MERCHANT',
  WEB_VERSION: '10.7S',
  LICENSES: [
    'dashboard',
    'listevent',
    'editevent',
    'listmenu',
    'editmenu',
    'listcampaign',
    'editcampaign',
    'listcoupon',
    'editcoupon',
    'listbooking',
    'editbooking',
    'listmember',
    'editmember',
    'listadmin',
    'editadmin',
    'listtag',
    'edittag',
    'listchatgroup',
    'editchatgroup',
    'listvirtualapp',
    'editvirtualapp',
    'listsettings',
    'editsettings',
    'listapp',
    'editapp',
    'listappchannel',
    'editappchannel',
    'listmessage',
    'createmessage',
    'deletemessage',
    'editmessage',
    'replymessage',
    'sendphoto',
    'sendvideo',
    'sendaudio',
    'sendgif',
    'senddoc',
    'sendarticle',
    'sendcoupon',
    'sendmenu',
    'sendsticky',
    'listbilling',
    'editbilling',
    'listbulk',
    'editbulk',
    'listbot',
    'editbot',
    'listtemplate',
    'edittemplate',
    'listcalendar',
    'editcalendar',
    'listtransaction',
    'edittransaction',
    'listproductaddons',
    'editproductaddons',
    'listpackage',
    'editpackage',
    'listbundle',
    'editbundle',
    'listproduct',
    'editproduct',
    'listcustomercare',
    'editcustomercare',
    'listmstore',
    'editmstore',
    'listmarker',
    'editmarker',
    'listmychannel',
    'editmychannel',
    'listblacklist',
    'editblacklist',
    'listmapservices',
    'editmapservice',
    'listroutes',
    'editroute',
    'listtripnotifiers',
    'edittripnotifiers',
    'listtrips',
    'edittrips',
    'listtripshistory',
    'listwhitelist',
    'editwhitelist'
  ]
};
