import {
  Component,
  ViewChild,
  ElementRef,
  EventEmitter,
  ChangeDetectionStrategy,
  Output,
  Input,
  OnInit
} from '@angular/core';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { FileService } from 'src/app/core/file.service';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFilesComponent implements OnInit {
  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;
  @ViewChild('videoInputFile', { static: false }) videoInputFile: ElementRef;
  @ViewChild('gifInputFile', { static: false }) gifInputFile: ElementRef;
  @ViewChild('audioInputFile', { static: false }) audioInputFile: ElementRef;
  @ViewChild('documentInputFile', { static: false })
  documentInputFile: ElementRef;
  @Input() isBotScreen = false;
  @Output() filesSelectedNotify = new EventEmitter<ISelectedFiles>();

  className = 'layer fa fa-plus';
  isMenuOpen = false;

  constructor(private _fileService: FileService) {}
  ngOnInit(): void {
    this.className = 'layer fa fa-plus';
  }

  toggleMenuEvent(value: boolean): void {
    this.isMenuOpen = value;
    this.onMenuOpen();
  }

  filesSelectedEvent() {
    this.filesSelectedNotify.emit(this.getFile());
  }

  resetEvent() {
    if (this.imageInputFile) {
      this.imageInputFile.nativeElement.value = '';
    }
    if (this.gifInputFile) {
      this.gifInputFile.nativeElement.value = '';
    }
    if (this.videoInputFile) {
      this.videoInputFile.nativeElement.value = '';
    }
    if (this.audioInputFile) {
      this.audioInputFile.nativeElement.value = '';
    }
    if (this.documentInputFile) {
      this.documentInputFile.nativeElement.vale = '';
    }
  }

  getFile(): ISelectedFiles {
    if (this.videoInputFile.nativeElement.files[0]) {
      const file = this.videoInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.imageInputFile.nativeElement.files[0]) {
      const file = this.imageInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.gifInputFile.nativeElement.files[0]) {
      const file = this.gifInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.audioInputFile.nativeElement.files[0]) {
      const file = this.audioInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.documentInputFile.nativeElement.files[0]) {
      const file = this.documentInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    }
  }

  onMenuOpen() {
    if (this.className !== 'layer fa fa-times') {
      this.className = 'layer fa fa-times';
    } else {
      this.className = 'layer fa fa-plus';
    }
  }
}
