import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InViewportModule } from 'ng-in-viewport';
import { NgxMasonryModule } from 'ngx-masonry';

import { NgxModule } from 'src/app/ngx.module';
import { CellsModule } from './../cells/cells.module';
import { SharedModule } from '../shared/shared.module';

import { ChannelPostsComponent } from './channel-posts/channel-posts.component';
import { MemberRepliesComponent } from './member-replies/member-replies.component';
import { ChannelRepliesComponent } from './channel-reply/channel-replies.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ReplyMessagesComponent } from './reply-messages/reply-messages.component';
import { MenuFormModule } from '../+merchant/+dashboard/menus/menu-form/menu.form.module';

@NgModule({
  imports: [
    CommonModule,
    InViewportModule,
    NgxMasonryModule,
    NgxModule,
    SharedModule,
    CellsModule,
    MenuFormModule
  ],
  declarations: [
    ChannelRepliesComponent,
    MemberRepliesComponent,
    ChannelPostsComponent,
    ChatMessagesComponent,
    ReplyMessagesComponent
  ],
  exports: [
    ChannelRepliesComponent,
    MemberRepliesComponent,
    ChannelPostsComponent,
    ChatMessagesComponent,
    ReplyMessagesComponent
  ]
})
export class ContainersModule {}
