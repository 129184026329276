/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assign-tags.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./assign-tags.component";
var styles_AssignTagsComponent = [i0.styles];
var RenderType_AssignTagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssignTagsComponent, data: {} });
export { RenderType_AssignTagsComponent as RenderType_AssignTagsComponent };
function View_AssignTagsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tags-container-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tags"]))], null, null); }
function View_AssignTagsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tag-slot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "padding: 15px 10px 15px 0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeTagEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-trash"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 2, 0, currVal_0); }); }
function View_AssignTagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tags-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignTagsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignTagsComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLabel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.assignTags; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AssignTagsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tags-container-empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no tags for this user"]))], null, null); }
export function View_AssignTagsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignTagsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignTagsComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.assignTags == null) ? null : _co.assignTags.length) > 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.assignTags == null) ? null : _co.assignTags.length) === 0); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AssignTagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-assign-tags", [], null, null, null, View_AssignTagsComponent_0, RenderType_AssignTagsComponent)), i1.ɵdid(1, 49152, null, 0, i3.AssignTagsComponent, [], null, null)], null, null); }
var AssignTagsComponentNgFactory = i1.ɵccf("app-assign-tags", i3.AssignTagsComponent, View_AssignTagsComponent_Host_0, { assignTags: "assignTags", showLabel: "showLabel" }, { removeTagNotfiy: "removeTagNotfiy" }, []);
export { AssignTagsComponentNgFactory as AssignTagsComponentNgFactory };
