<!-- chat Box -->
<div class="chat" *ngIf="!isScreenBot">
  <div class="chat-box">
    <div
      #dropdownEmoji
      class="btn-group align-end clickable-cursor"
      dropdown
      [autoClose]="true"
      [dropup]="true"
      [isOpen]="isEmojiKeyboardOpen"
      (isOpenChange)="toggleEmojiKeyboard($event)"
    >
      <div dropdownToggle type="button">
        <i
          class="smile-emoji far fa-smile"
          [class.hide]="isRecord"
          [class.open]="isEmojiKeyboardOpen"
          aria-hidden="true"
        ></i>
      </div>
      <ul
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu emoji-box"
        role="menu"
      >
        <app-emoji-keyboard
          (emoijNotify)="emoijNotify($event)"
        ></app-emoji-keyboard>
      </ul>
    </div>
    <div
      class="edit wrap-text copyable-item text-cursor"
      [class.hide]="isRecord"
      data-text="Type a message..."
      contenteditable
      #textBox
    ></div>
    <app-upload-files
      class="align-end clickable-cursor"
      [class.hide]="isRecord"
      (filesSelectedNotify)="sendMedia($event)"
    ></app-upload-files>
    <app-record-voice-note
      class="align-end clickable-cursor"
      [selectedChat]="selectedChat"
      (recordVoice)="startRecordEvent($event)"
      (sendVoiceNotify)="sendVoiceNote($event)"
      (micNotFoundNotify)="micNotFoundEvent()"
    ></app-record-voice-note>
  </div>
</div>
<div class="chat-bot" *ngIf="isScreenBot">
  <div class="chat-bot-box">
    <div
      #dropdownEmoji
      class="btn-group align-end clickable-cursor"
      dropdown
      [autoClose]="true"
      [dropup]="true"
      [isOpen]="isEmojiKeyboardOpen"
      (isOpenChange)="toggleEmojiKeyboard($event)"
    >
      <div dropdownToggle type="button">
        <i
          class="smile-emoji far fa-smile"
          [class.hide]="isRecord"
          [class.open]="isEmojiKeyboardOpen"
          aria-hidden="true"
        ></i>
      </div>
      <ul
        *dropdownMenu
        appCancelEvent
        class="dropdown-menu emoji-box"
        role="menu"
      >
        <app-emoji-keyboard
          (emoijNotify)="emoijNotify($event)"
        ></app-emoji-keyboard>
      </ul>
    </div>
    <div
      class="edit wrap-text copyable-item text-cursor"
      [class.hide]="isRecord"
      data-text="Type a message..."
      contenteditable
      #textBox
    ></div>
    <app-upload-files
      class="align-end clickable-cursor"
      [class.hide]="isRecord"
      [isBotScreen]="true"
      (filesSelectedNotify)="sendMedia($event)"
    ></app-upload-files>
    <app-record-voice-note
      class="align-end clickable-cursor"
      [selectedChat]="selectedChat"
      [isBotScreen]="true"
      (recordVoice)="startRecordEvent($event)"
      (sendVoiceNotify)="sendVoiceNote($event)"
      (micNotFoundNotify)="micNotFoundEvent()"
    ></app-record-voice-note>
  </div>
  <button class="send-btn" (click)="sendMessage()">Send</button>
</div>
