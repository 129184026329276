import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as SubChannelUIActions from './subChannelsUI.actions';

import { IChat } from 'src/models/IChat';
import { AppState } from 'src/models/AppState';
import { ISelectedFiles } from 'src/models/ISelectedFiles';

@Injectable({
  providedIn: 'root'
})
export class SubChannelUiDispatchers {
  constructor(private _store: Store<AppState>) {}

  listSubChannelUI() {
    this._store.dispatch(new SubChannelUIActions.ListSubChannelUI());
  }
  createSubChannelUI() {
    this._store.dispatch(new SubChannelUIActions.CreateSubChannelUI());
  }
  viewSubChannelUI(selectedSubChannel: IChat) {
    this._store.dispatch(
      new SubChannelUIActions.ViewSubChannelUI(selectedSubChannel)
    );
  }
  editSubChannelUI(selectedSubChannel: IChat) {
    this._store.dispatch(
      new SubChannelUIActions.EditSubChannelUI(selectedSubChannel)
    );
  }

  uploadSubChannelImage(selectedFile) {
    this._store.dispatch(
      new SubChannelUIActions.UploadSubChannelImage(selectedFile)
    );
  }
  subChannelPhotoUploadUI(createdSubChannel: IChat) {
    this._store.dispatch(
      new SubChannelUIActions.SubChannelPhotoUploadUI(createdSubChannel)
    );
  }
  subChannelCreationComplete() {
    this._store.dispatch(new SubChannelUIActions.SubChannelCreationComplete());
  }

  closeViewSubChannelUI() {
    this._store.dispatch(new SubChannelUIActions.CloseViewSubChannelUI());
  }
  closeEditSubChannelUI() {
    this._store.dispatch(new SubChannelUIActions.CloseEditSubChannelUI());
  }

  // paging dispatchers
  getNextSubChannelPage() {
    this._store.dispatch(new SubChannelUIActions.GetNextSubChannelPage());
  }

  getPrevSubChannelPage() {
    this._store.dispatch(new SubChannelUIActions.GetPrevSubChannelPage());
  }

  receivedSubChannelHistoryChunk(
    eop: number,
    sop: number,
    currentPage: IChat[]
  ): void {
    this._store.dispatch(
      new SubChannelUIActions.ReceivedSubChannelHistoryChunk(
        eop,
        sop,
        currentPage
      )
    );
  }

  resetSubChannelPaging() {
    this._store.dispatch(new SubChannelUIActions.ResetSubChannelPaging());
  }

  setStatusAsIdle() {
    this._store.dispatch(new SubChannelUIActions.SetStatusAsIdle());
  }
}
