import { Store } from '@ngrx/store';
import * as MembersUIActions from './membersUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MembersUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    // paging dispatchers
    getNextMembersPage() {
        this._store.dispatch(new MembersUIActions.GetNextMembersPage());
    }
    getPrevMembersPage() {
        this._store.dispatch(new MembersUIActions.GetPrevMembersPage());
    }
    receivedMembersHistoryChunk(eop, sop, currentPage, hash) {
        this._store.dispatch(new MembersUIActions.ReceivedMembersHistoryChunk(eop, sop, currentPage, hash));
    }
    resetMembersPaging(name, searchBy, status, view, accountTypes) {
        this._store.dispatch(new MembersUIActions.ResetMembersPaging(name, searchBy, status, view, accountTypes));
    }
    setStatusAsIdle() {
        this._store.dispatch(new MembersUIActions.SetStatusAsIdle());
    }
    deleteUser(userID, chatID, ref) {
        this._store.dispatch(new MembersUIActions.DeleteUser(userID, chatID, ref));
    }
    banUser(userID, chatID) {
        this._store.dispatch(new MembersUIActions.BanUser(userID, chatID));
    }
    unbanUser(userID, chatID) {
        this._store.dispatch(new MembersUIActions.UnBanUser(userID, chatID));
    }
    getMyMembers() {
        this._store.dispatch(new MembersUIActions.GetMyMembers());
    }
}
MembersUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MembersUiDispatchers_Factory() { return new MembersUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: MembersUiDispatchers, providedIn: "root" });
