/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-uploader-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../media-center-photo/media-center-photo.component.ngfactory";
import * as i3 from "../media-center-photo/media-center-photo.component";
import * as i4 from "../media-center.service";
import * as i5 from "../media-center-video/media-center-video.component.ngfactory";
import * as i6 from "../media-center-video/media-center-video.component";
import * as i7 from "../media-center-audio/media-center-audio.component.ngfactory";
import * as i8 from "../media-center-audio/media-center-audio.component";
import * as i9 from "../media-center-file/media-center-file.component.ngfactory";
import * as i10 from "../media-center-file/media-center-file.component";
import * as i11 from "ngx-masonry";
import * as i12 from "@angular/common";
import * as i13 from "../../../../node_modules/ngx-masonry/ngx-masonry.ngfactory";
import * as i14 from "../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i15 from "../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i16 from "./media-center-uploader-list.component";
var styles_MediaCenterUploaderListComponent = [i0.styles];
var RenderType_MediaCenterUploaderListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterUploaderListComponent, data: {} });
export { RenderType_MediaCenterUploaderListComponent as RenderType_MediaCenterUploaderListComponent };
function View_MediaCenterUploaderListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "delete-btn"], ["src", "./assets/img/deletholiday.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterDelete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_MediaCenterUploaderListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-photo", [], null, null, null, i2.View_MediaCenterPhotoComponent_0, i2.RenderType_MediaCenterPhotoComponent)), i1.ɵdid(1, 49152, null, 0, i3.MediaCenterPhotoComponent, [i4.MediaCenterService], { url: [0, "url"], localMedia: [1, "localMedia"], localThumbnail: [2, "localThumbnail"], progress: [3, "progress"], mediaStatus: [4, "mediaStatus"], fileSize: [5, "fileSize"], height: [6, "height"], width: [7, "width"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.localThumbnail); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.loadingProgress); var currVal_4 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.mediaStatus); var currVal_5 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.size); var currVal_6 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.height); var currVal_7 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.width); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MediaCenterUploaderListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-video", [], null, null, null, i5.View_MediaCenterVideoComponent_0, i5.RenderType_MediaCenterVideoComponent)), i1.ɵdid(1, 49152, null, 0, i6.MediaCenterVideoComponent, [i4.MediaCenterService], { url: [0, "url"], localMedia: [1, "localMedia"], localThumbnail: [2, "localThumbnail"], progress: [3, "progress"], mediaStatus: [4, "mediaStatus"], fileSize: [5, "fileSize"], height: [6, "height"], width: [7, "width"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.localThumbnail); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.loadingProgress); var currVal_4 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.mediaStatus); var currVal_5 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.size); var currVal_6 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.height); var currVal_7 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.width); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_MediaCenterUploaderListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-audio", [], null, null, null, i7.View_MediaCenterAudioComponent_0, i7.RenderType_MediaCenterAudioComponent)), i1.ɵdid(1, 49152, null, 0, i8.MediaCenterAudioComponent, [i4.MediaCenterService], { url: [0, "url"], localMedia: [1, "localMedia"], progress: [2, "progress"], mediaStatus: [3, "mediaStatus"], fileSize: [4, "fileSize"], fileName: [5, "fileName"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.loadingProgress); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.mediaStatus); var currVal_4 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.size); var currVal_5 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.name); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_MediaCenterUploaderListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-file", [], null, null, null, i9.View_MediaCenterFileComponent_0, i9.RenderType_MediaCenterFileComponent)), i1.ɵdid(1, 114688, null, 0, i10.MediaCenterFileComponent, [i4.MediaCenterService], { url: [0, "url"], fileName: [1, "fileName"], localMedia: [2, "localMedia"], progress: [3, "progress"], mediaStatus: [4, "mediaStatus"], fileSize: [5, "fileSize"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.name); var currVal_2 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.url); var currVal_3 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.loadingProgress); var currVal_4 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.mediaStatus); var currVal_5 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.size); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_MediaCenterUploaderListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ngxMasonryItem", [["class", "media-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i11.NgxMasonryDirective, [i1.ElementRef, i11.NgxMasonryComponent, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_4)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_5)), i1.ɵdid(5, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_6)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_7)), i1.ɵdid(9, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_8)), i1.ɵdid(11, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.filename); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((((_v.context.$implicit == null) ? null : _v.context.$implicit.type) === _co.messageTypes.PHOTO) || (((_v.context.$implicit == null) ? null : _v.context.$implicit.type) === _co.messageTypes.GIF)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.type) === _co.messageTypes.VIDEO); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.type) === _co.messageTypes.AUDIO); _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.type) === _co.messageTypes.DOCUMENT); _ck(_v, 11, 0, currVal_4); }, null); }
function View_MediaCenterUploaderListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "media-list-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-masonry", [], null, null, null, i13.View_NgxMasonryComponent_0, i13.RenderType_NgxMasonryComponent)), i1.ɵdid(3, 770048, null, 0, i11.NgxMasonryComponent, [i1.PLATFORM_ID, i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MediaCenterUploaderListComponent_3)), i1.ɵdid(5, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _v.parent.context.ngIf; var currVal_1 = _co.TrackByFunction; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_MediaCenterUploaderListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterUploaderListComponent_2)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.ngIf == null) ? null : _v.context.ngIf.length) > 0); var currVal_1 = i1.ɵnov(_v.parent, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MediaCenterUploaderListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i14.View_EmptyItemFrameComponent_0, i14.RenderType_EmptyItemFrameComponent)), i1.ɵdid(1, 49152, null, 0, i15.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mediaCenterFet == null) ? null : _co.mediaCenterFet.symbolImage); var currVal_1 = ((_co.mediaCenterFet == null) ? null : _co.mediaCenterFet.emptyTitle); var currVal_2 = ((_co.mediaCenterFet == null) ? null : _co.mediaCenterFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MediaCenterUploaderListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "media-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MediaCenterUploaderListComponent_1)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["isEmpty", 2]], null, 0, null, View_MediaCenterUploaderListComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.mediaCenterService.getMediaCenterItems$(_co.selectedMediaType))); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MediaCenterUploaderListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-uploader-list", [], null, null, null, View_MediaCenterUploaderListComponent_0, RenderType_MediaCenterUploaderListComponent)), i1.ɵdid(1, 49152, null, 0, i16.MediaCenterUploaderListComponent, [i4.MediaCenterService], null, null)], null, null); }
var MediaCenterUploaderListComponentNgFactory = i1.ɵccf("app-media-center-uploader-list", i16.MediaCenterUploaderListComponent, View_MediaCenterUploaderListComponent_Host_0, { selectedMediaType: "selectedMediaType" }, { mediaCenterNotify: "mediaCenterNotify" }, []);
export { MediaCenterUploaderListComponentNgFactory as MediaCenterUploaderListComponentNgFactory };
