import * as tslib_1 from "tslib";
import { HttpEventType } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { ChatMiddleware } from './chat.middleware';
import { ProfileMiddleware } from '../profile/profile.middleware';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { FileService } from 'src/app/core/file.service';
import { HelperService } from 'src/app/core/helper.service';
import { StorageService } from 'src/app/core/storage.service';
import * as MessagesActions from '../messages/message.actions';
import * as AuthActions from './../auth/auth.actions';
import { ChatDispatchers } from './chat.dispatchers';
import { AuthDispatchers } from '../auth/auth.dispatchers';
import { ProfileDispatchers } from '../profile/profile.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';
import * as ChatMethods from 'src/models/IChat';
import * as MainChatMethods from 'src/models/MainChat';
import * as ProfileMethods from 'src/models/IProfile';
import { GetBookingPeriods } from 'src/models/BookingPeriods';
import { GROUP, CHANNEL, NO_FILE_NAME, NOT_SUPPORTED_FILE_TYPE, MessageTypes, FILE_SIZE_EXCEEDED, ERROR_NOT_IMAGE, GROUP_UPLOAD_TYPE, BOOKING_CHANNEL } from 'src/models/constants';
import { createEventMethod, SetChat, createGroupMethod, createChannelMethod, EnableServiceMethod, createVirtualAppMethod } from 'src/models/IChat';
import { MessageDispatchers } from '../messages/message.dispatchers';
import * as isEqual from 'lodash.isequal';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { GetChatAdministrators, PayCash } from 'src/models/IChatMember';
export class ChatEffects {
    constructor(actions$, _chatDispatcher, _socketGateway, _storageService, _authDispatcher, _profileDispatcher, _uiDispatchers, _mainChatDispatchers, _messageDispatchers, _helperService, _uploadGateway, _fileService, _store, _uiDispatches) {
        this.actions$ = actions$;
        this._chatDispatcher = _chatDispatcher;
        this._socketGateway = _socketGateway;
        this._storageService = _storageService;
        this._authDispatcher = _authDispatcher;
        this._profileDispatcher = _profileDispatcher;
        this._uiDispatchers = _uiDispatchers;
        this._mainChatDispatchers = _mainChatDispatchers;
        this._messageDispatchers = _messageDispatchers;
        this._helperService = _helperService;
        this._uploadGateway = _uploadGateway;
        this._fileService = _fileService;
        this._store = _store;
        this._uiDispatches = _uiDispatches;
        this.authOk = this.actions$.pipe(ofType(AuthActions.RECIVE_AUTH_OK), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chats]) => {
            const action = val;
            if (action.payload.chatId) {
                if (!chats.find(chat => chat.id === action.payload.chatId)) {
                    const selectedChat = {};
                    selectedChat.id = action.payload.chatId;
                    selectedChat.admin = true; // This effect takes place only in merchant web where the logged in is always the admin
                    this._chatDispatcher.newChatReceived(selectedChat);
                }
                const newChat = {};
                newChat.id = action.payload.chatId;
                this.getChatsDetails([newChat], chats);
            }
        }));
        this.requestChatDetails = this.actions$.pipe(ofType("REQUEST_CHAT_DETAILS" /* REQUEST_CHAT_DETAILS */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chats]) => {
            const action = val;
            const newChat = {};
            newChat.id = action.chatID;
            this.getChatsDetails([newChat], chats);
        }));
        this.chatCounters = this.actions$.pipe(ofType("GET_CHAT_COUNTERS" /* GET_CHAT_COUNTERS */), map((action) => {
            this.getChatCounters(action.chatId, action.message_ids);
        }));
        this.chatHistory = this.actions$.pipe(ofType("REQUEST_CHAT_HISTORY" /* REQUEST_CHAT_HISTORY */), map((action) => {
            this.getChatHistory(action.payload);
        }));
        this.settingChatToCache = this.actions$.pipe(ofType("CHAT_DETAILS_RECEIVED" /* CHAT_DETAILS_RECEIVED */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatsState]) => {
            const action = val;
            const rcvdChat = chatsState.find(chat => chat.id === action.payload.id);
            if (rcvdChat && (rcvdChat.type === GROUP || rcvdChat.type === CHANNEL)) {
                const mainChat = ChatMiddleware.getMainChatFromChat(rcvdChat);
                this._mainChatDispatchers.mainChatReceived(mainChat);
            }
            if (rcvdChat && rcvdChat.welcome_message) {
                this._messageDispatchers.initReceivedMessage(rcvdChat.welcome_message);
            }
            this._storageService.setChatToCache(action.payload.id, action.payload);
        }));
        this.memberCountRcvd = this.actions$.pipe(ofType("CHAT_MEMBER_COUNT_RECEIVED" /* CHAT_MEMBER_COUNT_RECEIVED */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatsState]) => {
            const action = val;
            const selectedChat = chatsState.find(chat => chat.id === action.payload.id);
            if (selectedChat) {
                this._mainChatDispatchers.mainChatMemberCountReceived(action.payload.id, action.payload.member_count);
            }
            if (selectedChat &&
                (selectedChat.version &&
                    selectedChat.version !== action.payload.version)) {
                this.getChatInfo(action.payload.id);
            }
        }));
        this.loggedInLeftChat = this.actions$.pipe(ofType("LOGGED_IN_LEFT_CHAT" /* LOGGED_IN_LEFT_CHAT */), map((action) => {
            this._mainChatDispatchers.loggedInLeftMainChat(action.payload.chat_id);
            // should dispatch action in mainchat
        }));
        this.detailsRcvdFromCache = this.actions$.pipe(ofType("CHAT_DETAILS_FROM_CACHE" /* CHAT_DETAILS_FROM_CACHE */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatsState]) => {
            const action = val;
            const rcvdChat = chatsState.find(chat => chat.id === action.payload.id);
            if (rcvdChat && (rcvdChat.type === GROUP || rcvdChat.type === CHANNEL)) {
                this._mainChatDispatchers.mainChatReceived(ChatMiddleware.getMainChatFromChat(rcvdChat));
            }
            if (rcvdChat && rcvdChat.welcome_message) {
                this._messageDispatchers.initReceivedMessage(rcvdChat.welcome_message);
            }
        }));
        this.chatDeselected = this.actions$.pipe(ofType("DESELECT_CHAT" /* DESELECT_CHAT */), map((action) => {
            this._mainChatDispatchers.mainChatDeselected(action.id, action.lastTypedMessage);
        }));
        this.chatSelected = this.actions$.pipe(ofType("CHAT_SELECTED" /* CHAT_SELECTED */), map((action) => {
            this.getChatMemberCount(action.payload);
        }));
        this.updateChat = this.actions$.pipe(ofType("UPDATE_CHAT" /* UPDATE_CHAT */), map((action) => {
            this._socketGateway.sendSocketMessage(new SetChat(action.chatDetails, action.reference));
        }));
        this.chatHistoryRequested = this.actions$.pipe(ofType("REQUEST_CHAT_HISTORY" /* REQUEST_CHAT_HISTORY */), map((action) => {
            this._mainChatDispatchers.mainChatHistoryRequested(action.payload);
        }));
        this.chatListRcvd = this.actions$.pipe(ofType("CHAT_LIST_RECEIVED" /* CHAT_LIST_RECEIVED */), map((action) => action), withLatestFrom(this._store
            .select(state => state.uiReducer.hasNoContacts)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(res => {
            if (res[0].payload) {
                const groupsList = res[0].payload.filter(chat => chat.type === GROUP);
                if (groupsList) {
                    this._uiDispatchers.setGroupsCount(groupsList.length);
                    this.getChatsDetails(groupsList, res[2]);
                }
                // To fire the details received event in case the user has no contacts and no groups
                if ((!groupsList || groupsList.length === 0) && res[1]) {
                    this._uiDispatchers.detailsReceived();
                }
                const channelsList = res[0].payload.filter(chat => chat.type === CHANNEL);
                if (channelsList) {
                    this.getChatsDetails(channelsList, res[2]);
                }
            }
        }));
        this.subChatListRcvd = this.actions$.pipe(ofType("SUB_CHAT_LIST_RECEIVED" /* SUB_CHAT_LIST_RECEIVED */), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatsState]) => {
            const action = val;
            if (action.payload) {
                this.getChatsDetails(action.payload, chatsState);
                if (action.listType && action.listType === BOOKING_CHANNEL) {
                    action.payload.forEach(bookingChannel => {
                        this._socketGateway.sendSocketMessage(new GetBookingPeriods(bookingChannel.id));
                        // 2019-03-18
                        const currentDate = new Date();
                        const currentDay = currentDate.getDate();
                        const currentMonthNum = currentDate.getMonth() + 1;
                        const currentMonth = currentMonthNum < 10
                            ? '0' + currentMonthNum
                            : '' + currentMonthNum;
                        const currentYear = currentDate.getFullYear();
                        const startDateString = currentYear + '-' + currentMonth + '-' + currentDay;
                        this._socketGateway.sendSocketMessage(new ChatMethods.GetBookingExceptions(bookingChannel.id, startDateString));
                    });
                }
            }
        }));
        this.messageReceived = this.actions$.pipe(ofType(MessagesActions.RECEIVE_MESSAGE), withLatestFrom(this._store
            .select(state => state.chatReducer)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.profileReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, chatsState, profilesState]) => {
            const action = val;
            if (action.payload.group_id) {
                if (this._chatDispatcher.isNewChat(action.payload.group_id, chatsState)) {
                    const mainChat = {};
                    mainChat.id = action.payload.group_id;
                    mainChat.name = action.payload.group_name;
                    mainChat.type = action.payload.group_type === 100 ? GROUP : CHANNEL;
                    const lastMessage = this._helperService.handleDifferentReplyTypes(action.payload);
                    const newChat = {};
                    newChat.id = action.payload.group_id;
                    this._chatDispatcher.newChatReceived(newChat);
                    this._mainChatDispatchers.mainChatReceived(ChatMiddleware.initNewMainChat(lastMessage, mainChat));
                    this.getChatInfo(action.payload.group_id);
                }
                if (!action.payload.isChannelPost &&
                    action.payload.sender_id !== this._authDispatcher.getLoginID()) {
                    if (ProfileMiddleware.isNewProfile(action.payload.sender_id, profilesState)) {
                        // That means a member in group and he is not a contact to logged in user
                        this._profileDispatcher.unknownProfile(action.payload.sender_id);
                        this.getProfileDetails(action.payload.sender_id);
                    }
                    else if (!action.payload.endOfPage) {
                        const profileWithDifferentVersion = profilesState.find(prof => prof.user_id === action.payload.sender_id &&
                            prof.version &&
                            action.payload.sender_version &&
                            action.payload.sender_version.indexOf(prof.version) === -1);
                        if (profileWithDifferentVersion) {
                            this.getProfileDetails(action.payload.sender_id);
                        }
                    }
                }
                if (action.payload) {
                    if (ProfileMiddleware.isNewProfile(action.payload.receiver_id, profilesState)) {
                        this._profileDispatcher.unknownProfile(action.payload.receiver_id);
                        this.getProfileDetails(action.payload.receiver_id);
                    }
                    if (ProfileMiddleware.isNewProfile(action.payload.sender_id, profilesState)) {
                        this._profileDispatcher.unknownProfile(action.payload.sender_id);
                        this.getProfileDetails(action.payload.sender_id);
                    }
                }
            }
        }));
        this.profileInvalidated = this.actions$.pipe(ofType("CHAT_INVALIDATED" /* CHAT_INVALIDATED */), map((action) => {
            this.getChatInfo(action.payload);
        }));
        this.createEvent = this.actions$.pipe(ofType("CREATE_EVENT" /* CREATE_EVENT */), map((action) => {
            this._socketGateway.sendSocketMessage(createEventMethod(action.eventDetails));
        }));
        this.getBookingPeriods = this.actions$.pipe(ofType("GET_BOOKING_PERIODS" /* GET_BOOKING_PERIODS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetBookingPeriods(action.chatID));
        }));
        this.createBooking = this.actions$.pipe(ofType("CREATE_BOOKING" /* CREATE_BOOKING */), map((action) => {
            this._socketGateway.sendSocketMessage(ChatMethods.createBookingChannelMethod(action.bookingDetails));
        }));
        this.createGroup = this.actions$.pipe(ofType("CREATE_GROUP" /* CREATE_GROUP */), map((action) => {
            this._socketGateway.sendSocketMessage(createGroupMethod(action.groupDetails));
        }));
        this.deleteChat = this.actions$.pipe(ofType("DELETE_CHAT" /* DELETE_CHAT */), map((action) => {
            this._socketGateway.sendSocketMessage(new ChatMethods.DeleteChat(action.chat_id));
        }));
        this.chatDelete = this.actions$.pipe(ofType("CHAT_DELETED" /* CHAT_DELETED */), map((action) => {
            console.log(`${action.chat_id} deleted successfully`);
        }));
        this.chatDeleteError = this.actions$.pipe(ofType("CHAT_DELETED_ERROR" /* CHAT_DELETED_ERROR */), map((action) => {
            this._uiDispatchers.showPopup(SERVER_ERROR_MESSAGES[action.errorNumber]);
        }));
        this.enableService = this.actions$.pipe(ofType("ENABLE_SERVICE" /* ENABLE_SERVICE */), map((action) => {
            this._socketGateway.sendSocketMessage(new EnableServiceMethod(action.chat_id, action.period, action.period_number, action.price, action.currency));
        }));
        this.createChannel = this.actions$.pipe(ofType("CREATE_CHANNEL" /* CREATE_CHANNEL */), map((action) => {
            this._socketGateway.sendSocketMessage(createChannelMethod(action.channelDetails));
        }));
        this.createVirtualApp = this.actions$.pipe(ofType("CREATE_VIRTUAL_APP" /* CREATE_VIRTUAL_APP */), map((action) => {
            this._socketGateway.sendSocketMessage(createVirtualAppMethod(action.virtualAppDetails));
        }));
        this.uploadChatImage = this.actions$.pipe(ofType("UPLOAD_CHAT_PHOTO" /* UPLOAD_CHAT_PHOTO */), map((action) => {
            if (this.validateFile(action.selectedFile, 'image')) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.localFile)
                    .then(fileAsArrayBuffer => {
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.localFile.type, action.selectedFile.localFile.name, false, GROUP_UPLOAD_TYPE, null, action.chatID, true)
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            const chat = {};
                            const photo = { id: res.file };
                            chat.id = action.chatID;
                            chat.photo = photo;
                            this._socketGateway.sendSocketMessage(new SetChat(chat));
                        }
                    }, error => {
                        console.log('Failed to upload event image');
                    });
                });
            }
        }));
        this.rolesReceived = this.actions$.pipe(ofType("ROLES_DELIVERED" /* ROLES_DELIVERED */), withLatestFrom(this._store
            .select(s => s.uiReducer.selectedChat)
            .pipe(distinctUntilChanged(isEqual))), map(([val, selectedChat]) => {
            const action = val;
            const localChat = {
                id: selectedChat.id,
                chatRoles: action.payload
            };
            this._chatDispatcher.rolesReceived(localChat);
        }));
        this.buySubscriptionOnBehalfOfUser = this.actions$.pipe(ofType("BUY_SUBSCRIPTION_ON_BEHALF_OF_USER" /* BUY_SUBSCRIPTION_ON_BEHALF_OF_USER */), map(val => {
            const action = val;
            this._socketGateway.sendSocketMessage(new PayCash(action.member_id, null, '1', 1, action.currency, 'PAY_USER', action.chat_id));
        }));
        this.getChatAdministrators = this.actions$.pipe(ofType("GET_CHAT_ADMINISTRATORS" /* GET_CHAT_ADMINISTRATORS */), map((action) => {
            this._socketGateway.sendSocketMessage(new GetChatAdministrators(action.chatID));
        }));
    }
    getChatsDetails(chats, currentChatsState) {
        chats.forEach(chat => {
            const targetChat = currentChatsState.find(group => group.id === chat.id);
            if (!(targetChat &&
                targetChat.detailsReceived &&
                ((chat.version && targetChat.version === chat.version) ||
                    !chat.version))) {
                this._storageService
                    .getChatFromCache(chat.id)
                    .then(cachedChat => {
                    if (cachedChat &&
                        ((chat.version && cachedChat.version === chat.version) ||
                            !chat.version)) {
                        this._chatDispatcher.chatDetailsFromCache(cachedChat);
                    }
                    else {
                        this.getChatInfo(chat.id);
                    }
                })
                    .catch(err => {
                    this.getChatInfo(chat.id);
                });
            }
        });
    }
    getChatInfo(chatID) {
        this._socketGateway.sendSocketMessage(new ChatMethods.GetChatTemplate(chatID));
    }
    getChatMemberCount(chatID) {
        this._socketGateway.sendSocketMessage(new MainChatMethods.GetChatMemberCount(chatID));
    }
    getChatCounters(chatID, messageIDs) {
        this._socketGateway.sendSocketMessage(new ChatMethods.GetChatCounters(chatID, messageIDs));
    }
    getChatHistory(chatID) {
        this._socketGateway.sendSocketMessage(new ChatMethods.GetChatHistory(chatID));
    }
    getProfileDetails(userID) {
        if (userID) {
            this._socketGateway.sendSocketMessage(new ProfileMethods.GetUser(userID));
        }
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "authOk", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "requestChatDetails", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatCounters", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatHistory", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "settingChatToCache", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "memberCountRcvd", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "loggedInLeftChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "detailsRcvdFromCache", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatDeselected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatSelected", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "updateChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatHistoryRequested", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatListRcvd", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "subChatListRcvd", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "messageReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "profileInvalidated", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "createEvent", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "getBookingPeriods", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "createBooking", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "createGroup", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "deleteChat", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatDelete", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "chatDeleteError", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "enableService", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "createChannel", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "createVirtualApp", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "uploadChatImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "rolesReceived", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "buySubscriptionOnBehalfOfUser", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], ChatEffects.prototype, "getChatAdministrators", void 0);
