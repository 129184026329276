import { MyPage, MyPageForm } from 'src/models/MyPageConfig';
import {
  uuidv4,
  EMPTY_STRING,
  PAGE_LINK,
  PageFormContainerTypes
} from 'src/models/constants';

export const initRcvdPage = (
  isInstancePage: boolean,
  rcvdPage: MyPage,
  reference?: string
): MyPage => {
  const localPage: MyPage = rcvdPage;

  if (!isInstancePage) {
    if (!localPage.url || localPage.url === EMPTY_STRING) {
      localPage.url = `${PAGE_LINK}/${rcvdPage.groupId}/${rcvdPage.id}`;
    }
  }
  if (localPage.content) {
    localPage.content.id = rcvdPage.id;
    if (reference) {
      localPage.reference = reference;
      localPage.content.ref = reference;
    } else {
      localPage.content.ref = localPage.content.ref
        ? localPage.content.ref
        : rcvdPage.reference;
    }
    if (localPage.content.container && localPage.content.container.length > 0) {
      localPage.content.container.forEach((cont, index) => {
        cont.id = cont.id ? cont.id : uuidv4();
        cont.order = index;
        if (cont.component && cont.component.length > 0) {
          cont.component.forEach((comp, i) => {
            comp.id = comp.id ? comp.id : uuidv4();
            comp.order = i;
          });
        }
      });
    }
  }
  localPage.needUpdate = false;
  return localPage;
};

export const removeEmptyPageContainers = (myPage: MyPage): MyPage => {
  const updatedPage = { ...myPage };
  if (myPage.content && myPage.content.container) {
    const updatedContainers = myPage.content.container.filter(cont => {
      return (
        ((cont.container_type === PageFormContainerTypes.GALLERY ||
          cont.container_type === PageFormContainerTypes.LIST ||
          cont.container_type === PageFormContainerTypes.SLIDER) &&
          (cont.component && cont.component.length > 0)) ||
        (cont.container_type === PageFormContainerTypes.TEXT &&
          cont.body &&
          cont.body.length > 0) ||
        ((cont.container_type === PageFormContainerTypes.youTubeList ||
          cont.container_type === PageFormContainerTypes.VIDEO) &&
          cont.url &&
          cont.url.length > 0) ||
        (cont.container_type === PageFormContainerTypes.LOCATION &&
          cont.address &&
          cont.address.length > 0)
      );
    });
    const updatedPageForm = { ...myPage.content };
    updatedPageForm.container = updatedContainers;
    updatedPage.content = updatedPageForm;
    return updatedPage;
  }
  return myPage;
};
