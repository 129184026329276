export enum PrivilegesName {
  dashboard = 'dashboard',
  listevent = 'listevent',
  editevent = 'editevent',
  listmenu = 'listmenu',
  editmenu = 'editmenu',
  listcampaign = 'listcampaign',
  editcampaign = 'editcampaign',
  listcoupon = 'listcoupon',
  editcoupon = 'editcoupon',
  listbooking = 'listbooking',
  editbooking = 'editbooking',
  listcalendar = 'listcalendar',
  editcalendar = 'editcalendar',
  listmember = 'listmember',
  editmember = 'editmember',
  listadmin = 'listadmin',
  editadmin = 'editadmin',
  listtag = 'listtag',
  edittag = 'edittag',
  listchatgroup = 'listchatgroup',
  editchatgroup = 'editchatgroup',
  listvirtualapp = 'listvirtualapp',
  editvirtualapp = 'editvirtualapp',
  listsettings = 'listsettings',
  editsettings = 'editsettings',
  listapp = 'listapp',
  editapp = 'editapp',
  listappchannel = 'listappchannel',
  editappchannel = 'editappchannel',
  listmessage = 'listmessage',
  createmessage = 'createmessage',
  deletemessage = 'deletemessage',
  editmessage = 'editmessage',
  replymessage = 'replymessage',
  sendphoto = 'sendphoto',
  sendvideo = 'sendvideo',
  sendaudio = 'sendaudio',
  sendgif = 'sendgif',
  senddoc = 'senddoc',
  sendarticle = 'sendarticle',
  sendcoupon = 'sendcoupon',
  sendmenu = 'sendmenu',
  sendsticky = 'sendsticky',
  listbilling = 'listbilling',
  uploadTemps = 'uploadapptemplate',
  editbilling = 'editbilling',
  listbulk = 'listbulk',
  editbulk = 'editbulk',
  listbot = 'listbot',
  editbot = 'editbot',
  listcustomercare = 'listcustomercare',
  editcustomercare = 'editcustomercare',
  listtransaction = 'listtransaction',
  edittransaction = 'edittransaction',
  listbundle = 'listbundle',
  editbundle = 'editbundle',
  listproduct = 'listproduct',
  editproduct = 'editproduct',
  listmychannel = 'listmychannel',
  editmychannel = 'editmychannel',
  listmstore = 'listmstore',
  editmstore = 'editmstore',
  listproductaddons = 'listproductaddons',
  editproductaddons = 'editproductaddons',
  listpackage = 'listpackage',
  editpackage = 'editpackage',
  listtemplate = 'listtemplate',
  edittemplate = 'edittemplate',
  listAppChannelData = 'listappchanneldata',
  editAppChannelData = 'editappchanneldata',
  listmarker = 'listmarker',
  editmarker = 'editmarker',
  listblacklist = 'listblacklist',
  editblacklist = 'editblacklist',
  listmapservices = 'listmapservices',
  editmapservice = 'editmapservice',
  listroutes = 'listroutes',
  editroute = 'editroute',
  listtripnotifiers = 'listtripnotifiers',
  edittripnotifiers = 'edittripnotifiers',
  listtrips = 'listtrips',
  edittrips = 'edittrips',
  listtripshistory = 'listtripshistory',
  listwhitelist = 'listwhitelist',
  editwhitelist = 'editwhitelist'
}
