import { Store } from '@ngrx/store';
import * as MessageActions from './message.actions';
import { MessageActionTypes } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class MessageDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    getLastPostsHistory() {
        this._store.dispatch(new MessageActions.GetLastPostsHistory());
    }
    getLastRepliesHistory() {
        this._store.dispatch(new MessageActions.GetLastRepliesHistory());
    }
    listScheduleMessages() {
        this._store.dispatch(new MessageActions.ListScheduleMessages());
    }
    listAwardMessages() {
        this._store.dispatch(new MessageActions.ListAwardMessages());
    }
    listTags() {
        this._store.dispatch(new MessageActions.ListTags());
    }
    initReceivedMessage(payload) {
        this._store.dispatch(new MessageActions.InitReceivedMessage(payload));
    }
    receiveMessage(payload) {
        switch (payload.actionType) {
            case MessageActionTypes.NORMAL:
                if (!payload.existedBefore || payload.status === 'PENDING') {
                    // This case where new message received
                    this._store.dispatch(new MessageActions.ReceiveMessage(payload));
                }
                else {
                    // update exiting message with message_id if not having one and then in the effects download thumbnail if needed
                    this.updateExistingMessage(payload);
                }
                break;
            case MessageActionTypes.UPDATED:
                this.messageEdited(payload);
                break;
            case MessageActionTypes.DELETED:
                this.messageRecalled(payload);
                break;
        }
    }
    updateExistingMessage(payload) {
        this._store.dispatch(new MessageActions.UpdateExistingMessage(payload));
    }
    messageEdited(payload) {
        this._store.dispatch(new MessageActions.MessageEdited(payload));
    }
    sendingMessage(payload) {
        this._store.dispatch(new MessageActions.SendingMessage(payload));
    }
    sendingScheduleMessage(payload) {
        this._store.dispatch(new MessageActions.SendingScheduleMessage(payload));
    }
    sendingAwardMessage(payload) {
        this._store.dispatch(new MessageActions.SendingAwardMessage(payload));
    }
    sendingWelcomeMessage(payload) {
        this._store.dispatch(new MessageActions.SendingWelcomeMessage(payload));
    }
    messageSent(payload) {
        this._store.dispatch(new MessageActions.MessageSent(payload));
    }
    messageDelivered(payload) {
        this._store.dispatch(new MessageActions.MessageDelivered(payload));
    }
    messageSeen(message_id) {
        this._store.dispatch(new MessageActions.MessageSeen(message_id));
    }
    messagesSeenReceived(message_ids) {
        this._store.dispatch(new MessageActions.MessagesSeenReceived(message_ids));
    }
    resetNumberOfUnreadReplies(messageID) {
        this._store.dispatch(new MessageActions.ResetNumberOfUnreadReplies(messageID));
    }
    setMessageLocalMedia(msg) {
        this._store.dispatch(new MessageActions.SetMessageLocalMedia(msg));
    }
    setMessageLocalThumbnail(msg) {
        this._store.dispatch(new MessageActions.SetMessageLocalThumbnail(msg));
    }
    downloadMediaMessage(msg) {
        this._store.dispatch(new MessageActions.DownloadMediaMessage(msg));
    }
    downloadMessageThumbnail(msg) {
        this._store.dispatch(new MessageActions.DownloadMessageThumbnail(msg));
    }
    cancelDownloadMediaMessage(msg) {
        this._store.dispatch(new MessageActions.CancelDownloadMediaMessage(msg));
    }
    likeMessage(msg) {
        this._store.dispatch(new MessageActions.LikeMessage(msg));
    }
    unlikeMessage(msg) {
        this._store.dispatch(new MessageActions.UnlikeMessage(msg));
    }
    likeAckReceived(likeAck) {
        this._store.dispatch(new MessageActions.LikeAckReceived(likeAck));
    }
    shareMessage(msg) {
        this._store.dispatch(new MessageActions.ShareMessage(msg));
    }
    recallMessage(msg) {
        this._store.dispatch(new MessageActions.RecallMessage(msg));
    }
    setMessageMediaStatus(msg) {
        this._store.dispatch(new MessageActions.SetMessageMediaStatus(msg));
    }
    setMessageThumbnailStatus(msg) {
        this._store.dispatch(new MessageActions.SetMessageThumbnailStatus(msg));
    }
    messageIsViewed(msg) {
        this._store.dispatch(new MessageActions.MessageIsViewed(msg));
    }
    messageRecalled(message) {
        this._store.dispatch(new MessageActions.MessageRecalled(message));
    }
    chatCountersReceived(msgs) {
        this._store.dispatch(new MessageActions.ChatCountersReceived(msgs));
    }
    setLinkPreviewStatus(msg) {
        this._store.dispatch(new MessageActions.SetLinkPreviewStatus(msg));
    }
    linkPreviewDetailsReceived(msg) {
        this._store.dispatch(new MessageActions.LinkPreviewDetailsReceived(msg));
    }
    cancelUpload(msg) {
        this._store.dispatch(new MessageActions.CancelUpload(msg));
    }
    updateLoadingProgress(msg, progress) {
        this._store.dispatch(new MessageActions.UpdateLoadingProgress(msg, progress));
    }
    chatMessagesOutOfViewPort(groupID) {
        this._store.dispatch(new MessageActions.ChatMessagesOutOfViewPort(groupID));
    }
    contactMessagesOutOfViewPort(id) {
        this._store.dispatch(new MessageActions.ContactMessagesOutOfViewPort(id));
    }
    readChatMessages(mainChat) {
        this._store.dispatch(new MessageActions.ReadChatMessages(mainChat));
    }
    chatDeselected(mainChat) {
        this._store.dispatch(new MessageActions.ChatDeselected(mainChat));
    }
    historyEffect(message) {
        this._store.dispatch(new MessageActions.HistoryEffect(message));
    }
    deselectChannel() {
        this._store.dispatch(new MessageActions.DeselectChannel());
    }
    getBlobUrl(selectedFiles) {
        this._store.dispatch(new MessageActions.GetBlobUrl(selectedFiles));
    }
    cancelMediaOperation(message) {
        this._store.dispatch(new MessageActions.CancelMediaOperation(message));
    }
    retryMediaOperation(message) {
        this._store.dispatch(new MessageActions.RetryMediaOperation(message));
    }
    previewLinkMessage(message) {
        this._store.dispatch(new MessageActions.PreviewLinkMessage(message));
    }
    /** Schedule message dispatchers */
    receiveAwardMessage(payload) {
        this._store.dispatch(new MessageActions.ReceiveAwardMessage(payload));
    }
    receiveWelcomeMessage(payload) {
        this._store.dispatch(new MessageActions.ReceiveWelcomeMessage(payload));
    }
    receiveScheduleMessage(payload) {
        this._store.dispatch(new MessageActions.ReceiveScheduleMessage(payload));
    }
    receiveScheduleMessageCanceled(payload) {
        this._store.dispatch(new MessageActions.ReceiveScheduleMessageCanceled(payload));
    }
    /***************************************************************************************** */
    sendMessageNow(msg) {
        this._store.dispatch(new MessageActions.SendMessageNow(msg));
    }
    cancelScheduleMessage(messageId) {
        this._store.dispatch(new MessageActions.CancelScheduleMessage(messageId));
    }
    sendTextMessage(mainChat, parentMessage, userId, text, misc) {
        this._store.dispatch(new MessageActions.SendTextMessage(mainChat, parentMessage, userId, text, misc));
    }
    sendInstantUrlMessage(mainChat, parentMessage, userId, istantArticle, misc) {
        this._store.dispatch(new MessageActions.SendInstantUrlMessage(mainChat, parentMessage, userId, istantArticle, misc));
    }
    sendMediaMessage(mainChat, parentMessage, userId, file, mediaType, previewMetadata, blobUrl, misc) {
        this._store.dispatch(new MessageActions.SendMediaMessage(mainChat, parentMessage, userId, file, mediaType, previewMetadata, blobUrl, misc));
    }
    sendCalendarMessage(mainChat, parentMessage, userId, calendar, misc) {
        this._store.dispatch(new MessageActions.SendCalendarMessage(mainChat, parentMessage, userId, calendar, misc));
    }
    /***********************************************************************/
    /** TAG */
    sendingCreateTag(tag) {
        this._store.dispatch(new MessageActions.SendingCreateTag(tag));
    }
    sendingDeleteTag(tag) {
        this._store.dispatch(new MessageActions.SendingDeleteTag(tag));
    }
    sendingMemberTags(user_id, tags) {
        this._store.dispatch(new MessageActions.SendingSetMemberTags({ user_id, tags }));
    }
    /***************************************************************************/
    /** stats */
    getMessageMenuStats(message_id) {
        this._store.dispatch(new MessageActions.GetMessageMenuStats(message_id));
    }
    setMessageMenuStats(message_id, menuStats) {
        this._store.dispatch(new MessageActions.SetMessageMenuStats({ message_id, menuStats }));
    }
    getInlineMessageCallBack(buttonData) {
        this._store.dispatch(new MessageActions.GetInlineMessageCallBack(buttonData));
    }
}
MessageDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageDispatchers_Factory() { return new MessageDispatchers(i0.ɵɵinject(i1.Store)); }, token: MessageDispatchers, providedIn: "root" });
