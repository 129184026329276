<app-form-frame style="width: 60em;">
  <ng-container ngProjectAs="title">Create Location</ng-container>
  <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="mapMarkerEdit?.id" class="form-group">
      <div class="form-inline">
        <div class="control-label md">ID:</div>
        <div class="form-group lg-input">
          {{ mapMarkerEdit?.id }}
        </div>
      </div>
    </div>

    <div class="form-inline">
      <app-reactive-media-input
        [filedLabel]="'Image'"
        [fieldId]="'locationImage'"
        [isInline]="true"
        [control]="photo"
        [displyControl]="photoUrl"
      >
      </app-reactive-media-input>
      <label class="control-label space-around">
        Icon
      </label>
      <app-selector-input-icon
        class="icon-droplist"
        [iconList]="'markers'"
        [iconName]="iconName"
        (updateItemIconNotify)="updateItemIconEvent($event)"
      >
      </app-selector-input-icon>
    </div>

    <app-reactive-text-input
      [control]="title"
      [isInline]="true"
      [fieldId]="'inputLocationTitle'"
      [filedLabel]="'Title'"
    >
    </app-reactive-text-input>

    <app-reactive-textarea-input
      [fieldId]="'inputLocationDescription'"
      [filedLabel]="'Description'"
      [isInline]="true"
      [control]="snippet"
    >
    </app-reactive-textarea-input>
    <app-reactive-select-input
      [isInline]="true"
      [fieldId]="'typeID'"
      [filedLabel]="'Type'"
      [control]="type"
      [keyOptions]="options"
    >
    </app-reactive-select-input>

    <app-selector-input-location
      [isInline]="true"
      [fieldId]="'locationInput'"
      [filedLabel]="'Location'"
      [locationControl]="address"
      [latControl]="lat"
      [longControl]="lon"
    >
    </app-selector-input-location>

    <app-reactive-text-input
      [isInline]="true"
      [control]="url"
      [fieldId]="'locationUrl'"
      [filedLabel]="'Web Url'"
    >
    </app-reactive-text-input>

    <div class="form-group">
      <div class="form-submit">
        <app-border-btn submit [type]="'submit'">
          <ng-container *ngIf="mapMarkerEdit?.id">Edit</ng-container>
          <ng-container *ngIf="!mapMarkerEdit?.id">Create</ng-container>
        </app-border-btn>
        <app-border-btn *ngIf="showCancel" cancel (btnClicked)="cancelEvent()">
          Cancel
        </app-border-btn>
      </div>
    </div>
  </form>
</app-form-frame>
