import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AuthService } from 'src/app/core/auth.service';
import { LayoutService } from '../layout.service';

import { MainChat } from 'src/models/MainChat';
import { UISections } from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { UiService } from 'src/app/core/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { PaymentProvidersDispatchers } from 'src/app/store/paymentProviders/paymentProviders.dispatchers';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent implements OnInit, OnDestroy {
  uiSections: typeof UISections = UISections;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  @Input() selectedChatId: string;

  @Output() searchNotify = new EventEmitter<string>();
  @Output() toggleSidenavNotify = new EventEmitter<boolean>();
  @Output() sectionNotify = new EventEmitter<string>();
  @Output() talkToAdminNotify = new EventEmitter<string>();
  @Output() logoutNotify = new EventEmitter<boolean>();
  targetChat: MainChat;
  searchTerm = new FormControl();
  serchTerms$: Observable<string> = this.searchTerm.valueChanges;

  serchTermsSubscription: Subscription = null;

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    public uiService: UiService,
    public translate: TranslateService,
    public router: Router,
    public _chatDispatchers: ChatDispatchers,
    private _paymentProvidersDispatchers: PaymentProvidersDispatchers
  ) {}

  ngOnInit() {
    // console.log('in nav bar', this.selectedChatId);
    if (this.selectedChatId) {
      this.uiService.getChatByID$(this.selectedChatId).subscribe(res => {
        this.targetChat = res;
      });
    }
    this.serchTermsSubscription = this.serchTerms$
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => this.searchEvent(searchTerm));
  }

  ngOnDestroy() {
    if (this.serchTermsSubscription) {
      this.serchTermsSubscription.unsubscribe();
    }
  }

  toggleSidenavEvent() {
    this.toggleSidenavNotify.emit(true);
  }

  searchEvent(searchTerm: string): void {
    this.searchNotify.emit(searchTerm);
  }
  sectionEvent(direction: string): void {
    this.sectionNotify.emit(direction);
  }
  selectChatEvent(chat: MainChat) {
    this.layoutService.deselectChannel();
    this.authService.selectChat(chat.id);

    // const chatEdit: MainChat = {};
    // chatEdit.id = chat.id;
    // this.uiService.selectChat(chatEdit);
  }
  selectTalkToAdmin() {
    // this.uiService.messageViewed(this.targetChat);
    this.talkToAdminNotify.emit(this.selectedChatId);
  }
  logoutEvent() {
    this.logoutNotify.emit(true);
  }
  switchLanguage(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }
  TrackByFunction(index, chat: MainChat) {
    return chat.id;
  }
}
