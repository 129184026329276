<div class="attachs" dropdown #dropdown="bs-dropdown">
  <div
    dropdownToggle
    class="attach-btn clickable-cursor"
    aria-controls="dropdown-basic"
    (mouseover)="toggleHoverAttach(true)"
    (mouseleave)="toggleHoverAttach(false)"
  >
    <span class="inline-btn">
      <img src="./assets/img/attachment-clip.svg" alt="" /> <span>Attach</span>
    </span>
    <app-hover-tooltip
      *ngIf="hoverAttach"
      [top]="0"
      [right]="110"
      [text]="
        'Make your Post more engaging by adding to it a coupon, a sticky note, or polls'
      "
      [width]="'135px'"
    ></app-hover-tooltip>
  </div>
  <ul
    *dropdownMenu
    id="dropdown-basic"
    class="dropdown-menu"
    role="menu"
    aria-labelledby="button-basic"
  >
    <li
      *appShowIfHasPrivilege="privilegesName.listcoupon"
      role="menuitem"
      class="menu-item clickable-cursor"
      (click)="toggleCouponsPool(true)"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-coupons"></use>
      </svg>

      <span>Coupon</span>
    </li>

    <li
      role="menuitem"
      class="menu-item clickable-cursor"
      (click)="toggleStickyInput(true)"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-sticky"></use>
      </svg>
      <span>Sticky Post</span>
    </li>
    <li
      *appShowIfHasPrivilege="privilegesName.listmenu"
      role="menuitem"
      class="menu-item clickable-cursor"
      (click)="toggleMenusPool(true)"
    >
      <svg class="svg-icon icon-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-menus"></use>
      </svg>
      <span>Menu</span>
    </li>
  </ul>
</div>

<app-modal-container
  *ngIf="localState?.showCouponsPool"
  (cancelNotfiy)="toggleCouponsPool(false)"
>
  <app-coupons-pool
    #couponsPool
    (selectCouponNotfiy)="setCouponEvent($event)"
    (cancelNotfiy)="toggleCouponsPool(false)"
  >
  </app-coupons-pool>
</app-modal-container>

<app-modal-container
  *ngIf="localState?.showMenusPool"
  (cancelNotfiy)="toggleMenusPool(false)"
>
  <app-menus-pool
    #menusPool
    (selectMenuNotfiy)="setMenuEvent($event)"
    (cancelNotfiy)="toggleMenusPool(false)"
  >
  </app-menus-pool>
</app-modal-container>

<app-modal-container
  *ngIf="localState?.showStickyInput"
  (cancelNotfiy)="toggleStickyInput(false)"
>
  <app-compose-sticky-input
    (stickyInputNotify)="setStickyInput($event)"
    (cancelNotfiy)="toggleStickyInput(false)"
  >
  </app-compose-sticky-input>
</app-modal-container>
