import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  EventEmitter,
  ElementRef,
  Output,
  Input
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { FileService } from 'src/app/core/file.service';
import { InstantArticle } from 'src/models/InstantArticle';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { MessageTypes } from 'src/models/constants';
import { CalendarTimetable } from 'src/models/Calendar';
import { PrivilegesName } from 'src/models/privileges';

@Component({
  selector: 'app-select-message-type',
  templateUrl: './select-message-type.component.html',
  styleUrls: ['./select-message-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMessageTypeComponent {
  @ViewChild('gifInputFile', { static: false }) gifInputFile: ElementRef;
  @ViewChild('audioInputFile', { static: false }) audioInputFile: ElementRef;
  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;
  @ViewChild('videoInputFile', { static: false }) videoInputFile: ElementRef;
  @ViewChild('documentInputFile', { static: false })
  documentInputFile: ElementRef;

  @Input() messageType: string;
  @Input() messageLinkPreview: InstantArticle;
  @Input() messageMediaPreview: boolean;

  @Output() textNotifiy = new EventEmitter<boolean>();
  @Output() fileSelectedNotifiy = new EventEmitter<ISelectedFiles>();
  @Output() urlSelectedNotifiy = new EventEmitter<number>();
  @Output() calendarSelectedNotifiy = new EventEmitter<any>();

  private _isInstant = new BehaviorSubject<boolean>(false);
  public isInstant$ = this._isInstant.asObservable();
  privilegesName: typeof PrivilegesName = PrivilegesName;

  mType = MessageTypes;

  showComposeUrl = false;
  showComposeCalendar = false;

  hoverTooltip = false;
  top: number;
  right: number;
  text: string;
  hoverText: boolean;
  hoverPhoto: boolean;
  hoverVideo: boolean;
  hoverGif: boolean;
  hoverAudio: boolean;
  hoverDocument: boolean;
  hoverArticle: boolean;
  hoverCalendar: boolean;
  hoverContact: boolean;

  constructor(private _fileService: FileService) {}

  setText() {
    this.textNotifiy.emit(true);
  }

  getCircleStyle(type: string) {
    if (type === this.messageType && !this.messageLinkPreview) {
      return { 'background-color': '#775578' };
    }
    return { 'background-color': '#5476BD' };
  }

  getCircleTextUrlStyle() {
    if (this.messageLinkPreview && !this.messageLinkPreview.isInstant) {
      return { 'background-color': '#775578' };
    }
    return { 'background-color': '#5476BD' };
  }

  getCircleInstantUrlStyle() {
    if (this.messageLinkPreview && this.messageLinkPreview.isInstant) {
      return { 'background-color': '#775578' };
    }
    return { 'background-color': '#5476BD' };
  }

  onFilesSelected() {
    const event: ISelectedFiles = this.getFile();
    if (event.localFile) {
      this.fileSelectedNotifiy.emit(event);
      this.resetEvent();
    }
  }

  getFile(): ISelectedFiles {
    if (this.videoInputFile.nativeElement.files[0]) {
      const file = this.videoInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.imageInputFile.nativeElement.files[0]) {
      const file = this.imageInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.gifInputFile.nativeElement.files[0]) {
      const file = this.gifInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.audioInputFile.nativeElement.files[0]) {
      const file = this.audioInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    } else if (this.documentInputFile.nativeElement.files[0]) {
      const file = this.documentInputFile.nativeElement.files[0];
      // if (file.name.includes('.rar')) {
      //   return {
      //     localFile: file,
      //     type: 'document'
      //   };
      // }
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    }
  }

  resetEvent() {
    if (this.imageInputFile) {
      this.imageInputFile.nativeElement.value = '';
    }
    if (this.gifInputFile) {
      this.gifInputFile.nativeElement.value = '';
    }
    if (this.videoInputFile) {
      this.videoInputFile.nativeElement.value = '';
    }
    if (this.audioInputFile) {
      this.audioInputFile.nativeElement.value = '';
    }
    if (this.documentInputFile) {
      this.documentInputFile.nativeElement.vale = '';
    }
  }

  showComposeUrlEvent(e: boolean, isInstant?: boolean) {
    this.showComposeUrl = e;
    this._isInstant.next(isInstant);
  }
  showComposeCalendarEvent(e: boolean) {
    this.showComposeCalendar = e;
  }
  urlSelectedEvent(ref: number) {
    this.urlSelectedNotifiy.emit(ref);
    this.showComposeUrlEvent(false);
  }
  calendarSelectedEvent(calendar: CalendarTimetable) {
    this.calendarSelectedNotifiy.emit(calendar);
    this.showComposeCalendarEvent(false);
  }
  toggleTooltip(e, top?, right?, type?) {
    switch (type) {
      case this.mType.TEXT:
        this.hoverText = e;
        this.top = top;
        this.right = right;
        this.text = 'Write Text';
        break;
      case this.mType.PHOTO:
        this.hoverPhoto = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload Image';
        break;
      case this.mType.VIDEO:
        this.hoverVideo = e;
        this.top = top;
        this.right = right;
        this.text = 'Add Video';
        break;
      case this.mType.GIF:
        this.hoverGif = e;
        this.top = top;
        this.right = right;
        this.text = 'Add Animated Image (GIF)';
        break;
      case this.mType.AUDIO:
        this.hoverAudio = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload Audio';
        break;
      case this.mType.DOCUMENT:
        this.hoverDocument = e;
        this.top = top;
        this.right = right;
        this.text = 'Upload File';
        break;
      case this.mType.ARTICLE:
        this.hoverArticle = e;
        this.top = top;
        this.right = right;
        this.text = 'Attach an Article URL';
        break;
      case this.mType.CONTACT:
        this.hoverContact = e;
        this.top = top;
        this.right = right;
        this.text = 'Link a website';
        break;
      case this.mType.CALENDAR:
        this.hoverCalendar = e;
        this.top = top;
        this.right = right;
        this.text = 'Attach Calendar';
        break;
    }
  }
}
