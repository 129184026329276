import * as MessageActions from './message.actions';
import { MessageMiddleware } from './message.middleware';

import { IMessage } from 'src/models/IMessage';
import {
  RIGHT,
  INDIVIDUAL,
  SHARE_LINK,
  MessageTypes,
  MessageSendingStatus,
  MessageStatuses
} from 'src/models/constants';

export function messageReducer(
  state: IMessage[] = [],
  action: MessageActions.MessagesActions
): IMessage[] {
  switch (action.type) {
    case MessageActions.RECEIVE_MESSAGE:
      const rcvdMsg = action.payload;
      let updatedState = state;
      // if message has a parent then I should increase the number of replies in the parent side
      if (rcvdMsg.reply_to_message_id) {
        updatedState = state.map(message => {
          if (message.message_id === rcvdMsg.reply_to_message_id) {
            const updatedParentMessage: IMessage = {};
            if (message.lastReplyDate < rcvdMsg.date) {
              updatedParentMessage.lastReplyDate = rcvdMsg.date;
            }
            if (!rcvdMsg.endOfPage) {
              updatedParentMessage.numberOfReplies = message.numberOfReplies
                ? ++message.numberOfReplies
                : 1;
              if (!rcvdMsg.isViewed) {
                updatedParentMessage.numberOfUnreadReplies = message.numberOfUnreadReplies
                  ? ++message.numberOfUnreadReplies
                  : 1;
              }
            } else {
              updatedParentMessage.rcvdHistoryReply = true;
            }
            return { ...message, ...updatedParentMessage };
          }
          return message;
        });
      }
      if (updatedState.length > 0) {
        return MessageMiddleware.sortMessages([rcvdMsg, ...updatedState]);
      } else {
        return [rcvdMsg];
      }
    case MessageActions.SENDING_MESSAGE:
      if (!MessageMiddleware.isMessageExisting(action.payload, state)) {
        const msgToBeSent = action.payload;
        let newState = state;
        // if message has a parent then I should increase the number of replies in the parent side
        if (msgToBeSent.reply_to_message_id) {
          newState = state.map(message => {
            if (message.message_id === msgToBeSent.reply_to_message_id) {
              const updatedParentMessage: IMessage = {};
              updatedParentMessage.numberOfReplies = message.numberOfReplies
                ? ++message.numberOfReplies
                : 1;
              return { ...message, ...updatedParentMessage };
            }
            return message;
          });
        }
        msgToBeSent.status = MessageSendingStatus.PENDING;
        msgToBeSent.position = RIGHT;
        msgToBeSent.date = msgToBeSent.reference;
        msgToBeSent.lastReplyDate = msgToBeSent.reference;
        return state.length > 0 ? [...newState, msgToBeSent] : [msgToBeSent];
      } else {
        return state.map(message => {
          if (
            (message.message_id &&
              message.message_id === action.payload.message_id) ||
            (message.reference &&
              message.reference === action.payload.reference)
          ) {
            let newMsg: IMessage = {};
            newMsg = action.payload;
            newMsg.lastReplyDate = action.payload.reference;
            return { ...message, ...newMsg };
          }
          return message;
        });
      }
    case MessageActions.MESSAGE_SENT: {
      if (
        !action.payload.status ||
        action.payload.status !== MessageStatuses.DELETED
      ) {
        return MessageMiddleware.updateMessage(state, action.payload);
      } else {
        return state;
      }
    }
    case MessageActions.UPDATE_EXISTING_MESSAGE:
      return state.map(message => {
        if (message.message_id === action.payload.message_id) {
          const newMsg: IMessage = {};
          if (action.payload.endOfPage) {
            newMsg.endOfPage = action.payload.endOfPage;
            if (action.payload.numberOfReplies) {
              newMsg.numberOfReplies = action.payload.numberOfReplies;
            }
          }
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.MESSAGE_EDITED:
      return state.map(message => {
        if (message.message_id === action.payload.message_id) {
          const newMsg: IMessage = {};
          if (action.payload.type === MessageTypes.TEXT) {
            newMsg.text = action.payload.text;
            if (
              action.payload.inline_menu &&
              action.payload.inline_menu.length > 0
            ) {
              newMsg.inline_menu = action.payload.inline_menu;
            }
          } else {
            newMsg.caption = action.payload.caption;
          }
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.MESSAGE_DELIVERED:
      return state.map(message => {
        if (message.message_id === action.payload.message_id) {
          const newMsg: IMessage = {};
          newMsg.position = RIGHT;
          if (message.status !== MessageSendingStatus.SEEN) {
            newMsg.status = MessageSendingStatus.DELIVERED;
          }
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.MESSAGE_SEEN:
      return state.map(message => {
        if (message.message_id === action.message_id) {
          const newMsg: IMessage = {};
          newMsg.position = RIGHT;
          newMsg.status = MessageSendingStatus.SEEN;
          return { ...message, ...newMsg };
        }
        return message;
      });

    // Add link for message even if not shared
    case MessageActions.SHARE_MESSAGE:
      return state.map(msg => {
        if (msg.message_id === action.payload.message_id) {
          const newMsg: IMessage = {};
          newMsg.link = SHARE_LINK + action.payload.message_id;
          return { ...msg, ...newMsg };
        }
        return msg;
      });
    case MessageActions.CHAT_COUNTERS_RECEIVED:
      return state.map(message => {
        for (let i = 0; i < action.msgs.length; i++) {
          if (
            message.message_id &&
            message.message_id === action.msgs[i].message_id
          ) {
            const newMsg: IMessage = {};
            newMsg.likes = action.msgs[i].likes;
            newMsg.views = action.msgs[i].views;
            newMsg.shares = action.msgs[i].shares;
            // if (!environment.production) {
            //   if (action.msgs[i].link) {
            //     newMsg.link = SHARE_LINK + action.msgs[i].link.split('/').pop();
            //   }
            // } else {
            //   newMsg.link = action.msgs[i].link;
            // }
            return { ...message, ...newMsg };
          }
          return message;
        }
      });
    case MessageActions.READ_CHAT_MESSAGES:
      return state.map(message => {
        if (action.chat.type === INDIVIDUAL) {
          if (message.sender_id === action.chat.id && !message.isRead) {
            const newMsg: IMessage = {};
            newMsg.isRead = true;
            return { ...message, ...newMsg };
          }
        } else {
          if (message.group_id === action.chat.id && !message.isRead) {
            const newMsg: IMessage = {};
            newMsg.isRead = true;
            return { ...message, ...newMsg };
          }
        }
        return message;
      });
    //
    case MessageActions.CHAT_DESELECTED:
      return state.map(message => {
        if (action.chat.type === INDIVIDUAL) {
          if (message.sender_id === action.chat.id && !message.isChatViewed) {
            const newMsg: IMessage = {};
            newMsg.isChatViewed = message.isViewed;
            return { ...message, ...newMsg };
          }
        } else {
          if (message.group_id === action.chat.id && !message.isChatViewed) {
            const newMsg: IMessage = {};
            newMsg.isChatViewed = message.isViewed;
            return { ...message, ...newMsg };
          }
        }
        return message;
      });
    case MessageActions.MESSAGE_RECALLED: {
      const recalledMsg = action.message;
      let newState = state;
      // if message has a parent then I should decrease the number of replies in the parent side
      if (recalledMsg.reply_to_message_id) {
        newState = state.map(message => {
          if (message.message_id === recalledMsg.reply_to_message_id) {
            const updatedParentMessage: IMessage = {};
            updatedParentMessage.numberOfReplies = message.numberOfReplies
              ? --message.numberOfReplies
              : 0;
            if (!recalledMsg.isViewed) {
              updatedParentMessage.numberOfUnreadReplies = message.numberOfUnreadReplies
                ? --message.numberOfUnreadReplies
                : 0;
            }
            return { ...message, ...updatedParentMessage };
          }
          return message;
        });
      }
      return newState.filter(message => {
        return message.message_id !== recalledMsg.message_id;
      });
    }
    case MessageActions.LIKE_MESSAGE:
      return state.map(message => {
        if (
          message.message_id &&
          message.message_id === action.payload.message_id
        ) {
          const newMsg: IMessage = {};
          newMsg.myLike = 1;
          newMsg.likes = message.likes ? ++message.likes : 1;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.UNLIKE_MESSAGE:
      return state.map(message => {
        if (
          message.message_id &&
          message.message_id === action.payload.message_id
        ) {
          const newMsg: IMessage = {};
          newMsg.myLike = 0;
          newMsg.likes = message.likes ? --message.likes : 0;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.LIKE_ACK_RECEIVED:
      return state.map(message => {
        if (
          message.message_id &&
          message.message_id === action.payload.message_id
        ) {
          const newMsg: IMessage = {};
          newMsg.myLike = action.payload.myLike;
          newMsg.likes = action.payload.likes;
          return { ...message, ...newMsg };
        }
        return message;
      });

    case MessageActions.MESSAGE_IS_VIEWED: {
      let newState = state;
      const handledMsg = action.payload;
      // if message has a parent then I should decrease the number of replies in the parent side
      if (handledMsg.reply_to_message_id) {
        newState = state.map(message => {
          if (message.message_id === handledMsg.reply_to_message_id) {
            const updatedParentMessage: IMessage = {};
            updatedParentMessage.numberOfUnreadReplies = message.numberOfUnreadReplies
              ? --message.numberOfUnreadReplies
              : 0;
            return { ...message, ...updatedParentMessage };
          }
          return message;
        });
      }
      //
      return newState.map(message => {
        if (
          message.message_id &&
          message.message_id === handledMsg.message_id
        ) {
          const newMsg: IMessage = {};
          newMsg.isViewed = true;
          newMsg.isRead = true;
          return { ...message, ...newMsg };
        }
        return message;
      });
    }
    case MessageActions.RESET_NUMBER_OF_UNREAD_REPLIES:
      return state.map(message => {
        if (message.message_id === action.message_id) {
          const updatedMsg: IMessage = {};
          updatedMsg.numberOfUnreadReplies = 0;
          return { ...message, ...updatedMsg };
        }
        return message;
      });
    /**********************************************************************************/
    /** Media section */
    case MessageActions.SET_LOCAL_THUMBNAIL:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.localThumbnail = action.payload.localThumbnail;
          newMsg.thumbnailStatus = action.payload.thumbnailStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_LOCAL_MEDIA:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.localMedia = action.payload.localMedia;
          if (action.payload.mediaStatus) {
            newMsg.mediaStatus = action.payload.mediaStatus;
          }
          if (action.payload.media_width) {
            newMsg.media_width = action.payload.media_width;
          }
          if (action.payload.media_height) {
            newMsg.media_height = action.payload.media_height;
          }
          if (action.payload.media_duration) {
            newMsg.media_duration = action.payload.media_duration;
          }
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_MESSAGE_MEDIA_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.mediaStatus = action.payload.mediaStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.SET_MESSAGE_THUMBNAIL_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.thumbnailStatus = action.payload.thumbnailStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.UPDATE_LOADING_PROGRESS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.message.message_id) ||
          (message.reference && message.reference === action.message.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.loadingProgress = action.progress;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.CANCEL_DOWNLOAD_MEDIA_MESSAGE:
    case MessageActions.CANCEL_UPLOAD_MEDIA_MESSAGE:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.loadingProgress = 0;
          return { ...message, ...newMsg };
        }
        return message;
      });
    /********************************************************************************************************/
    /** Link Preview scetion */
    case MessageActions.SET_LINK_PREVIEW_STATUS:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          return { ...message, ...newMsg };
        }
        return message;
      });
    case MessageActions.LINK_PREVIEW_DETAILS_RECEIVED:
      return state.map(message => {
        if (
          (message.message_id &&
            message.message_id === action.payload.message_id) ||
          (message.reference && message.reference === action.payload.reference)
        ) {
          const newMsg: IMessage = {};
          newMsg.linkPreviewTitle = action.payload.linkPreviewTitle;
          if (
            action.payload.linkPreviewDescription &&
            action.payload.linkPreviewDescription.length > 0
          ) {
            newMsg.linkPreviewDescription = action.payload.linkPreviewDescription.trim();
          }
          newMsg.linkPreviewImage = action.payload.linkPreviewImage;
          newMsg.linkPreviewWidth = action.payload.linkPreviewWidth;
          newMsg.linkPreviewStatus = action.payload.linkPreviewStatus;
          newMsg.linkPreviewHeight = action.payload.linkPreviewHeight;
          newMsg.linkPreviewUrl = action.payload.linkPreviewUrl;
          newMsg.linkPreviewRootUrl = action.payload.linkPreviewRootUrl;
          return { ...message, ...newMsg };
        }
        return message;
      });
    /********************************************************************************************************/
    /** Menu Stats section */
    case MessageActions.SET_MESSAGE_MENU_STATS:
      return state.map(message => {
        if (message.message_id === action.payload.message_id) {
          return { ...message, menuStats: action.payload.menuStats };
        }
        return message;
      });
    /********************************************************************************************************/
    case MessageActions.DESELECT_CHANNEL:
    case MessageActions.RESET:
      return [];
    default:
      return state;
  }
}
