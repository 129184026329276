import { Bundle, BundleProduct } from './Bundle';
import { Product } from 'src/models/Product';
import {
  GET_STORE_METHOD,
  GET_STORES_METHOD,
  MERCHANT_PAGE_SIZE,
  CREATE_STORE_METHOD,
  CREATE_STORE_REF,
  SET_STORE_METHOD,
  ADD_STORE_ITEM_METHOD,
  REMOVE_STORE_ITEM_METHOD,
  SCREEN_STATUS,
  PageDirection,
  ListRequestDetails,
  ASSIGN_STORE_CHANNEL_METHOD,
  REMOVE_STORE_METHOD,
  GET_STORE_REF
} from './constants';
import { Package } from './Package';

export interface MStore {
  id?: string;
  date?: string;
  image?: string;
  modified_date?: string;
  photo_id?: string;
  version?: string;
  url?: string;
  main_group_id?: string;
  name?: string;
  desc?: string;
  status?: string;
  items?: StoreItem[];
  chats?: StoreChat[];
  style?: number;
  is_default?: number;
  bg?: string;
  owner?: string;
}
export interface StoreChat {
  is_default?: number;
  chat_id?: string;
}
export interface MStoreItem {
  id?: string;
  type?: number;
}
export interface StoreItem {
  id?: string;
  type?: number;
  date?: string;
  image?: string;
  modified_date?: string;
  version?: string;
  url?: string;
  group_id?: string;
  price?: string;
  name?: string;
  invitation_message?: string;
  currency?: string;
  desc?: string;
  status?: string;
  photo_id?: string;
  products?: BundleProduct[];
  product_id?: string;
}
export interface StoreUI {
  currentPage?: MStore[]; // will get it from the server result
  eop?: number; // will get it from the server result // end of page
  sop?: number; // will get it from the server result // start of page
  hash?: string;
  status?: SCREEN_STATUS; // So that if we want to add a progress circle or something until we receive the request
  direction?: PageDirection;
  previousRequest?: ListRequestDetails;
  selected_chat: string;
}

export class GetStoreMethod {
  readonly method = GET_STORE_METHOD;
  readonly reference = GET_STORE_REF;
  constructor(public id: string) {}
}

export class GetStoresMethod {
  readonly method = GET_STORES_METHOD;
  readonly page_size = MERCHANT_PAGE_SIZE;
  constructor(
    public direction?: number,
    public reference?: number,
    public selected_chat?: string
  ) {}
}

export class CreateStoreMethod {
  readonly method = CREATE_STORE_METHOD;
  readonly reference = CREATE_STORE_REF;
  constructor(packageToBeCreated: Package) {
    for (const key in packageToBeCreated) {
      if (packageToBeCreated.hasOwnProperty(key)) {
        this[key] = packageToBeCreated[key];
      }
    }
  }
}

export class SetStoreMethod {
  readonly method = SET_STORE_METHOD;
  constructor(storetoBeUpdated: MStore) {
    for (const key in storetoBeUpdated) {
      if (storetoBeUpdated.hasOwnProperty(key)) {
        this[key] = storetoBeUpdated[key];
      }
    }
  }
}

// data is string[] of Bundle IDs
export class AddStoreItemMethod {
  readonly method = ADD_STORE_ITEM_METHOD;
  constructor(public id: string, public data: MStoreItem[]) {}
}

export class AssignStoreChannelMethod {
  readonly method = ASSIGN_STORE_CHANNEL_METHOD;
  constructor(public id: string, public data: any[]) {}
}

// data is array of bundle IDs ["835935","835936"]
export class RemoveStoreItemMethod {
  readonly method = REMOVE_STORE_ITEM_METHOD;
  constructor(public id: string, public data: any[]) {}
}

export class RemoveStoreMethod {
  readonly method = REMOVE_STORE_METHOD;
  constructor(public id: string) {}
}
