import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-switch-btn',
  templateUrl: './switch-btn.component.html',
  styleUrls: ['./switch-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchBtnComponent {
  @Input() isOn: boolean;
  @Input() isDisabled = false;
  @Output() switchIsOn = new EventEmitter<boolean>();

  onChange(event: MatSlideToggleChange) {
    this.switchIsOn.emit(event.checked);
  }
}
