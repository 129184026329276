import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { AppState } from 'src/models/AppState';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { BookingUIActionTypes } from './bookingUI.actions';
import * as BookingActions from './bookingUI.actions';
import { FileService } from 'src/app/core/file.service';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import {
  ERROR_NOT_IMAGE,
  FILE_SIZE_EXCEEDED,
  MessageTypes,
  NOT_SUPPORTED_FILE_TYPE,
  NO_FILE_NAME,
  GROUP_UPLOAD_TYPE,
  BOOKING_CHANNEL
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { IUploadResponse } from 'src/models/IUploadResponse';
import { Photo } from 'src/models/Photo';
import { IChat, SetChat, GetSubChats } from 'src/models/IChat';
import { BookingUiDispatchers } from './bookingUI.dispatchers';

import * as isEqual from 'lodash.isequal';
import { SetEnableService } from 'src/models/BookingUI';
import { BookingsService } from 'src/app/+merchant/+dashboard/bookings/bookings.service';

@Injectable()
export class BookingUiEffects {
  @Effect({ dispatch: false })
  uploadBookingImage = this.actions$.pipe(
    ofType(BookingUIActionTypes.UPLOAD_BOOKING_IMAGE),
    withLatestFrom(
      this._store
        .select(state => state.bookingUiReducer.creationProcessBooking)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.bookingUiReducer.selectedBooking)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, creationBooking, updatedBooking]) => {
      const action = <BookingActions.UploadBookingImage>val;
      if (this.validateFile(action.booking.file, 'image')) {
        this.handleFileUpload(
          action.booking,
          creationBooking ? creationBooking.id : updatedBooking.id
        );
      }
    })
  );

  @Effect({ dispatch: false })
  afterBookingCreation = this.actions$.pipe(
    ofType(BookingUIActionTypes.UI_UPLOAD_BOOKING_PHOTO),
    map((action: BookingActions.BookingPhotoUploadUI) => {
      this._chatDispatchers.chatInvalidated(action.createdBooking.id);
    })
  );

  @Effect({ dispatch: false })
  getNextBookingPage = this.actions$.pipe(
    ofType(BookingUIActionTypes.UI_BOOKING_GET_NEXT_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.bookingUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, bookingUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetSubChats(
          [BOOKING_CHANNEL],
          0,
          bookingUiState.hash,
          bookingUiState.eop
        )
      );
    })
  );

  @Effect({ dispatch: false })
  getPrevBookingPage = this.actions$.pipe(
    ofType(BookingUIActionTypes.UI_BOOKING_GET_PREV_PAGE),
    withLatestFrom(
      this._store
        .select(state => state.bookingUiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, bookingUiState]) => {
      this._socketGateway.sendSocketMessage(
        new GetSubChats(
          [BOOKING_CHANNEL],
          1,
          bookingUiState.hash,
          bookingUiState.sop
        )
      );
    })
  );

  @Effect({ dispatch: false })
  afterBookingSectionSelection = this.actions$.pipe(
    ofType(BookingUIActionTypes.UI_LIST_BOOKING),
    map((action: BookingActions.ListBookingUI) => {
      this._uiBookingDispatchers.getNextBookingPage();
    })
  );
  //
  @Effect({ dispatch: false })
  afterBookingResetPaging = this.actions$.pipe(
    ofType(BookingUIActionTypes.UI_BOOKING_RESET_PAGING),
    map((action: BookingActions.ResetBookingPaging) => {
      this._uiBookingDispatchers.getNextBookingPage();
    })
  );
  //

  @Effect({ dispatch: false })
  setEnableService = this.actions$.pipe(
    ofType(BookingUIActionTypes.SET_ENABLE_SERVICE),
    map((action: BookingActions.SetEnableService) => {
      this._socketGateway.sendSocketMessage(
        new SetEnableService(action.config)
      );
    })
  );

  @Effect({ dispatch: false })
  setChatBooking = this.actions$.pipe(
    ofType(BookingUIActionTypes.SET_CHAT_UI_BOOKING),
    map((action: BookingActions.SetChat) => {
      console.log(action.chat);
      this._socketGateway.sendSocketMessage(new SetChat(action.chat));
    })
  );

  constructor(
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _uiDispatchers: UIDispatchers,
    private _uiBookingDispatchers: BookingUiDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private bookingsService: BookingsService,
    private _store: Store<AppState>
  ) {}

  handleFileUpload(booking: any, chatId: string) {
    this._fileService
      .readFileAsArrayBuffer(booking.file.localFile)
      .then(fileAsArrayBuffer => {
        this._uploadGateway
          .uploadWithProgress(
            fileAsArrayBuffer,
            booking.file.localFile.type,
            booking.file.localFile.name,
            false,
            GROUP_UPLOAD_TYPE,
            null,
            chatId,
            true
          )
          .subscribe(
            event => {
              if (event.type === HttpEventType.Response) {
                console.log('here i am ');
                const res = <IUploadResponse>event.body;
                let chat: IChat = {};
                const photo: Photo = { id: res.file };
                chat.id = chatId;
                chat.photo = photo;
                chat = { ...chat, ...booking.booking };
                console.log('new chat', chat);
                // this.bookingsService._currentPhotoUploaded.next(res);
                this._socketGateway.sendSocketMessage(new SetChat(chat));
              }
            },
            error => {
              console.log('Failed to upload booking image');
            }
          );
      });
  }

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return false;
    }
    return true;
  }
}
