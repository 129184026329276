<div class="share">
  <a
    [href]="shareLink"
    target="_blank"
    class="share-social-box link clickable-cursor"
  >
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-sections.svg#icon-link"></use>
    </svg>
    <app-hover-tooltip
      *ngIf="hoverShareLink"
      [top]="120"
      [right]="-255"
      [text]="tooltipText"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </a>
  <a
    (click)="shareOnSocial($event, 'F')"
    class="share-social-box facebook clickable-cursor"
  >
    <i class="fab fa-facebook-f"></i>
    <app-hover-tooltip
      *ngIf="hoverFacebook"
      [top]="120"
      [right]="-185"
      [text]="tooltipText"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </a>
  <a
    (click)="shareOnSocial($event, 'T')"
    class="share-social-box twitter clickable-cursor"
  >
    <i class="fab fa-twitter"></i>
    <app-hover-tooltip
      *ngIf="hoverTwitter"
      [top]="120"
      [right]="-165"
      [text]="tooltipText"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </a>
  <!-- <a
    (click)="shareOnSocial($event, 'G')"
    class="share-social-box google clickable-cursor"
  >
    <i class="fab fa-google-plus-g"></i>
  </a> -->

  <a
    ngxClipboard
    [cbContent]="shareLink"
    (cbOnSuccess)="copySuccessfullyEvent()"
    class="share-social-box copy clickable-cursor"
  >
    <svg class="svg-icon icon-icon">
      <use xlink:href="/assets/svg/app-sections.svg#icon-copy"></use>
    </svg>
    <app-hover-tooltip
      *ngIf="hoverCopyLink"
      [top]="120"
      [right]="-190"
      [text]="tooltipText"
      [width]="'max-content'"
    ></app-hover-tooltip>
  </a>
</div>
