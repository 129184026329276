<div class="sub-header no-copyable-item">
  <div
    *ngIf="direction === uiSections.SETTINGS; else directionT"
    class="page-title"
  >
    Settings
  </div>
</div>

<!---->
<ng-template #directionT>
  <div
    class="dropdown"
    dropdown
    *ngIf="direction === '' || direction === 'ARTP|ASTM'; else CalenderButton"
  >
    <a type="button" dropdownToggle>
      <input
        type="checkbox"
        aria-label="select all messages"
        (click)="checkBoxChanged($event)"
      />
      <i class="fas fa-chevron-down" aria-hidden="true"></i>
    </a>
    <ul *dropdownMenu class="dropdown-menu no-copyable-item">
      <li>
        <a (click)="selectEvent('SELECT_ALL')">all </a>
      </li>
      <li>
        <a (click)="selectEvent('SELECT_NONE')">none</a>
      </li>
      <li>
        <a (click)="selectEvent('SELECT_READ')">read </a>
      </li>
      <li>
        <a (click)="selectEvent('SELECT_UNREAD')">unread</a>
      </li>
      <li
        class="divider"
        *ngIf="(layoutService.messagesCollection$ | async)?.selected > 0"
      ></li>
      <li>
        <a
          *ngIf="(layoutService.messagesCollection$ | async)?.selected > 0"
          (click)="selectEvent('DELETE_ALL')"
        >
          Delete
        </a>
      </li>
      <li>
        <a
          *ngIf="(layoutService.messagesCollection$ | async)?.selected > 0"
          (click)="selectEvent('RECALL_ALL')"
        >
          Recall
        </a>
      </li>
      <li>
        <a *ngIf="(layoutService.messagesCollection$ | async)?.selected === 1">
          Copy
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<!-- /////////////  partials -->
<ng-template #CalenderButton>
  <div class="group-select" *ngIf="direction === uiSections.SCHEDULE">
    <div
      class="btn-group btn-group-sm btn-group-calendar"
      role="group"
      aria-label=""
    >
      <a
        class="btn btn-secondary"
        role="button"
        [popover]="datepickerPopover"
        placement="bottom"
        [outsideClick]="true"
      >
        Calendar <span class="caret"></span>
      </a>
      <a class="btn btn-secondary" role="button" *ngIf="filterScheduleDate">
        {{ filterScheduleDate | date: 'EEEE MMM d,y' }}
        <span
          class="close-icon"
          (click)="resetFilter()"
          aria-label="Close"
        ></span>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #datepickerPopover>
  <div class="Datepicker-Popover">
    <datepicker
      [(ngModel)]="scheduleDate"
      [minDate]="minDate"
      [showWeeks]="false"
      [minMode]="'day'"
      (selectionDone)="filterDateChanged($event)"
    >
    </datepicker>
  </div>
</ng-template>
