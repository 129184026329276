import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';

import * as MediaCenterActions from './mediaCenter.actions';

import {
  IMediaCenter,
  MediaCenterRcvd,
  MediaCenterPrams
} from 'src/models/IMediaCenter';

@Injectable({
  providedIn: 'root'
})
export class MediaCenterDispatchers {
  constructor(private _store: Store<AppState>) {}

  /** Gallery */
  getMyMediaGallery() {
    this._store.dispatch(new MediaCenterActions.GetMyMediaGallery());
  }

  myMediaGalleryRcvd(rcvd: MediaCenterRcvd[]) {
    this._store.dispatch(new MediaCenterActions.MyMediaGalleryRcvd(rcvd));
  }

  /***************************************** */

  /** Media Center */
  mediaCenterUploading(
    localFile: File,
    fileType: string,
    uploadPrams: MediaCenterPrams
  ) {
    this._store.dispatch(
      new MediaCenterActions.MediaCenterUploading(
        localFile,
        fileType,
        uploadPrams
      )
    );
  }
  mediaCenterUploadingProcessed(
    localMedia: IMediaCenter,
    uploadPrams: MediaCenterPrams
  ) {
    this._store.dispatch(
      new MediaCenterActions.MediaCenterUploadingProcessed(
        localMedia,
        uploadPrams
      )
    );
  }

  updateMediaCenterLoadingProgress(localMedia: IMediaCenter, progress: number) {
    this._store.dispatch(
      new MediaCenterActions.UpdateMediaCenterLoadingProgress(
        localMedia,
        progress
      )
    );
  }
  updateMediaCenterStatus(
    msg: IMediaCenter,
    status: string,
    url: string,
    file: string
  ) {
    this._store.dispatch(
      new MediaCenterActions.UpdateMediaCenterStatus(msg, status, url, file)
    );
  }

  deleteMediaCenterItem(msg: IMediaCenter) {
    this._store.dispatch(
      new MediaCenterActions.DeleteMediaCenterItem(msg.filename)
    );
  }
}
