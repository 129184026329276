import { Action } from '@ngrx/store';

import { MainChat } from 'src/models/MainChat';
import { IMessage } from 'src/models/IMessage';
import { ReplyMessage } from 'src/models/ReplyMessage';

export const enum MainChatActionTypes {
  MAIN_CHAT_RECEIVED = 'MAIN_CHAT_RECEIVED',
  MAIN_CHAT_DESELECTED = 'MAIN_CHAT_DESELECTED',
  MAIN_CHAT_HISTORY_REQUESTED = 'MAIN_CHAT_HISTORY_REQUESTED',
  UPDATE_NUMBER_OF_UNREAD_MESSAGES = 'UPDATE_NUMBER_OF_UNREAD_MESSAGES',
  UPDATE_LAST_MESSAGE = 'UPDATE_LAST_MESSAGE',
  UPDATE_LAST_MESSAGE_STATUS = 'UPDATE_LAST_MESSAGE_STATUS',
  MAIN_CHAT_SELECTED = 'MAIN_CHAT_SELECTED',
  INCREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES = 'INCREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES',
  DECREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES = 'DECREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES',
  RESET_NUMBER_OF_NOT_VIEWED_MESSAGES = 'RESET_NUMBER_OF_NOT_VIEWED_MESSAGES',
  REPLY_RECEIVED_IN_SELECTED_CHAT = 'REPLY_RECEIVED_IN_SELECTED_CHAT',
  UPDATE_END_OF_PAGE = 'UPDATE_END_OF_PAGE',
  UPDATE_EOP_LEVEL1 = 'UPDATE_EOP_LEVEL1',
  REQUEST_HISTORY = 'REQUEST_HISTORY',
  MAIN_CHAT_MEMBER_COUNT_RECEIVED = 'MAIN_CHAT_MEMBER_COUNT_RECEIVED',
  LOGGED_IN_LEFT_MAIN_CHAT = 'LOGGED_IN_LEFT_MAIN_CHAT',
  UPDATE_MAIN_CHAT_IMAGES = 'UPDATE_MAIN_CHAT_IMAGES'
}

export const DESELECT_CHANNEL = 'DESELECT_CHANNEL';
export const RESET = 'RESET';

export class MainChatReceived implements Action {
  readonly type = MainChatActionTypes.MAIN_CHAT_RECEIVED;
  constructor(public payload: MainChat) {}
}

export class MainChatSelected implements Action {
  readonly type = MainChatActionTypes.MAIN_CHAT_SELECTED;
  constructor(public mainChat: MainChat) {}
}

export class MainChatMemberCountReceived implements Action {
  readonly type = MainChatActionTypes.MAIN_CHAT_MEMBER_COUNT_RECEIVED;
  constructor(public id: string, public memberCount: number) {}
}

export class MainChatDeselected implements Action {
  readonly type = MainChatActionTypes.MAIN_CHAT_DESELECTED;
  constructor(public id: string, public lastTypedMessage: string) {}
}

export class MainChatHistoryRequested implements Action {
  readonly type = MainChatActionTypes.MAIN_CHAT_HISTORY_REQUESTED;
  constructor(public id: string) {}
}

export class LoggedInLeftMainChat implements Action {
  readonly type = MainChatActionTypes.LOGGED_IN_LEFT_MAIN_CHAT;
  constructor(public id: string) {}
}

export class UpdateNumberOfUnreadMessages implements Action {
  readonly type = MainChatActionTypes.UPDATE_NUMBER_OF_UNREAD_MESSAGES;
  constructor(
    public id: string,
    public unreadMessagesCount: number,
    public isFirstLevelMessage: boolean,
    public isRecalled: boolean
  ) {}
}

export class UpdateLastMessage implements Action {
  readonly type = MainChatActionTypes.UPDATE_LAST_MESSAGE;
  constructor(
    public mainChat: MainChat,
    public lastMessage: ReplyMessage,
    public afterRecall?: boolean
  ) {}
}

export class UpdateLastMessageStatus implements Action {
  readonly type = MainChatActionTypes.UPDATE_LAST_MESSAGE_STATUS;
  constructor(
    public id: string,
    public lastMessageStatus: string,
    public reference: number
  ) {}
}

export class IncrementNumberOfNotViewedMessages implements Action {
  readonly type = MainChatActionTypes.INCREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES;
  constructor(public mainChat: MainChat) {}
}

export class DecrementNumberOfNotViewedMessages implements Action {
  readonly type = MainChatActionTypes.DECREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES;
  constructor(public mainChat: MainChat) {}
}

export class ResetNumberOfNotViewedMessages implements Action {
  readonly type = MainChatActionTypes.RESET_NUMBER_OF_NOT_VIEWED_MESSAGES;
  constructor(public mainChatID: string) {}
}

export class ReplyReceivedInSelectedChat implements Action {
  readonly type = MainChatActionTypes.REPLY_RECEIVED_IN_SELECTED_CHAT;
  constructor(public message: IMessage) {}
}

export class UpdateEndOfPage implements Action {
  readonly type = MainChatActionTypes.UPDATE_END_OF_PAGE;
  constructor(public mainChatID: string, public endOfPage) {}
}

export class UpdateEopLevel1 implements Action {
  readonly type = MainChatActionTypes.UPDATE_EOP_LEVEL1;
  constructor(public mainChatID: string, public eopLevel1: number) {}
}

export class RequestHistory implements Action {
  readonly type = MainChatActionTypes.REQUEST_HISTORY;
  constructor(
    public parentMsgID?: string,
    public userID?: string,
    public requestOnce?: boolean,
    public subChatID?: string
  ) {}
}

export class UpdateMainChatImages implements Action {
  readonly type = MainChatActionTypes.UPDATE_MAIN_CHAT_IMAGES;
  constructor(public id: string, public imageUrl) {}
}

export class DeselectChannel implements Action {
  readonly type = DESELECT_CHANNEL;
}
export class Reset implements Action {
  readonly type = RESET;
}

export type MainChatActions =
  | MainChatReceived
  | MainChatSelected
  | MainChatDeselected
  | MainChatHistoryRequested
  | UpdateNumberOfUnreadMessages
  | UpdateLastMessage
  | UpdateLastMessageStatus
  | IncrementNumberOfNotViewedMessages
  | DecrementNumberOfNotViewedMessages
  | ResetNumberOfNotViewedMessages
  | UpdateEndOfPage
  | UpdateEopLevel1
  | RequestHistory
  | MainChatMemberCountReceived
  | LoggedInLeftMainChat
  | UpdateMainChatImages
  | DeselectChannel
  | Reset;
