<div
  [class.form-group]="!isInline"
  [class.form-inline]="isInline"
  *ngIf="!onlyCheckbox"
>
  <label
    *ngIf="filedLabel"
    class="control-label"
    [class.md]="isInline"
    [for]="filedId"
  >
    {{ filedLabel }}

    <app-hover-tooltip-icon
      *ngIf="showTooltip"
      [title]="filedLabel"
      [description]="descriptionTooltip"
      [top]="topTooltipHover"
      [left]="leftTooltipHover"
    ></app-hover-tooltip-icon>
  </label>

  <mat-checkbox [id]="filedId" [formControl]="control">
    <ng-content></ng-content>
  </mat-checkbox>

  <ng-container *ngIf="validationErrors$ | async as errors">
    <app-validation-errors
      *ngIf="!usingCheckBox"
      [fieldErrors]="errors"
      [fieldName]="filedLabel"
      [usingCheckBox]="false"
    >
    </app-validation-errors>
    <app-validation-errors
      *ngIf="usingCheckBox"
      check-box-error
      [fieldErrors]="errors"
      [fieldName]="filedError"
      [usingCheckBox]="true"
    >
    </app-validation-errors>
  </ng-container>
</div>

<mat-checkbox *ngIf="onlyCheckbox" [id]="filedId"> </mat-checkbox>
