import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/core/auth.service';
import { slideToLeft } from 'src/app/app-animations';
import {
  AuthStatus,
  SIGNUP_LINK,
  PRIVACY_LINK,
  TERMS_LINK
} from 'src/models/constants';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
  animations: [slideToLeft],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanComponent implements OnInit {
  email = false;
  qrSize: number;
  isPasswordForgot = false;
  remControl: FormControl;
  authStatus: typeof AuthStatus = AuthStatus;

  helpVideoVisibility = false;

  signupLink = SIGNUP_LINK;
  privacyLink = PRIVACY_LINK;
  termsLink = TERMS_LINK;

  constructor(public appService: AppService, public authService: AuthService) {
    this.email = false;
  }

  ngOnInit() {
    this.remControl = new FormControl(this.authService.authRem);
    this.remControl.valueChanges.subscribe(res => this.selectRemEvent(res));
  }
  selectRemEvent(res: boolean) {
    this.authService.setRem(res);
  }

  logoutEvent() {
    this.authService.logout();
  }

  goEmail() {
    this.email = true;
    this.authService.loginUseEmail();
  }
  loginQr() {
    this.email = false;
  }
  qrCodeReload() {
    this.authService.reopenNewConnection();
  }

  showNeedHelp(b: boolean) {
    this.helpVideoVisibility = b;
  }
}
