<div class="attachment-types">
  <div class="sending-to-label">
    Send To

    <app-hover-tooltip-icon
      [title]="'Send To'"
      [description]="
        'Select your post`s destination. Make sure you have first added Post/Feed to your Home Menu to post in your App`s Feed instantly.'
      "
      [top]="-70"
      [left]="30"
    ></app-hover-tooltip-icon>
  </div>

  <!-- Hide if subChat -->
  <ng-container *ngIf="(appMgmtService.tabsContainers$ | async)?.length > 0">
    <div class="h-line"></div>
    <div
      class="attachs clickable-cursor"
      dropdown
      [dropup]="true"
      *ngIf="(appMgmtService.tabsContainers$ | async)?.length > 0"
      (mouseover)="toggleHoverVisibility('Feed', true)"
      (mouseleave)="toggleHoverVisibility('Feed', false)"
    >
      <div dropdownToggle class="sending-to-btn">
        <div class="sending-to-logo">
          <svg class="svg-icon icon-icon">
            <use
              attr.xlink:href="./assets/svg/app-icons.svg#{{
                localSelectedTab?.icon
              }}"
            ></use>
          </svg>
        </div>
        <span>{{ localSelectedTab?.title }}</span> <span class="caret"></span>
      </div>
      <app-hover-tooltip
        *ngIf="hoverFeed"
        [top]="85"
        [right]="20"
        [width]="'max-content'"
        [text]="'Choose Feed'"
      ></app-hover-tooltip>
      <ul
        *dropdownMenu
        id="dropdown-basic"
        class="dropdown-menu"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li
          class="menu-item clickable-cursor"
          *ngFor="let tab of composeMessageService.tabsContainers$ | async"
          (click)="setSelectedTab(tab)"
        >
          <svg class="svg-icon icon-icon">
            <use
              attr.xlink:href="./assets/svg/app-icons.svg#{{ tab?.icon }}"
            ></use>
          </svg>
          <span> {{ tab.title }}</span>
        </li>
      </ul>
    </div>
  </ng-container>

  <!-- Hide if not listtag privales -->
  <ng-container *appShowIfHasPrivilege="privilegesName.listtag">
    <div class="h-line"></div>
    <div
      class="sending-to-btn clickable-cursor"
      (click)="showTagsModalEvent(true)"
      (mouseover)="toggleHoverVisibility('Tags', true)"
      (mouseleave)="toggleHoverVisibility('Tags', false)"
    >
      <div class="sending-to-logo">
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-tags"></use>
        </svg>
      </div>
      <span>Tags</span>
      <app-hover-tooltip
        *ngIf="hoverTags"
        [top]="85"
        [right]="20"
        [width]="'max-content'"
        [text]="'Add Tags'"
      ></app-hover-tooltip>
    </div>
    <div class="h-line"></div>
    <div
      class="sending-to-btn clickable-cursor"
      (click)="showPrivateTagsModalEvent(true)"
      (mouseover)="toggleHoverVisibility('Segment', true)"
      (mouseleave)="toggleHoverVisibility('Segment', false)"
    >
      <div class="sending-to-logo">
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-tags"></use>
        </svg>
      </div>
      <span>Segment</span>
      <app-hover-tooltip
        *ngIf="hoverSegment"
        [top]="85"
        [right]="20"
        [width]="'max-content'"
        [text]="'Add Segments'"
      ></app-hover-tooltip>
    </div>
  </ng-container>

  <!-- Hide on sub-chats -->
  <ng-container *appHideIfSubChat="true">
    <div class="h-line"></div>
    <div
      class="sending-to-btn clickable-cursor"
      (click)="showSubgroupsModalEvent(true)"
      (mouseover)="toggleHoverVisibility('Groups', true)"
      (mouseleave)="toggleHoverVisibility('Groups', false)"
    >
      <div class="sending-to-logo">
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-sections.svg#icon-groups"></use>
        </svg>
      </div>
      <span>Groups</span>
      <app-hover-tooltip
        *ngIf="hoverGroup"
        [top]="85"
        [right]="20"
        [width]="'max-content'"
        [text]="'Select Groups'"
      ></app-hover-tooltip>
    </div>
  </ng-container>

  <div class="h-line"></div>
  <div
    style="position: relative; display: flex; flex-direction: row-reverse; width: 50%;"
    (mouseover)="toggleHoverVisibility('silent', true)"
    (mouseleave)="toggleHoverVisibility('silent', false)"
  >
    <app-switch-btn [isOn]="silentFlag" (switchIsOn)="setSilentFlag($event)">
      Silent
    </app-switch-btn>
    <app-hover-tooltip
      *ngIf="hoverSilent"
      [top]="130"
      [right]="-15"
      [width]="'max-content'"
      [text]="silentTextTooltip"
    ></app-hover-tooltip>
  </div>
</div>
<div class="attachment-tags" *ngIf="selectedTags.length > 0">
  <app-assign-tags
    [assignTags]="selectedTags"
    (removeTagNotfiy)="removeTagEvent($event)"
  >
  </app-assign-tags>
</div>
<div class="attachment-tags" *ngIf="selectedSubgroups.length > 0">
  <app-assign-sub-chat
    [assignSubgroups]="selectedSubgroups"
    (removeSubgroupNotfiy)="removeSubgroupsEvent($event)"
  >
  </app-assign-sub-chat>
</div>

<!-- Tags Modal -->
<app-modal-container
  *ngIf="showTagsModal"
  no-scroll
  (cancelNotfiy)="showTagsModalEvent(false)"
>
  <app-tags-selector
    [isPublic]="true"
    [tags]="composeMessageService?.selectedChatTags$ | async"
    (cancelNotfiy)="showTagsModalEvent(false)"
    (submitTagsFormNotfiy)="setTagsEvent($event)"
    (createTagNotfiy)="createTagEvent($event)"
  >
  </app-tags-selector>
</app-modal-container>

<!-- Private Tags -->
<app-modal-container
  *ngIf="showPrivateTagsModal"
  no-scroll
  (cancelNotfiy)="showPrivateTagsModalEvent(false)"
>
  <app-tags-selector
    [isPrivate]="true"
    [tags]="composeMessageService?.selectedChatPrivateTags$ | async"
    (cancelNotfiy)="showPrivateTagsModalEvent(false)"
    (submitTagsFormNotfiy)="setPrivateTagsEvent($event)"
    (createTagNotfiy)="createTagEvent($event)"
  >
  </app-tags-selector>
</app-modal-container>

<!-- Subgroups Modal -->
<app-modal-container
  *ngIf="showSubgroupsModal"
  no-scroll
  (cancelNotfiy)="showSubgroupsModalEvent(false)"
>
  <app-sub-chats-selector
    (cancelNotfiy)="showSubgroupsModalEvent(false)"
    (selectChatsNotfiy)="setSubgroupsEvent($event)"
  >
  </app-sub-chats-selector>
</app-modal-container>
