<div class="compose-container">
  <div class="message-container">
    <!-- Message Targets ( Tags and Tab ) -->

    <div class="calendar-flag">
      <div class="calendar-flag-title">Auto Accept</div>
      <app-switch-btn
        [isOn]="autoAcceptCalendar"
        (switchIsOn)="setAutoAcceptCalendar($event)"
      >
      </app-switch-btn>
    </div>
    <app-calendar-preview-cell
      class="compose-component"
      [calendarName]="selectedCalendar?.title"
      [calendarDescription]="selectedCalendar?.description"
      [calendarAvtar]="selectedCalendar?.url"
      [calendarAccept]="!autoAcceptCalendar"
    >
    </app-calendar-preview-cell>
  </div>

  <!-- Message Attachs -->
  <ng-content select="[message-attachs]"></ng-content>
  <!------------------------------------------------->
  <!-- Message Targets ( Tags and Tab ) -->
  <ng-content select="[message-target]"></ng-content>
  <!------------------------------------------------->

  <div class="footer">
    <div class="form-submit">
      <ng-content select="[footer-tag]"></ng-content>
      <ng-content select="[footer-submit]"></ng-content>
      <ng-content select="[footer-cancel]"></ng-content>
      <app-border-btn cancel (btnClicked)="cancelEvent()">
        Clear
      </app-border-btn>
    </div>
  </div>
</div>
