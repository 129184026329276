import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurableTableComponent } from './configurable-table/configurable-table.component';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AccessorsModule } from '../accessors/accessors.module';
import { ListGridComponent } from './list-grid/list-grid.component';
import { TableViewComponent } from './table-view/table-view.component';

@NgModule({
  imports: [CommonModule, SharedModule, AbstractBtnsModule, AccessorsModule],
  declarations: [
    ConfigurableTableComponent,
    ListGridComponent,
    TableViewComponent
  ],
  exports: [ConfigurableTableComponent, ListGridComponent, TableViewComponent]
})
export class AbstractTablesModule {}
