import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Component({
  selector: 'app-delete-btn',
  template: `
    <app-circle-btn delete (btnClicked)="btnEvent()">
      <app-delete-icon></app-delete-icon>
    </app-circle-btn>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteBtnComponent {
  @Output() btnClicked = new EventEmitter<boolean>();
  @Input() disabled: boolean;
  btnEvent() {
    this.btnClicked.emit(true);
  }
}
