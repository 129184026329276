import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBarcodeModule } from 'ngx-barcode';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from '../shared/shared.module';
import { AccessorsModule } from '../accessors/accessors.module';
import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractLabelledModule } from '../abstract-labelled/abstract-labelled.module';

import { CardFrameComponent } from './card-frame/card-frame.component';
import { CellFrameComponent } from './cell-frame/cell-frame.component';
import { FormFrameComponent } from './form-frame/form-frame.component';
import { SelectorFrameComponent } from './selector-frame/selector-frame.component';
import { EmptyItemFrameComponent } from './empty-item-frame/empty-item-frame.component';
import { CreateItemFrameComponent } from './create-item-frame/create-item-frame.component';
import { HeaderfullFrameComponent } from './headerfull-frame/headerfull-frame.component';
import { HeaderlessClosableFrameComponent } from './headerless-closable-frame/headerless-closable-frame.component';
import { SelectableCellFrameComponent } from './selectable-cell-frame/selectable-cell-frame.component';
import { AssignedFrameComponent } from './assigned-frame/assigned-frame.component';
import { StepperFrameComponent } from './stepper-frame/stepper-frame.component';
import { CreateCellFrameComponent } from './create-cell-frame/create-cell-frame.component';
import { ChipsFrameComponent } from './chips-frame/chips-frame.component';
import { AbstractConfirmModalComponent } from './abstract-confirm-modal/abstract-confirm-modal.component';
import { ListStyleBarFrameComponent } from './list-style-bar-frame/list-style-bar-frame.component';
import { AssignTextActionComponent } from './assign-text-action/assign-text-action.component';
import { AbstractTooltip } from '../abstract-tooltip/abstract-tooltip.module';
import { SettingProfileFramComponent } from './setting-profile-fram/setting-profile-fram.component';
import { RadioSettingInputComponent } from './radio-setting-input/radio-setting-input.component';
import { ListsSettingFrameComponent } from './lists-setting-frame/lists-setting-frame.component';
import { AbstractIconsModule } from '../abstract-icons/abstract-icons.module';
import { PopupSettingFrameComponent } from './popup-setting-frame/popup-setting-frame.component';
import { AbstractFrameItemsComponent } from './abstract-frame-items/abstract-frame-items.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AbstractItemComponent } from './abstract-item/abstract-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    NgxBarcodeModule,
    SharedModule,
    AbstractLabelledModule,
    AbstractBtnsModule,
    AccessorsModule,
    AbstractTooltip,
    AbstractIconsModule,
    DragDropModule
  ],
  declarations: [
    CardFrameComponent,
    CellFrameComponent,
    FormFrameComponent,
    SelectorFrameComponent,
    EmptyItemFrameComponent,
    CreateItemFrameComponent,
    HeaderfullFrameComponent,
    HeaderlessClosableFrameComponent,
    SelectableCellFrameComponent,
    AssignedFrameComponent,
    StepperFrameComponent,
    CreateCellFrameComponent,
    ChipsFrameComponent,
    AbstractConfirmModalComponent,
    ListStyleBarFrameComponent,
    AssignTextActionComponent,
    SettingProfileFramComponent,
    RadioSettingInputComponent,
    ListsSettingFrameComponent,
    PopupSettingFrameComponent,
    AbstractFrameItemsComponent,
    AbstractItemComponent
  ],
  exports: [
    MatTabsModule,
    CardFrameComponent,
    CellFrameComponent,
    FormFrameComponent,
    SelectorFrameComponent,
    EmptyItemFrameComponent,
    CreateItemFrameComponent,
    HeaderfullFrameComponent,
    HeaderlessClosableFrameComponent,
    SelectableCellFrameComponent,
    AssignedFrameComponent,
    StepperFrameComponent,
    CreateCellFrameComponent,
    ChipsFrameComponent,
    AbstractConfirmModalComponent,
    ListStyleBarFrameComponent,
    AssignTextActionComponent,
    SettingProfileFramComponent,
    RadioSettingInputComponent,
    ListsSettingFrameComponent,
    PopupSettingFrameComponent,
    AbstractFrameItemsComponent
  ]
})
export class AbstractFramesModule {}
