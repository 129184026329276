import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-form-frame',
  templateUrl: './form-frame.component.html',
  styleUrls: ['./form-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFrameComponent {
  // default if not send
  @Input() hideHeader = false;
  @Input() titleIcon = false;
  @Input() isCard = true;
  @Input() bgHeadIcon = true;
  @Input() descIcon = false;
  @Input() formDisable = false;
}
