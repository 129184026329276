/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-frame.component";
var styles_FormFrameComponent = [i0.styles];
var RenderType_FormFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFrameComponent, data: {} });
export { RenderType_FormFrameComponent as RenderType_FormFrameComponent };
function View_FormFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "title-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "main"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "sub"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "tail"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
function View_FormFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "title-icon"]], [[2, "icon-bg", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "head-icon"]], [[2, "icon-bg", null]], null, null, null, null)), i1.ɵncd(null, 3), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "head-text"]], null, null, null, null, null)), i1.ɵncd(null, 4), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "tail"]], null, null, null, null, null)), i1.ɵncd(null, 5)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bgHeadIcon; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.bgHeadIcon; _ck(_v, 2, 0, currVal_1); }); }
function View_FormFrameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "desc-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "desc-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), i1.ɵncd(null, 6), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), i1.ɵncd(null, 7), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "desc"]], null, null, null, null, null)), i1.ɵncd(null, 8), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "button"]], null, null, null, null, null)), i1.ɵncd(null, 9)], null, null); }
function View_FormFrameComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "desc-header"]], null, null, null, null, null)), i1.ɵncd(null, 10)], null, null); }
export function View_FormFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "form-frame"]], [[2, "card", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "form-frame-form"]], [[2, "active", null]], null, null, null, null)), i1.ɵncd(null, 11), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "form-frame-border"]], null, null, null, null, null)), i1.ɵncd(null, 12), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "form-frame-more"]], null, null, null, null, null)), i1.ɵncd(null, 13)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.hideHeader && !_co.titleIcon); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.titleIcon; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.descIcon; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.descIcon; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCard; _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.formDisable; _ck(_v, 9, 0, currVal_5); }); }
export function View_FormFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-frame", [], null, null, null, View_FormFrameComponent_0, RenderType_FormFrameComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormFrameComponent, [], null, null)], null, null); }
var FormFrameComponentNgFactory = i1.ɵccf("app-form-frame", i3.FormFrameComponent, View_FormFrameComponent_Host_0, { hideHeader: "hideHeader", titleIcon: "titleIcon", isCard: "isCard", bgHeadIcon: "bgHeadIcon", descIcon: "descIcon", formDisable: "formDisable" }, {}, ["title", "desc", "action", "head-icon", "head-text", "action-icon", "[desc-icon]", "[desc-text]", "[desc-desc]", "[desc-button]", "action-releases", "[form-body]", "[form-border]", "[form-more]"]);
export { FormFrameComponentNgFactory as FormFrameComponentNgFactory };
