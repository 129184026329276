import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-selectable-cell-frame',
  templateUrl: './selectable-cell-frame.component.html',
  styleUrls: ['./selectable-cell-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableCellFrameComponent implements OnInit {
  @Input() symbolColor: string;
  @Input() symbolImage: string;
  @Input() coverColor: string;
  @Input() coverPhoto: string;

  @Input() isSelected: boolean;
  @Input() selectorId: string;
  @Input() upcCode: string;
  @Input() title: string;
  @Input() desc: string;

  @Output() checkClick = new EventEmitter<boolean>(false);
  @Output() checkChanged = new EventEmitter<boolean>(false);

  constructor() {}

  ngOnInit() {}

  checkChangedEvent(e: any) {
    const val = e.currentTarget.checked;
    if (!val) {
      this.checkChanged.emit(true);
    } else {
      this.checkClick.emit(true);
    }
  }
}
