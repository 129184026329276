import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { RootStoreService } from 'src/app/store/root-store.service';
import { IAuth } from 'src/models/IAuth';
import { AuthStatus } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class SocketHandlerService {
  webSocketSubscription = null;
  constructor(
    private _router: Router,
    private _socketService: SocketService,
    private _rootStoreService: RootStoreService
  ) {
    this.webSocketSubscription = this._socketService.webSocketObservable$
      .pipe(distinctUntilChanged())
      .subscribe(skObj => {
        if (skObj) {
          skObj.onmessage = (evt: any) => this.onSocketMessage(evt.data);
        }
      });

    this._socketService.authStore$
      .pipe(
        distinctUntilChanged(
          (p: IAuth, q: IAuth) => p.authStatus === q.authStatus
        )
      )
      .subscribe(state => {
        switch (state.authStatus) {
          case AuthStatus.AUTH_QR_SET:
            this._router.navigate(['/login']);
            break;
          case AuthStatus.AUTH_CHAT_SELECTED:
            this._router.navigate(['/']);
            break;
        }
      });
  }

  private onSocketMessage(dataOnSocket: any) {
    this._socketService.setPingTimer();
    let data = '';
    if (environment.encodeSocket) {
      data = this.convertArrayBufferToMessage(dataOnSocket);
    } else {
      data = dataOnSocket;
    }
    this._rootStoreService.redirectMessage(JSON.parse(data));
  }

  convertArrayBufferToMessage(byteArray: Uint8Array) {
    return new (<any>window).TextDecoder().decode(byteArray);
  }
}
