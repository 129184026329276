import { Action } from '@ngrx/store';
import { IChatMember } from 'src/models/IChatMember';

export const enum MembersUIActionTypes {
  UI_MEMBERS_GET_NEXT_PAGE = 'UI_MEMBERS_GET_NEXT_PAGE',
  UI_MEMBERS_GET_PREV_PAGE = 'UI_MEMBERS_GET_PREV_PAGE',
  UI_MY_MEMBERS = 'UI_MY_MEMBERS',
  UI_MEMBERS_RECEIVED_HISTORY_CHUNK = 'UI_MEMBERS_RECEIVED_HISTORY_CHUNK',
  UI_MEMBERS_RESET_PAGING = 'UI_MEMBERS_RESET_PAGING',
  UI_MEMBERS_SET_IDLE_STATUS = 'UI_MEMBERS_SET_IDLE_STATUS',
  UI_REMOVE_MEMBERS_FROM_CHAT = 'UI_REMOVE_MEMBERS_FROM_CHAT',
  UI_BAN_MEMBER_FROM_CHAT = 'UI_BAN_MEMBER_FROM_CHAT',
  UI_UNBAN_MEMBER_FROM_CHAT = 'UI_UNBAN_MEMBER_FROM_CHAT',
  RESET = 'RESET'
}

// Paging Actions

export class GetMyMembers implements Action {
  readonly type = MembersUIActionTypes.UI_MY_MEMBERS;
  constructor() {}
}

export class GetNextMembersPage implements Action {
  readonly type = MembersUIActionTypes.UI_MEMBERS_GET_NEXT_PAGE;
  constructor() {}
}

export class GetPrevMembersPage implements Action {
  readonly type = MembersUIActionTypes.UI_MEMBERS_GET_PREV_PAGE;
  constructor() {}
}

export class ReceivedMembersHistoryChunk implements Action {
  readonly type = MembersUIActionTypes.UI_MEMBERS_RECEIVED_HISTORY_CHUNK;
  constructor(
    public eop: number,
    public sop: number,
    public currentPage: IChatMember[],
    public hash: string
  ) {}
}

export class ResetMembersPaging implements Action {
  readonly type = MembersUIActionTypes.UI_MEMBERS_RESET_PAGING;
  constructor(
    public name?: string,
    public searchBy?: number,
    public status?: any,
    public view?: number,
    public accountTypes?: string[]
  ) {}
}

export class SetStatusAsIdle implements Action {
  readonly type = MembersUIActionTypes.UI_MEMBERS_SET_IDLE_STATUS;
  constructor() {}
}

export class DeleteUser implements Action {
  readonly type = MembersUIActionTypes.UI_REMOVE_MEMBERS_FROM_CHAT;
  constructor(
    public userID: string,
    public chatID: string,
    public ref: string
  ) {}
}

export class BanUser implements Action {
  readonly type = MembersUIActionTypes.UI_BAN_MEMBER_FROM_CHAT;
  constructor(public userID: string, public chatID: string) {}
}

export class UnBanUser implements Action {
  readonly type = MembersUIActionTypes.UI_UNBAN_MEMBER_FROM_CHAT;
  constructor(public userID: string, public chatID: string) {}
}

export class Reset implements Action {
  readonly type = MembersUIActionTypes.RESET;
}

export type Actions =
  | GetMyMembers
  | GetNextMembersPage
  | GetPrevMembersPage
  | ReceivedMembersHistoryChunk
  | ResetMembersPaging
  | SetStatusAsIdle
  | DeleteUser
  | BanUser
  | UnBanUser
  | Reset;
