import { MainChat } from '../../../models/MainChat';
import * as MainChatActions from './mainChat.actions';

import { MessageSendingStatus, CHANNEL } from '../../../models/constants';

export function mainChatReducer(
  state: MainChat[] = [],
  action: MainChatActions.MainChatActions
): MainChat[] {
  switch (action.type) {
    case MainChatActions.MainChatActionTypes.MAIN_CHAT_RECEIVED: {
      const mainChat = action.payload;
      let updatedList = state;

      const groupsToBeUpdated: MainChat[] = state.filter(
        chatItem => mainChat.id === chatItem.lastSenderIDInGroup
      );
      if (groupsToBeUpdated && groupsToBeUpdated.length > 0) {
        updatedList = state.map(chatToBeUpdated => {
          if (
            groupsToBeUpdated.find(
              targetChat => targetChat.id === chatToBeUpdated.id
            )
          ) {
            const updatedChat: MainChat = {};
            updatedChat.lastSenderNameInGroup = mainChat.name;
            return { ...chatToBeUpdated, ...updatedChat };
          }
          return chatToBeUpdated;
        });
      }

      if (!isMainChatExisting(mainChat, state)) {
        if (state.length > 0) {
          return sort([mainChat, ...updatedList]);
        } else {
          return [mainChat];
        }
      } else {
        let needSort = false;
        const chatTobeUpdated: MainChat = state.find(
          chatItem => mainChat.id === chatItem.id
        );
        if (chatTobeUpdated.name !== mainChat.name) {
          needSort = true;
        }
        const result = updatedList.map(chat => {
          if (chat.id === mainChat.id) {
            let updatedChat: MainChat = {};
            updatedChat = mainChat;
            if (chat.backgroundColor) {
              updatedChat.backgroundColor = chat.backgroundColor;
            }
            return { ...chat, ...updatedChat };
          }
          return chat;
        });

        if (needSort) {
          return sort(result);
        }
        return result;
      }
    }
    case MainChatActions.MainChatActionTypes.MAIN_CHAT_DESELECTED:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: MainChat = {};
          updatedChat.lastTypedMessage = action.lastTypedMessage;
          updatedChat.selected = false;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.MAIN_CHAT_MEMBER_COUNT_RECEIVED:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: MainChat = {};
          updatedChat.memberCount = action.memberCount;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.LOGGED_IN_LEFT_MAIN_CHAT:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: MainChat = {};
          updatedChat.isAdmin = false;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.MAIN_CHAT_SELECTED:
      return state.map(chat => {
        if (chat.id === action.mainChat.id) {
          const updatedChat: MainChat = {};
          updatedChat.selected = true;
          updatedChat.unreadMessagesNumberOnSelection =
            chat.firstLevelUnreadCounter;
          updatedChat.unreadCounter = 0;
          updatedChat.firstLevelUnreadCounter = 0;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.MAIN_CHAT_HISTORY_REQUESTED:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: MainChat = {};
          updatedChat.isHistoryRequested = true;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.UPDATE_NUMBER_OF_UNREAD_MESSAGES:
      return sort(
        state.map(chat => {
          if (chat.id === action.id) {
            const updatedChat: MainChat = {};
            updatedChat.unreadCounter = action.unreadMessagesCount;
            if (action.isFirstLevelMessage) {
              if (!action.isRecalled) {
                updatedChat.firstLevelUnreadCounter = chat.firstLevelUnreadCounter
                  ? ++chat.firstLevelUnreadCounter
                  : 1;
              } else {
                updatedChat.firstLevelUnreadCounter = chat.firstLevelUnreadCounter
                  ? --chat.firstLevelUnreadCounter
                  : 0;
              }
            }
            return { ...chat, ...updatedChat };
          }
          return chat;
        })
      );
    case MainChatActions.MainChatActionTypes
      .INCREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES:
      return state.map(chat => {
        if (chat.id === action.mainChat.id) {
          const updatedChat: MainChat = {};
          updatedChat.notViewedCounter = chat.notViewedCounter
            ? ++chat.notViewedCounter
            : 1;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes
      .DECREMENT_NUMBER_OF_NOT_VIEWED_MESSAGES:
      return state.map(chat => {
        if (chat.id === action.mainChat.id) {
          const updatedChat: MainChat = {};
          updatedChat.notViewedCounter = chat.notViewedCounter
            ? --chat.notViewedCounter
            : 0;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.UPDATE_END_OF_PAGE:
      return state.map(chat => {
        if (chat.id === action.mainChatID) {
          const updatedChat: MainChat = {};
          if (action.endOfPage) {
            updatedChat.endOfPage = action.endOfPage;
          }
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.UPDATE_EOP_LEVEL1:
      return state.map(chat => {
        if (chat.id === action.mainChatID) {
          const updatedChat: MainChat = {};
          if (action.eopLevel1) {
            updatedChat.eopLevel1 = action.eopLevel1;
          }
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes
      .RESET_NUMBER_OF_NOT_VIEWED_MESSAGES:
      return state.map(chat => {
        if (chat.id === action.mainChatID) {
          const updatedChat: MainChat = {};
          updatedChat.notViewedCounter = 0;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.UPDATE_LAST_MESSAGE:
      return sort(
        state.map(chat => {
          if (chat.id === action.mainChat.id) {
            if (
              action.lastMessage &&
              ((chat.lastMsgTime &&
                action.lastMessage.lastReplyTime >= chat.lastMsgTime) ||
                !chat.lastMsgTime ||
                action.afterRecall)
            ) {
              const updatedChat: MainChat = {};
              updatedChat.lastReplyID = action.lastMessage.lastReplyID;
              updatedChat.lastReplyReference =
                action.lastMessage.lastReplyReference;
              updatedChat.lastMsgTime = action.lastMessage.lastReplyTime;
              updatedChat.lastReplyMessage =
                action.lastMessage.lastReplyMessage;
              updatedChat.lastReplyStatus = action.lastMessage.lastReplyStatus;
              updatedChat.lastReplyType = action.lastMessage.lastReplyType;
              if (action.mainChat.lastSenderIDInGroup) {
                updatedChat.lastSenderIDInGroup =
                  action.mainChat.lastSenderIDInGroup;
                updatedChat.lastSenderNameInGroup =
                  action.mainChat.lastSenderNameInGroup;
              } else {
                updatedChat.lastSenderNameInGroup = null;
              }
              return { ...chat, ...updatedChat };
            } else if (!action.lastMessage) {
              const updatedChat: MainChat = {};
              updatedChat.lastReplyID = null;
              updatedChat.lastReplyReference = null;
              updatedChat.lastMsgTime = null;
              updatedChat.lastReplyMessage = '';
              updatedChat.lastReplyStatus = null;
              updatedChat.lastReplyType = null;
              updatedChat.lastSenderIDInGroup = null;
              updatedChat.lastSenderNameInGroup = null;
              return { ...chat, ...updatedChat };
            }
          }
          return chat;
        })
      );
    case MainChatActions.MainChatActionTypes.UPDATE_LAST_MESSAGE_STATUS:
      return state.map(chat => {
        if (
          chat.id === action.id &&
          chat.lastReplyReference &&
          chat.lastReplyReference === action.reference &&
          chat.lastReplyStatus !== MessageSendingStatus.SEEN &&
          !(
            action.lastMessageStatus === MessageSendingStatus.SENT &&
            chat.lastReplyStatus === MessageSendingStatus.DELIVERED
          )
        ) {
          const updatedChat: MainChat = {};

          updatedChat.lastReplyStatus = action.lastMessageStatus;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.MainChatActionTypes.UPDATE_MAIN_CHAT_IMAGES:
      return state.map(chat => {
        if (chat.id === action.id) {
          const updatedChat: MainChat = {};
          updatedChat.image = action.imageUrl;
          updatedChat.imageThumbnail = action.imageUrl;
          return { ...chat, ...updatedChat };
        }
        return chat;
      });
    case MainChatActions.DESELECT_CHANNEL: {
      return state.filter(
        chat => chat.type === CHANNEL && chat.isUpgradedChannel
      );
    }
    case MainChatActions.RESET:
      return [];
    default:
      return state;
  }

  function isMainChatExisting(
    mainChat: MainChat,
    mainChatsList: MainChat[]
  ): boolean {
    const existingChat = mainChatsList.find(chat => chat.id === mainChat.id);
    if (existingChat) {
      return true;
    } else {
      return false;
    }
  }

  function sort(chats: MainChat[]): MainChat[] {
    if (chats.length > 1) {
      return chats.sort((a, b) => {
        const dateA = a.lastMsgTime ? a.lastMsgTime : 0;
        const dateB = b.lastMsgTime ? b.lastMsgTime : 0;
        const nameA = a.name ? a.name.toUpperCase().trim() : '';
        const nameB = b.name ? b.name.toUpperCase().trim() : '';
        const priorityA = a.priority
          ? a.unreadCounter && a.unreadCounter > 0
          : false;
        const priorityB = b.priority
          ? b.unreadCounter && b.unreadCounter > 0
          : false;
        const pinnedA = a.pinned_date ? true : false;
        const pinnedB = b.pinned_date ? true : false;
        const pinnedDateA = a.pinned_date ? a.pinned_date : 0;
        const pinnedDateB = b.pinned_date ? b.pinned_date : 0;

        return pinnedA && !pinnedB
          ? -1
          : pinnedB && !pinnedA
          ? 1
          : pinnedA && pinnedB
          ? pinnedDateA > pinnedDateB
            ? -1
            : 1
          : priorityA && !priorityB
          ? -1
          : !priorityA && priorityB
          ? 1
          : dateA > dateB
          ? -1
          : dateA < dateB
          ? 1
          : dateA === dateB
          ? nameA < nameB
            ? -1
            : nameA > nameB
            ? 1
            : a.id < b.id
            ? -1
            : 1
          : 0;
      });
    } else {
      return chats;
    }
  }
}
