import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import * as AuthActions from './auth.actions';

import { AuthDispatchers } from './auth.dispatchers';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MessageDispatchers } from '../messages/message.dispatchers';
import { SettingsService } from 'src/app/+merchant/+dashboard/settings/settings.service';

import * as CouponMethods from 'src/models/ICoupon';
import * as MenuMethods from 'src/models/IBotMenu';
import * as AuthMethods from 'src/models/IAuth';
import * as ChatMethods from 'src/models/IChat';
import * as TagMethods from 'src/models/ITag';
import * as PaymentMethods from 'src/models/IPayment';
import * as ChatMembersMethods from 'src/models/IChatMember';
import { AppState } from 'src/models/AppState';
import { CHANNEL, CHANNEL_APP_SEARCH_CATEGORIES } from 'src/models/constants';
import { PrivilegesName } from 'src/models/privileges';
import { RequestMyBots, GetBotTemplates } from 'src/models/IProfile';
import { SERVER_ERROR_MESSAGES } from 'src/models/server-errors.enum';
import { RequestAppChannelData } from 'src/models/ChannelAppConfig';
import { GetMapServices } from 'src/models/MapService';
import { GetMapRoutes } from 'src/models/MapRoute';
import { GetMapTrips } from 'src/models/MapTrip';
import { GetMapTripUsers } from 'src/models/MapTripUser';
import { MainChat } from 'src/models/MainChat';
import { GetAppsMethod } from 'src/models/MultipleApps';
import { GetAppStoreMethod } from 'src/models/AppPublishInfo';
import {
  GetMyPagesListRequest,
  GetInstantPagesRequest
} from 'src/models/MyPageConfig';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  reopenNewConnection = this.actions$.pipe(
    ofType(AuthActions.REOPEN_NEW_CONNECTION),
    map(p => {
      this._socketGateway.openNewConnection();
    })
  );

  @Effect({ dispatch: false })
  rcvdMultiLogin = this.actions$.pipe(
    ofType(AuthActions.RECIVE_MULTI_LOGIN),
    map(p => {
      this._socketGateway.closeCurrentSocket();
    })
  );

  @Effect({ dispatch: false })
  getChatData = this.actions$.pipe(
    ofType(AuthActions.GET_CHAT_DATA),
    map((action: AuthActions.GetChatData) => {
      this._socketGateway.sendSocketMessage(
        new ChatMethods.GetChatTemplate(action.chat_id)
      );
    })
  );

  @Effect({ dispatch: false })
  rcvdChatList = this.actions$.pipe(
    ofType(AuthActions.RECIVE_AUTH_CHAT_LIST),
    withLatestFrom(
      this.store$
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged())
    ),
    map(([val, chatID]) => {
      const action = val as AuthActions.ReciveAuthChatList;
      // IF user already login before on another channel
      if (chatID) {
        this._socketGateway.sendSocketMessage(
          new AuthMethods.SendSelectChat(chatID)
        );
      } else if (action.payload) {
        // Get Chat Info
        const upgradedChannels = action.payload.filter(
          chat => chat.type === CHANNEL
        );

        if (upgradedChannels.length === 1) {
          this._socketGateway.sendSocketMessage(
            new ChatMethods.GetChatTemplate(upgradedChannels[0].id)
          );
          // Select chat
          this._socketGateway.sendSocketMessage(
            new AuthMethods.SendSelectChat(upgradedChannels[0].id)
          );
        } else if (action.payload.length === 0) {
          this._authDispatcher.logOut();
        }
      }
    })
  );

  @Effect({ dispatch: false })
  tokenChangeRcvd = this.actions$.pipe(
    ofType(AuthActions.RECIVE_AUTH_TOKEN_CHANGE),
    withLatestFrom(
      this.store$
        .select(state => state.authReducer.url)
        .pipe(distinctUntilChanged())
    ),
    map(([val, url]) => {
      const action = <AuthActions.ReciveAuthTokenChange>val;
      if (url) {
        this._socketGateway.openSocketWithUrl(url);
      }
    })
  );

  @Effect({ dispatch: false })
  selectChat = this.actions$.pipe(
    ofType(AuthActions.SET_CHAT_SELECT),
    map((action: AuthActions.SetChatSelect) => {
      this._uiDispacthers.setAuthPrivilege([]);
      this._socketGateway.sendSocketMessage(
        new AuthMethods.SendSelectChat(action.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  requestAdminQR = this.actions$.pipe(
    ofType(AuthActions.REQUEST_ADMIN_QR),
    map((action: AuthActions.RequestAdminQR) => {
      this._socketGateway.sendSocketMessage(new AuthMethods.GetAdminQRCode());
    })
  );

  /* after select channel in merchant request another requests */
  @Effect({ dispatch: false })
  authChatSelect = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_AUTH_CHAT_SELECTED),
    withLatestFrom(
      this.store$
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this.store$
        .select(state => state.authReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats, iAuth]) => {
      const action = <AuthActions.ReceiveAuthChatSelected>val;
      if (iAuth.mode == 0 || iAuth.mode == 1) {
        this._route.navigate(['/app']);
      } else {
        this._route.navigate(['/']);
      }

      const selectedMainChat = mainChats.find(
        mainChat => mainChat.id === action.payload.chatId
      );
      if (!selectedMainChat) {
        this._socketGateway.sendSocketMessage(
          new ChatMethods.GetChatTemplate(action.payload.chatId)
        );
      }
      const selectedChat: MainChat = selectedMainChat
        ? selectedMainChat
        : { id: action.payload.chatId, type: CHANNEL, subChannel: true };

      // GetAuthPrivilege & Roles for all types of users
      this._socketGateway.sendSocketMessage(new AuthMethods.GetAuthPrivilege());
      this._socketGateway.sendSocketMessage(new ChatMembersMethods.GetRoles());
      // SetChat Selected;

      this._uiDispacthers.chatSelected(selectedChat);
    })
  );

  @Effect({ dispatch: false })
  receiveAuthPrivilege = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_AUTH_PRIVILEGE),
    withLatestFrom(
      this.store$
        .select(s => s.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <AuthActions.ReceiveAuthPrivilege>val;
      this._uiDispacthers.setAuthPrivilege(action.payload);
      if (!action.reference) {
        this.requestIfHasPrivilege(action.payload, selectedChat.isChannelApp);
      }
    })
  );

  @Effect({ dispatch: false })
  changePassword = this.actions$.pipe(
    ofType(AuthActions.CHANGE_PASSWORD),
    map((action: AuthActions.ChangePassword) => {
      this._socketGateway.sendSocketMessage(
        new AuthMethods.ChangePassword(
          action.payload.oldPassword,
          action.payload.newPassword,
          action.payload.confirmNewPassword
        )
      );
    })
  );

  @Effect({ dispatch: false })
  changePasswordSet = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_SET),
    map((action: AuthActions.ReceiveChangePasswordSet) => {
      this._authDispatcher.logOut();
    })
  );

  @Effect({ dispatch: false })
  changePasswordError = this.actions$.pipe(
    ofType(AuthActions.RECEIVE_CHANGE_PASSWORD_ERROR),
    map((action: AuthActions.ReceiveChangePasswordError) => {
      this._settingsService.setServerError(
        SERVER_ERROR_MESSAGES[action.payload]
      );
    })
  );

  constructor(
    private _route: Router,
    private store$: Store<AppState>,
    private actions$: Actions,
    private _socketGateway: SocketGateway,
    private _authDispatcher: AuthDispatchers,
    private _uiDispacthers: UIDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _settingsService: SettingsService
  ) {}

  private requestIfHasPrivilege(privileges: string[], isMain: boolean) {
    // TODO: change request location
    // get messages ( First load )
    if (privileges.indexOf(PrivilegesName.listmessage) > -1) {
      this._messageDispatchers.getLastPostsHistory();
      setTimeout(() => {
        this._messageDispatchers.getLastRepliesHistory();
      }, 5000);
      this._messageDispatchers.listAwardMessages();
      this._messageDispatchers.listScheduleMessages();
    }
    // get billing
    if (privileges.indexOf(PrivilegesName.listbilling) > -1) {
      this._socketGateway.sendSocketMessage(
        new PaymentMethods.GetPaymentInfo()
      );
    }
    // get Tags
    if (privileges.indexOf(PrivilegesName.listtag) > -1) {
      this._socketGateway.sendSocketMessage(new TagMethods.GetTags());
    }
    // get menus
    if (privileges.indexOf(PrivilegesName.listmenu) > -1) {
      // this._socketGateway.sendSocketMessage(new MenuMethods.ListMyMenu());
    }
    // get bots
    if (privileges.indexOf(PrivilegesName.listbot) > -1) {
      this._socketGateway.sendSocketMessage(new RequestMyBots());
      this._socketGateway.sendSocketMessage(new GetBotTemplates());
    }

    // if (privileges.indexOf(PrivilegesName.listAppChannelData) > -1) {
    const keys = [
      CHANNEL_APP_SEARCH_CATEGORIES.AREA,
      CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY,
      CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS
    ];
    this._socketGateway.sendSocketMessage(new RequestAppChannelData(keys));
    // }

    // Need to add detailed privileges for Map Feature
    if (privileges.indexOf(PrivilegesName.listmarker) > -1) {
      // this._socketGateway.sendSocketMessage(new GetMapServices());
      // this._socketGateway.sendSocketMessage(new GetMapRoutes());
      // this._socketGateway.sendSocketMessage(new GetMapMarkers());
      // this._socketGateway.sendSocketMessage(new GetMapTrips());
      // this._socketGateway.sendSocketMessage(new GetMapTripUsers());
    }

    if (privileges.indexOf(PrivilegesName.listapp) > -1) {
      this._socketGateway.sendSocketMessage(new GetAppsMethod());
      this._socketGateway.sendSocketMessage(new GetAppStoreMethod());
      this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
      this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
    }

    if (privileges.indexOf(PrivilegesName.listappchannel) > -1) {
      this._socketGateway.sendSocketMessage(new GetMyPagesListRequest());
      this._socketGateway.sendSocketMessage(new GetInstantPagesRequest());
    }
  }
}
