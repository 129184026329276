<!-- Not Forget Password -->
<ng-container *ngIf="!isPasswordForgot">
  <div class="login-form">
    <div class="login-form-header">
      Log In
    </div>

    <div class="login-form-title">
      Log in to&nbsp;
      <app-link-btn (btnClicked)="logWithQr()">
        <ng-container ngProjectAs="link-content-clicked">
          Manage
        </ng-container>
      </app-link-btn>
      &nbsp; your App
    </div>

    <form class="login-form-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            type="email"
            formControlName="email"
          />
          <app-icon-validation-errors
            *ngIf="email?.errors && email?.touched"
            matSuffix
            [fieldErrors]="email?.errors"
            [fieldName]="'Email'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          class="login-form-error"
          *ngIf="email?.errors && email?.touched"
          [fieldName]="'Email'"
          [fieldErrors]="email?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            type="password"
            formControlName="password"
          />
          <app-icon-validation-errors
            *ngIf="password?.errors && password?.touched"
            matSuffix
            [fieldErrors]="password?.errors"
            [fieldName]="'Password'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          class="login-form-error"
          *ngIf="password?.errors && password?.touched"
          [fieldName]="'Password'"
          [fieldErrors]="password?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-inline space-between form-remember">
        <app-reactive-checkbox-input [filedId]="'inputRem'" [control]="rem">
          Remember me
        </app-reactive-checkbox-input>
        <app-link-btn
          [isDefaultLink]="true"
          (btnClicked)="toggleForgotPasswordScreenVisibility(true)"
        >
          <ng-container ngProjectAs="link-content-clicked">
            Forgot your password?
          </ng-container>
        </app-link-btn>
      </div>

      <div class="form-submit">
        <app-border-btn next invert lg [type]="'submit'">
          Log In <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
        </app-border-btn>
      </div>
    </form>

    <div class="login-form-manage">
      New to nandbox?&nbsp;
      <app-link-btn [openSelf]="true" [href]="signupLink">
        <ng-container ngProjectAs="link-content">
          Sign Up
        </ng-container>
      </app-link-btn>
    </div>

    <div class="login-form-terms">
      By logging in, you agree to our
      <app-link-btn [href]="termsLink">
        <ng-container ngProjectAs="link-content">
          Terms of Use & Privacy Policy.
        </ng-container>
      </app-link-btn>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isPasswordForgot">
  <app-forgot-password
    (goBackNotify)="toggleForgotPasswordScreenVisibility(false)"
  >
  </app-forgot-password>
</ng-container>
