<ng-container>
  <svg class="svg-icon icon-icon">
    <use
      attr.xlink:href="./assets/svg/app-sections.svg#{{ section?.symbolImage }}"
    ></use>
  </svg>

  <div class="section-title">Empty !</div>
  <div class="section-desc">
    {{ section?.emptyDesc }}
  </div>

  <ng-content select="[create-new-btn]"></ng-content>
</ng-container>
