import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { HttpService } from '../services/http.service';
import { StorageService } from 'src/app/core/storage.service';
import { MessageDispatchers } from 'src/app/store/messages/message.dispatchers';
import { DOWNLOAD_API } from 'src/models/constants';

@Injectable()
export class DownloadGateway {
  private _filesBlobUrlSet = new Map();
  downloadSubscriptions: Subscription[] = [];

  constructor(
    private _httpService: HttpService,
    private _storageService: StorageService
  ) {}

  downloadWithProgress(fileId: string) {
    const url = `${this._storageService.getApiUrl(DOWNLOAD_API)}${fileId}`;
    return this._httpService.downloadWithProgress(url);
  }

  downloadMediaFile(
    fileId: string,
    type: string,
    ignoreCache?: boolean,
    messageId?: string,
    messageDispatchers?: MessageDispatchers
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const blobLoaded = this.getMediaFromBlobList(fileId);
      if (ignoreCache) {
        this.downloadMediaFromUrl(
          `${this._storageService.getApiUrl(DOWNLOAD_API)}${fileId}`,
          fileId,
          type,
          messageId,
          messageDispatchers
        )
          .then(image => resolve(image))
          .catch(downloadError => reject(downloadError));
      } else if (!ignoreCache) {
        if (blobLoaded) {
          resolve(blobLoaded);
        } else {
          this._storageService
            .getMediaWithKey(fileId)
            .then(res => {
              let blobUrl: string;
              if (res) {
                // get media from cash
                blobUrl = window.URL.createObjectURL(res);
                this._filesBlobUrlSet.set(fileId, blobUrl);
                resolve(blobUrl);
              } else {
                // media not found on cash get it from server
                this.downloadMediaFromUrl(
                  `${this._storageService.getApiUrl(DOWNLOAD_API)}${fileId}`,
                  fileId,
                  type,
                  messageId,
                  messageDispatchers
                )
                  .then(image => resolve(image))
                  .catch(downloadError => reject(downloadError));
              }
            })
            .catch(err => {
              this.downloadMediaFromUrl(
                `${this._storageService.getApiUrl(DOWNLOAD_API)}${fileId}`,
                fileId,
                type,
                messageId,
                messageDispatchers
              )
                .then(image => resolve(image))
                .catch(downloadError => reject(downloadError));
            });
        }
      }
    });
  }

  private getMediaFromBlobList(fileId: string) {
    if (this._filesBlobUrlSet.has(fileId)) {
      return this._filesBlobUrlSet.get(fileId);
    }
    return false;
  }

  private downloadMediaFromUrl(
    url: string,
    fileId: string,
    type: string,
    messageId?: string,
    _messageDispatchers?: MessageDispatchers
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let progressMonitor = 0;
      const tempSub = this._httpService.downloadWithProgress(url).subscribe(
        event => {
          if (event.type === HttpEventType.DownloadProgress) {
            if (messageId) {
              const progressVal = Math.round(
                (event.loaded / event.total) * 100
              );
              if (progressVal - progressMonitor >= 18 || progressVal === 100) {
                progressMonitor = progressVal;
                _messageDispatchers.updateLoadingProgress(
                  { message_id: messageId },
                  progressVal
                );
              }
            }
          } else if (event.type === HttpEventType.Response) {
            const data = new Blob([event.body], { type });
            this._storageService.setMediaWithKey(fileId, data);
            const blobUrl = window.URL.createObjectURL(data);
            if (messageId) {
              delete this.downloadSubscriptions[messageId];
            }
            resolve(blobUrl);
          }
        },
        error => reject(error)
      );
      if (messageId) {
        this.downloadSubscriptions[messageId] = tempSub;
      }
    });
  }

  cancelDownload(messageId: string) {
    if (this.downloadSubscriptions[messageId]) {
      this.downloadSubscriptions[messageId].unsubscribe();
      delete this.downloadSubscriptions[messageId];
    }
  }
}
