import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as CouponUIActions from './couponUI.actions';

import { AppState } from 'src/models/AppState';
import { ICoupon } from 'src/models/ICoupon';

@Injectable({
  providedIn: 'root'
})
export class CouponUIDispatchers {
  constructor(private _store: Store<AppState>) {}

  couponListUI() {
    this._store.dispatch(new CouponUIActions.ListCouponUI());
  }
  createCouponUI() {
    this._store.dispatch(new CouponUIActions.CreateCouponUI());
  }
  viewCouponUI(selectedCoupon: ICoupon) {
    this._store.dispatch(new CouponUIActions.ViewCouponUI(selectedCoupon));
  }
  editCouponUI(selectedCoupon: ICoupon) {
    this._store.dispatch(new CouponUIActions.EditCouponUI(selectedCoupon));
  }
  closeCreateCouponUI() {
    this._store.dispatch(new CouponUIActions.CloseCreateCouponUI());
  }
  closeViewCouponUI() {
    this._store.dispatch(new CouponUIActions.CloseViewCouponUI());
  }
  closeEditCouponUI() {
    this._store.dispatch(new CouponUIActions.CloseEditCouponUI());
  }
  getNextCouponPage() {
    this._store.dispatch(new CouponUIActions.GetNextCouponPage());
  }
  getPrevCouponPage() {
    this._store.dispatch(new CouponUIActions.GetPrevCouponPage());
  }
  receivedCouponHistoryChunk(eop: number, sop: number, currentPage: string[]) {
    this._store.dispatch(
      new CouponUIActions.ReceivedCouponHistoryChunk(eop, sop, currentPage)
    );
  }
  resetCouponPaging(status?: string, expire?: string) {
    this._store.dispatch(new CouponUIActions.ResetCouponPaging(status, expire));
  }
  setStatusAsIdle() {
    this._store.dispatch(new CouponUIActions.SetStatusAsIdle());
  }
  reset() {
    this._store.dispatch(new CouponUIActions.Reset());
  }
}
