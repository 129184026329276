<div class="message-info"># {{ parentId | slice: -3 }}</div>
<div class="fill-space"></div>

<div class="btn-group" role="group">
  <i
    *ngIf="isNested"
    class="fas fa-chevron-left clickable-cursor"
    (click)="backEvent()"
    aria-hidden="true"
  >
  </i>

  <i
    class="fas fa-times close clickable-cursor"
    aria-hidden="true"
    (click)="closeEvent()"
  >
  </i>
</div>
