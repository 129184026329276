<div class="audio-container" [class.voice]="type === 'voice'">
  <!-- avtar section -->
  <div class="avtar">
    <ng-container *ngIf="type === 'voice'">
      <ng-content></ng-content>
    </ng-container>

    <div class="audio-avtar" *ngIf="type === 'audio'">
      <i class="fas fa-music"></i>
    </div>

    <span class="oval">
      <i *ngIf="type === 'voice'" class="fas fa-microphone layer"></i>
      <i *ngIf="type === 'audio'" class="fas fa-music layer"></i>
    </span>
  </div>

  <!-- core section -->
  <div class="core truncate-text">
    <div class="header">
      <div class="head clickable-cursor">
        <!-- in case of uploading and want to cancel -->

        <svg-icon
          *ngIf="
            mediaStatus === messageMediaStatus.DOWNLOADING ||
            mediaStatus === messageMediaStatus.UPLOADING
          "
          src="./assets/img/close-white.svg"
          [stretch]="true"
          [svgStyle]="{ 'width.px': 10, fill: '#C0C0C0' }"
          (click)="cancelEvent()"
        >
        </svg-icon>

        <div
          *ngIf="
            (mediaStatus === messageMediaStatus.DOWNLOADING ||
              mediaStatus === messageMediaStatus.UPLOADING) &&
            !percent
          "
          class="loader-spiner"
          [ngStyle]="theme"
          (click)="cancelEvent()"
        ></div>
        <circle-progress
          *ngIf="
            (mediaStatus === messageMediaStatus.DOWNLOADING ||
              mediaStatus === messageMediaStatus.UPLOADING) &&
            percent
          "
          class="loader-progress"
          [radius]="12"
          [percent]="percent"
          [outerStrokeColor]="mainThemeColor"
          (click)="cancelEvent()"
        ></circle-progress>

        <!-- in cse of FAILED and want to redownload or upload -->
        <i
          *ngIf="mediaStatus === messageMediaStatus.UPLOAD_FAILED"
          class="fas fa-long-arrow-alt-up"
          aria-hidden="true"
          (click)="retryEvent()"
        ></i>
        <i
          *ngIf="mediaStatus === messageMediaStatus.DOWNLOAD_FAILED"
          class="fas fa-long-arrow-alt-down"
          aria-hidden="true"
          (click)="retryEvent()"
        ></i>

        <!-- in case of media and isPause -->
        <i
          *ngIf="
            isPause &&
            localMedia &&
            (mediaStatus === messageMediaStatus.DOWNLOADED ||
              mediaStatus === messageMediaStatus.UPLOADED)
          "
          class="fas fa-play"
          aria-hidden="true"
          (click)="playAudio()"
        ></i>

        <!-- in case of media and not Pause -->
        <i
          *ngIf="
            !isPause &&
            localMedia &&
            (mediaStatus === messageMediaStatus.DOWNLOADED ||
              mediaStatus === messageMediaStatus.UPLOADED)
          "
          class="fas fa-pause"
          aria-hidden="true"
          (click)="pauseAudio()"
        ></i>
      </div>
      <div class="body truncate-text">
        <span *ngIf="type === 'audio'" class="title truncate-text" dir="auto">
          {{ title }}
        </span>
        <span *ngIf="type === 'audio'" class="desc" dir="auto"
          >{{ performer }}
        </span>
      </div>
      <div class="tail" [class.voice]="type === 'voice'">
        <!-- disply mediaDuration in case of paused -->
        <span
          *ngIf="
            currentValue === 0 &&
            localMedia &&
            (mediaStatus === messageMediaStatus.DOWNLOADED ||
              mediaStatus === messageMediaStatus.UPLOADED)
          "
        >
          {{ mediaDuration | mediaDuration }}
        </span>
        <!-- disply currentTime in case of played -->
        <span
          *ngIf="
            currentValue !== 0 &&
            localMedia &&
            (mediaStatus === messageMediaStatus.DOWNLOADED ||
              mediaStatus === messageMediaStatus.UPLOADED)
          "
        >
          -{{ -currentTime | mediaDuration }}
        </span>
        <!-- display media file szie in case of upload or download -->
        <span
          *ngIf="
            mediaStatus !== messageMediaStatus.DOWNLOADED &&
            mediaStatus !== messageMediaStatus.UPLOADED
          "
        >
          {{ fileSize | fileSize }}
        </span>
      </div>
    </div>
    <div class="footer" [class.voice]="type === 'voice'">
      <input
        class="range-slider__range"
        type="range"
        [value]="currentValue"
        [max]="1"
        step="any"
        #range
        (change)="seek(range.value)"
        [ngStyle]="audioSekeer"
      />
    </div>
  </div>
</div>

<app-text-cell
  *ngIf="caption"
  class="caption-box wrap-text copyable-item text-cursor"
  dir="auto"
  [text]="caption"
>
</app-text-cell>

<audio
  *ngIf="localMedia"
  (play)="audioPlayNow()"
  (pause)="pauseAudio()"
  (ended)="audioEnded()"
  (timeupdate)="onTimeUpdate()"
  #audioPlayer
>
  <source [src]="media" />
</audio>
