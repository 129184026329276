<div class="form-selector-input">
  <div class="form-selector-create">
    <label class="form-selector-label" id="createLabel" (click)="addnewEvent()">
      <img src="./assets/img/add-new.svg" class="form-selector-add" />
      <span>Add new</span>
      <!-- input it self maybe file or btn-->
      <div id="createLabel">
        <ng-content select="[form-selector-create-input]"></ng-content>
      </div>
    </label>
    <div class="form-selector-text">
      <img src="./assets/img/add-info.svg" alt="" class="form-selector-info" />
      <ng-content select="[form-selector-create-text]"></ng-content>
    </div>
  </div>
</div>
