import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AbstractBtnsModule } from '../abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from '../abstract-frames/abstract-frames.module';

import { PopupNotifierComponent } from './popup-notifier/popup-notifier.component';
import { SnackbarNotifierComponent } from './snackbar-notifier/snackbar-notifier.component';
import { SnackbaarErrorNotifierComponent } from './snackbaar-error-notifier/snackbaar-error-notifier.component';

@NgModule({
  imports: [CommonModule, AbstractBtnsModule, AbstractFramesModule],
  declarations: [
    SnackbarNotifierComponent,
    PopupNotifierComponent,
    SnackbaarErrorNotifierComponent
  ],
  exports: [PopupNotifierComponent, SnackbaarErrorNotifierComponent],
  entryComponents: [SnackbarNotifierComponent]
})
export class AbstractNotifiersModule {}
