import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { ITag } from 'src/models/ITag';

@Component({
  selector: 'app-assign-tags',
  templateUrl: './assign-tags.component.html',
  styleUrls: ['./assign-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignTagsComponent {
  @Input() assignTags: ITag[];
  @Input() showLabel = true;

  @Output() removeTagNotfiy = new EventEmitter<string>();

  removeTagEvent(tag: ITag) {
    this.removeTagNotfiy.emit(tag.id);
  }
}
