/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/pipes/twemoji/twemoji.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../shared/pipes/multi-line/multi-line.pipe";
import * as i5 from "../../shared/pipes/keep-html/keep-html.pipe";
import * as i6 from "./text-cell.component";
var styles_TextCellComponent = [i0.styles];
var RenderType_TextCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextCellComponent, data: {} });
export { RenderType_TextCellComponent as RenderType_TextCellComponent };
export function View_TextCellComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.TwemojiPipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.MultiLinePipe, []), i1.ɵpid(0, i5.KeepHtmlPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), i1.ɵppd(5, 1), i1.ɵppd(6, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 2), _co.text)))))); _ck(_v, 3, 0, currVal_0); }); }
export function View_TextCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-cell", [], null, null, null, View_TextCellComponent_0, RenderType_TextCellComponent)), i1.ɵdid(1, 49152, null, 0, i6.TextCellComponent, [], null, null)], null, null); }
var TextCellComponentNgFactory = i1.ɵccf("app-text-cell", i6.TextCellComponent, View_TextCellComponent_Host_0, { text: "text" }, {}, []);
export { TextCellComponentNgFactory as TextCellComponentNgFactory };
