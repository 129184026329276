import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from './storage.service';
import { RootStoreSelectors } from '../store/root-store.selectors';

import { AuthDispatchers } from 'src/app/store/auth/auth.dispatchers';
import { AuthGateway } from 'src/app/network/gateway/auth.gateway';

import * as AuthMethods from 'src/models/IAuth';
import { TOKEN_LOCAL_STORAGE_KEY } from 'src/models/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private _authGateway: AuthGateway,
    private _selectors: RootStoreSelectors,
    private _authDispatchers: AuthDispatchers,
    private _storageService: StorageService
  ) {
    // this.authCollection$.subscribe(console.log);
  }

  reopenNewConnection() {
    this._authDispatchers.reopenNewConnection();
  }

  /** Auth dispacters actions on store  */
  get authCollection$(): Observable<AuthMethods.IAuth> {
    return this._selectors.authCollection$;
  }
  get authRem(): boolean {
    return this._authDispatchers.authRem;
  }
  get token(): string {
    return this._storageService.getRecord(TOKEN_LOCAL_STORAGE_KEY);
  }

  setRem(rem: boolean) {
    this._authDispatchers.setAuthRem(rem);
  }
  logout() {
    this._authDispatchers.logOut();
  }

  selectChat(chatId: string) {
    this._authDispatchers.setChatSelect(chatId);
  }

  updateProgressWeb(no: string, mode: number) {
    this._authDispatchers.setAppProgress(no, mode);
  }
  updateWebMode(no: number) {
    this._authDispatchers.setAppMode(no);
  }
  getCountryISO() {
    return this._authDispatchers.getCountryData();
  }
  emailTacSuccess(form: AuthMethods.AuthForm) {
    this._authDispatchers.emailTacSuccess(
      form.email,
      form.appName,
      form.name,
      form.password
    );
  }
  reciveAuthToken(token: string) {
    const auth: AuthMethods.IAuth = {};
    auth.token = token;
    this._authDispatchers.reciveAuthToken(auth);
  }
  createAccountSuccess(password: string) {
    this._authDispatchers.createAccountSuccess(password);
  }
  selectTemplate(templateID: string) {
    this._authDispatchers.selectTemplate(templateID);
  }
  loginUseEmail() {
    this._authDispatchers.loginByEmail();
  }
  requestAdminQR() {
    this._authDispatchers.requestAdminQR();
  }

  // Auth Gateway //
  loginByEmail(form: AuthMethods.AuthForm) {
    return this._authGateway.loginForm(form);
  }
  createAccount(form: AuthMethods.AuthForm, tac: string) {
    return this._authGateway.tacForm(form, tac);
  }
  getEmailTac(form: AuthMethods.AuthForm) {
    return this._authGateway.signUpForm(form);
  }
  getResetPasswordTac(email: string) {
    return this._authGateway.getResetPasswordTac(email);
  }
  resetPassword(
    email: string,
    tac: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    return this._authGateway.resetPassword(
      email,
      tac,
      newPassword,
      confirmNewPassword
    );
  }
  getTemplatesByCategory(category: string) {
    return this._authGateway.getTemplatesByCategory(category);
  }
  getTemplateById(id: string) {
    return this._authGateway.getTemplatesByCategory(null, id);
  }

  getChatData(chat_id) {
    this._authDispatchers.getChatData(chat_id);
  }
}
