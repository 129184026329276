import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UISections } from 'src/models/constants';
import { ISection } from 'src/models/ISection';

@Component({
  selector: 'app-empty-section',
  templateUrl: './empty-section.component.html',
  styleUrls: ['./empty-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptySectionComponent {
  @Input() section: ISection;
  uiSections: typeof UISections = UISections;

  constructor() {}

  get fillColor() {
    return {
      fill: '#949494',
      width: '60px',
      height: '60px'
    };
  }
}
