import { Actions } from './container.actions';
import * as actions from './container.actions';
import { LocalContainer } from 'src/models/ChannelAppLocalConfig';
import { POST_TAG_TYPE, ADMIN_TAG_TYPE } from 'src/models/constants';

const INITIAL_STATE = [];

export function containerReducer(
  state: LocalContainer[] = INITIAL_STATE,
  action: Actions
): LocalContainer[] {
  switch (action.type) {
    case actions.ContainerActionTypes.APP_CONFIG_DATA_CHANGED: {
      return [
        ...action.onlineAppConfig.containers,
        ...action.offlineAppConfig.containers
      ];
    }
    case actions.ContainerActionTypes.UPDATE_CONTAINER: {
      return state.map(container => {
        if (container.ref === action.containerRef) {
          let updatedContainer: LocalContainer = {};
          updatedContainer = { ...container, ...action.containerUpdatedProps };
          return updatedContainer;
        }
        return container;
      });
    }
    case actions.ContainerActionTypes.REORDER_CONTAINER: {
      const targetContainer = state.find(
        container => container.ref === action.containerRef
      );
      const oldPosition = targetContainer ? targetContainer.order : null;
      const parentRef = targetContainer ? targetContainer.parentClassRef : null;
      const parentTabRef = targetContainer
        ? targetContainer.parentTabRef
        : null;
      return state.map(container => {
        if (container.ref === action.containerRef) {
          let updatedContainer: LocalContainer = {};
          updatedContainer = { ...container, order: action.newPosition };
          return updatedContainer;
        } else if (
          container.parentClassRef === parentRef &&
          ((!parentTabRef && !container.parentTabRef) ||
            (parentTabRef && parentTabRef === container.parentTabRef)) &&
          oldPosition !== null &&
          container.order >= 0
        ) {
          if (
            action.newPosition < oldPosition &&
            container.order < oldPosition &&
            container.order >= action.newPosition
          ) {
            // MOVING_UP
            let updatedContainer: LocalContainer = {};
            updatedContainer = { ...container, order: container.order + 1 };
            return updatedContainer;
          } else if (
            action.newPosition > oldPosition &&
            container.order > oldPosition &&
            container.order <= action.newPosition
          ) {
            // MOVING_DOWN
            let updatedContainer: LocalContainer = {};
            updatedContainer = {
              ...container,
              order: container.order !== 0 ? container.order - 1 : 0
            };
            return updatedContainer;
          }
        }
        return container;
      });
    }
    case actions.ContainerActionTypes.DELETE_CONTAINER: {
      const orderOfDeletedContainer = action.container.order;
      const parentRef = action.container.parentClassRef;
      const parentTabRef = action.container
        ? action.container.parentTabRef
        : null;
      const remainingList = state.filter(
        container => container.ref !== action.container.ref
      );
      if (remainingList) {
        return remainingList.map(container => {
          if (
            container.parentClassRef === parentRef &&
            ((!parentTabRef && !container.parentTabRef) ||
              (parentTabRef && parentTabRef === container.parentTabRef)) &&
            (orderOfDeletedContainer !== null &&
              orderOfDeletedContainer !== undefined) &&
            container.order >= 0
          ) {
            if (container.order > orderOfDeletedContainer) {
              let updatedContainer: LocalContainer = {};
              updatedContainer = { ...container, order: container.order - 1 };
              return updatedContainer;
            }
          }
          return container;
        });
      } else {
        return INITIAL_STATE;
      }
    }
    case actions.ContainerActionTypes.ADD_CONTAINER: {
      return [action.container, ...state];
    }
    case actions.ContainerActionTypes.SELECT_CONTAINER: {
      const parentTabRef = action.selectedContainer
        ? action.selectedContainer.parentTabRef
        : null;
      return state.map(container => {
        if (
          action.selectedContainer &&
          container.ref === action.selectedContainer.ref
        ) {
          let updatedContainer: LocalContainer = {};
          updatedContainer = { ...container, selected: true };
          return updatedContainer;
        } else if (
          action.selectedContainer &&
          container.parentClassRef ===
            action.selectedContainer.parentClassRef &&
          ((!parentTabRef && !container.parentTabRef) ||
            (parentTabRef && parentTabRef === container.parentTabRef))
        ) {
          // This to update the siblings of the selected container
          let updatedContainer: LocalContainer = {};
          updatedContainer = { ...container, selected: false };
          return updatedContainer;
        }
        return container;
      });
    }
    case actions.ContainerActionTypes.UPDATE_TAB_TYPE: {
      return state.map(container => {
        if (
          container.ref === action.containerRef &&
          container.type !== action.tabType
        ) {
          let updatedContainer: LocalContainer = {};
          const newLocalContainer: LocalContainer = {};
          newLocalContainer.type = action.tabType;
          newLocalContainer.id = action.tabID;
          newLocalContainer.limit = null;
          newLocalContainer.map = null;
          newLocalContainer.map_menu_id = null;
          newLocalContainer.options = '0';
          newLocalContainer.google_sync = 0;
          newLocalContainer.view_style = 0;
          newLocalContainer.is_on = 0;
          newLocalContainer.chat_id = null;
          newLocalContainer.sub_type = null;
          newLocalContainer.image_url = null;
          newLocalContainer.video_url = null;
          newLocalContainer.desc = null;
          newLocalContainer.message = null;
          updatedContainer = { ...container, ...newLocalContainer };
          return updatedContainer;
        }
        return container;
      });
    }
    case actions.ContainerActionTypes.RECEIVE_TAG_VALUES: {
      const rcvdPostTags = action.tabsTags.filter(
        tag => tag.type === POST_TAG_TYPE
      );
      const rcvdAdminTags = action.tabsTags.filter(
        tag => tag.type === ADMIN_TAG_TYPE
      );
      return state.map(container => {
        let updatedContainer = container;
        const currentPostTag = rcvdPostTags.find(
          tag => tag.id === container.ref
        );
        const currentAdminTag = rcvdAdminTags.find(
          tag => tag.id === container.ref
        );
        if (currentPostTag) {
          const newLocalContainer: LocalContainer = {};
          newLocalContainer.post_tag = currentPostTag.value;
          updatedContainer = { ...updatedContainer, ...newLocalContainer };
        }
        if (currentAdminTag) {
          const newLocalContainer: LocalContainer = {};
          newLocalContainer.talk_admin_tag = currentAdminTag.value;
          updatedContainer = { ...updatedContainer, ...newLocalContainer };
        }
        return updatedContainer;
      });
    }
    case actions.ContainerActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
