/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component.ngfactory";
import * as i2 from "../../../../abstract-frames/selectable-cell-frame/selectable-cell-frame.component";
import * as i3 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component.ngfactory";
import * as i4 from "../../../../abstract-btns/round-paging-btn/round-paging-btn.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i7 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i8 from "../../../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i9 from "../../../../abstract-btns/border-btn/border-btn.component";
import * as i10 from "../../../../abstract-frames/selector-frame/selector-frame.component.ngfactory";
import * as i11 from "../../../../abstract-frames/selector-frame/selector-frame.component";
import * as i12 from "./menus-pool.component";
import * as i13 from "../../menus/menus.service";
var styles_MenusPoolComponent = [];
var RenderType_MenusPoolComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenusPoolComponent, data: {} });
export { RenderType_MenusPoolComponent as RenderType_MenusPoolComponent };
function View_MenusPoolComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-selectable-cell-frame", [], null, [[null, "checkClick"], [null, "checkChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("checkClick" === en)) {
        var pd_0 = (_co.setSelectMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("checkChanged" === en)) {
        var pd_1 = (_co.resetSelectMessage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SelectableCellFrameComponent_0, i1.RenderType_SelectableCellFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.SelectableCellFrameComponent, [], { symbolColor: [0, "symbolColor"], symbolImage: [1, "symbolImage"], coverColor: [2, "coverColor"], isSelected: [3, "isSelected"], selectorId: [4, "selectorId"], title: [5, "title"] }, { checkClick: "checkClick", checkChanged: "checkChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.menuFet == null) ? null : _co.menuFet.symbolColor); var currVal_1 = ((_co.menuFet == null) ? null : _co.menuFet.symbolImage); var currVal_2 = ((_co.menuFet == null) ? null : _co.menuFet.coverColor); var currVal_3 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.id) === ((_co.currentSelectMessage == null) ? null : _co.currentSelectMessage.id)); var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.menu_name); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_MenusPoolComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-round-paging-btn", [["class", "paging-float-btn"]], null, [[null, "nextBtnClicked"], [null, "previousBtnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nextBtnClicked" === en)) {
        var pd_0 = (_co.menusService.getNextPage() !== false);
        ad = (pd_0 && ad);
    } if (("previousBtnClicked" === en)) {
        var pd_1 = (_co.menusService.getPrevPage() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_RoundPagingBtnComponent_0, i3.RenderType_RoundPagingBtnComponent)), i0.ɵdid(2, 49152, null, 0, i4.RoundPagingBtnComponent, [], null, { nextBtnClicked: "nextBtnClicked", previousBtnClicked: "previousBtnClicked" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenusPoolComponent_3)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = _co.TrackByFunction; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_MenusPoolComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenusPoolComponent_2)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.ngIf == null) ? null : _v.context.ngIf.length) > 0); var currVal_1 = i0.ɵnov(_v.parent, 8); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MenusPoolComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i6.View_EmptyItemFrameComponent_0, i6.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i7.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.menuFet == null) ? null : _co.menuFet.symbolImage); var currVal_1 = ((_co.menuFet == null) ? null : _co.menuFet.emptyTitle); var currVal_2 = ((_co.menuFet == null) ? null : _co.menuFet.emptyPoolDesc); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_MenusPoolComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-border-btn", [["submit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.insertEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_BorderBtnComponent_0, i8.RenderType_BorderBtnComponent)), i0.ɵdid(1, 573440, null, 0, i9.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵted(-1, 0, [" Insert "]))], null, null); }
export function View_MenusPoolComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "app-selector-frame", [], null, [[null, "closeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotify" === en)) {
        var pd_0 = (_co.cancelEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_SelectorFrameComponent_0, i10.RenderType_SelectorFrameComponent)), i0.ɵdid(1, 114688, null, 0, i11.SelectorFrameComponent, [], null, { closeNotify: "closeNotify" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Select Poll "])), (_l()(), i0.ɵeld(4, 0, null, 7, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenusPoolComponent_1)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isEmpty", 2]], null, 0, null, View_MenusPoolComponent_4)), (_l()(), i0.ɵeld(9, 0, null, 10, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "form-submit"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenusPoolComponent_5)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.menusService.botMenus$)); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.currentSelectMessage; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_MenusPoolComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menus-pool", [], null, null, null, View_MenusPoolComponent_0, RenderType_MenusPoolComponent)), i0.ɵdid(1, 49152, null, 0, i12.MenusPoolComponent, [i13.MenusService], null, null)], null, null); }
var MenusPoolComponentNgFactory = i0.ɵccf("app-menus-pool", i12.MenusPoolComponent, View_MenusPoolComponent_Host_0, {}, { cancelNotfiy: "cancelNotfiy", selectMenuNotfiy: "selectMenuNotfiy" }, []);
export { MenusPoolComponentNgFactory as MenusPoolComponentNgFactory };
