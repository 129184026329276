<ng-container *ngIf="selectedChatTags$ | async as chatTags">
  <table
    *ngIf="chatTags && chatTags?.length > 0; else isEmpty"
    class="table-container"
    role="table"
  >
    <thead>
      <tr class="flex-table header" role="rowgroup" style="padding: 5px 15px;">
        <th role="columnheader" class="md">Tag Name</th>
        <th role="columnheader" class="lg">Description</th>
        <th role="columnheader" class="sm"></th>
      </tr>
    </thead>
    <tbody style="background-color: #eef3f9; padding: 5px;">
      <tr
        *ngFor="
          let tag of chatTags | search: searchTerm:'name';
          let i = index;
          trackBy: TrackByFunction
        "
        class="flex-table row"
        role="rowgroup"
        style="background-color: #fff; padding: 5px 10px; border: none;"
      >
        <td role="cell" class="md truncate-text">
          <span>
            {{ tag?.name }}
          </span>
        </td>
        <td role="cell" class="lg truncate-text">
          <span> {{ tag?.description }} </span>
        </td>
        <td role="cell" class="sm action-cell">
          <ng-template
            [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{ $implicit: tag, index: i }"
          >
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<!-- No Items to show -->
<ng-template #isEmpty>
  <app-empty-item-frame
    [emptySymbol]="tagsFet?.symbolImage"
    [emptyTitle]="tagsFet?.emptyTitle"
    [emptyDesc]="tagsFet?.emptyPoolDesc"
    [noBg]="true"
  >
  </app-empty-item-frame>
</ng-template>
