import { MapDriverActions } from './mapTripDriver.actions';

import * as actions from './mapTripDriver.actions';

import { MapDriverUser } from 'src/models/MapDriver';

const INITIAL_STATE: MapDriverUser = {
  ids: []
};

export function mapDriverReducer(
  state = INITIAL_STATE,
  action: MapDriverActions
) {
  switch (action.type) {
    case actions.MapDriverActionTypes.MAP_TRIPS_DRIVER_RECEIVED:
      state = {
        ...state,
        ids: action.payload
      };

      return state;

    case actions.MapDriverActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
