/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-info.component";
import * as i3 from "../../+dashboard/app-mgmt/appMgmt.service";
var styles_ConfirmInfoComponent = [i0.styles];
var RenderType_ConfirmInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmInfoComponent, data: {} });
export { RenderType_ConfirmInfoComponent as RenderType_ConfirmInfoComponent };
export function View_ConfirmInfoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "confirm-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "confirm-info-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "confirm-info-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag & Drop the Modules to Install Them!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Install up to four modules on your home menu and as many modules as you want in the side menu! "])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "confirm-info-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "dont-show"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "input", [["class", "check-checkbox"], ["id", "dontShow"], ["type", "checkbox"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onCheck($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [["for", "dontShow"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Don't show again"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Let's Start"]))], null, null); }
export function View_ConfirmInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-info", [], null, null, null, View_ConfirmInfoComponent_0, RenderType_ConfirmInfoComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConfirmInfoComponent, [i3.AppMgmtService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmInfoComponentNgFactory = i1.ɵccf("app-confirm-info", i2.ConfirmInfoComponent, View_ConfirmInfoComponent_Host_0, {}, { closeNotifier: "closeNotifier" }, []);
export { ConfirmInfoComponentNgFactory as ConfirmInfoComponentNgFactory };
