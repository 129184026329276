import { Store } from '@ngrx/store';
import * as actions from './appPublishing.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class AppPublishingDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    setAppStoreInfo(appPublishingInfo) {
        this._store.dispatch(new actions.SetAppStoreInfo(appPublishingInfo));
    }
    receiveAppStoreInfo(appPublishingInfo) {
        this._store.dispatch(new actions.ReceiveAppStoreInfo(appPublishingInfo));
    }
    requestAppTesters() {
        this._store.dispatch(new actions.RequestAppTesters());
    }
    receivePublishInfoError(errorNumber) {
        this._store.dispatch(new actions.ReceivePublishInfoError(errorNumber));
    }
    receiveErrorCode(errorNumber) {
        this._store.dispatch(new actions.ReceiveErrorCode(errorNumber));
    }
    requestAppStoreInfo(appID) {
        this._store.dispatch(new actions.RequestAppStoreInfo(appID));
    }
}
AppPublishingDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppPublishingDispatchers_Factory() { return new AppPublishingDispatchers(i0.ɵɵinject(i1.Store)); }, token: AppPublishingDispatchers, providedIn: "root" });
