<div class="tabs-container">
  <div
    *ngIf="!isPhotoMedia"
    class="tab"
    (click)="mediaCenterTypeEvent(null)"
    [class.selected]="!selectedMediaTab"
  >
    <!-- <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-gallery"></use>
    </svg> -->
    <span>ALL Media</span>
  </div>
  <div
    class="tab"
    (click)="mediaCenterTypeEvent(messageTypes.PHOTO)"
    [class.selected]="selectedMediaTab === messageTypes.PHOTO"
  >
    <!-- <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-photo"></use>
    </svg> -->
    <span>Images</span>
  </div>
  <div
    *ngIf="!isPhotoMedia"
    class="tab"
    (click)="mediaCenterTypeEvent(messageTypes.VIDEO)"
    [class.selected]="selectedMediaTab === messageTypes.VIDEO"
  >
    <!-- <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-video"></use>
    </svg> -->
    <span>Videos</span>
  </div>
  <div
    *ngIf="!isPhotoMedia"
    class="tab"
    (click)="mediaCenterTypeEvent(messageTypes.AUDIO)"
    [class.selected]="selectedMediaTab === messageTypes.AUDIO"
  >
    <!-- <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-voice"></use>
    </svg> -->
    <span>Audios</span>
  </div>
</div>
