import { RootStoreSelectors } from '../store/root-store.selectors';
import { CHANNEL_APP_SEARCH_CATEGORIES } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../store/root-store.selectors";
export class AbstractControlsService {
    constructor(_selectors) {
        this._selectors = _selectors;
    }
    get area$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.AREA);
    }
    get classification$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CLASSIFICATIONS);
    }
    get category$() {
        return this._selectors.getAppChannelDataByKey$(CHANNEL_APP_SEARCH_CATEGORIES.CATEGORY);
    }
}
AbstractControlsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AbstractControlsService_Factory() { return new AbstractControlsService(i0.ɵɵinject(i1.RootStoreSelectors)); }, token: AbstractControlsService, providedIn: "root" });
