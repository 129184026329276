import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { UiService } from '../../core/ui.service';
import { MessageDispatchers } from '../messages/message.dispatchers';
import * as MainChatActions from './mainChat.actions';
import { MainChatActionTypes } from './mainChat.actions';

import { AppState } from 'src/models/AppState';
import { UserReplyEOP } from 'src/models/IRepliesEOP';
import * as MainChatMethods from 'src/models/MainChat';
import * as MessagesMethods from 'src/models/IMessage';
import {
  SERVER_PAGE_SIZE,
  GROUP,
  CHANNEL,
  PROJECT_NAME,
  INDIVIDUAL_PROJECT,
  MERCHANT_PROJECT,
  REACHED_END
} from 'src/models/constants';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class MainChatEffects {
  @Effect({ dispatch: false })
  mainChatSelected = this.actions$.pipe(
    ofType(MainChatActionTypes.MAIN_CHAT_SELECTED),
    map((action: MainChatActions.MainChatSelected) => {
      this._messageDispatchers.readChatMessages(action.mainChat);
      if (action.mainChat.type === GROUP || action.mainChat.type === CHANNEL) {
        this._chatDispatchers.chatSelected(action.mainChat.id);
      }
    })
  );

  @Effect({ dispatch: false })
  mainChatDeselected = this.actions$.pipe(
    ofType(MainChatActionTypes.MAIN_CHAT_DESELECTED),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats]) => {
      const action = <MainChatActions.MainChatDeselected>val;

      const targetChat = mainChats.find(chat => chat.id === action.id);
      if (targetChat) {
        this._messageDispatchers.chatDeselected(targetChat);
      }
    })
  );

  @Effect({ dispatch: false })
  replyReceivedInSelectedChat = this.actions$.pipe(
    ofType(MainChatActionTypes.REPLY_RECEIVED_IN_SELECTED_CHAT),
    map((action: MainChatActions.ReplyReceivedInSelectedChat) => {
      this.uiService.replyRcvdInSelectedChatNotify(action.message);
    })
  );

  @Effect({ dispatch: false })
  chatHistory = this.actions$.pipe(
    ofType(MainChatActionTypes.REQUEST_HISTORY),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.repliesEOPReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats, selectedChat, repliesEOPList]) => {
      const action = <MainChatActions.RequestHistory>val;
      if (selectedChat) {
        const targetChat = mainChats.find(chat => {
          if (action.subChatID) {
            return chat.id === action.subChatID;
          } else {
            return chat.id === selectedChat.id;
          }
        });
        if (targetChat) {
          if (!action.parentMsgID) {
            this.getChatHistory(targetChat, targetChat.endOfPage);
          } else {
            let endOfPage = null;
            let level = null;
            let proceedWithRequest = true;

            const replyRecord = repliesEOPList.find(
              item => item.messageID === action.parentMsgID
            );

            if (replyRecord) {
              if (action.userID) {
                let userRecord: UserReplyEOP = null;
                if (replyRecord.usersRepliesEOPList) {
                  userRecord = replyRecord.usersRepliesEOPList.find(
                    user => user.userID === action.userID
                  );
                }
                if (userRecord) {
                  if (action.requestOnce) {
                    proceedWithRequest = false; // do not get history as the flag requestOnce is true and you got history before
                  }
                  endOfPage = userRecord.eopSecondDepth;
                }
              } else {
                endOfPage = replyRecord.eopFirstDepth;
              }
            }

            if (
              targetChat.isAdmin &&
              targetChat.type === CHANNEL &&
              !action.userID
            ) {
              level = 2;
            } else {
              level = 3;
            }

            if (proceedWithRequest) {
              this.getChatHistory(
                targetChat,
                endOfPage,
                action.parentMsgID,
                level,
                action.userID
              );
            }
          }
        }
      }
    })
  );

  constructor(
    private actions$: Actions,
    private _messageDispatchers: MessageDispatchers,
    private _chatDispatchers: ChatDispatchers,
    private uiService: UiService,
    private _socketGateway: SocketGateway,
    private _store: Store<AppState>
  ) {}

  private getChatHistory(
    chat: MainChatMethods.MainChat,
    endOfPage: number,
    parentMsgID?: string,
    level?: number,
    userID?: string
  ) {
    if (endOfPage !== REACHED_END) {
      if (PROJECT_NAME === INDIVIDUAL_PROJECT) {
        this._socketGateway.sendSocketMessage(
          new MainChatMethods.GetChatHistoryFromMobile(
            chat.id,
            endOfPage,
            SERVER_PAGE_SIZE,
            parentMsgID,
            level,
            userID
          )
        );
      } else if (PROJECT_NAME === MERCHANT_PROJECT) {
        this.getChatHistoryFromBusinessServer(
          endOfPage ? endOfPage : 0,
          SERVER_PAGE_SIZE,
          parentMsgID,
          level,
          userID,
          chat ? chat.id : null
        );
      }
    }
  }

  private getChatHistoryFromBusinessServer(
    reference: number,
    page_size: number,
    reply_to_message_id?: string,
    lv?: number,
    user_id?: string,
    chat_id?: string
  ) {
    this._socketGateway.sendSocketMessage(
      new MessagesMethods.GetMessagesHistoryFromBusinessServer(
        null,
        reply_to_message_id,
        null,
        null,
        null,
        null,
        null,
        null,
        user_id,
        page_size,
        lv,
        reference,
        0,
        chat_id
      )
    );
  }
}
