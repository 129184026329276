<app-headerless-closable-frame (closeNotify)="cloaseEvent()">
  <ng-container ngProjectAs="content">
    <app-form-frame [isCard]="false">
      <ng-container ngProjectAs="title">
        <ng-content select="title"></ng-content>
      </ng-container>
      <ng-container ngProjectAs="desc">
        <ng-content select="desc"></ng-content>
      </ng-container>
      <ng-container ngProjectAs="action">
        <ng-content select="action"></ng-content>
      </ng-container>
      <ng-container ngProjectAs="[form-body]">
        <div class="form-selector-search">
          <ng-content select="[form-selector-search]"></ng-content>
        </div>
        <div class="form-selector-create">
          <ng-content select="[form-selector-create]"></ng-content>
        </div>
        <div class="form-selector-paging">
          <ng-content select="[form-selector-paging]"></ng-content>
        </div>
        <div class="form-selector-masonry">
          <ng-content select="[form-selector-masonry]"></ng-content>
        </div>
        <div class="form-selector-items">
          <ng-content select="[form-selector-items]"></ng-content>
        </div>
        <div class="form-selector-tabs">
          <ng-content select="[form-selector-tabs]"></ng-content>
        </div>
        <div class="form-selector-table">
          <ng-content select="[form-selector-table]"></ng-content>
        </div>
        <div class="form-selector-action">
          <ng-content select="[form-selector-action]"></ng-content>
        </div>
      </ng-container>
    </app-form-frame>
  </ng-container>
</app-headerless-closable-frame>
