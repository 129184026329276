import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
import { ChatDispatchers } from '../chats/chat.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { ERROR_NOT_IMAGE, FILE_SIZE_EXCEEDED, MessageTypes, NOT_SUPPORTED_FILE_TYPE, NO_FILE_NAME, GROUP_UPLOAD_TYPE, EVENT_CHANNEL } from 'src/models/constants';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { SetChat, GetSubChats } from 'src/models/IChat';
import { EventUiDispatchers } from './eventUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { EventsService } from 'src/app/+merchant/+dashboard/events/events.service';
export class EventUiEffects {
    constructor(actions$, _chatDispatchers, _socketGateway, _uiDispatchers, _uiEventDispatchers, _fileService, _uploadGateway, eventsService, _store) {
        this.actions$ = actions$;
        this._chatDispatchers = _chatDispatchers;
        this._socketGateway = _socketGateway;
        this._uiDispatchers = _uiDispatchers;
        this._uiEventDispatchers = _uiEventDispatchers;
        this._fileService = _fileService;
        this._uploadGateway = _uploadGateway;
        this.eventsService = eventsService;
        this._store = _store;
        this.uploadEventImage = this.actions$.pipe(ofType("UPLOAD_EVENT_IMAGE" /* UPLOAD_EVENT_IMAGE */), withLatestFrom(this._store
            .select(state => state.eventUiReducer.creationProcessEvent)
            .pipe(distinctUntilChanged(isEqual)), this._store
            .select(state => state.eventUiReducer.selectedEvent)
            .pipe(distinctUntilChanged(isEqual))), map(([val, creationEvent, updatedEvent]) => {
            const action = val;
            if (this.validateFile(action.selectedFile.file, 'image')) {
                this._fileService
                    .readFileAsArrayBuffer(action.selectedFile.file.localFile)
                    .then(fileAsArrayBuffer => {
                    this._uploadGateway
                        .uploadWithProgress(fileAsArrayBuffer, action.selectedFile.file.localFile.type, action.selectedFile.file.localFile.name, false, GROUP_UPLOAD_TYPE, null, creationEvent ? creationEvent.id : updatedEvent.id, true)
                        .subscribe(event => {
                        if (event.type === HttpEventType.Response) {
                            const res = event.body;
                            let chat = {};
                            const photo = { id: res.file };
                            chat.id = creationEvent
                                ? creationEvent.id
                                : updatedEvent.id;
                            chat.photo = photo;
                            chat = Object.assign({}, chat, action.selectedFile.event);
                            // this.eventsService._currentPhotoUploaded.next(res);
                            this._socketGateway.sendSocketMessage(new SetChat(chat));
                        }
                    }, error => {
                        console.log('Failed to upload event image');
                    });
                });
            }
        }));
        this.afterEventCreation = this.actions$.pipe(ofType("UI_EVENT_UPLOAD_PHOTO" /* UI_EVENT_UPLOAD_PHOTO */), map((action) => {
            this._chatDispatchers.chatInvalidated(action.createdEvent.id);
        }));
        this.getNextEventPage = this.actions$.pipe(ofType("UI_EVENT_GET_NEXT_PAGE" /* UI_EVENT_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.eventUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, eventUiState]) => {
            // const action = <EventActions.GetNextEventPage>val;
            this._socketGateway.sendSocketMessage(new GetSubChats([EVENT_CHANNEL], 0, eventUiState.hash, eventUiState.eop));
        }));
        this.getPrevEventPage = this.actions$.pipe(ofType("UI_EVENT_GET_PREV_PAGE" /* UI_EVENT_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.eventUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, eventUiState]) => {
            //  const action = <EventActions.GetPrevEventPage>val;
            this._socketGateway.sendSocketMessage(new GetSubChats([EVENT_CHANNEL], 1, eventUiState.hash, eventUiState.sop));
        }));
        this.afterEventSectionSelection = this.actions$.pipe(ofType("UI_LIST_EVENT" /* UI_LIST_EVENT */), map((action) => {
            this._uiEventDispatchers.getNextEventPage();
        }));
        this.afterEventResetPaging = this.actions$.pipe(ofType("UI_EVENT_RESET_PAGING" /* UI_EVENT_RESET_PAGING */), map((action) => {
            this._uiEventDispatchers.getNextEventPage();
        }));
    }
    validateFile(selectedFile, specificValidType) {
        if (!selectedFile.localFile.name) {
            this._uiDispatchers.showPopup(NO_FILE_NAME);
            return false;
        }
        else if (!this._fileService.isMimeTypeValid(selectedFile.type, selectedFile.localFile.type)) {
            this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
            return false;
        }
        else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, selectedFile.localFile.size)) {
            this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
            return false;
        }
        else if (specificValidType &&
            selectedFile.localFile.type.indexOf(specificValidType) === -1) {
            this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
            return false;
        }
        return true;
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "uploadEventImage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "afterEventCreation", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "getNextEventPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "getPrevEventPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "afterEventSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], EventUiEffects.prototype, "afterEventResetPaging", void 0);
