import { Injectable } from '@angular/core';
import { ComponentDispatchers } from './component.dispatchers';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { HttpEventType } from '@angular/common/http';
import { IUploadResponse } from 'src/models/IUploadResponse';
import {
  NO_FILE_NAME,
  NOT_SUPPORTED_FILE_TYPE,
  FILE_SIZE_EXCEEDED,
  ERROR_NOT_IMAGE,
  ContainerTypes,
  TAB_UPLOAD_TYPE,
  MessageTypes,
  REACHED_MAX_ITEM_LIMIT,
  REACHED_MIN_ITEM_LIMIT,
  SPLASH_PAGE_UPLOAD_TYPE,
  ERROR_NOT_PNG_IMAGE,
  LOGO_WIDTH,
  LOGO_HEIGHT,
  ERROR_IMAGE_SHOULD_BE_1024X1024,
  FAILED_TO_UPLOAD_IMAGE,
  MessageMediaStatus,
  AppClassNames
} from 'src/models/constants';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { FileService } from 'src/app/core/file.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ComponentActionTypes } from './component.actions';
import * as ComponentActions from './component.actions';
import { map, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { DownloadGateway } from 'src/app/network/gateway/download.gateway';
import { Store } from '@ngrx/store';
import { AppState } from 'src/models/AppState';
import {
  LocalComponent,
  LocalContainer,
  LocalItem
} from 'src/models/ChannelAppLocalConfig';
import { initNewComponent } from './component.middleware';
import { IUploadResponseImage } from 'src/models/IUploadResponseImage';
import { ImageSet } from 'src/models/ChannelAppConfig';

import * as isEqual from 'lodash.isequal';
import { AppMgmtService } from 'src/app/+merchant/+dashboard/app-mgmt/appMgmt.service';

@Injectable()
export class ComponentEffects {
  @Effect({ dispatch: false })
  addComponentRequest = this.actions$.pipe(
    ofType(ComponentActionTypes.ADD_COMPONENT_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.componentReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, components]) => {
      const action: ComponentActions.AddComponentRequest = <
        ComponentActions.AddComponentRequest
      >val;
      let componentsOfContainer: LocalComponent[] = [];
      componentsOfContainer = components.filter(
        container => container.parentContainerRef === action.container.ref
      );
      if (
        action.container.max_components &&
        componentsOfContainer &&
        componentsOfContainer.length >= action.container.max_components
      ) {
        this._uiDispatchers.showPopup(REACHED_MAX_ITEM_LIMIT);
      } else {
        const newComp = initNewComponent(
          action.container,
          componentsOfContainer
        );
        this._componentDispatchers.addComponent(newComp);
        // May be in the future I'll need to add items beneath the added component
      }
    })
  );

  @Effect({ dispatch: false })
  deleteComponentRequest = this.actions$.pipe(
    ofType(ComponentActionTypes.DELETE_COMPONENT_REQUEST),
    withLatestFrom(
      this._store
        .select(state => state.componentReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.containerReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, components, containers]) => {
      const action: ComponentActions.DeleteComponentRequest = <
        ComponentActions.DeleteComponentRequest
      >val;
      let componentsOfContainer: LocalComponent[] = [];
      componentsOfContainer = components.filter(
        component =>
          component.parentContainerRef === action.component.parentContainerRef
      );
      const parentContainer = containers.find(
        container => container.ref === action.component.parentContainerRef
      );
      if (
        parentContainer &&
        parentContainer.min_components &&
        componentsOfContainer &&
        componentsOfContainer.length <= parentContainer.min_components
      ) {
        this._uiDispatchers.showPopup(REACHED_MIN_ITEM_LIMIT);
      } else {
        this._componentDispatchers.deleteComponent(action.component);
      }
    })
  );

  @Effect({ dispatch: false })
  downloadComponentMedia = this.actions$.pipe(
    ofType(ComponentActionTypes.DOWNLOAD_COMPONENT_MEDIA),
    map((action: ComponentActions.DownloadComponentMedia) => {
      const localType = this._fileService.getMimeTypeFromMessageType(
        action.fileType
      );
      this._downloadGateway
        .downloadMediaFile(action.imageId, localType, false)
        .then(res => {
          this._componentDispatchers.componentMediaDownloadSuccess(
            res,
            action.component
          );
        })
        .catch(err => {
          console.log('Failed to download component image');
        });
    })
  );

  @Effect({ dispatch: false })
  uploadComponentMedia = this.actions$.pipe(
    ofType(ComponentActionTypes.UPLOAD_COMPONENT_MEDIA),
    withLatestFrom(
      this._store
        .select(state =>
          state.containerReducer.filter(
            cont => cont.container_type === ContainerTypes.TAB
          )
        )
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, tabs]) => {
      const action = <ComponentActions.UploadComponentMedia>val;
      const parentTab = tabs.find(
        tab => tab.ref === action.component.parentContainerRef
      );
      this.validateFile(action.selectedFile, 'image');
      this._fileService
        .readFileAsArrayBuffer(action.selectedFile.localFile)
        .then(fileAsArrayBuffer => {
          this._uploadGateway
            .uploadWithProgress(
              fileAsArrayBuffer,
              action.selectedFile.localFile.type,
              action.selectedFile.localFile.name,
              false,
              TAB_UPLOAD_TYPE,
              parentTab.id,
              null,
              true
            )
            .subscribe(
              event => {
                if (event.type === HttpEventType.Response) {
                  const res = <IUploadResponse>event.body;
                  const imageUrl = res.url;
                  this._componentDispatchers.componentMediaUploadSuccess(
                    imageUrl,
                    imageUrl,
                    action.component,
                    action.isBackground
                  );
                }
              },
              error => {
                console.log('Failed to upload component image');
              }
            );
        });
    })
  );

  @Effect({ dispatch: false })
  uploadVideoBackground = this.actions$.pipe(
    ofType(ComponentActionTypes.UPLOAD_VIDEO_BACKGROUND),
    map((action: ComponentActions.UploadVideoBackground) => {
      this._fileService
        .readFileAsArrayBuffer(action.selectedFile.localFile)
        .then(fileAsArrayBuffer => {
          this._fileService
            .readArrayBufferAsBlobUrl(
              fileAsArrayBuffer,
              action.selectedFile.type
            )
            .then(blobUrl => {
              this._fileService.getImageMetadata(blobUrl).then(props => {
                this._uploadGateway
                  .uploadWithProgress(
                    fileAsArrayBuffer,
                    action.selectedFile.localFile.type,
                    action.selectedFile.localFile.name,
                    false,
                    SPLASH_PAGE_UPLOAD_TYPE,
                    null,
                    null,
                    true
                  )
                  .subscribe(
                    event => {
                      if (event.type === HttpEventType.Response) {
                        const res = <IUploadResponseImage>event.body;
                        let imageUrl = '';
                        if (res.files[4]) {
                          imageUrl = res.files[4].url;
                        }
                        const imageSet: ImageSet = {};
                        imageSet.hdpi = res.files[0].url;
                        imageSet.mdpi = res.files[1].url;
                        imageSet.xhdpi = res.files[2].url;
                        imageSet.xxhdpi = res.files[3].url;
                        imageSet.xxxhdpi = res.files[4].url;
                        imageSet.ios1x = res.files[5].url;
                        imageSet.ios2x = res.files[6].url;
                        imageSet.ios3x = res.files[7].url;

                        if (imageUrl !== '' && action.currentPage) {
                          const containerDetails: LocalContainer = {};
                          containerDetails.image_set = imageSet;
                          containerDetails.image_url = imageUrl;
                          this.appManagmentService._imageUrl.next(imageUrl);
                          this.appManagmentService.updateContainer(
                            action.componentRef,
                            containerDetails
                          );
                        } else if (imageUrl !== '' && !action.currentPage) {
                          const containerDetails: LocalItem = {};
                          containerDetails.image_set = imageSet;
                          containerDetails.image_url = imageUrl;
                          this.appManagmentService._imageUrl.next(imageUrl);
                          this.appManagmentService.updateItem(
                            action.componentRef,
                            containerDetails
                          );
                        }
                      }
                    },
                    error => {
                      this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                    }
                  );
              });
            });
        });
    })
  );
  @Effect({ dispatch: false })
  uploadSplashPageLogo = this.actions$.pipe(
    ofType(ComponentActionTypes.UPLOAD_SPLASH_PAGE_LOGO),
    map((action: ComponentActions.UploadSplashPageLogo) => {
      if (this.validateImage(action.selectedFile)) {
        this._fileService
          .readFileAsArrayBuffer(action.selectedFile.localFile)
          .then(fileAsArrayBuffer => {
            this._fileService
              .readArrayBufferAsBlobUrl(
                fileAsArrayBuffer,
                action.selectedFile.type
              )
              .then(blobUrl => {
                this._fileService.getImageMetadata(blobUrl).then(props => {
                  if (
                    props.width !== props.height ||
                    props.width < LOGO_WIDTH ||
                    props.height < LOGO_HEIGHT
                  ) {
                    this._uiDispatchers.showPopup(
                      ERROR_IMAGE_SHOULD_BE_1024X1024
                    );
                    const newComponent: LocalComponent = {};
                    newComponent.uploadingSplashImage =
                      MessageMediaStatus.UPLOAD_FAILED;
                    this.appManagmentService.updateComponent(
                      action.componentRef,
                      newComponent
                    );
                  } else {
                    this._uploadGateway
                      .uploadWithProgress(
                        fileAsArrayBuffer,
                        action.selectedFile.localFile.type,
                        action.selectedFile.localFile.name,
                        false,
                        SPLASH_PAGE_UPLOAD_TYPE,
                        null,
                        null,
                        true
                      )
                      .subscribe(
                        event => {
                          if (event.type === HttpEventType.Response) {
                            const res = <IUploadResponseImage>event.body;
                            let imageUrl = '';
                            if (res.files[4]) {
                              imageUrl = res.files[4].url;
                            }
                            const imageSet: ImageSet = {};
                            imageSet.hdpi = res.files[0].url;
                            imageSet.mdpi = res.files[1].url;
                            imageSet.xhdpi = res.files[2].url;
                            imageSet.xxhdpi = res.files[3].url;
                            imageSet.xxxhdpi = res.files[4].url;
                            imageSet.ios1x = res.files[5].url;
                            imageSet.ios2x = res.files[6].url;
                            imageSet.ios3x = res.files[7].url;

                            this._componentDispatchers.UploadSplashPageLogoSuccess(
                              action.componentRef,
                              imageUrl,
                              imageSet
                            );
                          }
                        },
                        error => {
                          this._uiDispatchers.showPopup(FAILED_TO_UPLOAD_IMAGE);
                        }
                      );
                  }
                });
              });
          });
      }
    })
  );

  private validateFile(
    selectedFile: ISelectedFiles,
    specificValidType: string
  ) {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return;
    } else if (
      !this._fileService.isMimeTypeValid(
        selectedFile.type,
        selectedFile.localFile.type
      )
    ) {
      this._uiDispatchers.showPopup(NOT_SUPPORTED_FILE_TYPE);
      return;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return;
    } else if (
      specificValidType &&
      selectedFile.localFile.type.indexOf(specificValidType) === -1
    ) {
      this._uiDispatchers.showPopup(ERROR_NOT_IMAGE);
      return;
    }
  }

  private validateImage(selectedFile: ISelectedFiles): boolean {
    if (!selectedFile.localFile.name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (selectedFile.localFile.type.indexOf('image/png') === -1) {
      this._uiDispatchers.showPopup(ERROR_NOT_PNG_IMAGE);
      return false;
    } else if (
      !this._fileService.isFileSizeValid(
        MessageTypes.PHOTO,
        selectedFile.localFile.size
      )
    ) {
      this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      return false;
    }
    return true;
  }

  constructor(
    private actions$: Actions,
    private _componentDispatchers: ComponentDispatchers,
    private _uiDispatchers: UIDispatchers,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _downloadGateway: DownloadGateway,
    private _store: Store<AppState>,
    private appManagmentService: AppMgmtService
  ) {}
}
