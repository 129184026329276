<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">
    Select Calendar
  </ng-container>

  <!-- <ng-container ngProjectAs="[form-selector-search]">
    <app-search-form (searchFormSubmitNotfiy)="onSearchFormSubmit($event)">
    </app-search-form>
  </ng-container> -->

  <!-- <ng-container ngProjectAs="[form-selector-create]">
    <ng-container *appShowIfHasPrivilege="privilegesName.editcalendar">
      <app-create-item-frame>
        <ng-container ngProjectAs="[form-selector-create-label]">
          Add new Calender
        </ng-container>
        <ng-container ngProjectAs="[form-selector-create-text]">
          You can add new calendar from the "Add new calendar” field. Simply click
          the “+ Add new calendar” button and Create new calendar.
        </ng-container>
      </app-create-item-frame>
    </ng-container>
  </ng-container>  -->

  <ng-container ngProjectAs="[form-selector-items]">
    <ng-container
      *ngIf="
        (calendarService.calendarChannelList$ | async)?.length > 0;
        else isEmpty
      "
    >
      <app-round-paging-btn
        class="paging-float-btn"
        (nextBtnClicked)="calendarService.getNextPage()"
        (previousBtnClicked)="calendarService.getPrevPage()"
      >
      </app-round-paging-btn>
      <app-selectable-cell-frame
        *ngFor="
          let calendar of calendarService.calendarChannelList$
            | async
            | search: (searchTerm$ | async):'title';
          trackBy: TrackByFunction
        "
        [symbolColor]="calendarFet?.symbolColor"
        [symbolImage]="calendarFet?.symbolImage"
        [coverColor]="calendarFet?.coverColor"
        [coverPhoto]="calendar?.url"
        [title]="calendar?.title"
        [desc]="calendar?.desc"
        [selectorId]="calendar?.id"
        [isSelected]="calendar?.id === currentSelectMessage?.id"
        (checkClick)="setSelectMessage(calendar)"
        (checkChanged)="resetSelectMessage()"
      >
      </app-selectable-cell-frame>
    </ng-container>

    <ng-template #isEmpty>
      <app-empty-item-frame
        [emptySymbol]="calendarFet?.symbolImage"
        [emptyTitle]="calendarFet?.emptyTitle"
        [emptyDesc]="calendarFet?.emptyPoolDesc"
      >
      </app-empty-item-frame>
    </ng-template>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn
        *ngIf="currentSelectMessage"
        submit
        (btnClicked)="insertEvent()"
      >
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>
