/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-post-operations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-post-operations.component";
var styles_MessagePostOperationsComponent = [i0.styles];
var RenderType_MessagePostOperationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessagePostOperationsComponent, data: {} });
export { RenderType_MessagePostOperationsComponent as RenderType_MessagePostOperationsComponent };
function View_MessagePostOperationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-chevron-left clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_MessagePostOperationsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "message-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["# ", ""])), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "fill-space"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "btn-group"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessagePostOperationsComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isNested; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.parentId, (0 - 3))); _ck(_v, 1, 0, currVal_0); }); }
export function View_MessagePostOperationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-post-operations", [], null, null, null, View_MessagePostOperationsComponent_0, RenderType_MessagePostOperationsComponent)), i1.ɵdid(1, 49152, null, 0, i3.MessagePostOperationsComponent, [], null, null)], null, null); }
var MessagePostOperationsComponentNgFactory = i1.ɵccf("app-message-post-operations", i3.MessagePostOperationsComponent, View_MessagePostOperationsComponent_Host_0, { message: "message", parentId: "parentId", isNested: "isNested" }, { closeNotify: "closeNotify", backNotify: "backNotify" }, []);
export { MessagePostOperationsComponentNgFactory as MessagePostOperationsComponentNgFactory };
