import { Action } from '@ngrx/store';
import { DriverTrip } from 'src/models/DrverAssigned';

export const enum otherDriverTripsActionTypes {
  OTHER_TRIPS_RECEIVED = 'OTHER_TRIPS_RECEIVED',
  GET_OTHER_TRIPS = 'GET_OTHER_TRIPS'
}

export const RESET = 'RESET';

export class GetOtherTrips implements Action {
  readonly type = otherDriverTripsActionTypes.GET_OTHER_TRIPS;
  constructor(public trip_id: number, public account_id: string) {}
}

export class OtherTipsReceived implements Action {
  readonly type = otherDriverTripsActionTypes.OTHER_TRIPS_RECEIVED;
  constructor(public payload: any) {}
}

export class Reset implements Action {
  readonly type = RESET;
}

export type otherTripsActions = GetOtherTrips | OtherTipsReceived | Reset;
