/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../abstract-frames/popup-setting-frame/popup-setting-frame.component.ngfactory";
import * as i3 from "../../abstract-frames/popup-setting-frame/popup-setting-frame.component";
import * as i4 from "../media-center-uploader-input/media-center-uploader-input.component.ngfactory";
import * as i5 from "../media-center-uploader-input/media-center-uploader-input.component";
import * as i6 from "../../core/file.service";
import * as i7 from "../media-center-tabs/media-center-tabs.component.ngfactory";
import * as i8 from "../media-center-tabs/media-center-tabs.component";
import * as i9 from "../media-center-uploader-list/media-center-uploader-list.component.ngfactory";
import * as i10 from "../media-center-uploader-list/media-center-uploader-list.component";
import * as i11 from "../media-center.service";
import * as i12 from "./media-center-modal.component";
var styles_MediaCenterModalComponent = [i0.styles];
var RenderType_MediaCenterModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterModalComponent, data: {} });
export { RenderType_MediaCenterModalComponent as RenderType_MediaCenterModalComponent };
export function View_MediaCenterModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-popup-setting-frame", [], null, [[null, "closeNotifier"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeNotifier" === en)) {
        var pd_0 = (_co.cancelNotifyClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PopupSettingFrameComponent_0, i2.RenderType_PopupSettingFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.PopupSettingFrameComponent, [], { title: [0, "title"], desc: [1, "desc"], hideFooter: [2, "hideFooter"] }, { closeNotifier: "closeNotifier" }), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "media-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-media-center-uploader-input", [], null, [[null, "filesSelectedNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filesSelectedNotify" === en)) {
        var pd_0 = (_co.fileSelectedEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MediaCenterUploaderInputComponent_0, i4.RenderType_MediaCenterUploaderInputComponent)), i1.ɵdid(4, 49152, null, 0, i5.MediaCenterUploaderInputComponent, [i6.FileService], { mimeTypes: [0, "mimeTypes"] }, { filesSelectedNotify: "filesSelectedNotify" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-media-center-tabs", [["class", "media-types"]], null, [[null, "mediaCenterTypeNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mediaCenterTypeNotify" === en)) {
        var pd_0 = (_co.mediaCenterTypeEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MediaCenterTabsComponent_0, i7.RenderType_MediaCenterTabsComponent)), i1.ɵdid(6, 49152, null, 0, i8.MediaCenterTabsComponent, [], { selectedMediaTab: [0, "selectedMediaTab"], isPhotoMedia: [1, "isPhotoMedia"] }, { mediaCenterTypeNotify: "mediaCenterTypeNotify" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-media-center-uploader-list", [], null, [[null, "mediaCenterNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mediaCenterNotify" === en)) {
        var pd_0 = (_co.mediaCenterEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MediaCenterUploaderListComponent_0, i9.RenderType_MediaCenterUploaderListComponent)), i1.ɵdid(8, 49152, null, 0, i10.MediaCenterUploaderListComponent, [i11.MediaCenterService], { selectedMediaType: [0, "selectedMediaType"] }, { mediaCenterNotify: "mediaCenterNotify" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Add Media"; var currVal_1 = "Upload your media for use in your app."; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.mimeTypes; _ck(_v, 4, 0, currVal_3); var currVal_4 = ((_co.mediaCenterType == null) ? null : _co.mediaCenterType.type); var currVal_5 = _co.isPhotoMedia; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = ((_co.mediaCenterType == null) ? null : _co.mediaCenterType.type); _ck(_v, 8, 0, currVal_6); }, null); }
export function View_MediaCenterModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-modal", [], null, null, null, View_MediaCenterModalComponent_0, RenderType_MediaCenterModalComponent)), i1.ɵdid(1, 573440, null, 0, i12.MediaCenterModalComponent, [i11.MediaCenterService], null, null)], null, null); }
var MediaCenterModalComponentNgFactory = i1.ɵccf("app-media-center-modal", i12.MediaCenterModalComponent, View_MediaCenterModalComponent_Host_0, { mimeTypes: "mimeTypes", isPhotoMedia: "isPhotoMedia", tabID: "tabID", groupID: "groupID", botID: "botID" }, { mediaCenterNotify: "mediaCenterNotify", cancelNotify: "cancelNotify" }, []);
export { MediaCenterModalComponentNgFactory as MediaCenterModalComponentNgFactory };
