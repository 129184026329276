<div class="compose-container">
  <div class="message-container">
    <app-link-preview-cell
      *ngIf="
        instantArticle?.linkPreviewStatus ===
        msgLinkPreviewStat.LINK_PREVIEW_FETCHED
      "
      class="compose-component wrap-text"
      [title]="instantArticle?.linkPreviewTitle"
      [description]="instantArticle?.linkPreviewDescription"
      [image]="instantArticle?.linkPreviewImage"
      [height]="instantArticle?.linkPreviewHeight"
      [width]="instantArticle?.linkPreviewWidth"
      [url]="instantArticle?.linkPreviewUrl"
      [rootUrl]="instantArticle?.linkPreviewRootUrl"
      [style]="stylesComponent"
      [showSite]="false"
    >
    </app-link-preview-cell>

    <div
      *ngIf="
        instantArticle?.linkPreviewStatus ===
        msgLinkPreviewStat.LINK_PREVIEW_FETCHING
      "
      class="compose-loader"
    >
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>

    <div
      *ngIf="
        instantArticle?.linkPreviewStatus ===
        msgLinkPreviewStat.LINK_PREVIEW_FAILED
      "
      class="compose-loader"
    >
      We can't get any link Preview to this link we will send it as text
    </div>

    <!-- Message Targets ( Tags and Tab ) -->
    <ng-content select="[message-style]"></ng-content>
  </div>

  <!-- Message Attachs -->
  <ng-content select="[message-attachs]"></ng-content>
  <!------------------------------------------------->
  <!-- Message Targets ( Tags and Tab ) -->
  <ng-content select="[message-target]"></ng-content>
  <!------------------------------------------------->

  <div class="footer">
    <div class="form-submit">
      <ng-content select="[footer-tag]"></ng-content>
      <ng-content select="[footer-submit]"></ng-content>
      <ng-content select="[footer-cancel]"></ng-content>
      <app-border-btn cancel (btnClicked)="cancelEvent()">
        Clear
      </app-border-btn>
    </div>
  </div>
</div>
