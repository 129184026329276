import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { ChatDispatchers } from 'src/app/store/chats/chat.dispatchers';
import { ChatMemberDispatchers } from 'src/app/store/chatMembers/chatMember.dispatchers';
import { MembersUiDispatchers } from 'src/app/store/membersUI/membersUI.dispatchers';
import { BundlesUiDispatchers } from 'src/app/store/bundlesUI/bundlesUI.dispatchers';

import { TableService } from 'src/app/abstract-tables/table.service';

import { AbstractItem } from 'src/models/AbstractItem';
import { AbstractUI } from 'src/models/AbstractUI';
import { IChatMember } from 'src/models/IChatMember';
import { Bundle, BundleProduct } from 'src/models/Bundle';
import { IProfile } from 'src/models/IProfile';

@Injectable({
  providedIn: 'root'
})
export class MembersService extends TableService {
  selectedItems: string[];
  searchBy = 0;

  constructor(
    private _selectors: RootStoreSelectors,
    private _chatDispatchers: ChatDispatchers,
    private _chatMemberDispatchers: ChatMemberDispatchers,
    private _membersUiDispatchers: MembersUiDispatchers,
    private _bundlesUiDispatchers: BundlesUiDispatchers
  ) {
    super();
  }

  getMyMemberChats(userId: string) {
    this._chatMemberDispatchers.getMemberChats(userId);
  }
  promoteChatMember(userId: string) {
    this._chatMemberDispatchers.promoteChatMember(userId);
  }

  buyBundleOnBehalfOfUser(userId: string, bundle: Bundle) {
    this._bundlesUiDispatchers.buyBundleOnBehalfOfUser(userId, bundle);
  }

  buySubscriptionOnBehalfOfUser(
    userId: string,
    chat: BundleProduct,
    currency: string
  ) {
    this._chatDispatchers.buySubscriptionOnBehalfOfUser(
      chat.product_id,
      userId,
      currency
    );
  }

  assignAdminsToChat(chatId: string) {
    this.selectedItems.forEach(memberId => {
      this._chatMemberDispatchers.addAdminToChat(chatId, memberId);
    });
  }

  assignAdminsToChatInForm(chatId: string, selectedItems) {
    selectedItems.forEach(memberId => {
      this._chatMemberDispatchers.addAdminToChat(chatId, memberId);
    });
  }

  assignMembersToChat(chatId: string) {
    this.selectedItems.forEach(memberId => {
      this._chatMemberDispatchers.addMemberToChat(chatId, memberId);
    });
  }

  returnSelectedItems() {
    return this.selectedItems;
  }

  assignMemberToChat(chatId: string, userId: string) {
    this._chatMemberDispatchers.addMemberToChat(chatId, userId);
  }
  assignAdminToChat(chatId: string, userId: string) {
    this._chatMemberDispatchers.addAdminToChat(chatId, userId);
  }
  revokeMemberFromChat(chatId: string, userId: string) {
    this._chatMemberDispatchers.revokeMemberFromChat(chatId, userId);
  }
  revokeAdminFromChat(chatId: string, userId: string) {
    this._chatMemberDispatchers.revokeAdminFromChat(chatId, userId);
  }
  setChatMemberRole(chatId: string, userId: string, roleId: string) {
    this._chatMemberDispatchers.setChatMemberRole(chatId, userId, roleId);
  }
  setChatMemberView(userId: string, viewId: number) {
    this._chatMemberDispatchers.setChatMemberView(userId, viewId);
  }

  get selectedChatRoles$() {
    return this._selectors.selectedChatRoles$;
  }
  get selectedChat$() {
    return this._selectors.getSelectedChat$();
  }
  get subChats$() {
    return this._selectors.subChats$;
  }
  // get selectedChatMembers$() {
  //   return this._selectors.selectedChatMembers$;
  // }

  get list$() {
    return this._selectors.selectedChatMembers$.pipe(
      map(members => {
        return members.map(member => {
          const item: AbstractItem = {};
          item.id = member.user_id;
          item.date = member.member_since;
          item.name = member.name;
          item.msidin = member.msisdn;
          item.role = member.role_name;
          return item;
        });
      })
    );
  }

  getMemberDetailsByID$(id: string): Observable<IChatMember> {
    return this._selectors.selectedChatMembers$.pipe(
      map(members => {
        return members.find(member => member.user_id === id);
      })
    );
  }
  getMemberSubChats$(userId: string) {
    return this._selectors.getMemberSubChats$(userId);
  }
  getSubChatMember$(userId: string, chatId: string) {
    return this._selectors.getSubChatMember$(userId, chatId);
  }
  getSubChatMembers$(chatId: string) {
    return this._selectors.getSubChatMembers$(chatId);
  }
  getSubChatAdmins$(chatId: string) {
    return this._selectors.getSubChatAdmins$(chatId);
  }

  getNextPage(name?: string): void {
    this._membersUiDispatchers.getNextMembersPage();
  }

  getPreviousPage(name?: string): void {
    this._membersUiDispatchers.getPrevMembersPage();
  }

  resetPaging(
    name?: string,
    status?: any,
    view?: number,
    accountTypes?: string[]
  ): void {
    this._membersUiDispatchers.resetMembersPaging(
      name,
      this.searchBy,
      status,
      view,
      accountTypes
    );
  }

  get membersUI$() {
    return this._selectors.membersUi$;
  }

  get statusUI$() {
    return this._selectors.membersUi$.pipe(
      map(ui => {
        const abstractUI: AbstractUI = {};
        abstractUI.status = ui.status;
        return abstractUI;
      })
    );
  }

  setSearchBy(searchBy: number) {
    this.searchBy = searchBy;
  }

  deleteUser(userID: string, chatID: string, ref: string) {
    this._membersUiDispatchers.deleteUser(userID, chatID, ref);
  }

  banMemberFromChat(userID: string, chatID: string) {
    this._membersUiDispatchers.banUser(userID, chatID);
  }

  unBanMemberFromChat(userID: string, chatID: string) {
    this._membersUiDispatchers.unbanUser(userID, chatID);
  }

  getBuyerUser$(userId: string) {
    return this._selectors.getProfileByID$(userId);
  }

  // getChatMembers(id) {
  //   console.log('here in service');
  //   this._chatMemberDispatchers.listChatMembers(id);
  // }
}
