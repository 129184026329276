/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./add-icon.component";
var styles_AddIconComponent = ["[_nghost-%COMP%] {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }"];
var RenderType_AddIconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AddIconComponent, data: {} });
export { RenderType_AddIconComponent as RenderType_AddIconComponent };
export function View_AddIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon clickable-cursor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-edit"]], null, null, null, null, null))], null, null); }
export function View_AddIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-icon", [], null, null, null, View_AddIconComponent_0, RenderType_AddIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.AddIconComponent, [], null, null)], null, null); }
var AddIconComponentNgFactory = i0.ɵccf("app-add-icon", i1.AddIconComponent, View_AddIconComponent_Host_0, {}, {}, []);
export { AddIconComponentNgFactory as AddIconComponentNgFactory };
