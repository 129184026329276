import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

import { CalendarsPoolComponent } from './calendars-pool/calendars-pool.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule
  ],
  declarations: [CalendarsPoolComponent],
  exports: [CalendarsPoolComponent]
})
export class CalendarsPoolModule {}
