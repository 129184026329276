import { IMediaCenter, MediaCenterRcvd } from 'src/models/IMediaCenter';

export class MediaCenterMiddleware {
  public static mapMediaGallaeryRcvdToMediaCenter(item: MediaCenterRcvd) {
    const mediaItem: IMediaCenter = {};
    mediaItem.url = item.response ? item.response.url : null;
    mediaItem.file = item.response ? item.response.file : null;
    mediaItem.filename = item.response ? item.filename : null;
    mediaItem.width = item.width;
    mediaItem.height = item.height;
    mediaItem.duration = item.duration;
    mediaItem.type = item.type;
    mediaItem.name = item.name;
    mediaItem.size = item.size;
    return mediaItem;
  }
}
