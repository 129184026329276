import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import * as AdsProvidersActions from './adsProvider.actions';

import { AppState } from 'src/models/AppState';
import { AdsConfig } from 'src/models/constants';
import { KeysConfig } from './../../../models/constants';

@Injectable({
  providedIn: 'root'
})
export class AdsProvidersDispatchers {
  constructor(private _store: Store<AppState>) {}

  getAdsProviders(adsProviderId): void {
    this._store.dispatch(
      new AdsProvidersActions.GetAdsProviders(adsProviderId)
    );
  }

  receiveAdsProviders(adsProviders: AdsConfig): void {
    this._store.dispatch(
      new AdsProvidersActions.ReceiveAdsProvider(
        adsProviders.provider_id,
        adsProviders.config
      )
    );
  }

  receivePaymentProvider(adsProviderId: string, config: KeysConfig): void {
    this._store.dispatch(
      new AdsProvidersActions.ReceiveAdsProvider(adsProviderId, config)
    );
  }

  setAdsProvider(adsProviderId: string, config: KeysConfig): void {
    this._store.dispatch(
      new AdsProvidersActions.SetAdsProvider(adsProviderId, config)
    );
  }
}
