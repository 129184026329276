import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { TagFormModule } from '../tags/tag-form.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

import { TagsSelectorComponent } from './tags-selector/tags-selector.component';
import { AssignTagsComponent } from './assign-tags/assign-tags.component';
import { TagsTableComponent } from './tags-table/tags-table.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AccessorsModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    TagFormModule
  ],
  declarations: [
    AssignTagsComponent,
    TagsSelectorComponent,
    TagsTableComponent
  ],
  exports: [AssignTagsComponent, TagsSelectorComponent, TagsTableComponent]
})
export class TagsPoolModule {}
