import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angular2-qrcode';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractControlsModule } from 'src/app/abstract-controls/abstract-controls.module';

import { AuthComponent } from './auth.component';
import { ScanComponent } from './scan/scan.component';
import { LoginComponent } from './login/login.component';
import { AuthListComponent } from './auth-list/auth-list.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SupportLogInComponent } from './support-log-in/support-log-in.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    QRCodeModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractControlsModule
  ],
  declarations: [
    ScanComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    AuthListComponent,
    AuthComponent,
    SupportLogInComponent
  ]
})
export class AuthModule {}
