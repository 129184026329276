import { Store } from '@ngrx/store';
import * as EventUIActions from './eventUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class EventUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listEventUI() {
        this._store.dispatch(new EventUIActions.ListEventUI());
    }
    createEventUI() {
        this._store.dispatch(new EventUIActions.CreateEventUI());
    }
    viewEventUI(selectedEvent) {
        this._store.dispatch(new EventUIActions.ViewEventUI(selectedEvent));
    }
    editEventUI(selectedEvent) {
        this._store.dispatch(new EventUIActions.EditEventUI(selectedEvent));
    }
    uploadEventImage(selectedFile) {
        this._store.dispatch(new EventUIActions.UploadEventImage(selectedFile));
    }
    eventPhotoUploadUI(createdEvent) {
        this._store.dispatch(new EventUIActions.EventPhotoUploadUI(createdEvent));
    }
    eventCreationComplete() {
        this._store.dispatch(new EventUIActions.EventCreationComplete());
    }
    closeViewEventUI() {
        this._store.dispatch(new EventUIActions.CloseViewEventUI());
    }
    closeEventEditUI() {
        this._store.dispatch(new EventUIActions.CloseEventEditUI());
    }
    //
    // paging dispatchers
    getNextEventPage() {
        this._store.dispatch(new EventUIActions.GetNextEventPage());
    }
    getPrevEventPage() {
        this._store.dispatch(new EventUIActions.GetPrevEventPage());
    }
    receivedEventHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new EventUIActions.ReceivedEventHistoryChunk(eop, sop, currentPage));
    }
    resetEventPaging() {
        this._store.dispatch(new EventUIActions.ResetEventPaging());
    }
}
EventUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventUiDispatchers_Factory() { return new EventUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: EventUiDispatchers, providedIn: "root" });
