import {
  Input,
  Directive,
  OnChanges,
  ElementRef,
  Renderer2
} from '@angular/core';

import { MessageTypes } from 'src/models/constants';

@Directive({
  selector: '[appMessageTypesIcon]'
})
export class MessageTypesIconDirective implements OnChanges {
  @Input()
  appMessageTypesIcon: string;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges() {
    switch (this.appMessageTypesIcon) {
      case MessageTypes.PHOTO:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-photo'
        );
        break;
      case MessageTypes.VIDEO:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-video'
        );
        break;
      case MessageTypes.AUDIO:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-audio'
        );
        break;
      case MessageTypes.VOICE:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-voice'
        );
        break;
      case MessageTypes.LOCATION:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-marker'
        );
        break;
      case MessageTypes.STICKER:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-smile'
        );
        break;
      case MessageTypes.GIF:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-gif'
        );
        break;
      case MessageTypes.CONTACT:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-contact'
        );
        break;
      case MessageTypes.DOCUMENT:
        this._renderer.setAttribute(
          this._elRef.nativeElement,
          'xlink:href',
          './assets/svg/app-sections.svg#icon-file'
        );
        break;
      default:
        break;
    }
  }
}
