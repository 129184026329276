import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { mStoreScreens, MarketTypes, ChatTypes } from 'src/models/constants';
import { MStore, StoreItem, MStoreItem } from 'src/models/Stores';
import { StoreUiDispatchers } from 'src/app/store/storesUI/storeUI.dispatchers';
import { IChat } from 'src/models/IChat';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MobileStoresService {
  private _selectedStore = new BehaviorSubject<MStore>(null);
  public selectedStore$ = this._selectedStore.asObservable();

  private _currentStoreScreen = new BehaviorSubject<string>(
    mStoreScreens.mStore_LIST
  );
  public currentStoreScreen$ = this._currentStoreScreen.asObservable();
  marketTypes = MarketTypes;
  chatTypes: typeof ChatTypes = ChatTypes;
  confirmMstor = false;
  selectedMstoreItem: any;
  constructor(
    private _rootStore: RootStoreSelectors,
    private _storeDispatchers: StoreUiDispatchers
  ) {}

  get stores$() {
    return this._rootStore.getStores$();
  }

  getMStoreById(id: string) {
    this._storeDispatchers.getStore(id);
  }

  resetPaging(selectedChatId: string = null) {
    this._storeDispatchers.setStoreSelectedChat(selectedChatId);
  }
  getProduct$(id: string) {
    return this._rootStore.getProductById$(id);
  }
  getBundle$(id: string) {
    return this._rootStore.getBundle$(id);
  }
  getPackage$(id: string) {
    return this._rootStore.getPackageById$(id);
  }
  isBundle(prod: StoreItem) {
    return prod && prod.type === this.marketTypes.BUNDLE;
  }
  isPackage(prod: StoreItem) {
    return prod && prod.type === this.marketTypes.PACKAGE;
  }
  isProduct(prod: StoreItem) {
    return prod && prod.type === this.marketTypes.PRODUCT;
  }
  updateStore(store: MStore) {
    if (store && store.id) {
      this._storeDispatchers.setStore(store.id, store);
    } else {
      this._storeDispatchers.createStore(store);
    }
    this.closeItemCardEvent();
  }

  setStoreItem(storeId: string, items: StoreItem[]) {
    const itemsIds: MStoreItem[] = items.map(item => {
      return {
        id: item.id,
        type: item.type
      };
    });
    this._storeDispatchers.addStoreItem(storeId, itemsIds);
  }
  deleteMStore(id: string) {
    this._storeDispatchers.deleteStoreMobile(id);
  }
  toggleDeleteConfirmMstore(e: boolean, mStore?: any) {
    this.confirmMstor = e;
    this.selectedMstoreItem = mStore;
  }
  removeStoreItem(storeId: string, items: any[]) {
    this._storeDispatchers.removeStoreItem(storeId, items);
  }

  assignStoreChannel(storeId: string, chatIds: any[]) {
    this._storeDispatchers.assignStoreChannel(storeId, chatIds);
  }

  createItemEvent() {
    this.setCurrentScreen(mStoreScreens.mStore_CREATION);
    this.setSelectedStore(null);
  }
  readItemEvent(store: MStore) {
    this.setCurrentScreen(mStoreScreens.mStore_VIEW);
    this.setSelectedStore(store);
  }
  updateItemEvent(store: MStore) {
    this.setCurrentScreen(mStoreScreens.mStore_EDIT);
    this.setSelectedStore(store);
  }
  closeItemCardEvent() {
    this.setCurrentScreen(mStoreScreens.mStore_LIST);
    this.setSelectedStore(null);
  }

  setSelectedStore(store: MStore) {
    this._selectedStore.next(store);
  }

  setCurrentScreen(screen: string) {
    this._currentStoreScreen.next(screen);
  }
  getNextPage() {
    this._storeDispatchers.getNextStorePage();
  }

  getPreviousPage() {
    this._storeDispatchers.getPrevStorePage();
  }
  getGroup$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
  isGroup(prod: IChat) {
    return (
      prod &&
      (prod.type === this.chatTypes.GROUP ||
        prod.type === this.chatTypes.CHANNEL)
    );
  }
  getMStoreDetailsById(id: string): Observable<MStore> {
    return this._rootStore.getMStoreByID$(id);
  }

  getChannelDetails$(id: string) {
    return this._rootStore.getGroupDetailsByID$(id);
  }
}
