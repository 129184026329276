<div style="display: flex; align-items: center; margin: 0 5px;">
  <svg
    (mouseover)="toggleTooltipVisibility(true)"
    (mouseleave)="toggleTooltipVisibility(false)"
    class="svg-icon icon-icon clickable-cursor"
  >
    <use
      [attr.xlink:href]="'./assets/svg/app-sections.svg#icon-info-tooltip'"
    ></use>
  </svg>

  <div *ngIf="showTooltip" class="tooltip-text">
    <div class="tooltip-title">{{ title }}</div>
    <div class="tooltip-description">
      {{ description }}
    </div>
  </div>
</div>
