/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./signup/signup.component.ngfactory";
import * as i3 from "./signup/signup.component";
import * as i4 from "../../validators/form-validators.service";
import * as i5 from "../../core/auth.service";
import * as i6 from "../../core/analytics.service";
import * as i7 from "@angular/common";
import * as i8 from "./registration.component";
import * as i9 from "@angular/router";
var styles_RegistrationComponent = [i0.styles];
var RenderType_RegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistrationComponent, data: {} });
export { RenderType_RegistrationComponent as RenderType_RegistrationComponent };
export function View_RegistrationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "app-logo clickable-cursor"], ["href", "https://nandbox.com/en/"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "./assets/img/logo-w-text.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-signup", [["class", "auth"]], null, null, null, i2.View_SignupComponent_0, i2.RenderType_SignupComponent)), i1.ɵdid(3, 638976, null, 0, i3.SignupComponent, [i1.ChangeDetectorRef, i4.FormValidatorsService, i5.AuthService, i6.AnalyticsService], { countryIso: [0, "countryIso"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(((_co.authService == null) ? null : _co.authService.authCollection$)))) == null) ? null : tmp_0_0.countryIso); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registration", [], null, null, null, View_RegistrationComponent_0, RenderType_RegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i8.RegistrationComponent, [i9.ActivatedRoute, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistrationComponentNgFactory = i1.ɵccf("app-registration", i8.RegistrationComponent, View_RegistrationComponent_Host_0, {}, {}, []);
export { RegistrationComponentNgFactory as RegistrationComponentNgFactory };
