import { Store } from '@ngrx/store';
import * as ContainerActions from './container.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ContainerDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    updateContainer(componentRef, containerUpdatedProps) {
        this._store.dispatch(new ContainerActions.UpdateContainer(componentRef, containerUpdatedProps));
    }
    reorderContainer(containerRef, newPosition) {
        this._store.dispatch(new ContainerActions.ReorderContainer(containerRef, newPosition));
    }
    deleteContainerRequest(container) {
        this._store.dispatch(new ContainerActions.DeleteContainerRequest(container));
    }
    deleteContainer(container) {
        this._store.dispatch(new ContainerActions.DeleteContainer(container));
    }
    addSectionRequest(parentTabRef, specialContainerValues) {
        this._store.dispatch(new ContainerActions.AddSectionRequest(parentTabRef, specialContainerValues));
    }
    addContainerRequest(parentAppClass, specialContainerValues) {
        this._store.dispatch(new ContainerActions.AddContainerRequest(parentAppClass, specialContainerValues));
    }
    addContainer(container) {
        this._store.dispatch(new ContainerActions.AddContainer(container));
    }
    selectContainer(selectedContainer) {
        this._store.dispatch(new ContainerActions.SelectContainer(selectedContainer));
    }
    selectFirstContainer(appClassName) {
        this._store.dispatch(new ContainerActions.SelectFirstContainer(appClassName));
    }
    updateTabType(containerRef, tabType, tabID) {
        this._store.dispatch(new ContainerActions.UpdateTabType(containerRef, tabType, tabID));
    }
    updateTabTypeRequest(containerRef, tabType, pageId) {
        this._store.dispatch(new ContainerActions.UpdateTabTypeRequest(containerRef, tabType, pageId));
    }
    receiveTagValues(tabsTags, isChannelConfigOnly) {
        this._store.dispatch(new ContainerActions.ReceiveTagValues(tabsTags, isChannelConfigOnly));
    }
    updateMapContainerOnPublish(isChannelOnly) {
        this._store.dispatch(new ContainerActions.UpdateMapContainerOnPublish(isChannelOnly));
    }
}
ContainerDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContainerDispatchers_Factory() { return new ContainerDispatchers(i0.ɵɵinject(i1.Store)); }, token: ContainerDispatchers, providedIn: "root" });
