import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  FACEBOOK_SHARE_LINK,
  TWITTER_SHARE_LINK,
  GOOGLEPLUS_SHARE_LINK,
  LINK_SHARED_SUCCESSFULLY,
  LINK_SHARED_SUCCESSFULLY_TITLE
} from 'src/models/constants';

import { AbstractNotifiersService } from 'src/app/abstract-notifiers/abstract-notifiers.service';

@Component({
  selector: 'app-channel-share',
  templateUrl: './channel-share.component.html',
  styleUrls: ['./channel-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelShareComponent implements OnChanges {
  @Input() shareLink: string;
  @Input() screenType: string;
  tooltipText: string;

  sharedWindow = null;
  shareOn = '';
  hoverFacebook = false;
  hoverTwitter = false;
  hoverCopyLink = false;
  hoverShareLink = false;

  isCopy = false;

  constructor(private _abstractNotifier: AbstractNotifiersService) {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['shareLink'];
    if (chng && chng.currentValue && chng.currentValue !== chng.previousValue) {
      this.changeSocialWindowLocation();
    }
  }

  copySuccessfullyEvent() {
    if (!this.isCopy) {
      this._abstractNotifier.showSnakbarNotifierMessage(
        LINK_SHARED_SUCCESSFULLY,
        true,
        LINK_SHARED_SUCCESSFULLY_TITLE,
        true
      );
      this.isCopy = true;
    }

    setTimeout(() => (this.isCopy = false), 5000);
  }

  shareOnSocial(e: Event, network: string) {
    const w = 580;
    const h = 296;
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    const windowStyles = `toolbar=no,
                          location=no,
                          directories=no,
                          status=no,
                          menubar=no,
                          scrollbars=no,
                          resizable=no,
                          copyhistory=no,
                          width=${w},
                          height=${h},
                          top=${top},
                          left=${left}`;
    if (!this.shareLink) {
      this.shareOn = network;
    }
    if (e) {
      e.preventDefault();
      switch (network) {
        case 'F':
          this.sharedWindow = window.open(
            FACEBOOK_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        case 'T':
          this.sharedWindow = window.open(
            TWITTER_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        case 'G':
          this.sharedWindow = window.open(
            GOOGLEPLUS_SHARE_LINK + this.shareLink,
            'Share',
            windowStyles
          );
          break;
        default:
          break;
      }
    }
  }

  changeSocialWindowLocation() {
    switch (this.shareOn) {
      case 'F':
        this.sharedWindow.location = FACEBOOK_SHARE_LINK + this.shareLink;
        break;
      case 'T':
        this.sharedWindow.location = TWITTER_SHARE_LINK + this.shareLink;
        break;
      case 'G':
        this.sharedWindow.location = GOOGLEPLUS_SHARE_LINK + this.shareLink;
        break;
      default:
        break;
    }
  }

  toggleHoverTooltip(type, status: boolean) {
    switch (type) {
      case 'S':
        this.hoverShareLink = status;
        this.tooltipText = `Share your ${this.screenType}'s link`;
        break;
      case 'F':
        this.hoverFacebook = status;
        this.tooltipText = `Share on Facebook`;
        break;
      case 'T':
        this.hoverTwitter = status;
        this.tooltipText = `Share on Twitter`;
        break;
      case 'G':
        this.hoverCopyLink = status;
        this.tooltipText = `Copy ${this.screenType}'s link`;
        break;
    }
  }
}
