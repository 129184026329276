<div class="popup-settings">
  <div class="popup-settings-header">
    <div class="header-text">
      <span>
        {{ title }}
      </span>
      <span class="desc">
        {{ desc }}
      </span>
    </div>
    <svg class="svg-icon icon-icon" (click)="closePopup()" *ngIf="!hideclose">
      <use xlink:href="/assets/svg/app-sections.svg#icon-close-circle"></use>
    </svg>
  </div>
  <div class="popup-settings-body"><ng-content></ng-content></div>
  <div class="popup-settings-footer" *ngIf="!hideFooter">
    <!-- <app-border-btn next [type]="'submit'">
      {{ buttonTitle }}
    </app-border-btn> -->
    <button (click)="onSave()">{{ buttonTitle }}</button>
  </div>
</div>
