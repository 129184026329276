<div class="no-support">
  <a class="app-logo clickable-cursor" href="https://nandbox.com/en/">
    <img src="./assets/img/logo-w-text.svg" />
  </a>
  <div class="no-support-header">
    You Need A bigger Screen
  </div>
  <div class="no-support-tagline">
    <app-link-btn [openSelf]="true" [href]="'https://nandbox.com/en/'">
      <ng-container ngProjectAs="link-content">
        nandbox
      </ng-container>
    </app-link-btn>
    Works Best on Desktop
  </div>

  <div class="no-support-browsers">
    <div class="no-support-recommended">
      Recommended Browsers
    </div>

    <div class="no-support-working">
      <figure>
        <a class="clickable-cursor" href="https://www.google.com/chrome/">
          <img src="./assets/img/chrome-browser.png" alt="chrome" />
        </a>
        <figcaption>
          <app-link-btn [href]="'https://www.google.com/chrome/'">
            <ng-container ngProjectAs="link-content">
              Google Chrome
            </ng-container>
          </app-link-btn>
        </figcaption>
      </figure>
      <figure>
        <a class="clickable-cursor" href="https://www.opera.com/">
          <img src="./assets/img/opera-browser.png" alt="chrome" />
        </a>
        <figcaption>
          <app-link-btn [href]="'https://www.opera.com/'">
            <ng-container ngProjectAs="link-content">
              opera
            </ng-container>
          </app-link-btn>
        </figcaption>
      </figure>
    </div>

    <div class="no-support-footer">
      Back to&nbsp;
      <app-link-btn [openSelf]="true" [href]="'https://nandbox.com/en/'">
        <ng-container ngProjectAs="link-content">
          nandbox
        </ng-container>
      </app-link-btn>
    </div>
  </div>
</div>
