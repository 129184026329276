import {
  Component,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SubChatsService } from '../../sub-chats/sub-chats.service';

import { SUBGROUPS, SUBCHANNELS } from 'src/models/AppSections';
import { PrivilegesName } from 'src/models/privileges';
import { IChat } from 'src/models/IChat';
import { ChatTypes } from 'src/models/constants';

@Component({
  selector: 'app-sub-chats-selector',
  templateUrl: './sub-chats-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubChatsSelectorComponent implements OnInit {
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectChatsNotfiy = new EventEmitter<IChat[]>();

  currentSelectChats: IChat[] = [];
  showCompose = false;

  private _searchTerm = new BehaviorSubject<string>('');
  public searchTerm$ = this._searchTerm.asObservable();
  subGroupFet = SUBGROUPS;
  subChannelFet = SUBCHANNELS;

  chatTypes: typeof ChatTypes = ChatTypes;

  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(public subChatsService: SubChatsService) {}

  ngOnInit() {
    this.subChatsService.resetPaging(this.chatTypes.GROUP);
    this.subChatsService.resetPaging(this.chatTypes.CHANNEL);
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(prod: IChat) {
    this.currentSelectChats.push(prod);
  }
  resetSelectMessage(prod: IChat) {
    this.currentSelectChats = this.currentSelectChats.filter(
      res => res.id !== prod.id
    );
  }
  isProductSelected(prodId: string) {
    return this.currentSelectChats.filter(res => res.id === prodId).length > 0;
  }

  insertEvent() {
    this.selectChatsNotfiy.emit(this.currentSelectChats);
  }

  addNewEvent(e: boolean) {
    this.showCompose = e;
  }

  onSearchFormSubmit(term: string): void {
    this._searchTerm.next(term);
  }

  TrackByFunction(index, item: IChat) {
    return item.id;
  }
}
