<div class="main-header no-copyable-item">
  <div class="main-header-sidenav clickable-cursor">
    <div class="fas-container">
      <div class="fas-border" (click)="toggleSidenavEvent()">
        <i class="fas fa-bars"></i>
      </div>
      <div class="fas-separetor"></div>
    </div>
  </div>
  <app-navbar-pagename></app-navbar-pagename>

  <div class="full-w"></div>

  <!-- <div class="search">
    <input
      placeholder="Search"
      class="form-control search-input"
      aria-label="search input"
      [formControl]="searchTerm"
    />
    <img class="search-icon" src="./assets/img/search.svg" alt="search" />
  </div> -->
  <div class="top-icons">
    <div class="notification clickable-cursor" (click)="selectTalkToAdmin()">
      <svg class="clickable-cursor svg-icon">
        <use xlink:href="./assets/svg/app-sections.svg#icon-bell"></use>
      </svg>
      <span class="messages"></span>
    </div>

    <ng-container *appShowIfHasPrivilege="privilegesName.editsettings">
      <ng-container *appHideIfSubChat="privilegesName.editsettings">
        <svg
          (click)="sectionEvent(uiSections.SETTINGS)"
          class="clickable-cursor svg-icon icon-icon"
        >
          <use xlink:href="./assets/svg/app-sections.svg#icon-settings"></use>
        </svg>
      </ng-container>
    </ng-container>
    <app-lang-selector></app-lang-selector>
    <!-- Chats -->
    <div
      class="btn-group clickable-cursor list"
      #dd="bs-dropdown"
      dropdown
      appClickElsewhere
      (clickElsewhere)="dd.hide()"
    >
      <app-profile-image
        dropdownToggle
        class="head"
        [id]="(layoutService.selectedChat$ | async)?.id"
        [chatType]="'Channel'"
        [size]="32"
      >
      </app-profile-image>
      <ul *dropdownMenu class="dropdown-menu no-copyable-item" role="menu">
        <div class="main">
          <div class="title">
            Main App
          </div>
          <li
            class="menuitem clickable-cursor"
            role="menuitem"
            type="button"
            *ngFor="
              let channel of layoutService.allChannels$ | async;
              trackBy: TrackByFunction
            "
            (click)="selectChatEvent(channel)"
          >
            <app-profile-cell
              [chat]="channel"
              *ngIf="!channel?.subChannel"
            ></app-profile-cell>
          </li>
        </div>
        <div class="sub">
          <div class="title">
            Sub Channels
          </div>
          <ng-container
            *ngIf="(layoutService.allChannels$ | async)?.length > 1"
          >
            <li
              class="menuitem clickable-cursor"
              role="menuitem"
              type="button"
              *ngFor="
                let channel of layoutService.allChannels$ | async;
                trackBy: TrackByFunction
              "
              (click)="selectChatEvent(channel)"
            >
              <app-profile-cell
                [chat]="channel"
                *ngIf="channel?.subChannel"
              ></app-profile-cell>
            </li>
          </ng-container>
          <ng-container
            *ngIf="(layoutService.allChannels$ | async)?.length <= 1"
          >
            <div class="no-channels">
              There is no Sub Channel
            </div>
          </ng-container>
        </div>
        <li
          class="menuitem-logout clickable-cursor"
          role="menuitem"
          type="button"
          (click)="logoutEvent()"
        >
          <a>Log Out</a>
        </li>
      </ul>
    </div>
    <!-- End: Chats -->
  </div>
</div>
