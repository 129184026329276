import { ISection } from './ISection';
import { PrivilegesName } from './privileges';
import { UISections, ChannelAppScreens } from './constants';
export const LANG_EN_TRANS = {
  nandbox: 'nandbox',
  reply: 'Reply',
  replyToAll: 'Replay to all',
  storeConsol: 'Store Consol',
  inbox: 'Inbox',
  sent: 'Sent',
  settings: 'Change Password',
  billingSettings: 'Billing',
  uploadTemps: 'Upload Templates',
  menus: 'Polls',
  bulkUpload: 'Bulk upload',
  botsManager: 'API and Integrations',
  tagsAndSubGroups: 'Tags & subgroups',
  manageTag: 'Tags & Membership',
  manageSubgroup: 'Groups',
  manageMenu: 'Manage Menus',
  manageEvents: 'Manage Events',
  instantArticle: 'Instant View',
  posts: 'Posts',
  messages: 'Messages',
  mediaCenter: 'Media Center',
  schedule: 'Schedule',
  compose: 'compose',
  members: 'Members',
  image: 'image',
  video: 'video',
  audio: 'audio',
  voice: 'voice',
  location: 'location',
  sticker: 'sticker',
  contact: 'contact',
  all: 'All',
  none: 'none',
  read: 'read',
  unread: 'unread',
  saved: 'Saved',
  save: 'Save',
  channelName: 'ChannelName',
  nameIsRequired: 'Name Is Required',
  nameMustBeAtLeast1CharactersLong: 'Name must be at least 1 characters long.',
  nameCannotBeMoreThan23CharactersLong:
    'Name cannot be more than 23 characters long.',
  description: 'Description',
  descriptionCannotBeMoreThan101CharactersLong:
    'Description cannot be more than 101 characters long.',
  category: 'Category',
  categoryIsRequired: 'Category Is Required',
  social: 'Social',
  entertainment: 'Entertainment',
  'Health&Finess': 'Health & Finess',
  lifestyle: 'Lifestyle',
  media: 'Media',
  'News&Magazines': 'News & Magazines',
  sports: 'Sports',
  education: 'Education',
  corporate: 'Corporate',
  goverment: 'Goverment',
  professional: 'Professional',
  'Stores&Retail': 'Stores & Retail',
  religion: 'Religion',
  portal: 'portal',
  back: 'Back',
  logout: 'logout',
  selectChannel: 'Select a Channel',
  youDoNotHaveBusinessChannels: 'You do not have a Business Channel',
  useNandboxOnYourPhoneToScanTheCode:
    'Use nandbox on your phone to scan the code',
  scanTheCode: 'Scan the code',
  reloadQRCode: 'Reload QR Code',
  keepMeSignedIn: 'Keep me signed in',
  openNandboxOnYourDevice: 'Open nandbox on your device',
  android: 'Android',
  ios: 'IOS',
  home: 'Home',
  manageYourApp: 'Design Your App',
  branding: 'Branding',
  splash: 'Splash',
  login: 'Login',
  profile: 'Profile',
  tabs: 'Tabs',
  tabsDetails: 'Tabs Details',
  navMenu: 'Side Menu',
  appRelesses: 'App Relesses',
  appActivation: 'App Activation',
  features: 'Home Menu',
  featuresDetails: 'Features',
  appSettings: 'App Settings',
  releases: 'Build & Publish',
  publish: 'Publish',
  activateYourApp: 'Activation',
  indexData: 'Index Data',
  manageYourChannel: 'Manage Your Channel',
  manageUsers: 'Users',
  usersManagement: 'User Management',
  tagingManagement: 'User Tagging',
  campaign: 'Campaigns',
  coupon: 'Coupons',
  scheduleAMessage: 'Schedule Message',
  myAccount: 'My account',
  createCoupon: 'Create Coupon',
  createCampaign: 'Create Campaign',
  createMenu: 'Create Menu',
  editCoupon: 'Edit Coupon',
  editCampaign: 'Edit Campaign',
  createInDate: 'Create in Date',
  title: 'Title',
  couponCode: 'Coupon Code',
  expireDate: 'Expire Date',
  share: 'Share',
  value: 'Value',
  status: 'Status',
  campaignName: 'Campaign Name',
  dateDuration: 'Date Duration',
  days: 'Days',
  specificHours: 'Specific Hours',
  scope: 'Scope',
  events: 'Events',
  booking: 'Booking',
  mStore: 'mStore',
  calendar: 'Calendar',
  timeZone: 'Time Zone',
  createEvent: 'Create Event'
};
export const HOME: ISection = {
  section: UISections.HOME,
  title: 'Dashboard',
  description: 'Welcome to your nandbox Dashboard',
  symbolImage: 'Dashboard',
  symbolColor: '#2ED473',
  listOnHome: false,
  coverColor: null,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.dashboard, // just to make sure that home listed
  subMenuSections: [UISections.HOME]
};

export const VAPP: ISection = {
  section: UISections.CHANNEL,
  title: LANG_EN_TRANS.manageYourChannel,
  description: 'Manage your channel.',
  symbolImage: 'icon-manage-channels',
  symbolColor: '#4150a8',
  listOnHome: true,
  coverColor: `linear-gradient(145deg, #4150a8, #5e70d3)`,
  showSubMenu: false,
  subMenuScreen: null,
  hideOnSub: false,
  hideOnMain: true,
  privilegeName: PrivilegesName.listappchannel,
  subMenuSections: [UISections.CHANNEL],
  subMenu: [
    {
      section: UISections.CHANNEL,
      title: LANG_EN_TRANS.features,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.TABS
    },
    {
      section: UISections.CHANNEL,
      title: LANG_EN_TRANS.publish,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.PUBLISH
    }
  ]
};

export const APPSEC: ISection = {
  section: UISections.APP,
  title: 'Design Your App',
  description: 'Manage and edit your app.',
  symbolImage: 'icon-phone',
  symbolColor: '#2ED473',
  listOnHome: true,
  coverColor: `linear-gradient(145deg, #2ED473, #32B8BE)`,
  showSubMenu: false,
  subMenuScreen: null,
  hideOnSub: true,
  hideOnMain: false,
  privilegeName: PrivilegesName.listapp,
  subMenuSections: [UISections.APP],
  subMenu: [
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.branding,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.UPLOAD_LOGO
    },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.splash,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.SPLASH
    // },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.login,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.LOGIN
    // },
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.profile,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.PROFILE
    // },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.features,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.TABS
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.navMenu,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.NAV_MENU
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.appSettings,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.MAIN
    },
    {
      section: UISections.APP,
      title: LANG_EN_TRANS.releases,
      description: null,
      symbolImage: null,
      symbolColor: null,
      listOnHome: false,
      coverColor: null,
      showSubMenu: null,
      subMenuScreen: ChannelAppScreens.APP_RELEASES
    }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.publish,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.PUBLISH
    // }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.activateYourApp,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.ACTIVATION
    //  }
    // {
    //   section: UISections.APP,
    //   title: LANG_EN_TRANS.indexData,
    //   description: null,
    //   symbolImage: null,
    //   symbolColor: null,
    //   listOnHome: false,
    //   coverColor: null,
    //   showSubMenu: null,
    //   subMenuScreen: ChannelAppScreens.SEARCH
    // }
  ]
};

export const POSTS: ISection = {
  section: UISections.POSTS,
  title: 'Posts',
  description: 'Send posts and messages to your users',
  symbolImage: 'icon-posts',
  symbolColor: '#43B2E9',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmessage,
  subMenuSections: [UISections.POSTS],
  emptyTitle: 'No Messages Found',
  emptyDesc:
    'Click the icon on the right bottom to create new posts, and notify your app users.',
  emptyPoolDesc: 'You can add a new post from the add new field.'
};

export const SCHEDULES: ISection = {
  section: UISections.SCHEDULE,
  title: 'Scheduled Posts',
  description: `Schedule and manage your messages ahead of time.`,
  symbolImage: 'icon-schedules',
  symbolColor: '#C94D5C',
  listOnHome: true,
  coverColor: `linear-gradient(134deg, #FB6B77, #A73546)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmessage,
  subMenuSections: [UISections.SCHEDULE],
  emptyTitle: 'No Schedule Messages Found',
  emptyDesc:
    'Click the icon on the right bottom to create and schedule posts, broadcast news, and choose when to notify your users.',
  emptyPoolDesc: 'You can add a new schedule message from the add new field.'
};

export const EVENTS: ISection = {
  section: UISections.EVENTS,
  title: 'Events',
  description: 'Create and manage events for your Users to join.',
  symbolImage: 'icon-events',
  symbolColor: '#ee576b',
  symbolLightColor: '#fad8ee',
  listOnHome: true,
  coverColor: `linear-gradient(133deg, #ff6683 4%, #ffaf91 98%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listevent,
  subMenuSections: [UISections.EVENTS],
  emptyTitle: 'No Events founds',
  emptyDesc: 'Click here to create a new event.',
  emptyPoolDesc:
    'You can add a new Event from the add new field in events section.'
};

export const CAMPAIGNS: ISection = {
  hasCategory: true,
  section: UISections.CAMPAIGNS,
  title: 'Campaigns',
  description: 'Create and mange engagement competitions.',
  symbolImage: 'icon-campaigns',
  symbolColor: '#ECA04D',
  symbolLightColor: '#fbe4cc',
  listOnHome: true,
  coverColor: `linear-gradient(134deg, #FAD961, #E47A3E)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcampaign,
  subMenuSections: [UISections.CAMPAIGNS],
  emptyTitle: 'No Campaigns Found',
  emptyDesc: 'You can create a new Campaign by clicking here.',
  emptyPoolDesc:
    'You can add a new Campaign from the add new field in capmaigns section.'
};

export const COUPONS: ISection = {
  hasCategory: true,
  section: UISections.COUPONS,
  title: 'Coupons',
  description: 'Edit your coupon after its creation to activate it.',
  symbolImage: 'icon-coupons',
  symbolColor: '#6F6CD2',
  symbolLightColor: '#dfd7fb',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #A393EB, #5153C2)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcoupon,
  subMenuSections: [UISections.COUPONS],
  emptyTitle: 'No Coupons Found',
  emptyDesc: 'Click here to create a new coupon.',
  emptyPoolDesc:
    'You can add a new Coupon from the add new field in coupons section.'
};

export const USERS: ISection = {
  section: UISections.USERS,
  title: 'Users',
  description: 'Manage your signed-up App users.',
  symbolImage: 'icon-users',
  symbolColor: '#59CDEE',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmember,
  subMenuSections: [UISections.USERS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have users yet, The list is empty.'
};
export const MAPSERVICE: ISection = {
  section: UISections.MAPSERVICE,
  title: 'Map Services',
  description: '',
  symbolImage: 'icon-location',
  symbolColor: '#5845ea',
  symbolLightColor: '#ded9ff',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #5845ea, #7867fc)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmapservices,
  subMenuSections: [UISections.MAPSERVICE],
  emptyTitle: 'Empty!',
  emptyDesc: 'Click here to create a new Map Services.'
};
export const MAPROUTES: ISection = {
  section: UISections.MAPROUTES,
  title: 'Map Routes',
  description: '',
  symbolImage: 'icon-route',
  symbolColor: '#39b5be',
  symbolLightColor: '#ace6ea',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #3fb3bb, #59c7cf)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listroutes,
  subMenuSections: [UISections.MAPROUTES],
  emptyTitle: 'Empty!',
  emptyDesc: 'Click here to create a new Map Routes.'
};
export const TRIPS: ISection = {
  section: UISections.TRIPS,
  title: 'Trips',
  description: '',
  symbolImage: 'icon-road',
  symbolColor: '#926277',
  symbolLightColor: '#d8c5ce',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #926277, #be92a5)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtrips,
  subMenuSections: [UISections.TRIPS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You can create a new Trips by clicking here.'
};
export const TRIPSHISTORY: ISection = {
  section: UISections.TRIPSHISTORY,
  title: 'Trips History',
  description: '',
  symbolImage: 'icon-check-list',
  symbolColor: '#42b480',
  symbolLightColor: '#42b480',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #18987e, #66cc81)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtripshistory,
  subMenuSections: [UISections.TRIPSHISTORY],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have Trips yet, Trips History is empty.'
};

export const TRIPNOTIFIERS: ISection = {
  section: UISections.TRIPNOTIFIERS,
  title: 'Trip Notifiers',
  description: '',
  symbolImage: 'icon-notification',
  symbolColor: '#478de5',
  symbolLightColor: '#478de5',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #18987e, #66cc81)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtripnotifiers,
  subMenuSections: [UISections.TRIPNOTIFIERS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have Passengers yet, Trip Notifiers History is empty.'
};

export const BLACKLISTS: ISection = {
  section: UISections.BLACKLIST,
  title: 'BlackList',
  description: 'Prevent specific users or crowds from signing up in your App.',
  symbolImage: 'icon-blocked',
  symbolColor: '#db3c50',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #F38694, #EE0F2B)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listblacklist + ',' + PrivilegesName.editblacklist,
  subMenuSections: [UISections.BLACKLIST],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have blacklist users yet, The blacklist is empty.'
};

export const TAGS: ISection = {
  section: UISections.TAGS,
  title: 'Tags & Membership',
  description: 'Segment and Group your users for targeting',
  symbolImage: 'icon-tags',
  symbolColor: '#3EABC3',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #2DECF6, #3EA9C2)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtag,
  subMenuSections: [UISections.TAGS],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have tags yet, Tags & Membership is empty.',
  emptyPoolDesc: 'You can add a new Tag from the add new field in tags section.'
};

export const SUBGROUPS: ISection = {
  section: UISections.SUBGROUP,
  title: 'Chat Groups',
  description: 'Create and manage groups of thousands of users.',
  symbolImage: 'icon-groups',
  symbolColor: '#388cb8',
  symbolLightColor: '#cae7f6',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #388cb8, #5bb2df)`,
  showSubMenu: false,
  hideOnSub: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listchatgroup,
  subMenuSections: [UISections.SUBGROUP],
  emptyTitle: 'No Sub Groups founds',
  emptyDesc: 'Click here to create a new chat group',
  emptyPoolDesc:
    'You can add a new Group from the add new field in groups section.'
};

export const SUBCHANNELS: ISection = {
  section: UISections.SUBCHANNEL,
  title: 'Channels',
  description: 'Manage your broadcasting channels, with unlimited users.',
  symbolImage: 'icon-channel',
  symbolColor: '#b27cb2',
  symbolLightColor: '#ebddf5',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #c990c9, #381038)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listchatgroup,
  subMenuSections: [UISections.SUBCHANNEL],
  emptyTitle: 'No Sub Channel founds',
  emptyDesc: 'Click here to create a new channel.',
  emptyPoolDesc:
    'You can add a new Channel from the add new field in channels section.'
};

export const VIRTUAL_APPS: ISection = {
  section: UISections.VIRTUAL_APPS,
  title: 'Virtual Apps',
  description: 'Manage your Virtual Apps, with unlimited users.',
  symbolImage: 'icon-virtual-app',
  symbolColor: '#388cb8',
  symbolLightColor: '#cae7f6',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #388cb8, #5bb2df)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listvirtualapp,
  subMenuSections: [UISections.VIRTUAL_APPS],
  emptyTitle: 'No Virtual App founds',
  emptyDesc: 'You can create a new Virtual App by clicking here.',
  emptyPoolDesc:
    'You can add a new Virtual App from the add new field in Virtual App section.'
};

export const MENUS: ISection = {
  section: UISections.MENUS,
  title: 'Polls',
  description:
    'Create and manage polls and surveys, and attach them to your posts.',
  symbolImage: 'icon-menus',
  symbolColor: '#EC7574',
  symbolLightColor: '#fadfdf',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #FEC080, #EA6671)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmenu,
  subMenuSections: [UISections.MENUS],
  emptyTitle: 'No Polls Found',
  emptyDesc: 'Click here to create a new poll or contest.',
  emptyPoolDesc: 'You can create a new poll from the polls section.'
};

export const BOOKINGS: ISection = {
  section: UISections.BOOKING,
  title: 'Bookings',
  description: 'Manage your bookings, sessions, or schedules.',
  coverColor: `linear-gradient(130deg, #625eb1 2%, #20adec 100%)`,
  symbolImage: 'icon-booking',
  symbolColor: '#5272c0',
  symbolLightColor: '#d1e4f8',
  listOnHome: true,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbooking,
  subMenuSections: [UISections.BOOKING],
  emptyTitle: 'No Bookings Found',
  emptyDesc: 'Click here to create a new booking',
  emptyPoolDesc:
    'You can add a new Booking from the add new field in bookings section.'
};

export const CALENDERS: ISection = {
  section: UISections.CALENDAR,
  title: 'Calendars',
  description: 'Manage Schedules of activity for Booking and Events.',
  symbolImage: 'icon-calendar',
  symbolColor: '#3E8DF8',
  symbolLightColor: '#cadbf2',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #1662CA, #3E8DF8)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcalendar,
  subMenuSections: [UISections.CALENDAR],
  emptyTitle: 'There are no Calendars currently',
  emptyDesc: 'Click here to create schedules and timetables.',
  emptyPoolDesc:
    'You can add a new Calendar from the add new field in calendars section.'
};

export const BOTS: ISection = {
  hasCategory: true,
  section: UISections.BOTS,
  title: 'API and Integrations',
  description: 'Manage your bots and integrations',
  symbolImage: 'icon-bot',
  symbolColor: '#a8a8de',
  symbolLightColor: '#d2d2f4',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #6464a9, #a8a8de)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbot,
  subMenuSections: [UISections.BOTS],
  emptyTitle: 'No Bots Found',
  emptyDesc: 'You can create a new Bot by clicking here.',
  emptyPoolDesc:
    'You can add a new Bot from the add new field in bots manager section.'
};

export const CUSTOMERCARE: ISection = {
  hasCategory: true,
  section: UISections.CUSTOMERCARE,
  title: 'Customer Care',
  description: 'Purchase orders for a customer and provide a premium service.',
  symbolImage: 'icon-value',
  symbolColor: '#4cd39a',
  symbolLightColor: '#bff3dd',
  listOnHome: true,
  coverColor: `linear-gradient(139deg, #69e396 14%, #3dbaf6 85%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: [UISections.CUSTOMERCARE],
  emptyTitle: 'Purchase Bundle',
  emptyDesc: 'Purchase a Bundle for a user by clicking here.',
  emptyPoolDesc: 'Purchase a Bundle for a user by clicking here.'
};

export const CUSTOMERCARE_SUBSCRIPTION_ACTION: ISection = {
  hasCategory: true,
  section: UISections.CUSTOMERCARE,
  title: 'Serve your customers directly.',
  description: 'Manage your bots',
  symbolImage: 'icon-value',
  symbolColor: '#4cd39a',
  symbolLightColor: '#bff3dd',
  listOnHome: true,
  coverColor: `linear-gradient(139deg, #69e396 14%, #3dbaf6 85%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: [UISections.CUSTOMERCARE],
  emptyTitle: 'Purchase Subscription',
  emptyDesc: 'Purchase a Subscription for a user by clicking here.',
  emptyPoolDesc: 'Purchase a Subscription for a user by clicking here.'
};

export const BULKUPLOAD: ISection = {
  hasCategory: true,
  section: UISections.BULKUPLOAD,
  title: 'Bulk upload',
  description: 'Upload all your data in bulk.',
  symbolImage: 'icon-bulk',
  symbolColor: '#62C3B8',
  listOnHome: true,
  coverColor: `linear-gradient(138deg, #7EEEE1, #28AC9C)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbulk,
  subMenuSections: [UISections.BULKUPLOAD]
};

export const SETTINGS: ISection = {
  hasCategory: true,
  section: UISections.SETTINGS,
  title: 'Change Password',
  description: 'Be safe, and Change your password regularly.',
  symbolImage: 'icon-settings',
  symbolColor: '#2552A7',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #6078EA, #1D419D)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listsettings,
  subMenuSections: [UISections.SETTINGS]
};

export const BILLINGS: ISection = {
  hasCategory: true,
  section: UISections.BILLINGS,
  title: 'Billing',
  description: 'Check your account billing info.',
  symbolImage: 'icon-billing',
  symbolColor: '#bd3b62',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #fdaeac, #bd3b62)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbilling,
  subMenuSections: [UISections.BILLINGS]
};

export const TRANSACTIONS: ISection = {
  hasCategory: true,
  section: UISections.TRANSACTIONS,
  title: 'Orders',
  description: 'Manage your confirmed transactions/orders',
  symbolImage: 'icon-payment',
  symbolColor: '#fba5a5',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #f5d6de, #fba5a5)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: [UISections.TRANSACTIONS],
  emptyTitle: 'No Transactions Found',
  emptyDesc: ''
};

export const BUNDLES: ISection = {
  hasCategory: true,
  section: UISections.BUNDLES,
  title: 'Bundles',
  description: 'Combine products in a single bundle.',
  symbolImage: 'icon-bundle',
  symbolColor: '#955b94',
  symbolLightColor: '#f4d8ed',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #ff9488, #9484e9)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listbundle,
  subMenuSections: [UISections.BUNDLES],
  emptyTitle: 'No Bundles Found',
  emptyDesc: 'Click here to create a new bundle',
  emptyPoolDesc:
    'You can add a new Bundle from the add new field in bundles section.'
};

export const WHITELIST: ISection = {
  hasCategory: true,
  section: UISections.WHITELIST,
  title: 'Whitelist',
  description:
    'Manage your whitelist, Add new to your whitelist or remove from your whitelist.',
  symbolImage: 'icon-Whitelist',
  symbolColor: '#5ab9e1',
  symbolLightColor: '#f4d8ed',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #5ab9e1, #5e70d3)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listwhitelist,
  subMenuSections: [UISections.WHITELIST],
  emptyTitle: 'Empty!',
  emptyDesc: 'You do not have whitelist users yet, The whitelist is empty.'
};

export const PACKAGES: ISection = {
  hasCategory: true,
  section: UISections.PACKAGES,
  title: 'Multi-tiered Plans',
  description:
    'Apply multi-tiered pricing plans to offer your customers competitive prices and discounts.',
  symbolImage: 'icon-package',
  symbolColor: '#7888e6',
  symbolLightColor: '#d4d7f5',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4a90e2, #9484e9)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listpackage,
  subMenuSections: [UISections.PACKAGES],
  emptyTitle: 'No Plans Found',
  emptyDesc: ' Click here to create a new multi-tiered plan.',
  emptyPoolDesc:
    'You can add a new Plan from the add new field in plans section.'
};

export const PRODUCTS: ISection = {
  hasCategory: true,
  section: UISections.PRODUCTS,
  title: 'Products',
  description: 'Create and manage your products catalogue.',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listproduct,
  subMenuSections: [UISections.PRODUCTS],
  emptyTitle: 'No Products Found',
  emptyDesc: 'Click here to create a new product.',
  emptyPoolDesc:
    'You can add a new Product from the add new field in products section.'
};

export const MSTORE: ISection = {
  hasCategory: true,
  section: UISections.MSTORE,
  title: 'mStore',
  description: 'Create and organize your Categories/Stores.',
  symbolImage: 'icon-mstore',
  symbolColor: '#de657e',
  symbolLightColor: '#ffd7d8',
  listOnHome: true,
  coverColor: `linear-gradient(309deg, #bd3b62 0%, #fdaeac 100%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmstore,
  subMenuSections: [UISections.MSTORE],
  emptyTitle: 'No Store Found',
  emptyDesc: 'Click here to create a new m-Store.',
  emptyPoolDesc:
    'You can add a new mStore from the add new field in mStore section.'
};

export const SUBMENU: ISection = {
  hasCategory: true,
  section: UISections.SUBMENU,
  title: 'Sub Menu',
  description: 'Create new sub menu.',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmstore,
  subMenuSections: [UISections.SUBMENU],
  emptyTitle: 'No Sub menu Found',
  emptyDesc: 'You can create a new Sub menu by clicking here.',
  emptyPoolDesc:
    'You can add a new Sub menu from the add new field in Sub menu section.'
};

export const STOREMENU: ISection = {
  hasCategory: true,
  section: UISections.STOREMENU,
  title: 'Product Adds-On',
  description: 'Create Add-ons to add to your product or bundles.',
  symbolImage: 'icon-adds-on',
  symbolColor: '#5ab5dd',
  symbolLightColor: '#abdef1',
  listOnHome: true,
  coverColor: `linear-gradient(133deg, #765fc2 4%, #49e9ed 98%)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listproductaddons,
  subMenuSections: [UISections.STOREMENU],
  emptyTitle: 'No Product Adds-On Found',
  emptyDesc: 'Click here to create a new product add-on.',
  emptyPoolDesc:
    'You can add a new Product Adds-On from the add new field in Product Adds-On section.'
};

export const MEDIA_CENTER: ISection = {
  section: UISections.MEDIACENTER,
  title: 'Media Center',
  description: 'Control your media.',
  symbolImage: 'icon-gallery',
  symbolColor: '#C65373',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #4BD7E5, #3E9CEA)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmessage,
  subMenuSections: [UISections.MEDIACENTER],
  emptyTitle: 'No Media Found',
  emptyDesc:
    'If you have trouble finding an image click here to search our stock images library.',
  emptyPoolDesc: 'You can add a new Media from the add new field.'
};

/** Dev only Section */
export const UPLOAD_TEMPS: ISection = {
  section: UISections.UPLOADTEMPS,
  title: 'Upload Templates',
  description: 'Upload you app templates.',
  symbolImage: 'icon-templates',
  symbolColor: '#957EAC',
  listOnHome: false,
  coverColor: `linear-gradient(135deg, #FEC080, #EA6671)`,
  showSubMenu: false,
  hideOnSub: true,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtemplate,
  subMenuSections: [UISections.UPLOADTEMPS]
};

export const PAYMENT_MENU: ISection[] = [
  MSTORE,
  PRODUCTS,
  BUNDLES,
  PACKAGES,
  STOREMENU,
  TRANSACTIONS
];
export const USERS_MANAG: ISection[] = [USERS, TAGS, BLACKLISTS, WHITELIST];

/**
 * Menu Categories
 */
export const PRODUCT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Store Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listproduct +
    ',' +
    PrivilegesName.listbundle +
    ',' +
    PrivilegesName.listmstore +
    ',' +
    PrivilegesName.listpackage,
  subMenuSections: []
};
export const USERS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Users Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmember +
    ',' +
    PrivilegesName.listtag +
    ',' +
    PrivilegesName.listwhitelist +
    ',' +
    PrivilegesName.listblacklist,
  subMenuSections: []
};
export const CONTENT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Content Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmessage +
    ',' +
    PrivilegesName.listchatgroup +
    ',' +
    PrivilegesName.listvirtualapp,
  subMenuSections: []
};

export const EVENT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Event Management',
  description: '',
  symbolImage: '',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listevent +
    ',' +
    PrivilegesName.listbooking +
    ',' +
    PrivilegesName.listcalendar,
  subMenuSections: []
};
export const MSTORE_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'mStore Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmstore,
  subMenuSections: []
};
export const ORDER_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Order Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};
export const LOYALTY_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Loyalty Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcampaign + ',' + PrivilegesName.listcoupon,
  subMenuSections: []
};
export const OTHERS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Others',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listcustomercare +
    ',' +
    PrivilegesName.listbot +
    ',' +
    PrivilegesName.listbulk,
  subMenuSections: []
};
export const MAP_TRACKING: ISection = {
  isCategory: true,
  section: null,
  title: 'Map Tracking',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmapservices +
    ',' +
    PrivilegesName.listmarker +
    ',' +
    PrivilegesName.listroutes +
    ',' +
    PrivilegesName.listtripnotifiers +
    ',' +
    PrivilegesName.listtrips +
    ',' +
    PrivilegesName.listtripshistory,
  subMenuSections: []
};
export const SETTINGS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Settings',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: true,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listsettings + ',' + PrivilegesName.listbilling,
  subMenuSections: []
};

export const LOCATIONS: ISection = {
  section: UISections.LOCATIONS,
  title: 'Locations',
  description: 'Control your Location settings.',
  symbolImage: 'icon-map-marker',
  symbolColor: '#3E8DF8',
  symbolLightColor: '#cadbf2',
  listOnHome: true,
  coverColor: `linear-gradient(135deg, #1662CA, #3E8DF8)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmarker + ',' + PrivilegesName.editmarker,
  subMenuSections: [UISections.LOCATIONS],
  emptyTitle: 'No Location Found',
  emptyDesc: 'You can create a new Location by clicking here.',
  emptyPoolDesc:
    'You can add a new location from the add new field in location section.'
};
export const MYCH: ISection = {
  section: UISections.MYCH,
  title: 'My channel',
  description: 'Manage your sub channels.',
  symbolImage: 'icon-channel',
  symbolColor: '#b27cb2',
  symbolLightColor: '#ebddf5',
  listOnHome: true,
  coverColor: `linear-gradient(129deg, #c990c9, #381038)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmychannel + ',' + PrivilegesName.editmychannel,
  subMenuSections: [UISections.MYCH]
};
/**************************************************************************** */

export const M_COMMERCE_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'mCommerce',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listmember +
    ',' +
    PrivilegesName.listproduct +
    ',' +
    PrivilegesName.listbundle +
    ',' +
    PrivilegesName.listpackage +
    ',' +
    PrivilegesName.listmstore +
    ',' +
    PrivilegesName.listproductaddons,
  subMenuSections: []
};

export const M_COMMERCE_USER: ISection = {
  isCategory: true,
  section: null,
  title: 'Users',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listmember,
  subMenuSections: []
};

export const M_COMMERCE_PRODUCTS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Products',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName:
    PrivilegesName.listproduct +
    ',' +
    PrivilegesName.listbundle +
    ',' +
    PrivilegesName.listpackage +
    ',' +
    PrivilegesName.listmstore +
    ',' +
    PrivilegesName.listproductaddons,
  subMenuSections: []
};

export const M_COMMERCE_ORDER_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Order Management',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};

export const M_COMMERCE_INTEGRATION_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Integration',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listtransaction,
  subMenuSections: []
};

export const M_COMMERCE_MARKETINGS_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Marketings',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcampaign + ',' + PrivilegesName.listcoupon,
  subMenuSections: []
};

export const M_COMMERCE_SUPPORT_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'Support',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: []
};

export const M_COMMERCE_GENERAL_MANAGEMENT: ISection = {
  isCategory: true,
  section: null,
  title: 'General',
  description: '',
  symbolImage: 'icon-product',
  symbolColor: '#2bbcc9',
  symbolLightColor: '#b6e6eb',
  listOnHome: false,
  hideOnSub: false,
  coverColor: `linear-gradient(135deg, #01e5dc, #a687ca)`,
  showSubMenu: false,
  subMenuScreen: null,
  privilegeName: PrivilegesName.listcustomercare,
  subMenuSections: []
};

export const SIDE_MENU: ISection[] = [
  HOME,
  VAPP,
  APPSEC,
  CONTENT_MANAGEMENT,
  POSTS,
  SCHEDULES,
  MENUS,
  SUBCHANNELS,
  MYCH,
  SUBGROUPS,
  VIRTUAL_APPS,
  EVENT_MANAGEMENT,
  BOOKINGS,
  EVENTS,
  CALENDERS,
  PRODUCT_MANAGEMENT,
  ...PAYMENT_MENU,
  // MSTORE_MANAGEMENT,
  // MSTORE,
  // ORDER_MANAGEMENT,
  // TRANSACTIONS,
  LOYALTY_MANAGEMENT,
  CAMPAIGNS,
  COUPONS,
  MAP_TRACKING,
  MAPSERVICE,
  MAPROUTES,
  TRIPS,
  TRIPNOTIFIERS,
  LOCATIONS,
  TRIPSHISTORY,
  USERS_MANAGEMENT,
  ...USERS_MANAG,
  OTHERS_MANAGEMENT,
  CUSTOMERCARE,
  BULKUPLOAD,
  BOTS,
  SETTINGS_MANAGEMENT,
  SETTINGS,
  BILLINGS,
  UPLOAD_TEMPS
];

export const SIDE_MENU_TEMP: ISection[] = [
  APPSEC,
  M_COMMERCE_MANAGEMENT,
  M_COMMERCE_USER,
  USERS,
  M_COMMERCE_PRODUCTS_MANAGEMENT,
  PRODUCTS,
  BUNDLES,
  STOREMENU,
  PACKAGES,
  MSTORE,
  M_COMMERCE_ORDER_MANAGEMENT,
  M_COMMERCE_INTEGRATION_MANAGEMENT,
  TRANSACTIONS,
  M_COMMERCE_MARKETINGS_MANAGEMENT,
  CAMPAIGNS,
  COUPONS,
  M_COMMERCE_SUPPORT_MANAGEMENT,
  CUSTOMERCARE,
  M_COMMERCE_GENERAL_MANAGEMENT,
  POSTS,
  SCHEDULES,
  CALENDERS,
  LOCATIONS,
  MYCH,
  MENUS,
  SUBGROUPS,
  SUBCHANNELS,
  VIRTUAL_APPS,
  EVENTS,
  BOOKINGS,
  TAGS,
  BOTS,
  BULKUPLOAD,
  BILLINGS,
  SETTINGS,
  SETTINGS_MANAGEMENT,
  UPLOAD_TEMPS
];
