import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { NgxModule } from 'src/app/ngx.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';
import { AbstractTablesModule } from 'src/app/abstract-tables/abstract-tables.module';

import { AbstractUploadModule } from 'src/app/abstract-upload/abstract-upload.module';
import { MenusPoolModule } from '../../menus-pool/menus-pool.module';
import { MenuFormComponent } from './menu-form.component';
import { MenuHolderComponent } from '../menu-holder/menu-holder.component';
import { MenusRowsComponent } from '../menus-rows/menus-rows.component';
import { MenuRowItemComponent } from '../menu-row-item/menu-row-item.component';
import { AbstractNotifiersModule } from 'src/app/abstract-notifiers/abstract-notifiers.module';
import { CreatedMenuItemComponent } from '../created-menus-view/created-menu-item/created-menu-item.component';
import { CreatedMenusViewComponent } from '../created-menus-view/created-menus-view.component';
@NgModule({
  imports: [
    CommonModule,
    AccessorsModule,
    AbstractBtnsModule,
    AbstractFramesModule,
    AbstractTablesModule,
    ReactiveFormsModule,
    ColorPickerModule,
    SharedModule,
    NgxModule,
    MenusPoolModule,
    AbstractUploadModule,
    AbstractNotifiersModule
  ],
  declarations: [
    MenuFormComponent,
    MenuHolderComponent,
    MenusRowsComponent,
    MenuRowItemComponent,
    CreatedMenuItemComponent,
    CreatedMenusViewComponent
  ],

  exports: [
    MenuFormComponent,
    MenuHolderComponent,
    MenusRowsComponent,
    MenuRowItemComponent,
    CreatedMenuItemComponent,
    CreatedMenusViewComponent
  ]
})
export class MenuFormModule {}
