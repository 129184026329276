import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { TableService } from 'src/app/abstract-tables/table.service';

import { AbstractItem, AbstractTableColumn } from 'src/models/AbstractItem';
import { IOption } from 'src/models/campaign';
import { USERS } from 'src/models/AppSections';
import { MembersService } from 'src/app/+merchant/+dashboard/members/members.service';
import { UserStatus } from 'src/models/constants';

@Component({
  selector: 'app-list-selector-pool',
  templateUrl: './list-selector-pool.component.html',
  styleUrls: ['./list-selector-pool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListSelectorPoolComponent implements OnInit, OnChanges {
  selectorForm: FormGroup;

  singleSelectorForm: FormGroup;

  users = USERS;

  selectedUsers: any[] = [];

  private _searchTerm = new BehaviorSubject<string>('');
  public searchTerm$ = this._searchTerm.asObservable();

  @Input() items: AbstractItem[];
  @Input() tableColumns: AbstractTableColumn[];
  @Input() tableService: TableService;
  @Input() title = 'Add';
  @Input() isSingle = false;
  @Input() isLoading: boolean;

  @Input() allowSearchBy = false;
  @Input() searchOptions: IOption[];

  @Input() mapRoles = null;
  @Input() accountTypes: string[];
  @Input() selectedMembers: any[] = [];
  @Output()
  submitItemsFormNotfiy = new EventEmitter<boolean>(); // submitMembersFormNotfiy
  @Output()
  submitUsersNotfiy = new EventEmitter<any[]>(); // submitMembersFormNotfiy
  @Output() submitItemFormNotfiy = new EventEmitter<string>(); // submitMembersFormNotfiy
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  userStatus = UserStatus;

  constructor(public membersService: MembersService) {}

  ngOnInit() {
    this.tableService.resetPaging(
      null,
      this.userStatus.ACTIVE,
      this.mapRoles,
      this.accountTypes
    );
    this.tableService.resetSelectedItems();
    if (this.isSingle) {
      this.setUpSingleSelectorForm();
    }
  }

  ngOnChanges(chng: SimpleChanges) {
    const chngs = chng['items'];
    const previousChanges: AbstractItem[] = chngs.previousValue;
    const newChanges: AbstractItem[] = chngs.currentValue;
    let found = 0;
    for (let i = 0; i < newChanges.length; i++) {
      const result =
        previousChanges &&
        previousChanges.find(res => {
          if (res.id === newChanges[i].id) {
            found++;
            return res;
          }
        });
    }
    if (
      !this.isSingle &&
      chngs &&
      ((chngs.currentValue && !chngs.previousValue) ||
        (previousChanges &&
          newChanges &&
          newChanges.length > 0 &&
          found < this.items.length))
    ) {
      this.selectorForm = new FormGroup({
        items: this.composeInnerFormControls(newChanges)
      });
    }
  }

  setUpSingleSelectorForm() {
    this.singleSelectorForm = new FormGroup({
      singleItem: new FormControl(null)
    });
  }

  composeInnerFormControls(items: AbstractItem[]) {
    const finalArr = [];
    items.forEach(item => {
      if (
        this.tableService &&
        this.tableService.selectedItems &&
        this.tableService.selectedItems.includes(item.id)
      ) {
        finalArr[item.id] = new FormControl(true);
      } else {
        finalArr[item.id] = new FormControl(false);
      }
    });
    return new FormArray(finalArr);
  }

  selectedUser(item) {
    this.selectedUsers.push(item);
  }

  onSearchFormSubmit(event): void {
    const { value, searchBy } = event;
    this._searchTerm.next(value);
    this.tableService.setSearchBy(searchBy);
  }
  onSubmit() {
    this.updateSelectedItems();
    this.submitItemsFormNotfiy.emit(true);
  }

  onSingleSubmit() {
    this.submitItemFormNotfiy.emit(this.singleSelectorForm.value.singleItem);
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  getAddedAndRemovedValues(key: string): { addedIds: string[]; removedIds } {
    const addedArray: string[] = [];
    const removedArray: string[] = [];
    const itemsArray = this.selectorForm.controls[key] as FormArray;
    Object.keys(itemsArray.controls).forEach(itemkey => {
      if (itemsArray.get(itemkey).value) {
        addedArray.push(itemkey);
      } else {
        removedArray.push(itemkey);
      }
    });
    return { addedIds: addedArray, removedIds: removedArray };
  }

  updateSelectedItems() {
    const result = this.getAddedAndRemovedValues('items');
    this.tableService.updateSelectedItems(result.addedIds, result.removedIds);
  }

  isMemberSelected(memberID) {
    return this.selectedMembers.filter(res => res.id === memberID).length > 0;
  }
}
