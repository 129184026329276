<form
  class="search-form"
  [formGroup]="searchForm"
  (ngSubmit)="onSearchFormSubmit()"
>
  <div style="width: 150px;" class="form-group select-status" *ngIf="isUsers">
    <div class="select" dropdown>
      <div class="select-option" dropdownToggle>
        <div class="option">
          <span
            class="active-users"
            *ngIf="status === userStatus.ACTIVE"
          ></span>
          <span
            class="deActive-users"
            *ngIf="status === userStatus.BANNED"
          ></span>
          <div *ngIf="userStatus.ACTIVE === status">
            Active Users
          </div>
          <div *ngIf="userStatus.BANNED === status">
            Banned Users
          </div>
        </div>
        <div class="arrow-down"></div>
      </div>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li
          role="menuitem"
          class="menu-item clickable-cursor"
          (click)="selectTheStatusOfUser(userStatus.ACTIVE)"
        >
          <span
            class="dropdown-item"
            style="display: flex; align-items: center;"
          >
            <span class="active-users"></span>
            Active Users
          </span>
        </li>
        <li
          role="menuitem"
          class="menu-item clickable-cursor"
          (click)="selectTheStatusOfUser(userStatus.BANNED)"
        >
          <span
            class="dropdown-item"
            style="display: flex; align-items: center;"
          >
            <span class="deActive-users" style="margin-right: 5px;"></span>
            Banned Users
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="search-form-body">
    <div
      class="form-group lg-input"
      style="border-right: 1px solid #d4dbe2;"
      *ngIf="allowSearchBy"
      dropdown
    >
      <div class="select" style="border: none;" dropdownToggle>
        <ng-container *ngFor="let option of searchOptions">
          <div
            style="width: 100%;"
            *ngIf="searchForm?.get('searchBy').value === option?.key"
          >
            {{ option?.value }}
          </div>
        </ng-container>
        <div class="arrow-down"></div>
      </div>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li
          role="menuitem"
          class="menu-item clickable-cursor"
          *ngFor="let option of searchOptions"
          (click)="selectTypeOfSearch(option.key)"
        >
          <span
            class="dropdown-item"
            style="display: flex; align-items: center;"
          >
            {{ option?.value }}
          </span>
        </li>
      </ul>
    </div>
    <input type="search" formControlName="term" placeholder="Search" />
    <span
      style="position: relative;"
      (mouseover)="toggleHoverTooltip(true)"
      (mouseleave)="toggleHoverTooltip(false)"
    >
      <button
        type="submit"
        class="btn search-btn"
        [disabled]="!searchForm.valid"
      >
        <svg class="svg-icon icon-icon">
          <use xlink:href="./assets/svg/app-icons.svg#ic_search_24dp"></use>
        </svg>
      </button>
      <app-hover-tooltip
        *ngIf="showTooltip && toggleTooltip"
        [top]="positionTooltipTop"
        [right]="positionTooltipRight"
        [text]="textTooltip"
        [width]="widthTooltip"
      ></app-hover-tooltip>
    </span>
  </div>

  <!-- <input type="search" class="form-control" formControlName="term" />
  <button type="submit" class="btn search-btn" [disabled]="!searchForm.valid">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-icons.svg#ic_search_24dp"></use>
    </svg>
  </button> -->
</form>
