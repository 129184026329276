import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { SubscriptionService } from '../subscription.service';
import { IPricePlan } from 'src/models/IPricePlan';

@Component({
  selector: 'app-subscription-price-table',
  templateUrl: './subscription-price-table.component.html',
  styleUrls: ['./subscription-price-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPriceTableComponent implements OnInit, OnChanges {
  @Input() plans: IPricePlan[][];
  @Input() payStore;
  @Output() closeNotify = new EventEmitter<boolean>();
  isYearly = false;

  plansForTest: IPricePlan[][] = [];

  plan1 = [
    {
      id: 'plan_1',
      name: 'Professional',
      title: 'Professional App',
      tagline: 'Get your First 500 App Users on Board Now!',
      price: '$49/month',
      options: [
        '$49/month',
        '500 Users',
        '5 GB Storage',
        '2 GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    },
    {
      id: 'plan_1',
      name: 'Professional',
      title: 'Professional ',
      tagline: 'Get your First 500 App Users on Board Now!',
      price: '$49/month',
      options: [
        '$49/month',
        '500 Users',
        '5 GB Storage',
        '2 GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    }
  ];
  plan2 = [
    {
      id: 'plan_2',
      name: 'Growing',
      title: 'Growing APP',
      tagline: 'Expand your Outreach & Grow your App Now!',
      price: '$149/month',
      options: [
        '$149/month',
        '2000 Users',
        '20 GB Storage',
        '10 GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',

      isScalable: false
    },
    {
      id: 'plan_2',
      name: 'Growing',
      title: 'Growing APP',
      tagline: 'Expand your Outreach & Grow your App Now!',
      price: '$149/month',
      options: [
        '$149/month',
        '2000 Users',
        '20 GB Storage',
        '10 GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',

      isScalable: false
    }
  ];
  plan3 = [
    {
      id: 'plan_3',
      name: 'Pay as you go',
      title: 'Pay As You Go',
      tagline: 'Unlimited Users With Flexible Pricing Options!',
      price: 'Starting $350/month',
      options: [
        'Starting $350/month',
        'Unlimited users',
        '$5 per GB Storage',
        '$3 per GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    },
    {
      id: 'plan_3',
      name: 'Pay as you go',
      title: 'Pay As You Go',
      tagline: 'Unlimited Users With Flexible Pricing Options!',
      price: 'Starting $350/month',
      options: [
        'Starting $350/month',
        'Unlimited users',
        '$5 per GB Storage',
        '$3 per GB Bandwidth/month'
      ],
      brief:
        'Native Android & Native iOS,Payment Gateways,Google Ads,In-App Purchase,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Audio & Video Calling,Built-In Messenger',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#217fec',
      secondColor: '#edf5ff',
      isScalable: false
    }
  ];

  plan4 = [
    {
      id: 'plan_4',
      name: 'nandbox Ignite',
      title: 'nandbox Ignite',
      tagline: 'No Credit Card Required. No Trial Period',
      price: 'FREE',
      options: ['FREE', '500 Users', '5 GB Storage', '2 GB Bandwidth/month'],
      brief:
        'Native Android & Native iOS,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Standard Bots,Audio & Video Calling,Built-In Messenger,Webview,QR Scanner',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#4054b2',
      secondColor: '#eff2ff',
      isScalable: false
    },
    {
      id: 'plan_4',
      name: 'nandbox Ignite',
      title: 'nandbox Ignite',
      tagline: 'No Credit Card Required. No Trial Period',
      price: 'FREE',
      options: ['FREE', '500 Users', '5 GB Storage', '2 GB Bandwidth/month'],
      brief:
        'Native Android & Native iOS,Chat Groups,Channels & Sub-Channels,Mobile Store,Booking & Events,Feed & Posts,Loyalty Wallet,Ticket Validation,Ticket Redeem,Standard Bots,Audio & Video Calling,Built-In Messenger,Webview,QR Scanner',
      url: 'https://nandbox.com/en/pricing/',
      isPopular: false,
      mainColor: '#4054b2',
      secondColor: '#eff2ff',
      isScalable: false
    }
  ];

  constructor(public subscriptionService: SubscriptionService) {}
  ngOnChanges() {
    console.log('this.payStore.plan ', this.payStore);
    if (this.payStore.plan && this.payStore.url) {
      console.log('here i am ', this.payStore.url);
      window.open(this.payStore.url, '_blank');
    }
  }
  ngOnInit() {
    console.log('this.payStore.plan ', this.payStore);
    // if (this.plans.length <= 3) {
    //   this.plans.push(this.plan4);
    //   console.log('plans', this.plans);
    // }
    // if (this.plansForTest.length <= 0) {
    //   this.plansForTest.push(this.plan4);
    //   this.plansForTest.push(this.plan1);
    //   this.plansForTest.push(this.plan2);
    //   this.plansForTest.push(this.plan3);
    //   this.plans = this.plansForTest;
    //   console.log(this.plans);
    // }
  }

  closeEvent() {
    this.closeNotify.emit(true);
  }

  switchEvent(e: string) {
    if (e === 'left') {
      this.isYearly = true;
    } else {
      this.isYearly = false;
    }
  }
}
