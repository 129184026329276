import * as UIActions from './ui.actions';
import { UIState } from 'src/models/UIState';
import {
  CHANNEL,
  CHATS,
  CHANNELS,
  MAX_NUMBER_OF_PAGES_PER_DOM
} from 'src/models/constants';

const uiInitState: UIState = {
  selectedMessageForStats: null,
  selectedMessage: null,
  subParentMessage: null,
  clickedMessage: null,
  selectedUserId: null,
  subSelectedUserId: null,
  selectedBotMenu: null,
  botMenuEdit: false,
  selectedMapMarker: null,
  mapMarkerEdit: false,
  showDashboard: false,
  selectedTalkToAdmin: false,
  section: 'HOME',
  searchKeyWord: '',
  scheduleDate: null,
  privileges: [],
  currentPageNumber: 1,
  sideMenuStatus: 0
};

export function uiReducer(
  state = uiInitState,
  action: UIActions.UIActions
): UIState {
  switch (action.type) {
    case UIActions.UIActionTypes.UI_CHAT_SELECTED: {
      let lastSelectedChat = state.lastSelectedChat;
      let lastSelectedChannel = state.lastSelectedChannel;
      let allChannels = state.allChannelsSelected;
      const allChannelsWasLastSelected = state.allChannelsSelected;
      if (action.payload.type === CHANNEL) {
        lastSelectedChannel = action.payload;
        allChannels = false;
      } else {
        lastSelectedChat = action.payload;
      }
      // console.log('ui =>', action.payload);
      return Object.assign({}, state, {
        selectedChat: action.payload,
        selectedMessage: null,
        selectedUserId: null,
        replyToAdmin: false,
        selectedTalkToAdmin: false,
        groupReplies: false,
        lastSelectedChat,
        lastSelectedChannel,
        allChannelsSelected: allChannels,
        allChannelsWasLastSelected,
        showPreview: false,
        showPopup: false,
        sideMenuStatus: 0
      });
    }
    case UIActions.UIActionTypes.UI_SUB_CHAT_SELECTED: {
      return Object.assign({}, state, {
        selectedSubChat: action.payload,
        subParentMessage: null,
        subSelectedUserId: null
      });
    }
    case UIActions.UIActionTypes.UI_SUB_CHAT_DESELECTED: {
      return Object.assign({}, state, {
        selectedSubChat: null,
        subParentMessage: null
      });
    }
    case UIActions.UIActionTypes.UI_MESSAGE_SELECTED:
      return Object.assign({}, state, {
        selectedMessage: action.payload,
        selectedTalkToAdmin: false,
        selectedUserId: null
      });
    case UIActions.UIActionTypes.UI_MESSAGE_SELECTED_FOR_STATS:
      return Object.assign({}, state, {
        selectedMessageForStats: action.payload
      });
    case UIActions.UIActionTypes.UI_SUB_PARENT_MESSAGE_SELECTED:
      return Object.assign({}, state, {
        subParentMessage: action.payload,
        subSelectedUserId: null
      });
    case UIActions.UIActionTypes.UI_USER_SELECTED:
      return Object.assign({}, state, {
        selectedUserId: action.payload
      });
    case UIActions.UIActionTypes.UI_SUB_USER_SELECTED:
      return Object.assign({}, state, {
        subSelectedUserId: action.userID
      });
    case UIActions.UIActionTypes.UI_SUB_USER_DESELECTED:
      return Object.assign({}, state, {
        subSelectedUserId: null
      });
    case UIActions.UIActionTypes.UI_GROUP_REPLIES_SELECTED:
      return Object.assign({}, state, {
        groupReplies: true
      });
    case UIActions.UIActionTypes.UI_BACK_FROM_SUB_GROUP_REPLIES:
      return Object.assign({}, state, {
        subParentMessage: null
      });
    case UIActions.UIActionTypes.UI_BACK_FROM_GROUP_REPLIES:
      return Object.assign({}, state, {
        groupReplies: false,
        selectedMessage: null,
        selectedMessageForStats: null,
        clickedMessage: null,
        selectedUserId: null,
        selectedTalkToAdmin: false,
        selectedBotMenu: null,
        botMenuEdit: false,
        selectedMapMarker: null,
        mapMarkerEdit: false
      });
    case UIActions.UIActionTypes.UI_LIST_SELECTED:
      let chat = null;
      const allChannelsLastSelected = state.allChannelsSelected;
      let allChannelsSelected = false;
      if (action.payload === CHATS) {
        chat = state.lastSelectedChat;
      } else if (action.payload === CHANNELS) {
        chat = state.lastSelectedChannel;
        if (!chat) {
          allChannelsSelected = true;
        }
      }
      return Object.assign({}, state, {
        selectedList: action.payload,
        selectedChat: chat,
        groupReplies: false,
        selectedMessage: null,
        selectedTalkToAdmin: false,
        clickedMessage: null,
        selectedUserId: null,
        replyToAdmin: false,
        allChannelsSelected,
        allChannelsWasLastSelected: allChannelsLastSelected
      });
    case UIActions.UIActionTypes.UI_SELECT_ALL_CHANNELS:
      return Object.assign({}, state, {
        selectedChat: null,
        groupReplies: false,
        selectedMessage: null,
        selectedTalkToAdmin: false,
        clickedMessage: null,
        selectedUserId: null,
        replyToAdmin: false,
        lastSelectedChannel: null,
        allChannelsSelected: true,
        showPreview: false,
        showPopup: false
      });
    case UIActions.UIActionTypes.UI_SELECT_ONE_TO_ONE_REPLY:
      return Object.assign({}, state, {
        selectedUserId: action.payload,
        replyToAdmin: false,
        selectedTalkToAdmin: state.selectedTalkToAdmin
      });
    case UIActions.UIActionTypes.UI_SELECT_TALK_TO_ADMIN:
      return Object.assign({}, state, {
        replyToAdmin: false,
        selectedTalkToAdmin: true,
        selectedMessage: null,
        selectedUserId: null
      });
    case UIActions.UIActionTypes.UI_SELECT_REPLY_TO_ADMIN:
      return Object.assign({}, state, {
        replyToAdmin: true,
        selectedUserId: null
      });
    case UIActions.UIActionTypes.UI_BACK_FROM_ONE_TO_ONE_REPLY:
      let selectedMessage = state.selectedMessage;
      if (state.replyToAdmin) {
        selectedMessage = null;
      }
      return Object.assign({}, state, {
        selectedUserId: null,
        selectedMessage,
        replyToAdmin: false,
        selectedTalkToAdmin: state.selectedTalkToAdmin
      });
    case UIActions.UIActionTypes.UI_SHOW_POPUP:
      return Object.assign({}, state, {
        showPopup: true,
        popupMessage: action.payload,
        popupTitle: action.title
      });
    case UIActions.UIActionTypes.UI_SHOW_ERROR_POPUP:
      return Object.assign({}, state, {
        showErrorPopup: true,
        errorPopupMessage: action.popupErrorMessage
      });
    case UIActions.UIActionTypes.UI_HIDE_POPUP:
      return Object.assign({}, state, {
        showPopup: false,
        popupMessage: '',
        popupTitle: ''
      });
    case UIActions.UIActionTypes.UI_HIDE_ERROR_POPUP:
      return Object.assign({}, state, {
        showErrorPopup: false,
        errorPopupMessage: ''
      });
    case UIActions.UIActionTypes.UI_SHOW_PREVIEW:
      return Object.assign({}, state, {
        showPreview: true
      });
    case UIActions.UIActionTypes.UI_HIDE_PREVIEW:
      return Object.assign({}, state, {
        showPreview: false
      });
    case UIActions.UIActionTypes.UI_SHOW_DRAG_DROP_SCREEN:
      return Object.assign({}, state, {
        showDragDropScreen: true
      });
    case UIActions.UIActionTypes.UI_HIDE_DRAG_DROP_SCREEN:
      return Object.assign({}, state, {
        showDragDropScreen: false
      });
    case UIActions.UIActionTypes.UI_SET_CHANNELS_LIST_PAGE_NUMBER:
      return Object.assign({}, state, {
        channelsListPageNumber: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_CHATS_LIST_PAGE_NUMBER:
      return Object.assign({}, state, {
        chatsListPageNumber: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_MESSAGES_PAGE_NUMBER: {
      const positiveDelta =
        action.payload - state.messagesPageNumber > 0 ? true : false;
      let deltaValue = 0;
      if (action.payload > MAX_NUMBER_OF_PAGES_PER_DOM) {
        deltaValue = action.payload - MAX_NUMBER_OF_PAGES_PER_DOM;
      } else {
        deltaValue = action.payload - state.messagesPageNumber;
      }
      const delta =
        action.payload === 1 || !state.messagesPageNumber
          ? 0
          : positiveDelta
          ? Math.abs(deltaValue)
          : -Math.abs(deltaValue);

      return Object.assign({}, state, {
        scrollDelta: delta,
        messagesPageNumber: action.payload
      });
    }
    case UIActions.UIActionTypes.UI_SET_SUB_GROUP_MSGS_PAGE_NUMBER: {
      const positiveDelta =
        action.payload - state.subGroupMessagesPageNumber > 0 ? true : false;
      let deltaValue = 0;
      if (action.payload > MAX_NUMBER_OF_PAGES_PER_DOM) {
        deltaValue = action.payload - MAX_NUMBER_OF_PAGES_PER_DOM;
      } else {
        deltaValue = action.payload - state.subGroupMessagesPageNumber;
      }
      const delta =
        action.payload === 1 || !state.subGroupMessagesPageNumber
          ? 0
          : positiveDelta
          ? Math.abs(deltaValue)
          : -Math.abs(deltaValue);

      return Object.assign({}, state, {
        subGroupScrollDelta: delta,
        subGroupMessagesPageNumber: action.payload
      });
    }
    case UIActions.UIActionTypes.UI_SET_POSTS_PAGE_NUMBER: {
      const positiveDelta =
        action.payload - state.postsPageNumber > 0 ? true : false;
      let deltaValue = 0;
      if (action.payload > MAX_NUMBER_OF_PAGES_PER_DOM) {
        deltaValue = action.payload - MAX_NUMBER_OF_PAGES_PER_DOM;
      } else {
        deltaValue = action.payload - state.postsPageNumber;
      }
      const delta =
        action.payload === 1 || !state.postsPageNumber
          ? 0
          : positiveDelta
          ? Math.abs(deltaValue)
          : -Math.abs(deltaValue);

      return Object.assign({}, state, {
        postsScrollDelta: delta,
        postsPageNumber: action.payload
      });
    }
    case UIActions.UIActionTypes.UI_SET_SUB_POSTS_PAGE_NUMBER: {
      const positiveDelta =
        action.payload - state.subPostsPageNumber > 0 ? true : false;
      let deltaValue = 0;
      if (action.payload > MAX_NUMBER_OF_PAGES_PER_DOM) {
        deltaValue = action.payload - MAX_NUMBER_OF_PAGES_PER_DOM;
      } else {
        deltaValue = action.payload - state.subPostsPageNumber;
      }
      const delta =
        action.payload === 1 || !state.subPostsPageNumber
          ? 0
          : positiveDelta
          ? Math.abs(deltaValue)
          : -Math.abs(deltaValue);

      return Object.assign({}, state, {
        subPostsScrollDelta: delta,
        subPostsPageNumber: action.payload
      });
    }
    case UIActions.UIActionTypes.UI_SET_POST_REPLIES_PAGE_NUMBER:
      return Object.assign({}, state, {
        postRepliesPageNumber: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_SUB_POST_REPLIES_PAGE_NUMBER:
      return Object.assign({}, state, {
        subPostRepliesPageNumber: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_SEARCH_KEY_WORD:
      return Object.assign({}, state, {
        searchKeyWord: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_CONTACTS_COUNT:
      return Object.assign({}, state, {
        contactsCount: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_GROUPS_COUNT:
      return Object.assign({}, state, {
        groupsCount: action.payload
      });
    case UIActions.UIActionTypes.UI_DETAILS_RECEIVED:
      return Object.assign({}, state, {
        detailsReceived: true
      });
    case UIActions.UIActionTypes.UI_HAS_NO_CONTACTS:
      return Object.assign({}, state, {
        hasNoContacts: true
      });
    case UIActions.UIActionTypes.UI_SHOW_DASHBOARD:
      return Object.assign({}, state, {
        showDashboard: true
      });
    case UIActions.UIActionTypes.UI_SHOW_MEDIA_SCREEN:
      return Object.assign({}, state, {
        showMediaScreen: true,
        selectedMediaMessage: action.payload
      });
    case UIActions.UIActionTypes.UI_HIDE_MEDIA_SCREEN:
      return Object.assign({}, state, {
        showMediaScreen: false,
        selectedMediaMessage: null
      });

    case UIActions.UIActionTypes.UI_SET_CLICKED_MESSAGE:
      return Object.assign({}, state, {
        clickedMessage: action.payload
      });
    case UIActions.UIActionTypes.UI_SET_SECTION:
      return Object.assign({}, state, {
        section: action.payload,
        groupReplies: false,
        selectedMessage: null,
        selectedTalkToAdmin: false,
        clickedMessage: null,
        selectedUserId: null,
        showMediaScreen: false,
        selectedBotMenu: null,
        botMenuEdit: false,
        selectedMapMarker: null,
        mapMarkerEdit: false
      });
    case UIActions.UIActionTypes.UI_SET_PAGE_NUMBER:
      return Object.assign({}, state, {
        currentPageNumber: setPageNumber(
          action.payload,
          state.currentPageNumber
        )
      });
    case UIActions.UIActionTypes.UI_DATE_SELECTED:
      return Object.assign({}, state, {
        scheduleDate: action.payload
      });
    case UIActions.UIActionTypes.UI_BOT_MENU_SELECTED:
      /* in case of create we send payload null so it will update edit mode to true no preview */
      return Object.assign({}, state, {
        selectedBotMenu: action.payload ? action.payload : {},
        botMenuEdit: true
      });
    case UIActions.UIActionTypes.UI_MAP_MARKER_SELECTED:
      /* in case of create we send payload null so it will update edit mode to true no preview */
      return Object.assign({}, state, {
        selectedMapMarker: action.payload ? action.payload : {},
        mapMarkerEdit: true
      });
    case UIActions.UIActionTypes.UI_SET_PRIVILEGE:
      return Object.assign({}, state, {
        privileges: [...action.payload]
      });
    case UIActions.DESELECT_CHANNEL:
      return Object.assign({}, state, uiInitState);
    case UIActions.RESET:
      return uiInitState;
    case UIActions.UIActionTypes.UI_SET_SIDEMENU_STATUS:
      return Object.assign({}, state, {
        sideMenuStatus: action.status
      });
    default:
      return state;
  }
}

function setPageNumber(action: string, currentPageNumber: number): number {
  if (action === 'new') {
    return currentPageNumber + 1;
  } else if (action === 'old') {
    return currentPageNumber - 1 >= 1 ? currentPageNumber - 1 : 1;
  } else if (action === 'rest') {
    return (currentPageNumber = 1);
  }
}
