import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IOption } from 'src/models/campaign';
import { UserStatus } from 'src/models/constants';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFormComponent implements OnInit {
  @Output() searchFormSubmitNotfiy = new EventEmitter<string>();
  @Output() searchByNotify = new EventEmitter<object>();
  @Output() statusOfUser = new EventEmitter<string>();
  @Input() allowSearchBy = false;
  @Input() isUsers = false;

  @Input() searchOptions: IOption[] = null;
  @Input() showTooltip = false;
  @Input() positionTooltipTop: number;
  @Input() positionTooltipRight: number;
  @Input() textTooltip: string;
  @Input() widthTooltip;
  searchForm: FormGroup;
  userStatus = UserStatus;

  toggleTooltip = false;

  status: string;

  constructor() {}

  ngOnInit() {
    this.searchForm = new FormGroup({
      term: new FormControl(''),
      searchBy: new FormControl(0)
    });
    this.status = this.userStatus.ACTIVE;
    this.statusOfUser.emit(this.userStatus.ACTIVE);
  }

  onSearchFormSubmit() {
    if (this.allowSearchBy) {
      this.searchByNotify.emit({
        value: this.searchForm.value.term
          ? this.searchForm.value.term.trim()
          : '',
        searchBy: Number(this.searchForm.value.searchBy)
      });
    } else {
      this.searchFormSubmitNotfiy.emit(
        this.searchForm.value.term ? this.searchForm.value.term.trim() : ''
      );
    }
  }

  toggleHoverTooltip(status) {
    this.toggleTooltip = status;
  }
  selectTheStatusOfUser(status) {
    this.status = status;
    this.statusOfUser.emit(status);
    this.searchForm.patchValue({ term: '' });
    // this.onSearchFormSubmit();
  }

  selectTypeOfSearch(type) {
    this.searchForm.patchValue({ searchBy: Number(type) });
  }
}
