import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  mobileQuery: Observable<BreakpointState>;
  mobileQuerySmall: Observable<BreakpointState>;
  constructor(public breakpointObserver: BreakpointObserver) {
    this.mobileQuery = this.breakpointObserver.observe(['(max-width: 1400px)']);
    this.mobileQuerySmall = this.breakpointObserver.observe([
      '(max-width: 767px)'
    ]);
  }
}
