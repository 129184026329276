/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-share.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../abstract-tooltip/hover-tooltip/hover-tooltip.component.ngfactory";
import * as i3 from "../../../abstract-tooltip/hover-tooltip/hover-tooltip.component";
import * as i4 from "@angular/common";
import * as i5 from "ngx-clipboard";
import * as i6 from "./channel-share.component";
import * as i7 from "../../../abstract-notifiers/abstract-notifiers.service";
var styles_ChannelShareComponent = [i0.styles];
var RenderType_ChannelShareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelShareComponent, data: {} });
export { RenderType_ChannelShareComponent as RenderType_ChannelShareComponent };
function View_ChannelShareComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip", [], null, null, null, i2.View_HoverTooltipComponent_0, i2.RenderType_HoverTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipComponent, [], { text: [0, "text"], top: [1, "top"], right: [2, "right"], width: [3, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipText; var currVal_1 = 120; var currVal_2 = (0 - 255); var currVal_3 = "max-content"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChannelShareComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip", [], null, null, null, i2.View_HoverTooltipComponent_0, i2.RenderType_HoverTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipComponent, [], { text: [0, "text"], top: [1, "top"], right: [2, "right"], width: [3, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipText; var currVal_1 = 120; var currVal_2 = (0 - 185); var currVal_3 = "max-content"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChannelShareComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip", [], null, null, null, i2.View_HoverTooltipComponent_0, i2.RenderType_HoverTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipComponent, [], { text: [0, "text"], top: [1, "top"], right: [2, "right"], width: [3, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipText; var currVal_1 = 120; var currVal_2 = (0 - 165); var currVal_3 = "max-content"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ChannelShareComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hover-tooltip", [], null, null, null, i2.View_HoverTooltipComponent_0, i2.RenderType_HoverTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i3.HoverTooltipComponent, [], { text: [0, "text"], top: [1, "top"], right: [2, "right"], width: [3, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipText; var currVal_1 = 120; var currVal_2 = (0 - 190); var currVal_3 = "max-content"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ChannelShareComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "share-social-box link clickable-cursor"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-link"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelShareComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", "share-social-box facebook clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareOnSocial($event, "F") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fab fa-facebook-f"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelShareComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "a", [["class", "share-social-box twitter clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareOnSocial($event, "T") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "fab fa-twitter"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelShareComponent_3)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "a", [["class", "share-social-box copy clickable-cursor"], ["ngxClipboard", ""]], null, [[null, "cbOnSuccess"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("cbOnSuccess" === en)) {
        var pd_1 = (_co.copySuccessfullyEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 212992, null, 0, i5.ClipboardDirective, [i5.ClipboardService], { targetElm: [0, "targetElm"], cbContent: [1, "cbContent"] }, { cbOnSuccess: "cbOnSuccess" }), (_l()(), i1.ɵeld(16, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-copy"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelShareComponent_4)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hoverShareLink; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.hoverFacebook; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.hoverTwitter; _ck(_v, 13, 0, currVal_3); var currVal_4 = ""; var currVal_5 = _co.shareLink; _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = _co.hoverCopyLink; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shareLink; _ck(_v, 1, 0, currVal_0); }); }
export function View_ChannelShareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-channel-share", [], null, null, null, View_ChannelShareComponent_0, RenderType_ChannelShareComponent)), i1.ɵdid(1, 573440, null, 0, i6.ChannelShareComponent, [i7.AbstractNotifiersService], null, null)], null, null); }
var ChannelShareComponentNgFactory = i1.ɵccf("app-channel-share", i6.ChannelShareComponent, View_ChannelShareComponent_Host_0, { shareLink: "shareLink", screenType: "screenType" }, {}, []);
export { ChannelShareComponentNgFactory as ChannelShareComponentNgFactory };
