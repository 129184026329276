/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-item-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./create-item-frame.component";
var styles_CreateItemFrameComponent = [i0.styles];
var RenderType_CreateItemFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateItemFrameComponent, data: {} });
export { RenderType_CreateItemFrameComponent as RenderType_CreateItemFrameComponent };
export function View_CreateItemFrameComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "form-selector-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "form-selector-create"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "label", [["class", "form-selector-label"], ["id", "createLabel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addnewEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "form-selector-add"], ["src", "./assets/img/add-new.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add new"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["id", "createLabel"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "form-selector-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["alt", ""], ["class", "form-selector-info"], ["src", "./assets/img/add-info.svg"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_CreateItemFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-item-frame", [], null, null, null, View_CreateItemFrameComponent_0, RenderType_CreateItemFrameComponent)), i1.ɵdid(1, 114688, null, 0, i2.CreateItemFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateItemFrameComponentNgFactory = i1.ɵccf("app-create-item-frame", i2.CreateItemFrameComponent, View_CreateItemFrameComponent_Host_0, {}, { addnewNotfiy: "addnewNotfiy" }, ["[form-selector-create-input]", "[form-selector-create-text]"]);
export { CreateItemFrameComponentNgFactory as CreateItemFrameComponentNgFactory };
