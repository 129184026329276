import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';

import * as UiActions from './ui.actions';
import { UIMiddleware } from './ui.middleware';
import { UIDispatchers } from '../ui/ui.dispatchers';
import { MessageDispatchers } from '../messages/message.dispatchers';
import { MainChatDispatchers } from '../mainChats/mainChat.dispatchers';

import { AppState } from 'src/models/AppState';

import * as isEqual from 'lodash.isequal';

@Injectable()
export class UiEffects {
  @Effect({ dispatch: false })
  evaluateShowDashboard = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_EVALUATE_SHOW_DASHBOARD),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual)),
      this._store
        .select(state => state.uiReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(res => {
      const isProfileAndChatsRcvd = UIMiddleware.isAllProfilesAndChatsRcvd(
        res[1],
        res[2]
      );

      if (isProfileAndChatsRcvd && !res[2].detailsReceived) {
        this._uiDispatchers.detailsReceived();
      }
    })
  );

  @Effect({ dispatch: false })
  postReplyButtonSelected = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_SELECT_CHANNEL_POST),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats]) => {
      const action = <UiActions.SelectChannelPost>val;
      this._uiDispatchers.messageSelected(action.payload);
      const adminToPost = mainChats.find(
        chat => chat.id === action.payload.group_id && chat.isAdmin
      );
      if (adminToPost) {
        this._uiDispatchers.groupRepliesSelected();
      } else {
        this._uiDispatchers.selectReplyToAdmin();
      }
    })
  );

  @Effect({ dispatch: false })
  postSubReplyButtonSelected = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_SELECT_SUB_CHANNEL_POST),
    withLatestFrom(
      this._store
        .select(state => state.mainChatReducer)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, mainChats]) => {
      const action = <UiActions.SelectSubChannelPost>val;
      this._uiDispatchers.subParentMessageSelected(action.payload);
      const adminToPost = mainChats.find(
        chat => chat.id === action.payload.group_id && chat.isAdmin
      );
      if (adminToPost) {
        this._uiDispatchers.groupRepliesSelected();
      } else {
        this._uiDispatchers.selectReplyToAdmin();
      }
    })
  );

  @Effect({ dispatch: false })
  downloadMediaMessage = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_SHOW_MEDIA_SCREEN),
    map((action: UiActions.ShowMediaScreen) => {
      const message = action.payload;
      if (
        !message.localThumbnail &&
        message.thumbnail_id &&
        !message.thumbnailStatus
      ) {
        this._messageDispatchers.downloadMessageThumbnail(message);
      }
      if (
        !message.localMedia &&
        !message.mediaStatus &&
        message.message_id &&
        message.media_id
      ) {
        this._messageDispatchers.downloadMediaMessage(message);
      }
    })
  );

  @Effect({ dispatch: false })
  listSelected = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_LIST_SELECTED),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <UiActions.ChatSelected>val;
      if (selectedChat) {
        this._mainChatDispatchers.mainChatSelected(selectedChat);
      }
    })
  );

  @Effect({ dispatch: false })
  chatSelected = this.actions$.pipe(
    ofType(UiActions.UIActionTypes.UI_CHAT_SELECTED),
    withLatestFrom(
      this._store
        .select(state => state.uiReducer.selectedChat)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, selectedChat]) => {
      const action = <UiActions.ChatSelected>val;
      if (selectedChat) {
        this._mainChatDispatchers.mainChatSelected(selectedChat);
      }
    })
  );

  @Effect({ dispatch: false })
  channelDeselected = this.actions$.pipe(
    ofType(UiActions.DESELECT_CHANNEL),
    withLatestFrom(
      this._store
        .select(state => state.authReducer.chatId)
        .pipe(distinctUntilChanged(isEqual))
    ),
    map(([val, chatID]) => {
      const action = <UiActions.DeselectChannel>val;
      this._mainChatDispatchers.mainChatDeselected(chatID, '');
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _uiDispatchers: UIDispatchers,
    private _messageDispatchers: MessageDispatchers,
    private _mainChatDispatchers: MainChatDispatchers
  ) {}
}
