import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as MessageActions from './message.actions';

import { AppState } from 'src/models/AppState';
import { IMessage } from 'src/models/IMessage';
import { MainChat } from 'src/models/MainChat';
import { ITag } from 'src/models/ITag';
import { LikeMessageAck } from 'src/models/PostCounters';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { UrlPreviewMeta } from 'src/models/MetaData';
import { MessageActionTypes } from 'src/models/constants';
import { InstantArticle } from 'src/models/InstantArticle';
import { MenuCallBackStats } from 'src/models/MenuCallBackStats';
import { RcvdMessage, MessageControls } from 'src/models/MessageParts';
import { CalendarTimetable } from 'src/models/Calendar';

@Injectable({
  providedIn: 'root'
})
export class MessageDispatchers {
  constructor(private _store: Store<AppState>) {}

  getLastPostsHistory(): void {
    this._store.dispatch(new MessageActions.GetLastPostsHistory());
  }
  getLastRepliesHistory(): void {
    this._store.dispatch(new MessageActions.GetLastRepliesHistory());
  }
  listScheduleMessages(): void {
    this._store.dispatch(new MessageActions.ListScheduleMessages());
  }
  listAwardMessages(): void {
    this._store.dispatch(new MessageActions.ListAwardMessages());
  }
  listTags(): void {
    this._store.dispatch(new MessageActions.ListTags());
  }

  initReceivedMessage(payload: RcvdMessage): void {
    this._store.dispatch(new MessageActions.InitReceivedMessage(payload));
  }

  receiveMessage(payload: IMessage): void {
    switch (payload.actionType) {
      case MessageActionTypes.NORMAL:
        if (!payload.existedBefore || payload.status === 'PENDING') {
          // This case where new message received

          this._store.dispatch(new MessageActions.ReceiveMessage(payload));
        } else {
          // update exiting message with message_id if not having one and then in the effects download thumbnail if needed
          this.updateExistingMessage(payload);
        }
        break;
      case MessageActionTypes.UPDATED:
        this.messageEdited(payload);
        break;
      case MessageActionTypes.DELETED:
        this.messageRecalled(payload);
        break;
    }
  }

  updateExistingMessage(payload: IMessage): void {
    this._store.dispatch(new MessageActions.UpdateExistingMessage(payload));
  }

  messageEdited(payload: IMessage): void {
    this._store.dispatch(new MessageActions.MessageEdited(payload));
  }

  sendingMessage(payload: IMessage): void {
    this._store.dispatch(new MessageActions.SendingMessage(payload));
  }

  sendingScheduleMessage(payload: IMessage) {
    this._store.dispatch(new MessageActions.SendingScheduleMessage(payload));
  }

  sendingAwardMessage(payload: IMessage) {
    this._store.dispatch(new MessageActions.SendingAwardMessage(payload));
  }
  sendingWelcomeMessage(payload: IMessage) {
    this._store.dispatch(new MessageActions.SendingWelcomeMessage(payload));
  }

  messageSent(payload: IMessage): void {
    this._store.dispatch(new MessageActions.MessageSent(payload));
  }

  messageDelivered(payload: IMessage): void {
    this._store.dispatch(new MessageActions.MessageDelivered(payload));
  }

  messageSeen(message_id: string): void {
    this._store.dispatch(new MessageActions.MessageSeen(message_id));
  }

  messagesSeenReceived(message_ids: string[]): void {
    this._store.dispatch(new MessageActions.MessagesSeenReceived(message_ids));
  }

  resetNumberOfUnreadReplies(messageID: string) {
    this._store.dispatch(
      new MessageActions.ResetNumberOfUnreadReplies(messageID)
    );
  }

  setMessageLocalMedia(msg: IMessage): void {
    this._store.dispatch(new MessageActions.SetMessageLocalMedia(msg));
  }

  setMessageLocalThumbnail(msg: IMessage): void {
    this._store.dispatch(new MessageActions.SetMessageLocalThumbnail(msg));
  }

  downloadMediaMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.DownloadMediaMessage(msg));
  }

  downloadMessageThumbnail(msg: IMessage): void {
    this._store.dispatch(new MessageActions.DownloadMessageThumbnail(msg));
  }

  cancelDownloadMediaMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.CancelDownloadMediaMessage(msg));
  }

  likeMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.LikeMessage(msg));
  }

  unlikeMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.UnlikeMessage(msg));
  }

  likeAckReceived(likeAck: LikeMessageAck) {
    this._store.dispatch(new MessageActions.LikeAckReceived(likeAck));
  }

  shareMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.ShareMessage(msg));
  }

  recallMessage(msg: IMessage): void {
    this._store.dispatch(new MessageActions.RecallMessage(msg));
  }

  setMessageMediaStatus(msg: IMessage): void {
    this._store.dispatch(new MessageActions.SetMessageMediaStatus(msg));
  }

  setMessageThumbnailStatus(msg: IMessage): void {
    this._store.dispatch(new MessageActions.SetMessageThumbnailStatus(msg));
  }

  messageIsViewed(msg: IMessage): void {
    this._store.dispatch(new MessageActions.MessageIsViewed(msg));
  }

  messageRecalled(message: IMessage): void {
    this._store.dispatch(new MessageActions.MessageRecalled(message));
  }

  chatCountersReceived(msgs: IMessage[]) {
    this._store.dispatch(new MessageActions.ChatCountersReceived(msgs));
  }

  setLinkPreviewStatus(msg: IMessage) {
    this._store.dispatch(new MessageActions.SetLinkPreviewStatus(msg));
  }

  linkPreviewDetailsReceived(msg: IMessage) {
    this._store.dispatch(new MessageActions.LinkPreviewDetailsReceived(msg));
  }

  cancelUpload(msg: IMessage): void {
    this._store.dispatch(new MessageActions.CancelUpload(msg));
  }

  updateLoadingProgress(msg: IMessage, progress: number) {
    this._store.dispatch(
      new MessageActions.UpdateLoadingProgress(msg, progress)
    );
  }

  chatMessagesOutOfViewPort(groupID: string): void {
    this._store.dispatch(new MessageActions.ChatMessagesOutOfViewPort(groupID));
  }

  contactMessagesOutOfViewPort(id: string): void {
    this._store.dispatch(new MessageActions.ContactMessagesOutOfViewPort(id));
  }

  readChatMessages(mainChat: MainChat): void {
    this._store.dispatch(new MessageActions.ReadChatMessages(mainChat));
  }

  chatDeselected(mainChat: MainChat): void {
    this._store.dispatch(new MessageActions.ChatDeselected(mainChat));
  }

  historyEffect(message: IMessage): void {
    this._store.dispatch(new MessageActions.HistoryEffect(message));
  }

  deselectChannel(): void {
    this._store.dispatch(new MessageActions.DeselectChannel());
  }

  getBlobUrl(selectedFiles: ISelectedFiles): void {
    this._store.dispatch(new MessageActions.GetBlobUrl(selectedFiles));
  }

  cancelMediaOperation(message: IMessage): void {
    this._store.dispatch(new MessageActions.CancelMediaOperation(message));
  }

  retryMediaOperation(message: IMessage): void {
    this._store.dispatch(new MessageActions.RetryMediaOperation(message));
  }

  previewLinkMessage(message: IMessage): void {
    this._store.dispatch(new MessageActions.PreviewLinkMessage(message));
  }

  /** Schedule message dispatchers */
  receiveAwardMessage(payload: IMessage): void {
    this._store.dispatch(new MessageActions.ReceiveAwardMessage(payload));
  }
  receiveWelcomeMessage(payload: IMessage): void {
    this._store.dispatch(new MessageActions.ReceiveWelcomeMessage(payload));
  }

  receiveScheduleMessage(payload: IMessage): void {
    this._store.dispatch(new MessageActions.ReceiveScheduleMessage(payload));
  }

  receiveScheduleMessageCanceled(payload: IMessage): void {
    this._store.dispatch(
      new MessageActions.ReceiveScheduleMessageCanceled(payload)
    );
  }

  /***************************************************************************************** */
  sendMessageNow(msg: IMessage) {
    this._store.dispatch(new MessageActions.SendMessageNow(msg));
  }
  cancelScheduleMessage(messageId: string) {
    this._store.dispatch(new MessageActions.CancelScheduleMessage(messageId));
  }

  sendTextMessage(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    text: string,
    misc?: MessageControls
  ) {
    this._store.dispatch(
      new MessageActions.SendTextMessage(
        mainChat,
        parentMessage,
        userId,
        text,
        misc
      )
    );
  }

  sendInstantUrlMessage(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    istantArticle: InstantArticle,
    misc?: MessageControls
  ) {
    this._store.dispatch(
      new MessageActions.SendInstantUrlMessage(
        mainChat,
        parentMessage,
        userId,
        istantArticle,
        misc
      )
    );
  }

  sendMediaMessage(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    file: File,
    mediaType: string,
    previewMetadata?: UrlPreviewMeta,
    blobUrl?: string,
    misc?: MessageControls
  ): void {
    this._store.dispatch(
      new MessageActions.SendMediaMessage(
        mainChat,
        parentMessage,
        userId,
        file,
        mediaType,
        previewMetadata,
        blobUrl,
        misc
      )
    );
  }

  sendCalendarMessage(
    mainChat: MainChat,
    parentMessage: IMessage,
    userId: string,
    calendar: CalendarTimetable,
    misc?: MessageControls
  ): void {
    this._store.dispatch(
      new MessageActions.SendCalendarMessage(
        mainChat,
        parentMessage,
        userId,
        calendar,
        misc
      )
    );
  }

  /***********************************************************************/
  /** TAG */
  sendingCreateTag(tag: ITag) {
    this._store.dispatch(new MessageActions.SendingCreateTag(tag));
  }
  sendingDeleteTag(tag: ITag) {
    this._store.dispatch(new MessageActions.SendingDeleteTag(tag));
  }
  sendingMemberTags(user_id: string, tags: string[]) {
    this._store.dispatch(
      new MessageActions.SendingSetMemberTags({ user_id, tags })
    );
  }

  /***************************************************************************/
  /** stats */
  getMessageMenuStats(message_id: string) {
    this._store.dispatch(new MessageActions.GetMessageMenuStats(message_id));
  }
  setMessageMenuStats(message_id: string, menuStats: MenuCallBackStats[]) {
    this._store.dispatch(
      new MessageActions.SetMessageMenuStats({ message_id, menuStats })
    );
  }
  getInlineMessageCallBack(buttonData) {
    this._store.dispatch(
      new MessageActions.GetInlineMessageCallBack(buttonData)
    );
  }
}
