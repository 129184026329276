import * as AuthActions from './auth.actions';

import { IAuth } from 'src/models/IAuth';
import { AuthStatus, SignupStatus } from 'src/models/constants';

const initAuth: IAuth = {
  token: null,
  name: null,
  url: null,
  authStatus: AuthStatus.INIT_SOKET,
  signupStatus: SignupStatus.ENTER_EMAIL,
  qrExpire: false,
  selectedTemplateID: null,
  adminQRCode: null,
  countryIso: ''
};

export function authReducer(
  state: IAuth = initAuth,
  action: AuthActions.AuthActions
): IAuth {
  switch (action.type) {
    case AuthActions.RECIVE_AUTH_QR:
      return Object.assign({}, state, {
        qrCode: action.payload.qrCode,
        qrExpire: false,
        authStatus: AuthStatus.AUTH_QR_SET
      });
    case AuthActions.SET_COUNTRY_DATA:
      return Object.assign({}, state, {
        countryIso: action.countryData.country_code,
        timezone: action.countryData.timezone
      });
    case AuthActions.EMAIL_TAC_SUCCESS:
      return Object.assign({}, state, {
        signupStatus: SignupStatus.EMAIL_TAC_SENT,
        email: action.email,
        appName: action.appName,
        name: action.name,
        password: action.password
      });
    case AuthActions.CREATE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        signupStatus: SignupStatus.ACCOUNT_CREATED,
        password: action.password
      });
    case AuthActions.EMAIL_TOKEN_RECEIVED:
      return Object.assign({}, state, {
        signupStatus: SignupStatus.EMAIL_TOKEN_RECEIVED,
        token: action.token,
        authStatus: AuthStatus.TOKEN_RECEIVED
      });
    case AuthActions.RECIVE_AUTH_TOKEN:
      return Object.assign({}, state, {
        token: action.payload.token,
        authStatus: AuthStatus.TOKEN_RECEIVED
      });
    case AuthActions.RECIVE_AUTH_TOKEN_CHANGE:
      return Object.assign({}, state, {
        token: action.payload.token,
        url: action.payload.url
      });
    case AuthActions.RECIVE_MULTI_LOGIN:
      return Object.assign({}, state, {
        authStatus: AuthStatus.MULTI_LOGIN
      });
    case AuthActions.RECIVE_AUTH_OK:
      return Object.assign({}, state, {
        localId: action.payload.localId,
        name: action.payload.name,
        authStatus: AuthStatus.AUTH_TOKEN_OK,
        ID: action.payload.ID,
        date: action.payload.date,
        chatId: action.payload.chatId,
        offset: Date.now() - action.payload.date
      });
    case AuthActions.RECIVE_AUTH_NOT:
      return Object.assign({}, state, {
        authStatus: AuthStatus.AUTH_TOKEN_NOT
      });
    case AuthActions.SET_AUTH_QR_EXPIRE:
      return Object.assign({}, state, {
        qrExpire: true,
        authStatus: AuthStatus.AUTH_QR_EXPIRE
      });
    case AuthActions.RESET:
      return Object.assign({}, state, initAuth);
    case AuthActions.LOGGED_OUT:
      return Object.assign({}, state, {
        authStatus: AuthStatus.LOGGED_OUT,
        signupStatus: SignupStatus.ENTER_EMAIL,
        selectedTemplateID: null
      });
    /* Merchant Web Only  */
    case AuthActions.RECIVE_AUTH_CHAT_LIST:
      return Object.assign({}, state, {
        authStatus: AuthStatus.AUTH_CHATLIST_SET
      });
    case AuthActions.RECEIVE_AUTH_CHAT_SELECTED:
      return Object.assign({}, state, {
        chatId: action.payload.chatId,
        progressWeb: action.payload.progressWeb,
        mode: action.payload.mode,
        appConfig: action.payload.appConfig,
        role: action.payload.role,
        is_nandbox: action.payload.is_nandbox,
        main_group_id: action.payload.main_group_id,
        authStatus: AuthStatus.AUTH_CHAT_SELECTED
      });

    case AuthActions.SELECT_TEMPLATE:
      return { ...state, selectedTemplateID: action.templateID };
    case AuthActions.SET_APP_PROGRESS:
      return { ...state, progressWeb: action.progressWeb, mode: action.mode };
    case AuthActions.LOGIN_BY_EMAIL:
      return { ...state, authStatus: AuthStatus.AUTH_LOGIN_BY_EMAIL };
    case AuthActions.RECEIVE_ADMIN_QR:
      if (action.chatID === state.chatId) {
        return { ...state, adminQRCode: action.adminQR };
      } else {
        return state;
      }
    default:
      return state;
  }
}
