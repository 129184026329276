import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-reactive-color-input',
  templateUrl: './reactive-color-input.component.html',
  styleUrls: ['./reactive-color-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveColorInputComponent implements OnInit {
  @Input() colorSelected: string;
  @Input() colorLabel: string;
  @Input() colorId: string;
  @Input() isInline = false;

  @Output() selectedColor = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {}
  onChangeColor(e) {
    this.colorSelected = e;
    this.selectedColor.emit(e);
  }
}
