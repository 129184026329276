import { RootStoreSelectors } from '../store/root-store.selectors';
import { AuthDispatchers } from '../store/auth/auth.dispatchers';
import * as i0 from "@angular/core";
import * as i1 from "../store/auth/auth.dispatchers";
import * as i2 from "../store/root-store.selectors";
export class CredentialService {
    constructor(_authDispatchers, _selectors) {
        this._authDispatchers = _authDispatchers;
        this._selectors = _selectors;
    }
    get credential() {
        return this._authDispatchers.localAuth;
    }
    get authStore() {
        return this._selectors.authCollection$;
    }
    setChatSelect(chatID) {
        return this._authDispatchers.setChatSelect(chatID);
    }
    setQRExpire() {
        this._authDispatchers.setAuthQRExpire();
    }
    resetAuth() {
        this._authDispatchers.resetStore();
    }
    fireTokenReceivedEvent(payload) {
        this._authDispatchers.reciveAuthToken(payload);
    }
    removeLocalAuth() {
        this._authDispatchers.removeLocalAuth();
    }
}
CredentialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CredentialService_Factory() { return new CredentialService(i0.ɵɵinject(i1.AuthDispatchers), i0.ɵɵinject(i2.RootStoreSelectors)); }, token: CredentialService, providedIn: "root" });
