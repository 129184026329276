import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxModule } from 'src/app/ngx.module';
import { AccessorsModule } from 'src/app/accessors/accessors.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AbstractBtnsModule } from 'src/app/abstract-btns/abstract-btns.module';
import { AbstractFramesModule } from 'src/app/abstract-frames/abstract-frames.module';

import { MenusPoolComponent } from './menus-pool/menus-pool.component';
@NgModule({
  imports: [
    CommonModule,
    NgxModule,
    AccessorsModule,
    SharedModule,
    AbstractBtnsModule,
    AbstractFramesModule
  ],
  declarations: [MenusPoolComponent],
  exports: [MenusPoolComponent]
})
export class MenusPoolModule {}
