import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ContainerActions from './container.actions';

import { AppState } from 'src/models/AppState';
import { TabTypes } from 'src/models/constants';
import { TabTagItem } from 'src/models/ChannelAppConfig';
import {
  LocalContainer,
  LocalAppClass
} from 'src/models/ChannelAppLocalConfig';

@Injectable({
  providedIn: 'root'
})
export class ContainerDispatchers {
  constructor(private _store: Store<AppState>) {}

  updateContainer(
    componentRef: string,
    containerUpdatedProps: LocalContainer
  ): void {
    this._store.dispatch(
      new ContainerActions.UpdateContainer(componentRef, containerUpdatedProps)
    );
  }

  reorderContainer(containerRef: string, newPosition: number): void {
    this._store.dispatch(
      new ContainerActions.ReorderContainer(containerRef, newPosition)
    );
  }

  deleteContainerRequest(container: LocalContainer): void {
    this._store.dispatch(
      new ContainerActions.DeleteContainerRequest(container)
    );
  }

  deleteContainer(container: LocalContainer): void {
    this._store.dispatch(new ContainerActions.DeleteContainer(container));
  }

  addSectionRequest(
    parentTabRef: string,
    specialContainerValues?: LocalContainer
  ): void {
    this._store.dispatch(
      new ContainerActions.AddSectionRequest(
        parentTabRef,
        specialContainerValues
      )
    );
  }

  addContainerRequest(
    parentAppClass: LocalAppClass,
    specialContainerValues?: LocalContainer
  ): void {
    this._store.dispatch(
      new ContainerActions.AddContainerRequest(
        parentAppClass,
        specialContainerValues
      )
    );
  }

  addContainer(container: LocalContainer): void {
    this._store.dispatch(new ContainerActions.AddContainer(container));
  }

  selectContainer(selectedContainer: LocalContainer): void {
    this._store.dispatch(
      new ContainerActions.SelectContainer(selectedContainer)
    );
  }

  selectFirstContainer(appClassName: string): void {
    this._store.dispatch(
      new ContainerActions.SelectFirstContainer(appClassName)
    );
  }

  updateTabType(containerRef: string, tabType: TabTypes, tabID: string): void {
    this._store.dispatch(
      new ContainerActions.UpdateTabType(containerRef, tabType, tabID)
    );
  }

  updateTabTypeRequest(
    containerRef: string,
    tabType: TabTypes,
    pageId: string
  ): void {
    this._store.dispatch(
      new ContainerActions.UpdateTabTypeRequest(containerRef, tabType, pageId)
    );
  }

  receiveTagValues(tabsTags: TabTagItem[], isChannelConfigOnly: boolean): void {
    this._store.dispatch(
      new ContainerActions.ReceiveTagValues(tabsTags, isChannelConfigOnly)
    );
  }

  updateMapContainerOnPublish(isChannelOnly: boolean) {
    this._store.dispatch(
      new ContainerActions.UpdateMapContainerOnPublish(isChannelOnly)
    );
  }
}
