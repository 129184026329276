import * as tslib_1 from "tslib";
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, distinctUntilChanged, map } from 'rxjs/operators';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import { CouponUIDispatchers } from './couponUI.dispatchers';
import * as isEqual from 'lodash.isequal';
import { GetCoupons, GetCoupon } from 'src/models/ICoupon';
export class CouponUiEffects {
    constructor(actions$, _socketGateway, _store, _couponDispatchers) {
        this.actions$ = actions$;
        this._socketGateway = _socketGateway;
        this._store = _store;
        this._couponDispatchers = _couponDispatchers;
        this.getCoupons = this.actions$.pipe(ofType("UI_COUPON_RECEIVED_HISTORY_CHUNK" /* UI_COUPON_RECEIVED_HISTORY_CHUNK */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState]) => {
            couponUiState.currentPage.forEach(couponId => {
                this._socketGateway.sendSocketMessage(new GetCoupon(couponId));
            });
        }));
        this.getNextCalendarPage = this.actions$.pipe(ofType("UI_COUPON_GET_NEXT_PAGE" /* UI_COUPON_GET_NEXT_PAGE */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCoupons(0, couponUiState.hash, couponUiState.eop, couponUiState.couponsStatus, couponUiState.expire));
        }));
        this.getPrevCalendarPage = this.actions$.pipe(ofType("UI_COUPON_GET_PREV_PAGE" /* UI_COUPON_GET_PREV_PAGE */), withLatestFrom(this._store
            .select(state => state.couponUiReducer)
            .pipe(distinctUntilChanged(isEqual))), map(([val, couponUiState]) => {
            this._socketGateway.sendSocketMessage(new GetCoupons(1, couponUiState.hash, couponUiState.sop, couponUiState.couponsStatus, couponUiState.expire));
        }));
        this.afterCalendarSectionSelection = this.actions$.pipe(ofType("UI_COUPON_LIST" /* UI_LIST_COUPON */), map((action) => {
            this._couponDispatchers.getNextCouponPage();
        }));
        this.afterCalendarResetPaging = this.actions$.pipe(ofType("UI_COUPON_RESET_PAGING" /* UI_COUPON_RESET_PAGING */), map((action) => {
            this._couponDispatchers.getNextCouponPage();
        }));
    }
}
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getCoupons", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getNextCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "getPrevCalendarPage", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "afterCalendarSectionSelection", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Object)
], CouponUiEffects.prototype, "afterCalendarResetPaging", void 0);
