import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-selector-frame',
  templateUrl: './selector-frame.component.html',
  styleUrls: ['./selector-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorFrameComponent implements OnInit {
  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() addnewNotfiy = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  cloaseEvent() {
    this.closeNotify.emit(true);
  }

  addnewEvent() {
    this.addnewNotfiy.emit(true);
  }
}
