import { GET_SMTP_METHOD, SET_SMTP_METHOD } from './constants';

export interface Smtp {
  name?: string;
  host?: string;
  port?: number;
  active?: number;
  secure?: boolean;
  user?: Stmpuser;
}

export interface Stmpuser {
  name?: string;
  password?: string;
}

export class SetSmtpMethod {
  readonly method = SET_SMTP_METHOD;
  constructor(public smtp: Smtp) {}
}

export class GetSmtpMethod {
  readonly method = GET_SMTP_METHOD;
  constructor() {}
}
