import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { ScreenSizeService } from 'src/app/core/screen-size.service';
import { DashboardService } from './dashboard.service';
import { AppMgmtService } from './app-mgmt/appMgmt.service';

import { slideToDown } from 'src/app/app-animations';
import { AppService } from 'src/app/app.service';
import { UiService } from 'src/app/core/ui.service';
import { AuthService } from 'src/app/core/auth.service';

import { IMessage } from 'src/models/IMessage';
import { PrivilegesName } from 'src/models/privileges';
import {
  CHANNELS,
  UISections,
  MessageSendingType,
  AuthStatus,
  MAIN_WEB
} from 'src/models/constants';
import { NavigateService } from 'src/app/core/navigate.service';
import { LayoutService } from './../+layout/layout.service';
import { UIDispatchers } from './../../store/ui/ui.dispatchers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [slideToDown],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, OnDestroy {
  uiSections: typeof UISections = UISections;
  authStatus: typeof AuthStatus = AuthStatus;

  isChrome = true;
  isMobile = false;
  // UI variables
  showTagModal = false;
  isMenuOpen = true;
  initComposeMessage: IMessage = null;
  messageSendingType: typeof MessageSendingType = MessageSendingType;
  privilegesName: typeof PrivilegesName = PrivilegesName;
  authSubscribtion = null;
  constructor(
    public screenSize: ScreenSizeService,
    private _navigatedService: NavigateService,
    public router: Router,
    public uiService: UiService,
    public appServer: AppService,
    public authService: AuthService,
    public appMgmtService: AppMgmtService,
    public dashboardService: DashboardService,
    public _uiDispatchers: UIDispatchers
  ) {}

  ngOnInit() {
    this.uiService.selectTab(CHANNELS);

    /**
     * Check if browser is chrome as currently we are support chrome oly
     */
    // const ua = navigator.userAgent.toLowerCase();
    // this.isChrome = ua.indexOf('chrome') > -1;

    // this.isChrome =
    //   !!(<any>window).chrome &&
    //   (!!(<any>window).chrome.webstore ||
    //     !!(<any>window).chrome ||
    //     !!(<any>window).Chrome_headless);

    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this._navigatedService.preventBackButton();
  }

  ngOnDestroy() {
    this._navigatedService.goBack();
  }

  /** Events section */
  backFromGroupReplies() {
    this.uiService.backFromGroupReplies();
  }
  backFromOneToOneReply() {
    this.uiService.backFromOneToOneReply();
  }
  talkToAdminEvent(chatId: string) {
    this.uiService.requestHistory(chatId);
    this.uiService.selectTalkToAdmin();
  }

  toggleMenuEvent() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  searchEvent(searchTerm: string): void {
    this.paginationEvent('rest');
    this.dashboardService.setSearchKeyword(searchTerm);
  }
  sectionEvent(direction: string): void {
    this.paginationEvent('rest');
    this.dashboardService.setSection(direction);
  }
  selectEvent(action: string) {
    this.dashboardService.setSelect(action);
  }

  paginationEvent(value: string): void {
    this.dashboardService.setPageNumber(value);
  }
  filterDateEvent($event) {
    this.dashboardService.setScheduleDate($event);
  }
  logoutEvent($event) {
    if ($event) {
      this.dashboardService.logout();
    }
  }
  useHereEvent() {
    this.authService.reopenNewConnection();
  }
  closeWindowEvent() {
    (<any>window).location.assign(MAIN_WEB);
  }
}
