/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-media.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/directives/cancel-event/cancel-event.directive";
import * as i4 from "../../emojibox/emoji-keyboard/emoji-keyboard.component.ngfactory";
import * as i5 from "../../emojibox/emoji-keyboard/emoji-keyboard.component";
import * as i6 from "../../emojibox/emojibox.service";
import * as i7 from "ngx-bootstrap/dropdown";
import * as i8 from "ngx-bootstrap/component-loader";
import * as i9 from "../../shared/directives/auto-focus/auto-focus.directive";
import * as i10 from "../../abstract-btns/border-btn/border-btn.component.ngfactory";
import * as i11 from "../../abstract-btns/border-btn/border-btn.component";
import * as i12 from "./preview-media.component";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../core/ui.service";
import * as i15 from "../../core/helper.service";
var styles_PreviewMediaComponent = [i0.styles];
var RenderType_PreviewMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewMediaComponent, data: {} });
export { RenderType_PreviewMediaComponent as RenderType_PreviewMediaComponent };
function View_PreviewMediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "header"]], [[4, "background-color", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Preview "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times close clickable-cursor"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelMediaPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.themeColor; _ck(_v, 0, 0, currVal_0); }); }
function View_PreviewMediaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bot-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Preview "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelMediaPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:use", [[":xlink:href", "/assets/svg/app-sections.svg#icon-close-circle"]], null, null, null, null, null))], null, null); }
function View_PreviewMediaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "spiner-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "preview-spinner"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.theme; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PreviewMediaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The file you are trying to upload is not a supported file type "]))], null, null); }
function View_PreviewMediaComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "media"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; var currVal_1 = ((_co.mediaMetadata == null) ? null : _co.mediaMetadata.fileName); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PreviewMediaComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["videoTag", 1]], null, 1, "video", [["class", "media"], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; _ck(_v, 1, 0, currVal_0); }); }
function View_PreviewMediaComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["audioTag", 1]], null, 1, "audio", [["class", "media"], ["controls", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "source", [["type", "audio/mp4"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.media; _ck(_v, 1, 0, currVal_0); }); }
function View_PreviewMediaComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "media document wrap-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "wrap-text"], ["dir", "auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.mediaMetadata == null) ? null : _co.mediaMetadata.fileName); _ck(_v, 3, 0, currVal_0); }); }
function View_PreviewMediaComponent_10(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PreviewMediaComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "media-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_10)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.messageType === _co.mTypes.PHOTO) || ((_co.messageType === _co.mTypes.GIF) && ((_co.files == null) ? null : ((_co.files.name == null) ? null : _co.files.name.endsWith(".gif"))))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.messageType === _co.mTypes.VIDEO) || ((_co.messageType === _co.mTypes.GIF) && ((_co.files == null) ? null : ((_co.files.name == null) ? null : _co.files.name.endsWith(".mp4"))))); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.messageType === _co.mTypes.AUDIO); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.messageType === _co.mTypes.DOCUMENT); _ck(_v, 8, 0, currVal_3); var currVal_4 = (((_co.messageType === _co.mTypes.PHOTO) || (_co.messageType === _co.mTypes.VIDEO)) || (_co.messageType === _co.mTypes.GIF)); _ck(_v, 10, 0, currVal_4); }, null); }
function View_PreviewMediaComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["appCancelEvent", ""], ["class", "dropdown-menu emoji-box"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onHostClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.CancelEventDirective, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-emoji-keyboard", [], null, [[null, "emoijNotify"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emoijNotify" === en)) {
        var pd_0 = (_co.emoijNotify($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_EmojiKeyboardComponent_0, i4.RenderType_EmojiKeyboardComponent)), i1.ɵdid(3, 49152, null, 0, i5.EmojiKeyboardComponent, [i6.EmojiboxService], null, { emoijNotify: "emoijNotify" })], null, null); }
export function View_PreviewMediaComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { videoTag: 0 }), i1.ɵqud(671088640, 2, { audioTag: 0 }), i1.ɵqud(671088640, 3, { captionBox: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "preview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [["class", "chat-box"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 7, "div", [["class", "btn-group clickable-cursor"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], [[null, "isOpenChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isOpenChange" === en)) {
        var pd_0 = (_co.toggleEmojiKeyboard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.BsDropdownState, i7.BsDropdownState, []), i1.ɵdid(17, 212992, null, 0, i7.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i8.ComponentLoaderFactory, i7.BsDropdownConfig, i7.BsDropdownState], { dropup: [0, "dropup"], autoClose: [1, "autoClose"], isOpen: [2, "isOpen"] }, { isOpenChange: "isOpenChange" }), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["dropdownToggle", ""], ["type", "button"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 147456, null, 0, i7.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i7.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i7.BsDropdownState], null, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "emoji far fa-smile"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewMediaComponent_11)), i1.ɵdid(22, 16384, null, 0, i7.BsDropdownMenuDirective, [i7.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(23, 0, [[3, 0], ["captionBox", 1]], null, 1, "div", [["appAutoFocus", ""], ["class", "caption wrap-text copyable-item text-cursor"], ["contenteditable", ""], ["data-text", "Add a caption\u2026"], ["dir", "auto"]], null, [[null, "paste"], [null, "mouseup"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paste" === en)) {
        var pd_0 = (_co.onRichPaste($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.lastfocus() !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (_co.lastfocus() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 606208, null, 0, i9.AutoFocusDirective, [i1.ElementRef], { selectedChat: [0, "selectedChat"] }, null), i1.ɵncd(null, 1), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(27, 0, null, null, 7, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 6, "div", [["class", "form-submit"]], null, null, null, null, null)), i1.ɵncd(null, 3), i1.ɵncd(null, 4), i1.ɵncd(null, 5), (_l()(), i1.ɵeld(32, 0, null, null, 2, "app-border-btn", [["cancel", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.cancelMediaPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_BorderBtnComponent_0, i10.RenderType_BorderBtnComponent)), i1.ɵdid(33, 573440, null, 0, i11.BorderBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i1.ɵted(-1, 0, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.hideHeader && !_co.isBot); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isBot; _ck(_v, 6, 0, currVal_1); var currVal_2 = (!((_co.mediaMetadata == null) ? null : _co.mediaMetadata.mediaUrl) && !_co.mediaError); _ck(_v, 9, 0, currVal_2); var currVal_3 = (!((_co.mediaMetadata == null) ? null : _co.mediaMetadata.mediaUrl) && _co.mediaError); _ck(_v, 11, 0, currVal_3); var currVal_4 = ((_co.mediaMetadata == null) ? null : _co.mediaMetadata.mediaUrl); _ck(_v, 13, 0, currVal_4); var currVal_9 = true; var currVal_10 = true; var currVal_11 = _co.isEmojiKeyboardOpen; _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11); var currVal_16 = ((_co.selectedChat == null) ? null : _co.selectedChat.id); _ck(_v, 24, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.messageType === "document"); _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵnov(_v, 17).dropup; var currVal_7 = i1.ɵnov(_v, 17).isOpen; var currVal_8 = (i1.ɵnov(_v, 17).isOpen && i1.ɵnov(_v, 17).isBs4); _ck(_v, 15, 0, currVal_6, currVal_7, currVal_8); var currVal_12 = true; var currVal_13 = i1.ɵnov(_v, 19).isDisabled; var currVal_14 = i1.ɵnov(_v, 19).isOpen; _ck(_v, 18, 0, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.isEmojiKeyboardOpen; _ck(_v, 20, 0, currVal_15); }); }
export function View_PreviewMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-media", [], null, [[null, "click"], ["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onComponentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onkeypress($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PreviewMediaComponent_0, RenderType_PreviewMediaComponent)), i1.ɵdid(1, 245760, null, 0, i12.PreviewMediaComponent, [i1.ElementRef, i13.DomSanitizer, i14.UiService, i15.HelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewMediaComponentNgFactory = i1.ɵccf("app-preview-media", i12.PreviewMediaComponent, View_PreviewMediaComponent_Host_0, { selectedChat: "selectedChat", selectedMessage: "selectedMessage", selectedUserId: "selectedUserId", files: "files", messageType: "messageType", mediaMetadata: "mediaMetadata", mediaError: "mediaError", isErrorMessageShown: "isErrorMessageShown", themeColor: "themeColor", hideHeader: "hideHeader", isBot: "isBot" }, { sendMediaNotfiy: "sendMediaNotfiy" }, ["[message-style]", "[message-attachs]", "[message-target]", "[footer-tag]", "[footer-submit]", "[footer-cancel]"]);
export { PreviewMediaComponentNgFactory as PreviewMediaComponentNgFactory };
