<app-form-frame>
  <ng-container ngProjectAs="title">Create Coupon</ng-container>
  <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="couponInput?.couponId" class="form-group">
      <div class="form-inline">
        <div class="control-label md">ID</div>
        <div class="form-group lg-input">
          {{ couponInput?.couponId }}
        </div>
      </div>
    </div>
    <app-reactive-text-input
      [control]="name"
      [isInline]="true"
      [fieldId]="'inputCouponName'"
      [filedLabel]="'Coupon Name'"
      [showTooltip]="true"
      [descriptionTooltip]="'Name your Coupon'"
      [topTooltipHover]="-180"
      [leftTooltipHover]="125"
    >
    </app-reactive-text-input>

    <app-reactive-textarea-input
      [fieldId]="'inputCouponDescription'"
      [filedLabel]="'Coupon Description'"
      [isInline]="true"
      [control]="description"
      [showTooltip]="true"
      [descriptionTooltip]="
        'Describe to users how to use or redeem this coupon'
      "
      [topTooltipHover]="-230"
      [leftTooltipHover]="125"
    >
    </app-reactive-textarea-input>

    <app-reactive-date-input
      [fieldId]="'expireDate'"
      [filedLabel]="'Expire Date'"
      [isInline]="true"
      [control]="expire"
      [showTooltip]="true"
      [descriptionTooltip]="
        'Select from calendar the expire date of this coupon according to your promotion plan'
      "
      [topTooltipHover]="-280"
      [leftTooltipHover]="125"
    >
    </app-reactive-date-input>

    <app-reactive-radio-input
      [isInline]="true"
      [filedLabel]="'Allow Share to others'"
      [radioOptions]="shareOptions"
      [control]="form?.get('share')"
      [showTooltip]="true"
      [descriptionTooltip]="'Users can share coupons or no?'"
      [topTooltipHover]="-280"
      [leftTooltipHover]="125"
    >
    </app-reactive-radio-input>

    <app-reactive-radio-input
      [hidden]="!couponEdit?.couponId"
      [isInline]="true"
      [filedLabel]="'Status'"
      [radioOptions]="statusOptions"
      [control]="form?.get('status')"
    >
    </app-reactive-radio-input>

    <app-reactive-text-input
      [isInline]="true"
      [control]="value"
      [fieldId]="'inputCouponValue'"
      [filedLabel]="'Value'"
      [filedType]="'number'"
    >
    </app-reactive-text-input>

    <app-reactive-text-input
      [hidden]="couponEdit?.couponId"
      [control]="code"
      [isInline]="true"
      [fieldId]="'inputCouponCode'"
      [filedLabel]="'UPC Code'"
      [showTooltip]="true"
      [descriptionTooltip]="'give your coupon a unique code'"
      [topTooltipHover]="-180"
      [leftTooltipHover]="125"
    >
    </app-reactive-text-input>

    <div class="form-group">
      <div class="form-inline">
        <label class="control-label lg"></label>
        <div class="bar-code-container">
          <ngx-barcode
            [bc-value]="upcCode"
            [bc-display-value]="true"
            [bc-font-size]="12"
            [bc-element-type]="'img'"
            [bc-background]="'transparent'"
            [bc-width]="1"
            [bc-height]="50"
            [bc-margin]="0"
            [bc-margin-top]="0"
            [bc-margin-bottom]="0"
            [bc-margin-right]="0"
            [bc-margin-left]="0"
          >
          </ngx-barcode>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="form-submit">
        <app-border-btn submit [type]="'submit'">
          <ng-container *ngIf="couponEdit?.couponId">Edit</ng-container>
          <ng-container *ngIf="!couponEdit?.couponId">Create</ng-container>
        </app-border-btn>
        <app-border-btn *ngIf="showCancel" cancel (btnClicked)="cancelEvent()">
          Cancel
        </app-border-btn>
      </div>
    </div>
  </form>
</app-form-frame>
