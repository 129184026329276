import {
  Component,
  ChangeDetectionStrategy,
  DoCheck,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, debounceTime, take } from 'rxjs/operators';
import { MapGateway } from 'src/app/network/gateway/map.gateway';

@Component({
  selector: 'app-selector-input-location',
  templateUrl: './selector-input-location.component.html',
  styleUrls: ['./selector-input-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorInputLocationComponent
  implements OnInit, DoCheck, OnDestroy {
  /**
   * 'fieldId' is a unique field identification
   * Example: 'email', 'password'
   *    ---REQUIRED PROPERTY---
   */
  @Input() isInline = false;
  @Input() fieldId: string;
  @Input() filedLabel: string;
  // @Input() filedType = 'text'; // default value is string

  /**
   * 'control' is a reactive form validator/value controller
   *    ---REQUIRED PROPERTY---
   */
  @Input() locationControl: AbstractControl;
  @Input() latControl: AbstractControl;
  @Input() longControl: AbstractControl;

  @Input() showTooltip = false;
  @Input() descriptionTooltip: string;
  @Input() topTooltipHover: number;
  @Input() leftTooltipHover: number;

  @Output() valueChange = new EventEmitter<boolean>();

  private _validationErrors = new BehaviorSubject<object>(null);
  public validationErrors$ = this._validationErrors.asObservable();

  locationSubscription = null;
  locList: string[];
  lastLocation = '';

  constructor(
    private _mapGateway: MapGateway,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.locationSubscription = this.locationControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300) // delay request to not hit server many time
      )
      .subscribe(res => {
        //
        if (res !== this.lastLocation) {
          this.getList(res);
        }
      });
  }

  ngDoCheck() {
    if (
      this.locationControl &&
      this.locationControl.touched &&
      this.locationControl.invalid
    ) {
      this._validationErrors.next(this.locationControl['errors']);
    } else {
      this._validationErrors.next(null);
    }
  }

  ngOnDestroy() {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  getList(value: string) {
    this._mapGateway
      .getGooglePlacesAutoComplete(value)
      .subscribe((res: any) => {
        // show list if there are more options to show
        if (res && res.predictions && res.predictions.length > 0) {
          this.locList = res.predictions.map(
            prediction => prediction.description
          );
          this._ref.detectChanges();
        }
      });
    if (value === '' || value === null) {
      this.latControl.setValue('');
      this.longControl.setValue('');
    }
  }

  getPlaceDetails(value: string) {
    this.locationControl.setValue(value);
    this.locList = new Array(0);
    this.lastLocation = value;
    this._ref.detectChanges();
    this._mapGateway.getGooglePlace(value).subscribe((res: any) => {
      this.latControl.setValue(res.candidates[0].geometry.location.lat);
      this.longControl.setValue(res.candidates[0].geometry.location.lng);
      this.valueChange.emit(true);
    });
  }
}
