<app-selector-frame (closeNotify)="cancelEvent()">
  <ng-container ngProjectAs="title">Add Items</ng-container>
  <ng-container ngProjectAs="[form-selector-tabs]">
    <mat-tab-group>
      <ng-container *ngIf="prd">
        <mat-tab *appShowIfHasPrivilege="privilegesName.listproduct">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use
                xlink:href="./assets/svg/app-sections.svg#icon-product"
              ></use>
            </svg>
            Products
          </ng-template>
          <ng-container
            *ngIf="
              (productService.products$ | async)?.length > 0;
              else isProductsEmpty
            "
          >
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="productService.getNextPage()"
              (previousBtnClicked)="productService.getPreviousPage()"
            >
            </app-round-paging-btn>
            <app-selectable-cell-frame
              *ngFor="
                let prod of productService.products$ | async;
                let index = index
              "
              [symbolColor]="productsFet?.symbolColor"
              [symbolImage]="productsFet?.symbolImage"
              [coverColor]="productsFet?.coverColor"
              [coverPhoto]="prod?.url"
              [title]="prod?.name | twemoji"
              [desc]="prod?.desc | twemoji"
              [selectorId]="prod?.id"
              [isSelected]="isProductSelected(prod?.id)"
              (checkClick)="setSelectMessage(prod)"
              (checkChanged)="resetSelectMessage(prod)"
            >
            </app-selectable-cell-frame>
          </ng-container>
          <ng-template #isProductsEmpty>
            <app-empty-item-frame
              [emptySymbol]="productsFet?.symbolImage"
              [emptyTitle]="productsFet?.emptyTitle"
              [emptyDesc]="productsFet?.emptyPoolDesc"
            >
            </app-empty-item-frame>
          </ng-template>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="bundel">
        <mat-tab *appShowIfHasPrivilege="privilegesName.listbundle">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use xlink:href="./assets/svg/app-sections.svg#icon-bundle"></use>
            </svg>
            Bundles
          </ng-template>
          <ng-container
            *ngIf="
              (bundleService.bundles$ | async)?.length > 0;
              else isBundelEmpty
            "
          >
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="bundleService.getNextPage()"
              (previousBtnClicked)="bundleService.getPreviousPage()"
            >
            </app-round-paging-btn>
            <app-selectable-cell-frame
              *ngFor="
                let bundle of bundleService.bundles$ | async;
                let index = index
              "
              [symbolColor]="bundleFet?.symbolColor"
              [symbolImage]="bundleFet?.symbolImage"
              [coverColor]="bundleFet?.coverColor"
              [title]="bundle?.name | twemoji"
              [desc]="bundle?.desc"
              [coverPhoto]="bundle?.url"
              [selectorId]="bundle?.id"
              [isSelected]="isProductSelected(bundle?.id)"
              (checkClick)="setSelectMessage(bundle)"
              (checkChanged)="resetSelectMessage(bundle)"
            >
            </app-selectable-cell-frame>
          </ng-container>
          <ng-template #isBundelEmpty>
            <app-empty-item-frame
              [emptySymbol]="bundleFet?.symbolImage"
              [emptyTitle]="bundleFet?.emptyTitle"
              [emptyDesc]="bundleFet?.emptyPoolDesc"
            >
            </app-empty-item-frame>
          </ng-template>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="pck">
        <mat-tab *appShowIfHasPrivilege="privilegesName.listpackage">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use
                xlink:href="./assets/svg/app-sections.svg#icon-package"
              ></use>
            </svg>
            Multi tiered plans
          </ng-template>
          <ng-container
            *ngIf="
              (packageService.packages$ | async)?.length > 0;
              else isPackagesEmpty
            "
          >
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="packageService.getNextPage()"
              (previousBtnClicked)="packageService.getPreviousPage()"
            >
            </app-round-paging-btn>
            <app-selectable-cell-frame
              *ngFor="
                let package of packageService.packages$ | async;
                let index = index
              "
              [symbolColor]="packagesFet?.symbolColor"
              [symbolImage]="packagesFet?.symbolImage"
              [coverColor]="packagesFet?.coverColor"
              [coverPhoto]="package?.url"
              [title]="package?.name | twemoji"
              [desc]="package?.date | twemoji"
              [selectorId]="package?.id"
              [isSelected]="isProductSelected(package?.id)"
              (checkClick)="setSelectMessage(package)"
              (checkChanged)="resetSelectMessage(package)"
            >
            </app-selectable-cell-frame>
          </ng-container>
          <ng-template #isPackagesEmpty>
            <app-empty-item-frame
              [emptySymbol]="packagesFet?.symbolImage"
              [emptyTitle]="packagesFet?.emptyTitle"
              [emptyDesc]="packagesFet?.emptyPoolDesc"
            >
            </app-empty-item-frame>
          </ng-template>
        </mat-tab>
      </ng-container>
      <ng-container *ngIf="mStore">
        <mat-tab *appShowIfHasPrivilege="privilegesName.listmstore">
          <ng-template mat-tab-label>
            <svg class="svg-icon icon-icon">
              <use xlink:href="./assets/svg/app-sections.svg#icon-mstore"></use>
            </svg>
            mStore
          </ng-template>
          <ng-container *ngIf="(mStoreService.stores$ | async)?.length > 0">
            <app-round-paging-btn
              class="paging-float-btn"
              (nextBtnClicked)="mStoreService.getNextPage()"
              (previousBtnClicked)="mStoreService.getPreviousPage()"
            >
            </app-round-paging-btn>
            <ng-container
              *ngFor="
                let mStore of mStoreService.stores$ | async;
                let index = index
              "
            >
              <app-selectable-cell-frame
                *ngIf="mStore?.id !== mStoreID"
                [symbolColor]="mStoreFet?.symbolColor"
                [symbolImage]="mStoreFet?.symbolImage"
                [coverColor]="mStoreFet?.coverColor"
                [title]="mStore?.name | twemoji"
                [desc]="mStore?.desc"
                [coverPhoto]="mStore?.url"
                [selectorId]="mStore?.id"
                [isSelected]="isProductSelected(mStore?.id)"
                (checkClick)="setSelectMessage(mStore)"
                (checkChanged)="resetSelectMessage(mStore)"
              >
              </app-selectable-cell-frame>
            </ng-container>
          </ng-container>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>
  <ng-container ngProjectAs="[form-selector-action]">
    <div class="form-submit">
      <app-border-btn submit (btnClicked)="insertEvent()">
        Insert
      </app-border-btn>
    </div>
  </ng-container>
</app-selector-frame>
