import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { IMessage } from '../../../models/IMessage';
import { MessageMediaStatus, MessageTypes } from '../../../models/constants';

@Component({
  selector: 'app-message-options',
  templateUrl: './message-options.component.html',
  styleUrls: ['./message-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageOptionsComponent {
  @Input()
  message: IMessage;
  @Input()
  isAdmin: boolean;

  @Output()
  recallNotfiy: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _sanitizer: DomSanitizer) {}

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }

  get isDownloadable() {
    if (this.message) {
      if (
        (this.message.mediaStatus === MessageMediaStatus.DOWNLOADED ||
          this.message.mediaStatus === MessageMediaStatus.UPLOADED) &&
        (this.message.type === MessageTypes.PHOTO ||
          this.message.type === MessageTypes.GIF ||
          this.message.type === MessageTypes.VIDEO ||
          this.message.type === MessageTypes.VOICE ||
          this.message.type === MessageTypes.AUDIO ||
          this.message.type === MessageTypes.DOCUMENT)
      ) {
        return true;
      }
    }
    return false;
  }

  /* Component Events */
  recallEvent() {
    this.recallNotfiy.emit(true);
  }
}
