import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FormValidatorsService } from 'src/app/validators/form-validators.service';

import { ITag } from 'src/models/ITag';
import { AppForm } from 'src/models/AppForm';
import { groupNameValidator } from 'src/app/validators/groupNameValidator';
import { groupDescValidator } from 'src/app/validators/groupDescValidator';
import { IOption } from 'src/models/campaign';

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagFormComponent implements OnChanges, AppForm {
  form: FormGroup;

  localState = {
    genratedQrcode: null
  };

  @Input() tagEdit: ITag = {};
  @Input() groupId: string;

  @Output() cancelNotify = new EventEmitter<boolean>();
  @Output() createTagNotify = new EventEmitter<ITag>();

  radioOptions: IOption[] = [
    { key: 'Public', value: null },
    { key: 'Private', value: 1 }
  ];

  constructor(
    private _fb: FormBuilder,
    private _validateForm: FormValidatorsService
  ) {}

  ngOnChanges() {
    if (this.tagEdit) {
      this.form = this.setFormGroup();
      if (this.tagEdit.id) {
        const tagId = this.tagEdit.id;
        this.localState = {
          ...this.localState,
          genratedQrcode: `${this.groupId}&tag=${tagId}`
        };
      }
    }
  }

  // SetUp Form
  setFormGroup(): FormGroup {
    return this._fb.group({
      id: [this.tagEdit.id ? this.tagEdit.id : null],
      name: [
        this.tagEdit.name ? this.tagEdit.name : null,
        [Validators.required, groupNameValidator]
      ],
      description: [
        this.tagEdit.description ? this.tagEdit.description : null,
        [groupDescValidator]
      ],
      isPrivate: [this.tagEdit.isPrivate || null]
    });
  }

  // Get props
  get name() {
    return this.form.get('name');
  }
  get description() {
    return this.form.get('description');
  }

  onSubmit() {
    if (this.form.valid) {
      const tagToSend = Object.assign({}, this.form.value);
      tagToSend.isPrivate = tagToSend.isPrivate ? 1 : null;
      this.createTagNotify.emit(tagToSend);
      this.cancelEvent();
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }

  printEvent() {
    window.print();
  }

  // TODO: open share window
  shareEvent() {}
}
