<div class="profile-img-block" *ngIf="profile.image">
  <img
    appCancelDrag
    [src]="thumbnail"
    [alt]="profile?.name"
    class="circle-color"
    [class.square]="isSquare"
    [ngStyle]="cssStyles"
  />
  <div class="profile-img-block-svg">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-block"></use>
    </svg>
  </div>
</div>
<div class="profile-img-block" *ngIf="!profile.image">
  <span
    appCancelDrag
    class="circle-color"
    [class.square]="isSquare"
    [innerHTML]="profile?.name | nickName | twemoji"
    [ngStyle]="cssStyles"
  >
  </span>
  <div class="profile-img-block-svg">
    <svg class="svg-icon icon-icon">
      <use xlink:href="./assets/svg/app-sections.svg#icon-block"></use>
    </svg>
  </div>
</div>
