import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { HttpService } from '../services/http.service';
import { StorageService } from 'src/app/core/storage.service';
import { UPLOAD_API, DOMINANT_COLOR } from 'src/models/constants';

@Injectable()
export class UploadGateway {
  constructor(
    private _httpService: HttpService,
    private _storageService: StorageService
  ) {}

  uploadWithProgress(
    file: ArrayBuffer,
    fileType: string,
    name: string,
    isFile: boolean,
    type?: string,
    tabID?: string,
    groupID?: string,
    permanent?: boolean,
    cache?: boolean,
    botID?: string,
    dominantColor?: boolean,
    article?: boolean
  ) {
    const _url = `${this._storageService.getApiUrl(UPLOAD_API)}${name}`;
    // Initialize Params Object
    let params = new HttpParams();
    if (type) {
      params = params.append('type', type);
    }
    if (tabID) {
      params = params.append('tab', tabID);
    }
    if (groupID) {
      params = params.append('grp', groupID);
    }
    if (botID) {
      params = params.append('bot', botID);
    }
    if (permanent) {
      params = params.append('p', '1');
    }
    if (cache) {
      params = params.append('cache', '1');
    }
    if (dominantColor) {
      params = params.append(DOMINANT_COLOR, '1');
    }
    if (article) {
      params = params.append('a', '1');
    }

    this._httpService.uploadWithProgress(
      file,
      _url,
      this.getSafeMimeType(fileType, isFile),
      params
    );

    /*****************************************/
    return this._httpService.uploadWithProgress(
      file,
      _url,
      this.getSafeMimeType(fileType, isFile),
      params
    );
  }

  private getSafeMimeType(mimeType: string, isFile: boolean) {
    // Special case to upload audio file
    if (mimeType.indexOf('audio') !== -1 && !isFile) {
      return 'audio/mpeg';
    }
    return mimeType;
  }
}
