import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as ChatActions from './chat.actions';
import { ChatMiddleware } from './chat.middleware';

import * as ChatMethods from 'src/models/IChat';
import { IRole } from 'src/models/IRole';
import { AppState } from 'src/models/AppState';
import { ChatMember } from 'src/models/IChatMember';
import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IMessage } from 'src/models/IMessage';
import { IChat } from 'src/models/IChat';

@Injectable({
  providedIn: 'root'
})
export class ChatDispatchers {
  constructor(private _store: Store<AppState>) {}

  chatDetailsReceived(payload: ChatMethods.ReceiveChat): void {
    const chat = ChatMiddleware.initChat(payload.chat);
    this._store.dispatch(new ChatActions.ChatDetailsReceived(chat));
  }

  chatDetailsFromCache(payload: ChatMethods.IChat) {
    this._store.dispatch(new ChatActions.ChatDetailsFromCache(payload));
  }

  thumbnailDownloaded(payload: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.ThumbnailDownloaded(payload));
  }

  chatImageDownloaded(payload: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.ChatImageDownloaded(payload));
  }

  chatListReceived(payload: ChatMethods.ReceivedChats) {
    this._store.dispatch(new ChatActions.ChatListReceived(payload.chat_list));
  }

  subChatListReceived(payload: ChatMethods.ReceivedChats, listType?: string) {
    this._store.dispatch(
      new ChatActions.SubChatListReceived(payload.chat_list, listType)
    );
  }

  chatSelected(payload: string): void {
    this._store.dispatch(new ChatActions.ChatSelected(payload));
  }

  chatInvalidated(payload: string): void {
    this._store.dispatch(new ChatActions.ChatInvalidated(payload));
  }

  deselectChat(id: string, lastTypedMessage: string): void {
    this._store.dispatch(new ChatActions.DeselectChat(id, lastTypedMessage));
  }

  requestChatCounters(chatID: string, messageIDs: string[]): void {
    this._store.dispatch(
      new ChatActions.RequestChatCounters(chatID, messageIDs)
    );
  }

  requestChatHistory(payload: string): void {
    this._store.dispatch(new ChatActions.RequestChatHistory(payload));
  }

  newChatReceived(payload: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.NewChatReceived(payload));
  }

  chatMemberCountReceived(payload: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.ChatMemberCountReceived(payload));
  }

  loggedInLeftChat(payload: ChatMember): void {
    const chatMember = ChatMiddleware.initChatMember(payload);
    this._store.dispatch(new ChatActions.LoggedInLeftChat(chatMember));
  }

  isNewChat(chatId: string, chatsState: ChatMethods.IChat[]) {
    return ChatMiddleware.isNewChat(chatId, chatsState);
  }

  tagsReceived(payload: ChatMethods.ReceiveChat): void {
    const { chat } = payload;
    this._store.dispatch(new ChatActions.TagsReceived(chat));
  }
  rolesDelivered(payload: IRole[]): void {
    this._store.dispatch(new ChatActions.RolesDelivered(payload));
  }
  rolesReceived(payload: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.RolesReceived(payload));
  }

  createEvent(eventDetails: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.CreateEvent(eventDetails));
  }

  createBooking(bookingDetails: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.CreateBooking(bookingDetails));
  }

  createGroup(groupDetails: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.CreateGroup(groupDetails));
  }

  createChannel(channelDetails: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.CreateChannel(channelDetails));
  }

  createVirtualApp(virtualAppDetails: ChatMethods.IChat): void {
    this._store.dispatch(new ChatActions.CreateVirtualApp(virtualAppDetails));
  }

  deleteChat(chat_id: string): void {
    this._store.dispatch(new ChatActions.DeleteChat(chat_id));
  }

  chatDeleted(chat_id: string): void {
    this._store.dispatch(new ChatActions.ChatDeleted(chat_id));
  }
  chatDeletedError(chat_id: string, errorNumber: number): void {
    this._store.dispatch(
      new ChatActions.ChatDeletedError(chat_id, errorNumber)
    );
  }

  updateChat(chatDetails: ChatMethods.IChat, reference?: string): void {
    this._store.dispatch(new ChatActions.UpdateChat(chatDetails, reference));
  }

  uploadChatPhoto(chatID: string, selectedFile: ISelectedFiles): void {
    this._store.dispatch(new ChatActions.UploadChatPhoto(chatID, selectedFile));
  }

  chatAdminsReceived(chatID: string, admins: string[]): void {
    this._store.dispatch(new ChatActions.ChatAdminsReceived(chatID, admins));
  }

  configQrCodeReceived(chatID: string, configQrCode: string): void {
    this._store.dispatch(
      new ChatActions.ConfigQrCodeReceived(chatID, configQrCode)
    );
  }

  updateStore(chatDetails: ChatMethods.IChat, reference?: string): void {
    this._store.dispatch(new ChatActions.UpdateStore(chatDetails, reference));
  }

  sendingWelcomeMessage(payload: IMessage) {
    this._store.dispatch(new ChatActions.SendingWelcomeMessage(payload));
  }

  enableService(
    chat_id: string,
    period?: string,
    period_number?: number,
    price?: number,
    currency?: string
  ) {
    this._store.dispatch(
      new ChatActions.EnableService(
        chat_id,
        period,
        period_number,
        price,
        currency
      )
    );
  }

  buySubscriptionOnBehalfOfUser(
    chat_id: string,
    member_id: string,
    currency: string
  ) {
    this._store.dispatch(
      new ChatActions.BuySubscriptionOnBehalfOfUser(
        chat_id,
        member_id,
        currency
      )
    );
  }

  getBookingPeriods(chat_id: string) {
    this._store.dispatch(new ChatActions.GetBookingPeriods(chat_id));
  }
  requestChatDetails(chat_id: string) {
    this._store.dispatch(new ChatActions.RequestChatDetails(chat_id));
  }

  getChatAdministrators(id: string) {
    this._store.dispatch(new ChatActions.GetChatAdministrators(id));
  }
}
