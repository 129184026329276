/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../link-preview-cell/link-preview-cell.component.ngfactory";
import * as i3 from "../link-preview-cell/link-preview-cell.component";
import * as i4 from "@angular/common";
import * as i5 from "./instant-cell.component";
var styles_InstantCellComponent = [i0.styles];
var RenderType_InstantCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantCellComponent, data: {} });
export { RenderType_InstantCellComponent as RenderType_InstantCellComponent };
function View_InstantCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-link-preview-cell", [["class", "instant-cell-preview wrap-text"]], null, null, null, i2.View_LinkPreviewCellComponent_0, i2.RenderType_LinkPreviewCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.LinkPreviewCellComponent, [], { url: [0, "url"], title: [1, "title"], description: [2, "description"], image: [3, "image"], width: [4, "width"], height: [5, "height"], rootUrl: [6, "rootUrl"], style: [7, "style"], redirect: [8, "redirect"], showSite: [9, "showSite"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.message == null) ? null : _co.message.linkPreviewUrl); var currVal_1 = ((_co.message == null) ? null : _co.message.linkPreviewTitle); var currVal_2 = ((_co.message == null) ? null : _co.message.linkPreviewDescription); var currVal_3 = ((_co.message == null) ? null : _co.message.linkPreviewImage); var currVal_4 = ((_co.message == null) ? null : _co.message.linkPreviewWidth); var currVal_5 = ((_co.message == null) ? null : _co.message.linkPreviewHeight); var currVal_6 = ((_co.message == null) ? null : _co.message.linkPreviewRootUrl); var currVal_7 = ((_co.message == null) ? null : _co.message.style); var currVal_8 = _co.redirect; var currVal_9 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_InstantCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "instant-cell-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-circle-notch fa-spin"]], null, null, null, null, null))], null, null); }
export function View_InstantCellComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "instant-cell"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantCellComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantCellComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.message == null) ? null : _co.message.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FETCHED); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.message == null) ? null : _co.message.linkPreviewStatus) === _co.msgLinkPreviewStat.LINK_PREVIEW_FETCHING); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InstantCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instant-cell", [], null, null, null, View_InstantCellComponent_0, RenderType_InstantCellComponent)), i1.ɵdid(1, 114688, null, 0, i5.InstantCellComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantCellComponentNgFactory = i1.ɵccf("app-instant-cell", i5.InstantCellComponent, View_InstantCellComponent_Host_0, { message: "message", redirect: "redirect" }, {}, []);
export { InstantCellComponentNgFactory as InstantCellComponentNgFactory };
