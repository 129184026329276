<div class="form-frame" [class.card]="isCard">
  <!-- Text only title -->
  <div *ngIf="!hideHeader && !titleIcon" class="title-text">
    <div class="head">
      <div class="main"><ng-content select="title"></ng-content></div>
      <div class="sub"><ng-content select="desc"></ng-content></div>
    </div>
    <div class="tail">
      <ng-content select="action"></ng-content>
    </div>
  </div>
  <!-- Title icon -->
  <div *ngIf="titleIcon" class="title-icon" [class.icon-bg]="bgHeadIcon">
    <div class="head">
      <div class="head-icon" [class.icon-bg]="bgHeadIcon">
        <ng-content select="head-icon"></ng-content>
      </div>
      <div class="head-text">
        <ng-content select="head-text"></ng-content>
      </div>
    </div>
    <div class="tail"><ng-content select="action-icon"></ng-content></div>
  </div>
  <div *ngIf="descIcon" class="desc-header">
    <div class="desc-icon">
      <div class="section">
        <div class="icon"><ng-content select="[desc-icon]"></ng-content></div>
        <div>
          <div class="text"><ng-content select="[desc-text]"></ng-content></div>
          <div class="desc"><ng-content select="[desc-desc]"></ng-content></div>
        </div>
      </div>
      <div class="button">
        <ng-content select="[desc-button]"></ng-content>
      </div>
    </div>
  </div>
  <div *ngIf="descIcon" class="desc-header">
    <ng-content select="action-releases"></ng-content>
  </div>
  <!-- Form ( for form group ) -->
  <div class="form-frame-form" [class.active]="formDisable">
    <ng-content select="[form-body]"></ng-content>
  </div>
  <!-- Form for listing -->
  <div class="form-frame-border">
    <ng-content select="[form-border]"></ng-content>
  </div>
  <!-- Form for 2 form -->
  <div class="form-frame-more">
    <ng-content select="[form-more]"></ng-content>
  </div>
</div>
