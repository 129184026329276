import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-reactive-locked-input',
  templateUrl: './reactive-locked-input.component.html',
  styleUrls: ['./reactive-locked-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveLockedInputComponent implements OnInit {
  @Input() text: string;
  @Input() filedLabel: string;
  @Input() fieldId: string;
  @Input() isInline = false;
  constructor() {}

  ngOnInit() {}
}
