/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reactive-color-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-color-picker";
import * as i3 from "./reactive-color-input.component";
var styles_ReactiveColorInputComponent = [i0.styles];
var RenderType_ReactiveColorInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReactiveColorInputComponent, data: {} });
export { RenderType_ReactiveColorInputComponent as RenderType_ReactiveColorInputComponent };
export function View_ReactiveColorInputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[2, "color-picker1", null], [2, "color-picker2", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "control-label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "div", [["class", "color-picker1-input"]], [[4, "background", null]], [[null, "colorPickerChange"], [null, "click"], [null, "focus"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).handleClick() !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("input" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).handleInput($event) !== false);
        ad = (pd_2 && ad);
    } if (("colorPickerChange" === en)) {
        var pd_3 = (_co.onChangeColor($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.ColorPickerDirective, [i1.Injector, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.ViewContainerRef, i1.ElementRef, i2.ColorPickerService], { colorPicker: [0, "colorPicker"], cpOutputFormat: [1, "cpOutputFormat"], cpAlphaChannel: [2, "cpAlphaChannel"], cpPosition: [3, "cpPosition"] }, { colorPickerChange: "colorPickerChange" })], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.colorSelected; var currVal_6 = "hex"; var currVal_7 = "disabled"; var currVal_8 = "right"; _ck(_v, 4, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isInline; var currVal_1 = _co.isInline; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.colorId; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.colorLabel; _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.colorSelected; _ck(_v, 3, 0, currVal_4); }); }
export function View_ReactiveColorInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reactive-color-input", [], null, null, null, View_ReactiveColorInputComponent_0, RenderType_ReactiveColorInputComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReactiveColorInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReactiveColorInputComponentNgFactory = i1.ɵccf("app-reactive-color-input", i3.ReactiveColorInputComponent, View_ReactiveColorInputComponent_Host_0, { colorSelected: "colorSelected", colorLabel: "colorLabel", colorId: "colorId", isInline: "isInline" }, { selectedColor: "selectedColor" }, []);
export { ReactiveColorInputComponentNgFactory as ReactiveColorInputComponentNgFactory };
