import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countSize'
})
export class CountSizePipe implements PipeTransform {
  transform(value: number): string {
    if (value && value !== 0) {
      if (value < 1000) {
        return `${value}`;
      } else if (value < 1000000) {
        return `${(value / 1000)
          .toFixed(1)
          .toString()
          .replace(/\.0/g, '')}K`;
      } else {
        return `${(value / 1000000)
          .toFixed(1)
          .toString()
          .replace(/\.0/g, '')}M`;
      }
    } else {
      return '';
    }
  }
}
