import { GET_MY_MEDIA_METHOD, DELETE_MY_MEDIA_METHOD } from './constants';
import { IUploadResponse } from './IUploadResponse';

export interface MediaCenterPrams {
  type?: string;
  tabID?: string;
  groupID?: string;
  botID?: string;
}

export interface IMediaCenter {
  id?: string;
  size?: number;
  type?: string;
  name?: string;
  width?: number;
  height?: number;
  duration?: string;
  url?: string; // response.url
  file?: string; // response.file
  filename?: string; // response.file
  // local use
  reference?: number;
  mediaStatus?: string;
  loadingProgress?: number;
  localThumbnail?: string;
  localFile?: File;
  fileAsArrayBuffer?: ArrayBuffer;
}

export interface MediaCenterRcvd {
  id?: string;
  size?: number;
  type?: string;
  name?: string;
  width?: number;
  height?: number;
  duration?: string;
  filename?: string;
  p: number;
  upload_type?: string;
  response?: IUploadResponse;
  thumbnail?: {
    width: number;
    height: number;
    id: string;
  };
}

export class GetMyMediaGallery {
  readonly method = GET_MY_MEDIA_METHOD;
  // constructor(
  //   public page_size: number,
  //   public reference: number,
  //   public direction: number
  // ) {}
}

export class DeleteMyMediaGalleryItem {
  readonly method = DELETE_MY_MEDIA_METHOD;
  constructor(public filename: string) {}
}

export type MediaCenterMethods = GetMyMediaGallery | DeleteMyMediaGalleryItem;
