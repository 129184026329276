import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { IChat } from 'src/models/IChat';

@Component({
  selector: 'app-assign-sub-chat',
  templateUrl: './assign-sub-chat.component.html',
  styleUrls: ['./assign-sub-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignSubChatsComponent {
  @Input() assignSubgroups: IChat[];
  @Output() removeSubgroupNotfiy = new EventEmitter<string>();

  removeSubgroupEvent(grpId: string) {
    this.removeSubgroupNotfiy.emit(grpId);
  }
}
