import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SocketGateway } from 'src/app/network/gateway/socket.gateway';
import * as DriverAssignedAction from './driverAssigned.actions';
import * as DriverAssignedMethods from 'src/models/DrverAssigned';
import { AppState } from 'src/models/AppState';
import { DriverAssignedMiddleware } from './driverAssigned.middleware';

@Injectable()
export class DriverAssignedEffects {
  // send update to the server after insert in store
  @Effect({ dispatch: false })
  getDriverAssigned = this.actions$.pipe(
    ofType(DriverAssignedAction.DriverAssignedActionTypes.GET_DRIVER_TRIPS),
    map((action: DriverAssignedAction.GetDriverTrips) => {
      this._socketGateway.sendSocketMessage(
        new DriverAssignedMethods.GetDriverAssigned(
          action.trip_id,
          action.account_id,
          action.start_day,
          action.end_day
        )
      );
    })
  );

  @Effect({ dispatch: false })
  setDriverAssigned = this.actions$.pipe(
    ofType(DriverAssignedAction.DriverAssignedActionTypes.SET_DRIVER_TRIPS),
    map((action: DriverAssignedAction.SetDriverTrips) => {
      const cpToSend = DriverAssignedMiddleware.mapListToServer(
        action.driverAssignedList
      );
      this._socketGateway.sendSocketMessage(
        new DriverAssignedMethods.SetDriverAssigned(
          action.trip_id + '',
          action.account_id + '',
          cpToSend
        )
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _store: Store<AppState>,
    private _socketGateway: SocketGateway
  ) {}
}
