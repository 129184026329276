import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { FileService } from 'src/app/core/file.service';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { LocalComponent } from 'src/models/ChannelAppLocalConfig';
import {
  LOGO_WIDTH,
  LOGO_HEIGHT,
  ERROR_IMAGE_SHOULD_BE_1024X1024,
  MessageTypes,
  MimeTypes,
  ERROR_NOT_PNG_IMAGE,
  ERROR_NOT_JPG_IMAGE,
  FILE_SIZE_EXCEEDED,
  NO_FILE_NAME
} from 'src/models/constants';
import { SystemConfig } from 'src/models/ChannelAppConfig';

@Component({
  selector: 'app-upld-img-with-title',
  templateUrl: './upld-img-with-title.component.html',
  styleUrls: ['./upld-img-with-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpldImgWithTitleComponent implements OnChanges {
  @Input() isLogo: boolean;
  @Input() imageUrl: string;
  @Input() mimeType: string;
  @Input() uploading = false;
  @Input() cropFormat: string;
  @Input() inputId: string;
  @Input() imageTitle: LocalComponent;
  @Input() systemConfig: SystemConfig;
  @Output() closeNotify = new EventEmitter<boolean>();
  @Output() composeMediaNotify = new EventEmitter<ISelectedFiles>();
  @Output() imageCroppedUrlNotify = new EventEmitter<string>();
  @Input() disabled: number;
  @Input() iconBg: any;
  cropperMinWidth: number;
  @ViewChild('imageInputFile', { static: false }) imageInputFile: ElementRef;
  cropperWidth = 0;
  cropperHeight = 0;
  imageChangedEvent: any = '';
  fileArray: ArrayBuffer;
  imageFile: ISelectedFiles;
  showCropper: boolean;
  croppedImage: any = '';
  newUploadedImage = '';
  showCrop: boolean;
  selectedFile: any;

  constructor(
    private _ref: ChangeDetectorRef,
    private _fileService: FileService,
    private _uiDispatchers: UIDispatchers
  ) {}

  ngOnChanges(s: SimpleChanges) {
    const chng = s['imageUrl'];
    if (chng && chng.currentValue && chng.currentValue !== chng.previousValue) {
      this.uploading = false;
    }
  }
  get uploadedImage() {
    if (
      this.imageUrl &&
      this.systemConfig &&
      this.systemConfig.app_info &&
      this.iconBg
    ) {
      return {
        'background-image': `url(${this.imageUrl})`,
        'background-color': this.iconBg
      };
    } else if (
      !this.imageUrl &&
      this.systemConfig &&
      this.systemConfig.app_info
    ) {
      return {
        'background-image': 'url(/assets/img/empty-image.svg)'
      };
    }
    if (this.imageUrl) {
      return {
        'background-image': `url(${this.imageUrl})`,
        'background-color': 'black'
      };
    } else {
      return {
        'background-image': 'url(/assets/img/empty-image.svg)'
      };
    }
  }

  closeEvent() {
    this.closeNotify.emit(false);
    this.resetEvent();
  }
  getFile(): ISelectedFiles {
    if (this.imageInputFile.nativeElement.files[0]) {
      const file = this.imageInputFile.nativeElement.files[0];
      return {
        localFile: file,
        type: this._fileService.getMessageTypeFromMimeType(file.type)
      };
    }
    return null;
  }
  resetEvent() {
    if (this.imageInputFile) {
      this.imageInputFile.nativeElement.value = '';
    }
  }
  getBlob(b64Data: string) {
    const contentType = this.mimeType;
    const sliceSize = 512;

    b64Data = b64Data.replace(/data\:image\/(jpeg|jpg|png)\;base64\,/gi, '');

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const b: any = blob;

    b.lastModifiedDate = new Date();
    b.name = 'uploadedImage';

    return b;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageFile = event.file;
    this.imageFile.localFile = this.getBlob(this.croppedImage);
  }

  imageLoaded() {
    this.showCropper = true;
    // show cropper
  }
  loadImageFailed() {
    // show message
  }
  closePreview() {
    this.croppedImage = '';
    this.resetEvent();
    this.showCrop = false;
  }
  vaildateImage() {
    const event = this.getFile();
    if (event) {
      const isValidInput = this.validateImage(event, this.mimeType);
      if (isValidInput) {
        this.selectedFile = event;
        this._fileService
          .readFileAsArrayBuffer(event.localFile)
          .then(fileAsArrayBuffer => {
            this._fileService
              .readArrayBufferAsBlobUrl(fileAsArrayBuffer, event.type)
              .then(blobUrl => {
                this._fileService.getImageMetadata(blobUrl).then(props => {
                  if (
                    this.isLogo &&
                    (props.width < LOGO_WIDTH || props.height < LOGO_HEIGHT)
                  ) {
                    this.uploading = false;

                    this._uiDispatchers.showErrorPopup(
                      ERROR_IMAGE_SHOULD_BE_1024X1024
                    );
                  } else {
                    if (
                      props.width === LOGO_WIDTH &&
                      props.height === LOGO_HEIGHT &&
                      this.isLogo
                    ) {
                      this.onFilesSelected(this.selectedFile);
                    } else {
                      if (this.isLogo) {
                        this.cropperMinWidth = 1024;
                      } else {
                        this.cropperMinWidth = 0;
                      }
                      this.showCrop = true;
                      this.imageChangedEvent = event;
                      this._ref.detectChanges();
                    }
                  }
                });
              });
          });
      }
    }
    this._ref.detectChanges();
  }
  getshowCrop() {
    return this.showCrop;
  }
  onFilesSelected(file: ISelectedFiles) {
    if (file.localFile) {
      this.uploading = true;

      this.composeMediaNotify.emit(file);
      this.closeEvent();
    }
    this.showCrop = false;
    this.newUploadedImage = this.croppedImage;
    this.imageCroppedUrlNotify.emit(this.croppedImage);
  }
  private validateImage(
    selectedFile: ISelectedFiles,
    imageType?: string
  ): boolean {
    const { name, size, type } = selectedFile.localFile;
    if (!name) {
      this._uiDispatchers.showPopup(NO_FILE_NAME);
      return false;
    } else if (!this._fileService.isFileSizeValid(MessageTypes.PHOTO, size)) {
      // this._uiDispatchers.showPopup(FILE_SIZE_EXCEEDED);
      this._uiDispatchers.showErrorPopup(FILE_SIZE_EXCEEDED);
      return false;
    } else if (this.isLogo && type.indexOf(imageType) === -1) {
      if (imageType === MimeTypes.PNG) {
        this._uiDispatchers.showErrorPopup(ERROR_NOT_PNG_IMAGE);
      } else if (imageType === MimeTypes.JPG) {
        this._uiDispatchers.showErrorPopup(ERROR_NOT_JPG_IMAGE);
      }
      return false;
    }
    return true;
  }
  onDragEvents(event) {
    // event.preventDefault();
    event.stopPropagation();
    // console.log('here i am');
    // return false;
  }
}
