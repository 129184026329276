<ng-container *ngIf="authService.authCollection$ | async as authCollection">
  <div
    class="signup-form"
    *ngIf="authCollection?.signupStatus === signupStatus.ENTER_EMAIL"
  >
    <!-- Check if template -->
    <!-- <div *ngIf="form?.get('templateId')?.errors" class="form-error">
      Oops, something went wrong. Please click
      <app-link-btn [isDefaultLink]="true" [href]="signupLink">
        <ng-container ngProjectAs="link-content"> here </ng-container>
      </app-link-btn>
      if you don't know how to start.
    </div> -->

    <div class="signup-form-title">Sign Up</div>

    <div class="signup-form-new">
      Already have a nandbox account?
      <app-link-btn [href]="'/login'" [openSelf]="true">
        <ng-container ngProjectAs="link-content">Log In</ng-container>
      </app-link-btn>
    </div>

    <form class="signup-form-form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Name"
            type="text"
            formControlName="name"
          />
          <app-icon-validation-errors
            *ngIf="form?.get('name')?.errors && form?.get('name')?.touched"
            matSuffix
            [fieldErrors]="form?.get('name')?.errors"
            [fieldName]="'Name'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          *ngIf="form?.get('name')?.errors && form?.get('name')?.touched"
          class="signup-form-error"
          [fieldName]="'Name'"
          [fieldErrors]="form?.get('name')?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <mat-label>Select your Country</mat-label>
          <mat-select formControlName="countryIso">
            <mat-option
              *ngFor="let country of countryOptions"
              [value]="country.key"
            >
              {{ country.value }}
            </mat-option>
          </mat-select>

          <app-icon-validation-errors
            *ngIf="
              form?.get('countryIso')?.errors &&
              form?.get('countryIso')?.touched
            "
            matSuffix
            [fieldErrors]="form?.get('countryIso')?.errors"
            [fieldName]="'Country'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          *ngIf="
            form?.get('countryIso')?.errors && form?.get('countryIso')?.touched
          "
          class="signup-form-error"
          [fieldName]="'Country'"
          [fieldErrors]="form?.get('countryIso')?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Email"
            type="email"
            formControlName="email"
          />
          <app-icon-validation-errors
            *ngIf="form?.get('email')?.errors && form?.get('email')?.touched"
            matSuffix
            [fieldErrors]="form?.get('email')?.errors"
            [fieldName]="'Email'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          *ngIf="form?.get('email')?.errors && form?.get('email')?.touched"
          class="signup-form-error"
          [fieldName]="'Email'"
          [fieldErrors]="form?.get('email')?.errors"
        >
        </app-validation-errors>
      </div>

      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            placeholder="Password"
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            autocomplete="new-password"
          />
          <app-icon-validation-errors
            *ngIf="
              form?.get('password')?.errors && form?.get('password')?.touched
            "
            matSuffix
            [fieldErrors]="form?.get('password')?.errors"
            [fieldName]="'Password'"
          >
          </app-icon-validation-errors>
        </mat-form-field>

        <app-validation-errors
          *ngIf="
            form?.get('password')?.errors && form?.get('password')?.touched
          "
          class="signup-form-error"
          [fieldName]="'Password'"
          [fieldErrors]="form?.get('password')?.errors"
        >
        </app-validation-errors>
      </div>
      <app-reactive-checkbox-input
        [filedId]="'inputRem'"
        [control]="form?.get('agree')"
        [usingCheckBox]="true"
        [filedError]="
          'You must read and agree to nandbox Terms & Conditions in order to proceed.'
        "
      >
        <div class="form-check-agree">
          I have read and agree to the
          <app-link-btn [openSelf]="false" [href]="privacyLink">
            <ng-container ngProjectAs="link-content">
              Terms and Privacy Policy
            </ng-container> </app-link-btn
          >.
        </div>
      </app-reactive-checkbox-input>

      <div class="form-submit" style="margin-top: 20px">
        <app-border-btn next lg [type]="'submit'">
          Sign Up <i *ngIf="waiting" class="fas fa-spinner fa-spin"></i>
        </app-border-btn>
      </div>
    </form>
  </div>

  <app-verify-account
    *ngIf="authCollection?.signupStatus === signupStatus.EMAIL_TAC_SENT"
    [email]="form?.get('email').value"
    [countryIso]="form?.get('countryIso').value"
    [password]="form?.get('password').value"
    [appName]="form?.get('appName').value"
    [name]="form?.get('name').value"
  >
  </app-verify-account>
</ng-container>

<!-- <div *ngIf="authService.authCollection$ | async as authCollection">
  <app-form-frame
    *ngIf="authCollection?.signupStatus === signupStatus.ENTER_EMAIL"
    class="signup-form"
  >
    <ng-container ngProjectAs="title">Signup</ng-container>

    <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
      <app-reactive-text-input
        [fieldId]="'inputName'"
        [filedLabel]="'Name'"
        [control]="form?.get('name')"
      >
      </app-reactive-text-input>

      <app-selector-input-countries [control]="form?.get('countryIso')">
      </app-selector-input-countries>

      <app-reactive-text-input
        [filedType]="'email'"
        [fieldId]="'inputEmail'"
        [filedLabel]="'Email'"
        [control]="form?.get('email')"
      >
      </app-reactive-text-input>

      <app-reactive-text-input
        [filedType]="'password'"
        [fieldId]="'inputPassword'"
        [filedLabel]="'Password'"
        [control]="form?.get('password')"
      >
      </app-reactive-text-input>

      <app-reactive-checkbox-input
        [control]="form?.get('terms')"
        [filedId]="'inputTrems'"
      >
        I agree to the Terms of Use
      </app-reactive-checkbox-input>

      <app-reactive-checkbox-input
        [control]="form?.get('privacy')"
        [filedId]="'inputPrivacy'"
      >
        I agree to the Privacy Policy
      </app-reactive-checkbox-input>

      <div class="form-group">
        <div class="form-submit">
          <app-link-btn [href]="'https://nandbox.com/terms'">
            <ng-container ngProjectAs="link-content">
              View the Terms and Privacy Policy.
            </ng-container>
          </app-link-btn>
        </div>
      </div>

      <div class="form-group">
        <div class="form-submit">
          <app-border-btn submit [type]="'submit'">
            Next <i class="fas fa-spinner fa-spin" *ngIf="waiting"></i>
          </app-border-btn>
        </div>
      </div>
    </form>
  </app-form-frame>
</div> -->
