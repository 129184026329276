import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectionStrategy,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { HelperService } from '../../core/helper.service';
import { MainChat } from '../../../models/MainChat';
import {
  MAX_CAPTION_SIZE,
  CAPTION_SIZE_EXCEEDED,
  A_KEY_CODE,
  V_KEY_CODE,
  C_KEY_CODE,
  Z_KEY_CODE,
  X_KEY_CODE,
  CHAT_THEME_COLOR
} from '../../../models/constants';
import { UiService } from '../../core/ui.service';
import { UrlPreviewMeta } from 'src/models/MetaData';
import { MessageTypes } from 'src/models/constants';
import { IMessage } from 'src/models/IMessage';

@Component({
  selector: 'app-preview-media',
  templateUrl: './preview-media.component.html',
  styleUrls: ['./preview-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewMediaComponent implements OnInit, OnDestroy {
  @Input() selectedChat: MainChat;
  @Input() selectedMessage: IMessage;
  @Input() selectedUserId: string;
  @Input() files: File;
  @Input() messageType: string;
  @Input() mediaMetadata: UrlPreviewMeta;
  @Input() mediaError: boolean;
  @Input() isErrorMessageShown: boolean;
  @Input() themeColor = CHAT_THEME_COLOR;
  @Input() hideHeader = false;
  @Input() isBot = false;

  @Output() sendMediaNotfiy: EventEmitter<boolean> = new EventEmitter(false);

  @ViewChild('videoTag', { static: false }) videoTag: ElementRef;
  @ViewChild('audioTag', { static: false }) audioTag: ElementRef;
  @ViewChild('captionBox', { static: false }) captionBox: ElementRef;

  index = 0;
  active = false;
  selection = '';
  parentNode = '';
  mTypes: typeof MessageTypes = MessageTypes;

  isEmojiKeyboardOpen = false;

  constructor(
    private _ref: ElementRef,
    private _sanitizer: DomSanitizer,
    private _uiService: UiService,
    private _helperService: HelperService
  ) {}

  /* Listener Section */
  @HostListener('click', ['$event'])
  onComponentClick(event: MouseEvent) {
    this._helperService.clickedComponent = this._ref.nativeElement;
  }

  @HostListener('document:keydown', ['$event'])
  onkeypress(event: KeyboardEvent) {
    if (
      this.captionBox &&
      this._helperService.clickedComponent === this._ref.nativeElement
    ) {
      if (this.isErrorMessageShown) {
        // If error message is shown then do nothing
        event.preventDefault();
      } else if (event.keyCode === 13 && !event.shiftKey) {
        this._helperService.cancelEvent(event);
        this.sendMediaPreview();
      } else if (event.keyCode === 8 || event.keyCode === 46) {
        const htmlContent = this.captionBox.nativeElement;
        if (
          (htmlContent.textContent.length === 0 &&
            htmlContent.innerHTML.length === 0) ||
          htmlContent.innerHTML === '<br>'
        ) {
          this.captionBox.nativeElement.textContent = '';
        }
      } else if (event.keyCode === 27) {
        this.cancelMediaPreview();
      } else {
        if (
          !(
            event.ctrlKey &&
            (event.keyCode === A_KEY_CODE ||
              event.keyCode === C_KEY_CODE ||
              event.keyCode === V_KEY_CODE ||
              event.keyCode === Z_KEY_CODE ||
              event.keyCode === X_KEY_CODE)
          )
        ) {
          const caption = this._helperService.stripEmojis(
            this.captionBox.nativeElement.innerHTML
          );

          if (caption && caption.length >= MAX_CAPTION_SIZE) {
            event.preventDefault();
          }
        }
      }
    }
  }
  /******************************************************** */
  /** lifecycle hooks */
  ngOnInit() {
    this._helperService.clickedComponent = this._ref.nativeElement;
    this.parentNode = this._ref.nativeElement.parentNode;
  }

  ngOnDestroy() {
    if (this.videoTag && !this.videoTag.nativeElement.paused) {
      this.videoTag.nativeElement.pause();
    }
    if (this.audioTag && !this.audioTag.nativeElement.paused) {
      this.audioTag.nativeElement.pause();
    }
    this._helperService.clickedComponent = this.parentNode;
  }

  /******************************************************** */
  /** get valus */
  get media() {
    if (this.mediaMetadata && this.mediaMetadata.mediaUrl) {
      return this._sanitizer.bypassSecurityTrustUrl(
        this.mediaMetadata.mediaUrl
      );
    }
    return '';
  }

  get theme() {
    return {
      'border-top-color': this.themeColor,
      'border-right-color': this.themeColor
    };
  }
  /******************************************************** */

  /** Send Logic */
  getMediaToSend() {
    this.mediaMetadata.caption = this.getCaptionToSend();
    // console.log(this.selectedChat);
    return {
      selectedChat: this.selectedChat,
      selectedMessage: this.selectedMessage,
      selectedUserId: this.selectedUserId,
      files: this.files,
      messageType: this.messageType,
      mediaMetadata: this.mediaMetadata
    };
  }
  sendMediaPreview() {
    this.sendMediaNotfiy.emit(true);
  }

  getCaptionToSend(): string {
    this.isEmojiKeyboardOpen = false;
    const htmlText = this.captionBox.nativeElement.innerHTML;
    if (htmlText) {
      const textToSend = this._helperService.stripEmojis(htmlText);
      if (textToSend && textToSend.length > 0) {
        return textToSend;
      } else {
        return null;
      }
    }
  }

  setFocus() {
    if (this.captionBox) {
      this.captionBox.nativeElement.focus();
    }
  }

  emoijNotify($event: string) {
    const currentCaption = this._helperService.stripEmojis(
      this.captionBox.nativeElement.innerHTML
    );
    const emojiTobeInserted = this._helperService.stripEmojis($event);
    if (
      !currentCaption ||
      (currentCaption &&
        currentCaption.length + emojiTobeInserted.length <= MAX_CAPTION_SIZE)
    ) {
      this.setFocus();
      // this._helperService.restoreSelection(this.index);
      this._helperService.insertEmojiAtCursor($event);
    }
  }

  onRichPaste(e) {
    this.updateSelection();
    const cData = (e.originalEvent || e).clipboardData;
    const items = (cData && cData.items) || [];
    let text;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        e.preventDefault();
        return true;
      }
    }

    try {
      text = cData.getData('text/plain');
    } catch (e) {
      return true;
    }
    if (text.length) {
      const captionText = this._helperService.stripEmojis(
        this.captionBox.nativeElement.innerHTML
      );

      if (
        (captionText ? captionText.length : 0) +
          text.length -
          this.selection.length <=
        MAX_CAPTION_SIZE
      ) {
        const textAfter = this._helperService.convertUnifiedAndColonsToEmojiSpan(
          this._helperService.getMultiLine(text)
        );
        document.execCommand('insertHTML', false, textAfter);
        return this._helperService.cancelEvent(e);
      } else {
        this._uiService.showPopupMessage(CAPTION_SIZE_EXCEEDED);
        this._helperService.cancelEvent(e);
        return true;
      }
    }
    return true;
  }
  lastfocus() {
    this.index = this._helperService.saveSelection();
    this.updateSelection();
  }

  updateSelection() {
    this.selection = this._helperService.getSelection();
    this.selection = this.selection ? this.selection : '';
  }

  /* Tooggle display  */
  cancelMediaPreview() {
    this._uiService.hidePreview();
  }

  toggleEmojiKeyboard(value: boolean): void {
    this.isEmojiKeyboardOpen = value;
    if (this.captionBox.nativeElement.innerHTML) {
      const range = new Range();

      range.setStartAfter(this.captionBox.nativeElement.lastChild);
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(range);
    }
  }
}
