/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./circle-btn/circle-btn.component.ngfactory";
import * as i2 from "./circle-btn/circle-btn.component";
import * as i3 from "../abstract-icons/delete-icon.component.ngfactory";
import * as i4 from "../abstract-icons/delete-icon.component";
import * as i5 from "./delete-btn.component";
var styles_DeleteBtnComponent = [];
var RenderType_DeleteBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteBtnComponent, data: {} });
export { RenderType_DeleteBtnComponent as RenderType_DeleteBtnComponent };
export function View_DeleteBtnComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-circle-btn", [["delete", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.btnEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CircleBtnComponent_0, i1.RenderType_CircleBtnComponent)), i0.ɵdid(1, 49152, null, 0, i2.CircleBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-delete-icon", [], null, null, null, i3.View_DeleteIconComponent_0, i3.RenderType_DeleteIconComponent)), i0.ɵdid(3, 49152, null, 0, i4.DeleteIconComponent, [], null, null)], null, null); }
export function View_DeleteBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-btn", [], null, null, null, View_DeleteBtnComponent_0, RenderType_DeleteBtnComponent)), i0.ɵdid(1, 49152, null, 0, i5.DeleteBtnComponent, [], null, null)], null, null); }
var DeleteBtnComponentNgFactory = i0.ɵccf("app-delete-btn", i5.DeleteBtnComponent, View_DeleteBtnComponent_Host_0, { disabled: "disabled" }, { btnClicked: "btnClicked" }, []);
export { DeleteBtnComponentNgFactory as DeleteBtnComponentNgFactory };
