import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';
import { CellsModule } from '../cells/cells.module';
import { SharedModule } from '../shared/shared.module';
import { MediaCenterModule } from '../media-center/media-center.module';

import { CircleInputComponent } from './circle-input/circle-input.component';
import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { IconValidationErrorsComponent } from './icon-validation-errors/icon-validation-errors.component';
import { ReactiveMediaInputComponent } from './reactive-media-input/reactive-media-input.component';
import { ReactiveTextInputComponent } from './reactive-text-input/reactive-text-input.component';
import { ReactiveDateInputComponent } from './reactive-date-input/reactive-date-input.component';
import { ReactiveTextareaInputComponent } from './reactive-textarea-input/reactive-textarea-input.component';
import { ReactivePasswordInputComponent } from './reactive-password-input/reactive-password-input.component';
import { ReactiveSelectInputComponent } from './reactive-select-input/reactive-select-input.component';
import { ReactiveRadioInputComponent } from './reactive-radio-input/reactive-radio-input.component';
import { ReactiveCheckboxInputComponent } from './reactive-checkbox-input/reactive-checkbox-input.component';
import { ReactiveTableComponent } from './reactive-table/reactive-table.component';
import { ReactiveTimestampInputComponent } from './reactive-timestamp-input/reactive-timestamp-input.component';
import { SelectorInputIconComponent } from './selector-input-icon/selector-input-icon.component';
import { SelectorInputLocationComponent } from './selector-input-location/selector-input-location.component';
import { SelectorInputDateComponent } from './selector-input-date/selector-input-date.component';
import { SelectorInputTimezoneComponent } from './selector-input-timezone/selector-input-timezone.component';
import { SelectorInputCountriesComponent } from './selector-input-countries/selector-input-countries.component';
import { SelectorInputAreaComponent } from './selector-input-area/selector-input-area.component';
import { SelectorInputCategoryComponent } from './selector-input-category/selector-input-category.component';
import { SelectorInputClassificationComponent } from './selector-input-classification/selector-input-classification.component';
import { SelectorInputCurrencyComponent } from './selector-input-currency/selector-input-currency.component';
import { SelectorInputPeriodComponent } from './selector-input-period/selector-input-period.component';
import { ReactiveDurationInputComponent } from './reactive-duration-input/reactive-duration-input.component';
import { ReactiveLockedInputComponent } from './reactive-locked-input/reactive-locked-input.component';
import { ReactiveColorInputComponent } from './reactive-color-input/reactive-color-input.component';
import { NgxModule } from '../ngx.module';
import { ReactiveInputKeyWordComponent } from './reactive-input-key-word/reactive-input-key-word.component';
import { ReactiveSelctedOwnerComponent } from './reactive-selcted-owner/reactive-selcted-owner.component';
import { AbstractTooltip } from '../abstract-tooltip/abstract-tooltip.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    BsDatepickerModule,
    BsDropdownModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    SharedModule,
    CellsModule,
    MediaCenterModule,
    ColorPickerModule,
    NgxModule,
    FormsModule,
    AbstractTooltip
  ],
  declarations: [
    CircleInputComponent,
    ValidationErrorsComponent,
    IconValidationErrorsComponent,
    ReactiveMediaInputComponent,
    ReactiveTextInputComponent,
    ReactiveDateInputComponent,
    ReactiveDurationInputComponent,
    ReactiveTextareaInputComponent,
    ReactivePasswordInputComponent,
    ReactiveSelectInputComponent,
    ReactiveRadioInputComponent,
    ReactiveCheckboxInputComponent,
    ReactiveLockedInputComponent,
    ReactiveColorInputComponent,
    ReactiveTableComponent,
    ReactiveTimestampInputComponent,
    SelectorInputIconComponent,
    SelectorInputLocationComponent,
    SelectorInputDateComponent,
    SelectorInputTimezoneComponent,
    SelectorInputCountriesComponent,
    SelectorInputAreaComponent,
    SelectorInputCategoryComponent,
    SelectorInputClassificationComponent,
    SelectorInputCurrencyComponent,
    SelectorInputPeriodComponent,
    ReactiveInputKeyWordComponent,
    ReactiveSelctedOwnerComponent
  ],
  exports: [
    CircleInputComponent,
    ValidationErrorsComponent,
    IconValidationErrorsComponent,
    ReactiveMediaInputComponent,
    ReactiveTextInputComponent,
    ReactiveDateInputComponent,
    ReactiveDurationInputComponent,
    ReactiveTextareaInputComponent,
    ReactivePasswordInputComponent,
    ReactiveSelectInputComponent,
    ReactiveRadioInputComponent,
    ReactiveCheckboxInputComponent,
    ReactiveLockedInputComponent,
    ReactiveColorInputComponent,
    ReactiveTableComponent,
    ReactiveTimestampInputComponent,
    SelectorInputIconComponent,
    SelectorInputLocationComponent,
    SelectorInputDateComponent,
    SelectorInputTimezoneComponent,
    SelectorInputCountriesComponent,
    SelectorInputAreaComponent,
    SelectorInputCategoryComponent,
    SelectorInputClassificationComponent,
    SelectorInputCurrencyComponent,
    SelectorInputPeriodComponent,
    ReactiveInputKeyWordComponent,
    ReactiveSelctedOwnerComponent
  ]
})
export class AbstractControlsModule {}
