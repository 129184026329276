import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LagSelectorComponent implements OnInit, OnChanges {
  langs: any[] = [
    {
      key: 'en',
      value: 'English',
      img: './assets/img/uk.png'
    },
    {
      key: 'ar',
      value: 'العربية',
      img: './assets/img/arabic.png'
    }
  ];
  @Input() selectedLang: string;
  showLangMenu = false;
  constructor(
    public translate: TranslateService,
    public ref: ChangeDetectorRef
  ) {}
  ngOnChanges() {
    this.selectedLang = localStorage.getItem('locale');
    this.ref.detectChanges();
  }

  ngOnInit() {
    this.selectedLang = localStorage.getItem('locale');
  }

  changeBrowserLang(value) {
    localStorage.setItem('locale', value);
    this.selectedLang = value;
    this.translate.use(value);
    this.showMenu();

    this.ref.detectChanges();
  }
  showMenu() {
    if (this.showLangMenu) {
      this.showLangMenu = false;
    } else {
      this.showLangMenu = true;
    }
  }
}
