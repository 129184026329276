import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistrationComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.loginUseEmail();
    // Get TemplateID from url
    const templateId = this._route.snapshot.queryParams['id'];
    if (templateId) {
      this.authService.selectTemplate(templateId);
    }
  }
}
