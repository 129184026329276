<div class="uploader">
  <div class="uploader-image">
    <div class="uploader-image-input">
      <label
        [for]="inputId || 'image-input'"
        class="uploader-image-logo"
        [ngStyle]="uploadedImage"
      >
      </label>
      <input
        #imageInputFile
        [id]="inputId || 'image-input'"
        type="file"
        accept="image/*"
        (change)="vaildateImage()"
        [disabled]="disabled"
      />
      <label [for]="inputId || 'image-input'" class="upload-image">
        <div class="image-label">
          <svg class="svg-icon icon-icon">
            <use
              xlink:href="./assets/svg/app-sections.svg#icon-photograph"
            ></use>
          </svg>
        </div>
      </label>
    </div>
    <div *ngIf="uploading" class="uploader-image-spinner">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <!-- <div class="haver-div" (click)="imageInputFile.click()">
      <div class="hashed-div"><img src="./assets/img/plus.svg" /></div>
    </div> -->
  </div>
  <div *ngIf="imageTitle" class="uploader-title">{{ imageTitle }}</div>
</div>
<app-modal-container *ngIf="showCrop" (cancelNotfiy)="closePreview()">
  <div class="image-crop">
    <div class="close" (click)="closePreview()">
      <div class="form-title">Crop Image</div>
      <img src="./assets/img/close.svg" alt="close" />
    </div>
    <div class="crop">
      <image-cropper
        *ngIf="isLogo"
        (mousedown)="onDragEvents($event)"
        [imageChangedEvent]="imageChangedEvent"
        [imageFileChanged]="selectedFile?.localFile"
        [maintainAspectRatio]="true"
        [resizeToWidth]="cropperMinWidth"
        [aspectRatio]="1 / 1"
        [onlyScaleDown]="false"
        [roundCropper]="false"
        cropperMaxWidth="1024"
        cropperMaxHeight="1024"
        outputType="both"
        [format]="cropFormat"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <image-cropper
        *ngIf="!isLogo"
        (mousedown)="onDragEvents($event)"
        [imageChangedEvent]="imageChangedEvent"
        [imageFileChanged]="selectedFile?.localFile"
        [maintainAspectRatio]="true"
        [resizeToWidth]="cropperMinWidth"
        [aspectRatio]="1 / 1"
        [onlyScaleDown]="false"
        [roundCropper]="false"
        cropperMaxWidth="0"
        cropperMaxHeight="0"
        outputType="both"
        [format]="cropFormat"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <div class="preview">
      <img [src]="croppedImage" />
      <div class="upload-button">
        <label class="icon" (click)="onFilesSelected(imageFile)">
          <img src="./assets/img/image-upload-card.svg" alt="gif" /> Upload
          Image
        </label>
      </div>
    </div>
  </div>
</app-modal-container>
