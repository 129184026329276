import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CalendarsService } from '../../calendars/calendars.service';
import { CalendarTimetable } from 'src/models/Calendar';
import { CALENDERS } from 'src/models/AppSections';
import { PrivilegesName } from 'src/models/privileges';

@Component({
  selector: 'app-calendars-pool',
  templateUrl: './calendars-pool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarsPoolComponent {
  @Output() cancelNotfiy = new EventEmitter<boolean>();
  @Output() selectCalendarNotfiy = new EventEmitter<CalendarTimetable>();
  currentSelectMessage: CalendarTimetable = null;

  private _searchTerm = new BehaviorSubject<string>('');
  public searchTerm$ = this._searchTerm.asObservable();
  calendarFet = CALENDERS;
  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(public calendarService: CalendarsService) {
    this.calendarService.resetPaging();
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  setSelectMessage(message: CalendarTimetable) {
    this.currentSelectMessage = { ...message };
  }
  resetSelectMessage() {
    this.currentSelectMessage = null;
  }
  insertEvent() {
    this.selectCalendarNotfiy.emit(this.currentSelectMessage);
  }

  onSearchFormSubmit(term: string): void {
    this._searchTerm.next(term);
  }

  TrackByFunction(index, item: CalendarTimetable) {
    return item.id;
  }
}
