import { Action } from '@ngrx/store/public_api';
import { AppPublishInfo } from 'src/models/AppPublishInfo';

export const enum AppPublishingActionTypes {
  REQUEST_APP_STORE_INFO = 'REQUEST_APP_STORE_INFO',
  SET_APP_STORE_INFO = 'SET_APP_STORE_INFO',
  RECEIVE_APP_STORE_INFO = 'RECEIVE_APP_STORE_INFO',
  REQUEST_APP_TESTERS = 'REQUEST_APP_TESTERS',
  RECEIVE_PUBLISH_INFO_ERROR = 'RECEIVE_PUBLISH_INFO_ERROR',
  RECEIVE_ERROR_CODE = 'RECEIVE_ERROR_CODE',
  RESET = 'RESET'
}

export class SetAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.SET_APP_STORE_INFO;
  constructor(public appPublishInfo: AppPublishInfo) {}
}

export class ReceiveAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_APP_STORE_INFO;
  constructor(public appPublishInfo: AppPublishInfo) {}
}

export class RequestAppTesters implements Action {
  readonly type = AppPublishingActionTypes.REQUEST_APP_TESTERS;
  constructor() {}
}

export class ReceivePublishInfoError implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_PUBLISH_INFO_ERROR;
  constructor(public errorNumber: number) {}
}

export class ReceiveErrorCode implements Action {
  readonly type = AppPublishingActionTypes.RECEIVE_ERROR_CODE;
  constructor(public errorNumber: number) {}
}

export class RequestAppStoreInfo implements Action {
  readonly type = AppPublishingActionTypes.REQUEST_APP_STORE_INFO;
  constructor(public appID: string) {}
}

export class Reset implements Action {
  readonly type = AppPublishingActionTypes.RESET;
}

export type Actions =
  | SetAppStoreInfo
  | ReceiveAppStoreInfo
  | RequestAppTesters
  | ReceivePublishInfoError
  | ReceiveErrorCode
  | RequestAppStoreInfo
  | Reset;
