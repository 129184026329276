import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ComponentRef,
  OnDestroy,
  SimpleChanges,
  Input
} from '@angular/core';
import { Subject } from 'rxjs';

import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionModalComponent implements OnInit, OnDestroy {
  private _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public componentRef: ComponentRef<any>;

  constructor(public subscriptionService: SubscriptionService) {}

  ngOnInit() {
    this.subscriptionService.getPaymentToken();
  }
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeEvent() {
    this.resetPlan();
    this._onClose.next();
  }
  resetPlan() {
    this.subscriptionService.resetPaymentPlan();
  }
}
