import { map } from 'rxjs/operators';
import { MapMarkerDispatchers } from 'src/app/store/mapMarkers/mapMarker.dispatchers';
import { RootStoreSelectors } from 'src/app/store/root-store.selectors';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
import { UISections } from 'src/models/constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../store/root-store.selectors";
import * as i2 from "../../../store/ui/ui.dispatchers";
import * as i3 from "../../../store/mapMarkers/mapMarker.dispatchers";
export class LocationsService {
    constructor(_rootStore, _uiDispatchers, _mapMarkerDispatchers) {
        this._rootStore = _rootStore;
        this._uiDispatchers = _uiDispatchers;
        this._mapMarkerDispatchers = _mapMarkerDispatchers;
    }
    selectMapMarker(mapMarker) {
        this._uiDispatchers.mapMarkerSelected(mapMarker);
    }
    setMapMarkerEdit(mapMarker) {
        this._uiDispatchers.mapMarkerSelected(mapMarker);
    }
    getMapMarkerWithId(id) {
        return this._rootStore.getMapMarkerWId$(id);
    }
    setMapMarker(id, mapMarker, backToLocation = true) {
        if (id) {
            this._mapMarkerDispatchers.updateMapMarker(id, mapMarker);
        }
        else {
            this._mapMarkerDispatchers.createMapMarker(mapMarker);
        }
        if (backToLocation) {
            this.backToLocations();
        }
    }
    requestMapMarkerWithId(id) {
        return this._mapMarkerDispatchers.getMapMarker(id);
    }
    deleteMapMarker(id) {
        this._mapMarkerDispatchers.deleteMapMarker(id);
    }
    backToLocations() {
        this._uiDispatchers.setSection(UISections.LOCATIONS);
    }
    getNextPage() {
        this._mapMarkerDispatchers.getNextMapMarkerPage();
    }
    getPrevPage() {
        this._mapMarkerDispatchers.getPrevMapMarkerPage();
    }
    resetPaging(selected_chat = null, type) {
        this._mapMarkerDispatchers.setMapMarkerSelectedChat(selected_chat, type);
    }
    /** Selectors */
    get mapMarkers$() {
        return this._rootStore.mapMarkers$;
    }
    getMapActionTitle(action) {
        if (!action)
            return null;
        /**
         *Action Type:
         *  0 → Group
         *  1 → mStore
         *  2 → Booking
         *  3 → Event
         *  4 → Channel
         *  5 → Vapp
         */
        switch (action.type) {
            case '0':
            case '4':
            case '5':
                return this._rootStore
                    .getGroupDetailsByID$(action.id)
                    .pipe(map(channel => (channel && channel.title ? channel.title : '')));
            case '1':
                return this._rootStore
                    .getMStoreByID$(action.id)
                    .pipe(map(store => (store && store.name ? store.name : '')));
            case '2':
            case '3':
            default:
                return null;
        }
    }
    getMapMarkerDetailsById$(id) {
        return this._rootStore.getMapMarkerById$(id);
    }
}
LocationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationsService_Factory() { return new LocationsService(i0.ɵɵinject(i1.RootStoreSelectors), i0.ɵɵinject(i2.UIDispatchers), i0.ɵɵinject(i3.MapMarkerDispatchers)); }, token: LocationsService, providedIn: "root" });
