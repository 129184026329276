/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./media-center-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./media-center-tabs.component";
var styles_MediaCenterTabsComponent = [i0.styles];
var RenderType_MediaCenterTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MediaCenterTabsComponent, data: {} });
export { RenderType_MediaCenterTabsComponent as RenderType_MediaCenterTabsComponent };
function View_MediaCenterTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tab"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterTypeEvent(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["ALL Media"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedMediaTab; _ck(_v, 0, 0, currVal_0); }); }
function View_MediaCenterTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tab"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterTypeEvent(_co.messageTypes.VIDEO) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Videos"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedMediaTab === _co.messageTypes.VIDEO); _ck(_v, 0, 0, currVal_0); }); }
function View_MediaCenterTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "tab"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterTypeEvent(_co.messageTypes.AUDIO) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Audios"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedMediaTab === _co.messageTypes.AUDIO); _ck(_v, 0, 0, currVal_0); }); }
export function View_MediaCenterTabsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "tabs-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterTabsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "tab"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mediaCenterTypeEvent(_co.messageTypes.PHOTO) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Images"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterTabsComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MediaCenterTabsComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPhotoMedia; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.isPhotoMedia; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.isPhotoMedia; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedMediaTab === _co.messageTypes.PHOTO); _ck(_v, 3, 0, currVal_1); }); }
export function View_MediaCenterTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-media-center-tabs", [], null, null, null, View_MediaCenterTabsComponent_0, RenderType_MediaCenterTabsComponent)), i1.ɵdid(1, 49152, null, 0, i3.MediaCenterTabsComponent, [], null, null)], null, null); }
var MediaCenterTabsComponentNgFactory = i1.ɵccf("app-media-center-tabs", i3.MediaCenterTabsComponent, View_MediaCenterTabsComponent_Host_0, { selectedMediaTab: "selectedMediaTab", isPhotoMedia: "isPhotoMedia" }, { mediaCenterTypeNotify: "mediaCenterTypeNotify" }, []);
export { MediaCenterTabsComponentNgFactory as MediaCenterTabsComponentNgFactory };
