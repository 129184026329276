<div class="tag-form">
  <!-- <ng-container ngProjectAs="title">Edit Tag</ng-container> -->
  <div class="tag-form-header">Edit Tag</div>
  <form form-body [formGroup]="form" (ngSubmit)="onSubmit()">
    <div style="margin-bottom: 15px;">
      <app-reactive-text-input
        [filedType]="'text'"
        [isInline]="true"
        [filedLabel]="'Tag Name'"
        [fieldId]="'inputTagName'"
        [control]="form?.get('name')"
      >
      </app-reactive-text-input>
    </div>

    <div style="margin-bottom: 15px;">
      <app-reactive-textarea-input
        [isInline]="true"
        [fieldId]="'inputTagDescription'"
        [filedLabel]="'Tag Description'"
        [control]="form?.get('description')"
      >
      </app-reactive-textarea-input>
    </div>
    <div class="line"></div>
    <div style="padding: 15px 0;">
      <!-- <app-reactive-checkbox-input
        [isInline]="true"
        [filedId]="'is-private'"
        [filedLabel]="'Is Private'"
        [control]="form?.get('isPrivate')"
      >
      </app-reactive-checkbox-input> -->
      <app-reactive-radio-input
        [isInline]="true"
        [filedLabel]="'Status'"
        [control]="form?.get('isPrivate')"
        [radioOptions]="radioOptions"
      ></app-reactive-radio-input>
    </div>
    <div class="line" *ngIf="localState?.genratedQrcode"></div>
    <ng-container *ngIf="localState?.genratedQrcode">
      <div class="tag-qr-code">
        <label>QR Code</label>
        <app-qr-scanner
          no-shadow
          [qrCodeText]="localState?.genratedQrcode"
          [styleQROnEdit]="true"
          [title]="'Tag'"
        >
        </app-qr-scanner>
      </div>
    </ng-container>

    <div class="form-group tag-form-action">
      <div class="form-submit">
        <app-border-btn next [type]="'submit'">
          Save
        </app-border-btn>
        <app-border-btn back (btnClicked)="cancelEvent()">
          Cancel
        </app-border-btn>
      </div>
    </div>
  </form>
</div>
