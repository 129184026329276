<div class="uploader">
  <div class="uploader-input">
    <!-- <div class="uploader-input-label"> -->
    <div class="upload-file">
      <label for="file-upload" class="create-btn">
        <svg class="svg-icon icon-icon">
          <use xlink:href="/assets/svg/app-sections.svg#icon-plus-circle"></use>
        </svg>
        <div>Add Media</div>
      </label>
      <input
        #fileUpload
        id="file-upload"
        type="file"
        (change)="onFileSelectedEvent()"
        [accept]="mimeTypes"
      />
    </div>
    <!-- </div> -->
    <div class="uploader-input-text">
      <div>
        Upload any media, We'll automatically scale and format your media to fit
        your layout and design selections.
      </div>
    </div>
  </div>
</div>
