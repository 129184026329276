import {
  GET_APP_CHANNEL_SETTINGS_METHOD,
  SET_APP_CHANNEL_SETTINGS
} from './constants';

export interface AppChannelSettingsUI {
  whiteList_enabled?: boolean;
}

export class SetAppChannelSettings {
  readonly method = SET_APP_CHANNEL_SETTINGS;
  constructor(public whiteList_enabled: boolean) {}
}

export class GetAppChannelSettings {
  readonly method = GET_APP_CHANNEL_SETTINGS_METHOD;
  constructor() {}
}
