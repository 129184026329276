/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-image-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../directives/cancel-drag/cancel-drag.directive";
import * as i4 from "../../pipes/twemoji/twemoji.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../pipes/nickname/nick-name.pipe";
import * as i7 from "./profile-image-block.component";
import * as i8 from "../../../store/root-store.selectors";
var styles_ProfileImageBlockComponent = [i0.styles];
var RenderType_ProfileImageBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileImageBlockComponent, data: {} });
export { RenderType_ProfileImageBlockComponent as RenderType_ProfileImageBlockComponent };
function View_ProfileImageBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "profile-img-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "img", [["appCancelDrag", ""], ["class", "circle-color"]], [[8, "src", 4], [8, "alt", 0], [2, "square", null]], [[null, "dragstart"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_3 && ad);
    } if (("drop" === en)) {
        var pd_4 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(4, 16384, null, 0, i3.CancelDragDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "profile-img-block-svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-block"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.cssStyles; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.thumbnail; var currVal_1 = ((_co.profile == null) ? null : _co.profile.name); var currVal_2 = _co.isSquare; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ProfileImageBlockComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "profile-img-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["appCancelDrag", ""], ["class", "circle-color"]], [[2, "square", null], [8, "innerHTML", 1]], [[null, "dragstart"], [null, "dragenter"], [null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; if (("dragstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_2 && ad);
    } if (("dragleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_3 && ad);
    } if (("drop" === en)) {
        var pd_4 = (i1.ɵnov(_v, 4).onDragEvents($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(4, 16384, null, 0, i3.CancelDragDirective, [], null, null), i1.ɵppd(5, 1), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "profile-img-block-svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [["class", "svg-icon icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:use", [[":xlink:href", "./assets/svg/app-sections.svg#icon-block"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.cssStyles; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSquare; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), ((_co.profile == null) ? null : _co.profile.name))))); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ProfileImageBlockComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.TwemojiPipe, [i5.DomSanitizer]), i1.ɵpid(0, i6.NickNamePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageBlockComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileImageBlockComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.image; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.profile.image; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ProfileImageBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-image-block", [], null, null, null, View_ProfileImageBlockComponent_0, RenderType_ProfileImageBlockComponent)), i1.ɵdid(1, 704512, null, 0, i7.ProfileImageBlockComponent, [i5.DomSanitizer, i8.RootStoreSelectors, i1.ChangeDetectorRef], null, null)], null, null); }
var ProfileImageBlockComponentNgFactory = i1.ɵccf("app-profile-image-block", i7.ProfileImageBlockComponent, View_ProfileImageBlockComponent_Host_0, { id: "id", name: "name", size: "size", isSquare: "isSquare", chatType: "chatType" }, {}, []);
export { ProfileImageBlockComponentNgFactory as ProfileImageBlockComponentNgFactory };
