import { Action } from '@ngrx/store';
import { PaymentProvider, ProviderConfig } from 'src/models/PaymentGateways';
import { PaymentProviderIDs } from 'src/models/constants';

export const enum PaymentProvidersActionTypes {
  RECEIVE_PAYMENT_PROVIDERS = 'RECEIVE_PAYMENT_PROVIDERS',
  RECEIVE_PAYMENT_PROVIDER = 'RECEIVE_PAYMENT_PROVIDER',
  SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER',
  GET_PAYMENT_PROVIDERS = 'GET_PAYMENT_PROVIDERS',
  RESET = 'RESET'
}

export class ReceivePaymentProviders implements Action {
  readonly type = PaymentProvidersActionTypes.RECEIVE_PAYMENT_PROVIDERS;
  constructor(public paymentProviders: PaymentProvider[]) {}
}

export class ReceivePaymentProvider implements Action {
  readonly type = PaymentProvidersActionTypes.RECEIVE_PAYMENT_PROVIDER;
  constructor(
    public providerId: PaymentProviderIDs,
    public config: ProviderConfig
  ) {}
}

export class SetPaymentProvider implements Action {
  readonly type = PaymentProvidersActionTypes.SET_PAYMENT_PROVIDER;
  constructor(
    public providerId: PaymentProviderIDs,
    public config: ProviderConfig,
    public active: number
  ) {}
}

export class GetPaymentProviders implements Action {
  readonly type = PaymentProvidersActionTypes.GET_PAYMENT_PROVIDERS;
  constructor() {}
}

export class Reset implements Action {
  readonly type = PaymentProvidersActionTypes.RESET;
}

export type Actions =
  | ReceivePaymentProviders
  | ReceivePaymentProvider
  | SetPaymentProvider
  | GetPaymentProviders
  | Reset;
