/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/profile-cell/profile-cell.component.ngfactory";
import * as i3 from "../../../shared/components/profile-cell/profile-cell.component";
import * as i4 from "../../+layout/layout.service";
import * as i5 from "@angular/common";
import * as i6 from "./auth-list.component";
import * as i7 from "../../+dashboard/dashboard.service";
import * as i8 from "../../../core/auth.service";
var styles_AuthListComponent = [i0.styles];
var RenderType_AuthListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthListComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(200%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-200%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_AuthListComponent as RenderType_AuthListComponent };
function View_AuthListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_AuthListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "wait-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-sync-alt fa-spin"]], null, null, null, null, null))], null, null); }
function View_AuthListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-cell", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectChatEvent(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProfileCellComponent_0, i2.RenderType_ProfileCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfileCellComponent, [i4.LayoutService], { chat: [0, "chat"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AuthListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Select Channel"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "channels-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthListComponent_5)), i1.ɵdid(5, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.dashboardService.allChannels$)); var currVal_1 = _co.TrackByFunction; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_AuthListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You DoNot Have Business Channels "]))], null, null); }
function View_AuthListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthListComponent_4)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthListComponent_6)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.dashboardService.allChannels$))) == null) ? null : tmp_0_0.length) > 0); _ck(_v, 1, 0, currVal_0); var tmp_1_0 = null; var currVal_1 = ((((tmp_1_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.dashboardService.allChannels$))) == null) ? null : tmp_1_0.length) === 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AuthListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "card"]], [[24, "@slideToLeft", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "nandbox logo"], ["class", "img-logo"], ["src", "./assets/img/icon.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["nandbox portal"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthListComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["load", 2]], null, 0, null, View_AuthListComponent_2)), (_l()(), i1.ɵand(0, [["list", 2]], null, 0, null, View_AuthListComponent_3)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["back"]))], function (_ck, _v) { var _co = _v.component; var tmp_1_0 = null; var currVal_1 = ((((tmp_1_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.authService.authCollection$))) == null) ? null : tmp_1_0.authStatus) === _co.authStatus.AUTH_CHATLIST_SET); var currVal_2 = i1.ɵnov(_v, 9); var currVal_3 = i1.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_AuthListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-list", [], null, null, null, View_AuthListComponent_0, RenderType_AuthListComponent)), i1.ɵdid(1, 49152, null, 0, i6.AuthListComponent, [i7.DashboardService, i8.AuthService], null, null)], null, null); }
var AuthListComponentNgFactory = i1.ɵccf("app-auth-list", i6.AuthListComponent, View_AuthListComponent_Host_0, {}, {}, []);
export { AuthListComponentNgFactory as AuthListComponentNgFactory };
