import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import * as MediaCenterActions from './mediaCenter.actions';
import * as MediaCenterMethods from 'src/models/IMediaCenter';
import { UploadGateway } from 'src/app/network/gateway/upload.gateway';
import { SocketGateway } from 'src/app/network/gateway/socket.gateway';

import { FileService } from 'src/app/core/file.service';
import { IUploadResponse } from 'src/models/IUploadResponse';
import { MessageMediaStatus } from 'src/models/constants';
import { MediaCenterDispatchers } from './mediaCenter.dispatchers';

@Injectable()
export class MediaCenterEffects {
  uploadSubscriptions: Subscription[] = [];

  @Effect({ dispatch: false })
  getMyGallery = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.GET_MY_MEDIA_GALLERY),
    map((action: MediaCenterActions.GetMyMediaGallery) => {
      this._socketGateway.sendSocketMessage(
        new MediaCenterMethods.GetMyMediaGallery()
      );
    })
  );

  @Effect({ dispatch: false })
  uploadMediaFile = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.MEDIA_UPLOADING),
    map((action: MediaCenterActions.MediaCenterUploading) => {
      const msg: MediaCenterMethods.IMediaCenter = {};
      this._fileService
        .readFileAsArrayBuffer(action.localFile)
        .then(fileAsArrayBuffer => {
          this._fileService
            .readArrayBufferAsBlobUrl(fileAsArrayBuffer, action.fileType)
            .then(blobUrl => {
              msg.loadingProgress = 0;
              msg.reference = new Date().getTime();
              msg.url = blobUrl; // set blob url in file from local
              msg.file = blobUrl; // set file from blobUrl from local
              msg.mediaStatus = MessageMediaStatus.UPLOADING;
              msg.size = action.localFile.size;
              msg.name = action.localFile.name;
              msg.fileAsArrayBuffer = fileAsArrayBuffer;
              msg.localFile = action.localFile;
              msg.type = action.fileType;
              this._mediaCenterDispatchers.mediaCenterUploadingProcessed(
                msg,
                action.uploadPrams
              );
            });
        });
    })
  );

  @Effect({ dispatch: false })
  uploadMediaFileProcessed = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.MEDIA_UPLOADING_PROCESSED),
    map((action: MediaCenterActions.MediaCenterUploadingProcessed) => {
      this.uploadMedia(
        action.payload,
        action.uploadPrams,
        action.payload.fileAsArrayBuffer,
        action.payload.localFile
      );
    })
  );

  @Effect({ dispatch: false })
  deleteMediaCenterItem = this.actions$.pipe(
    ofType(MediaCenterActions.MediaCenterActionTypes.DELETE_MEDIA_CENTER_ITEM),
    map((action: MediaCenterActions.DeleteMediaCenterItem) => {
      this._socketGateway.sendSocketMessage(
        new MediaCenterMethods.DeleteMyMediaGalleryItem(action.payload)
      );
    })
  );

  constructor(
    private actions$: Actions,
    private _fileService: FileService,
    private _uploadGateway: UploadGateway,
    private _socketGateway: SocketGateway,
    private _mediaCenterDispatchers: MediaCenterDispatchers
  ) {}

  private uploadMedia(
    msg: MediaCenterMethods.IMediaCenter,
    prams: MediaCenterMethods.MediaCenterPrams,
    fileAsArrayBuffer: ArrayBuffer,
    localFile: File
  ) {
    let progressMonitor = 0;
    this.uploadSubscriptions[
      msg.reference
    ] = this._uploadGateway
      .uploadWithProgress(
        fileAsArrayBuffer,
        localFile.type,
        localFile.name,
        false,
        null,
        prams.tabID,
        prams.groupID,
        true,
        true,
        prams.botID
      )
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            const progressVal = Math.round((event.loaded / event.total) * 100);
            if (progressVal - progressMonitor >= 18 || progressVal === 100) {
              progressMonitor = progressVal;
              this._mediaCenterDispatchers.updateMediaCenterLoadingProgress(
                msg,
                progressVal
              );
            }
          } else if (event.type === HttpEventType.Response) {
            const res = <IUploadResponse>event.body;
            msg.file = res.file; // set file from responce of api
            msg.url = res.url; // set url from url
            msg.filename = res.file; // set fileName to use it in delete
            msg.loadingProgress = 100;
            this._mediaCenterDispatchers.updateMediaCenterStatus(
              msg,
              MessageMediaStatus.UPLOADED,
              res.url,
              res.file
            );
            delete this.uploadSubscriptions[msg.reference];
          }
        },
        error => {
          console.log('Failed to upload subGroup image');
        }
      );
  }

  cancelUploadMediaFile(msg: MediaCenterMethods.IMediaCenter) {
    if (this.uploadSubscriptions[msg.reference]) {
      this.uploadSubscriptions[msg.reference].unsubscribe();
      this._mediaCenterDispatchers.updateMediaCenterStatus(
        msg,
        MessageMediaStatus.UPLOAD_FAILED,
        null,
        null
      );
      delete this.uploadSubscriptions[msg.reference];
    }
  }
}
