import { AppPublishInfo } from 'src/models/AppPublishInfo';
import { Actions } from './appPublishing.actions';
import * as actions from './appPublishing.actions';

const INITIAL_STATE = {};
export function appPublishingReducer(
  state: AppPublishInfo = INITIAL_STATE,
  action: Actions
) {
  switch (action.type) {
    case actions.AppPublishingActionTypes.RECEIVE_APP_STORE_INFO: {
      return action.appPublishInfo;
    }
    case actions.AppPublishingActionTypes.RESET:
      return INITIAL_STATE;
    default: {
      return state;
    }
  }
}
