import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { MapMarker, MapMarkerAction } from 'src/models/MapMarker';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';
import { groupDescValidator } from 'src/app/validators/groupDescValidator';
import { LocationsService } from '../../../locations/locations.service';
import { FormValidatorsService } from 'src/app/validators/form-validators.service';

@Component({
  selector: 'app-location-mini-form',
  templateUrl: './location-mini-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMiniFormComponent implements OnInit {
  @Input() owner: string;
  @Input() mainGroupId: string;
  @Input() showCancel = false;
  @Output() closeMiniFormNotifiy = new EventEmitter<boolean>();

  iconName: string;

  form: FormGroup;
  mapMarkerEdit: MapMarker = {};

  options = [
    {
      key: 'stop',
      value: 'Stop'
    },
    {
      key: 'dropoff',
      value: 'Drop Off'
    }
  ];

  constructor(
    private _fb: FormBuilder,
    private _validateForm: FormValidatorsService,
    private _locationsService: LocationsService
  ) {}

  ngOnInit() {
    this.iconName = 'location_home_32_px';
    this.form = this.setFormGroup();
  }

  /* Setup form Validators */
  setFormGroup(): FormGroup {
    return this._fb.group({
      title: [
        this.mapMarkerEdit && this.mapMarkerEdit.title
          ? this.mapMarkerEdit.title
          : null,
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(1),
          noWhitespaceValidator
        ]
      ],
      snippet: [
        this.mapMarkerEdit && this.mapMarkerEdit.snippet
          ? this.mapMarkerEdit.snippet
          : null,
        [groupDescValidator, noWhitespaceValidator]
      ],
      photo: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.photo
          ? this.mapMarkerEdit.photo
          : null
      ),
      photoUrl: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.image_url
          ? this.mapMarkerEdit.image_url
          : null
      ),
      address: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.address
          ? this.mapMarkerEdit.address
          : null,
        [Validators.required]
      ),
      lat: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.lat
          ? this.mapMarkerEdit.lat
          : null
      ),
      lon: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.lon
          ? this.mapMarkerEdit.lon
          : null
      ),
      url: [
        this.mapMarkerEdit && this.mapMarkerEdit.url
          ? this.mapMarkerEdit.url
          : null
      ],
      type: new FormControl(
        this.mapMarkerEdit && this.mapMarkerEdit.type
          ? this.mapMarkerEdit.type
          : 'dropoff'
      )
    });
  }

  /** Get fields */
  get photo() {
    return this.form.get('photo');
  }
  get photoUrl() {
    return this.form.get('photoUrl');
  }
  get title() {
    return this.form.get('title');
  }
  get snippet() {
    return this.form.get('snippet');
  }
  get address() {
    return this.form.get('address');
  }
  get lat() {
    return this.form.get('lat');
  }
  get lon() {
    return this.form.get('lon');
  }
  get url() {
    return this.form.get('url');
  }
  get type() {
    return this.form.get('type');
  }

  updateItemIconEvent(icon: string) {
    this.iconName = icon;
  }

  cancelEvent() {
    this.closeMiniFormNotifiy.emit(true);
  }

  onSubmit() {
    if (this.form.valid) {
      const mapMarkerToSend: MapMarker = {
        title: this.form.value.title,
        snippet: this.form.value.snippet,
        icon: this.iconName,
        lat: this.form.value.lat,
        lon: this.form.value.lon,
        address: this.form.value.address,
        type: this.form.value.type,
        url: this.form.value.url,
        photo: this.form.value.photo,
        owner: this.owner
      };

      this._locationsService.setMapMarker(
        this.mapMarkerEdit.id,
        mapMarkerToSend,
        false
      );
      this.closeMiniFormNotifiy.emit(true);
    } else {
      this._validateForm.validateAllFormFields(this.form);
    }
  }
}
