import {
  SEND_QR_GET_METHOD,
  SEND_PING_METHOD,
  SEND_SELECT_CHAT_METHOD,
  GET_EMAIL_TAC_METHOD,
  SEND_TOKEN_AUTH_METHOD,
  CREATE_ACCOUNT_METHOD,
  GET_TOKEN,
  GET_ADMIN_QR_METHOD,
  GET_AUTH_PRIVILEGE_METHOD,
  CHANGE_PASSWORD_METHOD,
  RESET_PASSWORD_METHOD
} from './constants';
import { AppTemplate } from 'src/models/AppTemplate';

export interface IAuth {
  qrCode?: string;
  botId?: string;
  token?: string;
  localId?: string;
  name?: string;
  url?: string;
  authStatus?: string;
  rem?: boolean;
  qrExpire?: boolean;
  ID?: string;
  date?: number;
  offset?: number;
  chatId?: string;
  countryIso?: string;
  timezone?: string;
  tac?: string;
  email?: string;
  password?: string;
  appName?: string;
  signupStatus?: string;
  selectedTemplateID?: string;
  progressWeb?: any;
  mode?: any;
  adminQRCode?: string;
  appConfig?: number;
  role?: number;
  main_group_id?: string;
  is_nandbox?: number;
}

export interface AuthForm {
  email?: string;
  password?: string;
  appName?: string;
  name?: string;
  terms?: boolean;
  privacy?: boolean;
  countryIso?: string;
  templateID?: string;
  tac?: string;
  rem?: boolean;
  su?: string;
}

export interface CountryData {
  country_code?: string;
  timezone?: string;
}

export interface HttpResult {
  result?: number;
  error?: number;
  token?: string;
  templates?: AppTemplate[];
  su?: any;
}

export interface HttpTokenResult {
  result?: number;
  token?: string;
}

export class GetQRCode {
  readonly method = SEND_QR_GET_METHOD;
}

export class Ping {
  readonly method = SEND_PING_METHOD;
}

export class SendTokenAuth {
  readonly method = SEND_TOKEN_AUTH_METHOD;
  constructor(public token: string, public rem: boolean) {}
}

export class SendSelectChat {
  readonly method = SEND_SELECT_CHAT_METHOD;
  constructor(public chat_id: string) {}
}

// send type by 2 in case of reset
export class SendGetEmailTac {
  readonly method = GET_EMAIL_TAC_METHOD;
  constructor(
    public countryIso: string,
    public email: string,
    public type?: number
  ) {}
}

export class ResetPassword {
  readonly method = RESET_PASSWORD_METHOD;
  constructor(
    public email: string,
    public tac: string,
    public newPassword: string,
    public confirmNewPassword: string
  ) {}
}

export class CreateAccount {
  readonly method = CREATE_ACCOUNT_METHOD;
  constructor(
    public tac: string,
    public email: string,
    public password: string,
    public name: string,
    public template_id: string,
    public full_name: string
  ) {}
}

export class GetToken {
  readonly method = GET_TOKEN;
  constructor(
    public email: string,
    public password: string,
    public su?: string
  ) {}
}

export class GetAdminQRCode {
  readonly method = GET_ADMIN_QR_METHOD;
}

export class GetAuthPrivilege {
  readonly method = GET_AUTH_PRIVILEGE_METHOD;
  constructor(public reference?: string) {}
}

export class ChangePassword {
  readonly method = CHANGE_PASSWORD_METHOD;
  constructor(
    public oldPassword: string,
    public newPassword: string,
    public confirmNewPassword: string
  ) {}
}

export type AuthMethods =
  | GetQRCode
  | Ping
  | SendTokenAuth
  | SendSelectChat
  | SendGetEmailTac
  | CreateAccount
  | GetToken
  | GetAdminQRCode
  | GetAuthPrivilege
  | ChangePassword;
