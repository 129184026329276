import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy
} from '@angular/core';

import { UiService } from 'src/app/core/ui.service';
import { AuthService } from 'src/app/core/auth.service';
import { LayoutService } from './layout.service';
import { DashboardService } from '../+dashboard/dashboard.service';
import { AppMgmtService } from '../+dashboard/app-mgmt/appMgmt.service';

import { UISections, ChannelAppScreens } from 'src/models/constants';
import { Router } from '@angular/router';
import { UIDispatchers } from 'src/app/store/ui/ui.dispatchers';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mode: any;
  @Input() role: any;
  uiSection = UISections;
  appScreens = ChannelAppScreens;
  open: boolean;
  hidePopUp: boolean;
  private confirmInfo = null;
  private sideMenuStatus = null;
  authSubscribtion = null;
  constructor(
    public _dashboardService: DashboardService,
    public uiService: UiService,
    public layoutService: LayoutService,
    public authService: AuthService,
    public appManagementService: AppMgmtService,
    private _ref: ChangeDetectorRef,
    public _uiDispatchers: UIDispatchers
  ) {}
  ngOnChanges() {}
  ngOnInit() {
    // console.log('here i am mode & role => ', this.mode, this.role);
    setTimeout(() => {
      this.authSubscribtion = this.authService.authCollection$.subscribe(
        res => {
          if (
            res.is_nandbox &&
            res.main_group_id !== res.chatId &&
            this.role == 2
          ) {
            this._uiDispatchers.setSection(UISections.CHANNEL);
          } else if (!res.is_nandbox && res.main_group_id !== res.chatId) {
            this._uiDispatchers.setSection(UISections.HOME);
          } else if (
            res.appConfig ||
            (res.mode == 0 && res.appConfig == 0) ||
            (!res.is_nandbox &&
              res.main_group_id === res.chatId &&
              res.role == 1)
          ) {
            this._uiDispatchers.setSection(UISections.APP);
          } else {
            this._uiDispatchers.setSection(UISections.HOME);
          }
        }
      );
    }, 1500);
    // console.log('here i am ');
    this.sideMenuStatus = this.layoutService
      .getSideNavStatus()
      .subscribe(res =>
        res.matches
          ? this.layoutService.setSideMenuStatus(1)
          : this.layoutService.setSideMenuStatus(0)
      );

    this.confirmInfo = this.appManagementService.appInfo$.subscribe(res => {
      if (res) {
        if (res.hide_module_added_msg === 1) {
          this.hidePopUp = false;
        } else if (res.hide_module_added_msg === 0) {
          this.hidePopUp = true;
        } else if (res) {
          this.hidePopUp = true;
        }
      }
    });
  }

  sectionEvent(direction: string) {
    this.paginationEvent('rest');
    this._dashboardService.setSection(direction);
  }

  searchEvent(searchTerm: string) {
    this.paginationEvent('rest');
    this._dashboardService.setSearchKeyword(searchTerm);
  }

  paginationEvent(value: string) {
    this._dashboardService.setPageNumber(value);
  }

  logoutEvent($event) {
    if ($event) {
      this._dashboardService.logout();
    }
  }

  talkToAdminEvent(chatId: string) {
    this.uiService.requestHistory(chatId);
    this.uiService.selectTalkToAdmin();
  }

  closeAndOpenSideMenu() {
    if (this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
    this._ref.detectChanges();
  }

  setSideNavStatus(status) {
    // console.log('here 2');
    if (status) {
      this.layoutService.setSideMenuStatus(0);
    } else {
      this.layoutService.setSideMenuStatus(1);
    }
  }

  togglePopUp() {
    this.hidePopUp = false;
  }

  ngOnDestroy() {
    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
    if (this.confirmInfo !== null && this.confirmInfo !== undefined) {
      this.confirmInfo.unsubscribe();
    }
    if (this.sideMenuStatus !== null && this.sideMenuStatus !== undefined) {
      this.sideMenuStatus.unsubscribe();
    }
  }
}
