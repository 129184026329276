import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges
} from '@angular/core';

import { MediaCenterService } from '../media-center.service';

import { ISelectedFiles } from 'src/models/ISelectedFiles';
import { IMediaCenter, MediaCenterPrams } from 'src/models/IMediaCenter';
import { MessageMediaStatus, MessageTypes } from 'src/models/constants';

@Component({
  selector: 'app-media-center-modal',
  templateUrl: './media-center-modal.component.html',
  styleUrls: ['./media-center-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCenterModalComponent implements OnChanges {
  @Input() mimeTypes: string;
  @Input() isPhotoMedia: boolean;
  @Input() tabID: string;
  @Input() groupID: string;
  @Input() botID: string;
  @Output() mediaCenterNotify = new EventEmitter<IMediaCenter>();
  @Output() cancelNotify = new EventEmitter<boolean>(false);

  messageTypes: typeof MessageTypes = MessageTypes;

  mediaCenterType: MediaCenterPrams = {
    type: null,
    tabID: null,
    groupID: null,
    botID: null
  };

  constructor(private _mediaCenterService: MediaCenterService) {}

  ngOnChanges() {
    this.mediaCenterType = {
      ...this.mediaCenterType,
      type: this.isPhotoMedia ? this.messageTypes.PHOTO : null,
      tabID: this.tabID,
      groupID: this.groupID,
      botID: this.botID
    };
  }

  mediaCenterEvent(item: IMediaCenter) {
    if (
      item.mediaStatus === MessageMediaStatus.DOWNLOADED ||
      item.mediaStatus === MessageMediaStatus.UPLOADED ||
      item.url.includes('http')
    ) {
      this.mediaCenterNotify.emit(item);
    }
  }
  mediaCenterTypeEvent(type: string) {
    this.mediaCenterType = { ...this.mediaCenterType, type };
  }

  fileSelectedEvent(event: ISelectedFiles) {
    this._mediaCenterService.mediaCenterUploading(event, this.mediaCenterType);
  }

  cancelNotifyClick() {
    this.cancelNotify.emit(true);
  }
}
