import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from './appPublishing.actions';

import { AppState } from 'src/models/AppState';
import { AppPublishInfo } from 'src/models/AppPublishInfo';

@Injectable({
  providedIn: 'root'
})
export class AppPublishingDispatchers {
  constructor(private _store: Store<AppState>) {}

  setAppStoreInfo(appPublishingInfo: AppPublishInfo): void {
    this._store.dispatch(new actions.SetAppStoreInfo(appPublishingInfo));
  }

  receiveAppStoreInfo(appPublishingInfo: AppPublishInfo): void {
    this._store.dispatch(new actions.ReceiveAppStoreInfo(appPublishingInfo));
  }

  requestAppTesters() {
    this._store.dispatch(new actions.RequestAppTesters());
  }

  receivePublishInfoError(errorNumber: number) {
    this._store.dispatch(new actions.ReceivePublishInfoError(errorNumber));
  }

  receiveErrorCode(errorNumber: number) {
    this._store.dispatch(new actions.ReceiveErrorCode(errorNumber));
  }

  requestAppStoreInfo(appID?: string) {
    this._store.dispatch(new actions.RequestAppStoreInfo(appID));
  }
}
