<ng-container
  *ngIf="(layoutService?.uiCollection$ | async)?.section as currentSection"
>
  <div
    *ngIf="currentSection === uiSections.HOME; else sectionTitle"
    class="title"
    [innerHTML]="(layoutService?.selectedChat$ | async)?.name"
  ></div>
  <ng-template #sectionTitle>
    <div class="title" [innerHTML]="getSectionTitle(currentSection)"></div>
  </ng-template>
</ng-container>
