import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  Input
} from '@angular/core';

import { TagsService } from '../../tags/tags.service';

import { ITag } from 'src/models/ITag';
import { map } from 'rxjs/operators';
import { TAGS } from 'src/models/AppSections';

// note: styles of this componant shared
@Component({
  selector: 'app-tags-table',
  templateUrl: './tags-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsTableComponent {
  @Input() searchTerm: string;
  @Input() isPrivate: boolean;
  @Input() isPublic: boolean;

  tagsFet = TAGS;

  @ContentChild(TemplateRef, { static: false }) templateRef;

  constructor(public tagsService: TagsService) {}

  get selectedChatTags$() {
    if (this.isPrivate) {
      return this.tagsService.selectedChatTags$.pipe(
        map(tags => {
          if (tags !== null && tags.length > 0) {
            return tags.filter(res => res.isPrivate);
          } else {
            return [];
          }
        })
      );
    } else if (this.isPublic) {
      return this.tagsService.selectedChatTags$.pipe(
        map(tags => {
          if (tags !== null && tags.length > 0) {
            return tags.filter(res => !res.isPrivate);
          } else {
            return [];
          }
        })
      );
    } else {
      return this.tagsService.selectedChatTags$;
    }
  }
  TrackByFunction(index, tag: ITag) {
    return tag.id;
  }
}
