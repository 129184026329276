import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ElementRef,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

import { CellsService } from '../cells.service';
import { MessageMediaStatus } from 'src/models/constants';

@Component({
  selector: 'app-video-cell',
  templateUrl: './video-cell.component.html',
  styleUrls: ['./video-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoCellComponent implements OnChanges, OnDestroy {
  @Input() localMedia: string;
  @Input() localThumbnail: string;
  @Input() mediaStatus: string;
  @Input() fileSize: number;
  @Input() mediaDuration: number;
  @Input() height: number;
  @Input() width: number;
  @Input() caption: string;
  @Input() percent: number;
  @Input() themeColor: string;
  @Input() isFullScreen = false;

  @Output() retryNotify = new EventEmitter<boolean>(false);
  @Output() cancelNotify = new EventEmitter<boolean>(false);
  @Output() mediaClickedNotifier = new EventEmitter<boolean>(false);

  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;

  isPause = true;
  messageMediaStatus: typeof MessageMediaStatus = MessageMediaStatus;

  constructor(private _cellsService: CellsService) {}

  ngOnChanges(changes: SimpleChanges) {
    const chng = changes['localMedia'];
    if (
      chng &&
      chng.currentValue &&
      chng.previousValue &&
      chng.currentValue !== chng.previousValue
    ) {
      if (this.videoPlayer) {
        this.videoPlayer.nativeElement.load();
      }
    }
    if (this.width && this.height) {
      [this.width, this.height] = this._cellsService.resizeMedia(
        this.width,
        this.height
      );
    }
  }

  ngOnDestroy() {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.pause();
    }
  }

  get theme() {
    return {
      'border-top-color': this.themeColor,
      'border-right -color': this.themeColor
    };
  }

  get media() {
    return this._cellsService.getMedia(this.localMedia);
  }

  get thumbnail() {
    return this._cellsService.getMedia(this.localThumbnail);
  }

  videoPlayNow() {
    if (this.isFullScreen) {
      if (
        this._cellsService.currentPlayingMedia &&
        this._cellsService.currentPlayingMedia !==
          this.videoPlayer.nativeElement
      ) {
        this._cellsService.currentPlayingMedia.pause();
      }
      this._cellsService.currentPlayingMedia = this.videoPlayer.nativeElement;
    }
  }

  playVideo() {
    if (this.isFullScreen) {
      this.isPause = false;
      this.videoPlayer.nativeElement.play();
    }
  }

  retryEvent() {
    this.retryNotify.emit(true);
  }

  cancelEvent() {
    this.cancelNotify.emit(true);
  }

  mediaClickedEvent() {
    this.mediaClickedNotifier.emit(true);
  }
}
