import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { TagsService } from '../../tags/tags.service';

import { ITag } from 'src/models/ITag';
import { PrivilegesName } from 'src/models/privileges';

// note: styles of this componant shared
@Component({
  selector: 'app-tags-selector',
  templateUrl: './tags-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsSelectorComponent implements OnChanges {
  showTagForm = false;
  selectorForm: FormGroup;

  private _searchTerm = new BehaviorSubject<string>('');
  public searchTerm$ = this._searchTerm.asObservable();

  @Input() isPrivate: boolean;
  @Input() isPublic: boolean;

  @Input() tags: ITag[] = [];
  @Input() selectedTags: string[];

  @Output() submitTagsFormNotfiy = new EventEmitter<ITag[]>();
  @Output() createTagNotfiy = new EventEmitter<ITag>();
  @Output() cancelNotfiy = new EventEmitter<boolean>();

  privilegesName: typeof PrivilegesName = PrivilegesName;

  constructor(private _fb: FormBuilder, public tagsService: TagsService) {}

  ngOnChanges(chng: SimpleChanges) {
    const chngs = chng['tags'];
    const preLocalTags: ITag[] = chngs.previousValue;

    if (
      chngs &&
      ((chngs.currentValue &&
        chngs.previousValue &&
        chngs.currentValue.length !== chngs.previousValue.length) ||
        (chngs.currentValue && !chngs.previousValue))
    ) {
      this.selectorForm = this._fb.group({
        tags:
          this.tags && this.tags.length > 0
            ? this._fb.array(
                this.tags.map(x => {
                  if (this.selectedTags && this.selectedTags.length > 0) {
                    return this.selectedTags.includes(x.id);
                  } else if (
                    !preLocalTags ||
                    (preLocalTags &&
                      preLocalTags.filter(y => y.id === x.id).length === 0)
                  ) {
                    return !1;
                  }
                })
              )
            : []
      });
    } else if (!this.selectorForm) {
      this.selectorForm = this._fb.group({
        tags: []
      });
    }
  }

  cancelEvent() {
    this.cancelNotfiy.emit(true);
  }

  addnewEvent(e: boolean) {
    this.showTagForm = e;
  }
  onSearchFormSubmit(term: string) {
    this._searchTerm.next(term);
  }
  onSubmit() {
    const valueToStore = Object.assign({}, this.selectorForm.value, {
      tags: this.convertToValue('tags')
    });
    this.submitTagsFormNotfiy.emit(valueToStore.tags);
  }
  convertToValue(key: string) {
    return this.selectorForm.value[key]
      .map((x, i) => x && this[key][i])
      .filter(x => !!x);
  }

  createTagEvent(tag: ITag) {
    this.createTagNotfiy.emit(tag);
  }
}
