/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component.ngfactory";
import * as i3 from "../../../../abstract-frames/empty-item-frame/empty-item-frame.component";
import * as i4 from "../../../../shared/pipes/search/search.pipe";
import * as i5 from "./tags-table.component";
import * as i6 from "../../tags/tags.service";
var styles_TagsTableComponent = [];
var RenderType_TagsTableComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TagsTableComponent, data: {} });
export { RenderType_TagsTableComponent as RenderType_TagsTableComponent };
function View_TagsTableComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_TagsTableComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "tr", [["class", "flex-table row"], ["role", "rowgroup"], ["style", "background-color: #fff; padding: 5px 10px; border: none;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "td", [["class", "md truncate-text"], ["role", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "td", [["class", "lg truncate-text"], ["role", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "td", [["class", "sm action-cell"], ["role", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TagsTableComponent_4)), i0.ɵdid(9, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(10, { $implicit: 0, index: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 10, 0, _v.context.$implicit, _v.context.index); var currVal_3 = _co.templateRef; _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.description); _ck(_v, 6, 0, currVal_1); }); }
function View_TagsTableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "table", [["class", "table-container"], ["role", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "tr", [["class", "flex-table header"], ["role", "rowgroup"], ["style", "padding: 5px 15px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "th", [["class", "md"], ["role", "columnheader"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Tag Name"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "th", [["class", "lg"], ["role", "columnheader"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Description"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "th", [["class", "sm"], ["role", "columnheader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "tbody", [["style", "background-color: #eef3f9; padding: 5px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TagsTableComponent_3)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i0.ɵppd(11, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.ngIf, _co.searchTerm, "name")); var currVal_1 = _co.TrackByFunction; _ck(_v, 10, 0, currVal_0, currVal_1); }, null); }
function View_TagsTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagsTableComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf && (((_v.context.ngIf == null) ? null : _v.context.ngIf.length) > 0)); var currVal_1 = i0.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TagsTableComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-empty-item-frame", [], null, null, null, i2.View_EmptyItemFrameComponent_0, i2.RenderType_EmptyItemFrameComponent)), i0.ɵdid(1, 49152, null, 0, i3.EmptyItemFrameComponent, [], { emptySymbol: [0, "emptySymbol"], emptyTitle: [1, "emptyTitle"], emptyDesc: [2, "emptyDesc"], noBg: [3, "noBg"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tagsFet == null) ? null : _co.tagsFet.symbolImage); var currVal_1 = ((_co.tagsFet == null) ? null : _co.tagsFet.emptyTitle); var currVal_2 = ((_co.tagsFet == null) ? null : _co.tagsFet.emptyPoolDesc); var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TagsTableComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.SearchPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TagsTableComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["isEmpty", 2]], null, 0, null, View_TagsTableComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.selectedChatTags$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagsTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-tags-table", [], null, null, null, View_TagsTableComponent_0, RenderType_TagsTableComponent)), i0.ɵdid(1, 49152, null, 1, i5.TagsTableComponent, [i6.TagsService], null, null), i0.ɵqud(603979776, 1, { templateRef: 0 })], null, null); }
var TagsTableComponentNgFactory = i0.ɵccf("app-tags-table", i5.TagsTableComponent, View_TagsTableComponent_Host_0, { searchTerm: "searchTerm", isPrivate: "isPrivate", isPublic: "isPublic" }, {}, []);
export { TagsTableComponentNgFactory as TagsTableComponentNgFactory };
