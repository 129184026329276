/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./circle-btn/circle-btn.component.ngfactory";
import * as i2 from "./circle-btn/circle-btn.component";
import * as i3 from "../abstract-icons/edit-icon.component.ngfactory";
import * as i4 from "../abstract-icons/edit-icon.component";
import * as i5 from "./edit-btn.component";
var styles_EditBtnComponent = [];
var RenderType_EditBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditBtnComponent, data: {} });
export { RenderType_EditBtnComponent as RenderType_EditBtnComponent };
export function View_EditBtnComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-circle-btn", [["edit", ""]], null, [[null, "btnClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("btnClicked" === en)) {
        var pd_0 = (_co.btnEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CircleBtnComponent_0, i1.RenderType_CircleBtnComponent)), i0.ɵdid(1, 49152, null, 0, i2.CircleBtnComponent, [], null, { btnClicked: "btnClicked" }), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-edit-icon", [], null, null, null, i3.View_EditIconComponent_0, i3.RenderType_EditIconComponent)), i0.ɵdid(3, 49152, null, 0, i4.EditIconComponent, [], null, null)], null, null); }
export function View_EditBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-edit-btn", [], null, null, null, View_EditBtnComponent_0, RenderType_EditBtnComponent)), i0.ɵdid(1, 114688, null, 0, i5.EditBtnComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditBtnComponentNgFactory = i0.ɵccf("app-edit-btn", i5.EditBtnComponent, View_EditBtnComponent_Host_0, {}, { btnClicked: "btnClicked" }, []);
export { EditBtnComponentNgFactory as EditBtnComponentNgFactory };
