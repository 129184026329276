import { Injectable } from '@angular/core';

import { SocketService } from '../services/socket.service';
import {
  GIF_EXTENTION,
  MP4_EXTENTION,
  MessageTypes,
  SEND_MESSAGE_METHOD,
  SEND_PHOTO_METHOD,
  SEND_AUDIO_METHOD,
  SEND_DOCUMENT_METHOD,
  SEND_VIDEO_METHOD,
  SEND_VOICE_METHOD,
  SEND_LOCATION_METHOD,
  SEND_CONTACT_METHOD,
  SEND_INSTANT_URL,
  SEND_CALENDAR_METHOD
} from 'src/models/constants';
import { IMessage } from 'src/models/IMessage';
import { OutMessage } from 'src/models/MessageParts';

@Injectable()
export class MessageGateway {
  constructor(private _socketService: SocketService) {}

  sendSocketMessage(message: any) {
    this._socketService.emitSocketMessage(message);
  }

  sendMessage(message: IMessage) {
    const msgToBeSent: OutMessage = {};
    msgToBeSent.echo = 1;
    // can check to use group id instead
    msgToBeSent.chat_id = message.receiver_id;
    msgToBeSent.reference = message.reference;
    // Map Object if exist

    // Calendar object
    if (message.auto_accept) {
      msgToBeSent.auto_accept = message.auto_accept;
    }

    if (message.schedule_date) {
      msgToBeSent.schedule_date = message.schedule_date;
    }
    if (message.award) {
      msgToBeSent.award = message.award;
    }
    if (message.welcome) {
      msgToBeSent.welcome = message.welcome;
    }
    if (message.tags) {
      msgToBeSent.tags = message.tags;
    }
    if (message.tab) {
      msgToBeSent.tab = message.tab;
    }
    // need to do that if i want to send message style 0
    if (typeof message.style === 'number') {
      msgToBeSent.style = message.style;
    }
    if (message.bg_color) {
      msgToBeSent.bg_color = message.bg_color;
    }
    if (message.sticky_title) {
      msgToBeSent.sticky_title = message.sticky_title;
    }
    if (message.sticky_desc) {
      msgToBeSent.sticky_desc = message.sticky_desc;
    }
    if (message.c_code) {
      msgToBeSent.c_code = message.c_code;
    }
    if (message.c_exp) {
      msgToBeSent.c_exp = message.c_exp;
    }
    if (message.menu_ref) {
      msgToBeSent.menu_ref = message.menu_ref;
    }
    if (message.inline_menu) {
      msgToBeSent.inline_menu = message.inline_menu;
    }
    if (message.reply_to_admin) {
      msgToBeSent.reply_to_admin = message.reply_to_admin;
    }
    if (message.web_page_preview) {
      msgToBeSent.web_page_preview = message.web_page_preview;
    }
    if (message.disable_reply) {
      msgToBeSent.disable_reply = message.disable_reply;
    }
    if (
      message.disable_web_page_preview !== null &&
      message.disable_web_page_preview !== undefined
    ) {
      msgToBeSent.disable_web_page_preview = message.disable_web_page_preview;
    }
    if (
      message.disable_notification !== null &&
      message.disable_notification !== undefined
    ) {
      msgToBeSent.disable_notification = message.disable_notification;
    }
    if (message.reply_to_message_id) {
      msgToBeSent.reply_to_message_id = message.reply_to_message_id;
      // condition in case reply to admin
      if (message.group_type === 101 && !message.send_to_user_id) {
        msgToBeSent.to_admin = 1;
      }
    }
    if (message.send_to_user_id) {
      msgToBeSent.to_user_id = message.send_to_user_id;
    }
    if (message.caption) {
      msgToBeSent.caption = message.caption;
    }
    if (message.media_duration) {
      msgToBeSent.duration = message.media_duration;
    }
    if (message.media_width) {
      msgToBeSent.width = message.media_width;
    }
    if (message.media_height) {
      msgToBeSent.height = message.media_height;
    }
    if (message.file_size) {
      msgToBeSent.size = message.file_size;
    }
    if (message.chat_settings) {
      msgToBeSent.chat_settings = message.chat_settings;
    }
    /***************************************************************************************/
    /****** Switch based on Message Type ***************************************************/
    switch (message.type) {
      case MessageTypes.CALENDAR:
        msgToBeSent.method = SEND_CALENDAR_METHOD;
        msgToBeSent.title = message.json.title;
        msgToBeSent.description = message.json.description;
        msgToBeSent.photo = message.json.photo_id;
        msgToBeSent.json = message.json;
        msgToBeSent.calendar_id = message.json.id;
        break;
      case MessageTypes.ARTICLE:
        msgToBeSent.method = SEND_INSTANT_URL;
        msgToBeSent.url = message.url;
        msgToBeSent.title = message.linkPreviewTitle;
        msgToBeSent.description = message.linkPreviewDescription;
        msgToBeSent.photo_url = message.linkPreviewImage;
        break;
      case MessageTypes.TEXT:
        msgToBeSent.method = SEND_MESSAGE_METHOD;
        msgToBeSent.text = message.text;
        break;
      case MessageTypes.GIF:
        if (message.file_name.endsWith(GIF_EXTENTION)) {
          msgToBeSent.method = SEND_PHOTO_METHOD;
          msgToBeSent.photo = message.media_id;
        } else if (message.file_name.endsWith(MP4_EXTENTION)) {
          msgToBeSent.method = SEND_VIDEO_METHOD;
          msgToBeSent.video = message.media_id;
        }
        break;
      case MessageTypes.PHOTO:
        msgToBeSent.method = SEND_PHOTO_METHOD;
        msgToBeSent.photo = message.media_id;
        break;
      case MessageTypes.AUDIO:
        msgToBeSent.method = SEND_AUDIO_METHOD;
        msgToBeSent.audio = message.media_id;
        if (message.performer) {
          msgToBeSent.performer = message.performer;
        }
        if (message.title) {
          msgToBeSent.title = message.title;
        }
        break;
      case MessageTypes.DOCUMENT:
        msgToBeSent.method = SEND_DOCUMENT_METHOD;
        msgToBeSent.document = message.media_id;
        break;
      case MessageTypes.VIDEO:
        msgToBeSent.method = SEND_VIDEO_METHOD;
        msgToBeSent.video = message.media_id;
        break;
      case MessageTypes.VOICE:
        msgToBeSent.method = SEND_VOICE_METHOD;
        msgToBeSent.voice = message.media_id;

        break;
      case MessageTypes.LOCATION:
        msgToBeSent.method = SEND_LOCATION_METHOD;
        msgToBeSent.latitude = message.latitude;
        msgToBeSent.longitude = message.longitude;
        if (message.location_name) {
          msgToBeSent.name = message.location_name;
        }
        if (message.location_details) {
          msgToBeSent.details = message.location_details;
        }
        if (message.live_period) {
          msgToBeSent.live_period = message.live_period;
        }
        break;
      case MessageTypes.CONTACT:
        msgToBeSent.method = SEND_CONTACT_METHOD;
        msgToBeSent.phone_number = message.phone_number;
        msgToBeSent.name = message.contact_name;
        break;
      default:
        break;
    }
    /***************************************************************************************************/
    this.sendSocketMessage(msgToBeSent);
  }
}
