import { Actions } from './blacklistUI.actions';
import * as actions from './blacklistUI.actions';
import {
  SCREEN_STATUS,
  ListRequestDetails,
  PageDirection
} from '../../../models/constants';
import { BlackListsUI } from 'src/models/BlackListsUI';

const INITIAL_STATE: BlackListsUI = {
  eop: 0,
  sop: null,
  currentPage: [],
  hash: '',
  direction: null,
  previousRequest: {},
  msisdn: null,
  screenStatus: 'Done'
};

export function blackListUiReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case actions.BlacklistUIActionTypes.UI_BLACKLIST_RESET_PAGING:
      return { ...INITIAL_STATE, msisdn: action.name ? action.name : null };
    case actions.BlacklistUIActionTypes.UI_BLACKLIST_GET_NEXT_PAGE: {
      return { ...state, direction: PageDirection.NEXT };
    }
    case actions.BlacklistUIActionTypes.UI_BLACKLIST_GET_PREV_PAGE: {
      return { ...state, direction: PageDirection.PREV };
    }
    case actions.BlacklistUIActionTypes.UI_BLACKLIST_RECEIVED_HISTORY_CHUNK: {
      const prevRequest: ListRequestDetails = {};
      prevRequest.eop = state.eop;
      prevRequest.sop = state.sop;
      prevRequest.hash = state.hash;
      prevRequest.direction = state.direction;
      return {
        ...state,
        previousRequest: prevRequest,
        eop: action.eop ? action.eop : state.eop,
        sop: action.sop ? action.sop : state.sop,
        currentPage:
          (action.currentPage && action.currentPage.length) > 0
            ? action.currentPage
            : state.currentPage
      };
    }
    case actions.BlacklistUIActionTypes.UI_ADD_USER_TO_BLACKLIST_SUCCESS: {
      return { ...state, currentPage: [...action.users, ...state.currentPage] };
    }
    case actions.BlacklistUIActionTypes.UI_REMOVE_USER_FROM_BLACKLIST_SUCCESS: {
      return {
        ...state,
        currentPage: state.currentPage.filter(user => {
          if (user.msisdn !== action.users[0]) {
            return user;
          }
        })
      };
    }
    case actions.BlacklistUIActionTypes.UI_BLACKLIST_SET_IDLE_STATUS: {
      return { ...state };
    }
    case actions.BlacklistUIActionTypes
      .UI_SET_BLACKLIST_LOADING_SCREEN_STATUS: {
      return { ...state, screenStatus: action.status };
    }
    case actions.BlacklistUIActionTypes.RESET_BLACKLIST: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
