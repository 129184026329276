import { Store } from '@ngrx/store';
import * as SubChannelUIActions from './subChannelsUI.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class SubChannelUiDispatchers {
    constructor(_store) {
        this._store = _store;
    }
    listSubChannelUI() {
        this._store.dispatch(new SubChannelUIActions.ListSubChannelUI());
    }
    createSubChannelUI() {
        this._store.dispatch(new SubChannelUIActions.CreateSubChannelUI());
    }
    viewSubChannelUI(selectedSubChannel) {
        this._store.dispatch(new SubChannelUIActions.ViewSubChannelUI(selectedSubChannel));
    }
    editSubChannelUI(selectedSubChannel) {
        this._store.dispatch(new SubChannelUIActions.EditSubChannelUI(selectedSubChannel));
    }
    uploadSubChannelImage(selectedFile) {
        this._store.dispatch(new SubChannelUIActions.UploadSubChannelImage(selectedFile));
    }
    subChannelPhotoUploadUI(createdSubChannel) {
        this._store.dispatch(new SubChannelUIActions.SubChannelPhotoUploadUI(createdSubChannel));
    }
    subChannelCreationComplete() {
        this._store.dispatch(new SubChannelUIActions.SubChannelCreationComplete());
    }
    closeViewSubChannelUI() {
        this._store.dispatch(new SubChannelUIActions.CloseViewSubChannelUI());
    }
    closeEditSubChannelUI() {
        this._store.dispatch(new SubChannelUIActions.CloseEditSubChannelUI());
    }
    // paging dispatchers
    getNextSubChannelPage() {
        this._store.dispatch(new SubChannelUIActions.GetNextSubChannelPage());
    }
    getPrevSubChannelPage() {
        this._store.dispatch(new SubChannelUIActions.GetPrevSubChannelPage());
    }
    receivedSubChannelHistoryChunk(eop, sop, currentPage) {
        this._store.dispatch(new SubChannelUIActions.ReceivedSubChannelHistoryChunk(eop, sop, currentPage));
    }
    resetSubChannelPaging() {
        this._store.dispatch(new SubChannelUIActions.ResetSubChannelPaging());
    }
    setStatusAsIdle() {
        this._store.dispatch(new SubChannelUIActions.SetStatusAsIdle());
    }
}
SubChannelUiDispatchers.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubChannelUiDispatchers_Factory() { return new SubChannelUiDispatchers(i0.ɵɵinject(i1.Store)); }, token: SubChannelUiDispatchers, providedIn: "root" });
