<div class="file-container">
  <div class="file-avtar">
    <!-- in case of uploading and want to cancel -->
    <svg-icon
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOADING ||
        mediaStatus === messageMediaStatus.UPLOADING
      "
      src="./assets/img/close-white.svg"
      [stretch]="true"
      [svgStyle]="{ 'width.px': 10, fill: '#C0C0C0' }"
      (click)="cancelEvent()"
    >
    </svg-icon>
    <div
      *ngIf="
        (mediaStatus === messageMediaStatus.DOWNLOADING ||
          mediaStatus === messageMediaStatus.UPLOADING) &&
        !percent
      "
      class="loader-spiner"
      (click)="cancelEvent()"
      [ngStyle]="theme"
    ></div>
    <circle-progress
      *ngIf="
        (mediaStatus === messageMediaStatus.DOWNLOADING ||
          mediaStatus === messageMediaStatus.UPLOADING) &&
        percent
      "
      class="loader-progress"
      [radius]="21"
      [percent]="percent"
      [outerStrokeColor]="themeColor"
      (click)="cancelEvent()"
    ></circle-progress>

    <!-- in cse of FAILED and want to redownload or upload -->
    <i
      *ngIf="mediaStatus === messageMediaStatus.UPLOAD_FAILED"
      class="fas fa-long-arrow-alt-up"
      aria-hidden="true"
      (click)="retryEvent()"
    ></i>
    <i
      *ngIf="mediaStatus === messageMediaStatus.DOWNLOAD_FAILED"
      class="fas fa-long-arrow-alt-down"
      aria-hidden="true"
      (click)="retryEvent()"
    ></i>

    <!-- in case of media and isPause -->
    <i
      *ngIf="
        mediaStatus === messageMediaStatus.DOWNLOADED ||
        mediaStatus === messageMediaStatus.UPLOADED
      "
      class="fas fa-file"
      aria-hidden="true"
      [ngStyle]="filethem"
    ></i>
  </div>
  <div class="core truncate-text">
    <div class="header truncate-text" dir="auto" [title]="fileName">
      {{ fileName }}
    </div>
    <div class="footer">{{ fileSize | fileSize }}</div>
  </div>
</div>
