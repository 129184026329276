import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import {
  MessageMediaStatus,
  MessageTypes,
  LEFT,
  RIGHT,
  MEDIA_PROGRESS_THEME_COLOR,
  CHAT_THEME_COLOR,
  LinkPreviewOptions,
  MessageLinkPreviewStatus
} from 'src/models/constants';
import { IMessage } from 'src/models/IMessage';
import { UiService } from '../../core/ui.service';

@Component({
  selector: 'app-bubbel-cell',
  templateUrl: './bubbel-cell.component.html',
  styleUrls: ['./bubbel-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbelCellComponent {
  @Input() message: IMessage;
  @Input() isSubChat: boolean;
  @Input() chatType: string;
  @Input() chatName: string;
  @Input() isOneToOneReplie: boolean;
  @Input() isAdmin: boolean;

  @Output() retryNotify = new EventEmitter<IMessage>();
  @Output() cancelNotify = new EventEmitter<IMessage>();

  mType: typeof MessageTypes = MessageTypes;
  linkPreviewStyle: typeof LinkPreviewOptions = LinkPreviewOptions;
  msgLinkPreviewStat: typeof MessageLinkPreviewStatus = MessageLinkPreviewStatus;

  constructor(public uiService: UiService, private _sanitizer: DomSanitizer) {}

  themeColorMedia = MEDIA_PROGRESS_THEME_COLOR;
  themeColorFile = CHAT_THEME_COLOR;
  get messageStyle() {
    return {
      'left-bubbel': this.message.position === LEFT && !this.isOneToOneReplie,
      'right-bubbel': this.message.position === RIGHT && !this.isOneToOneReplie,
      'channel-reply': this.isOneToOneReplie,
      text:
        this.message.type === MessageTypes.TEXT ||
        this.message.type === MessageTypes.TEXT_FILE,
      media:
        this.message.type === MessageTypes.LOCATION ||
        this.message.type === MessageTypes.PHOTO ||
        this.message.type === MessageTypes.GIF ||
        this.message.type === MessageTypes.VIDEO,
      contact: this.message.type === MessageTypes.CONTACT,
      audio:
        this.message.type === MessageTypes.VOICE ||
        this.message.type === MessageTypes.AUDIO,
      file: this.message.type === MessageTypes.DOCUMENT
    };
  }

  getReplies(message: IMessage) {
    if (this.isSubChat) {
      this.uiService.selectSubGroupMessage(message);
    } else {
      this.uiService.selectedMessage(message);
    }
    if (!message.rcvdHistoryReply) {
      if (this.isSubChat) {
        this.uiService.requestHistory(
          message.message_id,
          null,
          null,
          message.group_id
        );
      } else {
        this.uiService.requestHistory(message.message_id);
      }
    }
  }

  retryEvent($event) {
    if ($event) {
      this.retryNotify.emit(this.message);
    }
  }

  cancelEvent($event) {
    if ($event) {
      this.cancelNotify.emit(this.message);
    }
  }

  fullScreenEvent(mediaStatus: string) {
    if (
      mediaStatus === MessageMediaStatus.DOWNLOADED ||
      mediaStatus === MessageMediaStatus.UPLOADED
    ) {
      this.uiService.showMediaScreen(this.message);
    }
  }

  recallEvent() {
    this.uiService.recallMessage(this.message);
  }

  get media() {
    if (this.message.localMedia) {
      return this._sanitizer.bypassSecurityTrustUrl(this.message.localMedia);
    }
    return '';
  }
}
