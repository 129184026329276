<table>
  <tr>
    <th><ng-content select="header-table-first"></ng-content></th>
    <th><ng-content select="header-table-tow"></ng-content></th>
    <th>
      <ng-content select="header-table-three"></ng-content>
    </th>
  </tr>
  <tr>
    <td><ng-content select="first-cell"></ng-content></td>
    <td><ng-content select="second-cell"></ng-content></td>
    <td><ng-content select="third-cell"></ng-content></td>
  </tr>
</table>
