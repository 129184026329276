import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IOption } from 'src/models/campaign';

import { AbstractControlsService } from '../abstract-controls.service';

import { CHANNEL_CATEGORIES, APP_CATEGORIES } from 'src/models/constants';

@Component({
  selector: 'app-selector-input-category',
  templateUrl: './selector-input-category.component.html',
  styleUrls: ['./selector-input-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorInputCategoryComponent implements OnInit {
  /**
   * 'fieldId' is a unique field identification
   * Example: 'email', 'password'
   *    ---REQUIRED PROPERTY---
   */
  @Input() isInline = false;
  @Input() filedLabel: string;
  /**
   * 'control' is a reactive form validator/value controller
   *    ---REQUIRED PROPERTY---
   */
  @Input() control: AbstractControl;

  channelCategories = CHANNEL_CATEGORIES;
  appCategories = APP_CATEGORIES;

  keyValueOptions = [];

  constructor(public abstractControls: AbstractControlsService) {
    const option: IOption = {
      key: 'null',
      value: 'None'
    };
    this.keyValueOptions.unshift(option);
    abstractControls.category$.subscribe(res => {
      if (res && res.list && res.list.length > 0) {
        res.list.map(res => {
          const option = {
            key: res,
            value: res
          };
          this.keyValueOptions.push(option);
        });
      }
    });
  }
  ngOnInit() {
    if (
      this.control.value === this.channelCategories.Social ||
      this.control.value === this.appCategories.Community
    ) {
      this.control.patchValue(null);
    }
  }
}
